import * as React from 'react';
import { PerformanceChart, AggregateCard, Overlay } from 'src/common-ui';
import { styles, footerHeight } from './Productivity.styles';
import * as _ from 'lodash';
import { TenantConfigViewItem, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { SUBHEADER_HEIGHT } from 'src/components/Subheader/Subheader.styles';

import { GroupBySlice, SortBySlice } from 'src/components/Subheader/Subheader.slice';

import { default as Subheader } from 'src/components/Subheader/Subheader.container';

import Renderer from 'src/utils/Domain/Renderer';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import { ViewDataState } from 'src/types/Domain';

export interface DataItem {
  [k: string]: number | string | null;
}

type State = {
  selectedItem: DataItem | null;
};

export type ValueProps = {
  title: string;
  chartConfig: TenantConfigViewItem; // TenantConfigViewDefn
  data: BasicPivotItem[] | null; // PivotItem[]
  carouselView: TenantConfigViewItem[]; // TenantConfigViewDefn
  loaded: boolean;
  groupBy: GroupBySlice;
  groupByViewDefnStr?: string;
  flowStatus: number[];
  sortBy: SortBySlice;
  sortByViewDefnStr?: string;
  viewDefn: TenantConfigViewData;
  unmodifiedViewDefn?: TenantConfigViewData;
  viewDataState: ViewDataState;
};

export type FunctionProps = {
  onShowView: () => void;
  onUpdateConfig: (config: TenantConfigViewData) => void;
};

type ProductivityProps = ValueProps & FunctionProps;

export class Productivity extends React.Component<ProductivityProps> {
  state: State = {
    selectedItem: null,
  };

  constructor(props: ValueProps & FunctionProps) {
    super(props);
  }

  catSelected = (item: DataItem, _num: number) => {
    this.setState({
      selectedItem: item,
    });
  };

  componentDidUpdate(prevProps: ValueProps) {
    // need to reset selectedItem in state when data is reloaded (defaulting to first item in list)
    if (!_.isEqual(prevProps.data, this.props.data)) {
      const selectedItem = !_.isEmpty(this.props.data) ? this.props.data![0] : null;
      this.setState({
        selectedItem,
      });
    }
  }

  componentDidMount() {
    this.props.onShowView();
  }

  makeConfiguratorDefn = (viewDefn: TenantConfigViewData) => {
    return {
      $id: viewDefn.$id,
      id: viewDefn.id,
      view: [],
      ...viewDefn.view[1],
    };
  };

  onUpdateConfig = (config: TenantConfigViewData) => {
    const viewDefn = _.cloneDeep(this.props.viewDefn);
    viewDefn.view[1].view = config.view;
    this.props.onUpdateConfig(viewDefn);
  };

  render() {
    const {
      title,
      loaded,
      chartConfig,
      data,
      carouselView,
      groupByViewDefnStr,
      sortByViewDefnStr,
      groupBy,
      viewDefn,
      unmodifiedViewDefn,
      viewDataState,
    } = this.props;
    const { selectedItem } = this.state;
    let ChartJsx;
    if (chartConfig.view && chartConfig.view.length >= 2 && !_.isNil(data)) {
      const topSeries: TenantConfigViewItem = chartConfig.view[0];
      const bottomSeries: TenantConfigViewItem = chartConfig.view[1];
      ChartJsx = (
        <PerformanceChart
          idProperty="colmemid"
          displayNameProperty="name"
          summaryProperty={topSeries.dataIndex} // I think I'm doing this right...
          topTitle={topSeries.text}
          restrictHeight={true}
          bottomTitle={bottomSeries.text}
          defaultIndex={0}
          topSeries={
            topSeries.view
              ? topSeries.view.map((it) => {
                  return {
                    displayName: it.text,
                    propertyName: it.dataIndex,
                    renderer: (val: number) => (it.renderer != null ? Renderer[it.renderer](val) : val),
                  };
                })
              : []
          }
          bottomSeries={
            bottomSeries.view
              ? bottomSeries.view.map((it) => {
                  return {
                    displayName: it.text,
                    propertyName: it.dataIndex,
                    renderer: (val: number) => (it.renderer != null ? Renderer[it.renderer](val) : val),
                  };
                })
              : []
          }
          // @ts-ignore
          onItemSelect={this.catSelected}
          data={data || []}
        >
          {(item) => {
            return <div className={styles.itemNameContainer}>{item.name}</div>;
          }}
        </PerformanceChart>
      );
    }
    let carousel;
    let carouselItems;
    let displayedItem = selectedItem;
    if (!displayedItem && data != null && data.length > 0) {
      displayedItem = data[0];
    }
    // create card set
    if (displayedItem !== null) {
      const groupByName = getGroupBySelectedOptionProperty(groupBy, 'text');

      carouselItems = carouselView.map((it, ind) => {
        if (it.visible === false) {
          return;
        }
        return (
          <div key={ind} data-qa-key={ind} data-qa-component="AggregateCard" className={styles.cardHolder}>
            <AggregateCard
              index={ind}
              title={it.text}
              className={styles.card}
              primary={Renderer.renderJustValue(displayedItem![it.dataIndex], it)} // needs Renderer
              secondary={
                it.view
                  ? it.view.map((secView) => ({
                      desc: secView.text,
                      value: Renderer.renderJustValue(displayedItem![secView.dataIndex], secView), // needs Renderer
                    }))
                  : []
              }
            />
          </div>
        );
      });
      carousel = (
        <React.Fragment>
          <div className="title" style={{ height: '15%' }}>
            {groupByName + ' : ' + displayedItem.description}
          </div>
          <div className={styles.cardsContainer}>{carouselItems}</div>
        </React.Fragment>
      );
    }

    const viewConfigurator = viewDefn.view &&
      unmodifiedViewDefn && {
        viewConfig: this.makeConfiguratorDefn(viewDefn),
        unmodifiedViewDefn: this.makeConfiguratorDefn(unmodifiedViewDefn),
        updateConfig: this.onUpdateConfig,
      };
    return (
      <React.Fragment>
        <Subheader
          title={title}
          groupByDefn={groupByViewDefnStr}
          sortByDefn={sortByViewDefnStr}
          showFlowStatus={true}
          showSearch={true}
          viewConfigurator={viewConfigurator}
          viewDataState={viewDataState}
        />
        <div data-qa-component="Productivity" className={styles.productivityContainer}>
          <Overlay type={'loading'} visible={!loaded} />
          <div className={styles.graphContainer}>{ChartJsx}</div>
          <footer className={styles.carouselContainer}>{carousel}</footer>
        </div>
      </React.Fragment>
    );
  }
}
