import * as React from 'react';
import * as styles from './Reconciliation.style';

import QRCategorySummary from './QuickRecapView/QRCategorySummary.container';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { classes } from 'typestyle';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { z } from 'zod';
import { QuickRecon } from 'src/services/configuration/codecs/general';

export type Option = {
  dataIndex: string;
  text: string;
  renderer?: string;
};

export type OptionGroup = {
  text: string;
  options: Option[];
};

export type ToggleAction = 'close' | 'apply';

export type EnabledProps = {
  enabled: true;
  quickReconConf?: QuickReconConf;
  isOpen: boolean | undefined;
  onToggleModal(action: ToggleAction): void;
  onReset(): void;
};

export type DisabledProps = { enabled: false };
export type ConfigureModalProps = EnabledProps;

export type Selection = {
  dataIndex: string;
  selections: Option[];
  text: string;
};

export type QuickReconConf = z.infer<typeof QuickRecon>;

export default class ReconcileModal extends React.Component<ConfigureModalProps> {
  constructor(props: ConfigureModalProps) {
    super(props);
  }

  render() {
    if (!this.props.enabled || !this.props.quickReconConf) {
      return null;
    }

    const { isOpen, onToggleModal, quickReconConf } = this.props;
    const floorsetApi = {
      url: '/api/assortment/gettrimmedfloorsets',
      params: {
        appName: ASSORTMENT,
        defnId: '',
        aggBy: '',
      },
    };

    return (
      <Dialog
        open={isOpen!}
        disableBackdropClick={false}
        fullWidth={true}
        scroll={'body'}
        maxWidth={'xl'}
        classes={{
          root: styles.modal,
          paperScrollBody: styles.modalPaper,
        }}
      >
        <DialogTitle classes={{ root: styles.modalTitle }} disableTypography={true}>
          <div className={styles.titleContainer}>
            <div className={classes(styles.leftTitleContainer, styles.modalTitleText)}>Quick Reconcile</div>
            <div className={classes(styles.rightTitleContainer, styles.modalTitleText)}>
              <i className={classes('far fa-times', styles.modalTitleIcon)} onClick={() => onToggleModal('close')} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent classes={{ root: styles.modalContent }}>
          <QRCategorySummary quickReconConf={quickReconConf} floorsetApi={floorsetApi} />
        </DialogContent>
      </Dialog>
    );
  }
}
