import React from 'react';
import { useState, useEffect } from 'react';
import { dialogContent, dialogTitle, modal } from 'src/components/ConfirmationModal/ConfirmationModal.styles';

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import cuid from 'cuid';
import { BasicItem } from 'src/types/Scope';

export enum SaveType {
  favorite,
}

/* 
  Similar to the delete modal this type allows an item
  with known keys to be passed in to the save modal. 
*/
// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-unused-vars
interface SaveConfirmationItem {}

type SaveConfirmationModalProps = {
  isOpen: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement>) => void;
  onSave: (favMeta?: BasicItem) => void;
  nameValidator: (name: string) => boolean;
};

export const SaveConfirmationModal = (props: SaveConfirmationModalProps) => {
  const inputRef = React.useRef<HTMLInputElement>();
  const { onSave, onClose, isOpen, nameValidator } = props;
  const [saveName, setSaveName] = useState<string | null>(null); // may need to inject props here
  const [nameValid, setNameValid] = useState<boolean>(false);

  useEffect(() => {
    // some weird async issue with the input ref :(
    const timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!saveName) {
      setNameValid(false);
    } else {
      setNameValid(nameValidator(saveName));
    }
  }, [nameValidator, saveName]);

  return (
    <Dialog open={props.isOpen} onClose={() => onClose} maxWidth={'md'} className={modal}>
      <DialogTitle classes={{ root: dialogTitle }}>
        <div>
          <span className="left-container">
            <i className="fas fa-save icon" />
            Save New Favorite
          </span>
          <span className="right-container">
            <i className="far fa-times" onClick={onClose} />
          </span>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: dialogContent }}>
        <p className="instructions">Favorite name:</p>
        <TextField
          id="outlined-basic"
          label="name"
          variant="outlined"
          error={!nameValid}
          inputRef={inputRef}
          onChange={(e) => setSaveName(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              if (saveName && nameValidator(saveName)) {
                onSave({ id: cuid(), name: saveName });
              }
            }
          }}
        />
        <footer>
          <button
            disabled={!nameValid}
            onClick={(_evt) => {
              if (saveName && nameValidator(saveName)) {
                onSave({ id: cuid(), name: saveName });
              }
            }}
            className="apply"
          >
            <i className="far fa-check" />
            Save
          </button>
          <button onClick={onClose} className="reset">
            <i className="fas fa-ban" />
            Cancel
          </button>
        </footer>
      </DialogContent>
    </Dialog>
  );
};
