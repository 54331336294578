import { ofType } from 'redux-observable';
import { mergeMap, map, filter, of } from 'rxjs';
import { isEmpty } from 'lodash';
import { AppEpic } from 'src/store';

import { inputIsNotNullOrUndefined } from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  NestedOvertimeComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { receiveScopeRefreshTrigger } from 'src/components/ScopeSelector/ScopeSelector.slice';
import {
  fetchNestedOvertimeData,
  receiveTenantConfig,
  setConfigureSelections,
  setTopMember,
} from './NestedOvertime.slice';

export const nestedOvertimeLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      setActivePage.type,
      receiveTenantConfig.type,
      setTopMember.type,
      setConfigureSelections.type
    ),
    map(() => maybeGetComponentProps<NestedOvertimeComponent>(state$.value, ConfDefnComponentType.nestedOvertime)),
    filter(inputIsNotNullOrUndefined),
    // filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    // @ts-ignore
    mergeMap(({ dataApi }) => {
      const overtimeDefn = dataApi.params.defnId;
      const { topMember, configureSelections } = state$.value.pages.hindsighting.nestedOvertime;
      if (!isEmpty(configureSelections)) {
        const overtimeOptions: ListDataOptions = {
          ...dataApi.params,
          topMembers: topMember,
          aggBy: [dataApi.params.aggBy].concat(configureSelections.map((i) => `${i.type}:${i.dataIndex}`)).join(','),
        };

        // overtime data request needs a topmember which comes from companionData selection
        return isEmpty(topMember) && of(fetchNestedOvertimeData(overtimeDefn, overtimeOptions));
      } else return of();
    })
  );
};
