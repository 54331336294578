import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { TenantConfigViewData, GeoTrendsConfigData } from 'src/dao/tenantConfigClient';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

export interface ViewDefns {
  keyFinancials: TenantConfigViewData;
  choiceProductivity: TenantConfigViewData;
  productMixAndTrend: TenantConfigViewData;
  trendAnalysis: TenantConfigViewData;
  globalRegionVolumeAndTrend: GeoTrendsConfigData;
}

export interface ViewData {
  keyFinancials: BasicPivotItem[];
  choiceProductivity: BasicPivotItem[];
  trendAnalysis: BasicPivotItem[];
  productMixAndTrend: BasicPivotItem[];
  globalRegionVolumeAndTrend: BasicPivotItem[];
}

export interface SummarySlice {
  areViewDefnsLoading?: boolean;
  flowStatusUpdated?: boolean;
  isViewDataLoading?: boolean;
  viewDefns: ViewDefns;
  viewData: ViewData;
}

const initialState: SummarySlice = {
  areViewDefnsLoading: true,
  flowStatusUpdated: true,
  isViewDataLoading: true,
  viewDefns: {} as ViewDefns,
  viewData: {} as ViewData,
};

const summarySlice = createSlice({
  name: 'Summary',
  initialState,
  reducers: {
    requestSummaryConfigs(state) {
      return state;
    },
    receiveSummaryConfigs(state, action: PayloadAction<ViewDefns>) {
      state.viewDefns = action.payload;
      state.areViewDefnsLoading = false;
    },
    requestSummaryData(state) {
      state.isViewDataLoading = true;
    },
    requestChoiceProductivityData(state) {
      state.isViewDataLoading = true;
    },
    receiveSummaryData(state, action: PayloadAction<ViewData>) {
      state.viewData = action.payload;
      state.isViewDataLoading = false;
    },
    receiveChoiceProductivityData(state, action: PayloadAction<ViewData>) {
      state.viewData = action.payload;
      state.isViewDataLoading = false;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestSummaryConfigs,
  receiveSummaryConfigs,
  requestChoiceProductivityData,
  receiveSummaryData,
  receiveChoiceProductivityData,
  receiveError,
  cleanUp,
  requestSummaryData,
} = summarySlice.actions;

export default summarySlice.reducer;
