import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { ViewDataState } from 'src/types/Domain';
import { cacheCheckFetchPivotData } from 'src/pages/Hindsighting//StyleColorReview/StyleColorReview.slice';
import serviceContainer from 'src/ServiceContainer';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

export type NestedAttributeConfig = {
  grid: TenantConfigViewData;
  macro: TenantConfigViewData;
  list: TenantConfigViewData;
  configure: TenantConfigViewData;
  unmodifiedViewDefn: TenantConfigViewData;
};

export type NestedAttributeSlice = {
  aggBys: string[];
  viewDefns: NestedAttributeConfig;
  isConfigLoading: boolean;
  viewDataState: ViewDataState;
  cacheHash: string | null;
};

const initialState: NestedAttributeSlice = {
  aggBys: [],
  viewDefns: {
    grid: {} as TenantConfigViewData,
    macro: {} as TenantConfigViewData,
    list: {} as TenantConfigViewData,
    configure: {} as TenantConfigViewData,
    unmodifiedViewDefn: {} as TenantConfigViewData,
  },
  isConfigLoading: false,
  viewDataState: ViewDataState.idle,
  cacheHash: null,
};

const nestedAttributeReducer = createSlice({
  name: 'NestedAttribute',
  initialState,
  reducers: {
    updateAggBys(state, action: PayloadAction<string[]>) {
      state.aggBys = action.payload;
    },
    requestConfig(state) {
      state.isConfigLoading = true;
    },
    receiveConfig(state, action: PayloadAction<NestedAttributeConfig>) {
      state.isConfigLoading = false;
      state.viewDefns = action.payload;
    },
    receiveSomeNestedAttributeConfig(state, action: PayloadAction<TenantConfigViewData>) {
      state.isConfigLoading = false;
      state.viewDefns.grid = {
        ...state.viewDefns.grid,
        ...action.payload,
      };
    },
    requestData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataReady;
      }
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  updateAggBys,
  requestConfig,
  receiveConfig,
  receiveSomeNestedAttributeConfig,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  receiveError,
  cleanUp,
} = nestedAttributeReducer.actions;

export function fetchNestedAttributeData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    serviceContainer.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData
  );
}

export default nestedAttributeReducer.reducer;
