import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import { isUndefined, isEqual, uniq } from 'lodash';

import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import coalesce from 'src/utils/Functions/Coalesce';
import Renderer from 'src/utils/Domain/Renderer';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { Point } from 'highcharts';

export interface PointOptions {
  id: string;
  mId: string;
  mDesc: string;
  month: string;
  z: number;
  region?: string;
}

export const isPointOptions = (maybePointOptions: Object): maybePointOptions is PointOptions => {
  return 'id' in maybePointOptions && 'mId' in maybePointOptions;
};

export interface ExtendedPointObject extends Point, PointOptions {}

export type SLCProps = {
  data: BasicPivotItem[];
  config: TenantConfigViewItem;
  title?: string;
};

export class SimplerLineChart extends React.Component<SLCProps> {
  constructor(props: SLCProps) {
    super(props);
  }

  shouldComponentUpdate(nextProps: SLCProps) {
    const { data: oldData, config: oldConfig } = this.props;

    if (!isUndefined(oldData) && !isUndefined(nextProps.data)) {
      const newData = nextProps.data;
      const newConfig = nextProps.config;

      const isDifferentData = !isEqual(oldData, newData);
      const isDifferentConfig = !isEqual(oldConfig, newConfig);

      return isDifferentData || isDifferentConfig;
    }
    return true;
  }

  dataHandle(data: BasicPivotItem[], config: TenantConfigViewItem, title: string | undefined) {
    /* eslint-disable-next-line @typescript-eslint/no-this-alias */
    const that = this;

    let categories: string[] = data.map((m) => m.month);

    categories = uniq(categories);
    categories.sort();

    return {
      title: {
        text: coalesce(title, config.text), // Comes from selectedItem
      },
      yAxis: {
        title: {
          text: '', // Comes from selectedItem
        },
        labels: {
          // tslint:disable-next-line:no-any
          formatter: function(this: { value: any }) {
            if (that.props.config != null) {
              return Renderer.renderJustValue(this.value, that.props.config);
            }
          },
        },
      },
      xAxis: {
        categories: categories,
        title: {
          text: 'Month',
        },
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          marker: {
            enabled: false,
          },
        },
      },
      tooltip: {
        formatter: function(this: { point: ExtendedPointObject; _series: null }) {
          // force render string;
          if (that.props.config != null) {
            return Renderer.renderJustValue(this.point.y, that.props.config);
          }
        },
      },
      legend: {
        enabled: this.isYearGrouped(data),
      },
      series: this.getSeries(data, config),
    };
  }

  isYearGrouped(data: BasicPivotItem[]): boolean {
    return data && data[0] && data[0]['lyty_key'];
  }

  getSeries = (data: BasicPivotItem[], config: TenantConfigViewItem) => {
    // If LY/TY grouping returns from the pivot, group it. If not, don't.
    if (this.isYearGrouped(data)) {
      return [
        {
          name: 'LY',
          data: data
            .filter((item: BasicPivotItem) => item['lyty_key'] == 'LY')
            .map((item: BasicPivotItem) => ({
              y: item[config.dataIndex],
              name: item.name,
            })),
        },
        {
          name: 'TY',
          data: data
            .filter((item: BasicPivotItem) => item['lyty_key'] == 'TY')
            .map((item: BasicPivotItem) => ({
              y: item[config.dataIndex],
              name: item.name,
            })),
        },
      ];
    }
    return [
      {
        data: data.map((item: BasicPivotItem) => ({
          y: item[config.dataIndex],
          name: item.name,
        })),
      },
    ];
  };

  render() {
    const { data, config } = this.props;
    if (data == null || config == null || this.props.config == null) {
      return <div />;
    }

    const highchartsConfig = this.dataHandle(this.props.data, this.props.config, this.props.title);

    return (
      <HighchartsReact
        // ReactHighCharts wraps everything in a container div with no styling
        // so we pass some heights, otherwise highcharts won't know the parent container height
        containerProps={{ style: { height: '100%' } }}
        options={{ ...highchartsConfig }}
        immutable={true} // TODO: check that this works the same as isPureConfig
      />
    );
  }
}
