import { classes, style } from 'typestyle';
import { RED_PRIMARY, TEAL_PRIMARY, smallFont } from 'src/utils/Style/Theme';
import { resolvePath } from 'src/cdn';
import { _p } from 'src/utils/Style/NestUtils';
import { dropdown } from 'src/utils/Style/Dropdown.styles';
import * as dropdownStyles from 'src/utils/Style/Dropdown.styles';

import scopeBackgroundPath from 'src/images/scopeBackground.png';
const scopeBackground = resolvePath(scopeBackgroundPath);

// Adds media query for scope selects
const DROPDOWN_WIDTH = 210;
export const PICKER_WIDTH =
  window.innerWidth < DROPDOWN_WIDTH * 4 ? DROPDOWN_WIDTH - window.innerWidth / 14 : DROPDOWN_WIDTH;

const scopeSelector = style({
  $debugName: 'scopeSelector',
  width: '100%',
  height: '125px',
  left: 0,
  position: 'fixed',
  color: '#fff',
  top: 85,
  background: `url(${scopeBackground})`,
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'center',
  backgroundSize: '100%',
  $nest: {
    header: {
      color: '#fff',
      fontSize: smallFont,
      position: 'absolute',
      top: 5,
      left: 5,
    },
    '.scopeArrow': {
      position: 'absolute',
      width: 0,
      height: 0,
      borderLeft: '40px solid transparent',
      borderRight: '40px solid transparent',
      borderBottom: '20px solid #007b77',
      top: '-20px',
      right: '230px',
    },
    '.scopeArrowLoading': {
      position: 'absolute',
      width: 0,
      height: 0,
      borderLeft: '40px solid transparent',
      borderRight: '40px solid transparent',
      borderBottom: '20px solid white',
      top: '-20px',
      right: '210px',
      opacity: 0.6,
    },
    '.dropdown-menu': {
      fontSize: smallFont,
      $nest: {
        '.dropdown-item': {
          cursor: 'pointer',
        },
      },
    },
    [_p(dropdown)]: {
      color: '#fff',
    },
  },
});
const scopeSelectorDropdownItem = style({
  $debugName: 'scopeSelectorDropdownItem',
});
const scopeSelectorLabel = style({
  $debugName: 'scopeSelectorLabel',
});
const defaultDropdown = style({
  $debugName: 'scope-default-dropdown',
  $nest: {
    [_p(dropdownStyles.formLabel)]: {
      color: 'inherit',
      top: 5,
      left: 0,
      padding: 5,
      transform: 'none',
      transition: 'none',
    },
    [_p(dropdownStyles.autocompleteTitle)]: {
      color: 'inherit',
      cursor: 'unset',
      top: -28,
      left: 0,
      padding: 5,
      transform: 'none',
      transition: 'none',
    },
    [_p(dropdownStyles.dropdown)]: {
      display: 'flex',
      fontWeight: 400,
      cursor: 'pointer',
      height: '100%',
      $nest: {
        '.far': {
          color: '#fff',
        },
        '.far[disabled]': {
          color: 'rgba(0, 0, 0, 0.38)',
        },
        'div:first-child': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 1,
        },
        '.dropdown-menu': {
          width: '100%',
          maxHeight: '400px',
          overflow: 'auto',
        },
      },
    },
  },
});
const scopeSelectorDropdown = classes(
  defaultDropdown,
  style({
    $debugName: 'scopeSelectorDropdown',
    backgroundColor: '#56cfc7',
    fontSize: smallFont,
    width: PICKER_WIDTH,
    height: '34px',
    $nest: {
      '.endAdornment': {
        display: 'flex',
      },
      '.s5-MuiButtonBase-root': {
        color: 'white',
      },
      '.s5-MuiInputBase-root': {
        color: 'white',
        fontSize: smallFont,
        marginLeft: '0.5em',
      },
      '.s5-MuiInput-underline::after': {
        display: 'none',
      },
      '.s5-MuiInput-underline::before': {
        display: 'none',
      },
    },
  })
);
const scopeSelectorDropdownSmall = classes(
  defaultDropdown,
  style({
    $debugName: 'scopeSelectorDropdownSmall',
    width: '9rem',
    height: 34,
    fontSize: smallFont,
    $nest: {
      [`& .${dropdown}`]: {
        marginTop: 'unset',
      },
    },
  })
);
const isInvalid = style({
  border: `1px solid ${RED_PRIMARY}`,
});
const scopeColumn = style({
  $debugName: 'scopeColumn',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  paddingRight: '1rem',
  $nest: {
    '.picker, .text': {
      height: 34,
    },
    '.picker': {
      width: PICKER_WIDTH,
    },
    '.text': {
      fontSize: smallFont,
      paddingLeft: '1rem',
    },
  },
});
const theSpacer = style({
  $debugName: 'theSpacer',
  height: 34,
});
const selectScopeButton = style({
  $debugName: 'selectScopeButton',
  color: TEAL_PRIMARY,
  backgroundColor: '#fff',
  border: 'none',
  fontSize: '1.25rem',
  padding: 0,
  width: 34,
  height: 34,
  $nest: {
    '&:hover': {
      border: '1px solid #fff',
    },
  },
});
const formDiv = style({
  background: 'none',
  top: 95,
});

export default {
  scopeSelector,
  scopeSelectorDropdownItem,
  scopeSelectorLabel,
  scopeSelectorDropdown,
  scopeSelectorDropdownSmall,
  isInvalid,
  scopeColumn,
  theSpacer,
  selectScopeButton,
  formDiv,
};
