import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import { createSelector, OutputSelector } from 'reselect';

import { AppState } from 'src/store';
import {
  CardViewDataInputs,
  CardViewGroup,
  CardViewOptionalInputs,
} from 'src/components/StandardCardView/UIData.types';
import { ReduxSlice as SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { generateGroupBy } from 'src/pages/Hindsighting/StyleColorReview/TopTYvsLY/TopTYvsLY.container';
import { getDateFromWeek } from 'src/components/ScopeSelector/ScopeSelector.utils';
import { BasicItem, BasicPivotItem, WorklistInfo } from 'src/worker/pivotWorker.types';
import { DaysRangeListResponse } from 'src/types/Scope';
import { FloorsetCmpSlice } from './FloorsetComparison.slice';
import { getDataFromCache } from 'src/services/pivotServiceCache';
import processDataForCardView from 'src/components/StandardCardView/CardViewDataProcessor';

export type ProcessedDataSelector = OutputSelector<
  AppState,
  CardViewGroup[],
  (res2: FloorsetCmpSlice, styles: BasicPivotItem[], subh: SubheaderSlice, work: WorklistInfo[]) => CardViewGroup[]
>;

export type FloorsetIndexSelector = OutputSelector<
  AppState,
  number,
  (
    res1: number,
    res2: number,
    res3: BasicItem[],
    res4: string | null,
    res5: number | undefined,
    res6: DaysRangeListResponse,
    res7: string | null
  ) => number
>;

const getStyles = (state: AppState) => {
  return getDataFromCache(state.pages.assortmentBuild.floorsetComparison)?.tree || [];
};

const getView = (state: AppState) => {
  return state.pages.assortmentBuild.floorsetComparison;
};

const getSubheader = (state: AppState) => {
  return state.subheader;
};
const getWorklist = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.sharedData.worklist;
};

export const getProcessedData: ProcessedDataSelector = createSelector(
  getView,
  getStyles,
  getSubheader,
  getWorklist,
  (viewSlice, styles, subheader, worklist) => {
    if (viewSlice && viewSlice.groupingInfo && subheader) {
      const groupingInfo = viewSlice.groupingInfo;
      const groupBy = generateGroupBy(groupingInfo.dataIndex);

      const dataInputs: CardViewDataInputs = {
        styles: styles || null,
        subheader: { ...subheader, groupBy },
        defns: {
          view: viewSlice.viewDefn || {},
          rollup: viewSlice.calcViewDefn || {},
        },
      };
      const optionalInputs: CardViewOptionalInputs = {
        worklist,
        staticColumns: groupingInfo.staticColumns,
      };

      return processDataForCardView(dataInputs, optionalInputs);
    }
    return [];
  }
);

const getCurrentFloorsetIndex = (state: AppState) => {
  return state.pages.assortmentBuild.floorsetComparison.selectedFloorsetIndex;
};

const getLastUpdatedFloorsetIndex = (state: AppState) => {
  return state.pages.assortmentBuild.floorsetComparison.floorsetIndexLastUpdated;
};

const getFloorsets = (state: AppState) => {
  return state.pages.assortmentBuild.floorsetComparison.floorsets;
};

const getScopeFloorsetSelection = (state: AppState) => {
  return state.scope.selections.floorSet;
};

const getScopeLastUpdated = (state: AppState) => {
  return state.scope.updatedAt;
};

const getDaysRangeList = (state: AppState) => {
  return state.scope.daysRangeList;
};

const getScopeStart = (state: AppState) => {
  return state.scope.selections.start;
};

export const getSelectedFloorsetIndex: FloorsetIndexSelector = createSelector(
  getCurrentFloorsetIndex,
  getLastUpdatedFloorsetIndex,
  getFloorsets,
  getScopeFloorsetSelection,
  getScopeLastUpdated,
  getDaysRangeList,
  getScopeStart,
  (
    selectedFloorsetIndex,
    floorsetIndexLastUpdated,
    floorsets,
    scopeFloorsetId,
    scopeLastUpdated,
    daysRangeList,
    start
  ) => {
    if (isEmpty(floorsets)) {
      return -1;
    }

    const viewFloorsetId = selectedFloorsetIndex >= 0 ? floorsets[selectedFloorsetIndex].id : '';

    if (!isNil(scopeLastUpdated) && !isNil(scopeFloorsetId)) {
      // checks for scope floorset selection
      // set selection to whichever was last selected (scope floorset dropdown or view's floorset dropdown)
      const mostRecentFloorsetId = scopeLastUpdated > floorsetIndexLastUpdated ? scopeFloorsetId : viewFloorsetId;
      return floorsets.findIndex((floorset) => floorset.id === mostRecentFloorsetId);
    }

    if (!isNil(start)) {
      const startDate = getDateFromWeek(start, daysRangeList.start_date);

      if (isEmpty(startDate)) {
        return 0;
      }

      const startMonth = moment(startDate).format('YYYY-DD-MMM');
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [year, day, month] = startMonth.split('-');
      const index = floorsets.findIndex(
        (floorset) => !isNil(floorset.id.match(year)) && !isNil(floorset.id.match(month))
      );
      return index < 0 ? 0 : index;
    }

    return 0; // default to first item in array
  }
);
