import { AppState } from 'src/store';
import { createSelector } from 'reselect';
import { get } from 'lodash';
import { projectState, StateSelection } from 'src/components/ListGridPair/ListGridPair.selectors';
import { NAME } from 'src/utils/Domain/Constants';
import { FabType } from 'src/components/higherOrder/withFab';
import { NestedViewOwnProps } from './NestedView.container';
import { getDataFromCache, isDataLoaded } from 'src/services/pivotServiceCache';
import { getConfigureModalAggBys } from '../../StyleColorReview/StyleColorReview.slice';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { processAdornments } from 'src/components/Adornments/Adornments';

function selectState(state: AppState, ownProps: NestedViewOwnProps): StateSelection {
  const { nestedView: viewState } = state.pages.hindsighting.historyGrid;
  const { title = 'Nested View', defns, hideCompanion, keys } = ownProps;
  const { viewDataState, isConfigLoading, viewDefns, aggBys: currentAggBys } = viewState;
  const treeData = getDataFromCache(viewState)?.tree || [];
  const configLoaded = !viewState.isConfigLoading;
  const dataLoaded = isDataLoaded(viewDataState);
  const currentAdornments: AdornmentType[] = get(viewState.viewDefns.grid, 'adornments', []);
  const adornments = processAdornments(currentAdornments);

  // Need to have the same aggBys that the data was requested with;
  // currentAggBys is missing the bottom level so re-determining the aggBys here for proper data processing
  const aggBys = getConfigureModalAggBys(currentAggBys, defns.dataApi).split(',');

  return {
    identityField: keys ? keys.idProp : NAME,
    subheaderState: state.subheader,
    configLoaded,
    dataLoaded,
    treeData,
    title,
    subheaderViewDefns: defns.subheader,
    shouldFilterFlowStatus: false,
    activeTab: state.perspective.activeTab,
    fabType: FabType.none,
    isConfigLoading,
    viewDefns,
    aggBys,
    viewDataState,
    hideCompanion,
    adornments,
  };
}
export const selectAndProjectState = createSelector(selectState, projectState);
