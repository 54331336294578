import * as React from 'react';
import styles from './Badge.styles';

export interface BadgeData {
  type?: string;
  color?: string;
  active?: boolean;
  onClick?: (badge: BadgeData) => void;
}

const DEFAULT_COLOR = 'lightgrey';
export const iconMap = new Map([
  ['heart', 'far fa-heart'],
  ['heart-filled', 'fas fa-heart'],
  ['circle', 'far fa-circle'],
  ['circle-filled', 'fas fa-circle'],
  ['unlocked', 'fas fa-unlock'],
  ['locked', 'fas fa-lock'],
]);

class Badge extends React.Component<BadgeData> {
  constructor(props: BadgeData) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { active, onClick } = this.props;
    if (active && onClick) {
      onClick(this.props);
    }
  }

  render() {
    const { active = false, color = DEFAULT_COLOR, type = 'circle' } = this.props;
    let actualType = iconMap.get(type) || type;
    if (active) {
      actualType = actualType + ' ' + styles.clickable;
    }
    return (
      <div className={styles.badgeContainer} onClick={this.handleClick} data-qa="badgeContainer">
        <i data-qa="badge" className={actualType} style={{ color }} />
      </div>
    );
  }
}

export default Badge;
