import { createSelector } from 'reselect';
import { parseGridConfig, FrameworkComponents } from 'src/utils/Component/AgGrid/AgConfigParse';
import { AppState } from 'src/store';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { filterData } from 'src/utils/Pivot/Filter';
import { GroupBySlice } from 'src/components/Subheader/Subheader.slice';
import { QRCategorySummarySlice } from './QRCategorySummary.slice';
import { pick } from 'lodash';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ColDef } from 'ag-grid-community';
import { listDataTreeToAgFlatTree } from 'src/utils/Component/AgGrid/AgDataFormat';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';

export type StateSelection = QRCategorySummarySlice;

function selectState(state: AppState): StateSelection {
  return {
    ...state.quickReconcile,
  };
}

export type LoadingProjection = {
  loaded: false;
  groupBy: GroupBySlice;
  floorset: string;
  isViewDataLoading: boolean | undefined;
  floorsetEnabled?: boolean;
};

export type LoadedProjection = {
  loaded: true;
  rowHeight?: number;
  groupBy: GroupBySlice;
  floorset: string;
  treeColumnDefinition: ColDef | undefined;
  gridData: BasicPivotItem[];
  colDefs: ColDef[];
  frameworkComponents: FrameworkComponents;
  isPrintMode?: boolean;
  isViewDataLoading: boolean | undefined;
  floorsetEnabled?: boolean;
};

export type StateProjection = LoadedProjection | LoadingProjection;

export function projectState(stateSelection: StateSelection): StateProjection {
  const { groupBy, floorset, areViewDefnsLoading, isViewDataLoading, viewData, viewDefns } = stateSelection;
  const search = '';
  if (!areViewDefnsLoading && !isViewDataLoading && viewData && viewDefns) {
    const parseResult = pick(parseGridConfig(viewDefns.gridDefn), 'colDefs', 'frameworkComponents', 'rowHeight');

    let gridItems;
    let treeColumnDefinition;

    if (viewDefns.groupByDefn && groupBy.selection) {
      const groupingField = getGroupBySelectedOptionProperty(groupBy, 'dataIndex');
      const result = listDataTreeToAgFlatTree([groupingField], search, [], viewData!.gridData);
      treeColumnDefinition = result.treeColumnDefinition;
      gridItems = result.agFlatTree;
    } else {
      // No Group By
      gridItems = filterData(viewData.gridData, search, externalGridSearchFields, []);
    }
    return {
      loaded: true,
      groupBy: groupBy,
      floorset: floorset,
      treeColumnDefinition,
      gridData: gridItems,
      isViewDataLoading: isViewDataLoading,
      floorsetEnabled: viewDefns.floorsetDefn && viewDefns.floorsetDefn.enabled,
      ...parseResult,
    };
  }

  return { loaded: false, groupBy: groupBy, isViewDataLoading: isViewDataLoading, floorset: floorset };
}

export default createSelector(selectState, projectState);
