import * as React from 'react';
import { isNil, isEmpty, isEqual } from 'lodash';

import { ViewApiConfig } from '../StyleEdit.types';
import { getValidValues } from '../StyleEditSection/StyleEditSection.client';
import { getUrl } from '../StyleEdit.utils';
import InputSuggest, { Suggestion } from 'src/common-ui/components/Inputs/InputSuggest/InputSuggest';
import styles from './DividedColumnDetails.styles';
import { classes } from 'typestyle';

export interface DependentPropDropdownProps {
  key: string;
  value: string;
  styleId: string;
  dataApi: ViewApiConfig;
  dataQa: string;
  mandatory?: boolean;
  allowEmptyOption: boolean;
  concatOptionValues: boolean;
  ignoreCache?: boolean;
  disabled?: boolean;
  editable?: boolean;
  handleDropdownChange: (selection: any) => void;
}

export interface DependentPropDropdownState {
  selectedIndex: number;
  options: Suggestion[];
}

export default class DependentPropDropdown extends React.Component<
  DependentPropDropdownProps,
  DependentPropDropdownState
> {
  constructor(props: DependentPropDropdownProps) {
    super(props);

    this.state = {
      options: [],
      selectedIndex: -1, // guarantees first update after initial selection
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: DependentPropDropdownProps) {
    if (prevProps.styleId !== this.props.styleId) {
      this.getData(true);
    }
    if (!isEqual(prevProps.dataApi, this.props.dataApi)) {
      this.getData(true, this.props.mandatory);
    }
  }

  shouldComponentUpdate(nextProps: DependentPropDropdownProps, nextState: DependentPropDropdownState) {
    if (!isEqual(nextProps.dataApi, this.props.dataApi)) {
      return true; // dependent prop cleared, re-get options data
    }

    if (nextProps.styleId !== this.props.styleId) {
      return true; // when styleId is updated, will get new dropdown options
    }

    return nextState.selectedIndex !== this.state.selectedIndex || !isEqual(nextState.options, this.state.options);
  }

  getData(refresh = false, mandatory = false) {
    const { dataApi, allowEmptyOption, concatOptionValues } = this.props;
    const url = getUrl(dataApi);

    getValidValues(url, allowEmptyOption, concatOptionValues)
      .then((validValues: Suggestion[]) => {
        // Either select the selected index or a blank value
        const prevSelectedIndex = !isEmpty(this.props.value) ? this.getSelectedIndex(validValues) : -1;
        let selectedIndex =
          prevSelectedIndex < 0 ? validValues.findIndex((opt) => opt.value === '') : prevSelectedIndex;

        // If mandatory, set to the first known value
        const foundIndex = validValues.findIndex((opt) => opt.value !== '' && !isEmpty(opt.value));
        if (mandatory && foundIndex > -1 && prevSelectedIndex < 0) {
          selectedIndex = foundIndex;
        }

        this.setState(
          {
            options: validValues,
            selectedIndex: selectedIndex < 0 ? 0 : selectedIndex, // default to first item as selection if no match
          },
          () => {
            if (refresh) {
              // on options refresh notify parent that the first option is new data selection. Pick non-empty
              const { selectedIndex } = this.state;
              const index = selectedIndex < 0 ? 0 : selectedIndex;
              this.props.handleDropdownChange(validValues[index]);
            }
          }
        );
      })
      .catch((err) => console.log(err));
  }

  getSelectedIndex(options: any[]) {
    const { value } = this.props;
    return options.findIndex((option) => option.value === value);
  }

  handleSelection = (selection: Suggestion) => {
    const { options } = this.state;
    const selectedIndex = options.findIndex((option) => option.value === selection.value);
    const selectedValue = options[selectedIndex];

    this.setState(
      {
        selectedIndex,
      },
      () => this.props.handleDropdownChange(selectedValue)
    );
  };

  render() {
    const { dataQa, disabled, editable, key } = this.props;
    const { selectedIndex, options } = this.state;
    const dropdownOptions = isNil(options) || isEmpty(options) ? [] : options;
    const selected = !isEmpty(dropdownOptions) ? options[selectedIndex] : undefined;

    // When it's not editable, display first option
    if (!editable) {
      return (
        <div className={classes(styles.dataItem, styles.dropdownValue)} key={key}>
          <div className={styles.dropdownValue}>{!isEmpty(dropdownOptions) && dropdownOptions[0].value}</div>
        </div>
      );
    }
    return (
      <div className={styles.dataItem}>
        <InputSuggest
          validValues={dropdownOptions}
          multiSelect={false}
          selected={selected}
          onSelect={this.handleSelection}
          dataQa={dataQa}
          disabled={disabled}
        />
      </div>
    );
  }
}
