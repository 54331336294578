/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';
import { noop } from 'lodash';
import { Report } from 'src/dao/reportClient';
import { DataApiConfig } from 'src/services/configuration/codecs/confdefnView';
import { getMutations, getNotices, uploadMutationTemplate } from './BulkImport.actions';

export interface BulkImportMutation extends Report {
  attributeDefnsImpact?: string[];
  systemTableImpact?: string[];
  floorsetApi?: DataApiConfig;
}
export type BulkImportNotice = BulkImportSuccess | BulkImportFailure;
interface BulkImportSuccess {
  rowsLoaded: number;
}
export interface BulkImportFailure {
  problems: {
    rowId: number;
    message: string;
  }[];
}

export enum BulkImportLoadingState {
  idle,
  mutationsLoading,
  templateLoading,
  templateUploading,
  noticesLoading,
}

export type BulkImportSlice = {
  mutations: BulkImportMutation[] | undefined;
  bulkImportLoadingState: BulkImportLoadingState;
};

const initialState: BulkImportSlice = {
  mutations: undefined,
  bulkImportLoadingState: BulkImportLoadingState.idle,
};

const bulkImportReducer = createSlice({
  name: 'BulkImport',
  initialState: initialState,
  reducers: {
    cleanUp() {
      return initialState;
    },
    onShowView() {
      noop();
    },
  },
  extraReducers: (builder) => {
    //#region getMutations actions
    builder.addCase(getMutations.pending, (state) => {
      state.bulkImportLoadingState = BulkImportLoadingState.mutationsLoading;
      state.mutations = undefined;
    });
    builder.addCase(getMutations.fulfilled, (state, action) => {
      state.bulkImportLoadingState = BulkImportLoadingState.idle;
      state.mutations = action.payload;
    });
    builder.addCase(getMutations.rejected, (state) => {
      state.bulkImportLoadingState = BulkImportLoadingState.idle;
    });
    //#endregion
    //#region uploadBulkImport actions
    builder.addCase(uploadMutationTemplate.pending, (state) => {
      state.bulkImportLoadingState = BulkImportLoadingState.templateUploading;
    });
    builder.addCase(uploadMutationTemplate.fulfilled, (state) => {
      state.bulkImportLoadingState = BulkImportLoadingState.idle;
    });
    builder.addCase(uploadMutationTemplate.rejected, (state) => {
      state.bulkImportLoadingState = BulkImportLoadingState.idle;
    });
    //#endregion
    //#region getNotices actions
    builder.addCase(getNotices.pending, (state) => {
      state.bulkImportLoadingState = BulkImportLoadingState.noticesLoading;
    });
    builder.addCase(getNotices.fulfilled, (state) => {
      state.bulkImportLoadingState = BulkImportLoadingState.idle;
    });
    builder.addCase(getNotices.rejected, (state) => {
      state.bulkImportLoadingState = BulkImportLoadingState.idle;
    });
    //#endregion
  },
});

export const { cleanUp } = bulkImportReducer.actions;
export default bulkImportReducer.reducer;
