import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { updateGroupBy } from 'src/components/Subheader/Subheader.slice';
import { fetchCanvasViewData, cleanUp } from './CanvasView.slice';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded } from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  CanvasViewComponent,
  maybeGetComponentProps,
  isSameComponentType,
} from 'src/services/configuration/codecs/confdefnComponents';
import { isListDataApi } from 'src/services/configuration/codecs/confdefnView';
import { ExtraPivotOptions, getAggBys, organizeListDataOptions } from '../StyleColorReview.slice';
import { receiveScopeConfig, receiveScopeRefreshTrigger } from 'src/components/ScopeSelector/ScopeSelector.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { updateSelectedItem } from 'src/pages/Worklist/Worklist.slice';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';

export const canvasViewLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      setActivePage.type,
      setActiveSubPage.type,
      updateSelectedItem.type,
      updateGroupBy.type,
      'LENS_CanvasView: Config loaded',
      receiveScopeConfig.type
    ),
    map(() => maybeGetComponentProps<CanvasViewComponent>(state$.value, ConfDefnComponentType.canvasView)),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ defns }) => {
      const { dataApi, model: modelDefn } = defns;
      const finalModelDefn = isListDataApi(dataApi) ? dataApi.defnId : modelDefn;

      const aggBy = getAggBys(state$.value.subheader, dataApi);
      const options: ExtraPivotOptions = { aggBy };
      const finalOptions = organizeListDataOptions(options, dataApi);

      if (isSameComponentType<CanvasViewComponent>(state$.value, ConfDefnComponentType.canvasView)) {
        console.info('navigation to same component type detected, cleaning up redux state before data load');
        return of(cleanUp(), fetchCanvasViewData(finalModelDefn, finalOptions));
      }

      return of(fetchCanvasViewData(finalModelDefn, finalOptions));
    })
  );
};
