import { LensProp } from '../TargetSetting/TargetSetting.types';
import { PeriodFormReduxSlice } from '../DatePicking/PeriodForm.types';
import { FloorsetReduxSlice } from '../TargetCreation/FloorsetSelector';
import { CriteriaReduxSlice, CriteriaOption } from '../Criteria/Criteria.types';
import { Getter, Lens } from 'monocle-ts';
import { ScopeSelectorSlice } from 'src/components/ScopeSelector/ScopeSelector.slice';
import { TargetType, TargetVersion } from 'src/pages/AssortmentStrategy/TargetSetting/Criteria/Criteria.client';
import { AppState } from 'src/store';
import { FloorsetConfigResponse } from 'src/types/Scope';
import { DataApiConfig } from 'src/services/configuration/codecs/confdefnView';

export type LevelOptions = {
  dataApi: DataApiConfig;
  dataIndex: string;
  text: string;
  type: 'level' | 'attribute';
};

export type TargetCreationViewDefns = {
  dataApi: string;
  apiRoot: string;
  targetLevelOptions: LevelOptions[];
  topLevelOptions: LevelOptions[];
};

export type SeedPageSlice = {
  criteria: CriteriaReduxSlice;
  weeks: PeriodFormReduxSlice;
  amount?: number;
};

export type SelectedTypes = {
  sales?: SelectedStartEnd;
  receipt?: SelectedStartEnd;
};

export type SelectedStartEnd = {
  start?: string;
  end?: string;
};

export type TargetSettingNewSlice = {
  targetReceipt: PeriodFormReduxSlice;
  targetSales: PeriodFormReduxSlice;
  lyReceipt: PeriodFormReduxSlice;
  lySales: PeriodFormReduxSlice;
  topLevelCriteria: CriteriaReduxSlice;
  targetLevelCriteria: CriteriaReduxSlice;
  seedPage: SeedPageSlice;
  targetSelectedFloorset: FloorsetReduxSlice;
  floorsetConfig: FloorsetConfigResponse;
  lyFloorsetConfig: FloorsetConfigResponse;
  targetSelectedDates: SelectedTypes;
  lySelectedDates: SelectedTypes;
  lySelectedFloorset: FloorsetReduxSlice;
  productId: string;
  locationId: string;
  apiRoot: string;
  dataApi: string;
};
export type OwnProps = LensProp<AppState, TargetSettingNewSlice> & {
  scopeLens: Getter<AppState, ScopeSelectorSlice>;
  targetType: TargetType;
  showOnlySeed?: boolean;
  closeModal: () => void;
};

export function createEmptySlice(): TargetSettingNewSlice {
  return {
    targetReceipt: {
      loaded: false,
      rangeList: [],
      daysRangeList: {
        end_date: {},
        start_date: {},
      },
    },
    targetSales: {
      loaded: false,
      rangeList: [],
      daysRangeList: {
        end_date: {},
        start_date: {},
      },
    },
    lyReceipt: {
      loaded: false,
      rangeList: [],
      daysRangeList: {
        end_date: {},
        start_date: {},
      },
    },
    lySales: {
      loaded: false,
      rangeList: [],
      daysRangeList: {
        end_date: {},
        start_date: {},
      },
    },
    topLevelCriteria: {
      options: [],
      selections: [],
    },
    targetLevelCriteria: {
      options: [],
      selections: [],
    },
    seedPage: {
      criteria: {
        options: [],
        selections: [],
      },
      weeks: {
        loaded: false,
        rangeList: [],
        daysRangeList: {
          end_date: {},
          start_date: {},
        },
      },
    },
    targetSelectedFloorset: {},
    targetSelectedDates: {},
    lySelectedDates: {},
    lySelectedFloorset: {},
    productId: '',
    locationId: '',
    apiRoot: '',
    floorsetConfig: [],
    lyFloorsetConfig: [],
    dataApi: '',
  };
}
type SelectedFloorset = {
  selectedFloorset?: string | undefined;
};
export type ValueProps = {
  showSeedWeeks: boolean;
  amount?: number;
  allowProgress?: boolean;
  targetReceiptStartWeek?: string | undefined | null;
  targetSalesStartWeek?: string | undefined | null;
  lyReceiptStartWeek?: string | undefined | null;
  lySalesStartWeek?: string | undefined | null;
  topLevelSelections: CriteriaOption[];
  targetLevelSelections: CriteriaOption[];
  selectedFloorset: SelectedFloorset;
};
export type FunctionProps = {
  onShowView?: () => void;
  onDestroy?: () => void;
  getSeedOptions: (dataIndex: string) => CriteriaOption[];
  voidFloorsetDropdown: (floorsetLens: Lens<AppState, FloorsetReduxSlice>) => void;
  updateAmount?: (amount: number | undefined) => void;
  setConfigurations?: (configurations: any) => void;
  checkIfTargetExists?: (id: string, version: TargetVersion, type: TargetType) => Promise<boolean | string>;
};
export interface Props extends ValueProps, FunctionProps, OwnProps {}
