import { forEach, get, isNil, has, defaultTo, set } from 'lodash';
import {
  ClientActionHandlersConfig,
  ClientActionHandler,
  ConfigurableGridConfigItem,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.types';
import { CLASS_ID, STYLE_NAME, STYLE_DESCRIPTION, CLASS_NAME, SUBCLASS_NAME } from 'src/utils/Domain/Constants';
import { CellEditingStartedEvent, ColDef, ColGroupDef, Column, GridApi, MenuItemDef, RowNode } from 'ag-grid-community';
import { BasicItem } from 'src/types/Scope';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { ConfigurablePostAction } from 'src/services/configuration/codecs/viewdefns/general';
import { AsyncCellState } from '../ConfigurableGrid.types';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

export function parseActions(config: ClientActionHandlersConfig): ClientActionHandler {
  const retObj: ClientActionHandler = {};

  forEach(config, (actionValue, _action) => {
    // at this point the name of the action doesn't really matter only the clientHandler name and value (string[])
    forEach(actionValue, (attributesToHandle, clientHandler) => {
      retObj[clientHandler] = attributesToHandle;
    });
  });

  return retObj;
}

export function isGroupColDef(colDef: ColDef | ColGroupDef): colDef is ColGroupDef {
  return has(colDef, 'groupId');
}

export function isGroupNode(node: RowNode): boolean {
  return !isNil(node.allChildrenCount) && node.allChildrenCount > 0;
}

function isRowNode(rn: unknown): rn is RowNode {
  const typedRowNode = rn as RowNode;
  return 'parent' in typedRowNode && 'data' in typedRowNode;
}

export function updateWithClientHandler(field: string, clientHandlers: ClientActionHandler | undefined): boolean {
  if (isNil(clientHandlers)) {
    return false;
  }

  let handleWithClientHandler = false;
  forEach(clientHandlers, (attributesToHandle, _clientHandler) => {
    if (attributesToHandle.indexOf(field) >= 0) {
      handleWithClientHandler = true;
    }
  });

  return handleWithClientHandler;
}

export function replaceExtraProps(str: string): string {
  switch (str) {
    case STYLE_NAME:
      return 'name';
    case STYLE_DESCRIPTION:
      return 'description';
    case CLASS_ID:
      return 'class';
    case CLASS_NAME:
      return 'ccclassname';
    case SUBCLASS_NAME:
      return 'ccpowerdrivername';
    default:
      return str
        .replace('attribute:', '')
        .replace('member:', '')
        .replace(':id', '')
        .replace(':name', '');
  }
}

export function calculateColumnWidth(dataIndex: string): number {
  switch (dataIndex) {
    case 'popoverTrigger':
      return 60;
    case 'attribute:ccstylecolorcreatedate:id':
      return 215;
    default:
      return 200;
  }
}

export function resetAsyncValidationData(event: CellEditingStartedEvent) {
  const { node } = event;
  if (has(node.data, 'invalid:style')) {
    const resetData = { ...node.data };
    delete resetData['invalid:style'];
    node.setData(resetData);
  }
}

export const floorsetDataToDropdown = (floorsetData: BasicItem): TenantConfigViewItem => {
  return {
    ...floorsetData,
    dataIndex: floorsetData.id,
    text: floorsetData.name,
  };
};

export const parseFloorsetDropdownData = (obj: BasicItem[]) => {
  // Currently, floorset returns data that needs to be mutated (id => dataIndex and name => text) for subheader
  return obj.map(floorsetDataToDropdown);
};

/** Converts an action from ConfigurablePostAction shape to MenuItemDef shape */
export const formatConfigurableActionItem = (
  action: ConfigurablePostAction,
  actionHandler: (updateType: 'coarse' | 'granular', value: unknown, valueDataIndex: string) => void
): MenuItemDef => {
  const { name, icon, tooltip, updateType, value, valueDataIndex } = action;
  return {
    name,
    icon,
    tooltip,
    action: actionHandler.bind(null, updateType, value, valueDataIndex),
  };
};

export function refreshGridCells(
  gridApi: GridApi,
  rowNodes: RowNode[],
  columns: (string | Column)[],
  options?: { forceRefresh: boolean }
) {
  const force = defaultTo(options?.forceRefresh, true);
  gridApi.refreshCells({
    rowNodes,
    columns,
    force,
  });
}

function setGroupNodeAsyncState(node: RowNode, fields: string[], state: AsyncCellState, updateChildren: boolean): void {
  fields.forEach((field) => {
    set(node, `data.asyncstate.${field}`, state);
  });
  if (updateChildren) {
    node.allLeafChildren.forEach((leafNode) => {
      fields.forEach((field) => {
        set(leafNode, `data.asyncstate.${field}`, state);
      });
    });
  }
}

function setRowNodeAsyncState(node: RowNode, fields: string[], state: AsyncCellState, updateChildren: boolean): void {
  fields.forEach((field) => {
    set(node, `data.asyncstate.${field}`, state);
  });
  if (updateChildren) {
    node.childrenAfterFilter.forEach((childNode) => {
      fields.forEach((field) => {
        set(childNode, `data.asyncstate.${field}`, state);
        childNode.setDataValue(`asyncstate.${field}`, state);
      });
    });
  }
}

function setItemAsyncState(
  item: BasicPivotItem,
  fields: string[],
  state: AsyncCellState,
  updateChildren: boolean
): void {
  fields.forEach((field) => {
    set(item, `asyncstate.${field}`, state);
  });
  if (updateChildren) {
    item.children.forEach((childItem) => {
      fields.forEach((field) => {
        set(childItem, `asyncstate.${field}`, state);
      });
    });
  }
}

export function updateNodeAsyncState(
  node: RowNode | BasicPivotItem,
  field: string[],
  state: AsyncCellState,
  options?: { updateNodeChildren: boolean }
) {
  const updateChildren = defaultTo(options?.updateNodeChildren, false);
  if (isRowNode(node)) {
    if (isGroupNode(node)) {
      setGroupNodeAsyncState(node, field, state, updateChildren);
    } else {
      setRowNodeAsyncState(node, field, state, updateChildren);
    }
  } else {
    setItemAsyncState(node, field, state, updateChildren);
  }
}

export function getCellAsyncState(node: RowNode, colInfo: ConfigurableGridConfigItem) {
  return get(node.data, `asyncstate.${colInfo.dataIndex}`, AsyncCellState.Idle);
}
