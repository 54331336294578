import React from 'react';
import { StateProps, DispatchProps } from './ScopeSelector.container';
import styles from './ScopeSelector.styles';
import DimensionForm from './DimensionForm/DimensionForm.container';
import AssortmentPeriodForm from './AssortmentPeriodForm/AssortmentPeriodForm.container';
import HindsightingPeriodForm from './HindsightingPeriodForm/HindsightingPeriodForm.container';
import { Overlay } from 'src/common-ui';
import Modal from '@material-ui/core/Modal';
import { match, Redirect } from 'react-router-dom';
import { HasPerspectives } from 'src/services/configuration/service';
import { Perspective } from 'src/services/configuration/bindings.types';
import { classes } from 'typestyle';

export type ScopeSelectorProps = StateProps & DispatchProps & RoutingProps;

export type RoutingProps = {
  match: match<{ perspective: Perspective }>;
  location: Location;
};

export type State = {};

function parseHashSearchQuery(hash: string) {
  const searchInHash = hash.indexOf('?') > -1 ? hash.substr(hash.indexOf('?') + 1) : '';
  const params = new URLSearchParams(searchInHash);
  return params;
}

export default (config: HasPerspectives) =>
  class ScopeSelector extends React.Component<ScopeSelectorProps, State> {
    constructor(props: ScopeSelectorProps) {
      super(props);
    }

    componentDidMount() {
      this.props.onRequestScope();
    }

    onSubmitClicked = (_event: React.MouseEvent<HTMLElement>) => {
      this.props.onSubmitScope(this.props.scope);
    };

    onCloseModal = () => {
      const { start, historyStart, productMember, locationMember } = this.props.selections;
      // gate this a little bit here so it doesnt fire so much
      if (start || historyStart || productMember || locationMember) {
        // SPIKE: this runs whenever the backdrop is clicked.  Does it need to?
        this.props.onRequestScope();
      }

      if (this.props.scope.valid) {
        this.props.onCloseScope();
      }
    };

    render() {
      const { scopeSelected, initialLoad, scopeLoading } = this.props;
      if (scopeSelected) {
        const params = parseHashSearchQuery(window.location.hash);
        const usher = params.get('usher');
        if (usher) {
          return <Redirect to={usher} />;
        }
      }
      const perspective = this.props.match.params.perspective;
      const boundPerspective = config.getPerspectives().find((p) => p.pathSlot === perspective);
      if (!boundPerspective || initialLoad) {
        return null;
      }

      return (
        <Modal
          open={!scopeSelected}
          onClose={this.onCloseModal}
          style={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className={styles.scopeSelector}>
            <div className="scopeArrow" />
            <div className="scopeArrowLoading" style={{ display: scopeLoading ? 'inherit' : 'none' }} />
            <Overlay visible={scopeLoading} type="loading" />
            <header>Select Scope</header>
            <div className={classes(styles.formDiv, styles.scopeSelector)}>
              <DimensionForm perspective={boundPerspective.id} />
              <AssortmentPeriodForm perspective={boundPerspective.id} />
              <HindsightingPeriodForm />
              <div className={styles.scopeColumn}>
                <button
                  onClick={this.onSubmitClicked}
                  className={classes('btn btn-primary', styles.selectScopeButton)}
                  data-qa="button-scopeselectorsubmit"
                >
                  <i className="far fa-arrow-alt-circle-right" />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    }
  };
