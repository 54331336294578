import { CartCard } from 'src/common-ui/components/CartCard/CartCard';
import * as React from 'react';

import { Dialog, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { default as Grid } from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import LifecycleStoreModal, { LifecycleStoreData } from 'src/components/LifecycleStoreModal/LifecycleStoreModal';
import { Overlay } from 'src/common-ui';
import SubheaderDropdown from 'src/components/Subheader/SubheaderDropdown';
import { RouteComponentProps } from 'react-router-dom';


import { CartItem, ColorSwatchItem } from './AssortmentCart.types';
import ColorsEdit from './ColorsEdit/ColorsEdit';
import styles from './AssortmentCart.styles';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { getSwatchUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { toast } from 'react-toastify';
import ServiceContainer from 'src/ServiceContainer';
import container from 'src/ServiceContainer';
import { AssortmentCartValueProps } from './AssortmentCart.container';
import { ViewApiConfig } from '../AssortmentBuild/StyleEdit/StyleEdit.types';
const SensitiveLifecycleStoreModal = makeScopeAndFilterSensitive(LifecycleStoreModal);
export type Powerdriver = {
  id: string;
  dataIndex: string;
  style: string;
  styleColor: string;
  text: string;
};

interface FunctionProps {
  removeItem: (item: CartItem) => void;
  duplicateItem: (item: CartItem) => void;
  changeItemDescription: (item: CartItem, description: string) => void;
  changeItemName: (item: CartItem, name: string) => void;
  changeItemSwatches: (item: CartItem, swatches: ColorSwatchItem[]) => void;
  onShowView?: (department: string) => void;
  removeAllItems: () => void;
  saveItems: (lifecycleStoreData: LifecycleStoreData | undefined, path: string) => Promise<void>;
  getStyleColor: (styleId: string) => void;
  getStyleColorPlaceholder: (styleId: string) => void;
  onRefetchData: () => void;
  addItemFromStyle: (styleId: string, activePowerdriverIndex: number) => void;
  changeItemPowerdriver: (item: CartItem, id: string, index: number) => void;
}

interface Props extends AssortmentCartValueProps, FunctionProps,  RouteComponentProps<Record<string, string>> { }

// eslint-disable-next-line prettier/prettier
export type { FunctionProps as AssortmentCartFunctionProps, Props as AssortmentCartProps };

interface AssortmentAddButton {
  buttons: {
    label: string;
    hashRoute: string;
  };
};
interface SensitiveLifecycleModal {
  lifecycleReference: {
    title: string;
    lifecycleConfig: ViewApiConfig;
    dataApiLifecycle: ViewApiConfig;
    storeConfig: ViewApiConfig;
    dataApiStore: ViewApiConfig;
    dependentsApi: ViewApiConfig | undefined;
  };
}
type AssortmentAddViewDefn = AssortmentAddButton & SensitiveLifecycleModal;
interface State {
  cartItemCount: number;
  editModalOpen: boolean;
  selectedItemInd: null | number;
  lifecycleStoreData?: LifecycleStoreData;
  addLoading: boolean;
  activePowerdriverIndex: number;
  addPosting: boolean;
  assortmentButton: AssortmentAddButton['buttons']
  lifecycleModal?: SensitiveLifecycleModal['lifecycleReference'];
}
export class AssortmentCart extends React.Component<Props, State> {
  modalRef: React.RefObject<HTMLElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      cartItemCount: 0,
      editModalOpen: false,
      selectedItemInd: null,
      lifecycleStoreData: undefined,
      addLoading: false,
      activePowerdriverIndex: 0,
      addPosting: false,
      assortmentButton: {label : '', hashRoute: ''},
    };
    this.modalRef = React.createRef();
  }

 componentDidMount() {
    // If items are being loaded into cart
    // 1. Adds items to be added to items in cart
    // 2. Adds the loading component
    // 3. Add the expected cart size to state
    container.tenantConfigClient
    .getTenantViewDefn<AssortmentAddViewDefn>({
      defnId: this.props.defns.view[0],
      appName: ASSORTMENT,
    })
    .then((resp) => {
      this.setState({
        assortmentButton: resp.buttons,
        lifecycleModal: resp.lifecycleReference,
      });
    });
    const { cartItemsToAddCount, items } = this.props;
    if (cartItemsToAddCount) {
      const initialCount: number = items.length;
      const cartSize: number = cartItemsToAddCount + initialCount; // 1.
      this.setState({
        addLoading: true, // 2.
        cartItemCount: cartSize, // 3.
      });
    }
    if (this.props.onShowView) {
      this.props.onShowView(this.props.department);
    }
  }


  componentDidUpdate() {
    // Checks if cart items are loaded
    // 1. The loaded items
    // 2. The expected number of items in cart
    // 4. Removes loading component
    if (this.props.cartItemsToAddCount === 0 && this.state.addLoading && !this.state.addPosting) {
      this.setState({
        addLoading: false, // 4.
      });
    }
  }

  renderModal = () => {
    let container;
    let title = '';
    if (this.state.selectedItemInd != null) {
      const item: CartItem = this.props.items[this.state.selectedItemInd];
      title = `${item.name} - ${item.description} - ${item.type}`;
      container = (
        <ColorsEdit
          title={title}
          item={item}
          portalRef={this.modalRef}
          canAddNewStyles={this.props.canAddNewStyles !== false}
          colors={this.props.colors}
          getStylePlaceholder={() => ''}
          placeholderSrc={item.imgSrc}
          closeModal={() => {
            this.setState({
              editModalOpen: false,
              selectedItemInd: null,
            });
          }}
          changeSwatches={(swatches: ColorSwatchItem[]) => {
            this.props.changeItemSwatches(item, swatches);
            this.setState({
              editModalOpen: false,
              selectedItemInd: null,
            });
          }}
        />
      );
    }
    return (
      <Dialog
        innerRef={this.modalRef}
        open={this.state.editModalOpen}
        onClose={() => {
          this.setState({
            editModalOpen: false,
            selectedItemInd: null,
          });
        }}
      >
        {container}
      </Dialog>
    );
  };

  onLifecycleSubmit = (data: LifecycleStoreData) => {
    this.setState({
      lifecycleStoreData: data,
    });
  };

  anyItemsInvalid = () => {
    return this.props.items.find((item) => !item.valid) != null;
  };

  clickPowerdriverAdd = () => {
    const { powerdrivers } = this.props;
    const { activePowerdriverIndex } = this.state;

    if (this.props.addItemFromStyle) {
      this.props.addItemFromStyle(powerdrivers[activePowerdriverIndex].style, activePowerdriverIndex);
    }
  };

  private addToAssortment = (): void => {
    this.setState({
      addLoading: true,
      addPosting: true,
    }, () => {
      ServiceContainer.loggingService.info(`User is attempting to add these items to the assortment:
      ${JSON.stringify(this.props.items)}
      and this lifecylce: ${JSON.stringify(this.state.lifecycleStoreData?.lifecycleData)}`)
      const saveItemsPromise = this.props.saveItems(this.state.lifecycleStoreData, this.state.assortmentButton.hashRoute);
      saveItemsPromise.catch((err: string) => {
        if (err && typeof err === 'string' && err === 'InvalidItems') {
          toast.info('Some of your item numbers already exists in the assortment, please adjust them and resubmit.');
        } else {
          toast.error('An error occured adding your items to the Assortment');
          ServiceContainer.loggingService.error(
            `An error occured adding these items to the Assortment: ${JSON.stringify(this.props.items)}`
          );
        }
        this.setState({
          addLoading: false,
          addPosting: false,
        });
      })
      saveItemsPromise.finally(() => {
        this.setState({
          addLoading: false,
          addPosting: false,
        });
      })
    });
  }

  render() {
    const { items, title, canAddNewStyles } = this.props;
    const totalStyleCount = items.length;
    const totalChoiceCount = items
      .map((item) => item.swatches.length)
      .reduce((total, count) => (total = total + count), 0);
    let addToAssortmentButton: JSX.Element = (
      <Button
        variant="contained"
        color="secondary"
        size="small"
        data-qa="add-to-assortment"
        style={{ color: 'white', marginBottom: 5, borderRadius: 0, width: '100%' }}
        onClick={this.addToAssortment}
        disabled={((this.anyItemsInvalid() || this.state.addLoading) || (totalStyleCount === 0)) }
      >
           {this.state.assortmentButton.label}
      </Button>
    );
    if (this.anyItemsInvalid()) {
      addToAssortmentButton = (
        <Tooltip title="Some items have previously used numbers.">
          <div>{addToAssortmentButton}</div>
        </Tooltip>
      );
    }
    return (
      <div style={{ height: '100%', overflow: 'auto', padding: '1%' }}>
        <Overlay type="loading" visible={this.state.addLoading} />
        <header className={styles.headerContainer}>
          <h3>{title}</h3>
          {canAddNewStyles && <div className={styles.buttonContainer}>
            <SubheaderDropdown
              label={'Powerdriver Add'}
              handleChangeOnDropdown={(event) => {
                this.setState({
                  activePowerdriverIndex: Number(event.target.value),
                });
              }}
              options={this.props.powerdrivers}
              selection={this.state.activePowerdriverIndex}
            />
            <span className="right-container">
              <i className={`far fa-plus-circle ${styles.addIcon}`} onClick={() => this.clickPowerdriverAdd()} />
            </span>
          </div>}
        </header>
        <div className={styles.cartContainer}>
          <Grid spacing={2} className={styles.cartHeader}>
            <Grid item={true} xs={7} className={styles.headerStyleText}>
              <span>STYLE</span>
            </Grid>
            <Grid container={true} xs={5} spacing={2} className={styles.headerCountText}>
              <span>CHOICE COUNT</span>
            </Grid>
          </Grid>
          <Grid className={''} container={true} spacing={0}>
            <Grid item={true} md={9}>
              {this.props.items.map((item, ind) => {
                return (
                  <div className={styles.cardContainer} key={item._id}>
                    <CartCard
                      id={item.name}
                      description={item.description}
                      imgSrc={item.imgSrc}
                      swatches={item.swatches}
                      editable={item.type !== 'existing'}
                      valid={item.valid}
                      noImageUrl={item.imgSrc}
                      onChangeId={(t) => {
                        if (item.name !== t) {
                          this.props.changeItemName(item, t.toString());
                        }
                      }}
                      onChangeDescription={(t) => {
                        this.props.changeItemDescription(item, t.toString());
                      }}
                      onColorEdit={() => {
                        this.setState({
                          editModalOpen: true,
                          selectedItemInd: ind,
                        });
                      }}
                      onDuplicateColor={() => this.props.duplicateItem(item)}
                      onColorRemove={() => this.props.removeItem(item)}
                      getSwatchUrl={getSwatchUrl}
                      canAddNewStyles={canAddNewStyles}
                    ></CartCard>
                  </div>
                );
              })}
            </Grid>
          </Grid>
          <div className={styles.summaryContainer}>
            <div className={styles.summaryBackground}>
              <div className={styles.summaryHeader}>SUMMARY</div>
              <Divider />
              <div className={styles.summaryBody}>
                <div className={styles.summaryContent}>
                  <div className={styles.summaryListItems}>
                    <Grid item={true} md={12}>
                      <Grid container={true} direction="row" justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item={true} md={9}>
                          <div>Total Style Count:</div>
                        </Grid>
                        <Grid item={true} md={3}>
                          {totalStyleCount}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item={true} md={12}>
                      <Grid container={true} direction="row" justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item={true} md={9}>
                          <div>Total Choice Count:</div>
                        </Grid>
                        <Grid item={true} md={3}>
                          {totalChoiceCount}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className={styles.summaryContent}>
                  <div className={styles.summaryListActions}>
                    {addToAssortmentButton}
                    <Button
                      color="secondary"
                      size="small"
                      data-qa="cancel_add-to-assortment"
                      onClick={this.props.removeAllItems}
                    >
                      CANCEL
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.reviewAssortmentRulesContainer}>
              {this.state.lifecycleModal && (<SensitiveLifecycleStoreModal
                onSubmit={this.onLifecycleSubmit}
                textToDisplay={this.state.lifecycleModal.title}
                dataApiLifecycle={this.state.lifecycleModal.dataApiLifecycle}
                dataApiStore={this.state.lifecycleModal.dataApiStore}
                lifecycleConfig={this.state.lifecycleModal.lifecycleConfig}
                storeConfig={this.state.lifecycleModal.storeConfig}
                dependentsApi={this.state.lifecycleModal.dependentsApi}
              />)
              }
            </div>
          </div>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}
