import React, { Component } from 'react';
import { isNil } from 'lodash';
import { classes } from 'typestyle';

import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { getStyleAttributes } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import { CC_COLOR, ISASSORTMENT } from 'src/utils/Domain/Constants';
import { AssortmentClient } from 'src/pages/AssortmentBuild/Assortment.client';
import {
  createSectionElement,
  SectionColorHeader,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSections/StyleEditSections.utils';
import { StyleEditViewSection } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';

import styleEditStyles from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.styles';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';

type StyleEditSectionsProps = {
  sectionsConfig: StyleEditViewSection[];
  styleData?: BasicPivotItem;
  filteredStyleColors: BasicPivotItem[];
  existingStyleColors: BasicPivotItem[];
  sortedStyleColorIds: string[];
  removableStyleColors: string[];
  showOnlyPreexist?: boolean;
  /**
   * This callback is used by the children to tell sections to increment it's state.currentRefreshCount
   * Which is the signal for all child sections to refresh their data
   */
  onRefresh: (refreshCompanion?: boolean) => void;
  /**
   * This is used to track children sections requesting cache clearing and data re-fetching to all sections
   */
  onSectionChange: (isExpanded: boolean, isColorHeaderNeeded: boolean, sectionDataLoaded: boolean) => void;
};

type StyleEditSectionsState = {
  currentPanel: string | null;
  sectionDataLoaded: boolean;
  stickyHeaderAdditionElement?: JSX.Element;
  /**
   * This is used to track children sections requesting cache clearing and data re-fetching to all sections
   */
  currentRefreshCount: number;
  lastEditedSection: string | null;
};

export class StyleEditSections extends Component<StyleEditSectionsProps, StyleEditSectionsState> {
  constructor(props: StyleEditSectionsProps) {
    super(props);

    this.state = {
      currentPanel: null,
      sectionDataLoaded: false,
      currentRefreshCount: 0,
      lastEditedSection: null,
    };
  }

  getExistingChild = (colorId: string) => {
    const { styleData, existingStyleColors } = this.props;
    if (isNil(styleData)) {
      return null;
    }

    return existingStyleColors.find((child) => child[CC_COLOR] === colorId);
  };

  onRefetchData = () => {
    this.refreshSections(null);
  };

  refreshSections = (componentId: string | null) => {
    // Adding a componentId makes styleEdit not refresh that section
    this.setState({ currentRefreshCount: this.state.currentRefreshCount + 1, lastEditedSection: componentId || null });
  };

  addColor = async (selectedNewColor: string, copyFrom: BasicPivotItem): Promise<void> => {
    const { styleData } = this.props;

    if (isNil(styleData)) {
      return;
    }

    let type: 'existing' | 'similar';
    let toCopy: BasicPivotItem;
    const existingChild = this.getExistingChild(selectedNewColor);

    if (!isNil(existingChild)) {
      toCopy = existingChild;
      type = 'existing';
    } else {
      toCopy = await getStyleAttributes(copyFrom.id);
      type = 'similar';
    }

    const newItem = {
      ...toCopy,
      name: `${styleData.name}: ${selectedNewColor}`,
      description: `${styleData.description} ${selectedNewColor}`,
      colorId: selectedNewColor,
      [ISASSORTMENT]: 'false',
      [CC_COLOR]: selectedNewColor,
      type,
    };

    const toSend = {
      ...styleData,
      type: 'existing',
      children: [
        {
          ...newItem,
        },
      ],
    };
    await AssortmentClient.addItemsToAssortment({ items: [toSend] });
    this.props.onRefresh(); // TODO: check this behaves the same as below
    // await this.getData();
  };

  handlePanelChange(newPanel: string, isColorHeaderNeeded: boolean) {
    const lastPanel = this.state.currentPanel;
    const panelClosing = lastPanel === newPanel;
    const panel = panelClosing ? null : newPanel;

    const isExpanded = !isNil(panel);
    this.props.onSectionChange(isExpanded, isColorHeaderNeeded, panelClosing);

    this.setState({
      currentPanel: panel,
      sectionDataLoaded: panelClosing,
    });
  }

  render() {
    const { sectionsConfig, styleData } = this.props;
    if (isNil(styleData)) {
      return <div />;
    }

    const { currentPanel, stickyHeaderAdditionElement } = this.state;

    return sectionsConfig.map((sectionConfig, index) => {
      const panelKey = `stylecolor_panel${index}_${sectionConfig.text}`;
      const expanded = currentPanel === panelKey;
      const isColorHeaderNeeded =
        sectionConfig.componentType === 'column_grid' || sectionConfig.componentType === 'column_multi_grid';
      return (
        <ExpansionPanel
          data-qa={'StyleEditSectionPanel'}
          data-qa-key={index}
          expanded={expanded}
          onChange={() => this.handlePanelChange(panelKey, isColorHeaderNeeded)}
          key={panelKey}
          elevation={2}
          TransitionProps={{ timeout: 0 }}
        >
          <ExpansionPanelSummary
            expandIcon={<i className={`fas fa-angle-down`} />}
            className={classes(
              expanded
                ? classes(styleEditStyles.stickySection, styleEditStyles.stickyHeaderArrowExpanded)
                : styleEditStyles.stickyHeaderArrow
            )}
          >
            <span className={styleEditStyles.sectionText}>{sectionConfig.text}</span>
            {expanded && isColorHeaderNeeded && <SectionColorHeader stickyElement={stickyHeaderAdditionElement} />}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            className={classes(
              styleEditStyles.scrollableSectionDetails,
              expanded && isColorHeaderNeeded ? styleEditStyles.scrollableSectionDetailsExpanded : ''
            )}
          >
            {createSectionElement(
              this,
              sectionConfig,
              styleData,
              expanded,
              this.refreshSections,
              this.state.currentRefreshCount,
              this.state.lastEditedSection
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
  }
}

const sensitiveStyleEditSections = makeScopeAndFilterSensitive(StyleEditSections);
export default sensitiveStyleEditSections;
