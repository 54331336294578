import Axios from 'src/services/axios';
import * as React from 'react';
import { isNil, isEmpty, cloneDeep, isBoolean, isNumber, noop, isEqual, isArray, pickBy } from 'lodash';

import { StyleEditViewMultiSection } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { getUrl, getViewProm } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import ServiceContainer from 'src/ServiceContainer';
import { ViewPortSensitiveStyleEditSection } from './StyleEditSection';
import { updateRangingParams, getValidValues } from './StyleEditSection.client';
import { FUNDED, SPECIAL_STORE_GROUP, COLOR_HEADER, TIME, STORE_COUNT } from 'src/utils/Domain/Constants';
import Overlay from 'src/common-ui/components/Overlay/Overlay';
import { AssortmentClient } from '../../Assortment.client';
import {
  HeaderData,
  StyleEditConfigColumn,
  FloorsetsResponseData,
  FloorsetData,
  StyleEditMultiSectionProps,
  StyleEditMultiSectionState,
  ColorHeaderConfigColumn,
} from './StyleEditSection.types';
import { maybeReturnNestData } from 'src/utils/Http/NestedDatas';
import { toast } from 'react-toastify';
import LocalPivotServiceContext from 'src/components/StylePreview/PivotServiceContext';
import cuid from 'cuid';

function _convertBooleanToNumber(a: boolean | number): number {
  if (isBoolean(a)) {
    return a ? 1 : 0;
  } else {
    return a;
  }
}

function modifySection(
  selection: any,
  sectionData: any,
  propagatingIndices: string[],
  calculatedStoreCount: number | null
) {
  const { dataIndex, newValue } = selection;
  const modified = cloneDeep(sectionData);

  if (dataIndex === SPECIAL_STORE_GROUP) {
    // ssg selection 'blanks' out all other propagating indices
    if (!isNil(newValue)) {
      modified[SPECIAL_STORE_GROUP] = [newValue];

      propagatingIndices.forEach((attr) => {
        if (attr !== SPECIAL_STORE_GROUP) {
          modified[attr] = [];
        }
      });
    }
  } else if (dataIndex === STORE_COUNT) {
    modified[STORE_COUNT] = newValue;
  } else if (dataIndex !== FUNDED) {
    // any other selection blanks out ssg selection, except for funded
    modified[dataIndex] = newValue;

    modified[SPECIAL_STORE_GROUP] = [];
    modified[STORE_COUNT] = isNil(calculatedStoreCount) ? 0 : calculatedStoreCount;
  } else {
    // just update funded value
    modified[FUNDED] = newValue;
  }

  return modified;
}

export default class StyleEditMultiSection extends React.Component<
  StyleEditMultiSectionProps,
  StyleEditMultiSectionState
> {
  static contextType = LocalPivotServiceContext;
  context!: React.ContextType<typeof LocalPivotServiceContext>;
  componentId = cuid();

  constructor(props: StyleEditMultiSectionProps) {
    super(props);

    this.state = {
      multiGridSections: [],
      sectionsData: [],
      propagatingIndices: [],
      skipAutoFillColumns: [],
      validValues: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getDataAndConfig();
  }

  componentDidUpdate(prevProps: StyleEditMultiSectionProps) {
    if (prevProps.parentId !== this.props.parentId) {
      this.getDataAndConfig();
    }
    if (
      this.props.currentRefreshCount > prevProps.currentRefreshCount &&
      this.props.lastEditedSection != this.componentId
    ) {
      this.getDataAndConfig();
    }
  }

  async getDataAndConfig() {
    // trigger the loader display
    if (this.state.isLoading) {
      return;
    }
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { dataApi, configApi } = this.props;
        let dataProm: Promise<any>;

        if (dataApi.isListData) {
          dataProm = ServiceContainer.pivotService
            .listData(dataApi.defnId, 'Assortment', dataApi.params)
            .then((pivotResponse) => pivotResponse.flat);
        } else {
          const dataUrl = getUrl(dataApi);
          dataProm = Axios.get(dataUrl).then((apiResponse) => apiResponse.data.data);
        }

        const queue: Promise<any>[] = [dataProm, getViewProm(configApi)];

        const resp = await Promise.all(queue);
        let dataItems: FloorsetsResponseData | FloorsetData[][] = resp[0];
        const config = maybeReturnNestData(resp[1]) as any;
        const sections: StyleEditViewMultiSection[] = [];
        const sectionsData: FloorsetData[][] = [];

        // retrieve validValues for all items in config with dataApi upfront (data will be cached)
        const validValueUrls = config.columns
          ?.filter(
            (column: StyleEditConfigColumn) => column.dataIndex !== SPECIAL_STORE_GROUP && !isNil(column.dataApi)
          )
          .map((column: StyleEditConfigColumn) => {
            return getValidValues(column.dataApi.url)
              .then((values) => values.map((value: any) => value.value))
              .then((values: any) => ({ [column.dataIndex]: values })); // store with dataIndex for easy lookup
          });

        const validValues = await Promise.all(validValueUrls);
        let anyDataChanged = false;
        if (!isArray(dataItems)) {
          dataItems = Object.values(dataItems);
        }
        await Promise.all(
          dataItems.map((dataItem) => {
            // auto-fill on section load
            const autoFilledData: FloorsetData[] = this.autoFillSectionsData(
              dataItem,
              validValues,
              config.propagatingIndices || [],
              config.skipAutoFillColumns || []
            );
            if (!isEqual(dataItem, autoFilledData)) {
              anyDataChanged = true;
            }
            sectionsData.push(autoFilledData);
            sections.push({
              componentType: 'column_grid',
              text: dataItem[0].time,
              config,
              data: autoFilledData,
            });
          })
        );
        if (anyDataChanged) {
          this.saveAutoFilledData(sectionsData);
        }
        this.setState({
          multiGridSections: sections,
          sectionsData,
          propagatingIndices: config.propagatingIndices || [],
          skipAutoFillColumns: config.skipAutoFillColumns || [],
          validValues,
          isLoading: false,
        });
      }
    );
  }

  retrieveAllValues = (dataIndex: string, validValues: any[]) => {
    const value = validValues.find((validValue) => !isNil(validValue[dataIndex]));
    return value[dataIndex];
  };

  autoFillSectionsData = (
    sectionData: FloorsetData[],
    validValues: any,
    propagatingIndices: string[],
    skipAutoFillColumns: string[]
  ) => {
    const autoFilledSectionData = sectionData.map((data) => {
      return this.autoFillSectionData(data, validValues, propagatingIndices, skipAutoFillColumns);
    });

    return autoFilledSectionData;
  };

  autoFillSectionData = (
    sectionData: FloorsetData,
    validValues: any[],
    propagatingIndices: string[],
    skipAutoFillColumns: string[]
  ) => {
    const sectionDataCopy = cloneDeep(sectionData);

    // FIXME: remove,  need to clear empty string from array that is meant to be empty
    propagatingIndices
      .filter((index) => skipAutoFillColumns.indexOf(index) < 0)
      .forEach((attr) => {
        const attrData = sectionDataCopy[attr];
        if (!isEmpty(attrData) && attrData[0] === '') {
          sectionDataCopy[attr] = []; // reset to empty array
        }
      });

    const ssg = sectionDataCopy[SPECIAL_STORE_GROUP];
    propagatingIndices
      .filter((index) => skipAutoFillColumns.indexOf(index) < 0)
      .forEach((attr) => {
        if (attr === SPECIAL_STORE_GROUP || attr === COLOR_HEADER) {
          // don't want to modify ssg
          return;
        }

        const attrData = sectionDataCopy[attr];
        if (isEmpty(attrData) && isEmpty(ssg)) {
          // get valid values and set field value in data to 'all' validValues
          const allValues = this.retrieveAllValues(attr, validValues);
          sectionDataCopy[attr] = allValues;
        } else if (!isEmpty(attrData) && !isEmpty(ssg)) {
          // clear field selections
          sectionDataCopy[attr] = [];
        }
      });

    return sectionDataCopy;
  };

  handleDataPropagation = async (
    modifiedSectionIndex: number,
    selection: any,
    calculatedStoreCount: number | null = null
  ) => {
    const { sectionsData, multiGridSections, propagatingIndices, skipAutoFillColumns, validValues } = this.state;
    
    // will be updating necessary sections data property
    const updatedGridSections = cloneDeep(multiGridSections);
    const sectionToUpdate = sectionsData[modifiedSectionIndex].find(
      (sectionData: FloorsetData) => sectionData.product === selection.id
    );
    // update current (and subsequent sections only if skipForwardPropagation
    const sectionsToPropagateTo = this.props.skipForwardPropagation
      ? [sectionsData[modifiedSectionIndex]]
      : sectionsData.slice(modifiedSectionIndex);
    const modifiedAttributes = {}; // stores modified floorsets data for the stylecolor

    // modify data in current section, then autofill
    const modifiedSectionData = modifySection(selection, sectionToUpdate, propagatingIndices, calculatedStoreCount);
    const autoFilledSectionData = this.autoFillSectionData(
      modifiedSectionData,
      validValues,
      propagatingIndices,
      skipAutoFillColumns
    );

    const finalSectionData = cloneDeep(autoFilledSectionData);

    const nonSSGIndicies: string[] = propagatingIndices.filter(
      (dataIndex: string) => dataIndex !== SPECIAL_STORE_GROUP
    );

    // propagate autoFilled data to current and subsequent sections
    const propagatedSectionsData = await Promise.all(
      sectionsToPropagateTo.map(async (sectionData, sectionIndex) => {
        const updatedData = await Promise.all(
          sectionData.map(async (dataItem) => {
            if (dataItem.product !== selection.id) {
              return dataItem;
            }

            // need to keep the time attribute from the original dataItem
            let newRowData: FloorsetData;
            if (sectionIndex === 0) {
              newRowData = {
                ...dataItem,
                ...finalSectionData,
              };
            } else {
              const dataToPropagate = pickBy(finalSectionData, (_val, key) => {
                return propagatingIndices.indexOf(key) >= 0;
              });
              newRowData = {
                ...dataItem,
                ...dataToPropagate,
              };
            }
            if (nonSSGIndicies.indexOf(selection.dataIndex) >= 0) {
              // always refetch store count at each time period. (Time period affects store count results)
              newRowData[STORE_COUNT] = await AssortmentClient.calculateStoreCount(
                finalSectionData.product,
                dataItem[TIME],
                nonSSGIndicies.map((dataIndex) => {
                  return { [dataIndex]: newRowData[dataIndex] };
                })
              ).then((resp) => {
                if (isNumber(resp)) {
                  return resp;
                } else {
                  return 0;
                }
              });
            }

            return {
              ...newRowData,
              [TIME]: dataItem[TIME],
            };
          })
        );

        // find correct item to propagate to
        const propagateTo = updatedData.find((item) => item.product === selection.id);

        if (!isNil(propagateTo)) {
          // store modified dataItem to send to server
          const time = propagateTo[TIME];
          const funded = propagateTo[FUNDED];
          propagateTo[FUNDED] = _convertBooleanToNumber(funded); // convert funded to number for server processing
          modifiedAttributes[time] = propagateTo;
        }

        // update multiGridSection's data as well to render updated values
        // need to use actual grid section's index not just the sectionIndex from map
        const actualIndex = modifiedSectionIndex + sectionIndex;
        updatedGridSections[actualIndex].data = updatedData;

        return updatedData;
      })
    );

    // update state so grids can render propagated/auto-filled data
    const allSectionsData = [...sectionsData.slice(0, modifiedSectionIndex), ...propagatedSectionsData];
    this.setState({
      multiGridSections: updatedGridSections,
      sectionsData: allSectionsData,
    });

    updateRangingParams(selection.id, modifiedAttributes)
      .then(() => {
        this.context.clearPivotCache();
        this.props.refreshSections(this.componentId);
      })
      .catch((e) => {
        toast.error('An error occured updating your lifecycle parameters');
        ServiceContainer.loggingService.error(
          'An error occured updating the lifecycle parameters in Style edit lifecylce paramters',
          e.stack
        );
      });
  };

  saveAutoFilledData(sectionsData: FloorsetData[][]) {
    // for now figuring out all stylecolor ids up front - not ideal
    const products: string[] = [];
    sectionsData.forEach((sectionData) => {
      sectionData.forEach((sectionProduct) => {
        const productId = sectionProduct.product;
        if (products.indexOf(productId) < 0) {
          products.push(productId);
        }
      });
    });

    products.forEach((productId) => {
      const modifiedAttributes = {}; // stores product values for each section

      sectionsData.forEach((sectionData) => {
        const product = sectionData.find((dataItem) => dataItem.product === productId);

        if (!isNil(product)) {
          const time = product[TIME];
          modifiedAttributes[time] = product;
        }
      });

      updateRangingParams(productId, modifiedAttributes).catch((e) => {
        toast.error('An error occured updating your lifecycle parameters');
        ServiceContainer.loggingService.error(
          'An error occured updating the lifecycle parameters in Style edit lifecylce paramters',
          e.stack
        );
      });
    });
  }

  buildStyleEditMultiSection(
    conf: StyleEditViewMultiSection,
    scope: BasicPivotItem,
    headerData: HeaderData,
    multiSectionIndex: number,
    existingStyleColors: BasicPivotItem[]
  ) {
    const styleId = scope.id;
    return (
      <div style={{ paddingBottom: 25, width: '100%' }}>
        <ViewPortSensitiveStyleEditSection
          expanded={this.props.expanded}
          styleId={styleId}
          headerData={headerData}
          multiSectionData={{ ...conf }}
          multiSectionIndex={multiSectionIndex}
          multiSectionLabel={conf.text}
          propagateSelection={this.handleDataPropagation}
          parentId={styleId}
          existingStyleColors={existingStyleColors}
          sortedStyleColorIds={this.props.sortedStyleColorIds}
          getHeaderElement={this.props.getHeaderElement}
          onSectionDataLoaded={this.props.onSectionDataLoaded}
          refreshSections={this.props.refreshSections}
          currentRefreshCount={this.props.currentRefreshCount}
          lastEditedSection={this.props.lastEditedSection}
          componentIdOverride={this.componentId}
          refresh={noop}
        />
      </div>
    );
  }

  render() {
    if (!this.props.expanded) {
      // in the mounted but in un-expanded state, renderer nothing to save resources
      return <React.Fragment></React.Fragment>;
    }
    if (this.state.isLoading) {
      return <Overlay type="loading" visible={true} fitParent={true} qaKey="StyleEditMultiSectionOverlay" />;
    }

    const { scope, parentId, headerData } = this.props;
    const { multiGridSections } = this.state;

    return (
      <div>
        {multiGridSections.map((grid, index) => {
          return (
            <div key={`${parentId}-${grid.text}`}>
              {this.buildStyleEditMultiSection(grid, scope, headerData, index, this.props.existingStyleColors)}
            </div>
          );
        })}
      </div>
    );
  }
}
