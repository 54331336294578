import { createSelector } from 'reselect';
import { AppState } from 'src/store';
import { HindsightingSlice } from 'src/pages/Hindsighting/Hindsighting.reducer';
import { ExtendedPointObject } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/Charts/SimplerChart';
import { TenantConfigViewItem, GeoTrendsConfigData } from 'src/dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { cascadingFilter } from 'src/utils/Tree/ObjectArrayTree';
import { makelookBackPredicate } from 'src/utils/Pivot/Filter';
import { mapNameToDefaultUri } from 'src/utils/Component/GeoChart';
import { ReduxSlice as SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { get } from 'lodash';

type StateSelection = {
  geoTrends: HindsightingSlice['geoTrends'];
  subheader: AppState['subheader'];
};

type LoadedProjection = {
  loaded: true;
  title: string;
  mapUri: string;
  selectedItem?: ExtendedPointObject;
  selectedGeoLevel: TenantConfigViewItem;
  levelSelectConfig: GeoTrendsConfigData;
  topChartConfig: TenantConfigViewItem;
  bottomChartConfig: TenantConfigViewItem;
  chartDataLoaded: boolean;
  geoDataLoaded: boolean;
  data: BasicPivotItem[];
  chartData: BasicPivotItem[];
  flowStatus: SubheaderSlice['flowStatus'];
};

type LoadingProjection = {
  loaded: false;
  title: string;
  mapUri?: string;
};

export type StateProjection = LoadedProjection | LoadingProjection;

function selectState(state: AppState) {
  return {
    geoTrends: state.pages.hindsighting.geoTrends,
    subheader: state.subheader,
  };
}

function projectState(selection: StateSelection): StateProjection {
  const { geoTrends, subheader } = selection;

  if (geoTrends.tenantConfigLoaded && geoTrends.geoDataLoaded) {
    const { lookBackPeriod, flowStatus } = subheader;

    let data = geoTrends.geoData;
    const chartData = geoTrends.chartData;

    if (lookBackPeriod) {
      // Filter by lookBackPeriod
      data = cascadingFilter(geoTrends.geoData, makelookBackPredicate(lookBackPeriod));
    }

    return {
      title: 'Geo Trends',
      mapUri: mapNameToDefaultUri(geoTrends.levelSelectConfig!.mapUri) || '',
      loaded: true,
      selectedItem: geoTrends.selectedItem,
      selectedGeoLevel: geoTrends.selectedGeoLevel!,
      levelSelectConfig: geoTrends.levelSelectConfig!,
      topChartConfig: geoTrends.topChartConfig!,
      bottomChartConfig: geoTrends.bottomChartConfig!,
      chartDataLoaded: geoTrends.chartDataLoaded,
      geoDataLoaded: geoTrends.geoDataLoaded,
      flowStatus,
      data,
      chartData,
    };
  }
  return {
    title: 'Geo Trends',
    mapUri: mapNameToDefaultUri(get(geoTrends, 'levelSelectConfig.mapUri')), // can return undef
    loaded: false,
  };
}

export default createSelector(selectState, projectState);
