import { noCaseExactMatch } from 'src/utils/Primitive/String';
import { maybeSortPivotItems, SortBy, simpleByField, getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import { BasicPivotItem, WorklistInfo } from 'src/worker/pivotWorker.types';
import { FLOWSTATUSPROP, ID, lookBackPeriodProp } from 'src/utils/Domain/Constants';
import { Indexable } from 'src/types/Primitive';
import { isEmpty, size, isNil, has, reduce } from 'lodash';
import { GridItem, GroupHeaderKey } from '../Component/AgGrid/AgDataFormat';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { SubheaderDropdownSlice } from 'src/components/Subheader/Subheader.slice';

export type FilterSortType = {
  dataIndex: string;
  direction: 'asc' | 'desc';
};

function isFST(sortBy: SortBy | FilterSortType): sortBy is FilterSortType {
  return (sortBy as FilterSortType).dataIndex != null;
}

export function containsSearch(indexable: Indexable, search: string, keys: string[]) {
  if (isNil(search)) {
    console.warn('WARNING: Search contains nil and it shouldnt');
    return true;
  }
  if (search === '' || keys.length === 0) {
    return true;
  }

  for (const key of keys) {
    const searchee = indexable[key];

    if (isNil(searchee)) {
      console.warn('WARNING: Attempted to see if a nil item contained search.');
      continue;
    }

    if (noCaseExactMatch(searchee, search)) {
      return true;
    }
  }
  return false;
}

export function containsFlow(indexable: Indexable, flowStatus: number[]) {
  if (flowStatus.length === 0) {
    return true;
  }
  const statusValue = indexable[FLOWSTATUSPROP];

  if (statusValue !== undefined) {
    return flowStatus.find((status) => status === statusValue) !== undefined;
  }
  return false;
}

/**
 * @param search - semicolon *or* ampersand separated list of values to query on
 * @param searchKeys - keys to compare each value in search for each item in `flat`
 *
 * `search` splits on semicolons if any are present, otherwise falls back to splitting
 * on ampersand. Search values are matched across any of the `searchKey` props
 * Logic is:
 *   * semicolon: results include items where *any* value in `search` is found in `searchKeys`
 *   * ampersand: results include items where *all* values in `search` are found in any `searchKey`
 */
export function filterData(
  flat: BasicPivotItem[],
  search: string,
  searchKeys: string[],
  flowStatus: number[],
  pareDownSelections?: TenantConfigViewItem[]
): BasicPivotItem[] {
  if (Array.isArray(flat)) {
    if (search.indexOf(';') >= 0) {
      const searchInputs: string[] = [];
      search.split(';').forEach((searchInput) => {
        if (searchInput.trim() !== '') searchInputs.push(searchInput.trim());
      });
      return flat
        .filter((datum) => {
          // If searchInputs is empty, user isn't searching
          if (isEmpty(searchInputs)) {
            return containsFlow(datum, flowStatus);
          }
          return (
            searchInputs.some((input) => containsSearch(datum, input, searchKeys)) && containsFlow(datum, flowStatus)
          );
        })
        .filter((datum) => {
          if (pareDownSelections && pareDownSelections.length > 0) {
            return pareDownSelections.find((f) => datum[f.dataIndex] == f.value);
          }
          return datum;
        });
    } else {
      const searchInputs: string[] = [];
      search.split('&').forEach((searchInput) => {
        if (searchInput.trim() !== '') searchInputs.push(searchInput.trim());
      });
      return flat
        .filter((datum) => {
          // If searchInputs is empty, user isn't searching
          if (isEmpty(searchInputs)) {
            return containsFlow(datum, flowStatus);
          }
          return (
            searchInputs.every((input) => containsSearch(datum, input, searchKeys)) && containsFlow(datum, flowStatus)
          );
        })
        .filter((datum) => {
          if (pareDownSelections && pareDownSelections.length > 0) {
            return pareDownSelections.find((f) => datum[f.dataIndex] == f.value);
          }
          return datum;
        });
    }
  }
  return flat;
}

export function filterPareDown(
  flat: BasicPivotItem[],
  filterItems: TenantConfigViewItem[],
  groupBySlice: SubheaderDropdownSlice
) {
  if (Array.isArray(flat)) {
    if (filterItems.length > 0) {
      const groupingKey = getGroupBySelectedOptionProperty(groupBySlice, 'dataIndex');
      const worklistItems = flat.filter((x) => filterItems.find((f) => x[f.dataIndex] == f.value));

      if (isEmpty(groupingKey)) {
        return worklistItems;
      }

      // When grouped, group rows were being filtered out, so below handles preserving them after the pareDown filter.
      // This stores group rows and pareDown selection items to build tree list after items filtering

      // grab all the groups
      const groupItems = flat.filter((item) => item[GroupHeaderKey]);
      const filteredItemsWithGroups = reduce(
        groupItems,
        (output, groupItem) => {
          // get all refined down worklist items for this group
          const itemsInGroup = worklistItems.filter((worklistItem) => {
            return worklistItem.group.indexOf(groupItem.id) >= 0;
          });

          // if necessary return group followed by worklist items for that group
          return itemsInGroup.length > 0 ? [...output, groupItem, ...itemsInGroup] : output;
        },
        [] as BasicPivotItem[]
      );

      return filteredItemsWithGroups;
    }
  }
  return flat;
}

export function filterAndSortPivotItems(
  search: string,
  sortBy: SortBy | FilterSortType,
  searchKeys: string[],
  flowStatus: number[],
  pivotItems: BasicPivotItem[],
  pareDownSelections?: TenantConfigViewItem[]
) {
  if (isFST(sortBy)) {
    return filterData(
      simpleByField(pivotItems, (sortBy as FilterSortType).dataIndex, sortBy.direction),
      search.trim(),
      searchKeys,
      flowStatus,
      pareDownSelections
    );
  } else {
    return filterData(
      maybeSortPivotItems(sortBy as SortBy, pivotItems),
      search,
      searchKeys,
      flowStatus,
      pareDownSelections
    );
  }
}
export function mergeWorklist(item: BasicPivotItem, worklist: WorklistInfo[]) {
  if (worklist.length == 0) {
    return item;
  }

  const foundWorklist = worklist.find((w) => w.product == (item[ID] || (item as GridItem).stylecolor));
  let newWorklist = item['worklist_type'];

  if (foundWorklist) {
    newWorklist = foundWorklist.type;
  } else if (size(newWorklist) > 0 && newWorklist !== 'hasexceptions') {
    // we skip 'hasexceptions' as that is separate from the worklist hold
    newWorklist = '';
  }

  return {
    ...item,
    ['worklist_type']: newWorklist,
  };
}
export function mergeWorklistForSort(items: BasicPivotItem[], worklist: WorklistInfo[]) {
  return items.map((item) => {
    // skip group rows from worklist merge logic
    return has(item, GroupHeaderKey)
      ? item
      : {
          ...mergeWorklist(item, worklist),
        };
  });
}

export const makelookBackPredicate = (lookBackPeriod: string) => {
  return (item: BasicPivotItem[]) => {
    const itemLookBackPeriod = item[lookBackPeriodProp];

    return (
      itemLookBackPeriod === undefined || itemLookBackPeriod.toLocaleLowerCase() === lookBackPeriod.toLocaleLowerCase()
    );
  };
};
