import * as React from 'react';
import * as AgGrid from 'ag-grid-community';

import { parseOverTimeTree, TreeRowData } from 'src/utils/Tree/OvertimeTreeHandler';
import { isNil, get, isEqual, last, isEmpty } from 'lodash';

import AgGridHeaderBreakClass from 'src/utils/Style/AgGridThemeBreakHeaders';
import { classes, style } from 'typestyle';
import {
  makeGridContainerStyle,
  gridListPairStyle,
  makeGridContainerStyleSpecWidth,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { OvertimeCellRendererParams, OvertimeConfig } from './OvertimeView.types';
import { ExportOptions } from 'src/common-ui/components/DataGrid/DataGrid';
import { GetMainMenuItemsParams, ColumnResizedEvent } from 'ag-grid-community';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { generateColDefs } from './OvertimeView.utils';
import { FrameworkComponents } from 'src/utils/Component/AgGrid/AgConfigParse';
import styles from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetGrid.styles';
import { multiHeaderDecorate } from 'src/common-ui/components/DataGrid/NestedHeader';
import {
  frameworkComponents,
  nonFrameworkComponents,
} from 'src/components/ConfigurableGrid/EditableGrid/EditableGrid.subcomponents';

type Props = {
  loaded: boolean;
  aggBys: string[];
  treeData: BasicPivotItem[];
  viewConfig: OvertimeConfig;
  search?: string;
  companionOpen?: boolean;
  hasCompanion?: boolean;
  exportOptions?: ExportOptions | undefined;
  rowHeight?: number;
};

type State = {
  columnDefs: AgGrid.ColDef[];
  rowData: TreeRowData[];
  aggBys?: string[];
  metrics?: string[];
  defaultColDef: AgGrid.ColDef;
  groupDefaultExpanded: -1;
  getDataPath: (data: TreeRowData) => string[];
  getRowNodeId: (data: TreeRowData) => string;
  autoGroupColumnDef: AgGrid.ColDef;
  components: unknown;
  frameworkComponents: FrameworkComponents;
};

const removeCheckMargin = style({
  $nest: {
    '.ag-group-checkbox': {
      display: 'none',
    },
  },
});

const bottomHeader = style({
  $nest: {
    '.ag-header-cell > :not(.group-child).ag-cell-label-container': {
      top: 0,
    },
    '.ag-header-cell-label > .ag-header-icon': {
      height: 'auto',
    },
  },
});

function getMenuItems(params: GetMainMenuItemsParams) {
  return params.defaultItems.filter((item) => item !== 'autoSizeAll');
}

export class NestedHeaderCellRenderer extends React.Component<OvertimeCellRendererParams> {
  constructor(props: OvertimeCellRendererParams) {
    super(props);
  }

  render() {
    const data = this.props.data;
    let textName = data?.name || data?.measureId;

    if (data) {
      const level = data?.path.length;
      const configClasses = data?.classes?.join(' ');
      const classes = `${styles.headerName} level-${level} ${configClasses}`;
      return <span className={classes}>{textName}</span>;
    }
    textName = this.props.getValue();
    return <span>{textName}</span>;
  }
}

export class OvertimeView extends React.Component<Props, State> {
  gridApi: AgGrid.GridApi | null | undefined;
  gridColumnApi: AgGrid.ColumnApi | null | undefined;
  constructor(props: Props) {
    super(props);
    this.state = {
      columnDefs: [],
      rowData: [],
      defaultColDef: {
        sortable: false,
        resizable: true,
        filter: true,
        width: 150,
      },
      components: {},
      groupDefaultExpanded: -1,
      getDataPath: function(data: TreeRowData) {
        return data.path;
      },
      getRowNodeId: function(data: TreeRowData): string {
        return data.path.join(' ');
      },
      frameworkComponents: {
        ...frameworkComponents,
        headerCellRenderer: NestedHeaderCellRenderer,
      },
      // TODO: This should probably come from viewdefn >.>
      autoGroupColumnDef: {
        headerName: 'Name',
        width: 250,
        field: 'name',
        pinned: true,
        cellRendererParams: {
          checkbox: false,
          innerRenderer: 'headerCellRenderer',
          suppressCount: true,
        },
      },
    };
  }

  parseTreeData = async () => {
    const { aggBys, viewConfig, treeData, search } = this.props;
    const metrics = viewConfig.columns.map((i) => ({
      key: i.dataIndex,
      name: i.text,
      formatter: i.renderer,
      classes: i.classes || [],
      ...i,
    }));

    if (isNil(aggBys) || isNil(metrics) || isEmpty(treeData)) {
      return;
    }

    const colDefLevels: string[] = get(viewConfig, 'timeLevels.colDef', ['month', 'member:week:description']);
    const dataLevels: string[] = get(viewConfig, 'timeLevels.data', ['month', 'week']);
    // the tree level at which to retrieve metric values
    const metricsTimeLevel = last(dataLevels) ?? '';
    const columnDefs = generateColDefs(colDefLevels, treeData);
    const rowData = parseOverTimeTree(treeData, aggBys, metrics, metricsTimeLevel, search);
    this.setState(
      {
        rowData,
        columnDefs,
        aggBys,
      },
      () => {
        if (this.gridApi != null) {
          this.gridApi.setColumnDefs(multiHeaderDecorate(this.state.columnDefs));
          this.gridApi.refreshCells({ force: true });
        }
      }
    );
  };

  componentDidUpdate(prevProps: Props) {
    if (
      !isEqual(prevProps.treeData, this.props.treeData) ||
      !isEqual(prevProps.search, this.props.search) ||
      !isEqual(prevProps.viewConfig, this.props.viewConfig)
    ) {
      if (!this.props.loaded) {
        this.setState({
          rowData: [],
        });
      } else {
        this.parseTreeData();
      }
    }
  }

  onGridReady = (params: AgGrid.GridReadyEvent) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  render() {
    const containerClass = this.props.hasCompanion
      ? makeGridContainerStyle(!this.props.companionOpen)
      : makeGridContainerStyleSpecWidth(0);
    const groupBolding = style({
      $nest: {
        '.ag-row-group': {
          fontWeight: 'bold',
        },
        '.ag-row-group-indent-0': {
          display: 'flex',
        },
        '.ag-row-group-indent-1': {
          display: 'flex',
        },
      },
    });
    return (
      <div
        id="myGrid"
        className={classes(
          'ag-theme-material',
          'data-grid',
          'double-header',
          AgGridHeaderBreakClass,
          removeCheckMargin,
          containerClass,
          bottomHeader,
          groupBolding
        )}
      >
        <div className={gridListPairStyle}>
          <ExtendedDataGrid
            columnDefs={this.state.columnDefs}
            data={this.state.rowData}
            rowHeight={this.props.rowHeight}
            onGridReady={this.onGridReady}
            frameworkComponents={this.state.frameworkComponents}
            nonFrameworkComponents={nonFrameworkComponents}
            loaded={this.props.loaded}
            exportOptions={{
              customHeader: !isNil(this.props.exportOptions) ? this.props.exportOptions.customHeader : '',
              fileName: !isNil(this.props.exportOptions) ? this.props.exportOptions.fileName : '',
            }}
            extraAgGridProps={{
              defaultColDef: this.state.defaultColDef,
              treeData: true,
              animateRows: true,
              groupDefaultExpanded: this.state.groupDefaultExpanded,
              getDataPath: this.state.getDataPath,
              getRowNodeId: this.state.getRowNodeId,
              autoGroupColumnDef: this.state.autoGroupColumnDef,
              getMainMenuItems: getMenuItems,
              suppressMovableColumns: true,
              onColumnResized: (event: ColumnResizedEvent) => {
                if (event.finished && event.api) {
                  event.api.redrawRows();
                }
              },
            }}
          />
        </div>
      </div>
    );
  }
}
