import React from 'react';

import ReceiptGrid from 'src/components/ReceiptGrid/ReceiptGrid.container';
import { AssortmentMatrixComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';

type Props = z.infer<typeof AssortmentMatrixComponentProps>;

export default class ReceiptGridComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <ReceiptGrid
          {...this.props}
          title={this.props.title || 'Assortment by Floorset'}
          showExtraRow={true}
          identityField={'id'} // change to floorset
        />
      </React.Fragment>
    );
  }
}
