import * as React from 'react';
import { macroGridStyle } from 'src/components/MacroGridPair/MacroGrid.styles';
import MacroSummary, {
  Props as MacroSummaryProps,
} from 'src/common-ui/components/Metrics/SimpleMetrics/MacroSummary/MacroSummary';
import { Overlay } from 'src/common-ui';
import { classes } from 'typestyle';
import styles from 'src/pages/AssortmentStrategy/TargetSetting/MacroSummaryGrid/MacroSummaryGrid.styles';

interface OptionProps {
  loaded: boolean;
  summaries: MacroSummaryProps[];
}
interface OptionState {
  isMacroCollapsed: boolean;
  configureIsOpen: boolean;
}

export default class MacroSummaryGrid extends React.Component<OptionProps, OptionState> {
  constructor(props: OptionProps) {
    super(props);

    this.state = {
      isMacroCollapsed: false,
      configureIsOpen: true,
    };

    this.toggleSummary = this.toggleSummary.bind(this);
  }

  toggleSummary() {
    this.setState({
      isMacroCollapsed: !this.state.isMacroCollapsed,
    });
  }

  render() {
    let mainContent: React.ReactElement | null = null;

    const containerClasses = macroGridStyle;
    if (this.props.loaded) {
      const { summaries } = this.props;

      const { isMacroCollapsed } = this.state;

      let macroClasses = 'macro-summaries';
      let expanderClass = 'far fa-fw macro-expander';

      if (isMacroCollapsed) {
        macroClasses += ' collapsed';
        expanderClass += ' fa-chevron-down';
      } else {
        expanderClass += ' fa-chevron-up';
      }
      const summariesEls = summaries.map((macroSummary, index) => {
        const options = {
          ...macroSummary,
          className: macroSummary.className,
        };
        return <MacroSummary key={index} {...options} />;
      });
      mainContent = (
        <React.Fragment>
          <div className="data-container">
            <div className="macro-summary-container">
              <div className="expander" onClick={this.toggleSummary}>
                <i className={expanderClass} />
                Summary
              </div>
              <div className={macroClasses}>
                <div className={styles.macroSummaryContainer}>{summariesEls}</div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return (
      <div className={classes(containerClasses, styles.optionContainer)}>
        <Overlay type="loading" visible={!this.props.loaded} />
        {mainContent}
      </div>
    );
  }
}
