import { GridApi, ColDef, RowClickedEvent, GridReadyEvent } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { get, has, isNil } from 'lodash/fp';
import * as math from 'mathjs';
import { Lens } from 'monocle-ts';
import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { classes } from 'typestyle';

import { Fab, Tooltip, Modal } from '@material-ui/core';

import Subheader from 'src/components/Subheader/Subheader.container';
import Renderer from 'src/utils/Domain/Renderer';
import coalesce from 'src/utils/Functions/Coalesce';
import { makeScopeSensitive } from 'src/components/higherOrder/ScopeSensitive';
import {
  targetSettingLens,
  targetListLens,
  scopeLensGetter,
  targetSettingNewLens,
  scopeConfigLens,
} from 'src/services/lenses/lenses';
import ControlPopper from '../ControlPopper/ControlPopper';
import { TargetType } from '../Criteria/Criteria.client';
import TargetCreation from '../TargetCreation/TargetCreation.container';
import { TargetSettingContainer } from '../TargetSetting/TargetSetting.container';
import * as TargetSettingStyles from '../TargetSetting/TargetSetting.styles';
import { TargetSettingConfigColumn, TargetSettingReduxSlice, TrueColDef } from '../TargetSetting/TargetSetting.types';
import { fabBtn } from './TargetList.styles';
import { Props, TargetListReduxSlice, State } from './TargetList.types';
import { onGridExport, GridExportType } from './TargetList.utils';
import { partial, includes } from 'lodash';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';

class UnsensitiveTargetList extends React.Component<Props, State> {
  ref!: AgGridReact | null;
  gridApi!: GridApi;

  constructor(props: Props) {
    super(props);
    this.state = {
      createNewOpen: false,
      popperRef: null,
      popperOpen: false,
      confirmOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.onShowView) {
      this.props.onShowView();
    }
  }

  onTargetListExport = (exportType: GridExportType) => {
    const columnConfigs = !isNil(this.props.config) ? this.props.config.grid.columns : [];
    onGridExport(exportType, this.gridApi, columnConfigs);
  };

  createColumn = (columnInfo: TargetSettingConfigColumn) => {
    const options: TrueColDef = {
      headerName: columnInfo.text,
      colId: `targetSetup.${columnInfo.dataIndex}`,
      pinned: columnInfo.pinned,
      cellClass: [TargetSettingStyles.cell],
      children: [],
    };
    if (columnInfo.editable === true) {
      options.editable = true;
      options.cellClass = [TargetSettingStyles.editableColumn, TargetSettingStyles.cell];
    }
    if (columnInfo.calculation != null) {
      const calculation = columnInfo.calculation;
      options.valueGetter = (params) => {
        const calc = math.parse(calculation);
        if (params.data == null) {
          return '';
        }
        const vars = {};
        calc
          .filter((node) => node.isSymbolNode)
          .forEach((node) => {
            const id = node.name || '';
            if (!has(id, vars) && !has(id, math)) {
              vars[id] = coalesce(get(`targetSetup.${id}`, params.data), params.getValue(id), params.data[id], '0');
            }
          });
        return math.eval(calculation, vars);
      };
    } else {
      options.field = `targetSetup.${columnInfo.dataIndex}`;
    }
    if (columnInfo.aggregatorFunction) {
      options.aggFunc = `${columnInfo.aggregatorFunction}`;
    } else if (columnInfo.aggregator) {
      options.aggFunc = columnInfo.aggregator;
    } else if (columnInfo.dataIndex === 'name') {
      options.footerValueGetter = () => 'Total';
    }

    if (columnInfo.renderer) {
      options.valueFormatter = (params) => {
        if (columnInfo.renderer != null) {
          if (Renderer[columnInfo.renderer]) {
            return Renderer[columnInfo.renderer](params.value);
          }
        }
        return params.value;
      };
    }
    return <AgGridColumn {...options} key={columnInfo.dataIndex} />;
  };

  onRowClicked = (event: RowClickedEvent) => {
    if (event.event) {
      if (event.event.target === this.state.popperRef) {
        this.setState({
          popperRef: null,
          popperOpen: false,
        });
      }
      this.setState({
        popperRef: event.event.target as HTMLElement,
        popperOpen: true,
      });
    }
  };

  generatePoppersection = (_targetType: TargetType) => {
    const callback = (action: string) => {
      this.setState({
        popperOpen: false,
      });
      if (this.gridApi != null) {
        const rows = this.gridApi.getSelectedRows();
        if (rows.length > 0) {
          const selected: TargetSettingReduxSlice = rows[0];
          this.props.onRowAction(action, selected);
        }
      }
    };
    // check if rp/op exists
    let availVers = ['WP'];
    if (this.gridApi != null) {
      const rows = this.gridApi.getSelectedRows();
      if (rows.length > 0) {
        const selected: TargetSettingReduxSlice = rows[0];
        availVers = selected.targetSetup?.versions || availVers;
      }
    }
    const basicControlPopper = {
      open: this.state.popperOpen,
      anchorRef: this.state.popperRef as HTMLElement,
      controls: [
        {
          icon: 'fa fa-pencil-alt',
          text: 'Edit',
          color: '#FAA33F',
          onClick: () => callback('Edit WP'),
          enabled: true
        },
        {
          icon: 'fa fa-pencil-alt',
          text: 'View OP',
          color: '#FAA33F',
          onClick: () => callback('View OP'),
          enabled:  includes(availVers, 'OP'),
        },
        {
          icon: 'fa fa-pencil-alt',
          text: 'View RP',
          color: '#FAA33F',
          onClick: () => callback('View RP'),
          enabled:  includes(availVers, 'RP'),
        },
        {
          icon: 'fa fa-trash-alt',
          text: 'Remove',
          color: '#E5454C',
          onClick: () => this.setState({ confirmOpen: true }),
          enabled: true,
        },
      ],
    };
    return <ControlPopper {...basicControlPopper} />;
  };

  renderList = (targetType: TargetType) => {
    const { config, rowData } = this.props;

    if (config == null) {
      return <div> Loading... </div>;
    }

    return (
      <div
        onClick={() => {
          if (this.state.popperOpen) {
            this.setState({
              popperOpen: false,
            });
          }
        }}
      >
        <Subheader title={config.text || ''} />
        <ConfirmationModal
          isOpen={this.state.confirmOpen}
          descriptionText={'Do you want to delete the selected target?'}
          onConfirm={() => {
            if (this.gridApi != null) {
              const rows = this.gridApi.getSelectedRows();
              if (rows.length > 0) {
                const selected: TargetSettingReduxSlice = rows[0];
                this.props.onRowAction('Remove', selected);
              }
            }
            this.setState({ confirmOpen: false });
          }}
          onCancel={() =>
            this.setState({
              confirmOpen: false,
            })
          }
        />
        <div
          style={{ height: 525, width: '100%' }}
          className={classes('ag-theme-material', 'data-grid', TargetSettingStyles.dataGrid)}
        >
          <AgGridReact
            ref={(ref: AgGridReact) => (this.ref = ref)}
            // binding to array properties
            rowData={rowData}
            singleClickEdit={true}
            onGridReady={(params: GridReadyEvent) => {
              if (params.api) {
                this.gridApi = params.api;
              }
            }}
            rowSelection="single"
            defaultColDef={
              {
                resizable: true,
                sortable: true,
                filter: false,
              } as ColDef
            }
            onRowClicked={(event: RowClickedEvent) => {
              if (event) {
                this.onRowClicked(event);
              }
            }}
            getContextMenuItems={() => {
              return [
                'expandAll',
                'contractAll',
                'copy',
                'resetColumns',
                {
                  name: 'CSV Export',
                  action: partial(this.onTargetListExport, 'csv'),
                },
                {
                  name: 'Excel Export',
                  action: partial(this.onTargetListExport, 'excel'),
                },
              ];
            }}
          >
            {config.grid.columns.map((info: TargetSettingConfigColumn) => this.createColumn(info))}
          </AgGridReact>
          <section className={fabBtn}>
            <Tooltip title="Create New Target" arrow>
              <Fab
                color="secondary"
                aria-label="Create New Target"
                onClick={() =>
                  this.setState({
                    createNewOpen: true,
                  })
                }
              >
                <i className={classes('fas fa-plus', TargetSettingStyles.addButton)} />
              </Fab>
            </Tooltip>

            <Modal
              open={this.state.createNewOpen}
              onClose={() =>
                this.setState({
                  createNewOpen: false,
                })
              }
              style={{
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '90vw',
                  height: '90vh',
                  minWidth: '700px',
                  backgroundColor: 'white',
                }}
              >
                <TargetCreation
                  lens={targetSettingNewLens}
                  scopeLens={scopeLensGetter}
                  targetType={targetType}
                  closeModal={() =>
                    this.setState({
                      createNewOpen: false,
                    })
                  }
                />
              </div>
            </Modal>
          </section>
          {this.generatePoppersection(targetType)}
        </div>
      </div>
    );
  };

  render() {
    const { match, type } = this.props;
    return (
      <div style={{ height: '100%', overflow: 'auto' }}>
        <Switch>
          <Route
            path={`${match.url}/:type/:targetKey/:version`}
            exact={true}
            component={(routerProps: RouteComponentProps<any>) => {
              return (
                <TargetSettingContainer
                  key={'target-setting'}
                  {...routerProps}
                  lens={targetSettingLens}
                  scopeConfigLens={scopeConfigLens.asGetter()}
                  targetCreationLens={targetListLens.compose(Lens.fromProp<TargetListReduxSlice>()('targetNew'))}
                />
              );
            }}
          />
          <Route path={`${match.url}/:type`} render={(routerProps: RouteComponentProps<any>) => {
            const type = routerProps.match.params.type;
            return this.renderList(type);
          }} />
          <Route
            exact={true}
            path={`${match.url}`}
            render={() => {
              if (type === 'Product') {
                return <Redirect to={`${match.url}/product`} />;
              } else {
                return <Redirect to={`${match.url}/location`} />;
              }
            }}
          />
        </Switch>
      </div>
    );
  }
}

export const TargetList = makeScopeSensitive<Props>(UnsensitiveTargetList);
