import { flow, get, hasIn, isNil, defaultTo } from 'lodash';
import { connect, Dispatch } from 'react-redux';
import { AppState } from 'src/store';
import {
  ConfigurableGridColumnDef,
  ConfigurableGridOwnProps,
  ConfigurableGridValueProps,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.types';

import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import {
  resetGroupBySelection,
  setGroupBySelection,
  setFloorsetSelection,
  updateConfigurableGridConfig,
  refreshConfigurableGridData,
  submitConfigurableGridPayload,
} from './ConfigurableGrid.slice';
import { bindActionCreators } from 'redux';
import { FabType, withFab } from '../higherOrder/withFab';
import { dispatchUpdateAssortmentPlan } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import { isViewDefnLoaded } from 'src/dao/tenantConfigClient';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { ConfigurableGrid } from './ConfigurableGrid';
import {
  getViewDefnData,
  getGroupByDropdownProps,
  getFloorsetDropdownProps,
  getConfigurableGridData,
  ConfigurableGridDataSelectorProps,
} from './ConfigurableGrid.selectors';
import { ConfigurableGridDispatchProps } from './ConfigurableGrid.types';
import { CoarseEditPayload } from 'src/dao/pivotClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { IS_PUBLISHED } from 'src/utils/Domain/Constants';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { processAdornments } from '../Adornments/Adornments';
import { ParseResult, parseConfigureConfig } from '../Configure/Configure';
import { Option } from 'src/components/Configure/ConfigureModal';
import { getConfigureSelections } from 'src/components/Subheader/Subheader.selectors';
import { updateConfigureSelections } from '../Subheader/Subheader.slice';
import { getSectionContextObj } from 'src/utils/Domain/Perspective';
import { isWorklistActive } from 'src/services/configuration/codecs/confdefnComponents';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';

function mapStateToProps(
  state: AppState,
  ownProps: ConfigurableGridOwnProps
): ConfigurableGridValueProps & ConfigurableGridOwnProps {
  const {
    viewDefnState,
    gridDataState: viewDataState,
    viewDefn,
    unmodifiedViewDefn,
  } = state.pages.assortmentBuild.configurableGrid;
  const { allowWorklistFunctionality = false, showFlowStatus } = ownProps;
  const configLoaded = isViewDefnLoaded(viewDefnState);
  const dataLoaded = isDataLoaded(viewDataState);

  // TODO: if possible move this logic down into the component to cut down on the number of props passed out of this function
  const columnDefs = isNil(viewDefn) ? [] : (viewDefn.columns as ConfigurableGridColumnDef[]);
  const gridRowHeight: number = get(viewDefn, 'main.rowHeight', 30);
  const groupRowHeight: number = get(viewDefn, 'main.groupRowHeight', 30);

  const massEditConfig = hasIn(viewDefn, 'massEditConfig') ? viewDefn.massEditConfig : undefined;
  const showPublish = hasIn(viewDefn, 'showPublish') ? viewDefn.showPublish : undefined;
  const publishText = hasIn(viewDefn, 'publishText') ? viewDefn.publishText : 'Publish';
  const publishAttribute = hasIn(viewDefn, 'publishAttribute') ? viewDefn.publishAttribute : IS_PUBLISHED;
  const hideUnpublish = hasIn(viewDefn, 'hideUnpublish') ? viewDefn.hideUnpublish : undefined;
  const updateCoordinateMap = hasIn(viewDefn, 'updateCoordinateMap') ? viewDefn.updateCoordinateMap : undefined;
  const salesAdjustmentConfig = isNil(viewDefn) ? {} : viewDefn.salesAdjustment;
  const configurablePostAction = get(viewDefn, 'configurablePostAction', {});
  const currentAdornments = get<AdornmentType[]>(viewDefn, 'adornments', []);
  const adornments = processAdornments(currentAdornments, { allowWorklistFunctionality });
  // configgrid ownProps are a union of things with legacy hardcoded identityField,
  // and the newer view component type "ConfigurableGridView", which uses ownProps.keys object
  const identifier = ownProps.identityField === null ? ownProps.keys.idProp : ownProps.identityField;

  const { dependentCalcs, companionSortOptions, defaultCompanionSortField, clientActionHandlers } = getViewDefnData(
    state
  );
  const leafIdProp =
    ownProps.identityField === null && ownProps.keys.leafIdProp ? ownProps.keys.leafIdProp : identifier;
  const floorsetDropdownProps = getFloorsetDropdownProps(state);
  const selectorProps: ConfigurableGridDataSelectorProps = {
    showFlowStatus,
    leafId: leafIdProp,
  };
  const ungroupedData: BasicPivotItem[] = getConfigurableGridData(state, selectorProps);
  // use configure if available, otherwise try fallback to groupby
  let configureOptions: ParseResult | undefined = undefined;
  let groupByDropdownProps: ReturnType<typeof getGroupByDropdownProps> | undefined = undefined;
  let configureSelections: Option[] | undefined = undefined;
  if (hasIn(viewDefn, 'configure')) {
    const parseResult = parseConfigureConfig(viewDefn.configure);
    configureOptions = {
      ...parseResult,
    };
    configureSelections = getConfigureSelections(state);
  } else {
    groupByDropdownProps = getGroupByDropdownProps(state);
  }

  const context = getSectionContextObj();
  const undoBtnConf = get(context, 'undoBtn', undefined);
  const showUndo = !state.print.isPrintMode && !isNil(undoBtnConf) && defaultTo(ownProps.showUndoBtn, false);

  return {
    ...ownProps,
    identifier, // not a good name, but this supercedes identityField useage in the component
    // use leafIdProp if in ConfigurableGridView (which has identityField null) if it exists
    leafIdProp,
    flowStatus: showFlowStatus ? state.subheader.flowStatus : [],
    showFlowStatus,
    search: state.subheader.search || '',
    groupBySelection: state.pages.assortmentBuild.configurableGrid.groupBySelection,
    favoritesList: state.subheader.favoritesList || [],
    fabType: ownProps.fabType || FabType.none,
    configLoaded,
    dataLoaded,
    configureSelections,
    configureOptions,
    columnDefs,
    massEditConfig,
    showPublish,
    publishText,
    publishAttribute,
    hideUnpublish,
    updateCoordinateMap,
    dependentCalcs,
    gridRowHeight,
    groupRowHeight,
    companionSortOptions,
    defaultCompanionSortField,
    unmodifiedViewDefn,
    configuratorViewDefn: viewDefn,
    clientActionHandlers,
    groupByDropdownProps,
    floorsetDropdownProps,
    data: ungroupedData, //always pass in flat data, grid manages grouping
    topAttributesData: state.pages.assortmentBuild.configurableGrid.topAttributesData,
    salesAdjustmentConfig,
    adornments,
    configurablePostAction,
    scopeSelection: state.scope.selections,
    viewDataState,
    showUndo,
    isWorklistActive: isWorklistActive(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<AppState>,
  _ownProps: ConfigurableGridOwnProps
): ConfigurableGridDispatchProps {
  return {
    dispatch,
    ...bindActionCreators({ setGroupBySelection, resetGroupBySelection, setFloorsetSelection }, dispatch),
    onUpdateConfig: (config: any) => {
      dispatch(updateConfigurableGridConfig(config));
    },
    updateAssortmentPlan: () => {
      dispatchUpdateAssortmentPlan(dispatch);
    },
    onRefreshConfigurableGridData: () => {
      dispatch(refreshConfigurableGridData());
    },
    submitPayload: async (payload: CoarseEditPayload) => {
      await submitConfigurableGridPayload(payload);
    },
    updateConfigureSelections(selections: Option[]) {
      dispatch(updateConfigureSelections(selections));
    },
  };
}

const sensitiveView = flow(() => ConfigurableGrid, withFab, makePopoverSensitive, makePrintSensitive)();
export default connect(mapStateToProps, mapDispatchToProps)(sensitiveView);
