import { style } from 'typestyle';

const dataGridStyles = style({
  borderLeft: '1px solid #ddd',
  borderRight: '1px solid #ddd',
  minWidth: 0,
  $nest: {
    '& .ag-cell': {
      height: 'inherit',
    },
    '&.ag-theme-material .ag-header-cell-label .ag-header-icon': {
      height: 'auto',
    },
  },
});

export default {
  dataGridStyles,
};
