import { AppState } from 'src/store';
import { createSelector, OutputSelector } from 'reselect';
import { getDataFromCache } from 'src/services/pivotServiceCache';
import { ExceptionList } from './ExceptionsSummary';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

const getTreeData = (state: AppState) => {
  return getDataFromCache(state.pages.hindsighting.exceptionsSummary)?.tree || [];
};
export type ProcessedDataSelector = OutputSelector<AppState, ExceptionList, (res1: BasicPivotItem[]) => ExceptionList>;
export const getExceptions = createSelector(getTreeData, (treeData) => {
  return treeData;
});
