import { style } from 'typestyle';

const imageContainer = style({
  display: 'inline-block',
  position: 'absolute',
  margin: '1px',
  border: '1px solid transparent',
  boxSizing: 'border-box',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  overflow: 'hidden',
  transition: 'top 1.5s, left 1.5s, width 1.5s, height 1.5s, opacity 2s, border-color 0.2s, transform 2s',
});

export default { imageContainer };
