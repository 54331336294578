import React from 'react';
import { Icon } from '@material-ui/core';
import { TrendingDown, TrendingFlat, TrendingUp } from '@material-ui/icons';
import { classes } from 'typestyle';

import { default as styles } from './TrendCard.styles';
import { VariancePillProps, VariancePillRenderer } from 'src/components/TrendDetails/TrendDetails';
import { GRAY } from 'src/utils/Style/Theme';
import { isNumber } from 'lodash';
import { getBandedValue, POSITIVE_BAND } from '../TrendDetails/TrendDetails.utils';

export const calcTrendCardWidth = () => {
  // Add .01 so if the number of items is 1 over the total items per col, it will create the extra column
  return 300 as const;
};

export const calcTrendCardHeight = () => {
  return 250 as const;
};

export enum TrendCardType {
  metric = 'metric',
  prediction = 'prediction',
}

type TrendDirection = 'positive' | 'negative' | 'neutral';
export interface TrendCardProps {
  trendCardType: TrendCardType;
  title: string;
  trendValue: number; // dictates number for metric cards only, prediction cards only need direction
  trendValueText: string; // dictates number for metric cards only, prediction cards only need direction
  trendSummary: string; // supporting text for trend
  currentValue: number;
  primaryPill: VariancePillProps;
  secondaryPill: VariancePillProps;
}

interface TrendVisualAttributes {
  color: string;
  metricIcon: string;
  metricPhrase: 'Increase' | 'Decrease' | 'No Change';
  predictionIcon: JSX.Element;
  predictionValue: 'Uptrend' | 'Downtrend' | 'Steady';
  predictionPhrase: 'Upwards' | 'Downwards' | 'Flat';
}

function getTrendDirection(value: number | string): TrendDirection {
  // handle metric number values
  if (isNumber(value)) {
    if (getBandedValue(value) === 0) return 'neutral';
    else if (value >= POSITIVE_BAND) return 'positive';
    else return 'negative';
  }

  // handle prediction string values
  if (value === 'uptrend') return 'positive';
  else if (value === 'downtrend') return 'negative';
  else return 'neutral';
}

function getTrendVisualAttributes(direction: TrendDirection): TrendVisualAttributes {
  switch (direction) {
    case 'positive':
      return {
        color: '#6BB08B',
        metricIcon: 'fa fa-arrow-up',
        metricPhrase: 'Increase',
        predictionIcon: <TrendingUp style={{ color: '#6BB08B' }} />,
        predictionValue: 'Uptrend',
        predictionPhrase: 'Upwards',
      };
    case 'negative':
      return {
        color: '#D04B32',
        metricIcon: 'fa fa-arrow-down',
        metricPhrase: 'Decrease',
        predictionIcon: <TrendingDown style={{ color: '#D04B32' }} />,
        predictionValue: 'Downtrend',
        predictionPhrase: 'Downwards',
      };
    case 'neutral':
    default:
      return {
        color: GRAY,
        metricIcon: 'fa fa-arrow-right',
        metricPhrase: 'No Change',
        predictionIcon: <TrendingFlat style={{ color: GRAY }} />,
        predictionValue: 'Steady',
        predictionPhrase: 'Flat',
      };
  }
}

export const TrendCard = ({
  trendCardType,
  title,
  trendValue,
  trendValueText,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trendSummary, // TODO: at some point may want to make configurable
  currentValue,
  primaryPill,
  secondaryPill,
}: TrendCardProps) => {
  const trendDirection = getTrendDirection(trendValue);
  const {
    color,
    metricIcon,
    metricPhrase,
    predictionIcon,
    predictionValue,
    predictionPhrase,
  } = getTrendVisualAttributes(trendDirection);

  const mainTrendContent =
    trendCardType === TrendCardType.metric ? (
      <div className="row-55">
        <div className="icon-55">
          <Icon style={{ color }} className={metricIcon} />
        </div>
        <div className="noticon-55">
          <div className="55-top">
            <span className="big-percent">
              <b>{trendValueText}</b>
            </span>
          </div>
          <div className="bottom-55">
            <span>
              <b>{metricPhrase}</b> in {title} Compared to Last Year
            </span>
          </div>
        </div>
      </div>
    ) : (
      <div className="row-55">
        <div className="icon-55">{predictionIcon}</div>
        <div className="noticon-55">
          <div className="55-top">
            <span className="big-percent">
              <b>{predictionValue}</b>
            </span>
          </div>
          <div className="bottom-55">
            <span>
              Trending <b>{predictionPhrase}</b>
            </span>
          </div>
        </div>
      </div>
    );

  return (
    <div
      className={classes(styles.card)}
      style={{
        width: calcTrendCardWidth(),
        height: calcTrendCardHeight(),
      }}
      data-qa-component="TrendCard"
      data-qa-action="OpenPopover"
      data-qa-key={title}
    >
      <div style={{ borderTop: `4px solid ${color}` }} className="inner-card">
        <div className="row-10">
          <span>
            <b>{title}</b>
          </span>
        </div>
        {mainTrendContent}
        <div className="row-35">
          <div className="bignumber">
            <span>
              <b>{currentValue}</b>
            </span>
          </div>
          <VariancePillRenderer pills={[primaryPill, secondaryPill]} />
        </div>
      </div>
    </div>
  );
};
