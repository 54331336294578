import React from 'react';
import { Report } from 'src/dao/reportClient';
import { ASSORTMENT, API_URL_PREFIX } from 'src/utils/Domain/Constants';
import { defaultTo } from 'lodash';
import { AppType } from 'src/types/Domain';
import { getLatestAccessToken } from 'src/services/auth/platform';

function reportParamToIcon(reportParam: string, key: number) {
  switch (reportParam) {
    case 'Product':
      return <i key={key} className="report-card-dimension fas fa-cube" />;
    case 'Location':
      return <i key={key} className="report-card-dimension fas fa-map-marker" />;
    case 'Time':
      return <i key={key} className="report-card-dimension fas fa-clock" />;
    default: // nothing
  }
  return undefined;
}

const reportUrlFromId = (report: Report & { perspective?: AppType }): string => {
  const latestToken = getLatestAccessToken();
  const accessToken: string = latestToken != null ? latestToken : '';
  return `${API_URL_PREFIX}/api/pivot3/export?appName=${defaultTo(report.perspective, ASSORTMENT)}&defnId=${
    report.modelId
  }&flowStatus=&groupBy=&sortBy=&xlsxTemplate=${report.templateId}&token=${encodeURIComponent(accessToken)}`;
};

export function ReportCard(report: Report & { perspective?: AppType }) {
  return (
    <div className="report-card">
      <header>
        {report.name.split('\n').map((token, i) => (
          <div key={i}>{token}</div>
        ))}
      </header>
      <main>
        <p>{report.description}</p>
        <div className="icons">{report.reportParams.split(',').map((param, i) => reportParamToIcon(param, i))}</div>
      </main>
      <footer>
        {/* <i className="footer-icon configure fas fa-cog" /> */}
        <a className="footer-icon download" href={reportUrlFromId(report)} target="_blank" rel="noreferrer">
          <i className="fas fa-download" />
        </a>
      </footer>
    </div>
  );
}
