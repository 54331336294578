import React from 'react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import {
  GridApi,
  GridReadyEvent,
  RowNode,
  CellClickedEvent,
  CellEditingStoppedEvent,
  BodyScrollEvent,
  CellEditingStartedEvent,
  SideBarDef,
  ColGroupDef,
  ProcessCellForExportParams,
  ExcelNumberFormat,
  ColDef,
  NewColumnsLoadedEvent,
  DisplayedColumnsChangedEvent,
  GetContextMenuItemsParams,
  MenuItemDef,
  ColumnApi,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';

import { KeyVal } from '../../common/object';
import { multiHeaderDecorate } from './NestedHeader';
import styles from './DataGrid.styles';
import { isEmpty, isEqual, isNil } from 'lodash';
import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService';
import {
  getCellFromRowCol,
  getMainMenuItems,
  handleNavigateToNextCell,
  onBodyScroll,
  onCellClicked,
  onCellEditingStarted,
  onCellEditingStopped,
  onCellKeyDown,
  onColumnResized,
  onGridReady,
  onCsvExport,
  onExcelExport,
  onNewColumnsLoaded,
  onDisplayedColumnsChanged,
  onFilterChanged,
  onSortChanged,
  onRowDataChanged,
  OverlayLoadingTemplate,
} from './DataGrid.events';
import { classes } from 'typestyle';
import { FilterModelType, SortModelType } from 'src/components/Sidenav/SideNav.slice';

LicenseManager.setLicenseKey(
  'S5_Stratos_MultiApp_2Devs11_July_2019__MTU2Mjc5OTYwMDAwMA==82681080271234c6108c212ac1a9f2c7'
);

const GRID_HEADER_HEIGHT = 30;

export type GetDataPath = ((x: any) => any) | undefined;

export type ScrollTo = {
  eventId: number;
  where: KeyVal<string, any>;
};

export type Gridable = {
  id: string;
  [key: string]: any;
};

export type RendererExcelFormat = {
  dataType: string;
  numberFormat: string | ExcelNumberFormat;
  id?: string;
};

export type RendererExcelFormatObject = {
  [s: string]: RendererExcelFormat;
};

export type DefaultShownValues = { [dataIndex: string]: string[] };

export type ExportOptions = {
  customHeader?: string;
  excelRendererObj?: RendererExcelFormatObject;
  fileName?: string;
  processCellOverride?: (params: ProcessCellForExportParams) => string | undefined;
};

export interface MenuItemDefWithId extends MenuItemDef {
  /**
   * AgGrid default context menu items only have a string identifer (eg, 'expandAll'),
   * but if we want to compare with a MenuItem (which only has .name like 'Expand All'),
   * we need something that matches the existing string identifier in order to override the,
   * default dataGrid context menu items
   */
  _id: string;
}

export type DataGridProps = {
  data: Gridable[] | any[];
  columnDefs: (ColDef | ColGroupDef)[];
  treeColumnDefinition?: ColDef | undefined;
  className?: string;
  rowHeight?: number;
  autoSizeOnReady?: boolean;
  isPrintMode?: boolean;
  frameworkComponents?: any;
  nonFrameworkComponents?: any;
  loaded: boolean;
  scrollTo?: ScrollTo;
  rowClassRules?: any;
  defaultShownValues?: DefaultShownValues;
  singleClickEdit?: boolean;
  sideBar?: SideBarDef | boolean | string;
  exportOptions?: ExportOptions;
  extraAgGridProps?: AgGridReactProps;
  gridFilterModel?: FilterModelType;
  gridSortModel?: SortModelType[];
  headerHeight?: number;
  onCellClicked?(event: CellClickedEvent): void;
  onGridReady?(event: GridReadyEvent): void;
  onNewColumnsLoaded?(event: NewColumnsLoadedEvent): void;
  onDisplayedColumnsChanged?(event: DisplayedColumnsChangedEvent): void;
  onCellEditingStopped?(event: CellEditingStoppedEvent): void;
  onCellEditingStarted?(event: CellEditingStartedEvent): void;
  onBodyScroll?(event: BodyScrollEvent): void;
  getAlternateContextMenu?: (params: GetContextMenuItemsParams) => (string | MenuItemDef)[];
  storeGridFilterModel?: (model: FilterModelType) => void;
  storeGridSortModel?: (model: SortModelType[]) => void;
  /**
   * Used to overrride the default behavior of some contextMenu items
   * This can be used, for instance, to override the expander to only expand one level instead of all of them
   */
  overrideDefaultContextMenuActions?: boolean;
};

export type NoRenderState = {
  gridApi?: GridApi;
  columnApi?: ColumnApi;
  lastScrolledTo?: number;
  defaultShownValues?: DefaultShownValues;
  // used for rendering an alternate context menu on left click
  leftOrRightClick: 'left' | 'right';
};

export default class DataGrid extends React.Component<DataGridProps> {
  public noRenderState: NoRenderState;
  public getCellFromRowCol = getCellFromRowCol.bind(this);

  private onGridReady = onGridReady.bind(this);
  private onCsvExport = onCsvExport.bind(this);
  private onExcelExport = onExcelExport.bind(this);
  private getMainMenuItems = getMainMenuItems.bind(this);
  private onCellClicked = onCellClicked.bind(this);
  private onCellEditingStopped = onCellEditingStopped.bind(this);
  private onCellEditingStarted = onCellEditingStarted.bind(this);
  private onBodyScroll = onBodyScroll.bind(this);
  private onColumnResized = onColumnResized.bind(this);
  private onCellKeyDown = onCellKeyDown.bind(this);
  private handleNavigateToNextCell = handleNavigateToNextCell.bind(this);
  private onNewColumnsLoaded = onNewColumnsLoaded.bind(this);
  private onDisplayedColumnsChanged = onDisplayedColumnsChanged.bind(this);
  private onFilterChanged = onFilterChanged.bind(this);
  private onSortChanged = onSortChanged.bind(this);
  private onRowDataChanged = onRowDataChanged.bind(this);

  constructor(props: DataGridProps) {
    super(props);

    this.noRenderState = {
      defaultShownValues: props.defaultShownValues,
      leftOrRightClick: 'right',
    };
  }

  componentDidUpdate(prevProps: DataGridProps) {
    if (this.noRenderState.gridApi) {
      if (this.props.isPrintMode) {
        this.noRenderState.gridApi.setDomLayout('print');
      } else if (prevProps.isPrintMode && !this.props.isPrintMode) {
        this.noRenderState.gridApi.setDomLayout('normal');
      }

      if (!isEqual(prevProps.data, this.props.data)) {
        this.noRenderState.gridApi.setRowData(this.props.data);
      }

      // apply correct overlay state
      // there are issues with ag-grids overlay at times, so sometimes this works other times not
      // wrapping in a setTimeout was one of the workarounds
      // see here for more details: https://github.com/ag-grid/ag-grid/issues/3849

      if (!this.props.loaded) {
        setTimeout(() => this.noRenderState.gridApi?.showLoadingOverlay());
      } else if (this.props.loaded && isEmpty(this.props.data)) {
        setTimeout(() => this.noRenderState.gridApi?.showNoRowsOverlay());
      } else {
        setTimeout(() => this.noRenderState.gridApi?.hideOverlay());
      }
    }
  }

  injectColumnDef = (colDef: any) => {
    const { exportOptions } = this.props;
    if (colDef && exportOptions && exportOptions.excelRendererObj) {
      const cellClassFunction = (params: any, renderer: string) => {
        return (
          params.colDef.renderer === renderer ||
          params.colDef.cellRenderer === renderer ||
          (params.node.data && params.node.data.renderer === renderer) ||
          (params.node.data && params.node.data.formatter === renderer)
        );
      };
      const excelRendererArr: RendererExcelFormat[] = Object.keys(exportOptions.excelRendererObj).map((key) => {
        return {
          ...exportOptions!.excelRendererObj![key],
          numberFormat: {
            format: (exportOptions!.excelRendererObj![key] as any).numberFormat.replace(/"/g, '&quot;'),
          },
        };
      });
      const injectableClassRules = excelRendererArr.reduce((accumeObj, style) => {
        accumeObj[style.id!] = (params: any) => cellClassFunction(params, style.id!);
        return accumeObj;
      }, {});
      return { ...colDef, cellClassRules: { ...colDef.cellClassRules, ...injectableClassRules } };
    }
    return colDef;
  };

  decorateColDefsForExcel = (colDefs: (ColDef | ColGroupDef)[]) => {
    if (colDefs && this.props.exportOptions) {
      return colDefs.map((colDef: any) => {
        const returnObj = this.injectColumnDef(colDef);
        if (colDef.children && colDef.children.length > 0) {
          returnObj.children = colDef.children.map((childDef: any) => this.injectColumnDef(childDef));
        }
        return returnObj;
      });
    }
    return colDefs;
  };

  showWorklist = (event: CellClickedEvent) => {
    // there appears to be no public interface that can accomplish this, so we're using the private interface instead
    // we take the event details from the left click, and manually fire it at the right click event
    // Warning: this could break in future releases of ag-grid
    if (!this.noRenderState.gridApi) {
      return; // no clicky too quicky
    }
    // @ts-ignore
    this.noRenderState.gridApi.contextMenuFactory.showMenu(event.node, event.column, event.value, event.event);
  };

  getRowHeight = (): number | undefined => {
    return this.props.rowHeight || this.props.extraAgGridProps?.rowHeight;
  };

  public getContextMenuItems = (params: GetContextMenuItemsParams) => {
    // We 'jiggle' the context menu when we detect a left click from onCellClicked
    // in order to re-use ag-grid's context menu functionality for other purposes
    // I attempted to use onCellContextMenu and some other approaches,
    // but that fires after getContextMenuItems, and everything else was more work

    if (this.props.getAlternateContextMenu && this.noRenderState.leftOrRightClick === 'left') {
      this.noRenderState.leftOrRightClick = 'right'; // reset back to default, then complete the event
      return this.props.getAlternateContextMenu(params);
    }
    // this is the normal context menu, invoked from right click
    // TODO: fix the icons here
    const collapseAll = () => {
      if (this.noRenderState.gridApi) {
        this.noRenderState.gridApi.forEachNode((node) => {
          node.expanded = false;
        });
        this.noRenderState.gridApi.onGroupExpandedOrCollapsed();
      }
    };
    const expandAll = () => {
      if (this.noRenderState.gridApi) {
        this.noRenderState.gridApi.forEachNode((node) => {
          node.expanded = true;
        });
        this.noRenderState.gridApi.onGroupExpandedOrCollapsed();
      }
    };
    let defaultContextMenu: (string | MenuItemDef)[] = [
      {
        name: 'Expand All',
        icon: '<i class="far fa-chevron-double-down" aria-hidden="true"></i>',
        action: () => expandAll(),
      },
      {
        name: 'Collapse All',
        icon: '<i class="far fa-chevron-double-up" aria-hidden="true"></i>',
        action: () => collapseAll(),
      },
      'copy',
      {
        name: 'Reset Columns',
        icon: '<i class="fa fa-repeat" aria-hidden="true"></i>',
        action: () => this.noRenderState.columnApi!.resetColumnState(),
      },
      {
        name: 'CSV Export',
        action: this.onCsvExport,
        icon: '<i class="fa fa-file" aria-hidden="true"></i>',
      },
      {
        name: 'Excel Export',
        action: this.onExcelExport,
        icon: '<i class="fa fa-file-excel" aria-hidden="true"></i>',
      },
    ];

    if (
      this.props.extraAgGridProps &&
      this.props.extraAgGridProps.getContextMenuItems &&
      this.props.overrideDefaultContextMenuActions
    ) {
      defaultContextMenu = defaultContextMenu.map((mItem) => {
        const maybeOverride = this.props.extraAgGridProps!.getContextMenuItems!(params).find((overrideItem) => {
          const ovItem = overrideItem as MenuItemDefWithId;
          if (
            (typeof overrideItem !== 'string' && typeof mItem === 'string' && mItem === ovItem._id) ||
            (typeof overrideItem !== 'string' && typeof mItem !== 'string' && mItem.name === ovItem._id)
          ) {
            return overrideItem;
          }
          return;
        });
        return maybeOverride ? maybeOverride : mItem;
      });
    }

    return defaultContextMenu;
  };

  render() {
    const {
      columnDefs,
      data,
      className,
      frameworkComponents,
      rowHeight,
      treeColumnDefinition,
      scrollTo,
      rowClassRules,
      defaultShownValues,
      nonFrameworkComponents,
      sideBar,
      extraAgGridProps,
      exportOptions,
    } = this.props;

    if (defaultShownValues) {
      this.noRenderState.defaultShownValues = defaultShownValues;
    }

    const { gridApi, lastScrolledTo, columnApi } = this.noRenderState;

    if (scrollTo !== undefined && gridApi && columnApi && scrollTo.eventId !== lastScrolledTo) {
      const setExpandedAll = (node: RowNode) => {
        if (node.parent) {
          node.setExpanded(true);
          setExpandedAll(node.parent);
        }
      };
      let foundIndex = -1;
      gridApi.ensureNodeVisible((node: RowNode) => {
        const firstCol = columnApi.getAllDisplayedColumns()[0];
        if (node.data && node.data[scrollTo.where.key] === scrollTo.where.value) {
          gridApi.clearRangeSelection();
          gridApi.setFocusedCell(node.rowIndex, firstCol);
          gridApi.ensureIndexVisible(node.rowIndex, 'top');
          return true;
        } else {
          if (node && node.allChildrenCount && node.allChildrenCount > 0) {
            foundIndex = -1;
            node.allLeafChildren.forEach((nod) => {
              if (nod.data && nod.data[scrollTo.where.key] === scrollTo.where.value) {
                setExpandedAll(nod);

                gridApi.setFocusedCell(nod.rowIndex, firstCol);
                foundIndex = nod.rowIndex;
              }
            });
            // Don't select more than one item
            if (foundIndex > -1) return true;
          }
        }
        return false;
      });
      // If a node that is a child of multiple parents is selected, use this to ensure scrolling works
      if (foundIndex > -1) {
        gridApi.ensureIndexVisible(foundIndex, 'top');
      }
      this.noRenderState.lastScrolledTo = scrollTo.eventId;
    }
    let colDefs = columnDefs;
    const decoratedFrameworkComponents = frameworkComponents;
    const isMultiHeader = columnDefs.filter(
      (colDef: ColGroupDef | ColDef) => 'children' in colDef && colDef.children.length
    ).length;

    if (isMultiHeader) {
      colDefs = multiHeaderDecorate(columnDefs as any);
    }

    const finalColDefs = this.decorateColDefsForExcel(colDefs);
    const baseGridOptions: AgGridReactProps = {
      //#region Default AgGrid Props
      sideBar: sideBar,
      columnDefs: finalColDefs,
      excludeChildrenWhenTreeDataFiltering: true,
      rowDataChangeDetectionStrategy: ChangeDetectionStrategyType.NoCheck,
      deltaColumnMode: true,
      suppressSetColumnStateEvents: true,
      rowHeight: rowHeight,
      headerHeight: !isNil(extraAgGridProps?.headerHeight) ? extraAgGridProps?.headerHeight : GRID_HEADER_HEIGHT,
      animateRows: false,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
      },
      suppressPropertyNamesCheck: true,
      singleClickEdit: true,
      frameworkComponents: decoratedFrameworkComponents,
      components: nonFrameworkComponents,
      rowBuffer: 0,
      icons: {
        sortAscending: '<i class="fas fa-lg fa-sort-down"></i>',
        sortDescending: '<i class="fas fa-lg fa-sort-up"></i>',
        filter: '<i class="fas fa-filter"></i>',
        clipboardCopy: '<i class="fa fa-clone" aria-hidden="true"></i>',
        resetColumns: '<i class="fa fa-repeat" aria-hidden="true"></i>',
      },
      suppressCopyRowsToClipboard: true,
      suppressRowClickSelection: true,
      enableRangeSelection: true,
      suppressMultiRangeSelection: true,
      enterMovesDown: false,

      //#endregion
      //#region Events
      onGridReady: this.onGridReady,
      onRowDataChanged: this.onRowDataChanged,
      onDisplayedColumnsChanged: this.onDisplayedColumnsChanged,
      onNewColumnsLoaded: this.onNewColumnsLoaded,
      getMainMenuItems: this.getMainMenuItems,
      getContextMenuItems: this.getContextMenuItems,
      onCellClicked: this.onCellClicked,
      onCellEditingStopped: this.onCellEditingStopped,
      onCellEditingStarted: this.onCellEditingStarted,
      onBodyScroll: this.onBodyScroll,
      onColumnResized: this.onColumnResized,
      onCellKeyDown: this.onCellKeyDown,
      navigateToNextCell: this.handleNavigateToNextCell,
      onFilterChanged: this.onFilterChanged,
      onSortChanged: this.onSortChanged,
      getRowHeight: this.getRowHeight,
      //#endregion
      // everything else that gets dumped in
      ...extraAgGridProps,
      overlayLoadingTemplate: OverlayLoadingTemplate,
    };

    if (rowClassRules) {
      baseGridOptions.rowClassRules = rowClassRules;
    }

    if (baseGridOptions.defaultColDef) {
      baseGridOptions.defaultColDef = this.injectColumnDef(baseGridOptions.defaultColDef);
    }
    if (baseGridOptions.autoGroupColumnDef) {
      baseGridOptions.autoGroupColumnDef = this.injectColumnDef(baseGridOptions.autoGroupColumnDef);
    }
    if (extraAgGridProps && extraAgGridProps.getContextMenuItems && this.props.overrideDefaultContextMenuActions) {
      // this is here if you want to override some of the context menu items, but not all of them
      // such as changing expand behavior, but keeping the xls export of DataGrid
      baseGridOptions.getContextMenuItems = this.getContextMenuItems;
    }

    if (exportOptions) {
      if (exportOptions.excelRendererObj) {
        const excelRendererArr: RendererExcelFormat[] = Object.keys(exportOptions.excelRendererObj).map((key) => {
          return {
            ...exportOptions!.excelRendererObj![key],
            numberFormat: {
              format: (exportOptions!.excelRendererObj![key] as any).numberFormat.replace(/"/g, '&quot;'),
            },
          };
        });
        baseGridOptions.excelStyles = excelRendererArr.map((s) => {
          return { numberFormat: s.numberFormat, id: s.id };
        });
      }
    }

    // We have to keep two separate grids for non-tree/tree data
    // because the grid does not detect changes to autoGroupColumnDef/treeData properties after initial render

    const rowData = data && data.length > 0 ? data : undefined;
    const treeGrid = !isNil(treeColumnDefinition) ? (
      <AgGridReact
        rowData={[]}
        gridOptions={{
          autoGroupColumnDef: treeColumnDefinition,
          groupDefaultExpanded: -1,
          treeData: !isNil(treeColumnDefinition),
          getDataPath: (item: any) => item[treeColumnDefinition.field!],
          ...baseGridOptions,
        }}
      />
    ) : (
      undefined
    );

    const flatGrid = isNil(treeColumnDefinition) ? <AgGridReact gridOptions={baseGridOptions} /> : undefined;

    return (
      <div
        className={classes(
          `ag-theme-material data-grid ${styles.dataGridStyles}`,
          isMultiHeader ? ' double-header' : '',
          className ? ` ${className}` : ''
        )}
      >
        {treeGrid}
        {flatGrid}
      </div>
    );
  }
}
