import { createStyles } from '@material-ui/core';

export const accordionOverrides = createStyles({
  root: {
    margin: 0,
    // uses material-ui's $rulename feature to reference and override `expanded` rule in same style sheet
    // empty expanded rule is necessary for style to be applied properly, see following link for details
    // https://v4.mui.com/customization/components/#use-rulename-to-reference-a-local-rule-within-the-same-style-sheet
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
});

export const accordionSummaryOverrides = createStyles({
  root: {
    flexDirection: 'row-reverse',
    padding: 0,
    height: '35px',
    minHeight: '35px',
    '&$expanded': {
      height: '35px',
      minHeight: '35px',
    },
  },
  expanded: {},
});

export const accordionDetailsOverrides = createStyles({
  root: {
    padding: '0 0.35rem',
  },
});
