import React from 'react';
import serviceContainer from 'src/ServiceContainer';
import { PrintProps } from 'src/components/higherOrder/Print/Print';
import styles from 'src/components/Headerbar/Headerbar.styles';
import { IconLink } from 'src/components/Headerbar/Links/IconLink';
import { DispatchProps } from './Headerbar.container';
import { StateProjection } from './Headerbar.selector';
import getWeekRangeText from 'src/utils/Functions/Description';
import { makeHeaderbarPlanSensitive, PlanSensitiveProps } from './AssortmentPlanSensitive';
import { isEmpty, omit, isNil, values, get } from 'lodash';
import RestoreSession from 'src/components/RestoreSession/RestoreSession.container';
import {
  getSessionPerspective,
  getSessionPage,
  getSessionScope,
} from 'src/components/RestoreSession/RestoreSession.utils';
import { FiltersLink } from 'src/components/Headerbar/Links/FiltersLink';
import { PrintLink } from 'src/components/Headerbar/Links/PrintLink';
import { RangeItem, Scope } from 'src/types/Scope';
import { PlanLink } from 'src/components/Headerbar/Links/PlanLink';
import { ReconcileLink } from 'src/components/Headerbar/Links/ReconcileLink';

type HeaderbarProps = StateProjection & DispatchProps & PrintProps & PlanSensitiveProps;
type HeaderbarState = {
  planQueuePopover: boolean;
  printSizeOpen?: boolean;
  reconcileOpen?: boolean;
};

interface ScopeTextProps {
  startKey: string;
  endKey: string;
  ranges: RangeItem[];
  scope: Scope;
}

const ScopeText = (props: ScopeTextProps) => {
  const { startKey, endKey, ranges, scope } = props;
  const { productMemberName, locationMemberName } = scope;
  const begin = scope[startKey];
  const end = scope[endKey];
  const weekRangeText = getWeekRangeText(begin, end, ranges);
  return <span>{`Product - ${productMemberName} | Location - ${locationMemberName} | ${weekRangeText}`}</span>;
};

export class Headerbar extends React.Component<HeaderbarProps, HeaderbarState> {
  popoverPlanQueue: React.RefObject<HTMLAnchorElement> = React.createRef();
  checkPlanIntervalId: NodeJS.Timer | null;

  constructor(props: HeaderbarProps) {
    super(props);
    this.state = {
      planQueuePopover: false,
      reconcileOpen: false,
    };
    this.checkPlanIntervalId = null;
  }

  handlePrintModeClick = () => serviceContainer.printService.setPrintMode(false);

  handlePrintClick = (size: { width: number; height: number }) => {
    serviceContainer.printService.openPrint(size.width, size.height);
  };

  toggleReconcileModal = () => {
    this.setState({
      reconcileOpen: !this.state.reconcileOpen,
    });
  };

  handleClickOnScope = () => {
    this.props.onToggleScopeSelector();
  };

  handleCartClick = () => {
    if (!isNil(this.props.assortmentCartLink)) {
      this.props.setActivePage(this.props.assortmentCartLink);
      window.location.hash = this.props.assortmentCartLink;
    }
  };

  render() {
    const {
      scope,
      filters,
      scopeRangesKey,
      scopeStartKey,
      scopeEndKey,
      isTopDownView,
      isPrintMode,
      isViewPrintable,
      printSizes,
      isCartEnabledInContext,
      cartButtonConfig,
      quickReconConf,
      assortmentPlanInProgress,
      askForRefresh,
      planTracker,
      onOpenPlanQueue,
      refreshPage,
    } = this.props;
    const tabRequiresReconcile = !isNil(quickReconConf);
    const hasLocalStorage = !isNil(getSessionPage()) && !isNil(getSessionPerspective());
    const scopeDefined = !values(omit(getSessionScope(), ['floorset'])).every(isEmpty);
    const currentScopeUndefined = values(omit(scope, ['floorset'])).every(isEmpty) && this.props.rangeList.length > 0;
    const reconcileTitle = get(quickReconConf, 'title', 'Quick Reconcile');

    const showPlan = !isPrintMode;
    const showScope = !isPrintMode;
    const showFilters = !isPrintMode;
    const showReconcile = !isPrintMode && tabRequiresReconcile && !isTopDownView;
    const showCart = !isPrintMode && !isTopDownView && !isNil(cartButtonConfig) && isCartEnabledInContext;
    const showRestore = hasLocalStorage && scopeDefined && currentScopeUndefined;

    return (
      <div className={`navbar navbar-expand-lg ${styles.headerbarStyle}`}>
        <span className="nav navbar-nav navbar-left" data-qa="HeaderbarLeftSide">
          {isPrintMode && (
            <IconLink
              id="ExitPrintMode"
              text="Exit Print Mode"
              iconClass="fal fa-step-backward"
              onClick={this.handlePrintModeClick}
              linkStyle={{ marginRight: 25, fontWeight: 'bold' }}
            />
          )}
          <ScopeText startKey={scopeStartKey} endKey={scopeEndKey} ranges={this.props[scopeRangesKey]} scope={scope} />
        </span>
        <ul className="nav navbar-nav ml-auto navbar-right" data-qa="HeaderbarRightSide">
          {showPlan && (
            <li className={askForRefresh ? styles.marqueLi : ''}>
              <PlanLink
                planInProgress={assortmentPlanInProgress}
                askForRefresh={askForRefresh}
                planItems={planTracker}
                onClick={onOpenPlanQueue}
                onRefresh={refreshPage}
              />
            </li>
          )}
          {showReconcile && (
            <li>
              <ReconcileLink title={reconcileTitle} conf={quickReconConf} />
            </li>
          )}
          {showScope && (
            <li>
              <IconLink id="Scope" iconClass="far fa-crosshairs" text="Scope" onClick={this.handleClickOnScope} />
            </li>
          )}
          {showFilters && (
            <li>
              <FiltersLink filterSlice={filters} onFiltersOpen={this.props.onOpenFilters} />
            </li>
          )}
          <li>
            <PrintLink
              isViewPrintable={isViewPrintable}
              isPrintMode={isPrintMode}
              printSizes={printSizes}
              onClick={this.handlePrintClick}
            />
          </li>
          {showCart && (
            <li>
              <IconLink
                id="Cart"
                text={`${cartButtonConfig.text} ${this.props.cartCount > 0 ? `(${this.props.cartCount})` : ''}`}
                iconClass={cartButtonConfig.iconSrc}
                linkClass={'clickable'}
                onClick={this.handleCartClick}
              />
            </li>
          )}
        </ul>
        {showRestore && <RestoreSession hideLoading={true} />}
      </div>
    );
  }
}

// @ts-ignore
// TODO: Headerbar is only a class component because of the use in the PlanSensitive HOC
// can really just remove the HOC and just make this a connected function component since the HOC is only used here
export default makeHeaderbarPlanSensitive(Headerbar);
