import { TEAL_PRIMARY } from 'src/common-ui/theme';
import { style } from 'typestyle';

export const bulkImportStyles = style({
  $debugName: 'bulkimport',
  $nest: {
    '.bulk-import-stepper': {
      margin: '0 auto 0 auto',
      maxWidth: '75vw',
      $nest: {
        '.s5-MuiStepIcon-active .s5-MuiStepIcon-text': {
          fill: 'white',
        },
        '.bulkimport-upload.s5-Mui-disabled': {
          pointerEvents: 'none',
        },
        '.s5-MuiStepContent-root': {
          overflowX: 'auto',
        },
        ' .success': {
          width: 314,
        },
      },
    },
    '.step-header': {
      backgroundColor: '#f3f1f5',
      height: '3rem',
      padding: '1rem',
    },
    '.s5-MuiAccordionActions-root': {
      width: '40rem',
      alignItems: 'flex-start',
    },
    '.continue-btn': {
      borderRadius: 0,
      fontWeight: 'normal',
      color: 'white',
      backgroundColor: TEAL_PRIMARY,
      $nest: {
        '&.loading': {
          top: '0px !important',
          cursor: 'not-allowed',
        },
        '&:disabled': {
          cursor: 'not-allowed',
          // color: '#dee2e6',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
        },
      },
    },

    ['.dropdown-group']: {
      paddingTop: '2em',
      paddingBottom: '2em',
      $nest: {
        '.dropdown-group-label': {
          paddingRight: '1em',
        },
        '.dropdown': {
          minWidth: '15rem',
          height: '2em',
          fontSize: '1.2em',
          padding: '0.5em',
          backgroundColor: '#f3f1f5',
          borderRadius: '0.4em',
          marginRight: '3rem',
        },
      },
    },
    '.grid-container .ag-center-cols-viewport': {
      overflowX: 'hidden', // overrides a default dataGrid class
    },
  },
});
