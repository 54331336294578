import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/epicsFunctions';
import { setActivePage, setActivePageComponentPropsConf } from './NavigationShell.slice';
import { extractNavPathFromString } from './navigationUtils';
import { isEnabledTab } from 'src/services/configuration/codecs/confdefn';
import {
  hasBaseDefns,
  hasBaseMultiDefns,
  hasApiDefns,
  hasTargetListType,
} from 'src/services/configuration/codecs/confdefnView';
import { isNone } from 'fp-ts/lib/Option';
import { updateFilterSensitivity } from 'src/components/FilterPanel/FilterPanel.slice';
import { isNil } from 'lodash';

export const setActivePageComponentPropsEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(setActivePage.type),
    map(() => [state$.value.appConfig.tenantConfig, state$.value.perspective.activePage] as const), // const here makes it a 2 elem tuple
    filter(inputIsNotNullOrUndefined),
    mergeMap((stateTuple) => {
      const navPath = extractNavPathFromString(stateTuple[1]);
      if (isNone(navPath)) {
        return of(setActivePageComponentPropsConf(undefined));
      }

      const maybeView = stateTuple[0].tabs
        .filter(isEnabledTab)
        .find((t) => t.pathSlot === navPath.value[1])
        ?.leftNavSections?.find((i) => i.pathSlot === navPath.value[2])
        ?.views.filter(
          (view) => hasBaseDefns(view) || hasBaseMultiDefns(view) || hasApiDefns(view) || hasTargetListType(view)
        )
        .find((v) => v.pathSlot === navPath.value[3]);

      if (
        maybeView &&
        (hasBaseDefns(maybeView) ||
          hasBaseMultiDefns(maybeView) ||
          hasApiDefns(maybeView) ||
          hasTargetListType(maybeView))
      ) {
        return of(setActivePageComponentPropsConf(maybeView));
      } else {
        return of(setActivePageComponentPropsConf(undefined));
      }
    })
  );
};

/**
 * This epic is responsible for setting the page filter sensitivity on any page navigation.
 * It assumes all pages are filter sensitive except a small known set that are hardcoded.
 *
 * Unfortunately, it currently relies on the configured pathSlot which has potential to deviate from the confdefn values.
 *
 * TODO: We may consider creating specific zod literals for hardcoded these values so we can assure that they match what the confdefn is configured with.
 */
export const setActivePageFilterSensitivityEpic: AppEpic = (action$, _state$) => {
  return action$.pipe(
    ofType(setActivePage.type),
    mergeMap((action) => {
      // returns the text after the final slash in the page path (if any)
      const pathSlot = action.payload.match(/[\w-]+$/g);
      const unformattedPathSlot = !isNil(pathSlot) ? pathSlot[0].toLowerCase().replace('-', '') : '';

      switch (unformattedPathSlot) {
        case 'topdown':
        case 'bottomup':
        case 'macromix':
        case 'geotrend':
        case 'topperformers':
        case 'summary':
        case 'parametertoggles':
        case 'cart':
        case 'targetlist':
          return of(updateFilterSensitivity(false));
        default:
          return of(updateFilterSensitivity(true));
      }
    })
  );
};
