/* eslint-disable @typescript-eslint/naming-convention */
import { LensProp } from '../TargetSetting/TargetSetting.types';
import { Lens, Getter } from 'monocle-ts';
import { AppState } from 'src/store';
import { ScopeSelectorSlice } from 'src/components/ScopeSelector/ScopeSelector.slice';
import { DataApiConfig } from 'src/services/configuration/codecs/confdefnView';

export type ValidMembersData = {
  description: string;
  dimension: Record<string, any>;
  id: string;
  index: number;
  levelId: string;
  name: string;
  ordered: boolean;
  root: boolean;
  shortestPathToRoot: Record<string, any>;
};

export type LYTargetData = {
  avg_str_rctu_cc: number;
  cccount: number;
  children: any[];
  cntwk: number;
  contr_debut_slsr: number;
  debut_fp_aps: number;
  debut_fp_sell_thru: number;
  debut_rec_aut: number;
  debut_sls_aur: number;
  department: string;
  description: string;
  eohu: number;
  expanded: boolean;
  flow_cccount: number;
  fp_slsr: number;
  grand_slsr: number;
  grand_ttl_slsr: number;
  id: string;
  index: number;
  leaf: boolean;
  lof_cccount: number;
  'member:department:description': string;
  'member:department:id': string;
  'member:department:name': string;
  'member:division:description': string;
  'member:division:id': string;
  'member:division:name': string;
  'member:gender:description': string;
  'member:gender:id': string;
  'member:gender:name': string;
  'member:group_prd:description': string;
  'member:group_prd:id': string;
  'member:group_prd:name': string;
  'member:top_bottom:description': string;
  'member:top_bottom:id': string;
  'member:top_bottom:name': string;
  name: string;
  pct_debut_slsr: number;
  pct_fp_slsr: number;
  pct_sls_pen: number;
  rctr: number;
  rctu: number;
  slsr: number;
  slsu: number;
  storecccount: number;
  storecount: string | number;
  strcntwk: number;
  tgt_avg_str_rctu_cc: number;
  tgt_cccount: number;
  tgt_cntwk: number;
  tgt_contr_debut_slsr: number;
  tgt_debut_fp_aps: number;
  tgt_debut_fp_sell_thru: number;
  tgt_debut_rec_aut: number;
  tgt_debut_sls_aur: number;
  tgt_eohu: number;
  tgt_flow_cccount: number;
  tgt_fp_slsr: number;
  tgt_lof_cccount: number;
  tgt_pct_debut_slsr: number;
  tgt_pct_fp_slsr: number;
  tgt_pct_sls_pen: number;
  tgt_rctr: number;
  tgt_rctu: number;
  tgt_slsr: number;
  tgt_slsu: number;
  tgt_storecccount: number;
  tgt_storecount: number | string;
  tgt_strcntwk: number;
  tgt_ttl_fp_cccount: number;
  tgt_ttl_slsr: number;
  ttl_fp_cccount: number;
  ttl_slsr: number;
};

export type CriteriaOption = {
  dataIndex: string;
  text: string;
  dataApi?: DataApiConfig;
  disabled?: boolean;
  type?: 'level' | 'attribute';
};

export type FunctionProps = {
  onShowView?: () => void;
  selectFirstSeed?: () => void;
  onOptionSelected: (selection: CriteriaOption, multiple?: boolean) => void;
  onReset: () => void;
  selectAllRight: () => void;
};

export type CriteriaReduxSlice = {
  options: CriteriaOption[][];
  selections: CriteriaOption[];
  cache?: { [s: string]: CriteriaOption[] };
  currentRequest?: Promise<any>;
  dataApi?: string;
};

export type ValueProps = {
  allSelected?: boolean;
} & CriteriaReduxSlice;
export const selectionLens = Lens.fromProps<CriteriaReduxSlice>()(['selections']);

type NotCustomGetter = {
  scopeLens: Getter<AppState, ScopeSelectorSlice>;
  parentIdLens: Getter<AppState, string | null>;
  parentLevelLens: Getter<AppState, string | null>;
  filterByHierarchy?: boolean;
};
type CustomGetter = {
  getOptions: (dataIndex: string) => CriteriaOption[];
};
type GetterProps = CustomGetter | NotCustomGetter;
export function hasCustomGetter(props: GetterProps): props is CustomGetter {
  return (props as CustomGetter).getOptions != null;
}
export type OwnProps = LensProp<AppState, CriteriaReduxSlice> &
  GetterProps & {
    titles: string[];
    showCheckAll?: boolean;
    datepicker?: JSX.Element;
  };
export type CriteriaProps = ValueProps & FunctionProps & OwnProps;
