import serviceContainer from '../../ServiceContainer';
import PerspectiveSelection from './PerspectiveSelection';
import { resetScope } from 'src/components/ScopeSelector/ScopeSelector.slice';
import { AppThunkDispatch } from 'src/store';
import { connect } from 'react-redux';

export type StateProps = Record<string, unknown>;

export type DispatchProps = {
  resetScope: () => void;
};
export type Props = StateProps & DispatchProps;

function mapStateToProps(): StateProps {
  return {};
}

function mapDispatchToProps(dispatch: AppThunkDispatch): DispatchProps {
  return {
    resetScope() {
      dispatch(resetScope());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerspectiveSelection(serviceContainer.configService, serviceContainer.configuratorConfigService));
