import { Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { ListIcon } from 'src/common-ui/components/IconPopper/ListIcon';
import * as styles from './IconPopper.style';

interface IconPopperProps {
  open: boolean;
  handleCloseModal: () => void;
  onSelectIcon: (iconClass: string) => void;
}
export default function IconPopper(props: IconPopperProps) {
  const { open, handleCloseModal, onSelectIcon } = props;
  const [iconList, setIconList] = useState(ListIcon);
  const [searchIcon, setSearchIcon] = useState('');
  const [selectedIcon, setSelectedIcon] = useState<HTMLElement | null>();

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchIcon(e.target.value);
  };

  const filterIcon = iconList.filter((icon) => icon.includes(searchIcon));

  const displayIcon = filterIcon.map((icon, idx) => (
    <div key={idx} className={styles.icon}>
      <i
        className={`fa fa-${icon}`}
        onClick={() => {
          onSelectIcon(`fa fa-${icon}`);
          handleCloseModal();
        }}
      />
    </div>
  ));

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      maxWidth="sm"
      classes={{
        root: styles.modal,
      }}
      scroll="paper"
    >
      <DialogTitle classes={{ root: styles.modalTitle }}>
        <div className={styles.titleContainer}>
          <div className={styles.leftTitleContainer}>Select Icon</div>
          <div className={styles.rightTitleContainer}>
            <span onClick={handleCloseModal}>x</span>
          </div>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: styles.contentContainer }}>
        <div className="search-container">
          <input type="search-icon" placeholder="Search Icon" value={searchIcon} onChange={handleSearchInput} />
        </div>
        <div className={styles.iconsContainer}>{displayIcon}</div>
      </DialogContent>
    </Dialog>
  );
}
