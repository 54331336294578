import React from 'react';
import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import { ICellRendererParams } from 'ag-grid-community';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { pick, isNil, get, has } from 'lodash';
import Renderer from 'src/utils/Domain/Renderer';
import { DefaultShownValues } from 'src/common-ui/components/DataGrid/DataGrid';
import { isNumber, isString } from 'lodash';
import { ListDataConfig, ViewApiConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { IsColumnFuncParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { GroupHeaderKey } from './AgDataFormat';

export type FrameworkComponents = GridOptions['frameworkComponents'];

export type ParseResult = {
  rowHeight?: number;
  groupRowHeight?: number;
  columnWidth?: number;
  frameworkComponents: FrameworkComponents;
  colDefs: ColDef[];
  treeColumnDefinition: ColDef | undefined;
  stylePaneTriggerSet: Set<string>;
  defaultShownValues: DefaultShownValues;
  masterDetailConfig: MasterDetailConfig | null;
  hiddenGroupColumnDataIndices: Set<string>;
};

export type MasterDetailConfig = {
  dataApi: ViewApiConfig | ListDataConfig;
  configApi: ViewApiConfig;
};

export type IncrementalResult = {
  renderers: Set<string>;
  colDefs: ColDef[];
  treeColumnDefinition: ColDef | undefined;
  stylePaneTriggerSet: Set<string>;
  masterDetailConfig: MasterDetailConfig | null;
  hiddenGroupColumnDataIndices: Set<string>;
};

export type CellStyler = (params: ICellRendererParams) => any;

function parseViewDefns(configViewItems: TenantConfigViewItem[]): IncrementalResult {
  const colDefs: ColDef[] = [];
  const renderers = new Set<string>();
  const stylePaneTriggerSet = new Set<string>();
  const hiddenGroupColumnDataIndices = new Set<string>();
  let treeColumnDefinition: ColDef | undefined;
  let masterDetailConfig: MasterDetailConfig | null = null;

  if (!configViewItems || !configViewItems[Symbol.iterator]) {
    throw new Error('nooop');
  }

  for (const configViewItem of configViewItems) {
    let isTreeColumn = false;
    let isStylePaneTrigger = false;
    const cellStylers: CellStyler[] = [];
    if (configViewItem.visible === false) {
      continue;
    }

    /* eslint-disable-next-line */
    //@ts-ignore
    const colDef: ColDef = Object.keys(configViewItem).reduce((transformed: ColDef & ColGroupDef, key: string) => {
      const value = configViewItem[key];

      switch (key) {
        case 'text':
          transformed.headerName = value;
          break;
        case 'dataIndex':
          transformed.field = value.toLowerCase();
          break;
        // :( I'm sorry
        case 'hidden':
          transformed.hide = configViewItem.visible != null ? !configViewItem.visible : value;
          break;
        case 'visible':
          transformed.suppressToolPanel = !value;
          transformed.hide = !value;
          break;
        case 'renderer': {
          if (value === 'backgroundFill') {
            cellStylers.push((params: ICellRendererParams) => ({
              'background-color': params.value,
              color: 'transparent',
              padding: 0,
            }));
            // Fixed depecation warnings causing ListView to crash
            transformed.suppressMenu = true;
            transformed.sortable = false;
            transformed.suppressMovable = true;
            transformed.filter = false;
            transformed.resizable = false;
            transformed.suppressAutoSize = true;
            transformed.suppressSizeToFit = true;
          } else {
            transformed.cellRenderer = value;
            renderers.add(value);
          }
          break;
        }
        case 'stylePaneOpenOnClick': {
          isStylePaneTrigger = value;
          break;
        }
        case 'align': {
          cellStylers.push(() => ({ textAlign: value }));
          break;
        }
        case 'highlightText': {
          if (!isNil(value)) {
            transformed.cellClass = value ? 'highlight' : undefined;
          }
          break;
        }
        // drop-in
        case 'headerCheckboxSelection':
        case 'headerCheckboxSelectionFilteredOnly':
        case 'checkboxSelection':
        case 'headerClass':
        case 'resizable':
        case 'suppressMovable':
        case 'suppressMenu':
        case 'width':
        case 'minWidth':
        case 'pinned':
          transformed[key] = value;
          break;
        case 'aggregator':
          transformed[key] = value;
          transformed['aggFunc'] = value;
          break;
        case 'columns':
          delete transformed.field;
          const result = parseViewDefns(value);
          transformed.children = result.colDefs;
          result.stylePaneTriggerSet.forEach((item) => stylePaneTriggerSet.add(item));
          result.renderers.forEach((item) => renderers.add(item));
          break;
        case 'treeColumn':
          isTreeColumn = value;
          break;
        case 'masterDetail':
          masterDetailConfig = value;
          break;
        case 'editable':
          transformed[key] = (params: IsColumnFuncParams) => {
            // only allow group headers of configured columns to be editable
            return !isNil(params.data[GroupHeaderKey]) && value;
          };
          break;
        default:
        // fallthrough, okay
      }

      transformed.comparator = (valueA: any, valueB: any) => {
        if (valueA === undefined) {
          return -1;
        } else if (valueB === undefined) {
          return 1;
        }
        if (isNumber(valueA) && isNumber(valueB)) {
          if (valueA === valueB) {
            return 0;
          }

          return valueA > valueB ? 1 : -1;
        } else if (isString(valueA) && isString(valueB)) {
          if (valueA === valueB) return 0;
          else return valueA > valueB ? 1 : -1;
        } else if (isString(valueA) || isString(valueB)) {
          // count NaN as 0 for now
          const modA = valueA === 'NaN' ? 0 : isString(valueA) ? parseInt(valueA) : valueA;
          const modB = valueB === 'NaN' ? 0 : isString(valueB) ? parseInt(valueB) : valueB;

          if (modA === modB) {
            return 0;
          }

          return modA > modB ? 1 : -1;
        }

        return 0;
      };

      return transformed;
    }, {});

    // assigning colId explicitly to allow for columns w/ duplicate dataIndexes.
    // pinned update is necessary to force agGrid to notice field change.
    colDef.colId = get(configViewItem, 'id') || configViewItem.dataIndex;
    colDef.pinned = colDef.pinned || false;
    if (cellStylers.length) {
      colDef.cellStyle = (params: ICellRendererParams) => {
        return cellStylers.map((fn) => fn(params)).reduce((acc, result) => ({ ...acc, ...result }));
      };
    }

    if (isStylePaneTrigger) {
      stylePaneTriggerSet.add(colDef.field!);
    }

    if (isTreeColumn) {
      treeColumnDefinition = colDef;
    } else {
      colDefs.push(colDef);
    }

    if (configViewItem.hideGroupedColumn) {
      hiddenGroupColumnDataIndices.add(configViewItem.dataIndex);
    }
  }

  return {
    renderers,
    colDefs,
    treeColumnDefinition,
    stylePaneTriggerSet,
    masterDetailConfig,
    hiddenGroupColumnDataIndices,
  };
}

/**
 * This function returns all renderers wrapped in a div for consumption by ag-grid
 * and also does some setup of inputs for grouped values and template renderers
 * Building all renderers doesn't appear to affect grid performance, and guards against some instances where
 * columns are set with a renderer that isn't attached to the grid instance
 * @param groupingField when grouping by level:class:name on a template renderer, we need to template on id+name
 * @returns Record<string,frameworkComponent> as {} any
 */
export const getFrameworkComponents = (groupingField?: string): GridOptions['frameworkComponents'] => {
  // build frameworkComponents of all renderers everytime
  // this doesn't appear to affect performance, and guards against some instances where
  // columns are set with a renderer that isn't attached to the grid instance
  return [...Object.keys(Renderer)].reduce((acc, renderKey) => {
    const renderer = Renderer[renderKey];

    // eslint-disable-next-line react/display-name
    acc[renderKey] = (params: ICellRendererParams) => {
      let renderedValue;

      if (renderKey === 'template' && groupingField === 'level:class:name') {
        // only want templated output when grouping by class
        renderedValue = renderer(params.data, '{id} - {name}');
      } else if (renderKey === 'template') {
        // for non-templated row header values, use original dataIndex
        renderedValue = renderer(params.data, '{name}');
      } else {
        renderedValue = renderer(params.value);
      }
      if (has(params.data, '$$GroupHeader')) {
        if (isNil(params.value)) {
          renderedValue = '';
          if (has(params.column, 'aggregator') || has(params.column, 'aggFunc')) renderedValue = '0';
        } else {
          renderedValue = renderer(params.value);
        }
      }
      return <div>{renderedValue}</div>;
    };

    return acc;
  }, {});
};

export function parseGridConfig(gridConfig: TenantConfigViewData, groupingField?: string): ParseResult {
  const results = parseViewDefns(gridConfig.view);

  const frameworkComponents = getFrameworkComponents(groupingField);

  const defaultShownValues = gridConfig.view.reduce((acc, next) => {
    if (next.columns) {
      next.columns.forEach((column) => {
        if (column.defaultShownValues) {
          acc[column.dataIndex] = column.defaultShownValues;
        }
      });
    }

    if (next.defaultShownValues) {
      acc[next.dataIndex] = next.defaultShownValues;
    }
    return acc;
  }, {} as any);

  return {
    defaultShownValues,
    rowHeight: gridConfig.main!.rowHeight,
    groupRowHeight: gridConfig.main!.groupRowHeight,
    frameworkComponents,
    ...pick(
      results,
      'treeColumnDefinition',
      'stylePaneTriggerSet',
      'colDefs',
      'masterDetailConfig',
      'hiddenGroupColumnDataIndices'
    ),
  };
}
