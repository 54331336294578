import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { z } from 'zod';
import { get, isEmpty, isNil } from 'lodash';

import { EnhancedOvertimeComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { ViewDataState } from 'src/types/Domain';
import { isViewDefnLoaded } from 'src/dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { AppState, AppThunkDispatch } from 'src/store';
import { isDataLoaded, getUniqueDataFromCache, HashType } from 'src/services/pivotServiceCache';
import Overlay from 'src/common-ui/components/Overlay/Overlay';
import Subheader from 'src/components/Subheader/Subheader.container';
import { TimeChart, TimeChartConfig } from 'src/components/TimeChart/TimeChart';
import { OvertimeView } from 'src/pages/AssortmentBuild/OvertimeView/OvertimeView';
import { listPairStyle } from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import { ConfigureOvertimeConfig } from 'src/pages/AssortmentBuild/OvertimeView/OvertimeView.types';
import ConfigureModal, {
  ConfigureModalProps,
  Option,
  OptionGroup,
  ToggleAction,
} from 'src/components/Configure/ConfigureModal';
import styles from 'src/components/EnhancedOvertime/EnhancedOvertime.styles';
import { updateAggBys } from 'src/components/EnhancedOvertime/EnhancedOvertime.slice';

export type EnhancedOvertimeOwnProps = z.infer<typeof EnhancedOvertimeComponentProps>;

interface EnhancedOvertimeStateProps {
  title: string;
  configLoaded: boolean;
  dataLoaded: boolean;
  chartViewDefn: TimeChartConfig | null;
  gridViewDefn: ConfigureOvertimeConfig | null;
  chartData: BasicPivotItem[];
  gridData: BasicPivotItem[];
  viewDataState: ViewDataState[];
  hideTitle: boolean;
  aggBys: Option[];
}

interface EnhancedOvertimeDispatchProps {
  updateAggBys: (aggBys: Option[]) => void;
}

interface EnhancedOvertimeProps extends EnhancedOvertimeStateProps, EnhancedOvertimeDispatchProps {}

function mapStateToProps(state: AppState, ownProps: EnhancedOvertimeOwnProps): EnhancedOvertimeStateProps {
  const { title = '', hideTitle = true } = ownProps;
  const viewState = state.pages.assortmentBuild.enhancedOvertime;
  const { viewDefnState, chartDataState, gridDataState, chartViewDefn, gridViewDefn } = viewState;
  const configLoaded = isViewDefnLoaded(viewDefnState);
  const dataLoaded = isDataLoaded(chartDataState) && isDataLoaded(gridDataState);
  const chartData = getUniqueDataFromCache(viewState, HashType.chart)?.tree || [];
  const gridData = getUniqueDataFromCache(viewState, HashType.grid)?.tree || [];
  const aggBys = viewState.aggBys;

  return {
    title,
    configLoaded,
    dataLoaded,
    chartData,
    gridData,
    chartViewDefn,
    gridViewDefn,
    viewDataState: [chartDataState, gridDataState],
    aggBys,
    hideTitle,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch): EnhancedOvertimeDispatchProps {
  return {
    updateAggBys(aggBys: Option[]) {
      dispatch(updateAggBys(aggBys));
    },
  };
}

const EnhancedOvertime = ({
  configLoaded,
  dataLoaded,
  title,
  chartData,
  gridData,
  chartViewDefn,
  gridViewDefn,
  viewDataState,
  aggBys,
  hideTitle,
  updateAggBys,
}: EnhancedOvertimeProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selections, setSelections] = useState<Option[]>([]);
  const [uncommittedSelections, setUncommittedSelections] = useState<Option[]>([]);
  const [configureOptions, setConfigureOptions] = useState<OptionGroup[]>([]);
  const [defaultSelections, setDefaultSelections] = useState<Option[]>([]);

  useEffect(() => {
    const options = isNil(gridViewDefn) ? [] : gridViewDefn.configure.view;
    setConfigureOptions(options);
  }, [gridViewDefn]);

  useEffect(() => {
    const defaults = isNil(gridViewDefn)
      ? []
      : gridViewDefn.configure.defaults.map((key, ind) => {
          const options = gridViewDefn.configure.view[ind].options;
          return options.find((i) => i.dataIndex === key) || options[0];
        });

    setDefaultSelections(defaults);
    updateAggBys(defaults);
  }, [gridViewDefn, updateAggBys]);

  const onConfigureReset = useCallback(() => {
    if (isNil(gridViewDefn)) {
      return;
    }
    setUncommittedSelections(defaultSelections);
  }, [defaultSelections, gridViewDefn]);

  const onToggleModal = useCallback(
    (action: ToggleAction) => {
      switch (action) {
        case 'apply': {
          setSelections(uncommittedSelections);
          updateAggBys(uncommittedSelections);
          break;
        }
        default: {
          setUncommittedSelections(selections);
        }
      }

      setIsOpen(false);
    },
    [selections, uncommittedSelections, updateAggBys]
  );

  const onSelectionUpdate = useCallback((selections: Option[]) => {
    setUncommittedSelections(selections);
  }, []);

  const onConfigureClick = useCallback(() => {
    setIsOpen(true);
    setUncommittedSelections(selections);
  }, [selections]);

  const configureModalProps: ConfigureModalProps = {
    enabled: true,
    isOpen,
    optionGroups: configureOptions,
    selections: isEmpty(uncommittedSelections) ? defaultSelections : uncommittedSelections,
    instructions: gridViewDefn ? gridViewDefn.configure.title : 'Select Aggregation Levels',
    onReset: onConfigureReset,
    onToggleModal,
    selectionUpdate: onSelectionUpdate,
  };

  if (!configLoaded || isNil(chartViewDefn) || isNil(gridViewDefn)) {
    return <Overlay visible={true} type={'loading'} qaKey={'overtime-loading'} />;
  }

  return (
    <div className={listPairStyle}>
      <Subheader
        title={title}
        hideTitle={hideTitle}
        showSearch={false}
        showFlowStatus={false}
        viewDataState={viewDataState}
        configureOptions={{
          type: 'enabled',
          onConfigureClick,
        }}
      />
      <section className={styles.enhancedOvertimeContainer}>
        <TimeChart aggBy={aggBys[0].dataIndex || ''} data={chartData} viewDefn={chartViewDefn} />
        <div className={'data-container'}>
          <React.Fragment>
            <OvertimeView
              loaded={dataLoaded}
              viewConfig={gridViewDefn}
              rowHeight={get(gridViewDefn, 'main.rowHeight', 40)}
              aggBys={aggBys.map((aggBy) => aggBy.dataIndex)}
              treeData={gridData}
              exportOptions={{
                fileName: title,
              }}
            />
          </React.Fragment>
        </div>
      </section>
      <ConfigureModal {...configureModalProps} />
    </div>
  );
};

export default connect(mapStateToProps, dispatchToProps)(EnhancedOvertime);
