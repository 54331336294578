import { IdentityPropLookup, IdentityPropsConfig } from './StandardCardView.types';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { isNil } from 'lodash';
import store from 'src/store';
import { STYLE_ID, STYLE_NAME, DESCRIPTION, ID, NAME } from 'src/utils/Domain/Constants';
import { CardViewItem } from './UIData.types';
import { StandardCardProps } from './StandardCard/StandardCard';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';

export function retrieveIdentityPropsConfig(
  config: TenantConfigViewData & { identityProps?: IdentityPropsConfig }
): IdentityPropsConfig {
  const { identityProps } = config;

  if (!isNil(identityProps)) {
    return identityProps;
  }

  // fallback to previously hardcoded values

  // StandardCardView style review views are configured slightly different
  const [, , , section] = store.getState().perspective.activePage.split('/');
  const isStyleReviewSection = section === 'style-review';

  if (isStyleReviewSection) {
    return {
      id: STYLE_ID,
      name: STYLE_NAME,
      description: DESCRIPTION,
      renderStyleData: true,
      leafId: ID,
    };
  }

  return {
    id: ID,
    name: NAME,
    description: DESCRIPTION,
    renderStyleData: false,
  };
}

export type GenerateCardProps = {
  styleItem: CardViewItem;
  identityPropsConfig: IdentityPropsConfig;
  currentTab?: string;
  adornments: AdornmentType[];
  onItemClick?: (item: ContainerPayload, eventTarget?: HTMLElement) => void;
  onMoreInfoClick?: (item: ContainerPayload) => void;
  popoverTitle?: string;
  isPrintMode?: boolean
};

export const generateCardProps = (props: GenerateCardProps): StandardCardProps => {
  const {
    styleItem,
    identityPropsConfig,
    currentTab,
    adornments,
    onItemClick,
    onMoreInfoClick,
    popoverTitle,
    isPrintMode,
  } = props;
  const { id: idProp, name: nameProp, description: descProp, leafId, renderStyleData } = identityPropsConfig;
  const renderedImg = styleItem.imgSrc;

  const id = mapIdentityToItemProp(idProp, ID, renderStyleData, styleItem);
  const name = mapIdentityToItemProp(nameProp, NAME, renderStyleData, styleItem);
  const description = mapIdentityToItemProp(descProp, DESCRIPTION, renderStyleData, styleItem);

  const cardProps: StandardCardProps = {
    ...styleItem,
    id: id || styleItem.styleId,
    name: name || styleItem.name,
    leafId: !isNil(leafId) ? styleItem[leafId] : undefined,
    description: description || styleItem.description,
    styleDescription: styleItem.description,
    imgSrc: renderedImg,
    onMoreInfoClick,
    onItemClick,
    popoverTitle: popoverTitle,
    isSelected: styleItem.isSelected || false,
    stylePaneKey: currentTab,
    adornments,
    isPrintMode
  };

  return cardProps;
};

export const mapIdentityToItemProp = (
  prop: string,
  propLookup: IdentityPropLookup,
  renderStyleData: boolean,
  styleItem: CardViewItem
) => {
  switch (propLookup) {
    case ID:
      return prop === STYLE_ID ? styleItem.styleId : styleItem[prop];
    case NAME:
      return prop === STYLE_NAME ? styleItem.styleName : styleItem[prop];
    case DESCRIPTION:
      return renderStyleData ? styleItem.styleDescription : styleItem[prop];
  }
};
