import * as React from 'react';
import styles from 'src/components/ScopeSelector/ScopeSelector.styles';
import { FloorsetConfigResponse } from 'src/types/Scope';
import { LensProp } from '../TargetSetting/TargetSetting.types';
import ScopeSelectorDropdown from 'src/components/ScopeSelector/ScopeSelectorDropdown';
import { FloorsetAttrResponse } from '../../../../types/Scope';
import { Lens, Optional } from 'monocle-ts';
import { PeriodFormReduxSlice } from '../DatePicking/PeriodForm.types';
import {
  floorsetDropdownTitle,
  loadingText,
} from 'src/components/ScopeSelector/FloorSetSelectorForm/FloorsetSelectorForm.styles';
import targetStyles from './TargetCreation.styles';
import { classes as mergeClasses } from 'typestyle';
import { SelectedTypes } from 'src/pages/AssortmentStrategy/TargetSetting/TargetCreation/TargetCreation.types';
import { AppState } from 'src/store';

export type ValueProps = {
  floorsetConfig: FloorsetConfigResponse;
  selectedFloorset?: string | null;
  selectedProductMember: string | null;
  invalid?: string;
  isLoading: boolean;
  locked: boolean;
};

export type FunctionProps = {
  onChangeFloorset?(floorset: string): void;
  onClickGetFloorset?(productMemberId: string): void;
};

export type FloorsetReduxSlice = {
  selectedFloorset?: string;
};

export type OwnProps<S = AppState> = LensProp<S, FloorsetReduxSlice> & {
  productIdLens: Lens<S, string>;
  salesLens?: Optional<S, PeriodFormReduxSlice>;
  receiptLens?: Optional<S, PeriodFormReduxSlice>;
  floorsetsLens: Lens<S, FloorsetConfigResponse>;
  selectedLens?: Lens<S, SelectedTypes>;
  updateFloorSetAttribs?(attributes: FloorsetAttrResponse): void;
};

export type FloorsetProps<S> = ValueProps & FunctionProps & OwnProps<S>;

export default class FloorsetSelectorForm<S> extends React.Component<FloorsetProps<S>> {
  constructor(props: FloorsetProps<S>) {
    super(props);
  }

  componentDidMount() {
    const { selectedProductMember } = this.props;
    this.handleClickGetFloorset(selectedProductMember);
  }

  handleFloorsetChange = (id: string): void => {
    const { onChangeFloorset } = this.props;
    if (onChangeFloorset) {
      onChangeFloorset(id);
    }
  };

  handleClickGetFloorset = (productMemberId: string | null): void => {
    const { onClickGetFloorset, isLoading } = this.props;
    if (productMemberId && !isLoading && onClickGetFloorset) {
      onClickGetFloorset(productMemberId);
    }
  };

  render() {
    const { floorsetConfig, selectedFloorset, selectedProductMember, invalid, isLoading } = this.props;

    let placeHolder = <div className={floorsetDropdownTitle}>Getting Floorsets...</div>;

    if (isLoading) {
      placeHolder = (
        <div className={mergeClasses(targetStyles.floorset.loadText, `${loadingText}`)} style={{ cursor: 'wait' }} />
      );
    }

    let floorSet = (
      <div
        onClick={() => this.handleClickGetFloorset(selectedProductMember)}
        className={styles.scopeSelectorDropdownSmall}
      >
        {placeHolder}
      </div>
    );

    if (floorsetConfig && floorsetConfig.length > 0) {
      floorSet = (
        <ScopeSelectorDropdown
          options={floorsetConfig}
          placeholder={'Select Floorset'}
          selection={selectedFloorset || undefined}
          invalid={invalid}
          handleChangeOnDropdown={this.handleFloorsetChange}
          locked={false}
          qa="dropdown-assortmentfloorset"
          applyClass={targetStyles.floorset.form}
        />
      );
    }

    return <React.Fragment>{floorSet}</React.Fragment>;
  }
}
