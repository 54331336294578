import React from 'react';

import ConfigurableGrid from 'src/components/ConfigurableGrid/ConfigurableGrid.container';
import { ConfigurableGridViewComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';

type Props = z.infer<typeof ConfigurableGridViewComponentProps>;

export default class ConfigurableGridView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <ConfigurableGrid
          {...this.props}
          title={this.props.title || 'Assortment by Floorset'}
          showExtraRow={true}
          identityField={null} // not used in ConfigurableGridView, which uses identityProps
        />
      </React.Fragment>
    );
  }
}
