import { style, cssRule } from 'typestyle';

function _p(str: string) {
  return `.${str}`;
}
export const IMGWIDTH = 160 as const;
export const IMGHEIGHT = 240 as const;
export const TOPWIDTH = 350 as const;
export const COLWIDTH = 325 as const;
const HEADERHEIGHT = 45 as const;
const HEADERPADDING = 5 as const;
export const TOPHEIGHT = IMGHEIGHT + HEADERHEIGHT;

const image = style({
  marginTop: HEADERHEIGHT, // pushes image below header
});
const headerRuler = 'top-card-ruler';
const headerText = 'header-text';
const header = style({
  height: HEADERHEIGHT - HEADERPADDING,
  marginBottom: HEADERPADDING,
  display: 'flex',
  flexDirection: 'column-reverse',
  position: 'relative',
  $nest: {
    [_p(headerRuler)]: {
      display: 'block',
      border: 0,
      height: 1,
      position: 'absolute',
      bottom: 0,
      margin: '0 auto 0 auto',
      width: COLWIDTH,
      background: `#333`,
      backgroundImage: `linear-gradient(to right, #ccc, #333, #ccc)`,
    },
    [_p(headerText)]: {
      fontSize: '.9rem',
      fontWeight: 600,
    },
  },
});
const column = style({
  width: COLWIDTH,
  $debugName: 'column',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  paddingLeft: 10,
  maxHeight: `calc(100% - ${HEADERHEIGHT}px)`,
  $nest: {
    '.columnTitle': {
      width: '35%',
      display: 'inline-block',
      fontWeight: 600,
      verticalAlign: 'top',
    },
    '.columnValue': {
      width: '65%',
      display: 'inline-block',
    },
  },
});
const colItem = style({
  width: '100% !important',
  minHeight: 30,
  fontSize: '.75rem',
});
const cardContainer = style({
  // TODO: maybe remove this, as the size is set by the wrapper above the TopCard.tsx level
  height: '100%',
  width: '100%',
});
const swatchesContainer = style({
  $debugName: 'swatches-container',
  width: '100%',
  fontSize: '0.75rem',
  display: 'flex',
  $nest: {
    '.swatches': {
      overflow: 'auto',
    },
  },
});
export default {
  cardContainer,
  headerRuler,
  headerText,
  image,
  column,
  header,
  colItem,
  swatchesContainer,
};
