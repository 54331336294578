import { OwnProps, FunctionProps, SeedOptions } from './SeedOptions';
import { connect } from 'react-redux';
import { find, isArray } from 'lodash/fp';
import { CriteriaOption } from '../Criteria/Criteria.types';
import { update } from 'src/services/lenses/Lenses.actions';
import { Lens } from 'monocle-ts';
import { seedOptions, seedPlan } from '../Criteria/Option.mocks';
import { PeriodFormReduxSlice } from '../DatePicking/PeriodForm.types';
import { SeedPageSlice } from 'src/pages/AssortmentStrategy/TargetSetting/TargetCreation/TargetCreation.types';
import { AppDispatch, AppThunkDispatch, AppState } from 'src/store';

function mapStateToProps<S>(state: S, ownProps: OwnProps<S>) {
  const seedPage = ownProps.lens.get(state);
  return {
    amount: seedPage.amount,
    showSeedWeeks: seedPage.weeks.loaded,
  };
}
function getOptionsFromDataIndex(dataIndex: string, availOptions: { dataIndex: string; options: CriteriaOption[] }[]) {
  const options = find((option) => {
    return option.dataIndex === dataIndex;
  }, availOptions);
  if (options == null) {
    return [] as CriteriaOption[];
  } else {
    return options.options;
  }
}
function mapDispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps<AppState>): FunctionProps {
  const lens = ownProps.lens;
  const factory = {
    onShowView: () => {
      dispatch((innerDispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const currentSelections = lens.compose(Lens.fromProp<SeedPageSlice>()('criteria')).get(state).selections;
        let selections = [seedPlan[0], seedOptions[0].options[0]];
        if (isArray(currentSelections) && currentSelections.length > 0) {
          const firstSelection =
            find((s) => {
              return s.text === currentSelections[0].text;
            }, seedPlan) || selections[0];
          const secondSelection = find((s) => s.dataIndex === firstSelection.dataIndex, seedOptions) || seedOptions[0];
          selections = [firstSelection, secondSelection.options[0]];
        }
        innerDispatch(
          update(
            lens.compose(Lens.fromPath<SeedPageSlice>()(['criteria'])).set({
              selections,
              options: [seedPlan, seedOptions[0].options],
            }),
            'Seed Options: Updating criteria'
          )
        );
        factory.getSeedOptions(selections[0].dataIndex);
      });
    },
    getSeedOptions: (dataIndex: string) => {
      const setterLens = lens.compose(Lens.fromPath<SeedPageSlice>()(['weeks'])).asSetter();
      dispatch(async (innerDispatch: AppDispatch, getState: () => AppState) => {
        const { weeks: currentWeeks, criteria: currentCriteria } = ownProps.lens.get(getState());
        const { daysRangeList, daysPastRangeList, rangeList, pastRangeList } = ownProps.scopeConfigLens.get(getState());
        if (dataIndex === 'userdef') {
          dispatch(
            update(
              setterLens.set({
                loaded: false,
                rangeList,
                daysRangeList,
              }),
              'Seed Options: Clearing week information.'
            )
          );
        } else {
          let newSetup: PeriodFormReduxSlice;
          if (dataIndex === 'ly') {
            newSetup = {
              selectedStartWeek: null,
              selectedEndWeek: null,
              rangeList: pastRangeList,
              daysRangeList: daysPastRangeList,
              loaded: true,
            };
          } else if (dataIndex === 'mfp') {
            newSetup = {
              selectedStartWeek: null,
              selectedEndWeek: null,
              rangeList: rangeList,
              daysRangeList: daysRangeList,
              loaded: true,
            };
          } else {
            return;
          }
          if (dataIndex === currentCriteria.selections[0].dataIndex && currentWeeks.loaded) {
            newSetup = {
              ...newSetup,
              selectedStartWeek: currentWeeks.selectedStartWeek,
              selectedEndWeek: currentWeeks.selectedEndWeek,
            };
          }
          innerDispatch(update(setterLens.set(newSetup), 'Seed Options: Updating Week Information.'));
        }
      });
      return getOptionsFromDataIndex(dataIndex, seedOptions);
    },
    onDestroy: () => {
      // TODO
    },
    updateAmount: (value: number) => {
      const amountSetter = ownProps.lens.compose(Lens.fromProp<SeedPageSlice>()('amount'));
      dispatch(update(amountSetter.set(value), 'Seed Options: Updating Seed Amount'));
    },
  };
  return factory;
}
// ignored becuase this section of code isn't used, and the types appear incorrect
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(SeedOptions);
