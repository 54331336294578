import { style } from 'typestyle';

const chart = 'SGC-Chart';
const IMGWIDTH = 300;
export const styles = {
  IMGWIDTH,
  IMGHEIGHT: 450,
  chartCont: style({
    width: 'calc(100% - 300px)',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    $nest: {
      [`.${chart}`]: {
        width: '50%',
      },
    },
  }),
  container: style({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1.5em 2em',
    $nest: {
      '> header h2': {
        paddingLeft: IMGWIDTH,
        fontSize: '1.4rem',
      },
    },
  }),
  chart,
};

export default styles;
