import { style } from 'typestyle';
import { color, important } from 'csx';
import { TEAL_PRIMARY, RED_PRIMARY } from 'src/utils/Style/Theme';
import { NestedCSSProperties } from 'typestyle/lib/types';

const gray = color('#dee2e6').toHexString();
const enabled = 'enabled';

const contentContainerStyle: NestedCSSProperties = {
  $debugName: 'massEditContainer',
  width: '70vw',
  height: '70vh',
  minWidth: '700px',
  margin: '0 auto',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  $nest: {
    '& article:first-child': {
      maxWidth: '35%',
    },
  },
};
const contentContainer = style(contentContainerStyle);
const contentContainerNoProductList = style({
  $debugName: 'massEditContainerv2',
  margin: '0 auto',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: 400,
});

const contentItemsContainer = style({
  $debugName: 'massEditContentItemsContainer',
  display: 'flex',
});
const verticalContentItemsContainer = style({
  $debugName: 'massEditVerticalContentItemsContainer',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
});

const actionsContainer = style({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const actionButton = style({
  width: '100px',
  height: '40px',
  cursor: 'pointer',
  backgroundColor: TEAL_PRIMARY,
  border: 'none',
  marginRight: '5px',
  fontWeight: 600,
  color: '#ffffff',
  $nest: {
    '&:disabled': {
      cursor: 'not-allowed',
      backgroundColor: '#d6d6d6',
    },
    i: {
      marginRight: '5px',
    },
    '&.cancel': {
      backgroundColor: RED_PRIMARY,
    },
  },
});
const contentItemProperties: NestedCSSProperties = {
  $debugName: 'massEditContentItem',
  padding: '1rem',
  margin: 'auto 0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  flex: '1 0 30%',
  maxWidth: '100%',
  maxHeight: '75%',
  minHeight: '75%',
};
const contentItem = style(contentItemProperties);
const contentItemNoProductList = style({
  $debugName: 'massEditContentItemV2',
  padding: '1rem',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  maxWidth: '100%',
  height: '100%',
  $nest: {
    ' .count-bottom': {
      marginTop: 'auto',
    },
  },
});

const dividerItem = style({
  flex: '0.25 0 4%',
});

const divider = style({
  border: `1px solid ${gray}`,
  height: '100%',
  margin: '0 auto',
});

const selectAllCheckbox = style({
  paddingLeft: important(0),
});

const itemCount = style({
  marginLeft: '0.5rem',
  fontSize: '0.85rem',
});

const sectionTitle = style({
  $debugName: 'massEditSectionTitle',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  marginBottom: '1.5rem',
});

const subSectionTitle = style({
  $debugName: 'massEditSubSectionTitle',
  fontSize: '0.9rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
});

const sectionContent = style({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const sectionContentItem = style({
  $debugName: 'massEditSectionContentItem',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1rem',
  $nest: {
    '&:first-child > div:nth-child(2)': {
      // override styles are not accepted on WeekRangePicker
      maxWidth: '100%',
    },
  },
});

const multiSelectSection = style({
  $debugName: 'massEditMultiSelectSection',
});

const multiSelectSearchContainer = style({
  marginBottom: 20,
});

const multiSelectDetailsContainer = style({
  display: 'flex',
  fontSize: '0.85rem',
  justifyContent: 'space-between',
  alignItems: 'baseline',
});

const multiSelectItemsContainer = style({
  $debugName: 'multiSelectItemsContainer',
  height: '30vh',
  overflow: 'auto',
});

const multiSelectItemContainer = style({
  $debugName: 'massEditMultiSelectItemContainer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  height: '50px',
  fontSize: '0.9rem',
  marginBottom: '0.1rem',
  paddingLeft: '1rem',
  border: `1px solid ${gray}`,
  $nest: {
    [`&.${enabled}`]: {
      color: '#ffffff',
      backgroundColor: TEAL_PRIMARY,
    },
  },
});

const modifierInput = style({
  $debugName: 'massEditModifierInput',
  $nest: {
    input: {
      height: '38px', // matches modifier selector styles
      width: '100%',
      outline: 0,
      border: '1px solid hsl(0, 0%, 80%)', // matches modifier selector styles
      borderRadius: '4px',
      paddingLeft: '0.5rem',
      $nest: {
        '&:disabled': {
          cursor: 'not-allowed',
        },
      },
    },
    // stretch input suggest
    'div.input-group > div': {
      width: '100%',
    },
  },
});
const actionButtonGroup = style({
  $debugName: 'actionButtonGroup',
  $nest: {
    ' .massedit-count': {
      color: 'white',
    },
  },
});

const actionButtonButton = style({
  $debugName: 'actionButton',
  textTransform: important('none'),
});

const actionButtonIcon = style({
  $debugName: 'actionButtonIcon',
  color: 'darkgrey',
  paddingRight: 5,
});

export default {
  contentContainer,
  contentItemsContainer,
  verticalContentItemsContainer,
  contentContainerNoProductList,
  actionsContainer,
  actionButton,
  contentItem,
  contentItemNoProductList,
  dividerItem,
  divider,
  sectionTitle,
  sectionContent,
  sectionContentItem,
  subSectionTitle,
  multiSelectSection,
  multiSelectSearchContainer,
  multiSelectDetailsContainer,
  multiSelectItemsContainer,
  multiSelectItemContainer,
  selectAllCheckbox,
  itemCount,
  enabled,
  modifierInput,
  actionButtonButton,
  actionButtonGroup,
  actionButtonIcon,
};
