import { connect } from 'react-redux';
import { flow } from 'lodash';

import { selectData } from './ListView.selectors';
import container from 'src/ServiceContainer';
import {
  cleanUp,
  receiveError as receiveListViewError,
  requestConfig,
  receiveConfig,
  receiveSomeListViewConfig,
} from './ListView.slice';
import ListGridPair from 'src/components/ListGridPair/ListGridPair';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { AppThunkDispatch } from 'src/store';
import { z } from 'zod';
import { HistoryGridComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { NestedGridViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { CompanionViewDefn, RollupDefn, SortByConfig } from 'src/services/configuration/codecs/viewdefns/general';

export type ListViewOwnProps = z.infer<typeof HistoryGridComponentProps>;

export type DispatchProps = {
  onShowView(): void;
  onDestroy(): void;
  onRefetchData(): void;
  onUpdate?(): void;
  onUpdateConfig?(config: TenantConfigViewData): void;
};

// TODO: once remaining ListGridPair methods no longer rely on onUpdate/onRefetch/onDestroy
// in DispatchProps type, remove from here
function dispatchToProps(dispatch: AppThunkDispatch, ownProps: ListViewOwnProps): DispatchProps {
  const { defns } = ownProps;
  return {
    onShowView() {
      dispatch(requestConfig());
      container.tenantConfigClient
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: ASSORTMENT,
          validationSchemas: [NestedGridViewDefn, SortByConfig, CompanionViewDefn, RollupDefn],
        })
        .then((config) => {
          const unmodifiedViewDefn = config[0];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defns.view[0],
            (config as any)[defns.view.length],
            dispatch,
            unmodifiedViewDefn
          );
          if (localConfig && localConfig.config) {
            config[0] = localConfig.config;
          }
          dispatch(
            receiveConfig({
              grid: config[0],
              listSort: config[1],
              list: config[2],
              subheaderRollUp: config[3],
              unmodifiedViewDefn,
            })
          );
        })
        .catch((error) => {
          dispatch(
            receiveListViewError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.listView,
              issues: error,
            })
          );
        });
    },
    onUpdate() {
      // data refetch handled in epic
    },
    onRefetchData() {
      // data refetch handled in epic
    },
    onUpdateConfig(config: TenantConfigViewData) {
      dispatch(receiveSomeListViewConfig(config));
    },
    onDestroy() {
      dispatch(cleanUp());
    },
  };
}

const wrappedView = flow(() => ListGridPair, makePopoverSensitive, makePrintSensitive)();

export default connect(selectData, dispatchToProps)(wrappedView);
