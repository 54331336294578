import { AppState } from 'src/store';
import { createSelector, OutputSelector } from 'reselect';
import { flattenAndLabelGroup } from 'src/components/StandardCardView/SortAndGroup';
import {
  CardViewDataInputs,
  CardViewGroup,
  CardViewOptionalInputs,
} from 'src/components/StandardCardView/UIData.types';
import { TopTYvsLYSlice } from './TopTYvsLY.slice';
import { ReduxSlice as SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { generateGroupBy } from './TopTYvsLY.container';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { filterData } from 'src/utils/Pivot/Filter';
import { toSummaries, Summary } from 'src/utils/Pivot/RollUp';
import { TreePivot, WorklistInfo } from 'src/worker/pivotWorker.types';
import { getDataFromCache } from 'src/services/pivotServiceCache';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import processDataForCardView from 'src/components/StandardCardView/CardViewDataProcessor';

export type ProcessedDataSelector = OutputSelector<
  AppState,
  CardViewGroup[],
  (res2: TopTYvsLYSlice, subh: SubheaderSlice, treeData: TreePivot, work: WorklistInfo[]) => CardViewGroup[]
>;

const getTreeData = (state: AppState) => {
  return getDataFromCache(state.pages.hindsighting.styleColorReview.topTYvsLY)?.tree || [];
};

const getView = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.topTYvsLY;
};
const getSearchIndexes = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.topTYvsLY.viewDefn.searchIndexes;
};
const getSubheader = (state: AppState) => {
  return state.subheader;
};
const getWorklist = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.sharedData.worklist;
};

export const getSummary = createSelector(
  getView,
  getSearchIndexes,
  getTreeData,
  getSubheader,
  (viewSlice, searchIndexes, treeData, subheader) => {
    const views = viewSlice.calcViewDefn.view;
    const searchFields = searchIndexes ? searchIndexes : externalGridSearchFields;
    if (treeData == null) return [];
    const groupDataIndex = getGroupBySelectedOptionProperty(subheader.groupBy, 'dataIndex');
    const groupDimension = getGroupBySelectedOptionProperty(subheader.groupBy, 'dimension');
    const flat = flattenAndLabelGroup({ items: treeData, groupDataIndex, groupDimension });
    if (flat.length === 0 || !Array.isArray(views) || views.length === 0) {
      return [] as Summary[];
    }
    const filteredFlat = filterData(
      flat,
      subheader.search,
      searchFields,
      subheader.flowStatus,
      subheader.pareDown && subheader.pareDown.selections
    );
    return toSummaries(filteredFlat, views);
  }
);

export const getProcessedData: ProcessedDataSelector = createSelector(
  getView,
  getSubheader,
  getTreeData,
  getWorklist,
  (viewSlice, subheader, treeData, worklist) => {
    if (viewSlice && viewSlice.groupingInfo && subheader) {
      const groupingInfo = viewSlice.groupingInfo;
      const groupBy = generateGroupBy(groupingInfo.dataIndex);
      const dataInputs: CardViewDataInputs = {
        styles: treeData || null,
        subheader: { ...subheader, groupBy },
        defns: {
          view: viewSlice.viewDefn || {},
          rollup: viewSlice.calcViewDefn || {},
        },
      };
      const optionalInputs: CardViewOptionalInputs = {
        worklist,
        staticColumns: groupingInfo.staticColumns,
      };
      return processDataForCardView(dataInputs, optionalInputs);
    }
    return [];
  }
);
