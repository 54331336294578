import * as React from 'react';
import Button from '@material-ui/core/Button';
import addColorButton, { addColorText } from './AddColorButton.styles';

export type Props = {
  onClick: () => void;
};

const StyleEditAddColorButton = (props: Props) => {
  return (
    <Button
      variant="contained"
      className={addColorButton}
      onClick={() => {
        props.onClick();
      }}
    >
      <i className="fas fa-2x fa-plus-circle" />
      <div className={addColorText}>Add color</div>
    </Button>
  );
};

export default StyleEditAddColorButton;
