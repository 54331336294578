import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantConfigViewItem, TenantConfigViewData, ViewDefnState } from 'src/dao/tenantConfigClient';
import { ViewDataState } from 'src/types/Domain';
import { IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import service from 'src/ServiceContainer';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { cacheCheckFetchPivotData } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

type TenantResponse = {
  viewDefn: TenantConfigViewItem;
  calcViewDefn: TenantConfigViewItem;
  identityPropsConfig: IdentityPropsConfig;
};

export type TopPerformersSlice = {
  viewDefn: TenantConfigViewItem;
  unmodifiedViewDefn: TenantConfigViewData;
  calcViewDefn: TenantConfigViewItem;
  identityPropsConfig: IdentityPropsConfig;
  cacheHash: string | null;
  viewDataState: ViewDataState;
  viewDefnState: ViewDefnState;
};

const initialState: TopPerformersSlice = {
  viewDefn: {} as TenantConfigViewItem,
  unmodifiedViewDefn: {} as TenantConfigViewData,
  calcViewDefn: {} as TenantConfigViewItem,
  identityPropsConfig: {} as IdentityPropsConfig,
  cacheHash: null,
  viewDataState: ViewDataState.idle,
  viewDefnState: ViewDefnState.idle,
};

const topPerformersReducer = createSlice({
  name: 'TopPerformers',
  initialState,
  reducers: {
    requestTenantConfig(state) {
      state.viewDefnState = ViewDefnState.loading;
    },
    receiveTenantConfig(state, action: PayloadAction<TenantResponse>) {
      state.viewDefn = action.payload.viewDefn;
      state.calcViewDefn = action.payload.calcViewDefn;
      state.identityPropsConfig = action.payload.identityPropsConfig;
      state.viewDefnState = ViewDefnState.loaded;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataReady;
      }
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestTenantConfig,
  receiveTenantConfig,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  receiveError,
  cleanUp,
} = topPerformersReducer.actions;

export function fetchTopPerformersData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData
  );
}

export default topPerformersReducer.reducer;
