import { isNil, some } from 'lodash';
import { ConfigurableGridConfigItem } from 'src/components/ConfigurableGrid/ConfigurableGrid.types';
import { ViewApiConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { StyleEditConfigColumn } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.types';

export type ObserverObject = {
  dataIndex: string;
  dataApi: ViewApiConfig;
};

export type ObservableGridProps = {
  [observedProp: string]: ObserverObject[];
};

export function isObservedProp(observers: ObservableGridProps, field: string) {
  return !isNil(observers[field]);
}

export function parseObservers(
  observers: ObservableGridProps,
  colInfo: ConfigurableGridConfigItem | StyleEditConfigColumn
) {
  const observersCopy = { ...observers };
  const observed = colInfo.observes;
  const observer = colInfo.dataIndex;
  const duplicate = some(observers[observed], (value) => value.dataIndex === colInfo.dataIndex);
  if (duplicate) {
    return observersCopy;
  }

  if (!isNil(observed) && !isNil(observer)) {
    const observerObj: ObserverObject = {
      dataIndex: observer,
      dataApi: colInfo.dataApi,
    };

    if (isNil(observersCopy[observed])) {
      // add new
      observersCopy[observed] = [observerObj];
    } else {
      // update existing
      const existingObservers = observersCopy[observed];
      observersCopy[observed] = [...existingObservers, observerObj];
    }
  }

  return observersCopy;
}
