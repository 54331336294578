import Axios from 'src/services/axios';

const WORKLIST_API = `api/worklist/`;

// mock action types
const worklistAdd = 'active';
const worklistRemove = 'remove';
const worklistSnooze1 = 'snooze_1d';
const worklistSnooze7 = 'snooze_1w';
const worklistReviewed = 'reviewed';

export type WorklistApiPayload = {
  type:
    | typeof worklistAdd
    | typeof worklistRemove
    | typeof worklistSnooze1
    | typeof worklistSnooze7
    | typeof worklistReviewed;
  product: string;
}[];

type WorklistListItem = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  user_id: string; // idk if we'll actually get this back
  product: string; // should be stylecolor
  type: string; // idk, maybe api payload, then filter on the front end?
};

type WorklistList = WorklistListItem[];

// would rather the return type be the type in this case
/* eslint-disable-next-line */
export function WorklistClient() {
  const client = Axios;
  return {
    getWorklistItems() {
      return client.get<WorklistListItem[]>(WORKLIST_API);
    },
    addToWorklist(product: string) {
      return client.post<WorklistList, WorklistApiPayload>(WORKLIST_API, [{ product, type: worklistAdd }]);
    },
    addItemsToWorklist(products: string[]) {
      const mappedProducts = products.map((p) => ({ product: p, type: worklistAdd }));
      return client.post<WorklistList, WorklistApiPayload>(WORKLIST_API, mappedProducts);
    },
    removeFromWorklist(product: string) {
      return client.post<WorklistList, WorklistApiPayload>(WORKLIST_API, [{ product, type: worklistRemove }]);
    },
    removeItemsFromWorklist(products: string[]) {
      const mappedProducts = products.map((p) => ({ product: p, type: worklistRemove }));
      return client.post<WorklistList, WorklistApiPayload>(WORKLIST_API, mappedProducts);
    },
    snoozeOneDay(product: string) {
      return client.post<WorklistList, WorklistApiPayload>(WORKLIST_API, [{ product, type: worklistSnooze1 }]);
    },
    snoozeItemsOneDay(products: string[]) {
      const mappedProducts = products.map((p) => ({ product: p, type: worklistSnooze1 }));
      return client.post<WorklistList, WorklistApiPayload>(WORKLIST_API, mappedProducts);
    },
    snoozeOneWeek(product: string) {
      return client.post<WorklistList, WorklistApiPayload>(WORKLIST_API, [{ product, type: worklistSnooze7 }]);
    },
    snoozeItemsOneWeek(products: string[]) {
      const mappedProducts = products.map((p) => ({ product: p, type: worklistSnooze7 }));
      return client.post<WorklistList, WorklistApiPayload>(WORKLIST_API, mappedProducts);
    },
    markAsReviewed(product: string) {
      return client.post<WorklistList, WorklistApiPayload>(WORKLIST_API, [{ product, type: worklistReviewed }]);
    },
    markItemsAsReviewed(products: string[]) {
      const mappedProducts = products.map((p) => ({ product: p, type: worklistReviewed }));
      return client.post<WorklistList, WorklistApiPayload>(WORKLIST_API, mappedProducts);
    },
  };
}
