import { ViewDefnState } from 'src/dao/tenantConfigClient';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cacheCheckFetchPivotData } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { ViewDataState } from 'src/types/Domain';
import service from 'src/ServiceContainer';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { TimeChartConfig } from './TimeChart';
import { isNil } from 'lodash';

export type TimeChartSlice = {
  viewDefnState: ViewDefnState;
  viewDataState: ViewDataState;
  cacheHash: string | null;
  viewDefn: TimeChartConfig | null;
};

const initialState: TimeChartSlice = {
  viewDefnState: ViewDefnState.idle,
  viewDataState: ViewDataState.idle,
  cacheHash: null,
  viewDefn: null,
};

const timeChartReducer = createSlice({
  name: 'TimeChart',
  initialState,
  reducers: {
    requestTenantConfig(state) {
      state.viewDefnState = ViewDefnState.loading;
    },
    receiveTenantConfig(state, action: PayloadAction<TimeChartConfig>) {
      state.viewDefnState = ViewDefnState.loaded;
      state.viewDefn = action.payload;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataReady;
      }
    },
    receiveError() {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestTenantConfig,
  receiveTenantConfig,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  receiveError,
  cleanUp,
} = timeChartReducer.actions;

export function fetchTimeChartData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData
  );
}

export default timeChartReducer.reducer;
