import Axios from 'src/services/axios';
import { isNil, uniqBy, uniq, partial, isEmpty, isNumber, isBoolean } from 'lodash';
import * as QueryString from 'query-string';

import { ValidMembersData } from 'src/pages/AssortmentStrategy/TargetSetting/Criteria/Criteria.types';
import { getItemFromCache, addItemToCache, getOrAddItemAsyncCache } from './ValidValuesCache';
import { getAllColors } from '../../AssortmentAdd/Assortment.client';
import { ISASSORTMENT, CC_COLOR } from 'src/utils/Domain/Constants';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

export type ValidValues = {
  id: string;
  name: string;
};

function requestingValidMembers(url: string) {
  return url.includes('validMembers');
}

function requestingDependentValues(url: string) {
  return url.includes('api/v2/dependents'); // probably too specific
}

function mapValidMembers(renderIdOnly: boolean, concatValues: boolean, option: ValidMembersData) {
  if (renderIdOnly) {
    return {
      value: option.id,
      label: option.id,
    };
  }

  return {
    value: option.id,
    label: concatValues ? `${option.id} ${option.name}` : option.name,
  };
}

function mapValidValues(concatValues: boolean, option: ValidMembersData) {
  const labelValue = option.description || option.name;
  const label = concatValues ? `${option.id} ${labelValue}` : labelValue;

  return {
    value: option.id,
    label,
  };
}
export type ValueLabelPair = ReturnType<typeof mapValidValues>;

function mapStringValues(_concatValues: boolean, option: string) {
  return {
    value: option,
    label: option,
  };
}

function getValidMembers(
  url: string,
  insertEmptyOption: boolean,
  concatOptionValues: boolean,
  ignoreCache: boolean,
  renderIdOnly: boolean
) {
  const cachedData = getItemFromCache(url);
  const mapFn = partial(mapValidMembers, renderIdOnly, concatOptionValues);

  if (!isNil(cachedData) && !ignoreCache) {
    // @ts-ignore
    let options = cachedData.map(mapFn);
    if (!insertEmptyOption) {
      options = options.filter((option) => !isEmpty(option.value) || isNumber(option.value) || isBoolean(option.value));
    }

    return Promise.resolve(options);
  }

  return Axios.get(url).then((resp) => {
    const respInfo = resp.data;
    let values = !isNil(respInfo) ? respInfo : [];
    values = !isNil(respInfo.data) ? respInfo.data : values;

    if (insertEmptyOption) {
      values.push({
        id: '',
        name: '',
      });
    }

    values = uniqBy(values, 'id');
    addItemToCache(url, values);

    const options = values.map(mapFn);
    return options;
  });
}

async function getValues(url: string, insertEmptyOption: boolean, concatOptionValues: boolean, ignoreCache: boolean) {
  // getValidValues
  const allValuesLookup = url.includes('validValues/all');
  const urlParts = url.split('=');
  const valuesType = urlParts[urlParts.length - 1];
  const mapFn = partial(mapValidValues, concatOptionValues);
  const vvReq = async () => {
    const respInfo = (await Axios.get(url)).data;
    let values = allValuesLookup ? respInfo.data[valuesType] : respInfo.data;

    if (insertEmptyOption) {
      values.push({
        id: '',
        name: '',
      });
    }

    values = uniqBy(values, 'id');
    return values;
  };

  if (!ignoreCache) {
    const cachedData = await getOrAddItemAsyncCache(url, vvReq);
    // @ts-ignore
    let options = cachedData.map(mapFn);
    if (!insertEmptyOption) {
      options = options.filter((option) => !isEmpty(option.value) || isNumber(option.value) || isBoolean(option.value));
    }

    return options;
  } else {
    const values = await vvReq();
    const options = values.map(mapFn);
    return options;
  }
}

function getDependentValues(url: string, insertEmptyOption: boolean, concatOptionValues: boolean, ignoreCache = false) {
  // parse url query params and get string
  const queryString = QueryString.parse(url);
  const dependentOnId = queryString.id;
  const mapFn = partial(mapStringValues, concatOptionValues);

  const cachedData = getItemFromCache(url);
  if (!isNil(cachedData) && !ignoreCache) {
    // @ts-ignore
    let options = cachedData.map(mapFn);
    if (!insertEmptyOption) {
      options = options.filter((option) => !isEmpty(option.value) || isNumber(option.value) || isBoolean(option.value));
    }

    return Promise.resolve(options);
  }
  return Axios.get(url).then((resp) => {
    const respInfo = resp.data;
    // @ts-ignore
    const values = respInfo.data[dependentOnId];
    let options = !isNil(values) ? values.map(mapFn) : [];

    if (insertEmptyOption) {
      options.push({
        value: '',
        label: '',
      });
    }

    options = uniqBy(options, (option: string) => option['value'].toUpperCase());
    addItemToCache(url, values);
    return options;
  });
}

export function getValidValues(
  url: string,
  insertEmptyOption = true,
  concatOptionValues = false,
  ignoreCache = false,
  renderIdOnly = false
) {
  if (requestingValidMembers(url)) {
    return getValidMembers(url, insertEmptyOption, concatOptionValues, ignoreCache, renderIdOnly);
  } else if (requestingDependentValues(url)) {
    return getDependentValues(url, insertEmptyOption, concatOptionValues, ignoreCache);
  }

  return getValues(url, insertEmptyOption, concatOptionValues, ignoreCache);
}

export function updateLifecycleParams(styleColorId: string, newValue: { [s: string]: any }) {
  const postData = {
    product: styleColorId,
    attributes: { ...newValue },
  };

  return (
    Axios.post('/api/assortment/lifecycleParams?appName=Assortment', postData)
      // tslint:disable-next-line:no-console
      .catch((err: string) => console.error('post error:', err))
  );
}

export function updateRangingParams(styleColorId: string, newValue: { [s: string]: unknown }) {
  // TODO: inject stylepreview cache clearing here
  const postData = {
    product: styleColorId,
    attributes: { ...newValue },
  };

  return (
    Axios.post('/api/assortment/rangingParams?appName=Assortment', postData)
      // tslint:disable-next-line:no-console
      .catch((err: string) => console.error('post error:', err))
  );
}

export function removeStyleColor(styleColorId: string) {
  return (
    Axios.post(`/api/assortment/removeFromAssortment`, null, {
      params: {
        appName: 'Assortment',
        productId: styleColorId,
      },
    })
      // tslint:disable-next-line:no-console
      .catch((err: string) => console.error('post error:', err))
  );
}

export function getExistingColorsInAsst(existingStyleColors: BasicPivotItem[]): string[] {
  return existingStyleColors.filter((i) => i[ISASSORTMENT] === 'true').map((d) => d[CC_COLOR]);
}

export function getExistingColorsNotInAsst(existingStyleColors: BasicPivotItem[]): string[] {
  return existingStyleColors.filter((i) => i[ISASSORTMENT] !== 'true').map((d) => d[CC_COLOR]);
}

export function getAvailableColors(existingStyleColors: BasicPivotItem[], currentStyleColor = '') {
  const existingInAsst = getExistingColorsInAsst(existingStyleColors);
  const mapFn = partial(mapStringValues, false); // concat not applicable here
  return getAllColors()
    .then((colors) => {
      const availableColors = colors.filter((color) => {
        return existingInAsst.indexOf(color) < 0 || color === currentStyleColor;
      });

      return availableColors;
    })
    .then((availableColors) => {
      let values = availableColors;
      values.push('');
      values = uniq(values);

      const options = values.map(mapFn);
      return options;
    });
}
