import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import {
  fetchFloorsetData,
  fetchConfigurableGridData,
  fetchConfigurableGridConfigs,
  receiveFloorsetData,
  setFloorsetSelection,
  cleanUp,
  refreshConfigurableGridData,
  requestConfigurableGridConfig,
  receiveConfigurableGridConfig,
} from './ConfigurableGrid.slice';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded } from 'src/utils/Functions/epicsFunctions';
import { isNil, isEmpty } from 'lodash';
import {
  ConfDefnComponentType,
  StyleAttributesComponent,
  StyleColorAttributesComponent,
  LineByFloorsetComponent,
  ConfigurableGridViewComponent,
  ReceiptGridComponent,
  maybeGetComponentProps,
  isSameComponentType,
} from 'src/services/configuration/codecs/confdefnComponents';
import { receiveWorklistConfig, updateSelectedItem } from 'src/pages/Worklist/Worklist.slice';
import { receiveScope, receiveScopeRefreshTrigger } from 'src/components/ScopeSelector/ScopeSelector.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { isWorklistActive } from 'src/services/configuration/codecs/confdefnComponents';
import { ConfigurableGridOwnProps } from './ConfigurableGrid.types';
import { getViewDefn, getFloorsetData } from './ConfigurableGrid.selectors';
import { ConfigurableGridViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';

export const configurableGridConfigLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(requestConfigurableGridConfig.type, setActivePage.type, setActiveSubPage.type, receiveWorklistConfig.type),
    map(() => {
      return (
        maybeGetComponentProps<StyleAttributesComponent>(state$.value, ConfDefnComponentType.styleAttributes) ||
        maybeGetComponentProps<StyleColorAttributesComponent>(
          state$.value,
          ConfDefnComponentType.styleColorAttributes
        ) ||
        maybeGetComponentProps<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.linePlanByFloorset) ||
        maybeGetComponentProps<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.configurableGrid) ||
        maybeGetComponentProps<ConfigurableGridViewComponent>(
          state$.value,
          ConfDefnComponentType.configurableGridView
        ) ||
        maybeGetComponentProps<ReceiptGridComponent>(state$.value, ConfDefnComponentType.receiptGrid)
      );
    }),
    filter(inputIsNotNullOrUndefined),
    mergeMap(({ configApi }) => {
      if (
        isSameComponentType<StyleAttributesComponent>(state$.value, ConfDefnComponentType.styleAttributes) ||
        isSameComponentType<StyleColorAttributesComponent>(state$.value, ConfDefnComponentType.styleColorAttributes) ||
        isSameComponentType<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.linePlanByFloorset) ||
        isSameComponentType<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.configurableGrid) ||
        isSameComponentType<ConfigurableGridViewComponent>(state$.value, ConfDefnComponentType.configurableGridView) ||
        isSameComponentType<ReceiptGridComponent>(state$.value, ConfDefnComponentType.receiptGrid)
      ) {
        console.info('navigation to same component type detected, cleaning up redux state before data load');
        return of(cleanUp(), fetchConfigurableGridConfigs(configApi));
      }
      return of(fetchConfigurableGridConfigs(configApi));
    })
  );
};

export const configurableGridFloorsetLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScope.type,
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      receiveConfigurableGridConfig.type,
      'LENS_ASST_PLAN_REFRESHING_PAGE'
    ),
    map(() => {
      return (
        maybeGetComponentProps<StyleAttributesComponent>(state$.value, ConfDefnComponentType.styleAttributes) ||
        maybeGetComponentProps<StyleColorAttributesComponent>(
          state$.value,
          ConfDefnComponentType.styleColorAttributes
        ) ||
        maybeGetComponentProps<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.linePlanByFloorset) ||
        maybeGetComponentProps<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.configurableGrid) ||
        maybeGetComponentProps<ConfigurableGridViewComponent>(
          state$.value,
          ConfDefnComponentType.configurableGridView
        ) ||
        maybeGetComponentProps<ReceiptGridComponent>(state$.value, ConfDefnComponentType.receiptGrid)
      );
    }),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope)),
    // we need this since we actually care about view defn now for override
    filter(() => !isNil(getViewDefn(state$.value))),
    mergeMap(({ floorsetApi }) => {
      // Override the componentProps "floorsetApi" with viewDefn's topMember dropdown when it is present
      const viewDefn: ConfigurableGridViewDefn = getViewDefn(state$.value);
      if (!isNil(viewDefn.subheaderDropdowns.topMember)) {
        return of(fetchFloorsetData(viewDefn.subheaderDropdowns.topMember.dataApi));
      } else {
        return of(fetchFloorsetData(floorsetApi));
      }
    })
  );
};

/**
 * This is separated out into it's own epic because it is dependent on the floorset data in the configurableGridFloorsetLoad epic.
 * It will be triggered for initial page load and scope/filter changes whenever the floorset data is loaded.
 *
 */
export const configurableGridLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      updateSelectedItem.type,
      receiveFloorsetData.type,
      setFloorsetSelection.type,
      refreshConfigurableGridData.type
    ),
    map(() => {
      return (
        maybeGetComponentProps<StyleAttributesComponent>(state$.value, ConfDefnComponentType.styleAttributes) ||
        maybeGetComponentProps<StyleColorAttributesComponent>(
          state$.value,
          ConfDefnComponentType.styleColorAttributes
        ) ||
        maybeGetComponentProps<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.linePlanByFloorset) ||
        maybeGetComponentProps<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.configurableGrid) ||
        maybeGetComponentProps<ConfigurableGridViewComponent>(
          state$.value,
          ConfDefnComponentType.configurableGridView
        ) ||
        maybeGetComponentProps<ReceiptGridComponent>(state$.value, ConfDefnComponentType.receiptGrid)
      );
    }),
    filter(inputIsNotNullOrUndefined),
    filter(() => !isNil(getViewDefn(state$.value))),
    filter(({ floorsetApi }) => {
      const inWorklistWithSelected = isWorklistActive(state$.value) ? state$.value.worklist.selectedItem != null : true;

      const viewDefn = getViewDefn(state$.value);
      let floorsetDataAvail = false;
      // check if floorset is needed and exists
      if (!isNil(floorsetApi) || !isNil(viewDefn.subheaderDropdowns.topMember)) {
        floorsetDataAvail = !isEmpty(getFloorsetData(state$.value));
      } else {
        floorsetDataAvail = true;
      }
      return floorsetDataAvail && inWorklistWithSelected;
    }),
    mergeMap((ownProps) => {
      if (
        isSameComponentType<StyleAttributesComponent>(state$.value, ConfDefnComponentType.styleAttributes) ||
        isSameComponentType<StyleColorAttributesComponent>(state$.value, ConfDefnComponentType.styleColorAttributes) ||
        isSameComponentType<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.linePlanByFloorset) ||
        isSameComponentType<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.configurableGrid) ||
        isSameComponentType<ConfigurableGridViewComponent>(state$.value, ConfDefnComponentType.configurableGridView) ||
        isSameComponentType<ReceiptGridComponent>(state$.value, ConfDefnComponentType.receiptGrid)
      ) {
        console.info('navigation to same component type detected, cleaning up redux state before data load');
        return of(cleanUp(), fetchConfigurableGridData(ownProps as ConfigurableGridOwnProps));
      }

      return of(fetchConfigurableGridData(ownProps as ConfigurableGridOwnProps));
    })
  );
};
