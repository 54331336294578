import * as React from 'react';
import {
  FitViewState,
  FitViewData,
  ItemData,
  ExtrinsicItemData,
  FitViewGroupPayload,
  PositionStatus,
} from './FitViewInterfaces';
import FitViewGroup from './FitViewGroup';
import FitViewItem from './FitViewItem';
import styles from './FitView.styles';
import { SyntheticEvent } from 'react';
import { ImageLoader } from './ImageLoader';
import * as lodash from 'lodash';

export class LayoutDetails {
  columns!: number;
  width!: number;
  height!: number;

  static getLayoutDetails(imgCount: number, groupWidth: number, itemAreaHeight: number): LayoutDetails {
    let canvasColumns = 1,
      iRatio = 450 / 300,
      canvasRows = Math.ceil(imgCount / canvasColumns),
      tileWidth = groupWidth / canvasColumns,
      tileHeight = tileWidth * iRatio;

    // We're decreasing the rows and increasing the columns each iteration
    while (tileHeight * canvasRows > itemAreaHeight && tileHeight > 1) {
      canvasColumns++;
      canvasRows = Math.ceil(imgCount / canvasColumns);
      tileWidth = groupWidth / canvasColumns;
      tileHeight = tileWidth * iRatio;
    }

    // noinspection JSSuspiciousNameCombination
    return {
      columns: canvasColumns,
      width: Math.floor(tileWidth),
      height: Math.floor(tileHeight),
    };
  }
}

export default class FitView extends React.Component<FitViewData, FitViewState> {
  loader: ImageLoader; // Image Loader for placeholder loading
  previousItems: Map<string, ItemData> = new Map<string, ItemData>(); // map of last known items by uid
  inCleanup = false; // cleanup flag
  canvas!: HTMLCanvasElement;
  groupRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: FitViewData) {
    super(props);
    this.state = {
      hoverId: '',
      loadingImages: false,
    };

    this.onImageLoad = lodash.throttle(this.onImageLoad.bind(this), 200);
    this.loader = new ImageLoader(this.onImageLoad, this.props.noImageUrl);
  }

  componentDidUpdate(prevProps: FitViewData) {
    const reduceHeaders = (allHeaders: string, g: FitViewGroupPayload): string => {
      return allHeaders + g.header;
    };
    const newGroupHeaders = this.props.groups.reduce(reduceHeaders, '');
    const prevGroupHeaders = prevProps.groups.reduce(reduceHeaders, '');
    if (this.props.width !== prevProps.width || newGroupHeaders !== prevGroupHeaders) {
      setTimeout(() => this.forceUpdate(), 125);
    }
  }

  getHeaderHeight = (groups: FitViewGroupPayload[]) => {
    const totalHeaderText = groups.reduce(
      (acc, group) => acc + group.header + group.subheader.reduce((acc2, s) => acc2 + (s.name + s.value), ''),
      ''
    );
    const groupWidth = this.props.width / groups.length;
    const noLineBreaks = Math.max(((this.getTextWidth(totalHeaderText) / groups.length) * 2.5) / groupWidth);
    return 52 + Math.max(1, noLineBreaks) * 16;
  };

  getTextWidth = (text: string) => {
    // re-use canvas object for better performance
    const canvas = this.canvas || (this.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    if (context) {
      context.font = '12px Open Sans';
      return context.measureText(text).width;
    } else {
      return 0;
    }
  };

  buildItems = (previousItems: Map<string, ItemData>): Map<string, ItemData> => {
    const { groups } = this.props;
    const items: ExtrinsicItemData[] = [];
    const idCounters = new Map<string, number>();
    const prevMap = new Map<string, ExtrinsicItemData>();

    previousItems.forEach((i) => {
      const posStatus = i.positionStatus;
      if (posStatus === PositionStatus.POSITIONED || posStatus === PositionStatus.ENTERING) {
        prevMap.set(i.uid, i);
      }
    });

    groups.forEach((group, groupIndex) => {
      group.items.forEach((item, index) => {
        let count = 0;
        if (idCounters.has(item.id)) {
          const oldCount = idCounters.get(item.id) as number;
          count = oldCount + 1;
        }
        const uid = item.id + '-' + count;
        const posStatus = prevMap.has(uid) ? PositionStatus.POSITIONED : PositionStatus.ENTERING;
        const newItem = {
          id: item.id,
          image: item.image,
          groupIndex,
          index,
          uid: uid,
          positionStatus: posStatus,
        };

        idCounters.set(item.id, count);
        items.push(newItem);
        prevMap.delete(uid);
      });
    });

    for (const removed of prevMap.values()) {
      items.push({
        id: removed.id,
        image: removed.image,
        groupIndex: removed.groupIndex,
        index: removed.index,
        uid: removed.uid,
        positionStatus: PositionStatus.EXITING,
      });
    }
    const result = new Map<string, ItemData>();
    items
      .map(this.getItemLayoutDetails)
      .map((item) => {
        return { ...item };
      })
      .forEach((item) => result.set(item.uid, item));
    return result;
  };

  getItemLayoutDetails = (item: ExtrinsicItemData): ItemData => {
    const { id, groupIndex, index, positionStatus, image } = item;
    const { hoverId } = this.state;
    const { width, height, groups } = this.props;

    let groupWidth = Math.floor(width / Math.max(groups.length, 1)) - 1;
    let offsetY = this.getHeaderHeight(groups);
    if (this.groupRef && this.groupRef.current) {
      groupWidth = this.groupRef.current.offsetWidth;
      if (this.groupRef.current.firstElementChild) {
        offsetY = this.groupRef.current.firstElementChild.clientHeight + 2;
      }
    }
    const offsetX = (groupWidth + 1) * groupIndex + 5;
    const itemsAreaHeight = height - offsetY;
    const maxGroupSize = groups.map((g) => g.items.length).reduce((a, b) => Math.max(a, b), 1);
    const layoutDetails = LayoutDetails.getLayoutDetails(maxGroupSize, groupWidth - 10, itemsAreaHeight);

    const newLeft = (index % layoutDetails.columns) * layoutDetails.width + offsetX;
    const newTop = Math.floor(index / layoutDetails.columns) * layoutDetails.height + offsetY;
    const dx = newLeft - width / 2;
    const dy = newTop - height / 2;
    const theta = Math.atan2(dy, dx);
    const startLeft = Math.round(width * 2 * Math.cos(theta) + width / 2);
    const startTop = Math.round(height * 2 * Math.sin(theta) + height / 2);
    const renderedImage = image ? this.loader.getImageSrc(image) : this.props.noImageUrl;
    const top = positionStatus === PositionStatus.POSITIONED ? newTop : startTop;
    const left = positionStatus === PositionStatus.POSITIONED ? newLeft : startLeft;

    return {
      ...item,
      left: left,
      top: top,
      width: layoutDetails.width,
      height: layoutDetails.height,
      showBorder: id === hoverId,
      renderedImage: renderedImage,
      isAnimated: true,
    };
  };

  onMouseOver = (event: SyntheticEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const dataSet = target.dataset;
    if (dataSet && dataSet.id && this.state.hoverId !== dataSet.id) {
      const item = this.previousItems.get(dataSet.id);
      if (item) {
        this.setState({ hoverId: item.id });
      }
    }
  };

  onMouseOut = () => {
    if (this.state.hoverId !== '') {
      this.setState({ hoverId: '' });
    }
  };

  onImageLoad = () => {
    if (!this.state.loadingImages) {
      window.requestAnimationFrame(() => {
        if (this.inCleanup) {
          return;
        }
        this.setState({ loadingImages: false });
      });
    }
  };

  onClick = (event: SyntheticEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const dataSet = target.dataset;
    if (dataSet && dataSet.id && this.props.onItemClick) {
      const item = this.previousItems.get(dataSet.id);
      if (item) {
        this.props.onItemClick(item, event);
      }
    }
  };

  groupsHtml = (forcedWidth?: number, overrideGroups?: FitViewGroupPayload[]) => {
    const groups = overrideGroups ? overrideGroups : this.props.groups;
    const width = forcedWidth ? forcedWidth : this.props.width;
    const groupWidth = Math.floor(width / Math.max(groups.length, 1));
    const headerHeight = this.getHeaderHeight(groups);
    // Case: No Data
    if (!groups.length) {
      return [
        <FitViewGroup
          key={'group-0'}
          groupIndex={0}
          width={groupWidth}
          maxHeaderHeight={headerHeight}
          header={'No Data'}
          subheader={[]}
          ref={this.groupRef}
        />,
      ];
    }
    return groups.map((group, i) => {
      let header = group.header;
      if (header.length * 7 > groupWidth) {
        const paranthesis = header.indexOf('(');
        header = header.substr(0, Math.floor(groupWidth / 7) - 2) + '...' + header.substr(paranthesis, header.length);
      }
      return (
        <FitViewGroup
          key={'group-' + i}
          {...group}
          header={header}
          width={groupWidth}
          maxHeaderHeight={headerHeight}
          groupIndex={i}
          isAnimated={!forcedWidth}
          ref={this.groupRef}
        />
      );
    });
  };

  itemsHtml = () => {
    const { useSrc } = this.props;
    const newItems = this.buildItems(this.previousItems);
    this.previousItems = newItems;
    const items: ItemData[] = [];
    let numEntering = 0;
    for (const item of newItems.values()) {
      items.push(item);
      if (item.positionStatus === PositionStatus.ENTERING) {
        numEntering++;
      }
    }
    if (numEntering > 0) {
      lodash.delay(() => this.onImageLoad(), 0);
    }

    items.sort((a, b) => a.uid.localeCompare(b.uid));
    return items.map((item) => <FitViewItem key={item.uid} useSrc={useSrc} {...item} />);
  };

  render() {
    const domEvents = {
      onMouseOver: this.onMouseOver,
      onMouseOut: this.onMouseOut,
      onClick: this.onClick,
    };
    if (this.props.width < 0) {
      return <div />;
    }
    let height = this.getHeaderHeight(this.props.groups);
    if (this.groupRef && this.groupRef.current && this.groupRef.current.firstElementChild) {
      height = this.groupRef.current.firstElementChild.clientHeight;
    }
    return (
      <div className={styles.FitView}>
        <div className={styles.getGroupsContainer(this.props.width, this.props.height - 6)}>
          {this.groupsHtml()}
          <div className={styles.horizontalLine} style={{ width: this.props.width, height: height }} />
        </div>
        <div className={styles.itemsContainer} {...domEvents}>
          {this.itemsHtml()}
        </div>
      </div>
    );
  }
}
