import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { receiveViewDefns, updateFlowStatus, updateGroupBy } from 'src/components/Subheader/Subheader.slice';
import { fetchProductMixData, receiveViewDefn } from './ProductMix.slice';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded } from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  ProductMixComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import {
  ExtraPivotOptions,
  getAggBys,
  getConfigureModalAggBys,
  organizeListDataOptions,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { DataApi } from 'src/services/configuration/codecs/confdefnView';
import { LEVEL_PROD_ROOT } from 'src/utils/Domain/Constants';
import { receiveScopeConfig, receiveScopeRefreshTrigger } from 'src/components/ScopeSelector/ScopeSelector.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';

export const productMixLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      receiveViewDefns.type,
      updateFlowStatus.type,
      updateGroupBy.type,
      receiveViewDefn.type,
      receiveScopeConfig.type
    ),
    map(() => maybeGetComponentProps<ProductMixComponent>(state$.value, ConfDefnComponentType.productMix)),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ defns }) => {
      const flowStatus = state$.value.subheader.flowStatus.join(',');

      // TODO: move this to config when ready
      const dataApi: DataApi = {
        defnId: defns.model,
        isListData: true,
        params: {
          aggBy: '',
          topAggBy: LEVEL_PROD_ROOT,
        },
      };

      // need to get current groupBy selection from subheader (minus the default bottom level)
      // and apply a topAggBy from the DataApi config.
      // to be clear there is no configure modal on this screen, just using this for the topAggBy logic
      const currentAggBy = getAggBys(state$.value.subheader, dataApi, {
        includeBottomLevel: false,
        forceReload: true,
        groupingKeyOverride: 'dataIndex',
      });
      const finalAggBy = getConfigureModalAggBys([currentAggBy], dataApi);

      const options: ExtraPivotOptions = { flowStatus, aggBy: finalAggBy };
      const finalOptions = organizeListDataOptions(options, dataApi);

      return of(fetchProductMixData(defns.model, finalOptions));
    })
  );
};
