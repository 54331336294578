import * as React from 'react';
import { useCallback } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { style } from 'typestyle';
import noImagePath from 'src/common-ui/images/noimage.jpg';
import { resolvePath } from 'src/cdn';
const noImage = resolvePath(noImagePath);

const styles = {
  imageHover: style({
    display: 'grid',
    justifyItems: 'center',
    width: '100%',
    $nest: {
      img: {
        position: 'relative',
        width: '50%',
        height: 30,
        objectFit: 'cover',
      },
    },
  }),
};
const ImageRendererWithHover = (props: ICellRendererParams) => {
  const image = props.value;

  // this uses raw dom events because the custom tooltips in ag-grid 20.2.0 are hot garbage
  // and the support for them is very poor
  const handleMouseLeave = useCallback(() => {
    document.querySelectorAll('.hover-image').forEach((img) => {
      img.remove();
    });
  }, []);
  const handleOnMouseOver = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const img = document.createElement('img');
      img.id = 'larger-image';
      img.src = image;
      img.classList.add('hover-image');
      img.setAttribute(
        'style',
        `position: absolute;
    width: 130px;
    height: 150px;
    object-fit: cover;
    top: ${e.clientY}px;
    left: ${e.clientX + 30}px;`
      );
      document.body.appendChild(img);
    },
    [image]
  );
  return (
    <div className={styles.imageHover} onMouseOver={handleOnMouseOver} onMouseLeave={handleMouseLeave}>
      <img alt="" src={image || noImage} />
    </div>
  );
};
export default ImageRendererWithHover;
