import React from 'react';
import { Metric, DirectionalMetric } from 'src/common-ui/components/Metrics/CommonTypes';
import { choiceProductivityStyle } from './ChoiceProductivity.styles';
import { downArrowClass, upArrowClass, neutralArrowClass } from 'src/common-ui/theme';
import Skeleton from '@material-ui/lab/Skeleton';

export type Props = {
  dataLoaded: boolean;
  metrics: [Metric, DirectionalMetric, Metric];
  extraClasses: string;
};

export default function ChoiceProductivity(props: Props) {
  if (!Object.keys(props).length) {
    return null;
  }
  const { dataLoaded, metrics, extraClasses } = props;

  const containerClasses = 'choice-productivity ' + choiceProductivityStyle + (extraClasses ? ' ' + extraClasses : '');

  const [primary, diff, secondary] = metrics;

  let iconClasses = 'dir fa fas ' + (diff.direction === 'down' ? 'down ' + downArrowClass : 'up ' + upArrowClass);

  let middleClasses = 'middle-container ' + (diff.direction === 'down' ? 'negative' : 'positive');

  if (diff.direction === 'neutral') {
    iconClasses = 'dir fa fas neutral ' + neutralArrowClass;
    middleClasses = 'middle-container zero';
  }

  if (!dataLoaded) {
    return (
      <div className={containerClasses}>
        <Skeleton className="left-container left-container-skeleton" variant="text" animation="wave" />
        <Skeleton className="middle-container-skeleton" variant="circle" animation="wave" width={60} height={60} />
        <div className="right-container">
          <section className="secondary-skeleton">
            <Skeleton className="arrow-skeleton dir" variant="text" animation="wave" />
            <Skeleton className="diff-skeleton" variant="text" animation="wave" />
          </section>
          <Skeleton variant="text" animation="wave" />
        </div>
      </div>
    );
  }

  return (
    <div className={containerClasses}>
      <div className="left-container">{primary.label}</div>
      <div className={middleClasses}>{primary.rendered}</div>
      <div className="right-container">
        <div className="diff-container">
          <i className={iconClasses} />
          <span className="diff">{diff.rendered}</span>
        </div>
        <div className="secondary">
          {secondary.label} {secondary.rendered}
        </div>
      </div>
    </div>
  );
}
