import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { compile } from 'seshat';
import { Renderer } from 'src/utils/Domain/Renderer';
import { uniqBy, isNil } from 'lodash';
import { ID, XTYPE_UNIQUE } from 'src/utils/Domain/Constants';

export type Summary = {
  rendered: string;
  raw: number;
  label: string;
  visible?: boolean;
};

export function processSummaries(
  data: BasicPivotItem[],
  rollUpConfigs: TenantConfigViewItem[],
  identityField: string = ID
): Summary[] {
  try {
    return rollUpConfigs.map((configItem) => {
      let rendered = '';
      let possiblyUniqueData = data;

      // TODO: Move this unique step to Seshat.
      if (configItem.xtype === XTYPE_UNIQUE) {
        possiblyUniqueData = uniqBy(data, identityField);
      }
      // Remove groups from rollup calculation
      possiblyUniqueData = possiblyUniqueData.filter((x) => !x['$$GroupHeader']);
      // const oArray: Record<string, any>[] = [];
      // possiblyUniqueData.forEach(
      //   (x) => {
      //     oArray.push({
      //       [configItem.dataIndex]: x[configItem.dataIndex] == undefined ? 0 : x[configItem.dataIndex]
      //     })
      //   }
      // );
      const raw = compile(configItem.formula || '0').execute(possiblyUniqueData);
      if (configItem.renderer && Renderer[configItem.renderer]) {
        rendered = Renderer[configItem.renderer](raw);
      }

      return {
        rendered,
        raw,
        visible: configItem.atSummaryLevel,
        label: configItem.text,
      };
    });
  } catch (e) {
    console.error(e);
    return [];
  }
}

export function toSummaries(
  flatData: BasicPivotItem[],
  rollUpConfigs: TenantConfigViewItem[],
  identityField: string = ID
): Summary[] {
  return processSummaries(flatData, rollUpConfigs, identityField);
}

export function formatSummaries(summaries: Summary[]) {
  return summaries
    .filter((summary) => {
      return isNil(summary.visible) || summary.visible == true;
    })
    .map((summary) => `${summary.label}: ${summary.rendered}`)
    .join(' | ');
}
