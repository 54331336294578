import { AppState } from 'src/store';
import { createSelector } from 'reselect';
import { Lens } from 'monocle-ts';
import { has, isEmpty, isNil } from 'lodash';
import { ReduxSlice as FilterSlice } from 'src/components/FilterPanel/FilterPanel.container';
import { ScopeSelectorSlice } from 'src/components/ScopeSelector/ScopeSelector.slice';
import { TOP_DOWN_URI, HINDSIGHTING_CONTEXT } from '../../utils/Domain/Constants';
import { RightContainerSlice } from '../RightContainer/RightContainer.slice';
import { QuickReconConf } from '../Reconcilation/ReconcileModal';
import { PrintSize } from '../higherOrder/Print/Print';
import { tenantTabHasPathSlot } from 'src/services/configuration/bindings.types';
import { PlanItem } from 'src/pages/AssortmentBuild/Planning.slice';
import { CartButton } from 'src/services/configuration/codecs/confdefn';
import { getSectionContext, getSectionContextObj } from 'src/utils/Domain/Perspective';

export type StateSelection = ScopeSelectorSlice & {
  filters: FilterSlice;
  activeTabId: string;
  isTopDownView: boolean;
  cartCount: number;
  rightContainer: RightContainerSlice;
  isViewPrintable: boolean;
  isCartEnabledInContext: boolean;
  cartButtonConfig?: CartButton;
  quickReconConf?: QuickReconConf;
  printSizes: PrintSize[];
  planTracker: PlanItem[];
  assortmentCartLink?: string;
};

export type StateProjection = StateSelection & {
  scopeStartKey: string;
  scopeEndKey: string;
  scopeRangesKey: string;
};

function getScopeFromContext(context: string) {
  let start = 'historyStart';
  let end = 'historyEnd';
  let scopeRangesKey = 'pastRangeList';

  if (context !== HINDSIGHTING_CONTEXT) {
    start = 'start';
    end = 'end';
    scopeRangesKey = 'rangeList';
  }
  return {
    scopeStartKey: start,
    scopeEndKey: end,
    scopeRangesKey,
  };
}

function getCartCount(state: AppState) {
  return Lens.fromPath<AppState>()(['cart', 'cartItems']).get(state) || [];
}

function selectState(state: AppState): StateSelection {
  const { scope, print, filters, perspective, rightContainer, appConfig } = state;
  const isTopDownView = !isNil(perspective.selected) ? perspective.selected.pathSlot === TOP_DOWN_URI : false;
  // We get the active tab from the url, so we need to remove the dash to get the id. Not the best way to do this.

  const activeTab = appConfig.tenantConfig.tabs.find(
    (tab) => tenantTabHasPathSlot(tab) && perspective.activeTab == tab.pathSlot
  );
  const planTracker = state.planTracker.planItems || [];
  const quickReconConf = !isNil(activeTab) && tenantTabHasPathSlot(activeTab) ? activeTab.quickReconConf : undefined;
  const context = getSectionContext();
  const contextObj = getSectionContextObj();
  const scopeKeys = getScopeFromContext(context);
  const cartButtonConfig = contextObj?.cartBtn;
  const isCartEnabledInContext = has(contextObj, 'cartBtn');
  const assortmentCartLink = perspective.viewLinks.find((view) => view.name === 'Assortment Cart')?.link;

  return {
    ...scope,
    isViewPrintable: print.isViewPrintable,
    cartCount: getCartCount(state).length,
    filters: filters,
    activeTabId: activeTab ? activeTab.id : '',
    quickReconConf: !isEmpty(quickReconConf) ? quickReconConf : undefined,
    isTopDownView,
    rightContainer: rightContainer,
    cartButtonConfig,
    printSizes: state.appConfig.tenantConfig.printSizes,
    planTracker,
    isCartEnabledInContext,
    assortmentCartLink,
    ...scopeKeys,
  };
}

export function projectState(stateSelection: StateSelection) {
  return {
    ...stateSelection,
  };
}

export default createSelector(selectState, projectState);
