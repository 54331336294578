import BoundSectionZipper from 'src/pages/NavigationShell/NavigationZipper/BoundSectionZipper';
import BoundTabZipper from 'src/pages/NavigationShell/NavigationZipper/BoundTabZipper';
import BoundViewZipper from 'src/pages/NavigationShell/NavigationZipper/BoundViewZipper';
import BoundTenantZipper from 'src/pages/NavigationShell/NavigationZipper/BoundTenantZipper';
import { BoundTenant } from 'src/services/configuration/bindings.types';

export { BoundSectionZipper, BoundTabZipper, BoundViewZipper, BoundTenantZipper };

export type BoundZipper = BoundTenantZipper | BoundSectionZipper | BoundTabZipper | BoundViewZipper;

export function zipper(tenant: Readonly<BoundTenant>): BoundTenantZipper {
  return new BoundTenantZipper(tenant);
}
