import { contains, find, flow, map, compact, curry, isNil } from 'lodash/fp';

import {
  TenantConfig,
  TenantTab,
  TenantLeftNavSection,
  TenantView,
  Perspective,
  LeftSectionBinding,
  BoundView,
  BoundSection,
  BoundTab,
  BoundPerspective,
  BoundTenant,
  ViewComponent,
} from './bindings.types';
import { TOP_DOWN_PERSPECTIVE, BOTTOM_UP_PERSPECTIVE, CONFIGURATOR_PERSPECTIVE } from 'src/utils/Domain/Constants';

import CategorySummary from 'src/pages/Hindsighting/CategoryRecap/CategorySummary/CategorySummary.container';
import ListView from 'src/pages/Hindsighting/HistoryGrid/ListView/ListView.container';
import NestedAttribute from 'src/pages/Hindsighting/AggregateViews/NestedAttribute/NestedAttribute.container';
import NestedOvertime from 'src/pages/AssortmentBuild/OvertimeView/NestedOvertime/NestedOvertimeContainer';
import NestedStyleOvertime from 'src/pages/AssortmentBuild/OvertimeView/StyleByLevel/StyleByLevelOvertime';
import NestedView from 'src/pages/Hindsighting/HistoryGrid/NestedView/NestedView.container';
import ProductDetails from 'src/pages/Hindsighting/AggregateViews/ProductDetails/ProductDetails.container';
import ProductMix from 'src/pages/Hindsighting/CategoryRecap/ProductMix/ProductMix.container';
import Productivity from 'src/pages/Hindsighting/CategoryRecap/Productivity/Productivity.container';
import {
  ParetoSummary,
  ParetoDetails,
} from 'src/pages/Hindsighting/Performance/ParetoAnalysis/ParetoAnalysis.container';
import CollectionView from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.container';
import CanvasView from 'src/pages/Hindsighting/StyleColorReview/CanvasView/CanvasView.container';
import SummaryView from 'src/pages/Hindsighting/StyleColorReview/SummaryView/SummaryView.container';
import GridView from 'src/pages/Hindsighting/StyleColorReview/GridView/GridView.container';
import TopTYvsLY from 'src/pages/Hindsighting/StyleColorReview/TopTYvsLY/TopTYvsLY.container';
import FlowType from 'src/pages/Hindsighting/StyleColorReview/FlowType/FlowType.container';
import Summary from 'src/pages/Hindsighting/MacroTrends/Summary/Summary.container';
import ExceptionsSummary from 'src/pages/Hindsighting/Exceptions/ExceptionsSummary/ExceptionsSummary.container';
import GeoTrends from 'src/pages/Hindsighting/MacroTrends/GeoTrends/GeoTrends.container';
import MacroMix from 'src/pages/Hindsighting/MacroTrends/MacroMix/MacroMix.container';
import TopPerformers from 'src/pages/Hindsighting/MacroTrends/TopPerformers/TopPerformers.container';
import TargetList from 'src/pages/AssortmentStrategy/TargetSetting/TargetList/TargetList.container';
import StyleEdit from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.container';
import StyleAttributes from 'src/pages/AssortmentBuild/LinePlan/StyleAttributes/StyleAttributes';
import StyleColorAttributes from 'src/pages/AssortmentBuild/LinePlan/StyleColorAttributes/StyleColorAttributes';
import LinePlanByFloorset from 'src/pages/AssortmentBuild/LinePlan/LinePlanByFloorset/LinePlanByFloorset';
import ConfigurableGridView from 'src/pages/AssortmentBuild/LinePlan/ConfigurableGridView/ConfigurableGridView';
import ReceiptGrid from 'src/components/ReceiptGrid/ReceiptGridComponent';
import FlowSheetByStyle from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle.container';
import AssortmentCart from 'src/pages/AssortmentCart/AssortmentCart.container';
import AssortmentAddView from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentAddView.container';
import AssortmentAddBySearch from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentAddBySearch/AssortmentAddBySearch';
import PricingOverTime from 'src/pages/AssortmentBuild/Pricing/PricingOverTime.container';
import FloorsetComparison from 'src/pages/AssortmentBuild/FloorsetComparison/FloorsetComparison.container';
import AssortmentPublish from 'src/pages/AssortmentBuild/AssortmentPublish/AssortmentPublish.container';
import Reporting from 'src/pages/Reporting/Reporting.container';
import BulkImport from 'src/pages/Reporting/BulkImport/BulkImport';
import ErrorBoundary from 'src/components/ErrorBoundary/GridErrorBoundary';
import Worklist from 'src/pages/Worklist/Worklist.container';
import ParameterToggles from 'src/pages/Allocation/ParameterToggles/ParameterToggles.container';
import SizeEligibilityListGrid from 'src/pages/Allocation/SizeEligibilityListGrid/SizeEligibilityListGrid.container';
import PlanogramSimple from 'src/pages/AssortmentStrategy/PlanogramSimple/PlanogramSimple.container';
import RouteToLocation from 'src/components/RouteToLocation/RouteToLocation';
import ConfigEditor from 'src/components/TabGrid/ConfigEditor';
import { PerspectiveConfig, isPerspective } from 'src/pages/PerspectiveSelection/PerspectiveSelection';

/* eslint-disable @typescript-eslint/naming-convention */
export const componentMapping = {
  AssortmentAddBySearch,
  AssortmentAddView,
  AssortmentCart,
  AssortmentPublish,
  CanvasView,
  CategorySummary,
  CollectionView,
  FloorsetComparison,
  FlowSheetByStyle,
  FlowType,
  GeoTrends,
  GridView,
  LinePlanByFloorset,
  ConfigurableGridView,
  ReceiptGrid,
  ListView,
  MacroMix,
  NestedAttribute,
  NestedOvertime,
  NestedStyleOvertime,
  NestedView,
  ParetoDetails,
  ParetoSummary,
  PricingOverTime,
  Productivity,
  ProductDetails,
  ProductMix,
  Reporting,
  BulkImport,
  StyleAttributes,
  StyleColorAttributes,
  StyleEdit,
  Summary,
  SummaryView,
  TargetList,
  TopPerformers,
  TopTYvsLY,
  ExceptionsSummary,
  Worklist,
  ParameterToggles,
  SizeEligibilityListGrid,
  PlanogramSimple,
  RouteToLocation,
  // TODO: Remove config editor
  ConfigEditor,
};
/* eslint-enable @typescript-eslint/naming-convention */

export const bindView = curry((perspective: Perspective, binding: LeftSectionBinding, tenantView: TenantView):
  | BoundView
  | undefined => {
  // Construct a bound content view for a given perspective.
  // This invokes the component factory defined in bindings with the current perspective
  if (!tenantView.hidden && contains(perspective, tenantView.inPerspectives)) {
    if (isNil(componentMapping[tenantView.component])) {
      console.error(`${tenantView.component} is an invalid component. (Route: ${tenantView.pathSlot})`);
      return {
        // TODO: build a real view-component error component here
        // @ts-ignore
        component: ErrorBoundary,
      };
    }
    return {
      ...tenantView,
      disabled: tenantView.disabled || false,
      component: componentMapping[tenantView.component] as ViewComponent,
      overflow: tenantView.overflow || 'hidden',
    };
  }
  return;
});

export const bindSection = curry((perspective: Perspective, tenantSection: TenantLeftNavSection):
  | BoundSection
  | undefined => {
  if (!tenantSection.hidden && contains(perspective, tenantSection.inPerspectives)) {
    const boundViews = flow(
      map(bindView(perspective)((tenantSection as unknown) as LeftSectionBinding)),
      compact
    )(tenantSection.views);
    return {
      ...tenantSection,
      disabled: tenantSection.disabled || false,
      defaultViewId: tenantSection.defaultView,
      boundViews: (boundViews as unknown) as BoundView[],
    };
  }
  return;
});

export const bindTenantTab = curry((perspective: Perspective, tenantTab: TenantTab): BoundTab | undefined => {
  // For a given perspective and tenant config tab
  // Construct a bound tab description by recursively binding each view section in the config against the perspective
  if (!tenantTab.hidden && contains(perspective, tenantTab.inPerspectives)) {
    let boundSections: BoundSection[] | undefined = undefined;

    if (tenantTab.leftNavSections) {
      boundSections = flow(map(bindSection(perspective)), compact)(tenantTab.leftNavSections);
    }

    return {
      id: tenantTab.id,
      name: tenantTab.name,
      pathSlot: tenantTab.pathSlot,
      defaultSectionId: tenantTab.defaultSection,
      boundSections,
      disabled: tenantTab.disabled,
    };
  }
  return;
});

export const bindTenant = curry(
  (perspective: BoundPerspective, tenant: TenantConfig): BoundTenant => {
    // For a given perspective and tenant configuration
    // Construct a bound ui description by recursively binding each tenant config tab against the perspective.
    const tabs = tenant.tabs as TenantTab[];
    const boundTabs = compact(map(bindTenantTab(perspective.id), tabs));
    const defaultTabString = tenant.defaultTab[perspective.id];
    const defaultBoundTab = find((tab) => tab.id === defaultTabString, boundTabs);

    return {
      defaultTabSlot: defaultBoundTab ? defaultBoundTab.pathSlot : undefined,
      boundTabs,
      perspective,
    };
  }
);

export function bindPerspectives(conf: TenantConfig, perspectiveConf: PerspectiveConfig): BoundPerspective[] {
  // For every perspective in the tenant config verify that we have a matching perspective and augemnt the structure
  // with bookeeping we need to drive the views.
  const perspectives = perspectiveConf.view.filter(isPerspective);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return compact(
    conf.perspectives.map((id) => {
      const perspective = perspectives.find((x) => id === x.id);
      if (
        (id === TOP_DOWN_PERSPECTIVE || id === BOTTOM_UP_PERSPECTIVE || id === CONFIGURATOR_PERSPECTIVE) &&
        perspective
      ) {
        return perspective;
      }
      return;
    })
  );
}
