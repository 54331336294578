import { FilterData } from 'src/types/Scope';
import Axios from 'src/services/axios';
import { FilterSelection } from 'src/common-ui/components/Filters/Filters';
import { AppType } from 'src/types/Domain';
import { setSessionFilters } from 'src/components/RestoreSession/RestoreSession.utils';

const FILTER_PATH = '/api/filter';

type FilterParams = {
  appName: AppType;
  section: string;
};

export type AvailableFilterParams = FilterParams & {
  filterSelections: FilterSelection[];
  attributeId?: string;
};
//[{"filterId":"category_name","filterSet":["MW Jeans"]}]

export type FilterCondition = {
  filterId: string;
  filterSet: string[];
};

export interface FilterClient {
  setFilterSelections(selections: FilterSelection[], params: FilterParams): Promise<boolean>;
  getFullFilters(params: FilterParams): Promise<FilterData>;
  getOnlyFilterSelections(appName: AppType): Promise<FilterCondition[]>;
  getAvailableFilterSelections(params: AvailableFilterParams): Promise<FilterData>;
}

export function makeFilterClient(): FilterClient {
  function getFullFilters(params: FilterParams): Promise<FilterData> {
    return (
      Axios.get(FILTER_PATH, { params })
        // TODO: We probably should validate the entire response.
        // Presumably the envelope has relevant information
        .then((response) => {
          return response.data.data;
        })
    );
  }

  function getOnlyFilterSelections(appName: AppType): Promise<FilterCondition[]> {
    return (
      Axios.get<{ data: FilterCondition[] }>(`${FILTER_PATH}/selections?appName=${appName}`)
        // TODO: We probably should validate the entire response.
        // Presumably the envelope has relevant information
        .then((response) => {
          return response.data.data;
        })
    );
  }

  function getAvailableFilterSelections(params: AvailableFilterParams): Promise<FilterData> {
    return Axios.post(`${FILTER_PATH}/getAvailableSelections`, params).then((response) => {
      return response.data.data;
    });
  }

  function setFilterSelections(selections: FilterSelection[], params: FilterParams): Promise<boolean> {
    setSessionFilters(selections);
    return Axios.post(FILTER_PATH, {
      ...params,
      filterSelections: selections,
    }).then((response) => {
      return response.status === 200;
    });
  }

  return {
    getFullFilters,
    getOnlyFilterSelections,
    setFilterSelections,
    getAvailableFilterSelections,
  };
}
