import React from 'react';
import {
  SelectedWeekRange,
  AllowedWeekRange,
} from 'src/pages/Hindsighting/StyleColorReview/CanvasView/CanvasView.slice';
import { WeekRange } from 'src/common-ui/components/WeekRange/WeekRangePicker.interface';
import { getCurrentAndPastDatesFromRange } from 'src/components/ScopeSelector/AssortmentPeriodForm/AssortmentPeriodForm';
import WeekRangePicker from 'src/common-ui/components/WeekRange/WeekRangePicker';
import { size, mapValues, merge, isNil } from 'lodash';
import { Getter, Lens } from 'monocle-ts';
import { update } from 'src/services/lenses/Lenses.actions';
import { connect } from 'react-redux';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { DaysRangeListResponse } from 'src/types/Scope';
import { AppState, AppThunkDispatch } from 'src/store';
import { fetchAssortmentAddData, AssortmentAddSlice } from './AssortmentAdd.slice';
import { getWeeksFitView } from './AssortmentAddView.utils';
import {
  ExtraPivotOptions,
  organizeListDataOptions,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { ScopeSelectorSlice } from 'src/components/ScopeSelector/ScopeSelector.slice';

type AssortmentAddRangePickerProps = ScopeSelectorSlice &
  SelectedWeekRange &
  AllowedWeekRange & {
    isAssortment: boolean;
    allowToggle: boolean;
  };
type DispatchProps = {
  weeksChanged: (range: SelectedWeekRange) => void;
  isAssortmentChanged: (newIsAssortment: boolean) => void;
};
type AllProps<S> = AssortmentAddRangePickerProps & OwnProps<S> & DispatchProps;
class AssortmentAddRangePicker<S> extends React.Component<AllProps<S>> {
  constructor(props: AllProps<S>) {
    super(props);
  }

  onNewWeeksSelected = (range: WeekRange) => {
    const { daysRangeList, daysPastRangeList } = this.props;
    const mergedRangeList = (mapValues(daysRangeList, (list, key: keyof DaysRangeListResponse) => {
      return merge(list, daysPastRangeList[key]);
    }) as unknown) as DaysRangeListResponse;
    const dates = getCurrentAndPastDatesFromRange(range, mergedRangeList, daysPastRangeList);
    this.props.weeksChanged({
      from: dates.start || dates.historyStart,
      to: dates.end || dates.historyEnd,
    });
  };

  render() {
    const {
      rangeList,
      pastRangeList,
      daysRangeList,
      daysPastRangeList,
      from,
      to,
      isAssortment,
      allowFrom,
      allowTo,
    } = this.props;
    const actRangeList = !isAssortment ? pastRangeList : rangeList;
    const actDaysRangeList = !isAssortment ? daysPastRangeList : daysRangeList;
    if (size(actRangeList) <= 0 || size(actDaysRangeList) <= 0) {
      return <div />;
    }
    let labelStyle: Record<string, any>;
    if (this.props.allowToggle) {
      labelStyle = { color: TEAL_PRIMARY, cursor: 'pointer' };
    } else {
      labelStyle = {};
    }
    return (
      <div className="asst-add-range-picker" style={{ width: 170 }}>
        <div style={{ fontSize: '9pt' }}>
          Search in&nbsp;
          <span
            style={labelStyle}
            onClick={() => this.props.allowToggle && this.props.isAssortmentChanged(!this.props.isAssortment)}
          >
            {isAssortment ? 'Assortment' : 'History'}
          </span>
          :
        </div>
        <div>
          <WeekRangePicker
            autoSelect={true}
            allowFrom={allowFrom}
            allowTo={allowTo}
            dateToNameMap={actDaysRangeList}
            initialSelectedTo={to}
            initialSelectedFrom={from}
            numberOfMonths={2}
            onNewWeeksSelected={this.onNewWeeksSelected}
            selectionOptions={[{ label: 'Start' }, { label: 'End' }]}
          />
        </div>
      </div>
    );
  }
}

export type OwnProps<S> = {
  scopeGetter: Getter<S, ScopeSelectorSlice>;
  addStylesSlice: Lens<S, AssortmentAddSlice>;
  assortmentModel?: string;
};

function mapStateToProps(state: AppState, ownProps: OwnProps<AppState>): AssortmentAddRangePickerProps {
  const { scopeRange, assortmentRange } = ownProps.addStylesSlice.get(state);
  const scope = ownProps.scopeGetter.get(state);
  const {
    pastRangeList,
    rangeList: assortmentRangeList,
    daysPastRangeList,
    daysRangeList: daysAssortmentRangeList,
  } = scope;
  const rangeList = assortmentRange ? assortmentRangeList : pastRangeList;
  const daysRangeList = assortmentRange ? daysAssortmentRangeList : daysPastRangeList;

  let allowFrom = '';
  let allowTo = '';
  if (Array.isArray(rangeList) && rangeList.length > 0) {
    // Makes sure values are in both rangeList and daysRangeList
    const startRangeList = rangeList.filter((x) => Object.values(daysRangeList.start_date).indexOf(x.id) !== -1);
    const endRangeList = rangeList.filter((x) => Object.values(daysRangeList.end_date).indexOf(x.id) !== -1);
    allowFrom = startRangeList[0].id;
    allowTo = endRangeList[endRangeList.length - 1].id;
  }

  const allowToggle = !isNil(ownProps.assortmentModel);
  let range: SelectedWeekRange;
  if (scopeRange) {
    range = scopeRange;
  } else {
    range = {
      from: assortmentRange ? scope.scope.start! : scope.scope.historyStart!,
      to: assortmentRange ? scope.scope.end! : scope.scope.historyEnd!,
    };
  }
  const rangePickerProps: AssortmentAddRangePickerProps = {
    ...ownProps.scopeGetter.get(state),
    ...range,
    isAssortment: !!assortmentRange,
    allowToggle,
    allowFrom,
    allowTo,
  };
  return rangePickerProps;
}

function mapDispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps<AppState>): DispatchProps {
  const reload = () => {
    dispatch((_dispatch: AppThunkDispatch, getState: () => AppState) => {
      const { modelDefn, scopeRange, assortmentRange } = ownProps.addStylesSlice.get(getState());

      if (modelDefn != null) {
        const rangeData = getWeeksFitView(scopeRange, assortmentRange);
        const options: ExtraPivotOptions = { ...rangeData };
        const finalOptions = organizeListDataOptions(options);
        dispatch(fetchAssortmentAddData(modelDefn, finalOptions));
      }
    });
  };
  return {
    isAssortmentChanged: (newIsAssortment) => {
      dispatch((_ign: AppThunkDispatch, getState: () => AppState) => {
        const { viewDefn } = ownProps.addStylesSlice.get(getState());
        const scope = ownProps.scopeGetter.get(getState());
        let newModelDefn: string;
        let range: SelectedWeekRange;
        if (newIsAssortment) {
          newModelDefn = viewDefn.assortmentModel || '';
          range = {
            from: scope.scope.start!,
            to: scope.scope.end!,
          };
        } else {
          newModelDefn = viewDefn.model || viewDefn.assortmentModel || '';
          range = {
            from: scope.scope.historyStart!,
            to: scope.scope.historyEnd!,
          };
        }
        dispatch(
          update(
            ownProps.addStylesSlice
              .compose(Lens.fromProps<AssortmentAddSlice>()(['scopeRange', 'assortmentRange', 'modelDefn']))
              .set({
                scopeRange: range,
                assortmentRange: newIsAssortment,
                modelDefn: newModelDefn,
              })
          )
        );
      });

      reload();
    },
    weeksChanged: (range) => {
      dispatch(
        update(
          ownProps.addStylesSlice.compose(Lens.fromProp<AssortmentAddSlice>()('scopeRange')).set(range),
          'Updating range of Assortment View Search.'
        )
      );
      reload();
    },
  };
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(AssortmentAddRangePicker);
