import { TenantConfigViewData } from 'src/dao/tenantConfigClient';

export type CompanionDataLookup = {
  starsId: string | null;
  imageUrlId: string;
  titleId: string;
  displayTitle?: string;
  bodyId: string;
  [key: string]: any;
};

export type ListViewMain = {
  main: {
    stars: string;
    image: string;
    title: string;
    body: string;
    displayTitle: string | undefined;
    [key: string]: string | undefined;
  };
};

export function parseCompanionListViewConfig(listViewConfig: TenantConfigViewData | ListViewMain): CompanionDataLookup {
  const { stars, image, title, body, displayTitle } = listViewConfig.main!;

  return {
    ...listViewConfig.main,
    starsId: stars || null,
    imageUrlId: image!,
    titleId: title!,
    displayTitle,
    bodyId: body!,
  };
}
