import { makePivotClient } from './dao/pivotClient';
import { makeScopeClient, ScopeClient } from 'src/dao/scopeClient';
import { makeObjectDeserializer } from 'src/dao/objectDeserializer';
import { makePivotService, PivotService } from 'src/services/pivotService';
import { makeTenantConfigClient, TenantConfigClient } from 'src/dao/tenantConfigClient';
import { makeFilterClient, FilterClient } from 'src/dao/filterClient';
import { ASSORTMENT, CONFIGURATOR } from 'src/utils/Domain/Constants';

import configServiceFactory, { ConfigurationService } from 'src/services/configuration';
import { makePrintService, PrintService } from 'src/services/Print/PrintService';
import { makeReportClient, ReportClient } from 'src/dao/reportClient';
import LoggingService from './services/loggingService';
import { PivotServiceType } from './worker/pivotWorker.types';

export type ServiceContainer = {
  pivotService: PivotService;
  scopeClient: ScopeClient;
  tenantConfigClient: TenantConfigClient;
  filterClient: FilterClient;
  configService: ConfigurationService;
  configuratorConfigService: ConfigurationService;
  printService: PrintService;
  reportClient: ReportClient;
  loggingService: LoggingService;
};

export function makeServiceContainer(): ServiceContainer {
  const pivotClient = makePivotClient(makeObjectDeserializer());

  return {
    pivotService: makePivotService(PivotServiceType.app, pivotClient),
    scopeClient: makeScopeClient(),
    filterClient: makeFilterClient(),
    tenantConfigClient: makeTenantConfigClient(),
    configService: configServiceFactory(ASSORTMENT),
    configuratorConfigService: configServiceFactory(CONFIGURATOR),
    printService: makePrintService(),
    reportClient: makeReportClient(),
    loggingService: new LoggingService(),
  };
}

export default makeServiceContainer();
