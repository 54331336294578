import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded } from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  FloorsetComparisonComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import {
  fetchFloorsetComparisonData,
  fetchFloorsetsAndSelect,
  setSelectedFloorsetIndex,
  receiveTenantConfig,
  setGroupingInfo,
} from './FloorsetComparison.slice';
import { receiveScope, receiveScopeRefreshTrigger } from 'src/components/ScopeSelector/ScopeSelector.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { isEmpty } from 'lodash';

export const floorsetComparisonLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScope.type,
      // the above action is only needed since it is potentially dispatched after
      // setActivePage is dispatched and floorset comparison data fetch
      // needs scope info that may not be ready yet.
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      setActivePage.type,
      setActiveSubPage.type,
      setSelectedFloorsetIndex.type,
      receiveTenantConfig.type
    ),
    map(() =>
      maybeGetComponentProps<FloorsetComparisonComponent>(state$.value, ConfDefnComponentType.floorsetComparison)
    ),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ defns, groupingInfo }) => {
      const { model: modelDefn } = defns;

      const { selectedFloorsetIndex, floorsets } = state$.value.pages.assortmentBuild.floorsetComparison;
      const productMember = state$.value.scope.scope.productMember;
      const topMembers = selectedFloorsetIndex >= 0 ? floorsets[selectedFloorsetIndex].id : '';
      const options: ListDataOptions = { topMembers };

      // fetchFloorsetAndSelect requires product member
      // fetchFloorsetComparision requires top member value

      // if both are missing, skip data fetches
      if (isEmpty(productMember) && isEmpty(topMembers)) {
        return of();
      }

      // if we have productMember but no floorsets, fetch floorsets and select
      if (!isEmpty(productMember) && isEmpty(floorsets)) {
        return of(setGroupingInfo(groupingInfo), fetchFloorsetsAndSelect());
      }

      // could possibly check for topMembers value here but not really necessary
      return of(setGroupingInfo(groupingInfo), fetchFloorsetComparisonData(modelDefn, options));
    })
  );
};
