import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { inputIsNotNullOrUndefined, isScopeDefined } from 'src/utils/Functions/epicsFunctions';
import { isEmpty } from 'lodash';
import {
  ConfDefnComponentType,
  PricingComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';

import {
  fetchOverTimeData,
  updateSelectedItem,
  refreshOvertimeData,
} from 'src/pages/AssortmentBuild/Pricing/PricingOverTime.slice';

export const pricingOverTimeLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(updateSelectedItem.type, refreshOvertimeData.type),
    filter((action) => {
      if (action.type === updateSelectedItem.type && isEmpty(action.payload)) {
        return false;
      }
      return true;
    }),
    map(() => maybeGetComponentProps<PricingComponent>(state$.value, ConfDefnComponentType.pricing)),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope)),
    mergeMap((_componentProps) => {
      const { selectedItem } = state$.value.pages.assortmentBuild.pricing;
      if (!isEmpty(selectedItem)) {
        return of(fetchOverTimeData(selectedItem));
      }

      return of();
    })
  );
};
