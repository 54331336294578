import { connect, Dispatch } from 'react-redux';
import DimensionForm from './DimensionForm';
import { Member } from 'src/types/Scope';
import { AppState } from 'src/store';
import { changeScopeSelection, clearFloorsetAndQuickSelect } from '../ScopeSelector.slice';
import { Perspective } from 'src/services/configuration/bindings.types';
import { sortBy } from 'lodash';

export type DispatchProps = {
  onChangeProductLevel(productLevelId: string): void;
  onChangeProductMember(productMemberId: string): void;
  onChangeLocationLevel(locationLevelId: string): void;
  onChangeLocationMember(locationMemberId: string): void;
};

export type RouteProps = {
  perspective: Perspective;
};

function mapStateToProps(state: AppState) {
  const { selections, selectionErrors } = state.scope;
  let productLevels: Member[] = [];
  let locationLevels: Member[] = [];
  if (state.scope.dimensionHierConfig) {
    productLevels = sortBy(state.scope.dimensionHierConfig.product, 'name');
    locationLevels = sortBy(state.scope.dimensionHierConfig.location, 'name');
  }

  return {
    selectionErrors,
    // SPIKE: do we need to sort these by name like previously?
    productLevels,
    locationLevels,
    selectedProductMember: selections.productMember,
    selectedLocationMember: selections.locationMember,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AppState>): DispatchProps {
  return {
    onChangeProductLevel(productLevel: string): void {
      dispatch(changeScopeSelection({ productLevel }));
    },
    onChangeProductMember(productMember: string): void {
      dispatch(changeScopeSelection({ productMember }));
      dispatch(clearFloorsetAndQuickSelect());
    },
    onChangeLocationLevel(locationLevel: string): void {
      dispatch(changeScopeSelection({ locationLevel }));
    },
    onChangeLocationMember(locationMember: string): void {
      dispatch(changeScopeSelection({ locationMember }));
    },
  };
}
export type StateProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps, mapDispatchToProps)(DimensionForm);
