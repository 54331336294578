import React from 'react';
import { connect } from 'react-redux';
import { isNil, flow, get } from 'lodash';

import { Overlay } from 'src/common-ui/index';

import { listPairStyle } from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import SubheaderContainer from 'src/components/Subheader/Subheader.container';
import { OvertimeView } from '../OvertimeView';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ASSORTMENT_BUILD_FILTER_ALL_WARNING } from 'src/utils/Domain/Constants';
import ConfigureModal, { ConfigureModalProps, Option, OptionGroup } from 'src/components/Configure/ConfigureModal';
import { ConfigureOvertimeConfig } from '../OvertimeView.types';
import container from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { getHeaderTextFromState } from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { NestedOvertimeComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { NestedOvertimeViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import {
  receiveError,
  receiveTenantConfig,
  requestTenantConfig,
  setConfigureSelections,
  setTopMember,
} from './NestedOvertime.slice';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { isViewDefnLoaded } from 'src/dao/tenantConfigClient';
import { isDataLoaded } from 'src/services/pivotServiceCache';

export type NestedOvertimeOwnProps = z.infer<typeof NestedOvertimeComponentProps>;

export type NestedOvertimeDispatchProps = {
  onShowView: () => void;
  updateConfigureSelections: (sections: Option[]) => void;
  onRefresh: (selectedId: string) => void;
  onError: (error: any) => void;
};
type NestedOvertimeValueProps = NestedOvertimeOwnProps & {
  customHeader: string;
  data: BasicPivotItem[];
  viewConfig: ConfigureOvertimeConfig;
  topMember?: string;
  configureSelections: Option[];
  defaultConfigureSelections: Option[];
  configureOptions?: OptionGroup[];
  configLoaded: boolean;
  dataLoaded: boolean;
  rowHeight: number;
};
export type NestedOvertimeProps = NestedOvertimeValueProps & NestedOvertimeDispatchProps;

type AllStates = {
  configureIsOpen: boolean;
  uncommitedConfigureSelections: Option[];
  search?: string;
};
type State = AllStates;

class NestedOvertimeContainer extends React.Component<NestedOvertimeProps, State> {
  constructor(props: NestedOvertimeProps) {
    super(props);
    this.state = {
      configureIsOpen: false,
      search: '',
      uncommitedConfigureSelections: this.props.defaultConfigureSelections,
    };
  }

  componentDidMount() {
    this.props.onShowView();
  }

  renderConfigureModal = () => {
    const configureModalProps: ConfigureModalProps = {
      enabled: true,
      isOpen: this.state.configureIsOpen,
      optionGroups: this.props.configureOptions || [],
      selections: this.state.uncommitedConfigureSelections || [],
      instructions: 'Select Aggregation Levels',
      onReset: () => {
        this.setState({
          uncommitedConfigureSelections: this.props.defaultConfigureSelections,
        });
      },
      onToggleModal: (action) => {
        const nextState: Partial<State> = {
          configureIsOpen: !this.state.configureIsOpen,
        };
        switch (action) {
          case 'apply': {
            if (this.state.uncommitedConfigureSelections) {
              this.setState({
                configureIsOpen: false,
              });
              this.props.updateConfigureSelections(this.state.uncommitedConfigureSelections);
            }
            break;
          }
          default: {
            this.setState({
              configureIsOpen: false,
              uncommitedConfigureSelections: this.props.configureSelections,
            });
          }
        }
        // TODO: There has to be a better way... :/
        this.setState(nextState as any);
      },
      selectionUpdate: (selections: Option[]) => {
        this.setState({
          uncommitedConfigureSelections: selections,
        });
      },
    };
    return <ConfigureModal {...configureModalProps} />;
  };

  render() {
    if (!this.props.configLoaded) {
      return <Overlay visible={true} type={'loading'} qaKey={'overtime-loading'} />;
    }

    const { title = 'Nested Over Time' } = this.props;
    return (
      <div className={listPairStyle}>
        <SubheaderContainer
          title={title}
          showSearch={true}
          showFlowStatus={false}
          errorCondition={ASSORTMENT_BUILD_FILTER_ALL_WARNING}
          configureOptions={{
            type: 'enabled',
            onConfigureClick: () => {
              this.setState({
                configureIsOpen: true,
                uncommitedConfigureSelections: this.props.configureSelections,
              });
            },
          }}
          searchReturn={(newVal: string) => {
            this.setState({
              search: newVal,
            });
          }}
        />
        <div className={'data-container'}>
          <React.Fragment>
            <OvertimeView
              loaded={this.props.dataLoaded}
              viewConfig={this.props.viewConfig}
              rowHeight={this.props.rowHeight}
              aggBys={(this.props.configureSelections || []).map((sel) => sel.dataIndex)}
              treeData={this.props.data}
              search={this.state.search}
              exportOptions={{
                fileName: title,
                customHeader: this.props.customHeader,
              }}
            />
          </React.Fragment>
          {this.renderConfigureModal()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: NestedOvertimeOwnProps): NestedOvertimeValueProps {
  const customHeader = getHeaderTextFromState(state, true);
  const viewState = state.pages.hindsighting.nestedOvertime;
  const {
    viewDataState,
    viewDefnState,
    data,
    viewDefn,
    topMember,
    configureSelections,
    defaultConfigureSelections,
    configureOptions,
    rowHeight,
  } = viewState;
  const configLoaded = isViewDefnLoaded(viewDefnState);
  const dataLoaded = isDataLoaded(viewDataState);
  return {
    ...ownProps,
    data,
    viewConfig: viewDefn,
    topMember,
    configureSelections,
    defaultConfigureSelections,
    configureOptions,
    configLoaded,
    dataLoaded,
    customHeader,
    rowHeight,
  };
}

function mapDispatchToProps(
  dispatch: AppThunkDispatch,
  { defns }: NestedOvertimeOwnProps
): NestedOvertimeDispatchProps {
  const client = container.tenantConfigClient;

  return {
    async onShowView() {
      dispatch(requestTenantConfig());
      const configResponse = await client.getTenantViewDefn<ConfigureOvertimeConfig>({
        defnId: defns.view[0],
        appName: 'Assortment',
        validationSchema: NestedOvertimeViewDefn,
      });

      const defaultConfigureSelections = configResponse.configure.defaults.map((key, ind) => {
        const options = configResponse.configure.view[ind].options;
        return options.find((i) => i.dataIndex === key) || options[0];
      });
      const configureOptions = configResponse.configure.view;
      const configureSelections = configResponse.configure.defaults.map((key, ind) => {
        const options = configResponse.configure.view[ind].options;
        return options.find((i) => i.dataIndex === key) || options[0];
      });

      const rowHeight = configResponse.main && configResponse.main.rowHeight ? configResponse.main.rowHeight : 30;

      dispatch(
        receiveTenantConfig({
          defaultConfigureSelections,
          configureOptions,
          configureSelections,
          viewDefn: configResponse,
          rowHeight,
        })
      );
    },
    updateConfigureSelections(uncommittedSelections: Option[]) {
      dispatch(setConfigureSelections(uncommittedSelections));
    },
    onRefresh(selectedId: string) {
      dispatch(setTopMember(selectedId));
    },
    onError(error: any) {
      dispatch(
        receiveError({
          type: ComponentErrorType.config,
          message: (error as Error)?.message,
          name: ConfDefnComponentType.nestedOvertime,
        })
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NestedOvertimeContainer);
