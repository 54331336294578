import { connect } from 'react-redux';
import { BoundPerspective, BoundTab } from 'src/services/configuration/bindings.types';
import { setFocusedGroup, setCollapsed } from './SideNav.slice';
import { SidenavWrapper } from 'src/components/Sidenav/Sidenav';
import { Location } from 'history';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { PrintProps } from 'src/components/higherOrder/Print/Print';
import { AppState, AppThunkDispatch } from 'src/store';

export type InlineProps = {
  className: string;
  sectionName: string;
  perspective: BoundPerspective;
  tab: BoundTab;
};

type StateProps = {
  groupId: string | null;
  collapsed?: boolean;
};

type RouterProps = {
  location: Location;
};

export type DispatchProps = {
  setFocusedGroup(groupId: string | null): void;
  setCollapsed(collapsed?: boolean): void;
};

export type Props = StateProps & DispatchProps & RouterProps & PrintProps;

function stateToProps(state: AppState): StateProps {
  return {
    groupId: state.sidenav.groupId,
    collapsed: state.sidenav.collapsed,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch): DispatchProps {
  return {
    setFocusedGroup(groupId: string) {
      return dispatch(setFocusedGroup(groupId));
    },
    setCollapsed(collapsed: boolean) {
      return dispatch(setCollapsed(collapsed));
    },
  };
}

export const Sidenav = (inline: InlineProps) => {
  const printSensitive = makePrintSensitive(SidenavWrapper(inline));
  return connect(stateToProps, dispatchToProps)(printSensitive);
};
