import { z } from 'zod';
import {
  BasicViewItem,
  NestedColumnsBasicViewItem,
  NestedOptionsBasicViewItem,
  NestedViewBasicViewItem,
  zCompanionView,
  ConfigRefs,
  ConfigurableGridActions,
  zConfigurableGridGraph,
  MainView,
  MassEditConfig,
  SalesAdjustment,
  SearchIndexes,
  SubheaderDropdown,
  WorklistTab,
  ParetoSection,
  StyleEditHeader,
  StyleEditSection,
  TimeLevels,
  MainViewDefnConfig,
  GridMainViewDefn,
  CompanionViewDefn,
  zConfigurablePostAction,
  zTopAttribute,
  ConfigureViewDefn,
} from 'src/services/configuration/codecs/viewdefns/general';
import { zAdornments } from 'src/services/configuration/codecs/viewdefns/literals';
import { DataApiConfigZod } from '../confdefnView';

export const HasAdornments = z.object({ adornments: z.array(zAdornments).default([]) });

// Adding to this validator type will allow certain features to be configured for all views.
// This just means the component will support adornments, it will still need to be configured to render in the card/grid items
const BaseViewDefn = HasAdornments;

const zConfigurableGridTopAttributes = z
  .object({
    title: z.string().optional(),
    expanded: z.boolean().optional(),
    left: z.array(zTopAttribute),
    right: z.array(zTopAttribute),
  })
  .optional();

export const ConfigurableGridViewDefn = BaseViewDefn.merge(
  z.object({
    title: z.string(),
    // TODO: can this shape be converted to match zCompanionView
    // TODO: update name in viewdefn to companionList,
    // it's configured as CompanionGrid but really represents the companion list's config
    companionGrid: z.object({
      label: z.string(),
      sortConfig: z.object({
        defaults: z.string().optional(),
        view: z.array(BasicViewItem),
      }),
    }),
    subheaderDropdowns: z.object({
      groupBy: SubheaderDropdown.optional(),
      topMember: z
        .object({
          label: z.string(),
          dataApi: DataApiConfigZod,
        })
        .optional(),
    }),
    columns: z.array(BasicViewItem),
    main: GridMainViewDefn.optional(),
    actions: ConfigurableGridActions.optional(),
    $configRefs: ConfigRefs.optional(),
    salesAdjustment: SalesAdjustment,
    showPublish: z.boolean().optional(),
    hideUnpublish: z.boolean().optional(),
    publishText: z.string().optional(),
    publishAttribute: z.string().optional(),
    massEditConfig: MassEditConfig.optional(),
    updateCoordinateMap: z.record(z.string()).optional(),
    topAttributes: zConfigurableGridTopAttributes,
    configurablePostAction: zConfigurablePostAction.optional(),
    redecorateMap: z.record(z.string()).optional(),
    configure: ConfigureViewDefn.optional(),
    graph: zConfigurableGridGraph.optional(),
  })
);

export type ConfigurableGridViewDefn = z.infer<typeof ConfigurableGridViewDefn>;

export const zWorklistViewDefn = BaseViewDefn.merge(
  z.object({
    title: z.string(),
    defaultTab: z.string(),
    tabs: z.array(WorklistTab),
    companionView: zCompanionView,
  })
);

export type WorklistViewDefn = z.infer<typeof zWorklistViewDefn>;

export const NestedGridViewDefn = BaseViewDefn.merge(
  z.object({
    id: z.string(),
    type: z.literal('grid'),
    main: GridMainViewDefn,
    // This is a legacy property used for AA Receipt Grid, but now we used the 0th entry of the componentProps.defns.view array as this value
    // This functionality should always pull from componentProps.defns.model and the model property in the viewDefn should be removed later
    // INT-2616 tracks the need for this migration
    model: z.string().optional(),
    view: z.array(NestedColumnsBasicViewItem.or(BasicViewItem)),
  })
);

export const ParetoSummaryViewDefn = BaseViewDefn.merge(
  z.object({
    id: z.string(),
    view: z.array(ParetoSection),
  })
);

export const ProductMixViewDefn = BaseViewDefn.merge(
  z.object({
    id: z.string(),
    defaults: z.string(),
    view: z.array(NestedViewBasicViewItem).or(BasicViewItem),
  })
);

export const ProductivityViewDefn = BaseViewDefn.merge(
  z.object({
    view: z.array(NestedViewBasicViewItem).or(BasicViewItem),
  })
);

export const StyleEditViewDefn = BaseViewDefn.merge(
  z.object({
    title: z.string(),
    searchIndexes: SearchIndexes,
    sectionsViewDefns: z.array(z.string()),
    companionView: zCompanionView,
  })
);

export const StyleEditSectionsViewDefn = BaseViewDefn.merge(
  z.object({
    header: StyleEditHeader,
    sections: z
      .array(StyleEditSection)
      .optional()
      .default([]),
  })
);

export const NestedOvertimeViewDefn = BaseViewDefn.merge(
  z.object({
    title: z.string(),
    main: GridMainViewDefn.optional(),
    columns: z.array(BasicViewItem),
    // TODO: needs to match the other configure view defn shape 'ConfigureViewDefn' in general.ts
    configure: z.object({
      title: z.string(),
      defaults: z.array(z.string()),
      view: z.array(NestedOptionsBasicViewItem),
    }),
    timeLevels: TimeLevels.optional(),
  })
);

export const NestedStyleOvertimeViewDefn = BaseViewDefn.merge(
  z.object({
    title: z.string(),
    main: GridMainViewDefn,
    columns: z.array(BasicViewItem),
    companionGrid: z.object({
      label: z.string(),
      sortConfig: z.object({
        defaultSelection: z.string(),
        options: z.array(BasicViewItem),
      }),
    }),
    timeLevels: TimeLevels.optional(),
  })
);

export const CanvasViewDefn = BaseViewDefn.merge(
  z.object({
    main: MainViewDefnConfig,
    model: z.string().optional(),
    view: z.array(BasicViewItem),
    text: z.string().optional(),
    assortmentModel: z.string().optional(),
  })
);

export const SummaryViewDefn = BaseViewDefn.merge(
  z.object({
    main: MainViewDefnConfig,
    view: z.array(BasicViewItem),
  })
);

export const GridViewDefn = BaseViewDefn.merge(
  z.object({
    type: z.literal('grid'),
    main: GridMainViewDefn,
    view: z.array(BasicViewItem.or(NestedColumnsBasicViewItem)),
    searchIndexes: z.array(z.string()).optional(),
  })
);

export const FlowTypeViewDefn = BaseViewDefn.merge(
  z.object({
    searchIndexes: z.array(z.string()).optional(),
    main: MainViewDefnConfig,
    view: z.array(BasicViewItem),
  })
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TopTYvsLYViewDefn = BaseViewDefn.merge(
  z.object({
    searchIndexes: z.array(z.string()).optional(),
    main: MainViewDefnConfig,
    view: z.array(BasicViewItem),
  })
);

export const CollectionViewDefn = BaseViewDefn.merge(
  z.object({
    type: z.literal('rollUp'),
    searchIndexes: z.array(z.string()).optional(),
    view: z.array(BasicViewItem),
  })
);

export const FloorsetComparisonViewDefn = BaseViewDefn.merge(
  z.object({
    main: MainView,
    view: z.array(BasicViewItem),
  })
);

export const zFlowSheetGridDefn = BaseViewDefn.merge(
  z.object({
    inputType: z.literal('grid'),
    model: z.string(),
    main: GridMainViewDefn,
    views: z.array(BasicViewItem),
    timeLevels: TimeLevels.optional(),
  })
);

export type FlowSheetGridDefn = z.infer<typeof zFlowSheetGridDefn>;

const MassEditViewDefn = z.union([
  z.object({
    title: z.string(),
    modifierTypes: z.array(BasicViewItem).optional(),
    dataIndex: z.string().optional(),
  }),
  z.object({
    dataIndex: z.string(),
    editor: z.string(),
    title: z.string(),
  }),
]);

export const MassEditDefn = BaseViewDefn.merge(
  z.object({
    hideEmpty: z.boolean().optional(),
    views: z.array(MassEditViewDefn),
  })
);
// this PricingOverTime and GridViewDefn are exactly same shape, but only 1 minor differrent view and views.
export const PricingOverTimeDefn = BaseViewDefn.merge(
  z.object({
    type: z.literal('grid'),
    main: GridMainViewDefn,
    views: z.array(BasicViewItem),
    searchIndexes: z.array(z.string()).optional(),
  })
);

export const SizeEligibilityListGridDefn = BaseViewDefn.merge(
  z.object({
    id: z.string(),
    type: z.string(),
    main: z.object({
      title: z.string(),
    }),
    companionApi: CompanionViewDefn,
    update: z.object({
      type: z.string(),
      keys: z.object({
        product: z.string(),
        cluster: z.string(),
      }),
    }),
    columns: z.array(BasicViewItem),
  })
);
// This type below for ParameterToggle, but I think it's already validated with the type,
// So I still keep it here in case we need to use it. At the end, if we don't need to use this,
// then I will delete it later
// const ParameterTogglesFields = z.object({
//   text: z.string(),
//   editor: z.string(),
//   radioFields: z.array(BasicViewItem),
// });

// const ParameterTogglesSections = z.object({
//   title: z.string(),
//   icon: z.string(),
//   fields: z.array(ParameterTogglesFields),
// });

// export const ParameterTogglesDefn = z.object({
//   title: z.string(),
//   sections: z.array(ParameterTogglesSections),
//   allocPlanModel: z.string(),
// });

export const PublishAllocationGridDefn = BaseViewDefn.merge(
  z.object({
    dataApi: z.object({
      isListData: z.boolean(),
      defnId: z.string(),
      params: z.object({
        aggBy: z.string(),
      }),
    }),
    main: GridMainViewDefn,
    title: z.string(),
    view: z.array(BasicViewItem),
  })
);
