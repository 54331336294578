import React from 'react';
import moment from 'moment';

import WeekRangePicker from 'src/common-ui/components/WeekRange/WeekRangePicker';
import { WeekRange } from 'src/common-ui/components/WeekRange/WeekRangePicker.interface';
import styles from '../ScopeSelector.styles';
import { DispatchProps, StateProps } from './HindsightingPeriodForm.container';
import FloorsetSelectorForm from 'src/components/ScopeSelector/FloorSetSelectorForm/FloorsetSelectorForm.container';
import { HINDSIGHTING_CONTEXT } from 'src/utils/Domain/Constants';

type Props = StateProps & DispatchProps;

export default function HindsightingPeriodForm(props: Props) {
  const {
    selectedEndWeek,
    selectedStartWeek,
    daysRangeList,
    allowFrom,
    allowTo,
    onChangeStartWeek,
    onChangeEndWeek,
  } = props;

  const onNewWeeksSelected = (range: WeekRange) => {
    const { from, to } = range;
    const fromDate = moment(from).format('YYYY-MM-DD');
    const toDate = moment(to).format('YYYY-MM-DD');
    const fromId = daysRangeList.start_date[fromDate];
    const toId = daysRangeList.end_date[toDate];
    onChangeStartWeek(fromId);
    onChangeEndWeek(toId);
  };

  return (
    <div className={styles.scopeColumn}>
      <FloorsetSelectorForm region={HINDSIGHTING_CONTEXT} />
      <div className="picker" data-qa="datepicker-hindsightingperiod">
        <WeekRangePicker
          autoSelect={true}
          allowFrom={allowFrom}
          allowTo={allowTo}
          dateToNameMap={daysRangeList}
          initialSelectedFrom={selectedStartWeek || ''}
          initialSelectedTo={selectedEndWeek || ''}
          onNewWeeksSelected={(range: WeekRange) => onNewWeeksSelected(range)}
          selectionOptions={[{ label: 'Start' }, { label: 'End' }]}
        />
      </div>
      <div className="text">Hindsighting Period</div>
    </div>
  );
}
