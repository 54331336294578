import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  Chip,
  Typography,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import HistoryIcon from '@material-ui/icons/History';
import moment from 'moment';
import { isEmpty, isNil, take } from 'lodash';
import { Overlay } from 'src/common-ui';
import { AppState, AppThunkDispatch } from 'src/store';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { usePlanQueueStyles } from 'src/components/PlanQueue/PlanQueue';
import { asyncUndoItem, UNDO_ENDPOINT, UndoItem } from 'src/pages/AssortmentBuild/Planning.slice';
import planQueueStyles from 'src/components/PlanQueue/PlanQueue.styles';
import Axios from 'src/services/axios';
import { ViewDataState } from 'src/types/Domain';

const useUndoStyles = makeStyles((_theme: Theme) =>
  createStyles({
    detailTooltipContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0.5rem 0.15rem',
    },
    detailTooltipContainerSection: {
      marginBottom: '0.15rem',
    },
    detailContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      height: '55px',
    },
    detailHeader: {
      display: 'flex',
      alignItems: 'baseline',
    },
    detailTitle: {
      marginRight: '0.5rem',
    },
    detailCount: {
      width: '25px',
      height: '20px',
      fontSize: '0.75rem',
    },
    modificationsContainer: {
      display: 'flex',
      overflowX: 'auto',
    },
    modificationsText: {
      borderRadius: '5px',
      marginRight: '0.15rem',
      height: 20,
      fontSize: '0.8rem',
    },
    list: {
      width: '100%',
      paddingTop: 0,
    },
  })
);

function mapStateToProps(state: AppState) {
  const loaded = isDataLoaded(state.planTracker.undoViewState);
  return {
    loaded,
    items: state.planTracker.undoItems,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch) {
  return {
    onItemUndo(undoId: string) {
      dispatch(asyncUndoItem(undoId));
    },
  };
}

interface UndoItemLevelProps {
  levelId: string;
}

const UndoItemLevel = ({ levelId }: UndoItemLevelProps) => {
  const classes = useUndoStyles();
  return (
    <Chip
      label={levelId}
      variant="outlined"
      size="small"
      classes={{
        sizeSmall: classes.modificationsText,
      }}
    />
  );
};

type UndoItemDetailsProps = Pick<
  UndoItem,
  'undoId' | 'planMeasure' | 'examplarMemberTie' | 'modifiedRecords' | 'modificationLevelTie' | 'createdAt'
> & {
  itemInitiator: string;
};

const UndoItemDetails = ({
  undoId,
  planMeasure,
  examplarMemberTie,
  modifiedRecords,
  modificationLevelTie,
  itemInitiator,
  createdAt,
}: UndoItemDetailsProps) => {
  const classes = useUndoStyles();
  const [memberTies, updateMemberTies] = useState<string[] | null>(null);
  const [viewDataState, setViewDataState] = useState<ViewDataState>(ViewDataState.idle);

  const countDisplay = modifiedRecords < 99 ? modifiedRecords.toString() : '99+';
  const modificationLevels =
    !isNil(examplarMemberTie) && !isEmpty(examplarMemberTie) ? examplarMemberTie : modificationLevelTie;

  const onTooltipOpen = async () => {
    if (viewDataState === ViewDataState.liveDataLoadingNoCache || viewDataState === ViewDataState.liveDataReady) {
      return;
    }

    try {
      setViewDataState(ViewDataState.liveDataLoadingNoCache);
      const resp = await Axios.get<string[][]>(`${UNDO_ENDPOINT}/${undoId}`);
      const ids = resp.data.map((itemset) => itemset.join(' '));
      updateMemberTies(take(ids, 10));
      setViewDataState(ViewDataState.liveDataReady);
    } catch (e) {
      console.warn('Unable to fetch undo modification data:', (e as any).message);
    }
  };

  const asyncContent =
    !isNil(memberTies) && !isEmpty(memberTies) ? (
      <div>
        {memberTies.map((memberTie) => (
          <div key={memberTie}>{memberTie}</div>
        ))}
      </div>
    ) : (
      <span> -- </span>
    );

  const tooltipContent = (
    <section className={classes.detailTooltipContainer}>
      <div className={classes.detailTooltipContainerSection}>{`${modifiedRecords} record(s) changed`}</div>
      <div className={classes.detailTooltipContainerSection}>{`${itemInitiator} - ${moment(createdAt).format(
        'h:mm a, D MMM YYYY'
      )}`}</div>
      <React.Fragment>
        <div className={classes.detailTooltipContainerSection}>Affected Members:</div>
        {asyncContent}
      </React.Fragment>
    </section>
  );

  return (
    <Tooltip title={tooltipContent} onOpen={onTooltipOpen} placement="left-start" enterDelay={250} enterNextDelay={500}>
      <section className={classes.detailContainer}>
        <div className={classes.detailHeader}>
          <Typography variant="body2" className={classes.detailTitle}>{`${planMeasure.join(', ')}`}</Typography>
          <Avatar variant="square" className={classes.detailCount}>
            {countDisplay}
          </Avatar>
        </div>
        <div className={classes.modificationsContainer}>
          {modificationLevels.map((level) => (
            <UndoItemLevel key={`${undoId}-${level}`} levelId={level} />
          ))}
        </div>
      </section>
    </Tooltip>
  );
};

type UndoPanelProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof dispatchToProps>;

const UndoPanel = ({ loaded, items, onItemUndo }: UndoPanelProps) => {
  const classes = useUndoStyles();
  const planQueueClasses = usePlanQueueStyles()();

  if (!loaded) {
    return (
      <section className={planQueueStyles.container}>
        <Overlay type="loading" visible={true} fitParent={true} />
      </section>
    );
  }

  return (
    <section className={planQueueStyles.container}>
      <div className={planQueueStyles.titleText}>
        <span className={planQueueClasses.large}>Undo Actions</span>
      </div>
      <Divider />
      <section className={planQueueStyles.itemsContainer}>
        {/* TODO: virtualized list needed here? */}
        <List className={classes.list}>
          {items.map((item, index) => {
            const {
              undoId,
              planMeasure,
              createdAt,
              initiatorName = '',
              initiatorEmail = '',
              examplarMemberTie,
              modifiedRecords,
              modificationLevelTie,
            } = item;
            const itemInitiator = initiatorName || initiatorEmail || 'Unknown';
            const notLastItem = index !== items.length - 1;

            return (
              <React.Fragment key={undoId}>
                <ListItem>
                  <ListItemText
                    primary={
                      <UndoItemDetails
                        undoId={undoId}
                        planMeasure={planMeasure}
                        examplarMemberTie={examplarMemberTie}
                        modifiedRecords={modifiedRecords}
                        modificationLevelTie={modificationLevelTie}
                        itemInitiator={itemInitiator}
                        createdAt={createdAt}
                      />
                    }
                    primaryTypographyProps={{ gutterBottom: true, variant: 'subtitle2', noWrap: true }}
                    secondary={<span>{`${itemInitiator} - ${moment(createdAt).format('h:mm a')}`}</span>}
                    secondaryTypographyProps={{ variant: 'caption' }}
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title={'Undo change'}>
                      <IconButton size={'small'} onClick={() => onItemUndo(undoId)}>
                        <HistoryIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
                {notLastItem && <Divider variant="middle" />}
              </React.Fragment>
            );
          })}
        </List>
      </section>
    </section>
  );
};

export default connect(mapStateToProps, dispatchToProps)(UndoPanel);
