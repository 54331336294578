import { isBoolean } from 'util';
import { isNil } from 'lodash';

const TEXT_INPUT_CHARACTER_COUNT = 256;
const TEXT_AREA_CHARACTER_COUNT = 512;

const validateText = (input: string, required: boolean, characterCount: number) => {
  /**
   * Validates the input string, it's max length, and its required status
   * @param {(string|undefined)} input The string (or undef) to be tested
   * @param {boolean} required Does the input require a value?
   * @param {number} characterCount Maximum allowable length of the string
   * @returns {boolean} valid or not
   */
  let valid = input.length <= characterCount;
  if (!required) {
    return valid;
  }
  valid = valid && input.length > 0;
  return valid;
};

const validateInteger = (input: number | undefined, required: boolean) => {
  /**
   * Validates the input as an integer and required/not required
   * @param {(number|undefined)} input The integer (or undef) to be tested
   * @param {boolean} required Does the input require a value?
   * @returns {boolean} valid or not
   */
  if (isNil(input) && !required) {
    return true;
  }
  return Number.isInteger(input!);
};

const validatePercent = (input: number | undefined, required: boolean) => {
  /**
   * Validates the input as up to a 2 decimal place number and required/not requred
   * @param {(number|undefined)} input The number (or undef) to be tested
   * @param {boolean} required Does the input require a value?
   * @returns {boolean} valid or not
   */
  if (isNil(input) && !required) {
    return true;
  }

  // check for NaN and Infinity (also strings and undef if they happen to get through)
  const valid = Number.isFinite(input!);
  if (!valid) {
    return false;
  }

  // It is a finite number at this point
  const num = input as number;
  const strPercent = num.toString();
  const percentSplit = strPercent.split('.');
  if (percentSplit.length === 1) {
    return true;
  }
  const decimalSide = percentSplit[1];
  if (decimalSide.length <= 2) {
    return true;
  }
  return false;
};

const validateMoney = (input: number | undefined, required: boolean) => {
  /**
   * Validates the input as up to a 2 decimal place positive number and required/not requred
   * @param {(number|undefined)} input The number (or undef) to be tested
   * @param {boolean} required Does the input require a value?
   * @returns {boolean} valid or not
   */
  if (isNil(input) && !required) {
    return true;
  }
  const valid = validatePercent(input, required);
  if (valid && input! >= 0) {
    return true;
  }
  return false;
};

const validateBoolean = (input: boolean | undefined, required: boolean) => {
  /**
   * Validates the input a boolean and required/not required
   * @param {(boolean|undefined)} input The boolean (or undef) to be tested
   * @param {boolean} required Does the input require a value?
   * @returns {boolean} valid or not
   */
  if (isNil(input) && !required) {
    return true;
  }
  return isBoolean(input);
};

export const Validator = {
  name: (input: string) => validateText(input, true, TEXT_INPUT_CHARACTER_COUNT),
  description: (input: string) => validateText(input, true, TEXT_INPUT_CHARACTER_COUNT),
  text: (input: string, required: boolean) => validateText(input, required, TEXT_INPUT_CHARACTER_COUNT),
  textArea: (input: string, required: boolean) => validateText(input, required, TEXT_AREA_CHARACTER_COUNT),
  integer: validateInteger,
  percent: validatePercent,
  money: validateMoney,
  boolean: validateBoolean,
};
