import * as React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { style } from 'typestyle';
import ImageCropperModal from 'src/components/ImageCropper/ImageCropperModal/ImageCropperModal';
import { resolvePath } from 'src/cdn';

import noImagePath from 'src/common-ui/images/noimage.jpg';
const noImage = resolvePath(noImagePath);

const styles = {
  imageContainer: style({
    display: 'grid',
    justifyItems: 'center',
  }),
  image: style({
    width: '50%',
  }),
  button: style({
    backgroundColor: 'transparent',
    border: '0 none',
    color: 'var(--teal)',
    fontWeight: 600,
    lineHeight: '1',
    padding: '0.25rem 1rem',
    textTransform: 'uppercase',
  }),
};

type Props = {
  showEditButton?: boolean;
} & ICellRendererParams;

type State = {
  open: boolean;
};

export class ImageCellRenderer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  updateImage = (image: string) => {
    this.props.setValue(image);
    this.closeModal();
  };

  editClicked = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  closeModal = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { showEditButton } = this.props;
    const { open } = this.state;
    const image = this.props.getValue();

    const editButtonContent = showEditButton ? (
      <button className={styles.button} onClick={() => this.editClicked()} type="button">
        Edit
      </button>
    ) : (
      undefined
    );

    return (
      <div className={styles.imageContainer}>
        <img alt="" className={styles.image} src={image || noImage} />
        {editButtonContent}

        <ImageCropperModal
          open={open}
          passedImage={image}
          returnImage={this.updateImage}
          closeModal={this.closeModal}
        />
      </div>
    );
  }
}
