import { get, isEmpty, isNil, groupBy as group, mapKeys, mapValues, take, filter, find, isString } from 'lodash';
import { curry, indexOf, map } from 'lodash/fp';
import { compile } from 'seshat';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { Group, GroupItem } from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.selectors';
import {
  DEPARTMENT_ID,
  externalGridSearchFields,
  reorderGroups,
  STYLE_COLOR_ID,
  STYLE_COLOR_NAME,
  STYLE_DESCRIPTION,
  STYLE_ID,
  STYLE_NAME,
  SWATCHES,
} from 'src/utils/Domain/Constants';
import { Renderer } from 'src/utils/Domain/Renderer';
import { filterAndSortPivotItems, mergeWorklistForSort } from 'src/utils/Pivot/Filter';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { flattenAndLabelGroup } from './SortAndGroup';
import { CardViewGroup, CardViewDataInputs, CardViewOptionalInputs, CardViewItem, ResponseItem } from './UIData.types';

export const NO_GROUPS_TYPE = '_undefined_group_';
export const emptyGroupedStyles: CardViewGroup = {
  header: NO_GROUPS_TYPE,
  subheader: [{ name: '', value: '' }],
  items: [],
};

type CardDefns = {
  columnList: TenantConfigViewItem[];
  mainInfo: TenantConfigViewItem[];
  rollup: TenantConfigViewItem[];
};

type CardMainInfoConfigs = {
  starDef: TenantConfigViewItem | undefined;
  valueDef: TenantConfigViewItem | undefined;
};

function groupIdToText(staticColumns: (string | null)[], id: string | number): string | null {
  if (staticColumns[id]) {
    return staticColumns[id];
  } else if (indexOf(id, staticColumns) >= 0) {
    return id.toString();
  } else {
    return null;
  }
}

function calculateGroupRollups(items: GroupItem[], rollupDefns: TenantConfigViewItem[] | undefined) {
  const rollups = filter(rollupDefns, (view) => !isNil(view.formula) && view.atGroupLevel !== false);

  if (isNil(rollups)) {
    return [];
  }

  return rollups.map((rollupDefn) => {
    // for groups with no items (e.g. flow status filtered flow type view) still need to render calcs, so provide 0
    const rawValue = !isNil(items) ? compile(rollupDefn.formula || '0').execute(items) : 0;
    return {
      title: rollupDefn.text || '',
      value: Renderer.renderJustValue(rawValue, rollupDefn),
      raw: rawValue,
      includePercentage: rollupDefn.includePercentage,
    };
  });
}

export function possiblyReorderGroups(groupMap: Record<string, any>, groupProp: string, staticColumns: string[]) {
  let newGroups: Group[];

  // reorderDetails are for some groups ('Performance', 'Shopzilla') have to order in a special way,
  // do that using pre-defined reorderGroups
  const reorderDetails = reorderGroups.find((details) => details.id === groupProp);
  if (reorderDetails) {
    newGroups = [];
    reorderDetails.order.forEach((prop) => {
      newGroups.push({
        items: [],
        ...groupMap[prop],
        header: reorderDetails.nameMap[prop] || groupMap[prop].header,
      });
    });
  } else {
    // this makes sure the data stays in the configured order if there are static columns
    // NOTE: groupMap contains an undefined key value it will become a string 'undefined' after Object.keys
    const orderedGroups = !isEmpty(staticColumns) ? staticColumns : Object.keys(groupMap);
    newGroups = orderedGroups.map((prop: string) => {
      return {
        ...groupMap[prop],
        header: prop === 'undefined' ? '' : prop,
      };
    });
  }
  return newGroups;
}

function generateOrderedGroups(
  items: BasicPivotItem[],
  groupDataIndex: string,
  staticColumns: string[],
  countLimit: number | undefined
): Group[] {
  const rawGroups = group(items, (item) => {
    // gets the value at the dataIndex, defaulting to no group if not found
    const rawValue = get(item, groupDataIndex, NO_GROUPS_TYPE);

    // sometimes the groups come through with chars that break fp's get bracket notation
    // if this is undesirable, at some point we could potentially replace periods with
    // the literal unicode character one-dot-leader: ․ (different than .)
    return isString(rawValue) ? rawValue.replace(/[\.\[\]]/, '') : rawValue;
  });

  // build proper grouped object structure,
  // by assigning group arrays to items property within current key
  let formattedGroups = mapValues(rawGroups, (value) => ({
    items: value,
  }));

  // change keys to specified static column values (i.e. flowStatus, 1 -> NEW)
  if (!isEmpty(staticColumns)) {
    formattedGroups = mapKeys(formattedGroups, (_value, key) => groupIdToText(staticColumns, key));
  }

  // limit number of items
  if (!isNil(countLimit)) {
    formattedGroups = mapValues(formattedGroups, (value) => {
      return {
        items: take(value.items, countLimit),
      };
    });
  }

  const orderedGroups = possiblyReorderGroups(formattedGroups, groupDataIndex, staticColumns);
  return orderedGroups;
}

function getMainInfoValues(cardInfoConfigs: CardMainInfoConfigs, item: ResponseItem) {
  const { starDef, valueDef } = cardInfoConfigs;
  const stars = starDef ? item[starDef.dataIndex] : item.attributevaluerank;
  const value = valueDef ? item[valueDef.dataIndex] : item['attribute:ccticketprice:name'];
  const valueRenderer = valueDef ? valueDef.renderer : '';

  return {
    id: item.id,
    name: item.name,
    description: item.description,
    stars,
    value,
    valueRenderer: valueRenderer || '',
  };
}

const createViewItem = curry(
  (
    columnList: TenantConfigViewItem[],
    mainInfoConfigs: CardMainInfoConfigs,
    selectedItems: BasicPivotItem[] | undefined,
    item: ResponseItem
  ): CardViewItem => {
    const columns = columnList
      .filter((conf) => conf.visible !== false)
      .map((conf) => ({
        title: conf.text || '',
        value: item[conf.dataIndex],
        renderer: conf.renderer || '',
      }));
    const swatchIds = item[SWATCHES] != null ? item[SWATCHES].split(',') : [];
    const isSelected = selectedItems != null ? selectedItems.map((i) => i.id).indexOf(item.id) >= 0 : false;

    return {
      ...getMainInfoValues(mainInfoConfigs, item),
      key: item.key,
      styleDescription: item[STYLE_DESCRIPTION] || '',
      styleId: item[STYLE_ID] || '',
      styleName: item[STYLE_NAME] || '',
      styleColorName: item[STYLE_COLOR_NAME] || '',
      imgSrc: item['attribute:img:id'] || '',
      columns,
      swatchIds,
      departmentId: item[DEPARTMENT_ID],
      isSelected,
      adornments: [], // this prop is unused here
    };
  }
);

function convertToViewData(
  groups: Group[],
  cardDefns: CardDefns,
  selectedItems: BasicPivotItem[] | undefined
): CardViewGroup[] {
  const { columnList, mainInfo, rollup } = cardDefns;
  const convertedData = groups.map((group) => {
    const rollups = calculateGroupRollups(group.items, rollup);

    // find and pass star/value configs for main info rendering up front
    const mainInfoConfigs: CardMainInfoConfigs = {
      starDef: find(mainInfo, (it) => it.xtype === 'stars'),
      valueDef: find(mainInfo, (it) => it.xtype === 'right-side'),
    };
    const responseToViewItems = map(createViewItem(columnList, mainInfoConfigs, selectedItems))(
      group.items as ResponseItem[]
    );

    const allItemsInCart =
      !isNil(selectedItems) && !isEmpty(selectedItems)
        ? group.items.every((gi) => {
            return selectedItems.findIndex((si) => si[STYLE_COLOR_ID] === gi[STYLE_COLOR_ID]) >= 0;
          })
        : false;

    return {
      header: group.header,
      subheader: [],
      groupCalcs: rollups,
      items: responseToViewItems,
      allItemsInCart,
    };
  });

  return convertedData;
}

/**
 * @param {CardViewDataInputs} dataInputs
 * @param {CardViewOptionalInputs} optionalInputs
 * @returns {CardViewGroup[]}
 */
export default function processDataForCardView(
  dataInputs: CardViewDataInputs,
  optionalInputs: CardViewOptionalInputs = {}
): CardViewGroup[] {
  if (isNil(dataInputs.styles) || isEmpty(dataInputs.styles)) {
    return [emptyGroupedStyles];
  }

  const { filterFlowStatus = true, worklist = [], staticColumns = [], selectedItems = [] } = optionalInputs;
  const cardDefns: CardDefns = {
    columnList: get(dataInputs.defns, 'view.view', []) as TenantConfigViewItem[],
    mainInfo: get(dataInputs.defns, 'view.main.view', []) as TenantConfigViewItem[],
    rollup: get(dataInputs.defns, 'rollup.view', []) as TenantConfigViewItem[],
  };
  const { groupBy, sortBy, search: searchValue, flowStatus, countLimit, pareDown } = dataInputs.subheader;
  const groupDataIndex = getGroupBySelectedOptionProperty(groupBy, 'dataIndex');
  const groupDimension = getGroupBySelectedOptionProperty(groupBy, 'dimension');
  const flatStyles = flattenAndLabelGroup({ items: dataInputs.styles, groupDataIndex, groupDimension });
  const customSearch = dataInputs.defns.view.searchIndexes;
  const searchFields = !isNil(customSearch) && !isEmpty(customSearch) ? customSearch : externalGridSearchFields;

  try {
    const flowStatusSelection = filterFlowStatus ? flowStatus : [];
    const pareDownSelections = pareDown ? pareDown.selections : undefined;
    const mergedWorklistStyles = !isEmpty(worklist) ? mergeWorklistForSort(flatStyles, worklist) : flatStyles;
    const filteredItems = filterAndSortPivotItems(
      searchValue,
      sortBy,
      searchFields,
      flowStatusSelection,
      mergedWorklistStyles,
      pareDownSelections
    );
    const orderedGroups = generateOrderedGroups(filteredItems, groupDataIndex, staticColumns, countLimit);
    const convertedItems = convertToViewData(orderedGroups, cardDefns, selectedItems);
    return convertedItems;
  } catch (error) {
    console.error('An error occured during processDataForCardView:', error);
    return [];
  }
}
