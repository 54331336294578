import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import StarRatings from 'react-star-ratings';
import { starDimension, starSpacing } from 'src/components/StandardCardView/StandardCard/StandardCard.styles';
import styles from 'src/components/StarPercentRenderer/StarPercentRenderer.styles';
import { starGoldColor } from 'src/utils/Style/Theme';
import { isNaN } from 'lodash';

export type Params = ICellRendererParams & {
  onChange: () => void;
};

interface State {
  rating: number;
}

export class StarEditor extends React.Component<Params, State> {
  constructor(props: Params) {
    super(props);
  }

  onRatingChange = (rating: number) => {
    this.props.setValue(rating);
  };

  render() {
    const rating = parseFloat(this.props.value);
    const parsed = isNaN(rating) ? 0 : rating;
    return (
      <div className={styles.container}>
        <StarRatings
          rating={parsed}
          changeRating={this.onRatingChange}
          numberOfStars={5}
          starDimension={starDimension}
          starSpacing={starSpacing}
          starRatedColor={starGoldColor}
        />
      </div>
    );
  }
}
