import { AppType } from 'src/types/Domain';
import { z } from 'zod';
import { UIConfDefn, EnabledTab as UIConfDefnTab, LeftNavSection, ConfDefnTab } from './codecs/confdefn';
import { SectionView } from './codecs/confdefnComponents';
import { FlowStatus } from './codecs/general';
import { Perspectives } from './codecs/literals';

export type ViewComponent = React.ComponentClass<Record<string, unknown>>;

export type Perspective = z.infer<typeof Perspectives>;
export interface ViewBinding {
  id: string;
  pathSlot: string;
  inPerspectives: readonly Perspective[];
  component: ViewComponent;
  icon: string;
  name: string;
  componentProps?: { [s: string]: unknown };
  hidden?: boolean;
  allowNesting?: boolean;
}

export interface LeftSectionBinding {
  id: string;
  name: string;
  pathSlot: string;
  inPerspectives: readonly Perspective[];
  viewBindings: ViewBinding[];
  icon: string;
}

export interface PathSlotted {
  pathSlot: string;
}

export interface CanBeDisabled {
  disabled: boolean;
}

export interface CanBeHidden {
  hidden?: boolean;
}

export interface BoundView extends PathSlotted, CanBeDisabled, ViewBinding, CanBeHidden {
  id: string;
  icon: string;
  name: string;
  overflow: string;
  // The only thing the component would be able to receive would be the props from the Route match.
  // Since existing components weren't written with this in mind,
  component: ViewComponent;
  componentProps?: { [s: string]: unknown };
  errorView?: boolean;
}

export interface BoundSection extends PathSlotted, CanBeDisabled {
  id: string;
  name: string;
  defaultViewId: string | undefined;
  pathSlot: string;
  disabled: boolean;
  icon: string;
  boundViews: BoundView[];
}

export interface BoundTab extends PathSlotted, CanBeDisabled {
  id: string;
  defaultSectionId: string | undefined;
  name: string;
  disabled: boolean;
  boundSections?: BoundSection[];
  component?: ViewComponent;
}

export interface BoundTenant {
  defaultTabSlot: string | undefined;
  boundTabs: BoundTab[];
  perspective: BoundPerspective;
}

export interface BoundPerspective extends PathSlotted {
  id: Perspective;
  display: string;
  value: AppType;
  iconSrc: string;
  disabled?: boolean;
}

export interface BoundRoute {
  display: string;
  iconSrc: string;
  route: string;
  disabled?: boolean;
}

export interface PerspectiveBinding {
  display: string;
  pathSlot: string;
  value: AppType;
  iconSrc: string;
}
export type TenantView = z.infer<typeof SectionView>;

export type TenantLeftNavSection = z.infer<typeof LeftNavSection>;

export type TenantTab = z.infer<typeof UIConfDefnTab>;

export type ConfDefnTab = z.infer<typeof ConfDefnTab>;

export function tenantTabHasPathSlot(tab: ConfDefnTab): tab is TenantTab {
  return (tab as TenantTab).pathSlot !== undefined;
}

export type FlowStatus = z.infer<typeof FlowStatus>;

export type TenantConfig = z.infer<typeof UIConfDefn>;
