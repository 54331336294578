/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { sectionContainer, viewsContainer, leftIconClass } from './ConfigEditor.styles';
import { Typography, Collapse } from '@material-ui/core';
import { isEmpty, isNil, omit } from 'lodash';
import TextFieldLabel from './TextFieldLabel';
import HorizontalTabs from './HorizontalTabs';
import ConfigEditorMain from './ConfigEditorMain';
import sidenavStyles from 'src/components/Sidenav/Sidenav.styles';
import { updateConfDefn, getConfDefn } from 'src/services/configService';
import { makePathSlot, CONF_DEFN_NAME, TenantTabConfigurator, TenantViewConfigurator } from './ConfigEditor.utils';
import { TenantConfig, TenantLeftNavSection, TenantTab } from 'src/services/configuration/bindings.types';
import { BOTTOM_UP_PERSPECTIVE } from 'src/utils/Domain/Constants';
import { MuiThemeProvider } from '@material-ui/core';
import { muiTheme } from 'src/utils/Style/Theme';
import { AppThunkDispatch } from 'src/store';
import { receiveError } from 'src/pages/Hindsighting/StyleColorReview/CanvasView/CanvasView.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { isEnabledTab } from 'src/services/configuration/codecs/confdefn';
import { ComponentErrorType } from '../ErrorBoundary/ErrorBoundary.slice';

interface SidePanelProps {
  //key: string; // id
  id: string;
  name: string;
  icon: string;
  selectedTabId: string;
  boundView: TenantViewConfigurator[];
  index: number;
  groupId?: string;
  isCustom?: boolean;
  activeTabId?: string | false;
  defaultSectionId?: string | false;
  isAddNew?: boolean;
  selectedViewIndex?: number;
  handleViewOpen: (id: string) => void;
  onClickView?: (i: number) => void;
  setSectionIcon?: (iconClass: string) => void;
  isDefaultView?: (id: string) => boolean;
  handleViewDelete?: (i: number) => void;
  onChangeDefaultView?: (i: number) => void;
  handleSectionDelete?: () => void;
  setBoundView?: (boundView: TenantViewConfigurator[]) => void;
  renameSection?: (index: number, newName: string, isCustom: boolean) => void;
}

const SidePanel = (props: SidePanelProps) => {
  const {
    id,
    selectedTabId,
    index,
    name,
    activeTabId,
    icon,
    groupId,
    boundView,
    isCustom,
    isAddNew,
    defaultSectionId,
    selectedViewIndex,
    isDefaultView,
    onClickView,
    setSectionIcon,
    setBoundView,
    renameSection,
    handleViewOpen,
    handleViewDelete,
    handleSectionDelete,
  } = props;
  const [viewSelectedName, setViewSelectedName] = useState<string | undefined>();

  const handleAddNewView = (iconView?: string) => {
    const addNewView = boundView.slice(0);
    let viewName = 'Created View';
    let x = 1;
    // Make sure created tabs have non-overlapping names
    while (addNewView.map((x: any) => x.name).indexOf(viewName) > -1) {
      viewName = 'Created View ' + x;
      x++;
    }
    addNewView.push({
      id: makePathSlot(viewName),
      name: viewName,
      pathSlot: makePathSlot(viewName),
      icon: iconView ? iconView : 'fa fa-plus',
      inPerspectives: [BOTTOM_UP_PERSPECTIVE],
    } as any);
    setBoundView && setBoundView(addNewView);
  };

  const setViewIcon = (index: number, iconClass: string) => {
    const newBoundView = boundView.slice(0);
    newBoundView[index].icon = iconClass;
    setBoundView && setBoundView(newBoundView);
  };

  const setViewName = (index: number, name: string) => {
    const newBoundView = boundView.slice(0);
    newBoundView[index].name = name;
    setBoundView && setBoundView(newBoundView);
  };
  const expanded = groupId === id;

  return (
    <div
      role="tabpanel"
      hidden={selectedTabId !== activeTabId}
      id={`section-${index}`}
      aria-labelledby={`section-${index}`}
    >
      {selectedTabId === activeTabId && (
        <div className={sectionContainer}>
          <Typography onClick={() => handleViewOpen(id)} id={id} style={{ cursor: 'pointer', width: '100%' }}>
            {/* FIXME: needs to be reworked, throws an error validateDOMNesting(...): <div> cannot appear as a descendant of <p>. exception */}
            {isAddNew ? (
              <div>
                <i className={`${icon} ${leftIconClass}`} />
                <span style={{ cursor: 'pointer' }}>{name}</span>
              </div>
            ) : (
              <TextFieldLabel
                isDefault={id == defaultSectionId}
                leftIcon={icon}
                isSelected={groupId === id}
                defaultValue={name}
                isEditable={name == viewSelectedName}
                onChangeIcon={(iconClass: string) => setSectionIcon && setSectionIcon(iconClass)}
                onEditStart={() => setViewSelectedName(name)}
                onChangeName={(newName: string) => renameSection && renameSection(index, newName, isCustom || false)}
                onDelete={() => handleSectionDelete && handleSectionDelete()}
              />
            )}
          </Typography>
          {!isAddNew && (
            <React.Fragment>
              <i
                onClick={() => handleViewOpen(id)}
                className={`fas fa-angle-down ${expanded ? sidenavStyles.expandedCaret : sidenavStyles.collapsedCaret}`}
              />
            </React.Fragment>
          )}
        </div>
      )}
      <Collapse in={groupId === id}>
        <ul className="nav flex-column" style={{ marginLeft: '2rem' }}>
          {!isEmpty(boundView) && (
            <div>
              {boundView.map((view: any, i: number) => {
                return (
                  <div className={viewsContainer} key={view.id}>
                    <div style={{ fontSize: 12, width: '100%' }} onClick={() => onClickView && onClickView(i)}>
                      <TextFieldLabel
                        isView={true}
                        isDefault={isDefaultView && isDefaultView(view.id)}
                        leftIcon={view.icon}
                        isSelected={i == selectedViewIndex}
                        isEditable={view.name == viewSelectedName}
                        onChangeIcon={(iconClass: string) => setViewIcon(i, iconClass)}
                        onEditStart={() => setViewSelectedName(view.name)}
                        defaultValue={view.name}
                        onChangeName={(newName: string) => setViewName(i, newName)}
                        onDelete={() => handleViewDelete && handleViewDelete(i)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div
            style={{ display: 'flex', width: '15rem', alignItems: 'center', margin: '0.1rem' }}
            onClick={() => handleAddNewView()}
            tabIndex={0}
          >
            <div style={{ marginRight: '10px' }}>
              <i className="fa fa-plus-circle" />
            </div>
            <div style={{ fontSize: '.75rem', cursor: 'default' }}>Create New View</div>
          </div>
        </ul>
      </Collapse>
    </div>
  );
};

function dispatchToProps(dispatch: AppThunkDispatch) {
  return {
    onError(type: ComponentErrorType, message: string) {
      dispatch(
        receiveError({
          type,
          message,
          name: ConfDefnComponentType.configEditor,
        })
      );
    },
  };
}

type ConfigEditorProps = ReturnType<typeof dispatchToProps>;

const ConfigEditor = ({ onError }: ConfigEditorProps) => {
  const [tabs, setTabs] = useState<TenantTabConfigurator[]>([]);
  const [confDefn, setConfDefn] = useState<TenantConfig | undefined>();
  const [groupId, setGroupId] = useState('');
  const [sections, setSections] = useState<(TenantLeftNavSection & { isCustom?: boolean })[]>([]);
  const [boundView, setBoundView] = useState<TenantViewConfigurator[]>([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [selectedViewIndex, setSelectedViewIndex] = useState<number>(-1);

  const handleViewOpen = (id: string) => {
    setGroupId(id);
    setSelectedViewIndex(-1);

    const findView = sections.find((view) => view.id === id);
    findView && setBoundView(findView.views);
  };

  useEffect(() => {
    getConfDefn<TenantConfig | undefined>(CONF_DEFN_NAME)
      .then((resp) => {
        const conf: TenantConfig | null = !isNil(resp) ? resp : null;
        if (isNil(conf)) {
          throw new Error('No confdefn detected, unable to load view.');
        }
        const defaultTabs = conf.tabs;
        // Adds leftNavSections for potentially disabled tabs and default $componentPropRef if necessary
        const redoneTabs = defaultTabs
          .map((tab) => {
            return {
              ...tab,
              leftNavSections: isEnabledTab(tab) ? tab.leftNavSections : [],
            };
          })
          .map((tab) => ({
            ...tab,
            leftNavSections: tab.leftNavSections.map((section) => ({
              ...section,
              views: section.views.map((view) => ({
                ...view,
                ['$componentPropRef']: view['$componentPropRef'] || `${tab.name}${section.name}${view.name}`,
              })),
            })),
          }));
        setTabs(redoneTabs as TenantTabConfigurator[]);
        setConfDefn(conf);
      })
      .catch((error) => {
        onError(ComponentErrorType.config, (error as Error)?.message);
      });
  }, [onError]);

  const defaultTabs = confDefn ? (confDefn.tabs as TenantTabConfigurator[]) : [];
  const tabsDefined = tabs.length > 0;
  const selectedTabId = tabsDefined && tabs[selectedTabIndex] && tabs[selectedTabIndex].id;
  const filterTab = tabsDefined && tabs.find((tab) => tab.id === selectedTabId);
  const filterSections = tabsDefined && filterTab && filterTab.leftNavSections ? filterTab.leftNavSections : null;
  const defaultSectionId = filterTab && filterTab.defaultSection;
  const defaultView = defaultSectionId && filterSections && filterSections.find((x) => x.id == defaultSectionId);
  const defaultViewId = defaultView && defaultView.defaultView;
  const sectionIndex = sections.findIndex((section) => section.id == groupId);

  useEffect(() => {
    setSections(filterSections || []);
  }, [filterSections]);

  useEffect(() => {
    // On tab change, auto-select the default section/view
    if (defaultSectionId && defaultViewId) {
      const newSection = filterTab.leftNavSections.find((x) => x.id == defaultSectionId);
      if (newSection) {
        setGroupId(defaultSectionId);
        setBoundView(newSection.views);
        const viewIndex = newSection.views.findIndex((view) => view.id == defaultViewId);
        setSelectedViewIndex(viewIndex);
      }
    } else {
      setSelectedViewIndex(-1);
    }
  }, [defaultSectionId, defaultViewId, filterTab, selectedTabIndex]);

  const saveConf = (newTabs: TenantTabConfigurator[]) => {
    if (confDefn) {
      // TODO: what happens in the case if the tab is disabled?
      // -> on confdefn load it a disabled tab will have leftNavSections: [] inserted.
      // -> any issue here if the user takes a previously disabled tab and makes it enabled?
      // -> potentially will need to strip this out if the tab is still disabled?

      // strip out $componentPropRef from all views before sending off changes to be saved
      const strippedTabs = newTabs.map((tab) => ({
        ...tab,
        leftNavSections: tab.leftNavSections.map((section) => ({
          ...section,
          views: section.views.map((view) => ({
            ...omit(view, '$componentPropRef'),
          })),
        })),
      })) as TenantTab[];
      // .map((tab: ConfDefnTab) => {
      //   return isEnabledTab(tab) ? tab : omit(tab, 'leftNavSections');
      // }) as TenantTab[];
      const newConfDefn: TenantConfig = {
        ...confDefn,
        tabs: strippedTabs,
      };
      updateConfDefn(CONF_DEFN_NAME, newConfDefn);
    }
  };
  const onChangeDefaultView = (viewIndex: number, sectionIndex: number) => {
    const newTabs = tabs.slice(0);
    newTabs[selectedTabIndex].defaultSection = sections[sectionIndex].id;
    newTabs[selectedTabIndex].leftNavSections[sectionIndex].defaultView = boundView[viewIndex].id;
    setTabs(newTabs);
    saveConf(newTabs);
  };
  const setSectionsToSelectedTab = (newSections: any[]) => {
    const newTabs = tabs.slice(0);
    newTabs[selectedTabIndex].leftNavSections = newSections.slice(0);
    setTabs(newTabs);
    saveConf(newTabs);
  };
  const setBoundViewAndSave = (index: number, boundView: any[]) => {
    setBoundView(boundView);
    const newTabs = tabs.slice(0);
    tabs[selectedTabIndex].leftNavSections[index].views = boundView;
    setTabs(newTabs);
    saveConf(newTabs);
  };
  const handleAddNewSection = () => {
    const addNewSection = sections.slice(0);
    let sectionName = 'Created Section';
    let x = 1;
    // Make sure created tabs have non-overlapping names
    while (addNewSection.map((x) => x.name).indexOf(sectionName) > -1) {
      sectionName = 'Created Section ' + x;
      x++;
    }
    addNewSection.push({
      id: makePathSlot(sectionName),
      name: sectionName,
      pathSlot: makePathSlot(sectionName),
      icon: 'fa fa-plus',
      inPerspectives: [BOTTOM_UP_PERSPECTIVE],
      views: [],
      isCustom: true,
    });
    setSections(addNewSection);
    setSectionsToSelectedTab(addNewSection);
  };
  const renameSection = (index: number, newName: string, isCustom: boolean) => {
    const newSections = sections.slice(0);
    newSections[index].name = newName;
    // Only change the id and pathSlot when renaming if it's a custom-made tab
    if (isCustom) {
      newSections[index].id = makePathSlot(newName);
      newSections[index].pathSlot = makePathSlot(newName);
    }
    setSections(newSections);
    setSectionsToSelectedTab(newSections);
  };
  const setSectionIcon = (index: number, iconClass: string) => {
    const newSections = sections.slice(0);
    newSections[index].icon = iconClass;
    setSections(newSections);
    setSectionsToSelectedTab(newSections);
  };
  const saveView = (saveView: TenantViewConfigurator) => {
    const newBoundViews = boundView.slice(0);
    newBoundViews[selectedViewIndex] = saveView;
    setBoundViewAndSave(sectionIndex, newBoundViews);
  };
  const deleteSection = (index: number) => {
    const newSections = sections.slice(0);
    newSections.splice(index, 1);
    setSections(newSections);
    setSectionsToSelectedTab(newSections);
  };
  const deleteView = (index: number) => {
    const newBoundViews = boundView.slice(0);
    newBoundViews.splice(index, 1);
    setBoundViewAndSave(sectionIndex, newBoundViews);
  };

  const selectedView = boundView && selectedViewIndex > -1 ? boundView[selectedViewIndex] : undefined;
  return (
    <MuiThemeProvider theme={muiTheme}>
      <div style={{ width: '95%', height: '95%' }}>
        <HorizontalTabs
          selectedTabIndex={selectedTabIndex}
          tabs={tabs}
          defaultTabs={defaultTabs}
          onTabsChange={(newTabs: TenantTabConfigurator[]) => {
            // Upon deleting or creating a tab, set a new tab
            if (tabs.length < newTabs.length) {
              setSelectedTabIndex(newTabs.length - 1);
            } else if (tabs.length > newTabs.length) {
              setSelectedTabIndex(newTabs.length - 1);
            }
            setTabs(newTabs);
            saveConf(newTabs);
          }}
          onSelectionChange={(index: number) => {
            setSelectedTabIndex(index);
            setBoundView([]);
            setGroupId('');
          }}
        />
        <div style={{ display: 'flex' }}>
          <div>
            {sections &&
              sections.map((section, index: number) => (
                <SidePanel
                  {...section}
                  key={section.id}
                  selectedTabId={selectedTabId || ''}
                  activeTabId={filterTab && filterTab.id}
                  handleViewOpen={handleViewOpen}
                  handleViewDelete={(i: number) => deleteView(i)}
                  onChangeDefaultView={(i: number) => onChangeDefaultView(i, index)}
                  handleSectionDelete={() => deleteSection(index)}
                  groupId={groupId}
                  boundView={boundView}
                  setBoundView={(boundView: TenantViewConfigurator[]) => setBoundViewAndSave(index, boundView)}
                  renameSection={renameSection}
                  index={index}
                  isCustom={section.isCustom ? section.isCustom : false}
                  onClickView={(i: number) => setSelectedViewIndex(i)}
                  setSectionIcon={(iconClass: string) => setSectionIcon(index, iconClass)}
                  isDefaultView={(id: string) => defaultViewId == id && defaultSectionId == sections[sectionIndex].id}
                  defaultSectionId={defaultSectionId}
                  selectedViewIndex={selectedViewIndex}
                />
              ))}
            <SidePanel
              name="Create New Section"
              selectedTabId={selectedTabId || ''}
              activeTabId={filterTab && filterTab.id}
              icon="fa fa-plus-circle"
              id="CreateNewSection"
              handleViewOpen={handleAddNewSection}
              isAddNew={true}
              boundView={boundView}
              index={0}
            />
          </div>
          <ConfigEditorMain
            selectedView={selectedView as TenantViewConfigurator}
            onChangeView={(newView: TenantViewConfigurator) => saveView(newView)}
            isDefault={
              selectedView ? selectedView.id == defaultViewId && defaultSectionId == sections[sectionIndex].id : false
            }
            onChangeDefault={() => onChangeDefaultView(selectedViewIndex, sectionIndex)}
          />
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default connect(() => ({}), dispatchToProps)(ConfigEditor);
