import { style } from 'typestyle';
import { px, rem } from 'csx';

const variance = style({
  display: 'flex',
  justifyContent: 'space-between',
  $nest: {
    '& .pills': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: 120,
    },
    '& .pill-group': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: rem(0.1),
      minWidth: px(140),
    },
    '& .pillpercent': {
      height: 14,
      background: '#D04B32',
      width: 50,
      borderRadius: 8,
      textAlign: 'center',
      color: 'white',
      fontSize: 10,
      $nest: {
        '& .fas': {
          fontSize: 10,
        },
      },
    },
    '& .tinynumber': {
      minWidth: px(75),
    },
  },
});

export default {
  variance,
};
