import React from 'react';
import { classes } from 'typestyle';
import { Metric, DirectionalMetric } from 'src/common-ui/components/Metrics/CommonTypes';
import { keyFinancialStyle } from './KeyFinancial.styles';
import { downArrowClass, upArrowClass } from 'src/common-ui/theme';
import Skeleton from '@material-ui/lab/Skeleton';

export type Props = {
  dataLoaded: boolean;
  metrics: [Metric, DirectionalMetric, Metric];
  extraClasses: string;
  ignoreHorizontalSpacing?: boolean;
};

export default function KeyFinancial(props: Props) {
  if (!Object.keys(props).length) {
    return null;
  }
  const { dataLoaded, metrics, extraClasses, ignoreHorizontalSpacing = false } = props;

  const containerClasses = 'key-financial-item ' + keyFinancialStyle + (extraClasses ? ' ' + extraClasses : '');

  const [primary, diff, secondary] = metrics;

  const dirClasses = 'dir fa fas ' + (diff.direction === 'down' ? 'down ' + downArrowClass : 'up ' + upArrowClass);
  const rightContainerClasses = classes('right-container', ignoreHorizontalSpacing ? 'right-container-override' : '');

  if (!dataLoaded) {
    return (
      <div className={containerClasses}>
        <header>{primary.label}</header>
        <div className="bottom-container">
          <Skeleton className="primary-skeleton" variant="text" animation="wave" />
          <div className={rightContainerClasses}>
            <section className="secondary-skeleton">
              <Skeleton className="arrow-skeleton dir" variant="text" animation="wave" />
              <Skeleton className="diff-skeleton" variant="text" animation="wave" />
            </section>
            <Skeleton variant="text" animation="wave" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={containerClasses}>
      <header>{primary.label}</header>
      <div className="bottom-container">
        <div className="primary">{primary.rendered}</div>
        <div className={rightContainerClasses}>
          <div className="diff-container">
            <i className={dirClasses} />
            <span className="diff">{diff.rendered}</span>
          </div>
          <div className="secondary">
            {secondary.label} {secondary.rendered}
          </div>
        </div>
      </div>
    </div>
  );
}
