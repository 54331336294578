import * as React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { isEmpty, isNil, size } from 'lodash';
import { isString } from 'lodash/fp';

import { ViewApiConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { Suggestion } from 'src/common-ui/components/Inputs/InputSuggest/InputSuggest';
import { getUrl } from '../../StyleEdit.utils';
import { getValidValues } from '../StyleEditSection.client';
import { getSelectedValidValues } from './RenderUtils';

export interface ValidValueEditorParams extends ICellRendererParams {
  asCsv?: boolean;
  dataConfig: ViewApiConfig;
  ignoreCache?: boolean;
}

interface State {
  validValues: Suggestion[];
  loaded: boolean;
  selectedOptions: Suggestion[];
}

function arrayContainsArray(superset: unknown[], subset: unknown[]) {
  return subset.every(function(value: unknown) {
    return superset.indexOf(value) >= 0;
  });
}

export default class ValidValuesRenderer extends React.Component<ValidValueEditorParams, State> {
  constructor(props: ValidValueEditorParams) {
    super(props);
    this.state = {
      validValues: [],
      loaded: false,
      selectedOptions: [],
    };
  }

  componentDidMount() {
    const { value, dataConfig, ignoreCache } = this.props; // value comes in as an array
    const url = getUrl(dataConfig);

    getValidValues(url, false, false, ignoreCache)
      .then((validValues) => {
        const selectedOptions = !isNil(value) ? getSelectedValidValues(value, validValues, true) : [];

        this.setState({
          selectedOptions,
          validValues,
          loaded: true,
        });
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { validValues, selectedOptions } = this.state;

    if (isEmpty(selectedOptions)) {
      return <div />;
    }

    const selectedOptionsLabels = selectedOptions.map((option) => option.label).filter((value) => size(value) > 0);
    const validValuesLabels = validValues
      .map((value) => (isString(value) ? value : value.label))
      .filter((value) => size(value) > 0);
    const displayText = arrayContainsArray(selectedOptionsLabels, validValuesLabels)
      ? 'ALL'
      : selectedOptionsLabels.join(', ');

    return <div>{displayText}</div>;
  }
}
