import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isNil } from 'lodash';

import container from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { ASSORTMENT_BUILD_FILTER_ALL_WARNING } from 'src/utils/Domain/Constants';
import { isViewDefnLoaded } from 'src/dao/tenantConfigClient';
import { ListDataOptions, TreePivot } from 'src/worker/pivotWorker.types';

import Subheader from 'src/components/Subheader/Subheader.container';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { getHeaderTextFromState } from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { ConfigureOvertimeConfig } from 'src/pages/AssortmentBuild/OvertimeView/OvertimeView.types';
import { OvertimeView } from 'src/pages/AssortmentBuild/OvertimeView/OvertimeView';
import { NestedOvertimeViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import {
  setConfigureSelections,
  receiveTenantConfig as receiveNestedTenantConfig,
  requestTenantConfig as requestNestedTenantConfig,
  receiveError,
  fetchNestedOvertimeData,
} from 'src/pages/AssortmentBuild/OvertimeView/NestedOvertime/NestedOvertime.slice';
import { NestedOvertimeDispatchProps } from 'src/pages/AssortmentBuild/OvertimeView/NestedOvertime/NestedOvertimeContainer';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import ConfigureModal, { ConfigureModalProps, Option } from 'src/components/Configure/ConfigureModal';
import { listPairStyle } from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import { TabbedComponentWrapperOwnProps } from 'src/pages/Worklist/Worklist.types';

type NestedOvertimeProps = ReturnType<typeof mapStateToPropsNestedOvertime>;
type NestedOvertimeGridWithSubheader = {
  subheaderSummary?: string;
  overtimeData?: TreePivot;
} & NestedOvertimeProps &
  NestedOvertimeDispatchProps;

export function mapStateToPropsNestedOvertime(state: AppState, ownProps: TabbedComponentWrapperOwnProps) {
  const customHeader = getHeaderTextFromState(state, true);
  const viewState = state.pages.hindsighting.nestedOvertime;
  const {
    viewDataState,
    viewDefnState,
    data,
    viewDefn,
    topMember,
    configureSelections,
    defaultConfigureSelections,
    configureOptions,
    rowHeight,
  } = viewState;
  const configLoaded = isViewDefnLoaded(viewDefnState);
  const dataLoaded = isDataLoaded(viewDataState);
  return {
    ...ownProps,
    data,
    viewDefn,
    topMember,
    configureSelections,
    defaultConfigureSelections,
    configureOptions,
    configLoaded,
    dataLoaded,
    customHeader,
    rowHeight,
  };
}

export function dispatchToPropsNestedOvertime(
  dispatch: AppThunkDispatch,
  ownProps: TabbedComponentWrapperOwnProps
): NestedOvertimeDispatchProps {
  const { viewDefns, dataApi, selectedItemId } = ownProps;
  const client = container.tenantConfigClient;

  const dispatchHandlers = {
    async onShowView() {
      dispatch(requestNestedTenantConfig());
      const configResponse = await client.getTenantViewDefn<ConfigureOvertimeConfig>({
        defnId: viewDefns[0],
        appName: 'Assortment',
        validationSchema: NestedOvertimeViewDefn,
      });
      const defaultConfigureSelections = configResponse.configure.defaults.map((key, ind) => {
        const options = configResponse.configure.view[ind].options;
        return options.find((i) => i.dataIndex === key) || options[0];
      });
      const configureOptions = configResponse.configure.view;
      const configureSelections = configResponse.configure.defaults.map((key, ind) => {
        const options = configResponse.configure.view[ind].options;
        return options.find((i) => i.dataIndex === key) || options[0];
      });

      const rowHeight = configResponse.main && configResponse.main.rowHeight ? configResponse.main.rowHeight : 30;
      dispatch(
        receiveNestedTenantConfig({
          defaultConfigureSelections,
          configureOptions,
          configureSelections,
          viewDefn: configResponse,
          rowHeight,
          topMember: ownProps.selectedItemId,
        })
      );
    },
    updateConfigureSelections(uncommittedSelections: Option[]) {
      dispatch(setConfigureSelections(uncommittedSelections));
    },
    onRefresh(_id: string) {
      const defn = dataApi?.params.defnId;
      const topMembers = selectedItemId;
      const options: ListDataOptions = {
        ...dataApi?.params,
        topMembers,
      };
      dispatch(fetchNestedOvertimeData(defn, options));
    },
    onError(error: any) {
      dispatch(
        receiveError({
          type: ComponentErrorType.config,
          message: (error as Error)?.message,
          name: ConfDefnComponentType.nestedOvertime,
        })
      );
    },
  };

  return dispatchHandlers;
}

const NestedOvertimeGridWithSubheader = ({ subheaderSummary, ...props }: NestedOvertimeGridWithSubheader) => {
  const {
    viewDefn,
    rowHeight,
    defaultConfigureSelections,
    configureSelections,
    configureOptions,
    updateConfigureSelections,
    data,
    configLoaded,
    customHeader,
    selectedItemId,
    onShowView,
    onRefresh,
  } = props;

  const [search, setSearch] = useState('');
  const [uncommittedConfigureSelections, setUncommittedConfigureSelections] = useState([] as Option[]);
  const [configureIsOpen, setConfigureIsOpen] = useState(false);

  useEffect(() => {
    onShowView();
  }, []);

  useEffect(() => {
    onRefresh('');
    // we only want to trigger this when the item changes, onRefresh is changed every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemId]);

  const renderConfigureModal = () => {
    const configureModalProps: ConfigureModalProps = {
      enabled: true,
      isOpen: configureIsOpen,
      optionGroups: configureOptions || [],
      selections: uncommittedConfigureSelections || [],
      instructions: 'Select Aggregation Levels',
      onReset: () => {
        setUncommittedConfigureSelections(defaultConfigureSelections);
      },
      onToggleModal: (action) => {
        switch (action) {
          case 'apply': {
            if (uncommittedConfigureSelections) {
              setConfigureIsOpen(false);
              updateConfigureSelections(uncommittedConfigureSelections);
            }
            break;
          }
          default: {
            setConfigureIsOpen(false);
            setUncommittedConfigureSelections(configureSelections);
          }
        }
        // TODO: There has to be a better way... :/
        setConfigureIsOpen(!configureIsOpen);
      },
      selectionUpdate: (selections: Option[]) => {
        setUncommittedConfigureSelections(selections);
      },
    };
    return <ConfigureModal {...configureModalProps} />;
  };

  return (
    <div className={listPairStyle}>
      <Subheader
        title={''}
        showSearch={false}
        showFlowStatus={false}
        errorCondition={ASSORTMENT_BUILD_FILTER_ALL_WARNING}
        summary={subheaderSummary}
        configureOptions={{
          type: 'enabled',
          onConfigureClick: () => {
            setConfigureIsOpen(true);
            setUncommittedConfigureSelections(configureSelections);
          },
        }}
        searchReturn={(newVal: string) => {
          setSearch(newVal);
        }}
      />
      <div className={'data-container'}>
        <React.Fragment>
          {!isNil(data) && !isNil(viewDefn) ? (
            <OvertimeView
              loaded={configLoaded}
              viewConfig={viewDefn}
              rowHeight={rowHeight}
              aggBys={(configureSelections || []).map((sel) => sel.dataIndex)}
              treeData={data || []}
              search={search}
              exportOptions={{
                fileName: 'Nested Over Time',
                customHeader: customHeader,
              }}
            />
          ) : (
            <div />
          )}
        </React.Fragment>
        {renderConfigureModal()}
      </div>
    </div>
  );
};

export const TabbedNestedOvertimeGrid = connect(
  mapStateToPropsNestedOvertime,
  dispatchToPropsNestedOvertime
  // @ts-ignore
)(makeScopeAndFilterSensitive(makePopoverSensitive(NestedOvertimeGridWithSubheader)));
