import React, { useState } from 'react';
import { noop } from 'lodash';
import { IconLink } from 'src/components/Headerbar/Links/IconLink';
import { QuickReconConf } from 'src/components/Reconcilation/ReconcileModal';
import ReconcileModal from 'src/components/Reconcilation/ReconcileModal';

interface ReconcileLinkProps {
  title: string;
  conf: QuickReconConf;
}

export const ReconcileLink = ({ conf, title }: ReconcileLinkProps) => {
  const [isOpen, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <React.Fragment>
      <IconLink id="ReconcileLink" iconClass="fa fa-dollar-sign" onClick={openModal} text={title} />
      <ReconcileModal onToggleModal={closeModal} onReset={noop} isOpen={isOpen} enabled={true} quickReconConf={conf} />
    </React.Fragment>
  );
};
