import { style, classes } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { color } from 'csx';

const disabledColor = color('#c7c7c7').toHexString();

export default {
  container: style({
    width: 500,
    borderRadius: 50,
    padding: 10,
    overflowY: 'auto',
  }),
  titleContainer: style({
    width: '100%',
  }),
  subtitleContainer: style({
    width: '90%',
    fontSize: '0.9rem',
  }),
  bodyContainer: style({
    width: '100%',
  }),
  leftBodyContainer: style({
    width: '40%',
    display: 'inline-block',
  }),
  rightBodyContainer: style({
    width: '55%',
    display: 'inline-block',
  }),
  footerContainer: style({
    width: '100%',
  }),
  titleText: style({
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'rgb(108,108,108)',
  }),
  s5button: style({
    background: TEAL_PRIMARY,
    color: 'white',
    height: 45,
    cursor: 'pointer',
    margin: '0 auto',
    width: 340,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: '.9rem',
    marginBottom: 2,
    marginTop: 8,
  }),
  s5buttonCancel: style({
    color: TEAL_PRIMARY,
    height: 50,
    cursor: 'pointer',
    margin: '0 auto',
    width: 340,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: '.8rem',
    fontWeight: 300,
  }),
  buttonCancel: style({
    position: 'absolute',
    right: 15,
    top: 5,
    fontWeight: 300,
    cursor: 'pointer',
    color: 'darkgrey',
  }),
  textButton: style({
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 600,
    color: TEAL_PRIMARY,
    fontSize: '0.8rem',
    $nest: {
      '&:hover:disabled': {
        cursor: 'not-allowed',
      },
    },
  }),
  textButtonDisabled: style({
    cursor: 'not-allowed',
    color: disabledColor,
  }),
  disabledButtonContainer: style({
    background: disabledColor,
    color: '#ffffff',
  }),
  getDisabledTextButtonStyles() {
    return classes(this.textButtonDisabled, this.disabledButtonContainer);
  },
};

export const swatchStyles = style({
  $debugName: 'historyStylePaneSwatches',
  width: '100%',
  $nest: {
    div: {
      border: '1px solid #aca899',
      cursor: 'pointer',
      width: 20,
      height: 20,
      margin: '5px 3px',
      display: 'inline-block',
    },
  },
  whiteSpace: 'nowrap',
});
