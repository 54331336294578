import React from 'react';
import { Checkbox } from '@material-ui/core';
import { CellValueChangedEvent, Column, GridApi, IHeaderParams, RowNode } from 'ag-grid-community';
import { style } from 'typestyle';
import { isNil, some, every, defer } from 'lodash';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { iconStyles } from '../BackgroundDataLoading/BackgroundDataLoading';
export type CheckboxHeaderRendererProps = {
  checkedStatus: 'on' | 'off' | 'indeterminate';
  onChange: (checked: boolean, size: string) => void;
  isEditable?: boolean;
  ignoreHeaderText?: boolean;
  isProcessing?: boolean;
};

type Props = IHeaderParams & CheckboxHeaderRendererProps;

type State = {
  checked: 'on' | 'off' | 'indeterminate';
};

const styles = style({
  $debugName: 'checkbox-header-renderer',
  display: 'flex',
  flexDirection: 'column',
  $nest: {
    '.checkbox-header-checkbox': {
      transform: 'scale(.75)',
      padding: '0',
    },
    '.ag-header-cell-text': {
      lineHeight: '36px',
      margin: 'auto',
    },
  },
});

function getCheckedState(api: GridApi, column: Column): 'on' | 'off' | 'indeterminate' {
  const dataIndex = column.getColId();
  const items: RowNode[] = [];
  api.forEachNodeAfterFilter((item) => {
    if (!isNil(item.allChildrenCount) && item.allChildrenCount > 0) {
      // skip nodes that are group nodes.
      return;
    }
    items.push(item);
  });
  // check for on/off
  const allSelected = every(items, (item) => item.data[dataIndex]);
  // check for indeterminate
  const someSelected = some(items, (item) => item.data[dataIndex]);
  const initialCheckedState = allSelected ? 'on' : someSelected ? 'indeterminate' : 'off';
  return initialCheckedState;
}

export default class CheckboxHeaderRenderer extends React.Component<Props, State> {
  checkMap = {
    on: {
      checked: true,
      indeterminate: false,
    },
    off: {
      checked: false,
      indeterminate: false,
    },
    indeterminate: {
      checked: false,
      indeterminate: true,
    },
  };

  constructor(props: Props) {
    super(props);

    // listen for changes in the grid for this column only to update the header checkbox status to the correct state
    // we need a defer to allow the actual grid's valuechange a chance to go first (for some reason it's fired after
    // the listeners vOv). Yes, I realise this is not ideal...we'll think of something.
    props.api.addEventListener('cellValueChanged', (event: CellValueChangedEvent) =>
      defer(this.onCellValueChanged, event)
    );

    this.state = {
      checked: this.props.checkedStatus ? this.props.checkedStatus : getCheckedState(props.api, props.column),
    };
  }

  componentWillUnmount() {
    // Any destruction calls need to be done here, not in destroy()
    this.props.api.removeEventListener('cellValueChanged', this.onCellValueChanged);
  }

  onCellValueChanged = (event: CellValueChangedEvent) => {
    if (event.column.getColId() === this.props.column.getColId()) {
      this.setState({
        checked: this.props.checkedStatus
          ? this.props.checkedStatus
          : getCheckedState(this.props.api, this.props.column),
      });
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const size = this.props.column.getColDef().refData?.size;
    this.setState(
      {
        checked: event.target.checked ? 'on' : 'off',
      },
      () => {
        if (this.props.onChange && size) {
          this.props.onChange(this.state.checked === 'on' ? true : false, size);
        } else if (this.props.onChange) {
          this.props.onChange(this.state.checked === 'on' ? true : false, '');
        }
      }
    );
  };
  getValue() {
    return this.state.checked;
  }

  isPopup() {
    return false;
  }

  render() {
    const { column, ignoreHeaderText, isProcessing } = this.props;
    const colDef = column.getColDef();
    const headerName = colDef.headerName || column.getColId();
    return (
      <div className={styles} style={{ lineHeight: isProcessing ? '30px' : 'revert' }}>
        <div className={iconStyles} style={{ textAlign: 'center', display: isProcessing ? undefined : 'none' }}>
          <span className="fas fa-spinner fa-spin" />
        </div>
        <div
          style={{
            textAlign: 'center',
            lineHeight: 'initial',
            display: isProcessing ? 'none' : 'flex',
            flexDirection: 'column',
          }}
        >
          {!ignoreHeaderText && !isNil(headerName) && <span className="ag-header-cell-text">{headerName}</span>}
          <Checkbox
            onChange={this.handleChange}
            icon={<RadioButtonUncheckedIcon />}
            style={{
              transform: 'scale(.75)',
              padding: 0,
              color: '#04a79b',
            }}
            checkedIcon={<CheckCircleOutlineIcon />}
            className="checkbox-header-checkbox"
            {...this.checkMap[this.state.checked]}
          />
        </div>
      </div>
    );
  }
}
