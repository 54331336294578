import * as React from 'react';
import { IHeaderGroupParams } from 'ag-grid-community';
import { style, classes } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';

const styles = {
  container: style({
    position: 'absolute',
    left: 10,
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 2,
    top: 8,
    fontSize: '.85rem',
  }),
  iconContainer: style({
    color: TEAL_PRIMARY,
    marginLeft: 5,
  }),
};

export type CustomGroupHeaderState = {
  expanded: boolean;
};

export default class CustomGroupHeader extends React.Component<IHeaderGroupParams, CustomGroupHeaderState> {
  constructor(props: IHeaderGroupParams) {
    super(props);
    this.state = {
      expanded: false,
    };

    props.columnGroup.getOriginalColumnGroup().addEventListener('expandedChanged', this.syncExpandButtons.bind(this));
  }

  componentDidMount() {
    this.syncExpandButtons();
  }

  expandOrCollapse = () => {
    const currentState = this.props.columnGroup.getOriginalColumnGroup().isExpanded();
    this.props.setExpanded(!currentState);
    this.syncExpandButtons();
  };

  syncExpandButtons = () => {
    this.setState({
      expanded: this.props.columnGroup.getOriginalColumnGroup().isExpanded(),
    });
  };

  render() {
    return (
      <div className={classes('ag-header-group-cell-label', styles.container)}>
        <div className="customHeaderLabel">{this.props.displayName}</div>
        <div className={classes(`customExpandButton`, styles.iconContainer)} onClick={() => this.expandOrCollapse()}>
          {/*<i className={this.state.expanded ? 'fa fa-arrow-left' : 'fa fa-arrow-right'} />*/}
        </div>
      </div>
    );
  }
}
