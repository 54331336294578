import { style } from 'typestyle';

const arrowSize = '15px';

const container = style({
  $debugName: 'ControlPopperContainer',
});

const paper = style({
  position: 'relative',
  padding: 2,
  border: '1px lightgrey solid',
  boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.14)',
  borderRadius: '4px',
  backgroundColor: '#fff',
  top: 14,
  display: 'flex',
  justifyContent: 'space-evenly',
  // top: arrowSize
});

const control = style({
  display: 'inline-flex',
  cursor: 'pointer',
  textAlign: 'center',
  alignItems: 'center',
  minHeight: 60,
  borderRadius: 10,
  margin: 5,
  $nest: {
    '&:hover': {
      backgroundColor: '#d8d8d8',
    },
  },
  padding: 5,
});

const highlightArrow = style({
  // TODO make the arrow blend well with the material ui paper
  borderBottom: `${arrowSize} solid lightgrey`,
  borderLeft: `${arrowSize} solid transparent`,
  borderRight: `${arrowSize} solid transparent`,
  height: 0,
  width: 0,
  position: 'absolute',
  top: -15,
  transform: 'translateX(-50%)',
  left: '50%',
});

const highlightArrowInner = style({
  borderBottom: `${arrowSize} solid white`,
  borderLeft: `${arrowSize} solid transparent`,
  borderRight: `${arrowSize} solid transparent`,
  height: 0,
  width: 0,
  position: 'absolute',
  top: -13,
  transform: 'translateX(-50%)',
  left: '50%',
});

const iconCircle = style({
  width: 40,
  height: 20,
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
});

const controlItem = style({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});

const itemText = style({
  fontSize: '0.7rem',
  marginTop: 5,
  opacity: 0.75,
});

const disabled = style({
  color: 'rgb(189 189 189)',
  cursor: 'not-allowed'
})

export default {
  container,
  paper,
  control,
  highlightArrow,
  iconCircle,
  controlItem,
  itemText,
  highlightArrowInner,
  disabled,
};
