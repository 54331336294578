import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// do not remove this HighMap import
import HighchartsMap from 'highcharts/modules/map';
HighchartsMap(Highcharts);
import './proj4';
import { AutoSizer } from 'react-virtualized';
import * as fp from 'lodash';

const MARGIN_BOTTOM = 50;
const MIN_RANGE = 1000; // how far in you can zoom

export interface GeoChartProps {
  title: string;
  mapUri?: string; // allow a first blank render with title only to improve perceived performance
  className?: string;
  onMapBubbleClick?: (evt: Highcharts.SeriesClickEventObject) => void;
  mapSeries: (Highcharts.SeriesMapbubbleOptions | Highcharts.SeriesMapOptions)[];
  geoConfig?: Highcharts.Options;
}

export interface GeoChartState {
  backgroundLoaded: boolean;
}

const baseConfig: Highcharts.Options = {
  chart: {
    backgroundColor: '#FDFCFD',
    animation: false,
    marginBottom: MARGIN_BOTTOM,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    headerFormat: '<span>{series.name}</span><br/>',
  },
  mapNavigation: {
    enabled: true,
    buttonOptions: {
      verticalAlign: 'bottom',
    },
  },
  legend: {
    align: 'center',
    verticalAlign: 'bottom',
    floating: true,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    symbolRadius: 7,
    symbolHeight: 14,
  },
  xAxis: {
    minRange: MIN_RANGE,
  },
  yAxis: {
    minRange: MIN_RANGE,
  },
};

export default class GeoChart extends React.Component<GeoChartProps, GeoChartState> {
  private mapChartObject!: Highcharts.Chart;
  private mapBackground?: any; // loaded from unchecked remote json

  constructor(props: GeoChartProps) {
    super(props);
    this.state = {
      backgroundLoaded: false,
    };

    this.setHighMapRef = this.setHighMapRef.bind(this);
    this.handleMapBubbleClick = this.handleMapBubbleClick.bind(this);
  }

  updateChartData() {
    if (this.props.mapUri && !this.mapBackground) {
      this.getMapBackground(this.props.mapUri);
    }

    const props = this.props;
    const mapSeries = props.mapSeries;

    function updateChart(
      chart: Highcharts.Chart,
      series: (Highcharts.SeriesMapbubbleOptions | Highcharts.SeriesMapOptions)[]
    ) {
      // this doesn't really do anything right now
      if (chart && chart.series) {
        chart.series.map((i) => i).forEach((serie) => serie.remove(true));
        series.forEach((serie) => chart.addSeries(serie));
      }
    }

    updateChart(this.mapChartObject, mapSeries);
  }

  componentDidMount() {
    this.updateChartData();
  }
  componentDidUpdate() {
    this.updateChartData();
  }

  getMapBackground(mapUri: string) {
    // data is loaded externally from /public/maps
    let mapDataPromise: Promise<Response>;

    if (mapUri) {
      mapDataPromise = fetch(mapUri);
      mapDataPromise
        .then((response) => {
          response
            .json()
            .then((parsedJson) => {
              this.mapBackground = parsedJson;
              this.setState({ backgroundLoaded: true });
            })
            .catch(() => console.log('Something went wrong loading the background map.'));
        })
        .catch(() => console.log('Something went wrong loading the background map.'));
    }
  }

  shouldComponentUpdate(nextProps: GeoChartProps, nextState: GeoChartState) {
    if (this.props.title !== nextProps.title) {
      return true;
    }
    if (this.props.mapUri !== nextProps.mapUri) {
      return true;
    }
    if (this.state.backgroundLoaded !== nextState.backgroundLoaded) {
      return true;
    }

    const oldData = this.props.mapSeries;
    const newData = nextProps.mapSeries;

    // This kind of check isn't recommended by react for blocking render reasons,
    // but I checked the perf and the equality check only takes 0.6ms so I'm leaving it
    return !fp.isEqual(oldData, newData);
  }

  handleMapBubbleClick(event: Highcharts.SeriesClickEventObject) {
    if (this.props.onMapBubbleClick) {
      this.props.onMapBubbleClick(event);
    }
  }

  setHighMapRef(element: any) {
    if (!fp.isNil(element)) {
      if (!fp.isNil(element.chart)) {
        this.mapChartObject = element.chart;
      }
    }
  }

  render() {
    const { mapSeries, title, geoConfig } = this.props;
    const backGroundData = this.mapBackground;

    const backgroundSeries: Highcharts.SeriesOptionsType = {
      showInLegend: false,
      type: 'map',
      mapData: backGroundData,
      index: 0,
    };

    const attachFinalOptions: Highcharts.Options = {
      title: {
        text: title,
      },
      series: [backgroundSeries, ...mapSeries],
      plotOptions: {
        series: {
          events: {
            click: this.handleMapBubbleClick,
          },
        },
      },
    };

    // attach overall options (title, data, etc.), then apply any props.geoConfig, then apply default config
    const mapOptions: Highcharts.Options = fp.defaultsDeep(attachFinalOptions, geoConfig, baseConfig);

    return (
      <AutoSizer style={{ height: '100%', width: '100%' }}>
        {({ height, width }) => {
          if (height <= 0) {
            return null;
          }
          const sizingConf = {
            chart: { height: height - 1, width: width - 1 },
          };
          return (
            <HighchartsReact
              constructorType={'mapChart'}
              key={'mapChart'}
              ref={this.setHighMapRef}
              options={fp.defaultsDeep(mapOptions, sizingConf)}
              immutable={true}
            />
          );
        }}
      </AutoSizer>
    );
  }
}
