import { style, classes } from 'typestyle';
import { match } from 'react-router';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { isNil } from 'lodash';

const COLLAPSED_SIDNAV_WIDTH = 54;
const FULL_SIDENAV_WIDTH = 225;

const DELAY_SECONDS = 0.4;
const TRANSITION_SECONDS = 0.4;
const makeTransition = (str: string, str2?: string) => {
  let returnString = `${str} ${TRANSITION_SECONDS}s ${DELAY_SECONDS}s ease-in-out`;
  if (str2) {
    returnString = returnString + ', ' + makeTransition(str2);
  }
  return returnString;
};

const COLLAPSE_ICON_FULL_LEFT = 202;
const sidebar = (scrollBarShowed: boolean) =>
  style({
    $debugName: 'sidebar',
    height: `calc(100% - 70px)`,
    background: '#f4f3f4',
    overflowY: 'auto',
    overflowX: 'hidden',
    flexShrink: 0,
    width: FULL_SIDENAV_WIDTH + (scrollBarShowed ? 15 : 0),
    $nest: {
      '& .collapseIconContainer': {
        position: 'relative',
        fontSize: 15,
        left: COLLAPSE_ICON_FULL_LEFT,
        marginTop: 1,
        cursor: 'pointer',
        top: -22,
        color: '#8c8c8c',
      },
    },
  });

const title = style({
  $debugName: 'title',
  fontSize: '12px',
});

const activeGroup = style({
  $debugName: 'activeGroup',
  color: '#00a89b',
});

const groupTitle = style({
  $debugName: 'groupTitle',
  fontSize: '12px',
  padding: '0.5rem',
  margin: 0,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  $nest: {
    '&.text-is-in-view': {
      color: '#ffffff',
    },
  },
});

function groupCollapser(isExpanded: boolean, isViewIn: boolean) {
  if (isExpanded) {
    return classes(groupTitle, 'text-primary', 'd-flex', 'flex-row', 'justify-content-between', 'w-100');
  }
  if (isViewIn) {
    return classes(
      groupTitle,
      'text-is-in-view',
      'bg-primary',
      'd-flex',
      'flex-row',
      'justify-content-between',
      'w-100'
    );
  }
  return classes(groupTitle, 'text-secondary', 'd-flex', 'flex-row', 'justify-content-between', 'w-100');
}

function navLink(routeMatch: match | null) {
  return classes(routeMatch ? 'text-white bg-primary' : 'text-secondary', 'nav-link');
}

const collapsedCaret = style({
  transform: 'rotate(0deg)',
  transition: 'transform 0.2s linear',
});

const expandedCaret = style({
  transform: 'rotate(180deg)',
  transition: 'transform 0.2s linear',
});

const iconStyle: NestedCSSProperties = {
  position: 'relative',
  left: -18,
};
const transitionIconStyle: NestedCSSProperties = {
  transition: makeTransition('left'),
};

// Nesting should be okay as it only affects the Sidenav div
const collapsedSidenavStyles = (scrollBarShowed: boolean) =>
  style({
    width: COLLAPSED_SIDNAV_WIDTH + (scrollBarShowed ? 15 : 0),
    whiteSpace: 'nowrap',
    position: 'absolute',
    zIndex: 102,
    $nest: {
      '& .collapseIconContainer': {
        left: 24,
        fontSize: 19,
      },
      '& .sectionNameDisplayed': {
        opacity: 0,
      },
      '& .d-flex > .fal': iconStyle,
      '& .d-flex > .far': iconStyle,
      '& .d-flex > .fas': iconStyle,
      '& .d-flex > .fab': iconStyle,
    },
  });
const warningIcon = {
  fontSize: '1rem',
};
const viewName = {
  fontSize: '.75rem',
};
const navItemWrapper = (isErrorView: boolean | undefined) => {
  const isError = !isNil(isErrorView) && isErrorView === true;
  return style({
    display: 'flex',
    color: isError ? 'red' : '',
    cursor: isError ? 'not-allowed' : 'pointer',
    alignItems: 'center'
  });
};

const fakeSidenavDivForSpacing = (scrollBarShowed: boolean) =>
  style({
    width: COLLAPSED_SIDNAV_WIDTH + (scrollBarShowed ? 15 : 0),
    height: '100%',
  });

const sidenavHover = () =>
  style({
    transition: makeTransition('width', 'box-shadow'),
    $nest: {
      '& .collapseIconContainer': {
        transition: makeTransition('left', 'font-size'),
      },
      '& .sectionNameDisplayed': {
        transition: makeTransition('left', 'opacity'),
      },
      '& .d-flex > .fal': transitionIconStyle,
      '& .d-flex > .far': transitionIconStyle,
      '& .d-flex > .fas': transitionIconStyle,
      '& .d-flex > .fab': transitionIconStyle,
    },
  });

export default {
  sidebar,
  title,
  activeGroup,
  groupTitle,
  groupCollapser,
  navLink,
  collapsedCaret,
  expandedCaret,
  collapsedSidenavStyles,
  sidenavHover,
  fakeSidenavDivForSpacing,
  navItemWrapper,
  warningIcon,
  viewName,
};
