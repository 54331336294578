import React, { ChangeEvent } from 'react';
import { noop } from 'lodash';
import Input from '@material-ui/core/Input';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

export type InputIntegerProps = {
  thousandSeparator?: boolean | string;
  value?: string | number | null;
  onChange?: (text: string | number | null) => void;
  onBlur?: (prop: any) => any;
  editable?: boolean;
  valid?: boolean;
  autoFocus?: boolean;
  className?: string;
  // using onValueChange trips handler on prop changes,
  // this allows us to use regular onChange which is only triggered by user interactions
  bypassDefaultHandler?: boolean;
  nullable?: boolean;
};
export default class InputInteger extends React.Component<InputIntegerProps> {
  static defaultProps = {
    thousandSeparator: false,
  };
  constructor(props: InputIntegerProps) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }
  handleOnChange(values: NumberFormatValues) {
    if (this.props.onChange) {
      this.props.onChange(values.floatValue != undefined ? values.floatValue : null);
    }
  }
  handleOverrideOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const floatValue = parseFloat(
      this.props.thousandSeparator ? event.target.value.replace(/,/g, '') : event.target.value
    );
    if (this.props.onChange) {
      this.props.onChange(floatValue != undefined ? floatValue : null);
    }
  };
  render() {
    const { value, editable, bypassDefaultHandler } = this.props;
    let content: JSX.Element | undefined;

    if (!editable) {
      content = (
        <div>
          <p style={{ marginTop: 14.4 }}>{value}</p>
        </div>
      );
    } else {
      let value = this.props.value;
      if (value == null) {
        value = '';
      }
      content = (
        <NumberFormat
          onValueChange={!bypassDefaultHandler ? this.handleOnChange : noop}
          onChange={bypassDefaultHandler ? this.handleOverrideOnChange : noop}
          decimalScale={0}
          allowNegative={false}
          customInput={Input}
          value={value}
          thousandSeparator={this.props.thousandSeparator}
          disableUnderline={true}
          onBlur={() => {
            if (!this.props.nullable && this.props.onChange && value == '') {
              this.props.onChange(0);
            }
          }}
        />
      );
    }
    return content;
  }
}
