import { classes, style } from 'typestyle';
import { TEAL_PRIMARY, smallBreakpoint } from 'src/utils/Style/Theme';
import { SUBHEADER_HEIGHT } from 'src/components/Subheader/Subheader.styles';

const RIGHT_PAGE_OPTIONS_WIDTH = 150;
const PARETO_MIN_HEIGHT = 717;
const mainContent = style({
  $debugName: 'mainContent',
  width: `100%`,
  float: 'left',
  height: `calc(100% - ${SUBHEADER_HEIGHT}px)`,
});
const mainContentFitScreen = style({
  minHeight: PARETO_MIN_HEIGHT,
});
const mainContentWrapper = style({
  overflow: 'auto',
});
const mainContentPrint = style({
  zoom: 0.8,
});

const pageOptions = style({
  $debugName: 'pageOptions',
  display: 'inline-block',
  width: RIGHT_PAGE_OPTIONS_WIDTH,
  paddingLeft: 20,
});

const basicOption = style({
  $debugName: 'pageOption',
  width: '100%',
  margin: '10px 0',
  cursor: 'pointer',
});

const selectedOption = style({
  borderLeft: `5px solid #289f88`,
  paddingLeft: '5px',
});

const pageOption = (active: boolean) => {
  if (active) {
    return classes(basicOption, selectedOption);
  }
  return basicOption;
};
const exitButton = 'exit-btn fas fa-times-circle';
const modalHeader = style({
  width: '100%',
  backgroundColor: TEAL_PRIMARY,
  padding: '1em',
  marginBottom: '1em',
  $nest: {
    h3: {
      fontSize: '1.25em',
      margin: 0,
      color: 'white',
      paddingLeft: '1.25em',
      display: 'inline-block',
    },
    '.exit-btn': {
      float: 'right',
      fontSize: '1.25em',
      color: 'white',
      cursor: 'pointer',
    },
  },
});
const modal = style({
  justifyContent: window.innerWidth > smallBreakpoint ? 'center' : 'inherit',
  display: 'flex',
  alignItems: 'center',
});

export default {
  mainContentFitScreen,
  mainContent,
  pageOptions,
  pageOption,
  modalHeader,
  exitButton,
  mainContentWrapper,
  mainContentPrint,
  modal,
};
