import React, { useEffect } from 'react';
import { HasPerspectives, HasBindings } from 'src/services/configuration/service';
import { Link, Redirect } from 'react-router-dom';
import styles from 'src/components/TopNavbar/TopNavbar.styles';
import { BoundPerspective, BoundRoute } from 'src/services/configuration/bindings.types';
import { computePerspectiveRoute } from '../NavigationShell/navigationUtils';
import { resolvePath } from 'src/cdn';
import {
  containerStyle,
  disabledStyle,
  brandLogoCentered,
} from 'src/pages/PerspectiveSelection/PerspectiveSelection.styles';
import { isNil } from 'lodash';
import RestoreSession from 'src/components/RestoreSession/RestoreSession.container';
import {
  getSessionPage,
  getSessionPerspective,
  removeSessionAll,
} from 'src/components/RestoreSession/RestoreSession.utils';
import blackTextLogo from 'src/images/S5Logo-Black-Inovation-Centered.png';
import brandLogoPath from 'src/images/S5Logo-White-NoInnovation-Centered.png';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { viewPath } from 'src/dao/tenantConfigClient';
import Axios from 'src/services/axios';
import TopNavIconLink from 'src/components/TopNavbar/TopNavIconLink';
import { logout } from 'src/services/auth/platform';
import { Props } from './PerspectiveSelection.container';
import TopNavIconPopover, { TopNavIconPopoverMenuItem } from 'src/components/TopNavbar/TopNavIconPopover';

const BrandLogo = resolvePath(brandLogoPath);

export const isPerspective = (value: BoundPerspective | BoundRoute): value is BoundPerspective => 'pathSlot' in value;
export type PerspectiveConfig = {
  type: string;
  view: (BoundPerspective | BoundRoute)[];
};

export const getPerspectiveConfig = () => {
  return Axios.get<PerspectiveConfig>(`${viewPath}/${ASSORTMENT}/Perspectives`);
};

// eslint-disable-next-line react/display-name
export default (config: HasPerspectives & HasBindings, configuratorConfig: HasPerspectives & HasBindings) => (
  props: Props
) => {
  const sessionPage = getSessionPage();
  const sessionPerspective = getSessionPerspective();
  const hasLocalStorage = !isNil(sessionPage) && !isNil(sessionPerspective);
  const perspectives = config.getPerspectivesWithRoutes();

  useEffect(() => {
    props.resetScope();
  }, []);

  if (perspectives.length === 1 && isPerspective(perspectives[0])) {
    const ui = config.getBindings(perspectives[0]);
    return <Redirect to={computePerspectiveRoute(ui)} />;
  }

  const userDropdownMenuItems: TopNavIconPopoverMenuItem[] = [
    {
      text: 'Logout',
      qaString: 'Logout',
      onClick: logout,
    },
  ];

  return (
    <div style={{ textAlign: 'center' }}>
      <nav className={`bg-dark ${styles.mainNavBar}`} data-qa="TopNav">
        <span className="logo-container">
          <Link to="/">
            <img className="logo" src={BrandLogo} alt="" data-qa="TopNavHomeIcon" />
          </Link>
        </span>
        <div className={styles.topNav}>
          <span className="buttons">
            <TopNavIconLink
              iconClass="fas fa-question-circle"
              href="http://s5stratoshelp.helpdocsonline.com/home/"
              target="_blank"
              data-qa="TopNavHelp"
            />
            <span className="text-light">
              <TopNavIconPopover
                icon={'fas fa-user user'}
                iconQaString={'User'}
                dropdownItems={userDropdownMenuItems}
              />
            </span>
          </span>
        </div>
      </nav>
      <img className={brandLogoCentered} src={blackTextLogo} alt="" />
      <div className={containerStyle}>
        {perspectives.map((p, index) => {
          let to = '',
            dataId = index.toString();
          if (isPerspective(p)) {
            if (p.id == 'Configurator') {
              to = computePerspectiveRoute(configuratorConfig.getBindings(p));
            } else {
              to = computePerspectiveRoute(config.getBindings(p));
            }
            dataId = p.id;
          } else {
            to = p.route;
          }
          return (
            <Link onClick={removeSessionAll} key={index} data-id={dataId} to={to}>
              <div className={p.disabled === true ? disabledStyle : ''}>
                <span className={`fa-stack fa-3x ${p.disabled === true ? 'disabled' : ''}`}>
                  <i className={p.iconSrc} />
                </span>
              </div>
              <label>{p.display}</label>
            </Link>
          );
        })}
      </div>
      {hasLocalStorage && <RestoreSession />}
    </div>
  );
};
