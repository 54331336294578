import moment from 'moment';
import { forEach } from 'lodash';

import { DaysRangeListResponse } from 'src/types/Scope';
import { WeekRange } from 'src/common-ui/components/WeekRange/WeekRangePicker.interface';
import { weekIdParser } from 'src/common-ui/components/WeekRange/WeekRangePicker.utils';

export type WeekNumberRange = {
  start: string;
  end: string;
};

export type WeekNumberList = string[];

export function getDateFromWeek(week: string, validDays: Record<string, any>) {
  return Object.keys(validDays).find((date) => validDays[date] === week) || '';
}

export function getDatesFromRange(range: WeekRange, daysRangeList: DaysRangeListResponse): WeekNumberRange {
  const { from, to } = range;
  const fromDate = moment(from).format('YYYY-MM-DD');
  const toDate = moment(to).format('YYYY-MM-DD');
  const fromId = daysRangeList.start_date[fromDate];
  const toId = daysRangeList.end_date[toDate];
  return {
    start: fromId,
    end: toId,
  };
}

export function getWeekListFromRange(range: WeekRange, daysRangeList: DaysRangeListResponse): WeekNumberList {
  const weekNumberRange = getDatesFromRange(range, daysRangeList);

  const { start, end } = weekNumberRange;

  if (start === end) {
    return [start];
  }
  const startWeekObject = weekIdParser(start);
  const endWeekObject = weekIdParser(end);

  const startWeek = startWeekObject.week;
  const endWeek = endWeekObject.week;

  // get week numbers within range (inclusive)
  const weekList: string[] = [];

  forEach(daysRangeList.start_date, (value: string) => {
    // add start/end weeks
    if (value === start || value === end) {
      weekList.push(value);
      return;
    }
    const parsedTime = weekIdParser(value);

    const startYear = startWeekObject.year;
    const endYear = endWeekObject.year;

    // add weeks in range
    // if start and end date are in the same year
    const valueWeek = parsedTime.week;
    const valueYear = parsedTime.year;
    if (startYear === endYear && valueYear === startYear) {
      if (valueWeek > startWeek && valueWeek < endWeek) {
        weekList.push(value);
      }
    }
    // if start and end date are not in the same year.
    // ie. Start = 2019_W45 -> End = 2020_W02
    else if (endYear > startYear) {
      if (valueWeek > startWeek && valueYear === startYear) {
        weekList.push(value);
      } else if (valueWeek < endWeek && valueYear === endYear) {
        weekList.push(value);
      }
    }
  });

  return weekList.sort();
}
