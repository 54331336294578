import React, { Component } from 'react';
import { isNil, get, isEmpty } from 'lodash';

import container from 'src/ServiceContainer';
import TemplateModalWithButton from 'src/components/TemplateModalWithButton/TemplateModalWithButton';
import { NavigationButton } from 'src/components/NavigationButton/NavigationButton';
import { ViewSimilarItemsSubheader } from './ViewSimilarItemsSubheader';
import { ViewsimilarItemsCardsView } from './ViewSimilarItemsCardsView';
import {
  ReduxSlice as SubheaderSlice,
  initialGroupBy,
  initialSortBy,
  initialPareDown,
} from 'src/components/Subheader/Subheader.slice';
import { CanvasViewSlice } from 'src/pages/Hindsighting/StyleColorReview/CanvasView/CanvasView.slice';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { getGroupedData } from 'src/pages/Hindsighting/StyleColorReview/CanvasView/CanvasView.selectors';
import { AssortmentPayload } from 'src/components/RightContainer/RightContainer.slice';
import {
  ViewSimilarItemsModalProps,
  ViewSimilarItemsModalState,
  ViewSimilarItemsConfigResponse,
  ViewSimiliarItemsSubheaderChange,
} from './ViewSimilarItems.types';
import { retrieveIdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.utils';

import { viewWidth } from 'csx';
import { BasicPivotItem, ListDataOptions } from 'src/worker/pivotWorker.types';

const initialState: SubheaderSlice = {
  search: '',
  groupBy: initialGroupBy,
  sortBy: initialSortBy,
  pareDown: initialPareDown,
  subheaderLoading: false,
  // following props aren't necessary for this view but here to compile
  lookBackPeriod: '',
  flowStatus: [],
  altSearch: '',
  altFlowStatus: [],
};
type ModelApi = {
  modelId: string;
  options: ListDataOptions;
};
export class ViewSimilarItemsModal extends Component<ViewSimilarItemsModalProps, ViewSimilarItemsModalState> {
  constructor(props: ViewSimilarItemsModalProps) {
    super(props);

    // necessary to bind to proper scope since being passed through several event handlers
    this.handleItemToggle = this.handleItemToggle.bind(this);
    this.state = {
      subheaderSlice: {
        ...initialState,
        search: this.props.initialSearchBy || '',
      },
      cardConfigsLoaded: false,
      cardDataLoaded: false,
      cardConfigs: null,
      rawCardData: [],
      formattedCardData: [],
      selectedItems: [],
    };
  }

  async componentDidMount() {
    await this.getCardDefns();
  }

  shouldComponentUpdate(_nextProps: ViewSimilarItemsModalProps, nextState: ViewSimilarItemsModalState) {
    if (this.state.selectedItems.length !== nextState.selectedItems.length) {
      return true;
    }

    return true;
  }

  onModalOpen = () => {
    if (isNil(this.state.rawCardData) || isEmpty(this.state.rawCardData)) {
      this.fetchCardData();
    } else {
      this.processCardData();
    }
  };

  getCardDefns = async () => {
    const { cardViewDefns } = this.props;
    const configResponse: ViewSimilarItemsConfigResponse = await container.tenantConfigClient.getTenantViewDefns({
      defnIds: cardViewDefns,
      appName: ASSORTMENT,
    });
    const [viewDefn, calcViewDefn] = configResponse;
    const identityPropsConfig = retrieveIdentityPropsConfig(viewDefn);

    this.setState({
      cardConfigsLoaded: true,
      cardConfigs: {
        viewDefn,
        calcViewDefn,
        identityPropsConfig,
      },
    });
  };

  processCardData = () => {
    const { rawCardData, cardConfigs, subheaderSlice, selectedItems } = this.state;

    const formattedCardData = getGroupedData(
      rawCardData,
      cardConfigs! as CanvasViewSlice,
      subheaderSlice,
      selectedItems
    );

    this.setState({
      formattedCardData,
    });
  };

  fetchCardData = async () => {
    const { cardConfigs } = this.state;
    const { viewDefn } = cardConfigs!;

    // const defnId = !isNil(viewDefn) && !isNil(viewDefn.model) ? viewDefn.model : '';
    const modelReq = (get(viewDefn, 'main.modelApi') as unknown) as ModelApi;
    if (this.props.topMembers) {
      modelReq.options.topMembers = this.props.topMembers;
    }
    const data = await container.pivotService.listData(modelReq.modelId, ASSORTMENT, modelReq.options);
    const rawCardData = data.flat;

    this.setState(
      {
        cardDataLoaded: true,
        rawCardData,
      },
      () => {
        this.processCardData();
      }
    );
  };

  handleSubheaderChange = (changes: ViewSimiliarItemsSubheaderChange) => {
    const { search, groupBy, sortBy, groupByDefn, sortByDefn } = changes;
    const { cardConfigs, rawCardData, selectedItems } = this.state;

    const updatedSlice = {
      ...this.state.subheaderSlice,
      search: !isNil(search) ? search : this.state.subheaderSlice.search,
      groupBy: !isNil(groupBy) ? groupBy : this.state.subheaderSlice.groupBy,
      sortBy: !isNil(sortBy) ? sortBy : this.state.subheaderSlice.sortBy,
      groupByDefn: !isNil(groupByDefn) ? groupByDefn : this.state.subheaderSlice.groupByDefn,
      sortByDefn: !isNil(sortByDefn) ? sortByDefn : this.state.subheaderSlice.sortByDefn,
    };

    const formattedCardData = getGroupedData(rawCardData, cardConfigs! as CanvasViewSlice, updatedSlice, selectedItems);

    this.setState({
      subheaderSlice: {
        ...updatedSlice,
      },
      formattedCardData,
    });
  };

  handleItemToggle(item: AssortmentPayload) {
    const { cardConfigs, rawCardData, selectedItems } = this.state;
    const { identityPropsConfig } = cardConfigs!;
    const toggledItem = rawCardData.find((dataItem) => item.id === dataItem[identityPropsConfig.id]);
    let updatedItems: BasicPivotItem[] = selectedItems;
    // if (toggledOn) {
    //   // toggle item off by removing from selectedItems
    //   updatedItems = selectedItems.filter((dataItem) => item.id !== dataItem[identityPropsConfig.id]);
    // }
    // else {
    // updatedItems = [...selectedItems, toggledItem!];
    // }
    if (toggledItem != null) {
      updatedItems = [toggledItem!];
    }
    // toggle item on
    this.setState(
      {
        selectedItems: updatedItems,
      },
      () => {
        this.processCardData();
      }
    );
  }

  handleModalSubmit = () => {
    console.log(`submitting ${this.state.selectedItems.length} selected items.`);

    // TODO: post items to api
    this.props.onSubmit(this.state.selectedItems);
    this.setState({
      selectedItems: [],
    });
  };

  handleModalCancel = () => {
    this.setState({
      selectedItems: [],
    });
  };

  render() {
    const { subheaderViewDefns, buttonText, level, cardType } = this.props;
    const { subheaderSlice, cardConfigsLoaded, cardDataLoaded, cardConfigs, formattedCardData } = this.state;

    return (
      <TemplateModalWithButton
        styleOverrides={{ width: viewWidth(95) }}
        buttonComponent={<NavigationButton buttonText={buttonText} />}
        title={'View Similar Items'}
        onOpen={this.onModalOpen}
        showSubmit={true}
        onSubmit={this.handleModalSubmit}
        onCancel={this.handleModalCancel}
      >
        <ViewSimilarItemsSubheader
          viewDefns={subheaderViewDefns}
          subheaderSlice={subheaderSlice}
          onSubheaderChange={this.handleSubheaderChange}
        />
        <ViewsimilarItemsCardsView
          cardType={cardType}
          level={level}
          subheaderSlice={subheaderSlice}
          configLoaded={cardConfigsLoaded}
          dataLoaded={cardDataLoaded}
          configs={cardConfigs}
          cardData={formattedCardData}
          onToggleItem={this.handleItemToggle}
        />
      </TemplateModalWithButton>
    );
  }
}
