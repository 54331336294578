import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, withStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import {
  accordionOverrides,
  accordionSummaryOverrides,
  accordionDetailsOverrides,
} from 'src/components/StyledAccordion/StyledAccordion.styles';

const LocalAccordion = withStyles(() => accordionOverrides)(Accordion);
const StyledAccordionSummary = withStyles(() => accordionSummaryOverrides)(AccordionSummary);
const StyledAccordionDetails = withStyles(() => accordionDetailsOverrides)(AccordionDetails);

interface StyledAccordionProps {
  title: string;
  expanded: boolean;
}

export const StyledAccordion: React.FunctionComponent<StyledAccordionProps> = ({ title, expanded, children }) => {
  return (
    <LocalAccordion defaultExpanded={expanded} square={true} elevation={0}>
      <StyledAccordionSummary expandIcon={<ExpandMore />} IconButtonProps={{ edge: 'start' }}>
        <Typography variant="body2">{title}</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </LocalAccordion>
  );
};
