import * as React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { StyleDetailsPopoverProps } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { IconCellRendererStyles } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/IconCellRenderer';

export type Params = ICellRendererParams &
  Pick<StyleDetailsPopoverProps, 'onItemClicked'> & {
    dataQa?: string;
    icon: string;
  };

export default class IconWithPopoverTrigger extends React.Component<Params> {
  constructor(props: Params) {
    super(props);
  }

  handleClick = () => {
    const { data, onItemClicked } = this.props;

    if (onItemClicked) {
      onItemClicked(data);
    }
  };

  render() {
    const { value, icon, dataQa } = this.props;

    let iconClass = `fa fa-${icon}`;
    if (iconClass.startsWith('far') || iconClass.startsWith('fas') || iconClass.startsWith('fa')) {
      iconClass = icon;
    }

    return (
      <div data-qa={dataQa} className={IconCellRendererStyles.container} onClick={this.handleClick}>
        <i className={`icon-with-popover-trigger ${iconClass}`} />
        <span className={IconCellRendererStyles.text}>{value ? value : undefined}</span>
      </div>
    );
  }
}
