import {
  Button,
  FormControl,
  Select,
  Input,
  InputLabel,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { getViewDefn, updateViewDefn } from 'src/services/configService';
import { BOTTOM_UP_PERSPECTIVE, TOP_DOWN_PERSPECTIVE } from 'src/utils/Domain/Constants';
import {
  RefConfig,
  RefComponent,
  AvailablePerspective,
  TenantViewConfigurator,
  CONFIG_REF_DEFN_NAME,
} from './ConfigEditor.utils';
import { autocompleteStyle, perspectiveSelectStyle } from './ConfigEditor.styles';
import { isEqual, isNil } from 'lodash';

function importAll(r: any) {
  const images = {};
  r.keys().forEach((item: any) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}
const allImages = importAll((require as any).context('src/images/ConfigImages/', false, /\.(png|jpe?g|svg)$/));

export type ConfigEditorMainProps = {
  selectedView: TenantViewConfigurator;
  isDefault: boolean;
  onChangeDefault: () => void;
  onChangeView: (view: TenantViewConfigurator) => void;
};

interface ConfigEditorViewDefn {
  $schema: 'RefList';
  components: RefComponent[];
}

const ConfigEditorMain = (props: ConfigEditorMainProps) => {
  const { selectedView: passedView, isDefault, onChangeDefault, onChangeView } = props;
  const [selectedViewIndex, setSelectedViewIndex] = useState<number>(-1);
  const [config, setConfig] = useState<RefComponent[]>([]);
  const [perspectives, setPerspectives] = useState<AvailablePerspective[]>([BOTTOM_UP_PERSPECTIVE]);
  useEffect(() => {
    getViewDefn<ConfigEditorViewDefn>(CONFIG_REF_DEFN_NAME).then((defn) => {
      let foundSelectedViewIndex =
        passedView && defn?.components.findIndex((x) => passedView['$componentPropRef'] == x.id);
      // TODO: FALLBACK TO REMOVE; just for default conf without propRefs already
      if (foundSelectedViewIndex == -1) {
        foundSelectedViewIndex = defn?.components.findIndex((x) =>
          isEqual(passedView.componentProps, x.properties.componentProps)
        );
      }
      setSelectedViewIndex(foundSelectedViewIndex || -1);
      setPerspectives((passedView && passedView.inPerspectives) || [BOTTOM_UP_PERSPECTIVE]);
      setConfig(defn?.components || []);
    });
  }, [passedView]);

  const saveDefn = async () => {
    await updateViewDefn(CONFIG_REF_DEFN_NAME, { $schema: 'RefList', components: config } as RefConfig);
  };

  const getImgName = () => {
    if (config[selectedViewIndex] && config[selectedViewIndex].properties) {
      const properties = config[selectedViewIndex].properties;
      let name = properties.component;
      if (properties.componentProps) {
        // TODO: Fallback for components the share the same component. This isn't the best solution, as if they change the title
        switch (properties.componentProps.title) {
          case 'Receipt View':
            name = 'ReceiptView';
            break;
          case 'DC View':
            name = 'DCView';
            break;
          case 'Financial Reconciliation':
            name = 'FinancialRecon';
            break;
        }
      }
      return name;
    }
    return '';
  };
  const saveView = (index: number) => {
    const newPassedView = Object.assign({}, passedView);
    newPassedView['$componentPropRef'] = config[index].id;
    onChangeView(newPassedView);
  };
  const saveViewPerspectives = (perspectives: any[]) => {
    const newPassedView = Object.assign({}, passedView);
    newPassedView.inPerspectives = perspectives;
    onChangeView(newPassedView);
  };

  const imgName = config[selectedViewIndex] && allImages[`${getImgName()}.png`];
  return passedView ? (
    <div style={{ padding: 10, width: '100%' }}>
      <span style={{ fontSize: '1.5rem', fontWeight: 600 }}>{passedView.name}</span>
      <div className={perspectiveSelectStyle}>
        <FormControl>
          <InputLabel>Perspectives</InputLabel>
          <Select
            style={{ width: 200 }}
            multiple
            value={perspectives}
            onChange={(e: any) => {
              if (e.target.value.length > 0) {
                setPerspectives(e.target.value);
                saveViewPerspectives(e.target.value);
              } else {
                // Default to bottom up
                setPerspectives([BOTTOM_UP_PERSPECTIVE]);
                saveViewPerspectives([BOTTOM_UP_PERSPECTIVE]);
              }
            }}
            input={<Input />}
            renderValue={(selected: any) => selected.join(', ')}
          >
            {[BOTTOM_UP_PERSPECTIVE, TOP_DOWN_PERSPECTIVE].map((persp) => (
              <MenuItem key={persp} value={persp}>
                {persp}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormGroup style={{ marginTop: 10, marginLeft: 20 }}>
          <FormControlLabel
            control={<Checkbox checked={isDefault} onChange={() => onChangeDefault()} />}
            label="Is Default View"
          />
        </FormGroup>
      </div>
      <div className={autocompleteStyle}>
        <Autocomplete
          value={
            selectedViewIndex > -1 && config
              ? config[selectedViewIndex]
              : { title: '', id: '', properties: { component: '', componentProps: [], inPerspectives: [] } }
          }
          fullWidth
          disablePortal
          options={config || []}
          getOptionLabel={(option) => option.title}
          onChange={(_event, newValue) => {
            const newIndex = config.findIndex((x) => x == newValue);
            setSelectedViewIndex(newIndex);
            saveView(newIndex);
          }}
          renderInput={(params) => <TextField {...params} label="View Config" />}
        />
        <Button
          onClick={() => {
            saveDefn();
          }}
          style={{ width: 200, marginTop: 10 }}
        >
          Modify Defn
        </Button>
      </div>
      <img style={{ maxWidth: '100%', marginTop: 40 }} src={imgName ? imgName.default : ''} />
    </div>
  ) : (
    <div />
  );
};

export default ConfigEditorMain;
