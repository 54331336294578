import { style, media } from 'typestyle';
import { borderHighlightColor, TEAL_PRIMARY } from 'src/utils/Style/Theme';
import {
  TOPHEIGHT,
  COLWIDTH,
  BORDERCLASS,
  FONTCOLOR,
  BORDERWIDTH,
} from 'src/components/StandardCardView/StandardCard/StandardCard.styles';
import { px } from 'csx';

const popoverHeader = 'popover-header';

export default {
  card: style(
    media(
      { type: 'print' },
      {
        position: 'static',
      }
    ),
    {
      $debugName: 'trendcard',
      padding: 15,
      height: '100%',
      display: 'inline-flex',
      justifyContent: 'space-evenly',
      position: 'relative',
      fontSize: '.6em',
      flexDirection: 'column',
      alignItems: 'flex-start',
      border: '1px solid transparent',
      $nest: {
        '& i.cardSelectedState': {
          position: 'absolute',
          top: 30,
          left: 25,
          fontSize: '1rem',
          color: '#8e8e8e',
        },
        '& .inner-card': {
          borderTop: '4px solid red',
          //borderRight: '1px solid grey',
          //borderLeft: '1px solid grey',
          //borderBottom: '1px solid grey',
          border: '1px solid #f4f1f4',
          width: '100%',
          height: '100%',
        },
        '& .row-55': {
          height: '55%',
          marginLeft: 30,
          display: 'flex',
          fontSize: 13,
          paddingRight: 50,
        },
        '& .icon-55': {
          marginTop: 32,
          marginRight: 6,
        },
        '& .noticon-55': {
          marginTop: 26,
        },
        '& .bottom-55': {
          fontWeight: 600,
        },
        '& .big-percent': {
          fontSize: 22,
        },
        '& .row-10': {
          height: '10%',
          fontSize: 14,
          padding: 5,
        },
        '& .row-35': {
          height: '35%',
          marginLeft: 20,
          display: 'flex',
          width: 250,
          justifyContent: 'space-evenly',
        },
        '& .bignumber': {
          fontSize: 20,
          marginTop: 8,
        },
        '& .pills': {
          display: 'flex',
          minWidth: px(120),
          flexDirection: 'column',
        },
        '& .pill-group': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: 5,
        },
        '& .pillpercent': {
          height: 14,
          background: '#D04B32',
          width: 50,
          borderRadius: 8,
          textAlign: 'center',
          color: 'white',
          fontSize: 9,
          $nest: {
            '& .fas': {
              fontSize: 8,
            },
          },
        },
      },
      breakInside: 'avoid',
      pageBreakInside: 'avoid',
    }
  ),
  getHoverStyle: (isBlankCard: boolean) => {
    if (isBlankCard) {
      return style({});
    }
    return style({
      $nest: {
        '&:hover': {
          borderWidth: BORDERWIDTH,
          borderColor: borderHighlightColor,
          borderStyle: 'solid',
        },
      },
    });
  },
  cardWithPopper: style({
    cursor: 'pointer',
  }),

  infoRow: style({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignContent: 'space-around',
  }),

  stars: style({
    flex: 2,
  }),

  value: style({
    flex: 1,
    textAlign: 'right',
    fontWeight: 'bold',
  }),

  description: style({
    textAlign: 'left',
    width: '100%',
    fontWeight: 600,
    maxHeight: '4.2em',
    lineHeight: '1.4em',
    overflow: 'hidden',
  }),

  column: style({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: TOPHEIGHT,
    paddingLeft: 10,
    textAlign: 'center',
  }),

  colItem: style({
    height: 37,
    width: COLWIDTH,
    fontSize: '.5rem',
    position: 'relative',
    color: FONTCOLOR,
    overflow: 'hidden',
    $nest: {
      [`& > .${BORDERCLASS}`]: {
        position: 'absolute',
        left: 0,
        height: TOPHEIGHT,
        top: 0,
        width: '100%',
      },
      [`&:nth-child(6n + 1) > ${BORDERCLASS}`]: {
        borderRight: '1px dotted #ddd',
      },
      [`&:first-of-type > ${BORDERCLASS}`]: {
        borderLeft: '1px dotted #ddd',
      },
    },
  }),

  infoContainer: style({
    display: 'flex',
    width: '100%',
    height: '100%',
  }),
  popper: style(
    {
      maxWidth: 1000,
      borderRadius: 10,
      $nest: {
        [`.${popoverHeader}`]: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      },
      zIndex: 9999,
      visibility: 'visible',
    },
    media(
      { minWidth: 700 },
      {
        $nest: {
          '& > [role=document]': {
            width: 500,
          },
        },
      }
    )
  ),
  popperClosed: style({
    pointerEvents: 'none',
  }),
  popoverHeader,
  cardOverlay: style({
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'white',
    opacity: 0.4,
    zIndex: 1,
    pointerEvents: 'none',
  }),
  iconOverlay: style({
    position: 'absolute',
    fontSize: '3rem',
    color: TEAL_PRIMARY,
    top: 80,
    left: 55,
    zIndex: 2,
    background: 'white',
    borderRadius: 60,
  }),
  worklistCard: style({
    position: 'absolute',
    top: 0,
    left: 5,
  }),
  cardContainer: style(media({ type: 'print' }, { display: 'block' }), {
    $debugName: 'cardContainer',
    display: 'inline-block',
    width: '100%',
    height: `100%`,
    overflowY: 'hidden',
    overflowX: 'hidden',
  }),
};
