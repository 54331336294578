import * as React from 'react';
import { IHeaderParams } from 'ag-grid-community';
import { styles } from './ValidValuesCheckboxEditor.styles';
import { ViewApiConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { getValidValues } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import { isNil } from 'lodash';
import { ValidValuesCache, ValidValuesResults } from 'src/services/validValuesCache';
import service from 'src/ServiceContainer';

export type ValidValuesCheckBoxEditorHeaderProps = {
  availableHeaders: string[];
  optionsApi?: ViewApiConfig;
  validValuesCache: ValidValuesCache;
  onHeadersFetched?: (headers: string[]) => void;
};

type VVCBEState = {
  apiHeaders?: string[];
};

type HeaderProps = IHeaderParams & ValidValuesCheckBoxEditorHeaderProps;
export class ValidValuesCheckBoxEditorHeader extends React.Component<HeaderProps, VVCBEState> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchApiHeaders();
  }

  fetchApiHeaders = async () => {
    const { optionsApi, validValuesCache } = this.props;

    if (isNil(optionsApi)) {
      service.loggingService.warn(`No api configured for the ${this.props.displayName} column.`);
      return;
    }

    const cacheHash = ValidValuesCache.hash({ endpoint: optionsApi.url });
    let results: ValidValuesResults;
    if (validValuesCache.has(cacheHash)) {
      results = validValuesCache.get(cacheHash);
    } else if (validValuesCache.isInflight(cacheHash)) {
      results = await validValuesCache.getInflightPromise(cacheHash);
    } else {
      const resultProm = getValidValues(optionsApi.url, false, false, false);
      validValuesCache.setInflightRequest(cacheHash, resultProm);
      results = await resultProm;
      validValuesCache.set(cacheHash, results);
      validValuesCache.inflightRequestMap.delete(cacheHash);
    }

    const headers = results.map((r: { label: string }) => r.label);
    this.setState(
      {
        apiHeaders: headers,
      },
      () => {
        // parent needs to be aware of order of headers for this column
        if (!isNil(this.props.onHeadersFetched)) {
          this.props.onHeadersFetched(headers);
        }
      }
    );
  };

  render() {
    const renderedHeaders = this.state.apiHeaders ? this.state.apiHeaders : this.props.availableHeaders;
    const columns = renderedHeaders.map((header, _idx, arr) => {
      const width = `${(1 / arr.length) * 100}%`;
      return (
        <div key={header} className="ag-header-cell-text" style={{ width }}>
          {header}
        </div>
      );
    });
    return <div className={styles}>{columns}</div>;
  }
}
