/* eslint-disable @typescript-eslint/naming-convention */
import { connect, Dispatch } from 'react-redux';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { SizeEligibilityListGrid } from './SizeEligibilityListGrid';
import {
  requestSizeEligibilityListGridConfig,
  receiveSizeEligibilityListGridConfig,
  SizeEligibilityListGridConfigs,
  requestSizeEligibilityListGridGridData,
  receiveSizeEligibilityListGridGridData,
  receiveSizeEligibilitySizeRanges,
  receiveFloorsetData,
  receiveCompanionData,
  setSelectedSizeRange,
  setValidSizes,
  requestCompanionViewData,
  resetSlice,
  receiveError,
} from './SizeEligibilityListGrid.slice';
import container from 'src/ServiceContainer';
import { API_URL_PREFIX, ASSORTMENT } from 'src/utils/Domain/Constants';
import { selectAndProjectState } from './SizeEligibilityListGrid.selector';
import { ReduxSlice as SubheaderSlice, SubheaderViewDefns } from 'src/components/Subheader/Subheader.slice';
import { AppState, AppThunkDispatch } from 'src/store';

import {
  getValidValues,
  ValueLabelPair,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import ServiceContainer from 'src/ServiceContainer';
import { toast } from 'react-toastify';
import { BasicItem, BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ViewApiConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import axios from 'axios';
import { getUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { ColDef } from 'ag-grid-community';
import { SizeEligibilityListGridComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { DataApi, ListDataConfig } from 'src/services/configuration/codecs/confdefnView';
import { z } from 'zod';
import { flow } from 'lodash';
import { SizeEligibilityListGridDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';

export type OwnProps = z.infer<typeof SizeEligibilityListGridComponentProps>;

export type SizeEligibilityGridRow = {
  size_range_class: string;
  store_count: number;
  grade_cap: string;
  sizerangelvl: string;
  capacity: string;
  sizeattr: string[];
  size_eligibility: string[];
  size_min: number;
  size_min_weeks: number;
  size_min_strategy: string;
};
export type LoadingSizeEligibilityList = {
  topProduct: string;
  dataLoading: true;
  configLoading: true;
};
export type LoadedSizeEligibilityList = {
  topProduct: string;
  dataLoading: boolean;
  configLoading: boolean;
  companionLoading: boolean;
  gridData: BasicPivotItem[] | undefined;
  sizeRanges: ValueLabelPair[] | undefined;
  selectedSizeRange: ValueLabelPair | undefined;
  validSizes: string[] | undefined;
  viewDefns: SizeEligibilityListGridConfigs | undefined;
  floorsetData: BasicItem[] | undefined;
  companionData: BasicPivotItem[] | undefined;
  subheader: SubheaderSlice;
  subheaderDefns: SubheaderViewDefns | undefined;
  companionApi?: ListDataConfig;
  dataApi: ListDataConfig;
  downloadLink?: string;
};
export type ProjectedState = LoadedSizeEligibilityList & {
  locations: string[];
  sizeRanges: ValueLabelPair[];
  treeColumnDefinition: ColDef | undefined;
  sizeNames: string[];
};
const validsizeslookup = (id: string) =>
  `api/v2/dependents?appName=Assortment&source=ccsizerange&target=validsizes&id=${id}`;
type DependencyLookupValues = {
  value: string;
  label: string;
};
const getCompanionData = (companionApi: ViewApiConfig | ListDataConfig | undefined, dispatch: Dispatch<AppState>) => {
  if (!companionApi) return;
  dispatch(requestCompanionViewData());
  if (companionApi.isListData) {
    ServiceContainer.pivotService
      .listData(companionApi.defnId, 'Assortment', companionApi.params)
      .then((resp) => {
        const data = resp.flat;
        dispatch(receiveCompanionData(data));
      })
      .catch((e) => {
        console.error(e.stack);
        // toast.info('An error occured getting the companion view / or it was out of date and canceled');
        ServiceContainer.loggingService.error(
          'An error occured getting the companion view / or it was out of date and canceled',
          e.stack
        );
      });
  } else {
    const dataUrl = getUrl(companionApi);
    axios.get(dataUrl).then((_d) => {
      // TODO: Load some kind of data here
      dispatch(receiveCompanionData([]));
    });
  }
};
const getFloorsetData = async (floorsetApi: DataApi, dispatch: Dispatch<AppState>) => {
  if (!floorsetApi || !!floorsetApi.isListData) return; // required
  const { url: floorsetUrl, headers: floorsetHeaders, params: floorsetParams } = floorsetApi;
  const floorsetOptions = {
    headers: floorsetHeaders,
    params: floorsetParams,
  };
  return axios
    .get(API_URL_PREFIX + floorsetUrl, floorsetOptions)
    .then((resp) => {
      const data: BasicItem[] = resp.data.data;
      dispatch(receiveFloorsetData(data));
      return data;
    })
    .catch((e) => {
      toast.info('An error occured getting the floorsets');
      ServiceContainer.loggingService.error('An error occured getting the floorsets', e.stack);
    });
};
const getGridData = (
  dataApi: ListDataConfig,
  sizeRange: ValueLabelPair,
  floorset: string,
  dispatch: Dispatch<AppState>,
  groupBy?: string
) => {
  dispatch(requestSizeEligibilityListGridGridData());

  const aggByWithGroupBy =
    dataApi.params?.aggBy && groupBy ? `${groupBy},${dataApi.params.aggBy}` : dataApi.params?.aggBy;

  const gridDataPromise = container.pivotService.listData(dataApi.defnId, ASSORTMENT, {
    ...dataApi.params,
    aggBy: aggByWithGroupBy,
    topMembers: sizeRange.value + (floorset.length > 0 ? ',' : '') + floorset,
  });
  const validSizesProm = getValidValues(validsizeslookup(sizeRange.value), false, false, false);
  Promise.all([gridDataPromise, validSizesProm])
    .then((results) => {
      const [gridData, validSizes] = results;
      // TODO: fix this shameful assertion
      const projectedGridData: BasicPivotItem[] = gridData.tree.map((loc: BasicPivotItem) => {
        //const mappedLoc = (loc as unknown) as SizeEligibilityGridRowPayload;
        return {
          ...loc,
          sizeattr: [],
          size_eligibility: loc['attribute:selected_sizes:name'],
        };
      });
      dispatch(setValidSizes(validSizes.map((i: DependencyLookupValues) => i.value)));
      return dispatch(receiveSizeEligibilityListGridGridData(projectedGridData));
    })
    .catch((error) => {
      dispatch(
        receiveError({
          type: ComponentErrorType.data,
          message: (error as Error)?.message,
          name: ConfDefnComponentType.sizeEligibility,
          issues: error,
        })
      );
    });
};

const getSizeRangeThenGetGridData = (
  dataApi: ListDataConfig,
  topProduct: string,
  floorset: string,
  dispatch: Dispatch<AppState>,
  groupBy?: string
) => {
  const validValuesPromise = getValidValues(
    `/api/attribute/validValues/one?appName=Assortment&ids=ccsizerange&members=${topProduct}`,
    false,
    false,
    false
  );
  return validValuesPromise
    .then((sizeRanges) => {
      dispatch(receiveSizeEligibilitySizeRanges(sizeRanges));
      dispatch(setSelectedSizeRange(sizeRanges[0]));
      return getGridData(dataApi, sizeRanges[0], floorset, dispatch, groupBy);
    })
    .catch((e) => {
      toast.info('An error occured getting the valid size ranges');
      ServiceContainer.loggingService.error('An error occured getting the valid size ranges', e.stack);
    });
};

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps) {
  const { defns, viewProperty, companionApi, floorsetApi, dataApi } = ownProps;
  return {
    groupByDefn: defns.subheader.groupBy,
    viewProperty,
    onShowView(topProduct: string | undefined, groupBy?: string) {
      dispatch(requestSizeEligibilityListGridConfig());
      getCompanionData(companionApi, dispatch);
      container.tenantConfigClient
        .getTenantViewDefns<SizeEligibilityListGridConfigs>({
          defnIds: defns.view,
          appName: ASSORTMENT,
          validationSchemas: [SizeEligibilityListGridDefn],
        })
        .then(async (config) => {
          const maybeExcConfig = config[0];
          if (maybeExcConfig.columns && maybeExcConfig.columns !== undefined && maybeExcConfig.main) {
            // TODO: actually use this
            dispatch(receiveSizeEligibilityListGridConfig(maybeExcConfig));
          }
          if (topProduct) {
            if (floorsetApi) {
              const data = await getFloorsetData(floorsetApi, dispatch);
              return getSizeRangeThenGetGridData(dataApi, topProduct, data[0].id, dispatch, groupBy);
            }
            return getSizeRangeThenGetGridData(dataApi, topProduct, '', dispatch, groupBy);
          }
          return undefined;
        })
        .catch((error) => {
          dispatch(
            receiveError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.sizeEligibility,
              issues: error,
            })
          );
        });
    },
    updateData(newData: BasicPivotItem[]) {
      dispatch(receiveSizeEligibilityListGridGridData(newData));
    },
    onRefetchData(topProduct: string | undefined, floorset: string, groupBy: string | undefined) {
      getCompanionData(companionApi, dispatch);
      if (floorsetApi) {
        getFloorsetData(floorsetApi, dispatch);
      }
      if (topProduct) {
        getSizeRangeThenGetGridData(dataApi, topProduct, floorset, dispatch, groupBy);
      }
    },
    handleChangeSizeRange(newSizeRange: ValueLabelPair, floorset: string, groupBy: string | undefined) {
      dispatch(setSelectedSizeRange(newSizeRange));
      getGridData(dataApi, newSizeRange, floorset, dispatch, groupBy);
    },
    onUnmount() {
      dispatch(resetSlice());
    },
  };
}
export type SizeEligibilityListGridDispatchProps = ReturnType<typeof dispatchToProps>;

const sensitiveView = flow(() => SizeEligibilityListGrid, makeScopeAndFilterSensitive, makePrintSensitive)();

export default connect(selectAndProjectState, dispatchToProps)(sensitiveView);
