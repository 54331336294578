import { MainViewConfig, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { OptionGroup, Option } from 'src/components/Configure/ConfigureModal';
import { pick, compact, cloneDeep } from 'lodash';
import { Indexable } from 'src/types/Primitive';

export type ParseResult = {
  optionGroups: OptionGroup[];
  defaultSelections: Option[];
  originalDefaultSelections: Option[];
  instructions?: string;
  minimumSelections?: number;
};

export type Parseable = {
  main?: {
    info?: string;
    minimumSelections?: number;
  };
  defaults?: Indexable;
  view?: TenantConfigViewItem[];
  defaultsOverride?: Option[];
};

export function parseConfigureConfig(config: Parseable, defaults?: Indexable): ParseResult {
  const optionGroupConfigs = config.view || [];
  const defaultLookup = defaults || config.defaults || {};

  // Configure Grouping
  const optionGroups = optionGroupConfigs.map((group) => ({
    text: group.text,
    options: group.view!,
  }));

  let defaultSelections = compact(
    optionGroupConfigs.map((group) => {
      const defaultKey = defaultLookup[group.dataIndex];

      if (defaultKey) {
        for (const child of group.view!) {
          if (child.dataIndex === defaultKey) {
            return pick(child, 'dataIndex', 'text', 'renderer');
          }
        }
        throw new Error(`Default config item "${defaultKey}" not found in children`);
      }
      return undefined;
    })
  );
  const originalDefaultSelections = cloneDeep(defaultSelections);
  if (config.defaultsOverride) {
    defaultSelections = config.defaultsOverride;
  }

  return {
    instructions: config.main && config.main.info,
    minimumSelections: config.main && config.main.minimumSelections,
    optionGroups,
    defaultSelections,
    originalDefaultSelections,
  };
}
