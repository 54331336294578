import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded } from 'src/utils/Functions/epicsFunctions';
import { updateAggBys, updateAggBysNoOverwriteExisting, fetchNestedViewData } from './NestedView.slice';
import {
  ConfDefnComponentType,
  NestedViewComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { isNil } from 'lodash';
import {
  organizeListDataOptions,
  getConfigureModalAggBys,
  ExtraPivotOptions,
} from '../../StyleColorReview/StyleColorReview.slice';
import { isListDataApi } from 'src/services/configuration/codecs/confdefnView';
import { updateFlowStatus } from 'src/components/Subheader/Subheader.slice';
import { receiveScopeConfig, receiveScopeRefreshTrigger } from 'src/components/ScopeSelector/ScopeSelector.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { updateSelectedItem } from 'src/pages/Worklist/Worklist.slice';

export const nestedViewLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      // This view's initial navigation data load is not triggered by setActivePage
      // because the updateAggBys action is triggered in the onShowView container method
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      updateAggBys.type,
      updateAggBysNoOverwriteExisting.type,
      updateSelectedItem.type,
      updateFlowStatus.type,
      receiveScopeConfig.type
    ),
    map(() => maybeGetComponentProps<NestedViewComponent>(state$.value, ConfDefnComponentType.nestedView)),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ defns, topMembers }) => {
      const { dataApi, model: modelDefn } = defns;
      const finalModelDefn = isListDataApi(dataApi) ? dataApi.defnId : modelDefn;

      const flowStatus = state$.value.subheader.flowStatus.join(',');
      const aggBy = getConfigureModalAggBys(
        state$.value.pages.hindsighting.historyGrid.nestedView.aggBys,
        defns.dataApi
      );
      const options: ExtraPivotOptions = !isNil(topMembers) ? { topMembers, flowStatus, aggBy } : { flowStatus, aggBy };
      const finalOptions = organizeListDataOptions(options, dataApi);

      // Nested View doesn't need cleanUp action on change from worklist -> non worklist
      // as the cleanUp action fires on unmount, unlike some other views (Summary, etc.)

      return of(fetchNestedViewData(finalModelDefn, finalOptions));
    })
  );
};
