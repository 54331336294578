import { ColDef } from 'ag-grid-community';

type ColumnDef = ColDef & {
  children?: ColumnDef[];
};

function agHeaderTemplate(extraClasses: string) {
  return `<div class="ag-cell-label-container ${extraClasses}" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
      <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
      <span ref="eFilter" class="ag-header-icon ag-filter-icon" aria-hidden="true"></span>
      <span ref="eSortOrder" class="ag-header-icon ag-sort-order" aria-hidden="true"></span>
      <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" aria-hidden="true"></span>
      <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" aria-hidden="true"></span>
      <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" aria-hidden="true"></span>
    </div>
  </div>`;
}

export const nestedHeaderKey = 'nestedHeader';
export const nestedHeaderGroupKey = 'nestedHeaderGroup';

export function multiHeaderDecorate(colDefs: ColumnDef[]) {
  const decorated: ColumnDef[] = [];

  for (const colDef of colDefs) {
    const newColDef: ColumnDef = { ...colDef };
    const { children } = newColDef;

    if (children) {
      const newChildren: ColumnDef[] = [];

      for (const child of children) {
        const newChild = { ...child };
        const newParams = child.headerComponentParams || {};
        newParams.template = agHeaderTemplate('group-child');
        newChild.headerComponentParams = newParams;
        newChildren.push(newChild);
      }
      newColDef.children = newChildren;
    } else {
      const newParams = colDef.headerComponentParams || {};
      newParams.template = agHeaderTemplate('no-group');
      colDef.headerComponentParams = newParams;
    }
    decorated.push(newColDef);
  }
  return decorated;
}
