import { TargetSettingConfiguration, TargetSettingReduxSlice } from '../TargetSetting/TargetSetting.types';
import { TargetSettingNewSlice, createEmptySlice } from '../TargetCreation/TargetCreation.types';
import { RouteComponentProps } from 'react-router';
import { LYTargetData } from 'src/pages/AssortmentStrategy/TargetSetting/Criteria/Criteria.types';
import { ViewRefreshable } from 'src/components/higherOrder/ScopeSensitive';
import { TargetListComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';

export type TargetListReduxSlice = {
  rowData: LYTargetData[];
  viewConf: TargetSettingConfiguration | null;
  targetNew: TargetSettingNewSlice;
};

export function createEmptyTargetList(): TargetListReduxSlice {
  return {
    rowData: [],
    viewConf: null,
    targetNew: createEmptySlice(),
  };
}

export type ValueProps = {
  config: TargetSettingConfiguration | null;
  rowData: LYTargetData[];
  title?: string;
};
export type FunctionProps = {
  onShowView: () => void;
  onRowAction: (action: string, selectedRow: TargetSettingReduxSlice) => void;
} & ViewRefreshable;

export type OwnProps = z.infer<typeof TargetListComponentProps> & RouteComponentProps;

export type Props = ValueProps & FunctionProps & OwnProps;

export type State = {
  createNewOpen: boolean;
  popperRef: HTMLElement | null | undefined;
  popperOpen: boolean;
  confirmOpen: boolean;
};
