import * as React from 'react';
import { Checkbox, MuiThemeProvider, Tooltip } from '@material-ui/core';
import { ICellRendererParams } from 'ag-grid-community';
import { muiTheme } from 'src/utils/Style/Theme';
import { concat, includes, without, get } from 'lodash';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { disabledStyles, cellStyles, totalCheckboxStyle } from './ValidValuesCheckboxEditor.styles';
import { ViewApiConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { getValidValues } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import { ValidValuesCache, ValidValuesResults } from 'src/services/validValuesCache';
import { isNil } from 'lodash';

type Props = ICellRendererParams & {
  isEditable: boolean;
  availableSelections: string[];
  optionsApi?: ViewApiConfig;
  group?: boolean;
  validValuesCache: ValidValuesCache;
};

type State = {
  apiOptions?: string[];
};

export default class ValidValuesCheckBoxEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchApiHeaders();
  }

  fetchApiHeaders = async () => {
    const { optionsApi, validValuesCache } = this.props;
    if (!isNil(optionsApi)) {
      const cacheHash = ValidValuesCache.hash({ endpoint: optionsApi.url });
      let results: ValidValuesResults;
      if (validValuesCache.has(cacheHash)) {
        results = validValuesCache.get(cacheHash);
      } else if (validValuesCache.isInflight(cacheHash)) {
        results = await validValuesCache.getInflightPromise(cacheHash);
      } else {
        const resultProm = getValidValues(optionsApi.url, false, false, false);
        validValuesCache.setInflightRequest(cacheHash, resultProm);
        results = await resultProm;
        validValuesCache.set(cacheHash, results);
        validValuesCache.inflightRequestMap.delete(cacheHash);
      }
      this.setState({
        apiOptions: results.map((r: { value: string }) => r.value),
      });
    }
  };

  toggleSelection(sel: string) {
    const currentSelections = this.props.getValue();
    // don't allow fully empty selection set
    if (includes(currentSelections, sel)) {
      if (currentSelections.length > 1) {
        this.props.setValue(without(currentSelections, sel));
      }
    } else {
      this.props.setValue(concat(currentSelections, sel));
    }
  }

  selectionActive(sel: string) {
    return includes(this.props.getValue(), sel);
  }

  render() {
    const disabledClass = this.props.isEditable ? '' : disabledStyles;
    // check if it's grouping, render this
    if (isNil(this.props.value)) {
      return <span />;
    }
    let availableSelections = this.props.availableSelections;
    if (!isNil(this.props.optionsApi)) {
      availableSelections = !isNil(this.state.apiOptions) ? this.state.apiOptions : [];
    }
    let element: JSX.Element[];
    if (this.props.group) {
      element = availableSelections.map((sel) => {
        const groupVal = get(this.props.value, sel);
        return (
          <span key={'' + sel} className={totalCheckboxStyle}>
            {groupVal}
          </span>
        );
      });
    } else {
      element = availableSelections.map((sel, _idx, arr) => {
        const width = `${(1 / arr.length) * 100}%`;
        const disabled = this.selectionActive(sel) && this.props.getValue().length <= 1;
        const cursor = disabled || !this.props.isEditable ? 'not-allowed' : 'pointer';
        return (
          <span key={'' + sel} className={`vv-cell-checkbox ${disabledClass}`} style={{ width }}>
            <Tooltip
              title="Must have at least one selection."
              disableFocusListener={!disabled}
              disableHoverListener={!disabled}
            >
              <Checkbox
                checked={this.selectionActive(sel)}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleOutlineIcon />}
                onChange={() => this.toggleSelection(sel)}
                disabled={!this.props.isEditable}
                style={{ cursor }}
                disableRipple={!this.props.isEditable}
              />
            </Tooltip>
          </span>
        );
      });
    }
    return (
      <MuiThemeProvider theme={muiTheme}>
        <div className={cellStyles}>{element}</div>
      </MuiThemeProvider>
    );
  }
}
