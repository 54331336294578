import { deburr, kebabCase } from 'lodash';
import { TenantTab, TenantView } from 'src/services/configuration/bindings.types';
import { BOTTOM_UP_PERSPECTIVE, TOP_DOWN_PERSPECTIVE, CONFIGURATOR_PERSPECTIVE } from 'src/utils/Domain/Constants';

export const CONFIG_REF_DEFN_NAME = 'ViewRefList';
export const CONF_DEFN_NAME = 'AssortmentUiConf';

export type AvailablePerspective =
  | typeof BOTTOM_UP_PERSPECTIVE
  | typeof TOP_DOWN_PERSPECTIVE
  | typeof CONFIGURATOR_PERSPECTIVE;
export type TenantTabConfigurator = TenantTab & { isCustom?: boolean };
export type TenantViewConfigurator = TenantView & { ['$componentPropRef']?: string };
export type RefConfig = {
  $schema: 'RefList';
  components: RefComponent[];
};

export type RefComponent = {
  title: string;
  id: string;
  properties: RefProperties;
};

export type RefProperties = {
  component: string;
  componentProps: Record<any, any>;
  inPerspectives: AvailablePerspective[];
};

export const makePathSlot = (id: string) => {
  return deburr(kebabCase(id));
};
