import Axios from 'src/services/axios';
import { isNil } from 'lodash';
import { resolvePath } from 'src/cdn';
import { toastAndLog } from 'src/services/loggingService';

import { dataURItoBlob, ImageBlobMeta, watermarkBlob, watermarkImageAsBlob } from './WatermarkImage';

import noImagePath from 'src/common-ui/images/noimage.jpg';
import { getCroppedImgFromString } from 'src/components/ImageCropper/ImageCropper/Cropper.utils';
const noImage = resolvePath(noImagePath);

export const uploadImage = async (imgBlob: ImageBlobMeta): Promise<string> => {
  const formData = new FormData();

  formData.append('file', imgBlob.blob);
  try {
    return await Axios.post('api/asset/upload', formData).then((uploadResp: any) => {
      const fileName = uploadResp.data.data.fileName;
      try {
        return Axios.post('/api/asset/saveNoCrop', {
          id: fileName,
          type: imgBlob.mime,
        }).then((resp) => {
          toastAndLog(`Successfully saved image to server of fileName: ${fileName}`);
          return resp.data.data || '';
        });
      } catch (e) {
        toastAndLog(`An error saving the image to the server of fileName: ${fileName}`, e);
        return Promise.reject();
      }
    });
  } catch (e) {
    toastAndLog(`An error occurred uploading the image. No fileName available yet.`, e);
    return Promise.reject();
  }
};

export const watermarkAndUpload = async (imgSrc: string): Promise<string> => {
  const croppedImage = await getCroppedImgFromString(imgSrc);
  const watermarkedImage = await watermarkBlob(croppedImage).catch(() => null);

  if (!isNil(watermarkedImage)) {
    try {
      const newImage = dataURItoBlob(watermarkedImage);
      const imageUrl = await uploadImage(newImage);
      if (imageUrl) {
        return imageUrl;
      }
    } catch (error) {
      // send noImage in case image upload fails
      return noImage;
    }
  }

  return noImage;
};
