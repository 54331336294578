import { AppState } from 'src/store';
import { createSelector } from 'reselect';
import { get } from 'lodash';
import { projectState, StateSelection } from 'src/components/ListGridPair/ListGridPair.selectors';
import { STYLE_COLOR_NAME } from 'src/utils/Domain/Constants';
import { FabType } from 'src/components/higherOrder/withFab';
import { ListViewOwnProps } from './ListView.container';
import { getDataFromCache, isDataLoaded } from 'src/services/pivotServiceCache';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { processAdornments } from 'src/components/Adornments/Adornments';

function selectState(state: AppState, ownProps: ListViewOwnProps): StateSelection {
  const { title = 'List View', defns } = ownProps;
  const { listView: viewState } = state.pages.hindsighting.historyGrid;

  const { viewDataState, viewDefns, isConfigLoading } = viewState;
  const treeData = getDataFromCache(viewState)?.tree || [];
  const configLoaded = !viewState.isConfigLoading;
  const dataLoaded = isDataLoaded(viewDataState);
  const currentAdornments: AdornmentType[] = get(viewState.viewDefns.grid, 'adornments', []);
  const adornments = processAdornments(currentAdornments);

  return {
    identityField: ownProps.keys?.idProp || STYLE_COLOR_NAME,
    subheaderState: state.subheader,
    configLoaded,
    dataLoaded,
    treeData,
    title,
    subheaderViewDefns: defns.subheader,
    shouldFilterFlowStatus: false,
    activeTab: state.perspective.activeTab,
    fabType: FabType.none,
    viewDefns,
    isConfigLoading,
    viewDataState,
    adornments,
  };
}

export const selectData = createSelector(selectState, projectState);
