import { ScopeSelectorSlice } from 'src/components/ScopeSelector/ScopeSelector.slice';
import { SubheaderSlice } from 'src/components/Subheader/Subheader.slice';

export function inputIsNotNullOrUndefined<T>(input: null | undefined | T | Array<null | undefined | T>): input is T {
  if (Array.isArray(input)) {
    return input.every(inputIsNotNullOrUndefined);
  }
  return input !== null && input !== undefined;
}

export function isScopeDefined(scope: ScopeSelectorSlice) {
  return scope.scopeSelected;
}

export function isSubheaderLoaded(subheader: SubheaderSlice) {
  return !subheader.subheaderLoading;
}
