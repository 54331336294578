import React from 'react';
import { Button } from '@material-ui/core';

import * as TargetSettingStyles from './TargetSetting.styles';
import TemplateModalWithButton from 'src/components/TemplateModalWithButton/TemplateModalWithButton';
import { classes } from 'typestyle';
import { LYTargetData } from '../Criteria/Criteria.types';
import { GridApi, ColumnApi, ColDef, GridReadyEvent } from 'ag-grid-community';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { LEVEL_PROD_ROOT, ASSORTMENT } from 'src/utils/Domain/Constants';
import ServiceContainer from 'src/ServiceContainer';
import { Gridable } from 'src/common-ui/components/DataGrid/DataGrid';
import { FrameworkComponents } from 'src/utils/Component/AgGrid/AgConfigParse';
import { FlatPivot } from 'src/worker/pivotWorker.types';
import { Overlay } from 'src/common-ui';

export type TSReconcileModalProps = {
  rowData: LYTargetData[];
  colDefs: ColDef[] | undefined;
  floorset: string;
  topCriteria: string;
  frameworkComponents: FrameworkComponents;
};

export type TSReconcileModalState = {
  mergedData?: any[] | Gridable[];
  loading: boolean;
};

function mergeData(activeData: LYTargetData[], retrievedData: FlatPivot) {
  return activeData.map((data) => ({ ...retrievedData[0], ...data }));
}

export default class TargetSettingReconcileModal extends React.Component<TSReconcileModalProps, TSReconcileModalState> {
  gridApi!: GridApi;
  gridColumnApi!: ColumnApi;

  constructor(props: TSReconcileModalProps) {
    super(props);

    this.state = {
      mergedData: undefined,
      loading: false,
    };
  }

  onOpen = () => {
    this.setState(
      {
        loading: true,
      },
      async () => {
        const resp = await ServiceContainer.pivotService.listData('AssortmentStrategyRecon', ASSORTMENT, {
          aggBy: `${LEVEL_PROD_ROOT},level:${this.props.topCriteria}`,
          topMembers: this.props.floorset,
        });

        this.setState({
          mergedData: mergeData(this.props.rowData, resp.flat),
          loading: false,
        });
      }
    );
  };

  render() {
    let content = (
      <div
        style={{
          width: '80vw',
          minHeight: '300px',
          padding: '50px 10px 10px 10px',
        }}
      >
        <Overlay visible={true} type={'loading'} fitParent={true} />
      </div>
    );
    if (!this.state.loading && this.props.colDefs && this.state.mergedData) {
      content = (
        <div
          className={classes('ag-theme-material', 'data-grid', 'double-header', TargetSettingStyles.dataGrid)}
          style={{
            width: '80vw',
            minHeight: '300px',
            padding: '50px 10px 10px 10px',
          }}
        >
          <ExtendedDataGrid
            loaded={true}
            columnDefs={this.props.colDefs}
            data={this.state.mergedData}
            onGridReady={(params: GridReadyEvent) => {
              if (params.api && params.columnApi) {
                this.gridApi = params.api;
                this.gridColumnApi = params.columnApi;
                const allColumns = params.columnApi.getAllColumns();
                const allColumnIds: string[] = [];
                // Resize all columns except last column notes
                allColumns.forEach((column, i) => {
                  if (allColumns.length - 1 > i) allColumnIds.push(column.getId());
                });

                params.columnApi.autoSizeColumns(allColumnIds);
              }
            }}
            frameworkComponents={this.props.frameworkComponents}
            extraAgGridProps={{
              suppressRowClickSelection: true,
              suppressColumnVirtualisation: true,
              stopEditingWhenGridLosesFocus: true,
              groupIncludeTotalFooter: true,
              rowBuffer: 50,
              rowHeight: 30,
              headerHeight: 40,
              rowSelection: 'single',
              defaultColDef: {
                resizable: true,
                sortable: true,
                filter: false,
              },
            }}
          />
        </div>
      );
    }
    return (
      <TemplateModalWithButton
        buttonComponent={
          <Button color="secondary" className={TargetSettingStyles.actionButton}>
            <i className={classes('fas fa-balance-scale', TargetSettingStyles.actionButtonIcon)} />
            <span>Reconcile</span>
          </Button>
        }
        title="Reconcile"
        onOpen={this.onOpen}
      >
        {content}
      </TemplateModalWithButton>
    );
  }
}
