import { ListViewable } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ReduxSlice as SubheaderSlice, SubheaderViewDefns } from 'src/components/Subheader/Subheader.slice';
import { ScrollTo } from 'src/common-ui/components/DataGrid/DataGrid';
import {
  AssortmentMatrixComponentProps,
  WorklistComponentProps,
} from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { BackgroundDataLoadingProps } from 'src/components/BackgroundDataLoading/BackgroundDataLoading';
import { CollectionViewOwnProps } from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.container';
import { GridViewOwnProps } from 'src/pages/Hindsighting/StyleColorReview/GridView/GridView.container';
import { FlowTypeOwnProps } from 'src/pages/Hindsighting/StyleColorReview/FlowType/FlowType.container';
import { SummaryViewOwnProps } from 'src/pages/Hindsighting/StyleColorReview/SummaryView/SummaryView.container';
import { NestedViewOwnProps } from 'src/pages/Hindsighting/HistoryGrid/NestedView/NestedView.container';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { EnhancedOvertimeOwnProps } from 'src/components/EnhancedOvertime/EnhancedOvertime';
import { QuickTrendsOwnProps } from 'src/pages/Hindsighting/MacroTrends/QuickTrends/QuickTrends';
import { FabType } from 'src/components/higherOrder/withFab';
import { RouteComponentProps } from 'react-router-dom';
import { FlowStatus } from 'src/services/configuration/bindings.types';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { WorklistViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { GroupViewItem } from 'src/services/configuration/codecs/viewdefns/general';
import { ListDataConfig } from 'src/services/configuration/codecs/confdefnView';
import { StyleByLevelOvertimeOwnProps } from '../AssortmentBuild/OvertimeView/StyleByLevel/StyleByLevelOvertime';

/**
 * @deprecated
 *
 * Please use ConfDefnComponentType instead.
 *
 * This is no longer the preferred type values for configuring worklist components.
 * It is here strictly for backward compatibility.
 * */
export enum LegacyWorklistComponentType {
  visualize = 'visualize',
  pricing = 'pricing',
  flowsheet = 'flowsheet',
  styleEdit = 'styleEdit',
  collectionView = 'CollectionView',
  gridView = 'GridView',
  flowType = 'FlowType',
  summaryView = 'SummaryView',
  nestedView = 'NestedView',
  nestedStyleOverTime = 'NestedStyleOvertime',
  nestedOvertime = 'NestedOvertime',
}

type WorklistTabWithComponentProps =
  | WorklistTabCollectionViewTab
  | WorklistTabGridViewTab
  | WorklistTabFlowTypeViewTab
  | WorklistTabSummaryViewViewTab
  | WorklistTabNestedViewViewTab
  | WorklistTabEnhancedOvertimeViewTab
  | WorklistTabQuickTrendsViewTab
  | WorklistTabConfigurableGridTab
  | WorklistTabReceiptGridTab
  | WorklistTabStyleOvertimeViewTab
  | WorklistTabNestedOvertimeViewTab;

export type WorklistTab =
  | WorklistTabVisualizeViewTab
  | WorklistTabPricingViewTab
  | WorklistTabFlowSheetViewTab
  | WorklistTabStyleEditViewTab
  | WorklistTabWithComponentProps;

export function isWorklistTabWithComponentProps(
  tab: Pick<WorklistTab, 'componentType'>
): tab is WorklistTabWithComponentProps {
  return (
    tab.componentType === ConfDefnComponentType.collectionView ||
    tab.componentType === ConfDefnComponentType.gridView ||
    tab.componentType === ConfDefnComponentType.flowType ||
    tab.componentType === ConfDefnComponentType.summaryView ||
    tab.componentType === ConfDefnComponentType.nestedView ||
    tab.componentType === ConfDefnComponentType.enhancedOvertime ||
    tab.componentType === ConfDefnComponentType.quickTrends ||
    tab.componentType === ConfDefnComponentType.configurableGrid ||
    tab.componentType === ConfDefnComponentType.receiptGrid ||
    tab.componentType === ConfDefnComponentType.flowSheet ||
    tab.componentType === ConfDefnComponentType.nestedStyleOverTime ||
    tab.componentType === ConfDefnComponentType.nestedOvertime
  );
}

type WorklistTabSuper = {
  title: string;
  pathSlot: string;
};

type WorklistTabProplessSuper = WorklistTabSuper & {
  // TODO: match new defns structure from confDefn
  viewDefns: string[];
  massEditModel?: string;
  showFlowStatus?: boolean;
};

export type WorklistTabVisualizeViewTab = WorklistTabProplessSuper & {
  componentType: ConfDefnComponentType.visualize | LegacyWorklistComponentType.visualize;
  componentProps: unknown;
};

export type WorklistTabPricingViewTab = WorklistTabProplessSuper & {
  componentType: ConfDefnComponentType.pricing | LegacyWorklistComponentType.pricing;
  componentProps: TabbedComponentWrapperOwnProps;
};

export type WorklistTabFlowSheetViewTab = WorklistTabProplessSuper & {
  componentType: ConfDefnComponentType.flowSheet | LegacyWorklistComponentType.flowsheet;
  componentProps: TabbedComponentWrapperOwnProps;
};

export type WorklistTabStyleEditViewTab = WorklistTabProplessSuper & {
  componentType: ConfDefnComponentType.styleEdit | LegacyWorklistComponentType.styleEdit;
  componentProps: unknown;
};

export type WorklistTabCollectionViewTab = WorklistTabSuper & {
  componentType: ConfDefnComponentType.collectionView | LegacyWorklistComponentType.collectionView;
  componentProps: CollectionViewOwnProps;
};

export type WorklistTabGridViewTab = WorklistTabSuper & {
  componentType: ConfDefnComponentType.gridView | LegacyWorklistComponentType.gridView;
  componentProps: GridViewOwnProps;
};

export type WorklistTabFlowTypeViewTab = WorklistTabSuper & {
  componentType: ConfDefnComponentType.flowType | LegacyWorklistComponentType.flowType;
  componentProps: FlowTypeOwnProps;
};

export type WorklistTabStyleOvertimeViewTab = WorklistTabSuper & {
  componentType: ConfDefnComponentType.nestedStyleOverTime | LegacyWorklistComponentType.nestedStyleOverTime;
  componentProps: StyleByLevelOvertimeOwnProps;
};

export type WorklistTabNestedOvertimeViewTab = WorklistTabSuper & {
  componentType: ConfDefnComponentType.nestedOvertime | LegacyWorklistComponentType.nestedOvertime;
  componentProps: StyleByLevelOvertimeOwnProps;
};

export type WorklistTabSummaryViewViewTab = WorklistTabSuper & {
  componentType: ConfDefnComponentType.summaryView | LegacyWorklistComponentType.summaryView;
  componentProps: SummaryViewOwnProps;
};

export type WorklistTabQuickTrendsViewTab = WorklistTabSuper & {
  componentType: ConfDefnComponentType.quickTrends;
  componentProps: QuickTrendsOwnProps;
};

export type WorklistTabNestedViewViewTab = WorklistTabSuper & {
  componentType: ConfDefnComponentType.nestedView | LegacyWorklistComponentType.nestedView;
  componentProps: NestedViewOwnProps;
};

export type WorklistTabEnhancedOvertimeViewTab = WorklistTabSuper & {
  componentType: ConfDefnComponentType.enhancedOvertime;
  componentProps: EnhancedOvertimeOwnProps;
};

export type WorklistTabConfigurableGridTab = WorklistTabSuper & {
  componentType: ConfDefnComponentType.configurableGrid;
  componentProps: z.infer<typeof AssortmentMatrixComponentProps>;
};

export type WorklistTabReceiptGridTab = WorklistTabSuper & {
  componentType: ConfDefnComponentType.receiptGrid;
  componentProps: z.infer<typeof AssortmentMatrixComponentProps>;
};

export type WorklistOwnProps = z.infer<typeof WorklistComponentProps>;

export type WorklistValueProps = {
  title: string;
  isLoading: boolean;
  config: WorklistViewDefn | undefined;
  styles: BasicPivotItem[];
  treeStyles: BasicPivotItem[];
  subheaderSlice: SubheaderSlice;
  subheaderViewDefns: SubheaderViewDefns;
  showLevel?: boolean;
  fabType: FabType;
  selectedLevel?: GroupViewItem;
  flowStatus: number[];
  flowStatusConfig?: FlowStatus;
  showFlowStatus: boolean;
  companionSearch?: string;
  activeSubPage?: string;
  adornments: AdornmentType[];
  showUndoBtn?: boolean;
} & BackgroundDataLoadingProps;

export type WorklistDispatchProps = {
  onShowView: () => void;
  updateSearchString: (value: string) => void;
  updateCompanionSearchString: (value: string) => void;
  selectItem: (item: string) => void;
  selectLevel: (level: GroupViewItem) => void;
  updateStyleItem: (styleId: string, propToUpdate: string, propValue: string) => void;
  updateAssortmentPlan: () => void;
  setActiveSubPage(subPage: string): void;
  updateFlowStatus: (flow: number[]) => void;
};

export type WorklistProps = WorklistOwnProps & WorklistValueProps & WorklistDispatchProps & RouteComponentProps;
export type WorklistState = {
  selectedCompanionItem: ListViewable | undefined;
  useStyleColors: boolean;
  companionScrollTo?: ScrollTo;
  viewMounted: boolean;
  sortField?: string;
  sortDirection: 'asc' | 'desc';
};

/**
 * Defines the props for any wrapped component that is rendered in the worklist view.
 *
 * Currently used for tabbed flowsheet/pricing/nested overtime/style overtime
 */
export type TabbedComponentWrapperOwnProps = {
  selectedItemId: string;
  viewDefns: string[];
  massEditModel?: string;
  showFlowStatus?: boolean;
  dataApi?: ListDataConfig;
  styles?: BasicPivotItem[];
  showUndoBtn?: boolean;
};
