import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { LicenseManager } from 'ag-grid-enterprise';
import store, { AppState, persistor } from './store';
import serviceContainer, { ServiceContainer } from 'src/ServiceContainer';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'src/common-ui/styles/index.css';
import NavigationShell from 'src/pages/NavigationShell/NavigationShell.container';
import PerspectiveSelection from 'src/pages/PerspectiveSelection/PerspectiveSelection.container';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import {
  updateAppConfig,
  updateConfigFingerPrint,
  updateConfiguratorConfig,
} from 'src/services/configuration/AppConfig.slice';
import { ASSORTMENT_ROUTE_PATH } from './pages/NavigationShell/NavigationShell';
import { updateFlowStatus, updateFlowStatusConfig } from './components/Subheader/Subheader.slice';
import { enableAllPlugins } from 'immer';
import { tenantTabHasPathSlot } from './services/configuration/bindings.types';
import { fetchFingerprintFactory } from 'src/services/configuration';
import { PersistGate } from 'redux-persist/integration/react';
import { createEventSource } from 'src/services/serverEventSource';

import { startup, explicitLogout$, loginError$, explicitLogin, accessToken$ } from './services/auth/platform';

import { combineLatest, asyncScheduler, subscribeOn, map, combineLatestWith, filter, Observable } from 'rxjs';
import { Logout } from './components/Logout/Logout';
import { Overlay } from './common-ui';

require('./global.css');

enableAllPlugins();

LicenseManager.setLicenseKey(
  'S5_Stratos_MultiApp_2Devs11_July_2019__MTU2Mjc5OTYwMDAwMA==82681080271234c6108c212ac1a9f2c7'
);

const { printService, configService, configuratorConfigService } = serviceContainer;

const root = document.getElementById('root');

type LateRootState = {
  store: typeof store;
};

type RootState = {
  explicitLogout: boolean;
  error: boolean;
  lateInit?: LateRootState;
};

const definedAccessToken$: Observable<string> = accessToken$
  .asObservable()
  .pipe(filter((x) => x !== null))
  .pipe(map((x) => x as string));

class RootComponent extends React.Component<{}, RootState> {
  componentDidMount() {
    // Always start this immediatley
    fetchFingerprintFactory()
      .then((fingerprint) => {
        store.dispatch(updateConfigFingerPrint(fingerprint));
      })
      .catch(() => {
        console.info('Config has no fingerprint.');
      });

    const configError$ = configService.errored$.pipe(map((e) => !!e));

    const anError$ = loginError$.pipe(combineLatestWith(configError$)).pipe(map(([el, ec]) => el || ec));

    combineLatest([explicitLogout$, anError$])
      .pipe(subscribeOn(asyncScheduler))
      .subscribe(([explicitLogout, error]) => {
        this.setState({ explicitLogout, error });
      });

    combineLatest([configService.configuration$, configuratorConfigService.configuration$]).subscribe(
      async ([uiConfs, configuratorConfs]) => {
        const { tenant: uiConf } = uiConfs;
        const { tenant: configuratorConf } = configuratorConfs;
        createEventSource(store.dispatch, definedAccessToken$);
        const state = store.getState();
        const activeTab = state.perspective.activeTab;
        printService.hydrate(store);
        printService.setPrintFromUrl();
        window.onpopstate = printService.setPrintFromUrl;
        store.dispatch(updateAppConfig(uiConf));
        store.dispatch(updateConfiguratorConfig(configuratorConf));
        // Select all if no default selections set. Update starting flowstatus with default. All flows selected = []
        let flowStatusSelections = uiConf.flowStatus.defaultSelection || [];
        let flowStatusConfig = uiConf.flowStatus;
        // Get flow status override based on active tab
        if (uiConf && activeTab) {
          const tab = uiConf.tabs.find((tab) => tenantTabHasPathSlot(tab) && tab.pathSlot == activeTab);
          if (tab && tenantTabHasPathSlot(tab) && tab.flowStatusOverride && tab.flowStatusOverride.values) {
            flowStatusConfig = tab.flowStatusOverride;
            flowStatusSelections = tab.flowStatusOverride.defaultSelection;
          }
        }
        if (flowStatusSelections.length === uiConf.flowStatus.values.length) {
          flowStatusSelections = [];
        }
        store.dispatch(updateFlowStatus(flowStatusSelections));
        store.dispatch(updateFlowStatusConfig(flowStatusConfig));
        this.setState({ ...this.state, lateInit: { store } });
        await fetchFingerprintFactory()
          .then((fingerprint) => {
            store.dispatch(updateConfigFingerPrint(fingerprint));
          })
          .catch(() => {
            console.info('Config has no fingerprint.');
          });
        // TODO: manage this state better.
        window.__debug.bootState = store.getState();
      }
    );
  }

  render() {
    if (this.state?.error) {
      return <Overlay visible={true} fitParent={true} type={'error'} />;
    } else if (this.state?.explicitLogout) {
      return <Logout message={'You are logged out'} onLogin={() => explicitLogin()} />;
    } else if (this.state?.lateInit) {
      return (
        <div>
          <Provider store={this.state.lateInit.store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router>
                <Switch>
                  <Route exact={true} path="/" component={PerspectiveSelection} />
                  {/* TODO: When /:perspective exact, redirect to the default tab correctly */}
                  <Route path={ASSORTMENT_ROUTE_PATH} component={NavigationShell} />
                </Switch>
              </Router>
            </PersistGate>
          </Provider>
          <ToastContainer autoClose={5000} />
        </div>
      );
    } else {
      return <Overlay visible={true} fitParent={true} type={'loading'} />;
    }
  }
}

// Do startup
startup();
ReactDOM.render(<RootComponent />, root);

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __debug: {
      store: Store<AppState>;
      serviceContainer: ServiceContainer;
      bootState?: AppState;
    };
  }
}

window.__debug = {
  store,
  serviceContainer,
};
