import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { fetchQuickTrendsData } from './QuickTrends.slice';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded } from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  QuickTrendsComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import {
  ExtraPivotOptions,
  getAggBys,
  organizeListDataOptions,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { receiveScopeRefreshTrigger } from 'src/components/ScopeSelector/ScopeSelector.slice';
import { updateSelectedItem } from 'src/pages/Worklist/Worklist.slice';
import { setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { updateGroupBy, receiveViewDefns, updateFlowStatus } from 'src/components/Subheader/Subheader.slice';
import { isEmpty } from 'lodash';

export const quickTrendsLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type,
      updateFlowStatus.type,
      setActiveSubPage.type,
      updateSelectedItem.type,
      updateGroupBy.type,
      receiveViewDefns.type
    ),
    map(() => maybeGetComponentProps<QuickTrendsComponent>(state$.value, ConfDefnComponentType.quickTrends)),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ defns }) => {
      const { models: modelDefns } = defns;
      const [summaryModelDefn] = modelDefns;

      const topMembers = state$.value.worklist.selectedItem;
      const levelBy = state$.value.worklist.selectedLevel?.groupingKey;
      const aggBy = getAggBys(state$.value.subheader, undefined, { includeBottomLevel: false });
      const flowStatus = state$.value.subheader.flowStatus;

      // we can't fetch data without the required groupings
      if (levelBy == null || levelBy === '' || aggBy == null) {
        return of();
      }
      // summary data request options
      const summaryOptions: ExtraPivotOptions = {
        topMembers: topMembers,
        aggBy: isEmpty(aggBy) ? levelBy : [levelBy, aggBy].join(','),
        flowStatus: flowStatus.join(','),
      };
      const finalSummaryOptions = organizeListDataOptions(summaryOptions);

      return of(fetchQuickTrendsData(summaryModelDefn, finalSummaryOptions));
    })
  );
};
