import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from 'src/store';
import RightContainer, {
  RightContainerProps,
  RightContainerPayloadType,
} from 'src/components/RightContainer/RightContainer';
import { RightContainerSlice, ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import {
  setRightContainerPayload,
  cleanUpRightContainer,
  clearAndCloseRightContainer,
} from 'src/components/RightContainer/RightContainer.slice';
import { getFilterSelections } from '../FilterPanel/FilterPanel.container';
import { getSectionContextObj } from 'src/utils/Domain/Perspective';
import { isNil } from 'lodash';

export type StateProps = RightContainerSlice;

export type DispatchProps = {
  setRightContainerPayload(payload: ContainerPayload): void;
  clearAndCloseWindow(payload?: ContainerPayload): void;
  onDestroy(): void;
};

function mapStateToProps(state: AppState): RightContainerProps {
  const context = getSectionContextObj();
  const currentPerspective = state.perspective.selected?.id || '';
  if (isNil(context) || isNil(currentPerspective) || !context.editStyleBtn || !context.editStyleBtn.inPerspectives) {
    return {
      ...state.rightContainer,
      editStyleBtnConf: undefined,
    };
  }

  // only render edit style btn if configured for current perspective
  const editStyleBtnConf = context.editStyleBtn.inPerspectives.includes(currentPerspective)
    ? context.editStyleBtn
    : undefined;
  return {
    ...state.rightContainer,
    editStyleBtnConf,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch): DispatchProps {
  return {
    setRightContainerPayload(payload: ContainerPayload) {
      dispatch(setRightContainerPayload(payload));
    },
    clearAndCloseWindow(payload?: ContainerPayload) {
      dispatch(clearAndCloseRightContainer());
      if (payload && payload.type === RightContainerPayloadType.Filter) {
        dispatch(getFilterSelections());
      }
    },
    onDestroy() {
      dispatch(cleanUpRightContainer());
    },
  };
}

export default connect(mapStateToProps, dispatchToProps)(RightContainer);
