import { connect } from 'react-redux';
import ServiceContainer from 'src/ServiceContainer';
import ScopeSelector from './ScopeSelector';
import { Scope, SelectionErrors } from 'src/types/Scope';
import { AppState, AppThunkDispatch } from 'src/store';
import { getProductMapSelector, getLocationMapSelector } from './ScopeSelector.selectors';

import { asyncGetScopeConfig, asyncSetScope, asyncGetScope } from './ScopeSelector.actions';
import {
  ScopeSelectorSlice,
  changeScopeSelection,
  validateAllSelections,
  closeScope,
} from 'src/components/ScopeSelector/ScopeSelector.slice';
import { clearCache } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/ValidValuesCache';

export type StateProps = ScopeSelectorSlice;

export type DispatchProps = {
  onSubmitScope(oldScope: Scope): void;
  onRequestScope(): void;
  onRequestDimensionConfig(): void;
  onRequestFloorsetsConfig(): void;
  onChangeScopeSelection(selections: Scope): void;
  onValidateSelections(selectionErrors: SelectionErrors): void;
  onCloseScope(): void;
};

function mapStateToProps(state: AppState): StateProps {
  return {
    ...state.scope,
    productDimension: getProductMapSelector(state),
    locationDimension: getLocationMapSelector(state),
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch): DispatchProps {
  return {
    onRequestDimensionConfig() {
      dispatch(asyncGetScopeConfig());
    },
    onSubmitScope(oldScope: Scope) {
      clearCache(); // clear validValues cache in case dept is changed in scope
      dispatch(asyncSetScope(oldScope));
    },
    onRequestScope() {
      dispatch(asyncGetScope());
    },
    onCloseScope() {
      dispatch(closeScope());
    },
    onRequestFloorsetsConfig() {
      return;
    },
    onChangeScopeSelection(selections: Scope) {
      dispatch(changeScopeSelection(selections));
    },
    onValidateSelections() {
      dispatch(validateAllSelections());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScopeSelector(ServiceContainer.configService));
