import NavigationShell from './NavigationShell';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { AppState, AppThunkDispatch } from 'src/store';
import { BoundPerspective } from 'src/services/configuration/bindings.types';
import {
  setActivePerspective,
  setActivePage,
  setActiveTab,
  preSetActiveSubPage,
  setActiveSubPage,
  preSetActivePage,
  setViewLinks,
} from 'src/pages/NavigationShell/NavigationShell.slice';
import { connect } from 'react-redux';
import { getFilters } from 'src/components/FilterPanel/FilterPanel.container';
import {
  setSessionPerspective,
  setSessionPage,
  setSessionTab,
  setSessionSubPage,
} from 'src/components/RestoreSession/RestoreSession.utils';
import { updateFlowStatus, updateFlowStatusConfig } from 'src/components/Subheader/Subheader.slice';
import { TenantConfig, FlowStatus } from 'src/services/configuration/bindings.types';
import { Location } from 'history';
import { extractNavPath } from './navigationUtils';
import { RouteLink } from 'src/types/RouteLink';

export type StateProps = {
  validScope: boolean;
  activeTab: string;
  tenantConfig: TenantConfig;
  activePage: string;
  activeSubPage?: string;
  viewLinks: RouteLink[];
  perspective: BoundPerspective | null;
};
export type DispatchProps = {
  setActivePerspective(perspective: BoundPerspective): void;
  setActivePage(data: string): void;
  setActiveSubPage(data?: string): void;
  setActiveTab(tab: string, dontFetchFilters?: boolean): void;
  setViewLinks(links: RouteLink[]): void;
  updateFlowStatus(values: number[]): void;
  updateFlowStatusConfig(config: FlowStatus): void;
};

export type Props = StateProps & DispatchProps;

function mapStateToProps(state: AppState): StateProps {
  // Code to switch between using multiple UiConfs
  const maybeMatchHash = extractNavPath((window.location as unknown) as Location, 'hash'); // slice is to remove the leading #
  let tenantConfig = state.appConfig.tenantConfig;
  if (maybeMatchHash._tag == 'Some' && maybeMatchHash.value[0] == 'configurator') {
    tenantConfig = state.appConfig.configurationConfig;
  }
  return {
    validScope: state.scope.scope.valid,
    activeTab: state.perspective.activeTab,
    tenantConfig,
    activePage: state.perspective.activePage,
    activeSubPage: state.perspective.activeSubPage,
    viewLinks: state.perspective.viewLinks,
    perspective: state.perspective.selected,
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch): DispatchProps {
  return {
    setActivePerspective(perspective: BoundPerspective) {
      dispatch(setActivePerspective(perspective));
      setSessionPerspective(perspective.id);
    },
    setActivePage(data: string) {
      dispatch(preSetActivePage());
      dispatch(setActivePage(data));
      if (data !== '/') setSessionPage(data);
    },
    setActiveSubPage(data?: string) {
      dispatch(preSetActiveSubPage());
      dispatch(setActiveSubPage(data));
      setSessionSubPage(data!);
    },
    setActiveTab(tab: string, dontFetchFilters?: boolean) {
      dispatch(setActiveTab(tab));
      setSessionTab(tab);
      // We get filters on first load by getting scope
      // Dont fetch them twice, as it causes an onRefetchData to trigger
      if (!dontFetchFilters) {
        dispatch(getFilters());
      }
    },
    setViewLinks(links: RouteLink[]) {
      dispatch(setViewLinks(links));
    },
    updateFlowStatus(values: number[]) {
      dispatch(updateFlowStatus(values));
    },
    updateFlowStatusConfig(config: FlowStatus) {
      dispatch(updateFlowStatusConfig(config));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(makePrintSensitive(NavigationShell()));
