import React, { useCallback } from 'react';
import { isNil } from 'lodash';
import { dialogContent, dialogTitle, modal } from 'src/components/ConfirmationModal/ConfirmationModal.styles';
import { FavoriteListItem } from '../Subheader/Favorites/FavoritesMenu';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

export enum DeleteType {
  favorite,
  configuratorView,
  configuratorTab,
}

interface DeleteConfirmationItem {
  favorite?: FavoriteListItem;
  view?: string;
  tab?: string;
}

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement>) => void;
  onDelete: (favorite?: FavoriteListItem) => void;
  deleteType: DeleteType;
  item: DeleteConfirmationItem;
}

const getItemName = (type: DeleteType, item: DeleteConfirmationItem): string => {
  switch (type) {
    case DeleteType.favorite:
      return item.favorite?.name || '';
    case DeleteType.configuratorView:
      return item.view || '';
    case DeleteType.configuratorTab:
      return item.tab || '';
    default:
      return '';
  }
};

const getDeleteTypeString = (type: DeleteType) => {
  switch (type) {
    case DeleteType.favorite:
      return 'Favorite';
    case DeleteType.configuratorView:
      return 'View';
    case DeleteType.configuratorTab:
      return 'Tab';
    default:
      return '';
  }
};

export const DeleteConfirmationModal = (props: DeleteConfirmationModalProps) => {
  const { onDelete, onClose, deleteType, item } = props;
  const { favorite } = item;
  const deleteTypeString = getDeleteTypeString(deleteType);
  const handleDelete = useCallback(() => {
    if (!isNil(favorite) && deleteType === DeleteType.favorite) {
      onDelete(favorite);
    } else {
      onDelete();
    }
  }, [deleteType, favorite, onDelete]);

  return (
    <Dialog open={props.isOpen} onClose={() => onClose} maxWidth={'md'} classes={{ root: modal }}>
      <DialogTitle classes={{ root: dialogTitle }}>
        <div>
          <span className="left-container">
            <i className="fas fa-trash icon fa-sm" />
            Delete {deleteTypeString}
          </span>
          <span className="right-container">
            <i className="far fa-times" onClick={onClose} />
          </span>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: dialogContent }}>
        <p className="instructions">
          Delete {deleteTypeString}: {getItemName(deleteType, item)}
        </p>
        <footer>
          <button onClick={handleDelete} className="apply">
            <i className="far fa-check" />
            Delete
          </button>
          <button onClick={onClose} className="reset">
            <i className="fas fa-ban" />
            Cancel
          </button>
        </footer>
      </DialogContent>
    </Dialog>
  );
};
