import { style, classes } from 'typestyle';
import { mediumBorderColor, smallMediumFont, smallFont, borderHighlightColor } from 'src/utils/Style/Theme';
import { COMPANION_EXPANDED_WIDTH, COMPANION_COLLAPSED_WIDTH } from 'src/utils/Domain/Constants';
import { TEAL_PRIMARY } from 'src/common-ui/theme';
import { vvCheckboxStyles } from '../ValidValuesCheckboxEditor/ValidValuesCheckboxEditor.styles';

export const EXTRA_ROW_HEIGHT = 55;

export const editableCell = 'editable-cell';
const notHighlightedEditable = `.ag-theme-material .ag-row .${editableCell}`;
export const gridContainerStyle = style({
  $debugName: 'grid-container-style',
  overflow: 'auto',
  height: '100%',
  $nest: {
    [notHighlightedEditable]: {
      color: TEAL_PRIMARY,
    },
  },
});
export function makeGridContainerStyleSpecWidth(widthMinus: number) {
  return style({
    $debugName: 'gridContainer',
    height: '100%',
    display: 'flex',
    overflow: 'auto',
    width: `calc(100% - ${widthMinus}px)`,
    $nest: {
      '&>*': {
        flexGrow: 1,
      },
      [notHighlightedEditable]: {
        color: TEAL_PRIMARY,
      },
    },
  });
}

export function makeGridContainerStyle(companionCollapsed: boolean) {
  const widthMinus = companionCollapsed ? COMPANION_COLLAPSED_WIDTH : COMPANION_EXPANDED_WIDTH;
  return makeGridContainerStyleSpecWidth(widthMinus);
}
export const extraRowContainerStyles = style({
  $debugName: 'extra-row-container-styles',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minWidth: 0,
  $nest: {
    '& .size-heatmap-cell': {
      padding: 0,
      height: 'auto',
    },
  },
});
export const styles = {
  arrowGroup: style({
    width: 450,
    display: 'flex',
    height: '55px',
    marginLeft: 'auto',
    flexGrow: 1,
    maxWidth: 500,
    minWidth: 430,
  }),
  publishedContainer: style({
    whiteSpace: 'pre',
    fontSize: '1rem',
    display: 'inline-flex',
    flexWrap: 'wrap',
    $nest: {
      '& span': {
        marginRight: 12,
      },
    },
  }),
  fab: style({
    position: 'absolute',
    bottom: '24px',
    right: '24px',
    color: '#fff',
    backgroundColor: '#1fa67a',
    width: 56,
    height: 56,
    padding: 0,
    fontSize: '1rem',
    textAlign: 'center',
    fontWeight: 500,
    borderRadius: '50%',
    zIndex: 999,
    cursor: 'pointer',
  }),
};

export const listPairStyle = style({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  $nest: {
    '.header-row': {
      fontWeight: 700,
    },
    '.data-container': {
      display: 'flex',
      flex: 1,
      minHeight: 1,
      width: '100%',
      $nest: {
        '.content-container': {
          width: '100%',
        },
      },
    },
    '.action-button': {
      position: 'relative',
      right: 43,
      top: 1,
    },
    '.over-time-container': {
      position: 'relative',
      width: '100%',
    },
    '.data-grid': {
      fontSize: '0.8rem',
      height: '100%',
      width: 'inherit',
      $nest: {
        [`.ag-row.ag-row-focus:not(.ag-row-hover) .${vvCheckboxStyles} .s5-MuiIconButton-label`]: {
          color: 'white',
        },
        [`.ag-row.ag-row-focus:not(.ag-row-hover) .editable-cell`]: {
          color: 'white',
        },
        [`.ag-row.ag-row-focus:not(.ag-row-hover) .s5-MuiCheckbox-root`]: {
          color: 'white',
        },
        [`.ag-row.ag-row-focus:not(.ag-row-hover) svg.s5-MuiSvgIcon-root`]: {
          fill: 'white',
        },
        [`.loading svg.s5-MuiSvgIcon-root`]: {
          fill: 'gray',
        },
        [`.redecorating svg.s5-MuiSvgIcon-root`]: {
          fill: 'gray',
        },
        '.ag-row-group-indent-0': {
          display: 'flex',
        },
        '.ag-row-group .ag-cell': {
          alignItems: 'flex-end',
        },
        '.ag-row-group .ag-cell span.s5-MuiButtonBase-root': {
          paddingBottom: 0,
        },
        '.loading': {
          backgroundColor: 'transparent',
          opacity: '0.2',
          animation: 'loading 4s infinite',
          cursor: 'not-allowed',
          color: 'gray !important',
        },
        '.redecorating': {
          backgroundColor: 'transparent',
          opacity: '0.2',
          animation: 'redecorating 1.5s infinite',
          cursor: 'not-allowed',
          color: 'gray !important',
        },
      },
    },
    '.ag-header-icon .ag-icon': {
      top: -24,
      left: 3,
    },
    '.submission-container': {
      height: 50,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
});

const companionBorder = '1px solid ' + mediumBorderColor;

export const companionStyles = style({
  borderRight: companionBorder,
  width: COMPANION_EXPANDED_WIDTH,
  $nest: {
    '&.scroll-target': {
      borderColor: borderHighlightColor,
    },
    '.control-bar > label': {
      fontSize: smallMediumFont,
    },
    '.control-bar .dropdown': {
      fontSize: smallMediumFont,
      alignItems: 'center',
      display: 'flex',
    },
    '.control-bar .dropdown-item': {
      fontSize: smallFont,
    },
    '&.collapsed': {
      maxWidth: 115,
    },
    '> header': {
      borderBottom: companionBorder,
    },
    '.id': {
      fontSize: '0.7rem',
    },
    '.name': {
      height: 40,
      display: 'flex',
      overflow: 'auto',
      fontSize: '0.8rem',
    },
  },
});

export const gridListPairStyle = classes(
  listPairStyle,
  style({
    overflow: 'auto',
    height: '96%',
  })
);

export const gridNoDataOverlayStyles = style({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const headerCheckbox = style({
  $debugName: 'headerCheckbox',
  $nest: {
    '& .ag-header-select-all': {
      marginLeft: '10px',
    },
  },
});

export const gridActionButtons = style({
  $debugName: 'gridActionButtons',
  display: 'flex',
  alignItems: 'center',
  padding: '0 0.3rem',
});

export const subheaderActionButtonsSpacing = style({
  $debugName: 'subheaderActionButtonsSpacing',
  // accounts for undo btn, configure btn, configurator icon, fav icon
  right: '305px',
});

export const macrosContainer = style({
  display: 'flex',
  height: '40px',
  width: '100%',
  marginBottom: '0.5rem',
});
