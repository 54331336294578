import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { changeScopeSelection, receiveScopeRefreshTrigger } from 'src/components/ScopeSelector/ScopeSelector.slice';
import { resetAssortmentCart } from 'src/pages/AssortmentCart/AssortmentCart.slice';

export const resetCartEpic: AppEpic = (action$) => {
  return action$.pipe(
    ofType(changeScopeSelection.type, receiveScopeRefreshTrigger.type),
    mergeMap(() => {
      // SPIKE: this fires when the user selects, but does not submit, new weeks in scope selection
      // should we clear cart at that time?
      // whenever scope changes, dump the cart
      return of(resetAssortmentCart());
    })
  );
};
