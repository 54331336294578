import { style } from 'typestyle';
import { positiveTrend, negativeTrend, primaryTextColor, fadedNeutral } from '../../../../theme';

export const macroSummaryStyle = style({
  padding: '1rem 2rem',
  display: 'flex',
  textAlign: 'left',
  color: fadedNeutral,
  justifyContent: 'space-around',
  $nest: {
    header: {
      textAlign: 'left',
      fontSize: '0.8rem',
    },
    '.left-container': {
      width: '45%',
      marginRight: '5%',
    },
    '.right-container': {
      width: '50%',
    },
    '.primary-metric': {
      fontSize: '1rem',
      verticalAlign: 'top',
      color: primaryTextColor,
      fontWeight: 200,
      marginTop: '0.2rem',
      display: 'block',
      overflowWrap: 'break-word',
    },
    '.variances': {
      margin: 0,
      padding: 0,
      fontSize: '0.8rem',
      $nest: {
        '> li': {
          display: 'flex',
          alignContent: 'space-between',
          fontSize: '1rem',
        },
      },
    },
    '.metric-name': {
      width: '4rem',
      textAlign: 'right',
      margin: 0,
      fontSize: '0.8rem',
    },
    '.metric-value': {
      width: '4rem',
      textAlign: 'left',
      fontSize: '0.8rem',
    },
    '.dir': {
      margin: '0 0.5rem',
    },
    '.up': {
      color: positiveTrend,
    },
    '.down': {
      color: negativeTrend,
    },
    '.none': {
      color: 'transparent',
    },
  },
});
