import { CSSProperties } from 'typestyle/lib/types';

export type GridStyles = {
  top?: CSSProperties;
  topLeft?: CSSProperties;
  topRight?: CSSProperties;
  left?: CSSProperties;
  right?: CSSProperties;
  bottomLeft?: CSSProperties;
  bottomRight?: CSSProperties;
  bottom?: CSSProperties;
  nthRow?: [number, CSSProperties][];
  rangeStyles?: [number, number, CSSProperties][];
};

export type Options = {
  prefix?: string;
  fixDisheveledBottom?: {
    totalCount: number;
  };
};

export function nthChildRange(from: number, to: number) {
  return `:nth-child(n+${from}):nth-child(-n+${to})`;
}

export function makeGridStyles(width: number, height: number, gridStyles: GridStyles, options: Options = {}) {
  const styles = {};
  const prefix = options.prefix || '';

  const { top, topLeft, topRight, left, right, bottomLeft, bottomRight, bottom, nthRow, rangeStyles } = gridStyles;

  if (topLeft) {
    styles[prefix + ':nth-child(1)'] = topLeft;
  }

  if (top) {
    styles[`${prefix}:nth-child(-n+${width})`] = top;
  }

  if (topRight) {
    styles[`${prefix}:nth-child(${width})`] = topRight;
  }

  if (left) {
    styles[`${prefix}:nth-child(${width}n+1)`] = left;
  }

  if (right) {
    styles[`${prefix}:nth-child(${width}n)`] = right;
  }

  const bottomLeftIndex = width * height - width + 1;

  if (bottomLeft) {
    styles[`${prefix}:nth-child(${bottomLeftIndex})`] = bottomLeft;
  }

  if (bottomRight) {
    styles[`${prefix}:nth-child(${width * height})`] = bottomRight;
  }

  const penultimateRowStart = bottomLeftIndex - width;
  const penultimateRowEnd = width * height - width;
  const { fixDisheveledBottom } = options;
  const shortRowLength = fixDisheveledBottom && fixDisheveledBottom.totalCount % width;

  if (shortRowLength !== undefined && shortRowLength !== 0) {
    // Dishevelled Bottom
    styles[`${prefix}:nth-child(n+${penultimateRowStart + shortRowLength - 1})`] = bottom;

    // Bottom Left
    if (bottomLeft) {
      styles[`${prefix}:nth-child(n+${bottomLeft})`] = bottomLeft;
    }

    if (bottomRight) {
      // Penultimate Bottom Right
      styles[`${prefix}:nth-child(n+${penultimateRowEnd})`] = bottomRight;
    }

    // Short Bottom Right
    styles[`${prefix}:nth-child(n+${penultimateRowEnd + shortRowLength})`] = bottomRight || { ...right, ...bottom };
  } else if (bottom) {
    styles[`${prefix}:nth-child(n+${penultimateRowEnd + 1})`] = bottom;
  }

  if (rangeStyles && rangeStyles.length) {
    rangeStyles.forEach((rangeOption) => {
      const [from, to, style] = rangeOption;
      styles[`${prefix}${nthChildRange(from, to)}`] = style;
    });
  }

  if (nthRow) {
    nthRow.forEach((rowOption) => {
      const [rowIndex, style] = rowOption;
      const rowStart = rowIndex * width + 1;
      styles[`${prefix}${nthChildRange(rowStart, rowStart + width - 1)}`] = style;
    });
  }

  return styles;
}
