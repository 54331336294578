import { color } from 'csx';
import { createTheme } from '@material-ui/core/styles';

// Colors
export const TEAL_PRIMARY = '#00a89b';
export const RED_PRIMARY = '#f6413c';
export const ORANGE = color('#ff6a5a');
export const ORANGE_LIGHT = ORANGE.fade(0.5);
export const ORANGE_DARK = ORANGE.darken(0.2);
export const PINKISH = '#f38d9d';
export const GRAY = '#9d9d9d';

export const primaryTextColor = '#555';

export const faintTextColor = '#888';
export const fainterTextColor = 'rgb(210,210,210)';
export const veryFaintNeutral = '#f1f1f1';
export const superFaintNeutral = 'rgb(252,252,252)';

export const faintNeutral = '#eee';

export const faintBorderColor = faintNeutral;
export const mediumBorderColor = '#ddd';
export const separationBorderColor = '#cecece';
export const borderHighlightColor = 'rgba(69,182,159,.5)';

export const starGoldColor = '#ff8802';

// Spacial
export const mediumGutterWidth = 20;

// Cursor
export const CURSOR_POINTER = 'pointer';
export const CURSOR_NOT_ALLOWED = 'not-allowed';

// Fonts!
export const extraSmallFont = '0.625rem'; // 10px
export const smallFont = '0.75rem'; // 12px
export const smallMediumFont = '0.875rem'; // 14px
export const mediumLargeFont = '1.125rem'; // 18px
export const largeFont = '1.4rem'; // 22px
export const extraLargeFont = '1.8rem'; // 28px

// Breakpoints
export const extraSmallBreakpoint = 768; // Ipad Portrait
export const smallBreakpoint = 1024; // IpadPro Portrait
export const mediumBreakpoint = 1366; // IpadPro
export const largeBreakpoint = 1600;
export const extraLargeBreakpoint = 1865;

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: GRAY,
    },
    secondary: {
      main: TEAL_PRIMARY,
    },
  },
  overrides: {
    MuiStepIcon: {
      root: {
        '&$active': {
          color: TEAL_PRIMARY,
        },
        '&$completed': {
          color: TEAL_PRIMARY,
        },
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        letterSpacing: 'unset',
        boxSizing: 'content-box',
        height: 24,
        fontSize: smallFont,
        '&$selected': {
          backgroundColor: TEAL_PRIMARY,
          color: 'white',
        },
      },
    },
    MuiPaper: {
      root: {
        color: 'unset',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        flexDirection: 'column',
        padding: '8px 24px 24px',
      },
    },
    MuiButtonBase: {
      root: {
        display: 'flex',
      },
    },
    MuiIconButton: {
      edgeEnd: {
        // mui4 added a negative margin right, overriding here to match 3
        marginRight: 'unset',
      },
      root: {
        // mui4 added a negative margin right, overriding here to match 3
        marginRight: 'unset',
      },
    },
    MuiCheckbox: {},
    MuiExpansionPanelSummary: {
      root: {
        padding: '0 24px 0 24px',
        color: primaryTextColor,
      },
      expandIcon: {
        top: '50%',
      },
    },
    MuiFormLabel: {
      root: {
        position: 'initial',
        fontSize: '1em',
      },
    },
    MuiCardActions: {
      root: {
        // this is what the padding was in mui3, overridden here to match styling of 3
        padding: '8px 4px',
      },
    },
  },
});
