import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual, get, cloneDeep } from 'lodash';
import { GroupBySlice } from 'src/components/Subheader/Subheader.slice';
import { TenantConfigViewItem, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

export interface ViewData {
  gridData: BasicPivotItem[];
}

export type DropdownConfigViewData = TenantConfigViewData & {
  hideEmptyRow?: boolean;
};

export interface SubheaderDefns {
  groupBy: DropdownConfigViewData;
  sortBy?: DropdownConfigViewData;
  countLimit?: DropdownConfigViewData;
}

export interface ViewDefns {
  groupByDefn: TenantConfigViewData;
  gridDefn: TenantConfigViewData;
  floorsetDefn?: TenantConfigViewData;
}

export interface SubheaderDropdownSlice {
  defaultSelection: number;
  selection: number | null;
  options: TenantConfigViewItem[];
}

const emptyItem = {
  dataIndex: '',
  text: '',
  dimension: 'product',
};

export const initialGroupBy = {
  defaultSelection: 0,
  selection: 0,
  options: [emptyItem],
};

export interface QRCategorySummarySlice {
  floorset: string;
  areViewDefnsLoading: boolean;
  isViewDataLoading?: boolean;
  viewData?: ViewData;
  viewDefns?: ViewDefns;
  groupByUpdated: boolean;
  floorsetUpdated: boolean;
  groupBy: GroupBySlice;
}

const initialState: QRCategorySummarySlice = {
  areViewDefnsLoading: true,
  isViewDataLoading: false,
  groupByUpdated: false,
  floorsetUpdated: false,
  groupBy: initialGroupBy,
  floorset: '',
};

function _handleDropdownExtras(
  ddSlice: SubheaderDropdownSlice,
  viewDefn: DropdownConfigViewData,
  currentSelection: TenantConfigViewItem | null
) {
  ddSlice.options = viewDefn.view;
  if (viewDefn.hideEmptyRow !== true) {
    ddSlice.options.unshift(emptyItem);
  }
  if (viewDefn.default) {
    const defaultSelection = ddSlice.options.findIndex((opt) => opt.dataIndex === viewDefn.default);
    ddSlice.defaultSelection = defaultSelection;
  }
  const newSelection = ddSlice.options.findIndex((opt) => isEqual(opt, currentSelection));
  if (newSelection >= 0) {
    ddSlice.selection = newSelection;
  } else {
    ddSlice.selection = 0;
  }
  return ddSlice;
}

const qrCategorySummarySlice = createSlice({
  name: 'QRCategorySummary',
  initialState,
  reducers: {
    requestQRCategorySummaryConfigs(state) {
      state.areViewDefnsLoading = true;
    },
    receiveQRCategorySummaryConfigs(state, action: PayloadAction<ViewDefns>) {
      state.areViewDefnsLoading = false;
      state.viewDefns = action.payload;
    },
    receiveQRCategoryViewDefns(state, action: PayloadAction<SubheaderDefns>) {
      let groupBy: GroupBySlice = cloneDeep(initialGroupBy);
      const groupByDefn = action.payload.groupBy;
      if (groupByDefn) {
        const curSel = get(state.groupBy.options, `[${state.groupBy.selection}]`, null);
        groupBy = {
          ...state.groupBy,
          ..._handleDropdownExtras(groupBy, groupByDefn, curSel),
        };
      }
      state.groupBy = groupBy;
    },
    requestQRCategorySummaryData(state) {
      state.groupByUpdated = false;
      state.floorsetUpdated = false;
      state.isViewDataLoading = true;
    },
    receiveQRCategorySummaryData(state, action: PayloadAction<ViewData>) {
      state.isViewDataLoading = false;
      state.viewData = action.payload;
    },
    receiveQRCategorySummaryDataError(state) {
      state.groupByUpdated = false;
      state.floorsetUpdated = false;
    },
    cleanUpQRCategorySummary() {
      return initialState;
    },
    updateQRGroupBy(state, action: PayloadAction<number>) {
      (state.groupByUpdated = true), state.groupBy;
      state.groupBy.selection = action.payload;
    },
    updateQRFloorsetBy(state, action: PayloadAction<string>) {
      state.floorsetUpdated = true;
      state.floorset = action.payload;
    },
    receiveError() {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestQRCategorySummaryConfigs,
  receiveQRCategorySummaryConfigs,
  receiveQRCategoryViewDefns,
  requestQRCategorySummaryData,
  receiveQRCategorySummaryData,
  receiveQRCategorySummaryDataError,
  cleanUpQRCategorySummary,
  updateQRGroupBy,
  updateQRFloorsetBy,
  receiveError,
  cleanUp,
} = qrCategorySummarySlice.actions;

export default qrCategorySummarySlice.reducer;
