import { combineReducers } from 'redux';
import styleColorReview from './StyleColorReview/StyleColorReview.slice';
import aggregateViewsReducer from './AggregateViews/AggregateViews.reducer';
import historyGridReducer from './HistoryGrid/HistoryGrid.reducer';
import productMix from './CategoryRecap/ProductMix/ProductMix.slice';
import paretoAnalysisReducer from './Performance/ParetoAnalysis/ParetoAnalysis.slice';
import productivity from './CategoryRecap/Productivity/Productivity.slice';
import geoTrends from './MacroTrends/GeoTrends/GeoTrends.slice';
import categoryRecap from './CategoryRecap/CategoryRecap.reducer';
import storeGroupChart from './Performance/StoreGroupAnalysis/StoreGroupChart.slice';
import macroMix from './MacroTrends/MacroMix/MacroMix.slice';
import summary from 'src/pages/Hindsighting/MacroTrends/Summary/Summary.slice';
import { default as exceptionsSummaryReducer } from 'src/pages/Hindsighting/Exceptions/ExceptionsSummary/ExceptionsSummary.slice';
import topPerformersReducer from './MacroTrends/TopPerformers/TopPerformers.slice';
import quickTrends from 'src/pages/Hindsighting/MacroTrends/QuickTrends/QuickTrends.slice';
import timeChartReducer from 'src/components/TimeChart/TimeChart.slice';
import styleByLevelOvertime from 'src/pages/AssortmentBuild/OvertimeView/StyleByLevel/StyleByLevelOvertime.slice';
import nestedOvertime from 'src/pages/AssortmentBuild/OvertimeView/NestedOvertime/NestedOvertime.slice';

const hindsightingReducer = combineReducers({
  categoryRecap,
  styleColorReview,
  aggregateViews: aggregateViewsReducer,
  historyGrid: historyGridReducer,
  productMix,
  paretoAnalysis: paretoAnalysisReducer,
  productivity,
  storeGroupChart,
  macroMix,
  geoTrends,
  summary,
  topPerformers: topPerformersReducer,
  exceptionsSummary: exceptionsSummaryReducer,
  // TODO: find a "better" place for this state in AppState
  timeChart: timeChartReducer,
  quickTrends,
  styleByLevelOvertime,
  nestedOvertime,
});
export type HindsightingSlice = ReturnType<typeof hindsightingReducer>;
export default hindsightingReducer;
