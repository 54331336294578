import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantConfigViewItem, GeoTrendsConfigData } from 'src/dao/tenantConfigClient';
import { ExtendedPointObject } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/Charts/SimplerChart';
import { Pivot, BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

export interface TenantResponse {
  levelSelectConfig?: GeoTrendsConfigData;
  topChartConfig?: TenantConfigViewItem;
  bottomChartConfig?: TenantConfigViewItem;
}

interface GeoTrendsSlice {
  loaded: boolean;
  mapUri: string;
  geoData: BasicPivotItem[];
  geoDataLoaded: boolean;
  chartData: BasicPivotItem[];
  chartDataLoaded: boolean;
  tenantConfigLoaded: boolean;
  levelSelectConfig?: GeoTrendsConfigData;
  topChartConfig?: TenantConfigViewItem;
  bottomChartConfig?: TenantConfigViewItem;
  selectedItem: ExtendedPointObject | undefined;
  selectedGeoLevel?: TenantConfigViewItem;
}

interface Geodata {
  data: Pivot;
  selectedGeoLevel: TenantConfigViewItem;
}

const initialState: GeoTrendsSlice = {
  loaded: false,
  mapUri: '',
  levelSelectConfig: undefined,
  topChartConfig: undefined,
  bottomChartConfig: undefined,
  geoData: [],
  geoDataLoaded: false,
  chartData: [],
  chartDataLoaded: false,
  tenantConfigLoaded: false,
  selectedItem: undefined,
  selectedGeoLevel: undefined,
};

const geoTrendsSlice = createSlice({
  name: 'GeoTrends',
  initialState,
  reducers: {
    requestTenantConfig(state) {
      state.tenantConfigLoaded = false;
    },
    receiveTenantConfig(state, action: PayloadAction<TenantResponse>) {
      state.tenantConfigLoaded = true;
      state.levelSelectConfig = action.payload.levelSelectConfig;
      state.topChartConfig = action.payload.topChartConfig;
      state.bottomChartConfig = action.payload.bottomChartConfig;
    },
    requestGeoData(state) {
      state.loaded = false;
      state.geoDataLoaded = false;
    },
    receiveGeoData(state, action: PayloadAction<Geodata>) {
      state.loaded = true;
      state.geoDataLoaded = true;
      state.geoData = action.payload.data.tree;
      state.selectedGeoLevel = action.payload.selectedGeoLevel;
    },

    selectGeoLevel(state, action: PayloadAction<TenantConfigViewItem>) {
      state.selectedGeoLevel = action.payload;
    },
    geotrendsRequestChartData(state) {
      state.chartDataLoaded = false;
    },
    geotrendsReceiveChartData(state, action: PayloadAction<BasicPivotItem[]>) {
      state.chartData = action.payload;
      state.chartDataLoaded = true;
    },
    selectPoint(state, action: PayloadAction<ExtendedPointObject>) {
      // don't bother trying to get the types right here
      state.selectedItem = action.payload as any;
    },
    resetPoint(state) {
      state.selectedItem = undefined;
    },
    cleanUp() {
      return initialState;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    receiveGeoDataError(state) {
      state.geoDataLoaded = true;
    },
  },
});

export const {
  requestTenantConfig,
  receiveTenantConfig,
  requestGeoData,
  receiveGeoData,
  selectGeoLevel,
  geotrendsRequestChartData,
  geotrendsReceiveChartData,
  selectPoint,
  resetPoint,
  cleanUp,
  receiveError,
  receiveGeoDataError,
} = geoTrendsSlice.actions;

export default geoTrendsSlice.reducer;
