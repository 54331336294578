import Axios from 'src/services/axios';
import { AppType } from 'src/types/Domain';
import { Indexable } from 'src/types/Primitive';

export type ReportGroup = {
  id: string;
  name: string;
  items: string[];
};

export type Report = {
  id: string;
  name: string;
  description: string;
  reportParams: string;
  paramGroup: string;
  modelId: string;
  templateId: string;
  params?: Indexable;
};

export type Identifyable = {
  id: string;
  name: string;
};

export type Params = { [key: string]: Identifyable[] };

export type ParamGroup = {
  id: string;
  defaultParams: Indexable;
  params: Params;
};

export type ReportConfig = {
  reportGroups: ReportGroup[];
  reports: Report[];
  paramGroups: ParamGroup[];
};

export type ReportClient = {
  getReportConfig(appName: AppType): Promise<ReportConfig>;
};

export function makeReportClient(): ReportClient {
  return {
    getReportConfig(appName: AppType): Promise<ReportConfig> {
      console.log(appName);
      return Axios.get('/api/report?appName=' + appName).then((response) => response.data.data);
    },
  };
}
