import { style } from 'typestyle';

const container = style({
  padding: '0 1.5rem',
});

const titleText = style({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.5rem',
});

const itemsContainer = style({
  overflowY: 'auto',
});

const item = style({
  marginBottom: '2rem',
});

const itemTitle = style({
  display: 'flex',
  alignItems: 'center',
});

export default {
  container,
  titleText,
  itemsContainer,
  item,
  itemTitle,
};
