import { style } from 'typestyle';
import { important, color } from 'csx';
import { mediumBorderColor, TEAL_PRIMARY } from 'src/utils/Style/Theme';

const editableBackgroundColor = color('#edf7f6');

const editableColumn = style({
  $debugName: 'editable-column',
  backgroundColor: '#FFF',
  color: 'black',
});

const cell = style({
  // is overridden by another style rn
  $debugName: 'aggrid-cell',
  borderColor: mediumBorderColor,
});

const summaryRow = style({
  fontWeight: 'bold',
});

const dataGrid = style({
  // at the row level so that editable cells have different color on row hover
  width: '100%',
  fontSize: '0.8rem',
  flex: 1,
  // minHeight: 525,
  $nest: {
    '.ag-row-footer': {
      fontWeight: 'bold',
      // borderTop: '1px solid black'
    },
    [`.ag-row-odd:not(.ag-row-footer) .${editableColumn}.ag-cell-value`]: {
      backgroundColor: editableBackgroundColor.darken(0.02).toHexString(),
    },
    [`.ag-row-even:not(.ag-row-level-0) .${editableColumn}.ag-cell-value`]: {
      backgroundColor: editableBackgroundColor.darken(0.02).toHexString(),
    },
    [`.ag-row-footer .${editableColumn}.ag-cell-value`]: {
      backgroundColor: 'white',
    },
    [`.ag-row-footer.ag-row-odd .${editableColumn}.ag-cell-value`]: {
      backgroundColor: '#f9f9f9',
    },
    '.ag-cell-inline-editing': {
      height: '100%',
    },
    '.ag-row-group': {
      fontWeight: 'bold',
    },
  },
});

const tableRoot = style({
  $nest: {
    '.ag-row-footer': {
      fontWeight: 'bold',
    },
  },
});

const container = style({
  height: '100%',
  flexDirection: 'column',
  display: 'flex',
});

const addButton = style({
  color: 'white',
});

const actionButtonGroup = style({
  $debugName: 'actionButtonGroup',
  position: 'absolute',
  display: 'flex',
  right: 0,
  top: 10,
});

const actionButton = style({
  $debugName: 'actionButton',
  textTransform: important('none'),
});

const actionButtonIcon = style({
  $debugName: 'actionButtonIcon',
  color: 'darkgrey',
  paddingRight: 5,
});

const majorActionButton = style({
  $debugName: 'majorActionButton',
  textTransform: important('none'),
  backgroundColor: TEAL_PRIMARY,
  color: 'white',
});

const majorActionButtonIcon = style({
  $debugName: 'majorActionButtonIcon',
  color: 'white',
  paddingRight: 5,
});

export {
  dataGrid,
  editableColumn,
  summaryRow,
  cell,
  tableRoot,
  container,
  addButton,
  actionButtonGroup,
  actionButton,
  actionButtonIcon,
  majorActionButton,
  majorActionButtonIcon,
};
