import { style, media } from 'typestyle';
import { mediumBreakpoint, extraSmallBreakpoint } from 'src/utils/Style/Theme';
import { important, rem, px } from 'csx';
import { isNil } from 'lodash';

const HORIZONTAL_TABLET = mediumBreakpoint;
const searchBoxHeight = 30;
const imageSize = window.innerWidth > extraSmallBreakpoint ? 25 : 20;
const topNav = style({
  display: 'flex',
  alignItems: 'center',
  padding: '0 1rem',
  $nest: {
    '.navbar-brand': {
      color: 'white',
      fontSize: px(12),
    },
    '.dropdown': {
      color: 'white',
    },
  },
});
const mainNavBar = style(
  media(
    {
      minWidth: 0,
      maxWidth: HORIZONTAL_TABLET - 1,
    },
    {
      $nest: {
        '.nav-item': {
          padding: '0 .5rem',
        },
      },
    }
  ),
  {
    $debugName: 'mainNavBar',
    display: 'flex',
    height: 40,
    $nest: {
      '.logo-container': {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1rem',
        flexGrow: 1,
        zIndex: 102,
      },
      '.logo': {
        height: imageSize,
      },
      '.nav-links': {
        display: 'flex',
        flexGrow: 3,
        zIndex: 102,
      },
      '.nav-link': {
        display: 'flex',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        height: '100%',
        padding: 0,
      },
      '.nav-link:not(.disabled)': {
        color: 'white',
        marginRight: rem(0.5),
      },
      '.nav-item': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        flex: 1,
        padding: '0 1rem',
        maxWidth: 200,
        $nest:{
          '> a':{
            $nest:{
              '&:focus-visible':{
                outline: '2px solid #00a89b'
              }
            }
          }

        }
      },
      '.nav-item-action-button': {
        color: 'white',
        borderStyle: 'none',
        borderRadius: 0,
        backgroundColor: 'transparent',
        alignSelf: 'center',
      },
      '.nav-controls': {
        flexGrow: 2,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0 1rem',
        zIndex: 102,
        $nest: {
          i: {
            color: 'white',
          },
          '.navbar-brand': {
            fontSize: '0.75rem',
            verticalAlign: 'sub',
            $nest: {
              '&:focus-visible': {
                outline: '2px solid #00a89b'
              }
            }
          },
          '> a': {
            color: 'white',
          },
        },
      },
      '.input-group': {
        justifyContent: 'center',
      },
      '.search-container': {
        flex: '1 1',
        maxWidth: 380,
      },
      '.form-control': {
        fontSize: '.75rem',
        height: searchBoxHeight,
        border: 0,
        padding: '0 0.75rem 0 0.5rem',
        lineHeight: 1,
        backgroundColor: '#6b6b6c',
        color: 'white',
      },
      '.form-control::placeholder': {
        color: '#929292',
      },
      '.form-control:focus': {
        boxShadow: 'none',
      },
      '.buttons': {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      '.user': {
        cursor: 'pointer',
      },
      '.logout': {
        cursor: 'pointer',
      },
    },
  }
);
const logoutDropdown = style({
  $debugName: 'logout',
  pointerEvents: 'auto',
  fontSize: important('1rem'),
  padding: important('0.8rem 1.5rem'),
});

const TopNavSearch = style({
  $debugName: 'TopNavSearch',
  paddingRight: '20px',
  maxWidth: '22rem',
  flex: 1,
  $nest: {
    '.rbt-input-hint-container': {
      display: 'flex',
      alignItems: 'center',
    },
    '.rbt-input-hint': {
      top: 'unset !important',
      left: 'unset !important',
      color: 'rgba(255, 255, 255, 0.25) !important',
    },
  },
});

const TopNavSearchIconContainer = style({
  $debugName: 'TopNavSearchIconContainer',
  margin: '0',
  padding: '7px 0 0 5px',
  backgroundColor: '#6b6b6c',
  height: searchBoxHeight,
  $nest: {
    '> i': {
      fontSize: '0.9rem',
      color: 'white',
    },
  },
});

export const navItemText = style(
  {
    $debugName: 'navItemText',
  },
  media({ minWidth: 0, maxWidth: HORIZONTAL_TABLET - 1 }, { fontSize: '0.6rem' }),
  media({ minWidth: HORIZONTAL_TABLET, maxWidth: 1653 }, { fontSize: '0.8rem' }),
  media({ minWidth: 1654 }, { fontSize: '0.9rem' })
);

export const searchMediaStyles = style(
  {
    display: 'flex',
  },
  media(
    {
      minWidth: 0,
      maxWidth: HORIZONTAL_TABLET - 1,
    },
    {
      marginLeft: 30,
      display: 'flex',
    }
  )
);

const getFastNavPopperStyles = (anchor: HTMLElement | null, searchContainer: HTMLElement | null) => {
  const minWidth = !isNil(anchor) ? anchor.clientWidth : undefined;
  // width should not extend past the search container
  const maxWidth =
    !isNil(anchor) && !isNil(searchContainer) ? anchor.clientWidth + searchContainer.clientWidth : undefined;
  return style({
    maxWidth,
    minWidth,
    minHeight: important(px(375)),
    padding: '1rem 1rem 0 1rem',
    overflow: important('auto'),
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
  });
};

export default {
  mainNavBar,
  TopNavSearch,
  TopNavSearchIconContainer,
  navItemText,
  topNav,
  logoutDropdown,
  getFastNavPopperStyles,
};
