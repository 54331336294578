import React from 'react';
import { isNil, isUndefined, memoize } from 'lodash';
import fp from 'lodash/fp';
import { AutoSizer, Grid, List } from 'react-virtualized';
import { toast } from 'react-toastify';
import { classes } from 'typestyle';

import { Overlay } from 'src/common-ui';
import Renderer from 'src/utils/Domain/Renderer';
import Subheader from 'src/components/Subheader/Subheader.container';
import { formatSummaries } from 'src/utils/Pivot/RollUp';
import { CardViewItem, CardViewGroup } from './UIData.types';
import { StandardCard, StandardCardProps } from './StandardCard/StandardCard';
import { TOPHEIGHT, BOTTOMHEIGHT } from './StandardCard/StandardCard.styles';
import { TopCard } from './TopCard/TopCard';
import { styles, STD_GROUPHEADERHEIGHT } from './StandardCardView.styles';
import { Props } from './StandardCardView.types';
import { CustomNoRowsOverlay } from '../ConfigurableGrid/EditableGrid/EditableGrid.subcomponents';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { FabType } from 'src/components/higherOrder/withFab';
import { WorklistService } from 'src/services/Worklist/Worklist.service';
import ServiceContainer from 'src/ServiceContainer';

import { Checkbox } from '@material-ui/core';
import { noop } from 'react-select/lib/utils';
import { NO_GROUPS_TYPE } from './CardViewDataProcessor';
import CardsGrid, { RenderedGridCard } from 'src/common-ui/components/CardsGrid/CardsGrid';
import { errorToLoggingPayload } from 'src/services/loggingService';
import { generateCardProps, GenerateCardProps } from './StandardCardView.utils';

export const emptyGroupedStyles: CardViewGroup = {
  header: NO_GROUPS_TYPE,
  subheader: [{ name: '', value: '' }],
  items: [] as CardViewItem[],
};

type Direction = -1 | 1;
const LEFT: Direction = -1;
const RIGHT: Direction = 1;
const SCROLLSPEED = 400;

type State = {
  groupScroll: number[];
  groupCells: JSX.Element | undefined;
  scrollTimerId?: number;
};

class StandardCardView extends React.Component<Props, State> {
  grids: Grid[] = [];
  state: State;
  inCleanup!: boolean;

  constructor(props: Props) {
    super(props);
    this.state = {
      groupScroll: [],
      groupCells: undefined,
    };
    this.getGridItems = memoize(this.getGridItems);
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentWillUnmount() {
    if (this.props.onDestroy) {
      this.props.onDestroy();
    }
  }

  mouseEnter = (scrollWidth: number, direction: Direction, ind: number, max: number) => {
    let timeoutId = this.state.scrollTimerId;
    if (timeoutId != null) {
      window.clearInterval(timeoutId);
    }
    timeoutId = window.setInterval(() => {
      const grid = this.grids[ind];
      if (grid == null) {
        return;
      }
      const { scrollLeft = 0 } = grid.state;
      const newScroll = Math.min(Math.max(scrollLeft + direction * scrollWidth, 0), max);
      grid.scrollToPosition({
        scrollLeft: newScroll,
        scrollTop: 0,
      });
    }, SCROLLSPEED);
    this.setState({
      scrollTimerId: timeoutId,
    });
  };

  mouseLeave = () => {
    const timeoutId = this.state.scrollTimerId;
    if (timeoutId != null) {
      window.clearInterval(timeoutId);
    }
    this.setState({
      scrollTimerId: undefined,
    });
  };

  onFabClick = () => {
    switch (this.props.fabType) {
      case FabType.cart:
        const { cartCount, addSelectedItemsToCart } = this.props;
        if (!isNil(addSelectedItemsToCart) && !isNil(cartCount) && !isNil(this.props.assortmentCartLink)) {
          addSelectedItemsToCart(cartCount);
          this.props.setActivePage && this.props.setActivePage(this.props.assortmentCartLink);
          window.location.hash = this.props.assortmentCartLink;
        }
        break;
      case FabType.worklist:
        this.addItemsToWorklist();
        break;
      default:
        break;
    }
  };

  addItemsToWorklist = async () => {
    const worklistService = WorklistService();
    const { filteredStyles, identityPropsConfig } = this.props;
    const itemIds = filteredStyles.map((item) => item[identityPropsConfig.id]);

    try {
      await worklistService.addItemsToWorklist(itemIds);
      toast.info('Item Successfully added to worklist');
    } catch (error) {
      const msg = 'An error occured adding an item to the worklist';
      toast.error(msg);
      ServiceContainer.loggingService.error(msg, errorToLoggingPayload(error));
    }
  };

  handleCheckGroup(group: CardViewGroup) {
    if (group.allItemsInCart) {
      group.items.forEach((item) => {
        this.props.onItemClicked(item);
      });
    } else {
      group.items.forEach((item) => {
        !item.isSelected ? this.props.onItemClicked(item) : noop();
      });
    }
  }

  groupRender = ({
    groups,
    cardWidth,
    height,
    isPrintMode,
  }: {
    groups: CardViewGroup[];
    cardWidth: number;
    height: number;
    isPrintMode: boolean;
  }) => {
    const { summary, cardType } = this.props;
    const heightWithScroll = height + BOTTOMHEIGHT + 18;
    const heightWithHeader = heightWithScroll + STD_GROUPHEADERHEIGHT;

    const isAddCart = this.props.fabType === FabType.cart;

    const rowRenderer = (group: CardViewGroup, ind: number, topStyle?: React.CSSProperties) => {
      const addGroupItemsToCartCheckBox = isAddCart ? (
        <Checkbox
          className={'add-all-checkbox'}
          checked={group.allItemsInCart}
          onChange={() => {
            this.handleCheckGroup(group);
          }}
        />
      ) : null;

      let groupContent: JSX.Element;
      if (isPrintMode) {
        const cardSet = this.renderCards(group.items);
        groupContent = <div style={{ display: 'block' }}>{cardSet}</div>;
      } else {
        const gridItems = group.items.map(this.generateCardProps);
        groupContent = (
          <div style={{ height: heightWithScroll, position: 'relative' }} data-qa="StandardCardViewGroupedGrid">
            <AutoSizer>
              {({ height: wheight, width }) => (
                <React.Fragment>
                  <button
                    className={styles.hoverButton}
                    style={{ right: 0 }}
                    onMouseEnter={() =>
                      this.mouseEnter(cardWidth, RIGHT, ind, cardWidth * (group.items.length - width / cardWidth))
                    }
                    onMouseLeave={() => this.mouseLeave()}
                  >
                    <i className="far fa-angle-right" />
                  </button>
                  <button
                    className={styles.hoverButton}
                    style={{ left: 0 }}
                    onMouseEnter={() => this.mouseEnter(cardWidth, LEFT, ind, cardWidth * (group.items.length - 1))}
                    onMouseLeave={this.mouseLeave}
                  >
                    <i className="far fa-angle-left" />
                  </button>
                  <Grid
                    ref={(grid) => (grid ? (this.grids[ind] = grid) : undefined)}
                    cellRenderer={({ columnIndex, key, style, isScrolling, isVisible }) => (
                      <RenderedGridCard
                        key={key}
                        style={style}
                        isScrolling={isScrolling}
                        isVisible={isVisible}
                        cardType={cardType}
                        cardProps={gridItems[columnIndex]}
                      />
                    )}
                    columnCount={group.items.length}
                    columnWidth={cardWidth}
                    height={wheight}
                    rowCount={1}
                    rowHeight={heightWithScroll - 18}
                    width={width}
                  />
                </React.Fragment>
              )}
            </AutoSizer>
          </div>
        );
      }
      return (
        <div key={ind} style={topStyle}>
          <hgroup className={styles.groupHeader}>
            {addGroupItemsToCartCheckBox}
            <h1>{group.header}</h1>
            <h2>
              {fp
                .map((calc) => {
                  const lol = summary.find((item) => item.label === calc.title);
                  let percentageText = '';
                  if (calc.includePercentage && lol) {
                    percentageText = ' (' + Renderer.percent(calc.raw / lol.raw, '0.00') + ')';
                  }
                  return calc.title + ': ' + calc.value + percentageText;
                }, group.groupCalcs)
                .join(' | ')}
            </h2>
          </hgroup>
          {groupContent}
        </div>
      );
    };
    if (isPrintMode) {
      return <div className={styles.rowGrouping}>{groups.map((g, i) => rowRenderer(g, i))}</div>;
    } else {
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <AutoSizer>
            {({ height: asHeight, width }) => (
              <List
                height={asHeight}
                width={width}
                rowCount={groups.length}
                rowHeight={heightWithHeader}
                rowRenderer={({ index, style }) => rowRenderer(groups[index], index, style)}
              />
            )}
          </AutoSizer>
        </div>
      );
    }
  };

  // See about abstracting this logic for use in ColumnGroupedView too
  generateCardProps = (styleItem: CardViewItem): StandardCardProps => {
    const { currentTab, identityPropsConfig, adornments } = this.props;
    const newProps: GenerateCardProps = {
      styleItem,
      currentTab,
      identityPropsConfig,
      adornments,
      popoverTitle: this.props.popoverTitle,
      onItemClick: this.props.onItemClicked,
      onMoreInfoClick: this.props.showStylePane,
      isPrintMode: this.props.isPrintMode,
    };
    return generateCardProps(newProps);
  };

  renderCards = (viewItems: CardViewItem[]) => {
    const cards = viewItems.map((item) => {
      const cardProps = this.generateCardProps(item);
      const key = item[this.props.identityPropsConfig.id];
      switch (this.props.cardType) {
        case 'TopCard': {
          return <TopCard key={key} {...cardProps} />;
        }
        case 'StandardCard':
        default: {
          return <StandardCard key={key} {...cardProps} />;
        }
      }
    });
    return cards;
  };

  getGridItems(children: CardViewItem[]) {
    return children.map(this.generateCardProps);
  }

  render() {
    const {
      title,
      hideTitle,
      subheaderViewDefns,
      summary,
      loaded,
      groupedStyles,
      config,
      showLookBackPeriod,
      isPrintMode = false,
      cardType,
      errorCondition,
      unmodifiedViewDefn,
      showLimit,
      showFlowStatus,
    } = this.props;
    const renderAsGroup =
      groupedStyles.length > 1 || (groupedStyles.length > 0 && groupedStyles[0].header !== NO_GROUPS_TYPE);
    let cardWidth, cardHeight;
    if (cardType === 'TopCard') {
      cardWidth = TopCard.calcCardWidth();
      cardHeight = TopCard.calcCardHeight();
    } else {
      cardWidth = StandardCard.calcCardWidth(fp.get('view.length', config));
      cardHeight = StandardCard.calcCardHeight();
    }

    let sizing;
    if (renderAsGroup) {
      sizing = this.groupRender({
        groups: groupedStyles,
        cardWidth: cardWidth,
        height: TOPHEIGHT + 10,
        isPrintMode,
      });
    } else {
      const children: CardViewItem[] = fp.get('[0].items', groupedStyles) || [];
      if (fp.isEmpty(children)) {
        sizing = <CustomNoRowsOverlay />;
      } else if (isPrintMode) {
        sizing = this.renderCards(children || []);
      } else {
        const gridItems = this.getGridItems(children || []);
        sizing = <CardsGrid cardType={cardType} gridItems={gridItems} cardWidth={cardWidth} cardHeight={cardHeight} />;
      }
    }

    const viewConfigurator = unmodifiedViewDefn && {
      viewConfig: config as TenantConfigViewData,
      unmodifiedViewDefn,
      updateConfig: this.props.onConfigUpdate,
    };

    return (
      <div
        data-qa-component="StandardCardView"
        className={classes(this.props.className, isPrintMode ? styles.inPrintMode : styles.mainContainer)}
      >
        {!this.props.ignoreSubheader && (
          <Subheader
            title={title}
            hideTitle={hideTitle}
            groupByDefn={subheaderViewDefns.groupBy}
            sortByDefn={subheaderViewDefns.sortBy}
            countLimitDefn={subheaderViewDefns.countLimit}
            pareDownDefn={subheaderViewDefns.pareDown}
            showCountLimit={showLimit}
            showFlowStatus={!isUndefined(showFlowStatus) ? showFlowStatus : true}
            showLookBackPeriod={showLookBackPeriod}
            summary={formatSummaries(summary)}
            showSearch={true}
            customEl={this.props.rangePickerEl}
            errorCondition={errorCondition}
            viewConfigurator={viewConfigurator}
            downloadLink={this.props.downloadLink}
            viewDataState={this.props.viewDataState}
          />
        )}
        <div className={styles.cardContainer} data-qa="StandardCardViewContainer">
          <Overlay type="loading" visible={!loaded} />
          {loaded ? sizing : null}
        </div>
      </div>
    );
  }
}

export default StandardCardView;
