import { Suggestion } from 'src/common-ui/components/Inputs/InputSuggest/InputSuggest';
import { isNil, isString } from 'lodash';

export function createElementFromString(html: string) {
  const template = document.createElement('template');
  template.innerHTML = html.trim();
  return template.content.firstChild!;
}

export function createElementsFromString(html: string) {
  const template = document.createElement('template');
  template.innerHTML = html.trim();
  return template.content;
}

export function getSelectedValidValue(value: string, options: Suggestion[], checkLabel = false) {
  const selected = options.find((item) => item.value === value);

  if (!isNil(selected)) {
    return selected;
  }

  return !checkLabel ? selected : options.find((item) => item.label === value);
}

export function getSelectedValidValues(value: string | string[], options: Suggestion[], asCsv = false) {
  let values: string[];

  // keeping string check, since values for review asst. rules are still coming in as an array
  if (isString(value) && asCsv) {
    const matched = value.match(/{(.*)}/);

    values = matched ? matched[1].split(',') : value.split(',');
  } else if (!isString(value)) {
    values = value;
  }

  return options.filter((opt) => values.indexOf(opt.value as string) >= 0);
}
