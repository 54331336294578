import { isNil } from 'lodash';
import LRUMap from 'mnemonist/lru-map';
import hash from 'object-hash';
import { ViewDataState } from 'src/types/Domain';

// SPIKE: style edit needs 6 requests per style preview call + 1 for the companion
// bumping this to 14 allows for two style edits to be in cache +1 buffer
const DEFAULT_CAPACITY = 100;

export type ValidValuesResults = any;

export function getDataFromCache(cache: ValidValuesCache, maybeCacheHash: string | null): any | undefined {
  const cacheHasHash = maybeCacheHash ? cache.has(maybeCacheHash) : false;
  return maybeCacheHash && cacheHasHash ? cache.get(maybeCacheHash) : undefined;
}
/**
 * Given a viewDataState, it returns if it the data is loaded or not
 *
 * @param viewDataState The view data state enum
 * @returns boolean
 */
export function isDataLoaded(viewDataState: ViewDataState): boolean {
  return viewDataState === ViewDataState.liveDataReady || viewDataState === ViewDataState.liveDataLoadingFoundCache;
}

type HashInputs = {
  endpoint: string;
};

export class ValidValuesCache {
  cache = new LRUMap<string, ValidValuesResults>(DEFAULT_CAPACITY);
  inflightRequestMap = new Map<string, Promise<ValidValuesResults>>();

  /**
   * Generates a hash from the provided inputs.
   * Objects are sorted before hashing by default
   */
  static hash({ endpoint }: HashInputs): string {
    return hash({
      endpoint,
    });
  }

  has(hash: string): boolean {
    return this.cache.has(hash);
  }

  isInflight(hash: string): boolean {
    return this.inflightRequestMap.has(hash);
  }

  get(hash: string) {
    return this.cache.get(hash);
  }

  getInflightPromise(hash: string) {
    return this.inflightRequestMap.get(hash);
  }

  set(hash: string, data: ValidValuesResults) {
    const result = this.cache.setpop(hash, data);

    if (!isNil(result) && result.evicted) {
      console.log('cache capacity reached, least recently used item dropped');
    } else {
      console.log(`valid values data added to cache, size is currently ${this.cache.size}`);
    }
  }

  setInflightRequest(hash: string, promise: Promise<ValidValuesResults>) {
    this.inflightRequestMap.set(hash, promise);
  }

  deleteInflightRequest(hash: string) {
    // SPIKE: better to just delete on cache.set()?
    this.inflightRequestMap.delete(hash);
  }

  clear() {
    this.cache.clear();
    // SPIKE: should we cancel all the inflights here?
    this.inflightRequestMap.clear();
  }
}

export function makeValidValuesCache() {
  return new ValidValuesCache();
}
