import React, { createRef, RefObject } from 'react';
import { isNil } from 'lodash';
import { classes } from 'typestyle';

import { Fab, Button, Tooltip } from '@material-ui/core';
import { fabBtn } from 'src/pages/AssortmentStrategy/TargetSetting/TargetList/TargetList.styles';
import ButtonModal from 'src/components/ButtonModal/ButtonModal';
import { createDefaultButtons } from 'src/components/ButtonModal/ButtonModal.default';
import { TEAL_PRIMARY } from 'src/common-ui/theme';

export enum FabType {
  none = 'none',
  buttonModal = 'buttonModal',
  worklist = 'worklist',
  cart = 'cart',
  planning = 'planning',
}

export type FabHandlerInvocable = {
  onFabClick: () => void;
  onToggleAll?: () => void;
};

// these are all the expected property keys that help drive the logic of the fabTypes
// duplicate loading props is because different components used different loading props
type WithFabProps = {
  fabType: FabType;
  fabDefn?: string;
  fabTooltip?: string;
  isPrintMode: boolean;
  loaded: boolean;
  isLoading: boolean;
  isFabDisabled: boolean;
  cartCount: number; // needed because fab cart btn renders number of items in cart
  showSelectAll: boolean;
  isSelectAllDisabled: boolean;
};

function getFabContent(
  wrappedComponentRef: RefObject<FabHandlerInvocable>,
  fabType: FabType,
  fabContentProps: Pick<WithFabProps, 'isFabDisabled' | 'cartCount' | 'showSelectAll' | 'isSelectAllDisabled'>,
  fabTooltip?: string,
  fabDefn?: string
) {
  switch (fabType) {
    case FabType.buttonModal:
      return <ButtonModal tooltipString={fabTooltip} defn={fabDefn} />;
    case FabType.worklist:
      return (
        <section className={fabBtn}>
          <Fab
            color="secondary"
            aria-label="Add visible items to worklist"
            onClick={() => {
              if (wrappedComponentRef && wrappedComponentRef.current) {
                wrappedComponentRef.current.onFabClick();
              }
            }}
          >
            <i style={{ color: 'white' }} className={classes('far fa-lg fa-plus')} />
          </Fab>
        </section>
      );
    case FabType.cart:
      const { isFabDisabled = false, cartCount, showSelectAll, isSelectAllDisabled } = fabContentProps;
      return (
        <React.Fragment>
          <section
            className={fabBtn}
            style={{
              backgroundColor: isFabDisabled ? 'rgb(174, 174, 174)' : TEAL_PRIMARY,
              bottom: '5px',
              right: 37,
            }}
          >
            <Button
              aria-label="Create New Target"
              style={{
                color: isFabDisabled ? 'rgb(244, 244, 244)' : 'white',
              }}
              onClick={() => {
                if (wrappedComponentRef && wrappedComponentRef.current) {
                  wrappedComponentRef.current.onFabClick();
                }
              }}
              disabled={isFabDisabled}
            >
              Add to Cart {`(${cartCount})`}
            </Button>
          </section>
          {showSelectAll && (
            <section
              className={fabBtn}
              style={{
                backgroundColor: isSelectAllDisabled ? 'rgb(174, 174, 174)' : TEAL_PRIMARY,
                bottom: '5px',
                right: 185,
              }}
            >
              <Button
                style={{
                  color: isSelectAllDisabled ? 'rgb(244, 244, 244)' : 'white',
                }}
                onClick={() => {
                  if (wrappedComponentRef && wrappedComponentRef.current && wrappedComponentRef.current.onToggleAll) {
                    wrappedComponentRef.current.onToggleAll();
                  }
                }}
                disabled={isSelectAllDisabled}
              >
                Select All
              </Button>
            </section>
          )}
        </React.Fragment>
      );

    case FabType.planning:
      return (
        <section className={fabBtn}>
          <Tooltip title={fabTooltip || 'Replan pending items'} enterDelay={0} arrow>
            <Fab
              color="secondary"
              aria-label="Update Plan"
              onClick={() => {
                if (wrappedComponentRef && wrappedComponentRef.current) {
                  wrappedComponentRef.current.onFabClick();
                }
              }}
            >
              <i style={{ color: 'white' }} className={classes('far fa-lg fa-paper-plane')} />
            </Fab>
          </Tooltip>
        </section>
      );
    case FabType.none:
    default:
      return undefined;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function withFab<P>(WrappedComponent: React.ComponentType<P>) {
  const FabWrappedComponent = (props: P & WithFabProps) => {
    const { fabDefn, isPrintMode, fabType, fabTooltip, loaded, isLoading: viewLoading, ...fabContentProps } = props;
    const wrappedRef: RefObject<FabHandlerInvocable> = createRef();

    // consolidate loading variables from different views into single value
    const isLoading = (!isNil(loaded) && !loaded) || (!isNil(viewLoading) && viewLoading);
    if (isPrintMode || isLoading) {
      return <WrappedComponent {...props} />;
    }
    let fabContent = getFabContent(wrappedRef, fabType, fabContentProps, fabTooltip, fabDefn);
    if (fabTooltip && (fabType == FabType.worklist || fabType == FabType.cart)) {
      fabContent = (
        <Tooltip title={fabTooltip} enterDelay={0} arrow>
          {fabContent || <div />}
        </Tooltip>
      );
    }
    return (
      <React.Fragment>
        <WrappedComponent ref={wrappedRef} {...props} />
        {fabContent}
      </React.Fragment>
    );
  };

  FabWrappedComponent.displayName = 'FabWrappedComponent';
  return FabWrappedComponent;
}
