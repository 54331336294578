import { AppState } from 'src/store';
import { createSelector, OutputSelector } from 'reselect';
import { flattenAndLabelGroup } from 'src/components/StandardCardView/SortAndGroup';
import {
  CardViewDataInputs,
  CardViewGroup,
  CardViewOptionalInputs,
} from 'src/components/StandardCardView/UIData.types';

import { ReduxSlice as SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { CanvasViewSlice } from './CanvasView.slice';
import { BasicPivotItem, TreePivot, WorklistInfo } from 'src/worker/pivotWorker.types';
import { GetSummaryOutput } from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.selectors';
import { toSummaries } from 'src/utils/Pivot/RollUp';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { filterData } from 'src/utils/Pivot/Filter';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { isNil, get, isEmpty } from 'lodash';
import { AssortmentAddSlice } from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentAdd.slice';
import { getDataFromCache } from 'src/services/pivotServiceCache';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import processDataForCardView from 'src/components/StandardCardView/CardViewDataProcessor';

const getTreeData = (state: AppState) => {
  return getDataFromCache(state.pages.hindsighting.styleColorReview.canvasView)?.tree || [];
};

const getRollup = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.canvasView.calcViewDefn;
};

const getViewSlice = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.canvasView;
};

const getViewIdentityProp = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.canvasView.identityPropsConfig.id;
};

const getSubheader = (state: AppState) => {
  return state.subheader;
};

export const getSelectedItems = (state: AppState) => {
  return state.pages.assortmentBuild.addStyles.selectedItemsForCart;
};

export function getGroupedData(
  styles: BasicPivotItem[],
  canvasViewConfig: CanvasViewSlice | AssortmentAddSlice,
  subheader: SubheaderSlice,
  selectedItems: BasicPivotItem[],
  worklist?: WorklistInfo[]
) {
  if (styles && canvasViewConfig && !isNil(canvasViewConfig.calcViewDefn) && subheader) {
    const dataInputs: CardViewDataInputs = {
      styles,
      subheader,
      defns: {
        view: canvasViewConfig.viewDefn || {},
        rollup: canvasViewConfig.calcViewDefn || {},
      },
    };
    const optionalInputs: CardViewOptionalInputs = {
      worklist,
      selectedItems,
    };
    return processDataForCardView(dataInputs, optionalInputs);
  }
  return [];
}

export type ProcessedDataSelector = OutputSelector<
  AppState,
  CardViewGroup[],
  (res1: BasicPivotItem[], res2: CanvasViewSlice, subh: SubheaderSlice, selIt: BasicPivotItem[]) => CardViewGroup[]
>;
export const getProcessedData: ProcessedDataSelector = createSelector(
  getTreeData,
  getViewSlice,
  getSubheader,
  getSelectedItems,
  getGroupedData
);

export function combineSummaryInputs(
  identityProp: string,
  treeData: TreePivot,
  rollupConfig: TenantConfigViewData | Record<string, never>,
  subheader: SubheaderSlice,
  searchIndexes?: string[]
) {
  const emptySummary: GetSummaryOutput[] = [];
  const views = get(rollupConfig, 'view');
  const searchFields = searchIndexes ? searchIndexes : externalGridSearchFields;

  if (isNil(views) || isEmpty(rollupConfig)) {
    return emptySummary;
  } // rollups not required

  const groupDataIndex = getGroupBySelectedOptionProperty(subheader.groupBy, 'dataIndex');
  const groupDimension = getGroupBySelectedOptionProperty(subheader.groupBy, 'dimension');
  const flat = flattenAndLabelGroup({ items: treeData, groupDataIndex, groupDimension });

  if (flat.length === 0 || !Array.isArray(views) || views.length === 0) {
    return emptySummary;
  }
  const filteredFlat = filterData(
    flat,
    subheader.search,
    searchFields,
    subheader.flowStatus,
    subheader.pareDown && subheader.pareDown.selections
  );
  return toSummaries(filteredFlat, views, identityProp);
}

export const getSummary = createSelector(
  getViewIdentityProp,
  getTreeData,
  getRollup,
  getSubheader,
  combineSummaryInputs
);
