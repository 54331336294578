import * as React from 'react';

export type SectionHeaderOptions = {
  isOpen: boolean;
  title: string;
  handleClick: () => void;
};

export default function SectionHeader({ handleClick, isOpen, title }: SectionHeaderOptions) {
  return (
    <header className="toggle" onClick={handleClick}>
      <i className={`far fa-chevron-${isOpen ? 'up' : 'down'}`} />
      {title}
    </header>
  );
}
