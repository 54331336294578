import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConfigurableGridConfigItem } from 'src/components/ConfigurableGrid/ConfigurableGrid.types';
import { AttributeDataHandler } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModal.types';
import { ValueLabelPair } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import { ListViewMain } from 'src/utils/Component/ListView';
import { BasicItem, BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

export type SizeEligibilityListGridConfigs = {
  columns: ConfigurableGridConfigItem[];
  main: {
    title: string;
    leafKey?: string;
  };
  companionApi: ListViewMain;
  update: AttributeDataHandler;
};

export type SizeEligibilityListGridSlice = {
  viewDefns: undefined | SizeEligibilityListGridConfigs;
  areConfigLoading: boolean;
  areGridDataLoading: boolean;
  areSizeRangesLoading: boolean;
  areCompanionViewLoading: boolean;
  sizeRanges: ValueLabelPair[] | undefined;
  gridData: BasicPivotItem[] | undefined;
  selectedSizeRange: ValueLabelPair | undefined;
  validSizes: string[] | undefined;
  floorsetData: BasicItem[] | undefined;
  companionData: BasicPivotItem[] | undefined;
};

const initSizeEligibilityListGridSlice = {
  viewDefns: undefined,
  areConfigLoading: false,
  areGridDataLoading: false,
  areSizeRangesLoading: false,
  areCompanionViewLoading: false,
  sizeRanges: undefined,
  gridData: undefined,
  selectedSizeRange: undefined,
  validSizes: undefined,
  floorsetData: undefined,
  companionData: undefined,
};

const SizeEligibilityListGridSliceReducer = createSlice({
  name: 'sizeEligibilityListGrid',
  initialState: initSizeEligibilityListGridSlice as SizeEligibilityListGridSlice, // this needs to be asserted
  reducers: {
    setSelectedSizeRange(state, action: PayloadAction<ValueLabelPair>) {
      state.selectedSizeRange = action.payload;
    },
    setValidSizes(state, action: PayloadAction<string[]>) {
      state.validSizes = action.payload;
    },
    requestSizeEligibilityListGridConfig(state) {
      state.areConfigLoading = true;
    },
    requestSizeEligibilitySizeRanges(state) {
      state.areSizeRangesLoading = true;
    },
    requestSizeEligibilityListGridGridData(state) {
      state.areGridDataLoading = true;
    },
    requestCompanionViewData(state) {
      state.areCompanionViewLoading = true;
    },
    receiveSizeEligibilityListGridConfig(state, action: PayloadAction<SizeEligibilityListGridConfigs>) {
      state.areConfigLoading = false;
      state.viewDefns = action.payload;
    },
    receiveSizeEligibilitySizeRanges(state, action: PayloadAction<ValueLabelPair[]>) {
      state.sizeRanges = action.payload;
      state.areSizeRangesLoading = false;
    },
    receiveSizeEligibilityListGridGridData(state, action: PayloadAction<BasicPivotItem[]>) {
      state.gridData = action.payload;
      state.areGridDataLoading = false;
    },
    receiveFloorsetData(state, action: PayloadAction<BasicItem[]>) {
      state.floorsetData = action.payload;
    },
    receiveCompanionData(state, action: PayloadAction<BasicPivotItem[]>) {
      state.companionData = action.payload;
      state.areCompanionViewLoading = false;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initSizeEligibilityListGridSlice;
    },
    resetSlice() {
      return initSizeEligibilityListGridSlice;
    },
  },
});

export const {
  setSelectedSizeRange,
  requestSizeEligibilityListGridConfig,
  requestSizeEligibilitySizeRanges,
  requestSizeEligibilityListGridGridData,
  receiveSizeEligibilityListGridConfig,
  receiveSizeEligibilitySizeRanges,
  receiveSizeEligibilityListGridGridData,
  requestCompanionViewData,
  receiveFloorsetData,
  receiveCompanionData,
  setValidSizes,
  receiveError,
  resetSlice,
} = SizeEligibilityListGridSliceReducer.actions;
export default SizeEligibilityListGridSliceReducer.reducer;
