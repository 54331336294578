import { FavoriteListItem } from './FavoritesMenu';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { CompanionFavoriteData } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';

export const DEFAULT_FAVORITE_ID = 'DEFAULT_FAVORITE_bd57c309';
export const DEFAULT_FAVORITE = (
  unmodifiedViewDefn: TenantConfigViewData,
  defaultCompanionData?: CompanionFavoriteData
): FavoriteListItem => ({
  id: DEFAULT_FAVORITE_ID,
  name: 'Default View',
  icon: 'fal fa-sync-alt',
  config: unmodifiedViewDefn,
  groupBySelection: 0,
  sortBySelection: 0,
  sortByDirection: 'desc' as const,
  flowStatus: [],
  pareDownSelections: [],
  limitSelection: 0,
  companionData: defaultCompanionData,
});
