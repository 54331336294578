import React, { Component, Fragment } from 'react';
import { ICellRendererParams, RowNode } from 'ag-grid-community';

import { NavigationButton } from 'src/components/NavigationButton/NavigationButton';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import {
  getId,
  PUBLISH_INDEX,
  IS_PUBLISHABLE_INDEX,
  RowData,
} from 'src/pages/AssortmentBuild/FlowSheet/FlowSheet.types';
import { isNil } from 'lodash';

type Props = {
  onPublish: (columnId: string, rowNode: RowNode, isPublished: boolean) => void;
} & ICellRendererParams;

type State = {
  isOpen: boolean;
};

export default class PublishGridRenderer extends Component<Props, State> {
  isPublished = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  onConfirm = () => {
    const columnId = this.props.column.getColId();
    this.props.onPublish(columnId, this.props.node, this.isPublished);
    const data = this.props.data;
    const colDef = this.props.column.getColDef();
    const field = colDef.field || '';

    const isPublishedNode = this.props.api.getRowNode(getId(data.groupId, PUBLISH_INDEX));
    const isPublishedData: RowData = isPublishedNode.data;
    this.isPublished = !isPublishedData.extraData[field];
    isPublishedData.extraData[field] = this.isPublished;
    isPublishedNode.setData(isPublishedData);
    this.toggleModal();
  };

  onCancel = () => {
    this.toggleModal();
  };

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { isOpen } = this.state;

    const colDef = this.props.column.getColDef();
    const data = this.props.data;
    const field = colDef.field || '';

    let buttonText = '';
    const isPublishedNode = this.props.api.getRowNode(getId(data.groupId, PUBLISH_INDEX));
    if (isPublishedNode) {
      const isPublishedData: RowData = isPublishedNode.data;
      this.isPublished = !!isPublishedData.extraData[field];
      buttonText = this.isPublished ? 'Un-Publish' : 'Publish';
    }

    let isPublishable = false;
    const isPublishableNode = this.props.api.getRowNode(getId(data.groupId, IS_PUBLISHABLE_INDEX));
    if (isPublishableNode) {
      const isPublishableData: RowData = isPublishableNode.data;
      isPublishable = !isNil(field) ? !!isPublishableData.extraData[field] : false;
      buttonText = !isPublishable ? 'Publish Disabled' : buttonText;
    }

    const publishModalText = this.isPublished ? 'Un-Publish week receipts?' : 'Publish week receipts?';

    return (
      <Fragment>
        <NavigationButton buttonText={buttonText} onClick={this.toggleModal} disabled={!isPublishable} />
        <ConfirmationModal
          isOpen={isOpen}
          descriptionText={publishModalText}
          onConfirm={this.onConfirm}
          onCancel={this.onCancel}
        />
      </Fragment>
    );
  }
}
