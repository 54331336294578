import { style } from 'typestyle/lib';
import { primaryTextColor, mediumBorderColor, faintTextColor } from 'src/utils/Style/Theme';
import {
  TREND_UP_COLOR,
  TREND_DOWN_COLOR,
  NO_TREND_COLOR,
  MAP_BUBBLE_MIN_SIZE,
  MAP_BUBBLE_MAX_SIZE,
} from '../GeoTrends/GeoTrends.styles';

const keyFinancialHeight = 140;
const choiceProductivityHeight = 180;
const headerHeight = 25;

// Global Region Volume and Trend styles.
export const geoMapStyles = {
  bubbleMaxSize: MAP_BUBBLE_MAX_SIZE,
  bubbleMinSize: MAP_BUBBLE_MIN_SIZE,
  noTrendColor: NO_TREND_COLOR,
  trendDownColor: TREND_DOWN_COLOR,
  trendUpColor: TREND_UP_COLOR,
};

export default style({
  color: primaryTextColor,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  $nest: {
    '.summary-container': {
      flex: '1',
      overflow: 'auto',
      $nest: {
        '.summary-container-fullwidth': {
          // FIXME: The subcontainers should be properly
          // scaling w/ screen width. This just forces
          // an scrollbar to prevent element overlapping.
          minWidth: 1100,
        },
      },
    },
    '.toggle': {
      cursor: 'pointer',
      height: headerHeight,
      fontSize: '0.9rem',
      $nest: {
        '> i': {
          marginRight: '0.5rem',
        },
      },
    },
    '.key-financials': {
      flexDirection: 'column',
      height: keyFinancialHeight,
      $nest: {
        '&-collapsed': {
          height: headerHeight,
          overflow: 'hidden',
        },
        '.items': {
          backgroundColor: '#fafafa',
          flexWrap: 'wrap',
          height: 'calc(100% - 30px)',
          overflowY: 'visible',
          display: 'flex',
        },
        '.key-financial-item': {
          backgroundColor: '#fafafa',
          color: faintTextColor,
          height: 85,
          marginTop: 14,
          width: '25%',
          minWidth: 260,
          $nest: {
            '+ .key-financial-item': {
              borderLeft: '1px solid ' + mediumBorderColor,
            },
            '> header': {
              padding: '10px 0 0 35px',
              fontSize: '0.7rem',
            },
            '.right-container': {
              paddingRight: 35,
            },
            '.primary': {
              paddingLeft: 35,
              fontSize: '1.7rem',
            },
          },
        },
      },
    },
    '.choice-productivity-group': {
      height: choiceProductivityHeight,
      display: 'flex',
      flexDirection: 'column',
      $nest: {
        '&-collapsed': {
          height: headerHeight,
          overflow: 'hidden',
        },
        '&.print': {
          height: 'auto',
        },
        '.items': {
          overflowY: 'auto',
          height: `calc(100% - ${headerHeight}px)`,
          display: 'flex',
          flexWrap: 'wrap',
          $nest: {
            '&.collapsed': {
              display: 'none',
            },
          },
        },
        '.key-financial-wrapper': {
          display: 'flex',
          height: '100%',
          width: '20%',
          alignItems: 'center',
          minWidth: 240,
          $nest: {
            header: {
              padding: '0 0 10px 35px',
              fontSize: '0.7rem',
              fontWeight: 'unset',
            },
            '.primary': {
              color: primaryTextColor,
              paddingLeft: 40,
              flex: 'none',
            },
            '.key-financial-item': {
              width: '100%',
            },
            '.right-container': {
              paddingLeft: 20,
            },
          },
        },
        '.choice-productivity': {
          width: '33.333%',
          height: '4.5rem',
          minWidth: 285,
          $nest: {
            '.left-container': {
              marginRight: 5,
              fontSize: '0.7rem',
              width: '8rem',
            },
            '.middle-container': {
              fontSize: '0.7rem',
            },
          },
        },
        '.choice-wrapper': {
          flex: 1,
          display: 'flex',
          flexWrap: 'wrap',
        },
      },
    },
    '.macro-trend-analysis': {
      flexDirection: 'column',
      $nest: {
        '&__container': {
          $nest: {
            '&-collapsed': {
              height: headerHeight,
              overflow: 'hidden',
            },
          },
        },
        '&__items': {
          display: 'flex',
        },
      },
    },
  },
});
