import React from 'react';
import { CellValueChangedEvent } from 'ag-grid-community/dist/lib/events';
import { ColDef, ColGroupDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';

import { TimeEntry, TreePivot } from 'src/worker/pivotWorker.types';
import { PrintProps } from 'src/components/higherOrder/Print/Print';
import { Option } from 'src/components/Configure/ConfigureModal';
import { GridItem } from 'src/utils/Component/AgGrid/AgDataFormat';
import { mapStateToProps, dispatchToProps } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle.container';
import { FlowSheetGrid } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetGrid';
import { CustomInputAttributes, ValidValuesInputParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { ComponentTypeGridDispatchProps } from 'src/pages/AssortmentBuild/Pricing/PricingGrid';
import { StyleDetailsPopoverProps } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { PricingAndFlowSheetComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { RowNode } from 'ag-grid-community/dist/lib/entities/rowNode';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';
import { FlowSheetGridDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';

export type FlowSheetGridProps = {
  loaded: boolean;
  itemId?: string;
  overTimeData?: TreePivot;
  rowHeight?: number;
  groupRowHeight?: number;
  scopeTimeEntries: TimeEntry[];
  anchorField?: string;
  editable: boolean;
  columnWidth?: number;
  showUndoBtn?: boolean;
  gridDefn?: FlowSheetGridDefn;
  onValueChange?: (payload: GranularEditPayloadItem[], runPlan?: boolean) => void;
  onItemClicked?: (item: Record<string, any>, eventTarget?: HTMLElement) => void;
  updateAssortmentPlan?: () => void;
} & ComponentTypeGridDispatchProps;

export type RowData = {
  id: string;
  additionalId?: string;
  path: string[];
  groupId: string;
  measureId?: string;
  name?: string;
  editable?: boolean;
  inputType?: string;
  hidden: boolean;
  renderer?: string;
  classes?: string[];
  calculation?: string;
  valueTests?: string[];
  includeInAllUpdates?: boolean;
  editableByCalc?: string;
  ignoreLock?: boolean;
  ignorePublished?: boolean;
  extraData: {
    [extra: string]: number | string | boolean;
  };
} & (RowDataStdInputParams | RowDataVVInputParams);

type RowDataStdInputParams = {
  inputType?: 'receiptsAdjCalculator' | 'integer' | 'textValidator' | 'checkbox' | undefined;
  inputParams?: CustomInputAttributes;
};

type RowDataVVInputParams = {
  inputType: 'validValues';
  inputParams?: ValidValuesInputParams;
};

export type FrameworkComponents = { [renderer: string]: React.ReactNode };

export interface FlowSheetGridState {
  columnDefs?: (ColDef | ColGroupDef)[];
  rowData: RowData[];
  rowDataOld: RowData[]; // 1 step behind
  anchorField?: string;
  context: {
    componentParent: FlowSheetGrid;
  };
  frameworkComponents: FrameworkComponents;
}

export const EDITABLE_BG_COLOR = 'rgba(119, 255, 244, 0.2)';
export const IS_PUBLISHABLE_INDEX = 'ispublishable';
export const PUBLISH_INDEX = 'dc_publish';
export const LOCKED_INDEX = 'is_locked';
export const SYS_VRP_INDEX = 'sys_vrp';
export const OVERRIDE_VRP_INDEX = 'dc_uservrp';
export const PO_VRP_INDEX = 'po_vrp';
export const TEST_PO = 'testpo';
export const FLOORSET_PO = 'floorsetpo';
export const DC_USERADJ = 'dc_useradj';
export const DC_FINREV = 'dc_sc_finrev';
export const DC_ONORDER = 'dc_onorder';

export function getId(groupId: string, measureId: string): string {
  return `${groupId}_${measureId}`;
}

export interface FlowSheetCellRendererParams extends ICellRendererParams {
  data: RowData;
  editable: (rowNode: RowNode, colDef: ColDef) => boolean;
  onHeaderClick: (item: { id: string | undefined; parentId: string | undefined }) => Record<string, any>;
}
export interface FlowSheetCellValueChangedEvent extends CellValueChangedEvent {
  data: RowData;
}

export interface EditRendererState {
  value?: number | string | boolean;
}

export interface GridMeasureDefn {
  text: string;
  dataIndex: string;
  renderer?: string;
  editable?: boolean;
  inputType?: string;
  calculation?: string;
  dependentData?: string[];
  valueTests?: string[];
  inputParams?: CustomInputAttributes;
  editableByCalc?: string;
  ignoreLock?: boolean;
  ignorePublished?: boolean;
  visible?: boolean;
  hideFromConfigurator?: boolean;
  includeInAllUpdates?: boolean;
  classes?: string[];
}

export type OwnProps = z.infer<typeof PricingAndFlowSheetComponentProps>;

export type FlowSheetValueProps = ReturnType<typeof mapStateToProps>;
export type FlowSheetDispatchProps = ReturnType<typeof dispatchToProps> & {
  updateConfigureSelections?(selections: Option[]): void;
};

export type FlowSheetProps = OwnProps &
  FlowSheetValueProps &
  FlowSheetDispatchProps &
  PrintProps &
  StyleDetailsPopoverProps;

export type SortDirection = 'asc' | 'desc';
export type State = {
  uniqueItems: GridItem[]; // should this be moved?
  rowData?: RowData[];
  selectedItem?: string; //remove me later
  companionSortDirection: SortDirection;
  companionCollapsed: boolean;
  companionSortField?: string;
  companionLevelField?: string;
  companionData: CompanionDataItem[];
  isDefault?: boolean;
  configureIsOpen: boolean;
  getMassEdit?: boolean;
  configureLastSelected?: Option[];
  isCompanionChangeOnDefault?: boolean;
};

export interface CompanionDataItem {
  id: string;
  title?: string;
  name: string;
  stars: number;
  imageUri: string;
  [key: string]: string | number | undefined;
}
