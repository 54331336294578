import { ViewDefnState, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { FavoriteResponseItem } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { ViewDataState } from 'src/types/Domain';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import serviceContainer from 'src/ServiceContainer';
import { BasicPivotItem, ListDataOptions } from 'src/worker/pivotWorker.types';
import { cacheCheckFetchPivotData } from '../../StyleColorReview/StyleColorReview.slice';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

export type ViewDefns = {
  groupByDefn: TenantConfigViewData;
  keyFinancials: TenantConfigViewData;
  choiceProductivity: TenantConfigViewData;
  gridDefn: TenantConfigViewData;
  unmodifiedViewDefn: TenantConfigViewData;
  favoritesList?: FavoriteResponseItem[];
};

export type CategorySummaryViewData = {
  keyFinancials: BasicPivotItem[];
  choiceProductivity: BasicPivotItem[];
  gridData: BasicPivotItem[];
};

export type CategorySummarySlice = {
  cacheHash: string | null;
  viewDataState: ViewDataState;
  viewDefnState: ViewDefnState;
  viewDefns?: ViewDefns;
};

const initialState: CategorySummarySlice = {
  cacheHash: null,
  viewDataState: ViewDataState.idle,
  viewDefnState: ViewDefnState.idle,
};

const categorySummaryReducer = createSlice({
  name: 'CategorySummary',
  initialState,
  reducers: {
    requestCategorySummaryViewDefns(state) {
      state.viewDefnState = ViewDefnState.loading;
    },
    receiveCategorySummaryViewDefns(state, action: PayloadAction<ViewDefns>) {
      state.viewDefnState = ViewDefnState.loaded;
      state.viewDefns = action.payload;
    },
    receiveGridViewDefn(state, action: PayloadAction<TenantConfigViewData>) {
      if (!isNil(state.viewDefns)) {
        state.viewDefns = {
          ...state.viewDefns,
          gridDefn: action.payload,
        };
      }
    },
    requestData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataReady;
      }
    },
    cleanUp() {
      return initialState;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
  },
});

export const {
  requestCategorySummaryViewDefns,
  receiveCategorySummaryViewDefns,
  receiveGridViewDefn,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  cleanUp,
  receiveError,
} = categorySummaryReducer.actions;

export function fetchCategorySummaryData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    serviceContainer.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData
  );
}

export default categorySummaryReducer.reducer;
