import { connect, Dispatch } from 'react-redux';
import { GeoTrends, FunctionProps } from './GeoTrends';
import selectAndProjectState from './GeoTrends.selectors';
import container from 'src/ServiceContainer';

import {
  cleanUp,
  receiveTenantConfig,
  receiveError,
  receiveGeoData,
  requestGeoData,
  geotrendsRequestChartData,
  geotrendsReceiveChartData,
  TenantResponse,
  selectPoint,
  resetPoint,
  selectGeoLevel,
} from './GeoTrends.slice';

import serviceContainer from 'src/ServiceContainer';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { TOP_DOWN } from 'src/utils/Domain/Constants';
import { ExtendedPointObject } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/Charts/SimplerChart';
import { makeScopeSensitive } from 'src/components/higherOrder/ScopeSensitive';
import { getTopMembers } from 'src/pages/Hindsighting/MacroTrends/Summary/Summary.container';
import { AppState, AppThunkDispatch } from 'src/store';
import { AnyAction as BaseAction } from 'redux';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';

const DEFNS = {
  aggBy: 'HistoryYearlyTrendGeoTrendsAggBy',
  mapViewDefn: 'HistoryYearlyTrendRecapGeoTrends',
  chartsViewDefn: 'HistoryYearlyTrendRecapGeoTrendsCharts',
  listData: 'HistoryGeo',
  chartListData: 'HistoryGeoByMonth',
};

function asyncGetMapData(extraAggBy?: string) {
  const service = serviceContainer.pivotService;

  return (dispatch: AppThunkDispatch, getState: () => AppState): Promise<BaseAction | void> => {
    const state = getState();
    const { selectedGeoLevel } = state.pages.hindsighting.geoTrends;
    const { flowStatus = [] } = state.subheader;

    const modelId = selectedGeoLevel ? DEFNS.listData + selectedGeoLevel.modelId : DEFNS.listData;

    if (selectedGeoLevel) {
      dispatch(requestGeoData());
      return service
        .listData(modelId, TOP_DOWN, {
          aggBy: selectedGeoLevel.groupingKey + (extraAggBy ? ',' + extraAggBy : ''),
          flowStatus: flowStatus.join(','),
          topMembers: getTopMembers(getState().scope.scope),
        })
        .then((resp) => {
          const props = { data: resp, selectedGeoLevel: selectedGeoLevel };
          dispatch(receiveGeoData(props));
        })
        .catch((error) =>
          dispatch(
            receiveError({
              type: ComponentErrorType.data,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.geoTrends,
              stack: (error as Error)?.stack,
              issues: error,
            })
          )
        );
    }
    return Promise.resolve();
  };
}

function asyncGetChartData() {
  const service = serviceContainer.pivotService;

  return (dispatch: Dispatch<AppState>, getState: () => AppState): Promise<void> | void => {
    const { selectedItem } = getState().pages.hindsighting.geoTrends;

    if (selectedItem) {
      const itemId = selectedItem.mId;
      dispatch(geotrendsRequestChartData());
      service
        .listData(DEFNS.chartListData, TOP_DOWN, {
          aggBy: 'level:month',
          topMembers: itemId,
        })
        .then((resp) => {
          dispatch(geotrendsReceiveChartData(resp.tree));
        })
        .catch((error) =>
          dispatch(
            receiveError({
              type: ComponentErrorType.data,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.geoTrends,
              stack: (error as Error)?.stack,
              issues: error,
            })
          )
        );
    }
  };
}

function dispatchToProps(dispatch: AppThunkDispatch): FunctionProps {
  const client = container.tenantConfigClient;

  return {
    onShowView() {
      const config: TenantResponse = {
        levelSelectConfig: undefined,
        topChartConfig: undefined,
        bottomChartConfig: undefined,
      };

      client
        .getTenantViewDefns({
          defnIds: [DEFNS.mapViewDefn, DEFNS.chartsViewDefn],
          appName: TOP_DOWN,
        })
        .then((resp) => {
          config.levelSelectConfig = resp[0];
          config.topChartConfig = resp[1].view[0];
          config.bottomChartConfig = resp[1].view[1];
          dispatch(receiveTenantConfig(config));
          return resp[0];
        })
        .then((levelSelectConfig) => {
          const defaultSelection = levelSelectConfig.default;
          const defaultItem = levelSelectConfig.view.find((item) => item.dataIndex === defaultSelection)!;
          dispatch(selectGeoLevel(defaultItem));
          dispatch(asyncGetMapData());
        })
        .catch((error) =>
          dispatch(
            receiveError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.geoTrends,
              stack: (error as Error)?.stack,
              issues: error,
            })
          )
        );
    },
    onUpdate() {
      dispatch(asyncGetMapData());
    },
    // @ts-ignore
    onUpdateGeoLevel(selectedGeo: TenantConfigViewItem) {
      dispatch(selectGeoLevel(selectedGeo));
      dispatch(resetPoint());
      dispatch(asyncGetMapData());
    },
    onSelectPoint(selectedItem: ExtendedPointObject) {
      dispatch(selectPoint(selectedItem));
      dispatch(asyncGetChartData());
    },
    onDestroy() {
      dispatch(cleanUp());
    },
  };
}

// @ts-ignore
const scopeSensitiveComponent = makeScopeSensitive(GeoTrends);
export default connect(selectAndProjectState, dispatchToProps)(scopeSensitiveComponent);
