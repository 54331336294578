import * as React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ColDef, ColGroupDef, GridApi, ColumnApi, CellClassParams } from 'ag-grid-community';

import { ADJUSTED_APS } from 'src/utils/Domain/Constants';
import * as StyleEditSectionStyles from './StyleEditSection.styles';
import { TransposedColDef } from './StyleEditSection.types';

export type StyleEditSectionHeaderGridProps = {
  frameworkComponents: any;
  nonFrameworkComponents: any;
  rowData: undefined | TransposedColDef[];
  columnDefs: (ColDef | ColGroupDef)[];
  onHeaderGridReady: (headerGridApi: GridApi, headerGridColumnApi: ColumnApi) => void;
};

export class StyleEditSectionHeaderGrid extends React.Component<StyleEditSectionHeaderGridProps> {
  constructor(props: StyleEditSectionHeaderGridProps) {
    super(props);
  }

  render() {
    const { frameworkComponents, nonFrameworkComponents, rowData, columnDefs, onHeaderGridReady } = this.props;

    return (
      <AgGridReact
        rowData={rowData}
        suppressBrowserResizeObserver={true}
        columnDefs={columnDefs}
        singleClickEdit={true}
        suppressContextMenu={true}
        components={nonFrameworkComponents}
        frameworkComponents={frameworkComponents}
        headerHeight={0}
        getRowNodeId={(data: any) => data.dataKey}
        getRowHeight={(rowParams: any) => {
          // this method fixes row heights after data propagation
          // to other grids in the store eligibility section
          if (rowParams.data.dataKey === 'color_header') {
            return StyleEditSectionStyles.colorHeaderRowHeight;
          }

          return StyleEditSectionStyles.defaultRowHeight;
        }}
        onGridReady={(gridParams: GridReadyEvent) => {
          if (gridParams.api && gridParams.columnApi) {
            onHeaderGridReady(gridParams.api, gridParams.columnApi);
          }
        }}
        onCellEditingStopped={() => this.forceUpdate()}
        rowClassRules={{
          [StyleEditSectionStyles.editableRow]: (rowParams: CellClassParams) => {
            return rowParams.data.dataKey === ADJUSTED_APS;
          },
        }}
        domLayout={'autoHeight'}
      />
    );
  }
}
