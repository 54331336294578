import { isNil } from 'lodash';
import { FabType } from 'src/components/higherOrder/withFab';
import { ASSORTMENT, TOP_DOWN } from 'src/utils/Domain/Constants';
import { z } from 'zod';
import {
  AssortmentMatrixDetailProps,
  BaseDefnsComponentProps,
  BaseMultiDefnsComponentProps,
  TargetListComponentProps,
} from './confdefnComponentProps';
import { Perspectives } from './literals';

export const HasExceptionsMultiSelect = z.object({ allowExceptionMultiSelect: z.boolean().optional() });
export const HasSubheaderDownloadLink = z.object({ subheader: z.object({ downloadLink: z.string() }).optional() });
export const HasTopMembers = z.object({ topMembers: z.string().optional() });
// TODO: `HasWorklist` needs migration to be removed from code base
/** @deprecated This will be removed in favor of the Adornments viewdefn property. It remains for backward compatibility only */
export const HasWorklist = z.object({ allowWorklistFunctionality: z.boolean().optional() });
// TODO: should really be called HasStylePane with a prop of showStylePane to be more clear
export const HasPopover = z.object({ showPopover: z.boolean().optional() });
export const HasFab = z.object({ fabType: z.nativeEnum(FabType).optional(), fabTooltip: z.string().optional() });
export const HasRangeSelector = z.object({ showRangeSelector: z.boolean().optional() });
export const HasTitle = z.object({
  title: z.string().optional(),
  hideTitle: z.boolean().optional(),
});
export const HasCountLimit = z.object({ showCountLimit: z.boolean().optional() });
export const HasHideableCompanion = z.object({ hideCompanion: z.boolean().optional() });
export const HasFlowStatus = z.object({
  showFlowStatus: z
    .boolean()
    .optional()
    .default(true),
});
export const HasUndoButton = z.object({
  showUndoBtn: z
    .boolean()
    .optional()
    .default(false),
});
export const HasSubheaderErrorText = z.object({ subheaderErrorText: z.string().optional() });
export const HasConfigure = z.object({ showConfigure: z.boolean().optional() });
export const HasFullHeight = z.object({ fullHeight: z.boolean().optional() });
export const HasSummaries = z.object({ hideSummaries: z.boolean().optional() });

export const hasBaseDefns = (
  props: Record<string, any>
): props is { componentProps: z.infer<typeof BaseDefnsComponentProps> } => {
  return 'defns' in props.componentProps && 'model' in props.componentProps.defns;
};

export const hasTargetListType = (
  props: Record<string, any>
): props is { componentProps: z.infer<typeof TargetListComponentProps> } => {
  return 'type' in props.componentProps;
};

export const hasBaseMultiDefns = (
  props: Record<string, any>
): props is { componentProps: z.infer<typeof BaseMultiDefnsComponentProps> } => {
  return 'defns' in props.componentProps && 'models' in props.componentProps.defns;
};

export const hasApiDefns = (
  props: Record<string, any>
): props is { componentProps: z.infer<typeof AssortmentMatrixDetailProps> } => {
  return (
    'dataApi' in props.componentProps && 'configApi' in props.componentProps && 'planningApi' in props.componentProps
  );
};

export const IdentifierProps = z.object({
  keys: z.object({
    idProp: z.string(),
    descProp: z.optional(z.string()),
    styleId: z.optional(z.string()),
    leafIdProp: z.optional(z.string()),
  }),
});

export const OptionalIdentifierProps = IdentifierProps.partial();

export const GroupingInfo = z.object({
  groupingInfo: z.object({
    dataIndex: z.string(),
    staticColumns: z.array(z.string()),
  }),
});

/** ConfigApi */

const ConfigApiParams = z.object({
  appName: z.union([z.literal(ASSORTMENT), z.literal(TOP_DOWN)]),
  defnId: z.string().optional(),
  aggBy: z.string().optional(),
});

export const ConfigApiZod = z.object({
  url: z.string(),
  params: ConfigApiParams,
});

/** DataApi */

// TODO: These definitely need some...renaming
export const DataApiConfigZod = z.object({
  url: z.string(),
  params: z.optional(z.optional(z.record(z.string()))),
  headers: z.optional(z.optional(z.record(z.string()))),
  isListData: z.optional(z.nullable(z.literal(false))),
});

export const RouteToLocationConfigZod = z.object({
  url: z.string(),
  params: z.object({
    facet: z.string(),
    product: z.string(),
    location: z.string(),
    time: z.string(),
    prodlife: z.string(),
  }),
});

export const ListDataApiZod = z.object({
  defnId: z.string(),
  isListData: z.literal(true),
  params: z
    .object({
      /** The default bottom level to potentially be appended to existing aggBys from the view */
      aggBy: z.string(),
      /** If present, the default top level to potentially be prepended to existing aggBys from the view*/
      topAggBy: z.string().optional(),
      nestData: z.boolean().optional(),
      ignoreAncestors: z.boolean().optional(),
    })
    // in this case, allow any extra params to pass through,
    // so that we support arbitrary params being passed down and through to the pivot
    .catchall(z.any()),
});

export const DataApiZod = z.union([DataApiConfigZod, ListDataApiZod]);

export type DataApiConfig = z.infer<typeof DataApiConfigZod>;
export type ListDataConfig = z.infer<typeof ListDataApiZod>;
export type DataApi = z.infer<typeof DataApiZod>;

export const isListDataApi = (dataApi: DataApi | undefined): dataApi is ListDataConfig => {
  return !isNil(dataApi) && 'isListData' in dataApi && dataApi.isListData === true;
};

// component property is merged in at the component level for a more narrow type (see confdefnComponents.ts)
export const BaseSectionView = z.object({
  id: z.string(),
  name: z.string(),
  icon: z.string(),
  pathSlot: z.string(),
  inPerspectives: z.array(Perspectives),
  disabled: z.boolean().optional(),
  hidden: z.boolean().optional(),
  overflow: z.string().optional(),
});
