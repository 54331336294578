import React, { Component } from 'react';
import { noop } from 'lodash';

import StandardCardView from 'src/components/StandardCardView/StandardCardView';
import { initialGroupBy, initialSortBy } from 'src/components/Subheader/Subheader.slice';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import { ViewSimilarItemsCardsViewProps } from './ViewSimilarItems.types';

import styles from 'src/pages/AssortmentBuild/StyleEdit/ViewSimilarItems/ViewSimiliarItemsModal.styles';
import { FabType } from 'src/components/higherOrder/withFab';
import { ViewDataState } from 'src/types/Domain';

export class ViewsimilarItemsCardsView extends Component<ViewSimilarItemsCardsViewProps> {
  render() {
    const { cardType, configLoaded, dataLoaded, configs, cardData, onToggleItem } = this.props;

    return (
      <StandardCardView
        className={styles.viewSimilarCardContainerOverrides}
        ignoreSubheader={true}
        groupedStyles={cardData}
        config={configs?.viewDefn || ({} as TenantConfigViewData)}
        identityPropsConfig={configs?.identityPropsConfig || ({} as IdentityPropsConfig)}
        loaded={configLoaded && dataLoaded}
        cardType={cardType}
        onItemClicked={onToggleItem as () => void}
        // following props are not required for view but to compile only
        onShowView={noop}
        summary={[]} // TODO: hope this is not needed in this view
        title={''}
        showStylePane={noop}
        sortBy={initialSortBy}
        groupBy={initialGroupBy}
        unmodifiedViewDefn={{
          $id: '',
          view: [],
        }}
        defns={{ model: '', subheader: {}, view: [] }}
        subheaderViewDefns={{}}
        fabType={FabType.none}
        isFabDisabled={false}
        onFabClick={noop}
        filteredStyles={[]}
        subheader={{ downloadLink: '' }}
        showPopover={false}
        showFlowStatus={false}
        viewDataState={ViewDataState.idle}
        adornments={[]}
      />
    );
  }
}
