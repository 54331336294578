import { AgGridColumn } from 'ag-grid-react';
import { flow, partial, isNumber, isNil } from 'lodash';
import { map, reduce, set } from 'lodash/fp';
import * as math from 'mathjs';
import * as React from 'react';
import Renderer from 'src/utils/Domain/Renderer';
import coalesce from 'src/utils/Functions/Coalesce';

import * as TargetSettingStyles from './TargetSetting.styles';
import { TargetSettingConfigColumn, TrueColDef } from './TargetSetting.types';
import { ValueParserParams } from 'ag-grid-community';

function applyMath(func: (...a: math.MathType[]) => number, checkNaN: boolean, values: number[]) {
  if (values == null || values.length < 1) {
    return 0;
  } else {
    return func.apply(
      math,
      values.map((v) => {
        if (v == null || (checkNaN && isNaN(v))) {
          return 0;
        } else {
          return v;
        }
      })
    );
  }
}

export function createColumn(columnInfo: TargetSettingConfigColumn) {
  const options: TrueColDef = {
    headerName: columnInfo.text,
    colId: columnInfo.dataIndex,
    pinned: columnInfo.pinned,
    children: [],
  };
  if (columnInfo.editable === true) {
    options.editable = true;
    options.cellClass = [TargetSettingStyles.editableColumn];
  }
  if (columnInfo.calculation != null) {
    const calculation = columnInfo.calculation;
    options.valueGetter = (params) => {
      const calc = math.parse(calculation);
      if (params.data == null) {
        return '';
      }
      const vars = flow(
        () => calc.filter((node) => node.isSymbolNode),
        map((node) => node.name),
        reduce((acc, id = '') => {
          // using getValue ensures we get up to date version
          return set(id, coalesce(params.getValue(id), params.data[id], '0'), acc);
        }, {})
      )();
      return math.eval(calculation, vars);
    };
  } else {
    options.field = columnInfo.dataIndex;
  }
  if (columnInfo.aggregatorFunction) {
    options.aggFunc = `${columnInfo.aggregatorFunction}`;
  } else if (columnInfo.aggregator) {
    options.aggFunc = columnInfo.aggregator;
  } else if (columnInfo.dataIndex === 'name') {
    options.footerValueGetter = () => 'Total';
  }

  if (columnInfo.renderer) {
    options.valueFormatter = (params) => {
      if (columnInfo.renderer != null) {
        if (Renderer[columnInfo.renderer]) {
          return Renderer[columnInfo.renderer](params.value);
        }
      }
      return params.value;
    };
  }
  return <AgGridColumn {...options} key={columnInfo.dataIndex} />;
}

export const aggFuncs = {
  totalName: function() {
    return 'Summary';
  },
  // @ts-ignore
  sum: partial(applyMath, math.sum, true),
  // @ts-ignore
  add: partial(applyMath, math.sum, true),
  min: partial(applyMath, math.min, true),
  max: partial(applyMath, math.max, true),
  avg: partial(applyMath, math.mean, true),
};

export function isInvalidNumber(val: unknown) {
  return (isNumber(val) && (isNaN(val) || !isFinite(val))) || isNil(val);
}

export function percentParser(params: ValueParserParams) {
  // percents are entered as integers, but stored as regular floats
  return Number(params.newValue) / 100;
}
