import RestoreSession from './RestoreSession';
import { connect } from 'react-redux';
import { setActiveTab, setActivePerspective } from 'src/pages/NavigationShell/NavigationShell.slice';
import { asyncGetPlanDates } from '../ScopeSelector/ScopeSelector.actions';
import { receiveFilterState, updateSelectionOverrides } from '../FilterPanel/FilterPanel.slice';
import { Scope, FilterGroup } from 'src/types/Scope';
import { SelectionOverride } from 'src/common-ui/components/Filters/Filters';
import { withRouter, RouteComponentProps } from 'react-router';
import { BoundPerspective } from 'src/services/configuration/bindings.types';
import { AppThunkDispatch } from 'src/store';
import { receiveScopeRefreshTrigger, requestScope } from '../ScopeSelector/ScopeSelector.slice';

type RestoreSessionDispatchProps = {
  setPerspective(perspective: BoundPerspective): void;
  setActiveTab(tab: string): void;
  requestScope(): void;
  receiveScope(scope: Scope): void;
  receiveFilters(filters: FilterGroup[]): void;
  setFilterSelections(filters: SelectionOverride[]): void;
  setTab(tab: string): void;
};

export type RestoreSessionState = {
  loading: boolean;
};

export type RestoreSessionProps = { hideLoading?: boolean } & RestoreSessionDispatchProps &
  RouteComponentProps<Record<string, string>>;

function mapDispatchToProps(dispatch: AppThunkDispatch): RestoreSessionDispatchProps {
  return {
    setPerspective(perspective: BoundPerspective) {
      dispatch(setActivePerspective(perspective));
    },
    setActiveTab(tab: string) {
      dispatch(setActiveTab(tab));
    },
    requestScope() {
      dispatch(requestScope());
    },
    receiveScope(scope: Scope) {
      const scopeObj = { scope: scope, updatedAt: Date.now() };
      dispatch(asyncGetPlanDates());
      dispatch(receiveScopeRefreshTrigger(scopeObj));
    },
    receiveFilters(filters: FilterGroup[]) {
      dispatch(receiveFilterState({ filters: filters, updatedAt: Date.now() }));
    },
    setFilterSelections(filters: SelectionOverride[]) {
      dispatch(updateSelectionOverrides(filters));
    },
    setTab(tab: string) {
      dispatch(setActiveTab(tab));
    },
  };
}
export default withRouter(connect(null, mapDispatchToProps)(RestoreSession));
