import { style, classes } from 'typestyle';

export const itemBase = (selected: boolean) =>
  style({
    width: 50,
    height: 50,
    cursor: 'pointer',
    boxShadow: selected ? '0 0 0 0.2rem rgba(69,182,159,.5)' : '',
  });

export const missingSwatchItem = (selected: boolean) => classes('fas fa-camera', itemBase(selected));

export const colorSwatchItem = (swatchUrl: string, selected = false) =>
  classes(
    style({
      background: `url(${encodeURI(swatchUrl)})no-repeat`,
      backgroundSize: 'cover',
      boxShadow: selected ? '0 0 0 0.2rem rgba(69,182,159,.5)' : '',
    }),
    itemBase(selected)
  );
