import * as React from 'react';
import { Checkbox } from '@material-ui/core';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
type Props = ICellRendererParams & {
  value: boolean;
  onChange?: (node: RowNode, checked: boolean) => void;
  isEditable: boolean;
};

type State = {
  checked: boolean;
};

export default class CheckboxCellRenderer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      checked: props.value ? true : false,
    };
  }

  componentWillUnmount() {
    // Any destruction calls need to be done here, not in destroy()
  }

  onClick = (event: React.MouseEvent<HTMLElement>) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.node, (event.target as HTMLInputElement).checked);
    }
    if (this.props.setValue) {
      this.props.setValue(!this.props.value);
    }
  };

  isPopup() {
    return false;
  }

  render() {
    const { isEditable, value } = this.props;
    const trulyNotEditable = isEditable === false;
    return (
      <Checkbox
        checked={value}
        style={{
          transform: 'scale(.75)',
          padding: 0,
          color: '#04a79b',
        }}
        icon={<RadioButtonUncheckedIcon />}
        checkedIcon={<CheckCircleOutlineIcon />}
        disabled={trulyNotEditable}
        disableRipple={trulyNotEditable}
        onClick={this.onClick}
      />
    );
  }
}
