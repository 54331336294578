import { connect, Dispatch } from 'react-redux';
import { defaultTo, flow, get } from 'lodash';

import PlanogramSimple from './PlanogramSimple';
import container from 'src/ServiceContainer';
import { AppState } from 'src/store';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { DIMENSION_PRODUCT, ASSORTMENT } from 'src/utils/Domain/Constants';
import { GroupBySlice, SortBySlice } from 'src/components/Subheader/Subheader.slice';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import {
  cleanUp,
  receiveTenantConfig,
  receiveError,
  requestTenantConfig,
  setGroupingInfo,
} from 'src/pages/Hindsighting/StyleColorReview/TopTYvsLY/TopTYvsLY.slice';
import { getProcessedData, getSummary } from 'src/pages/Hindsighting/StyleColorReview/TopTYvsLY/TopTYvsLY.selectors';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { Summary } from 'src/utils/Pivot/RollUp';
import { withFab, FabType } from 'src/components/higherOrder/withFab';
import { updateFlowStatus } from 'src/components/Subheader/Subheader.slice';
import { createSelector } from 'reselect';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { processAdornments } from 'src/components/Adornments/Adornments';

export function generateGroupBy(dataIndex: string): GroupBySlice {
  return {
    selection: 0,
    defaultSelection: 0,
    options: [
      {
        text: 'empty',
        dataIndex,
        dimension: DIMENSION_PRODUCT,
      },
    ],
  };
}

type AsstPropInfoType = {
  field: string;
  value: string;
};

const getActSortBy = createSelector(
  (state: AppState) => state.subheader.sortBy,
  (sortBy: SortBySlice) => {
    let actSortBy;
    const { selection, defaultSelection, options, direction } = sortBy;
    if (options.length > 0) {
      actSortBy = {
        selection: options[defaultTo(selection, defaultSelection)],
        direction: direction,
      };
    }
    return actSortBy;
  }
);

function mapStateToProps(state: AppState, ownProps: any) {
  const { topTYvsLY } = state.pages.hindsighting.styleColorReview;
  const { descProp, keys, viewDefns, groupingInfo, fabTooltip } = ownProps.conf;
  const groupedStyles = getProcessedData(state);
  const groupBy = generateGroupBy(groupingInfo.dataIndex);
  let summary: Summary[] = [];
  if (ownProps.conf.showSummaryRollup === true) {
    summary = getSummary(state);
  }
  const asstPropInfo: AsstPropInfoType = ownProps.conf.asstPropInfo || {
    field: '__DUMMY__',
    value: '__DUMMY__',
  };
  const loaded = !topTYvsLY.tenantConfigLoading;
  const currentAdornments: AdornmentType[] = get(topTYvsLY.viewDefn, 'adornments', []);
  const adornments = processAdornments(currentAdornments);

  return {
    title: ownProps.conf.title,
    loaded,
    config: topTYvsLY.viewDefn,
    sortBy: getActSortBy(state),
    groupBy,
    requiredViewDefns: viewDefns,
    groupedStyles,
    summary: summary, // Required for view component
    idProp: keys.idProp,
    descProp,
    currentTab: state.perspective.activeTab,
    unmodifiedViewDefn: topTYvsLY.unmodifiedViewDefn,
    fabType: ownProps.fabType || FabType.none,
    fabTooltip,
    isFabDisabled: false,
    calcViewDefn: topTYvsLY.calcViewDefn,
    asstPropInfo,
    modelDefn: ownProps.conf.modelDefn,
    viewDefn: topTYvsLY,
    subheader: state.subheader,
    adornments,
  };
}

// FIXME: create confdefn validator for this component and generate ownProps type from it
function dispatchToProps<S>(dispatch: Dispatch<S>, ownProps: any) {
  const { tenantConfigClient } = container;
  const { defnIds, groupingInfo } = ownProps.conf;

  return {
    fetchConfig() {
      dispatch(updateFlowStatus([]));
      dispatch(requestTenantConfig());
      dispatch(setGroupingInfo(groupingInfo));
      tenantConfigClient
        .getTenantViewDefns({
          defnIds,
          appName: ASSORTMENT,
        })
        .then((resp) => {
          const unmodifiedViewDefn = resp[0];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defnIds[0],
            (resp as any)[defnIds.length],
            dispatch,
            unmodifiedViewDefn
          );
          if (localConfig && localConfig.config) {
            resp[0] = localConfig.config;
          }
          dispatch(
            receiveTenantConfig({
              viewDefn: resp[0],
              calcViewDefn: resp[1],
              unmodifiedViewDefn,
            })
          );
        })
        .catch((error) =>
          dispatch(
            receiveError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.topTyLy,
              stack: (error as Error)?.stack,
              issues: error,
            })
          )
        );
    },
    onDestroy() {
      dispatch(cleanUp());
    },
    showStylePane(_item: ContainerPayload) {
      // do nothing
    },
    onItemClicked(item: ContainerPayload) {
      dispatch(setRightContainerPayload(item));
    },
    onUpdate() {
      // do nothing
    },
    onConfigUpdate(config: TenantConfigViewData) {
      dispatch(
        receiveTenantConfig({
          viewDefn: config,
        })
      );
    },
  };
}
export type DispatchProps = ReturnType<typeof dispatchToProps>;
export type StateProps = ReturnType<typeof mapStateToProps>;

const wrappedView = flow(() => PlanogramSimple, withFab, makePrintSensitive, makeScopeAndFilterSensitive)();

export default connect(mapStateToProps, dispatchToProps)(wrappedView);
