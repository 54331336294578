import { setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RightContainerPayloadType } from 'src/components/RightContainer/RightContainer';

export interface HistoryPayload {
  type: RightContainerPayloadType.History;
  id: string;
  parentId: string;
  styleColorName?: string;
  isAssortmentBuild?: boolean;
}
export interface AssortmentPayload {
  type: RightContainerPayloadType.Assortment;
  id: string;
  parentId: string;
  styleColorName?: string;
  isAssortmentBuild: boolean;
}
export interface FilterPayload {
  type: RightContainerPayloadType.Filter;
}
export interface PlanQueuePayload {
  type: RightContainerPayloadType.PlanQueue;
  id: string;
}
export interface UndoPayload {
  type: RightContainerPayloadType.Undo;
}

export type ContainerPayload = HistoryPayload | AssortmentPayload | FilterPayload | PlanQueuePayload | UndoPayload;
export type RightContainerSlice = {
  payload?: ContainerPayload;
  open: boolean;
};

const initialRightContainerState: RightContainerSlice = {
  open: false,
};

const rightContainerReducer = createSlice({
  name: 'RightContainer',
  initialState: initialRightContainerState,
  reducers: {
    setRightContainerPayload: (state, action: PayloadAction<ContainerPayload>) => {
      state.payload = action.payload;
      state.open = true;
    },
    clearAndCloseRightContainer: (state) => {
      state.open = false;
      state.payload = undefined;
    },
    cleanUpRightContainer: () => {
      return initialRightContainerState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActivePage, (state) => {
      state.open = false;
      state.payload = undefined;
    });
  },
});

export const {
  setRightContainerPayload,
  clearAndCloseRightContainer,
  cleanUpRightContainer,
} = rightContainerReducer.actions;

export default rightContainerReducer.reducer;
