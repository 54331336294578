import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { SortOption } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { pick } from 'lodash';

export type ParseResult = {
  defaultSort: SortOption;
  sortOptions: SortOption[];
};

export function parseSortConfig(sortConfig: TenantConfigViewData): ParseResult {
  let defaultKey: string;
  if (sortConfig.defaults) {
    defaultKey = sortConfig.defaults!.dataIndex;
  } else {
    defaultKey = sortConfig.view[0].dataIndex;
  }
  const sortOptions = sortConfig.view!.map((option) => pick(option, 'dataIndex', 'text'));
  const defaultSort = sortOptions.find((option) => option.dataIndex === defaultKey)!;

  return {
    defaultSort,
    sortOptions,
  };
}
