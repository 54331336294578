import { AnyAction, combineReducers, Reducer } from 'redux';
import hindsighting from './Hindsighting/Hindsighting.reducer';
import reporting from 'src/pages/Reporting/Reporting.slice';
import { TargetSettingReduxSlice } from './AssortmentStrategy/TargetSetting/TargetSetting/TargetSetting.types';
import { targetSettingReducer } from './AssortmentStrategy/TargetSetting/TargetSetting/TargetSetting.reducer';
import {
  TargetListReduxSlice,
  createEmptyTargetList,
} from './AssortmentStrategy/TargetSetting/TargetList/TargetList.types';
import assortmentBuild from './AssortmentBuild/AssortmentBuild.reducer';
import allocation from './Allocation/Allocation.reducer';

import { isNil } from 'lodash';

export type AssortmentStrategySlice = {
  targetSetting: TargetSettingReduxSlice;
  targetList: TargetListReduxSlice;
};

const pagesCombined = combineReducers({
  hindsighting,
  reporting,
  assortmentStrategy: combineReducers({
    targetSetting: targetSettingReducer,
    targetList: (tlr?: TargetListReduxSlice) => {
      // this is here because targetList does direct lens updates on the slice,
      // which needs a non-null starting point
      if (isNil(tlr)) {
        return createEmptyTargetList();
      }
      return tlr;
    },
  }),
  assortmentBuild,
  allocation,
});
export type PagesSlice = ReturnType<typeof pagesCombined>;

export const resetPages = () => {
  return {
    type: 'Pages/resetPages',
  };
};

const pagesReducer: Reducer<PagesSlice, AnyAction> = (state: PagesSlice | undefined, action: AnyAction) => {
  switch (action.type) {
    case 'Pages/resetPages':
      // When receiving the action 'Pages/resetPages', we send in undef as the state to be reduced,
      // which causes all the child slices to return their initial state.
      // We use this to clear state of views after leaving them, so that they're in initial state
      // when navigating to them.
      return pagesCombined(undefined, action);
    default:
      return pagesCombined(state, action);
  }
};

export default pagesReducer;
