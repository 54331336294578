import { connect } from 'react-redux';
import { Lens, fromTraversable, Prism } from 'monocle-ts';
import { Traversable } from 'fp-ts/lib/Array';
import { isNil, isEmpty, pick, flow, get } from 'lodash';
import queryString from 'query-string';

import container from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { update } from 'src/services/lenses/Lenses.actions';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { updateSearch, updateFlowStatus } from 'src/components/Subheader/Subheader.slice';
import { styleEditLens } from 'src/services/lenses/lenses';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import StyleEdit from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit';
import {
  requestStyleEditConfigs,
  receiveStyleEditConfigs,
  cleanupStyleEdit,
  receiveError,
  fetchStyleEditData,
  StyleEditSlice,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.slice';
import {
  StyleEditOwnProps,
  StyleEditValueProps,
  StyleEditConfig,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { getProcessedData } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.selectors';
import { FabType, withFab } from 'src/components/higherOrder/withFab';
import { dispatchUpdateAssortmentPlan } from '../StyleEdit/StyleEdit.client';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { RollupDefn } from 'src/services/configuration/codecs/viewdefns/general';
import { StyleEditViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { processAdornments } from 'src/components/Adornments/Adornments';

function mapStateToProps(state: AppState, ownProps: StyleEditOwnProps): StyleEditValueProps {
  const { title = 'Style Details View', fabType } = ownProps;
  const viewState = styleEditLens.get(state);
  const subheader = state.subheader;
  const { filteredStyles, summary, searchKeys, viewDefn } = getProcessedData(state);
  // check for redirect search parameters, if applicable, to set the selected item's index
  let redirectSelection: BasicPivotItem | undefined;
  if (!isNil(ownProps.location) && !isEmpty(ownProps.location.search)) {
    const query = queryString.parse(ownProps.location.search);

    redirectSelection = filteredStyles.find((style) => {
      return style.id === query.selectedStyleId;
    });
  }

  // if viewDefns haven't loaded, onShowView hasn't been triggered yet
  const isLoading = viewState.isConfigLoading || !isDataLoaded(viewState.viewDataState);
  const currentAdornments: AdornmentType[] = get(viewDefn, 'adornments', []);
  const adornments = processAdornments(currentAdornments);

  return {
    title,
    isPrintMode: state.print.isPrintMode,
    isLoading,
    config: viewDefn,
    adornments,
    companionFilters: pick(subheader, ['search', 'flowStatus']),
    previewFilters: pick(subheader, ['altSearch', 'altFlowStatus', 'sortBy']),
    flowStatusOptions: state.appConfig.tenantConfig.flowStatus,
    summary,
    styles: filteredStyles,
    redirectSelection,
    searchKeys,
    viewDataState: viewState.viewDataState,
    fabType: fabType || FabType.none,
  };
}

export function dispatchToProps(dispatch: AppThunkDispatch, ownProps: StyleEditOwnProps) {
  const { defns } = ownProps;

  return {
    async onShowView() {
      dispatch(requestStyleEditConfigs());

      try {
        const configs = await container.tenantConfigClient.getTenantViewDefns<StyleEditConfig & TenantConfigViewData>({
          defnIds: defns.view,
          appName: ASSORTMENT,
          validationSchemas: [StyleEditViewDefn, RollupDefn],
        });

        const [viewDefn, rollup] = configs;
        dispatch(receiveStyleEditConfigs({ viewDefn, rollup }));
      } catch (error) {
        dispatch(
          receiveError({
            type: ComponentErrorType.config,
            message: (error as Error)?.message,
            name: ConfDefnComponentType.styleEdit,
            issues: error
          })
        );
      }

      // retrieve companion list data
      dispatch(fetchStyleEditData(defns.model));
    },
    onDestroy() {
      dispatch(cleanupStyleEdit());
    },
    onUpdate(styleId: string, propToUpdate: string, propValue: string) {
      const updated = styleEditLens
        // TODO: we have to be smarter about this with caching coming in.
        // If edit occurs while in cacheData, liveData overwrites, leading
        // to "stale" name as name was changed after request but before response.
        // Potential solve: "delay" dispatch until viewDataState === 'liveDataLoaded'
        .compose(Lens.fromPath<StyleEditSlice>()(['liveData', 'tree']))
        .composeTraversal(fromTraversable(Traversable)())
        .composePrism(Prism.fromPredicate((it) => it.id === styleId))
        .modify((item: BasicPivotItem) => {
          return {
            ...item,
            [propToUpdate]: propValue,
          };
        });

      dispatch(update(updated, 'Style Item Updated'));
    },
    onSearchChange(search: string) {
      dispatch(updateSearch(search));
    },
    onFilterChange(values: number[]) {
      dispatch(updateFlowStatus(values));
    },
    updateAssortmentPlan: () => {
      dispatchUpdateAssortmentPlan(dispatch);
    },
    onError(error: any) {
      dispatch(
        receiveError({
          type: ComponentErrorType.config,
          message: (error as Error)?.message,
          name: ConfDefnComponentType.styleEdit,
        })
      );
    },
  };
}

const sensitiveView = flow(() => StyleEdit, withFab, makePrintSensitive, makeScopeAndFilterSensitive)();

export default connect(mapStateToProps, dispatchToProps)(sensitiveView);
