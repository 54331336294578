import { style } from 'typestyle';
import {
  primaryTextColor,
  mediumBorderColor,
  faintTextColor,
  mediumBreakpoint,
  smallBreakpoint,
} from 'src/utils/Style/Theme';
import { SUBHEADER_HEIGHT } from 'src/components/Subheader/Subheader.styles';
import { rangePx } from 'src/utils/Style/Media';

const keyFinancialHeight = 140;
const choiceProductivityHeight = 180;
const headerHeight = 25;
const BOTTOM_MARGIN = 1;

export const keyFinancialStyle = style({
  backgroundColor: '#fafafa',
  color: faintTextColor,
  height: 85,
  marginTop: 14,
  width: '25%',
  minWidth: 260,
  $nest: {
    [rangePx(0, mediumBreakpoint)]: {
      width: '50%',
    },
    '& + .key-financial-item': {
      borderLeft: '1px solid ' + mediumBorderColor,
    },
    '> header': {
      padding: '10px 0 0 35px',
      fontSize: '0.7rem',
    },
    '.right-container': {
      paddingRight: 35,
    },
    '.primary': {
      paddingLeft: 35,
      fontSize: '1.7rem',
    },
  },
});

export const choiceProductivityStyle = style({
  width: '33.333%',
  height: '50%',
  paddingLeft: 10,
  $nest: {
    [rangePx(0, smallBreakpoint)]: {
      width: '100%',
    },
    [rangePx(smallBreakpoint, mediumBreakpoint)]: {
      width: '50%',
    },
    '.left-container': {
      fontSize: '0.7rem',
      width: '8rem',
    },
    '.middle-container': {
      fontSize: '0.7rem',
      minWidth: 65,
      minHeight: 65,
    },
  },
});

export function categorySummaryStyle(isKeyFinancialOpen: boolean, isChoiceProductivityOpen: boolean) {
  let offset = SUBHEADER_HEIGHT;

  if (isKeyFinancialOpen) {
    offset += keyFinancialHeight;
  } else {
    offset += headerHeight;
  }

  if (isChoiceProductivityOpen) {
    offset += choiceProductivityHeight;
  } else {
    offset += headerHeight;
  }

  return style({
    color: primaryTextColor,
    height: '100%',
    $nest: {
      '.toggle': {
        cursor: 'pointer',
        height: headerHeight,
        fontSize: '0.9rem',
        $nest: {
          '> i': {
            marginRight: '0.5rem',
          },
        },
      },
      '.category-summary-container': {
        height: '100%',
      },
      '.no-select': {
        cursor: 'initial',
      },
      section: {
        marginBottom: `${BOTTOM_MARGIN}rem`,
      },
      '.key-financials': {
        flexDirection: 'column',
        height: isKeyFinancialOpen ? keyFinancialHeight : headerHeight,
        maxWidth: '100vw',
        $nest: {
          '.items': {
            backgroundColor: '#fAfAfA',
            flexWrap: 'wrap',
            height: 'calc(100% - 30px)',
            overflowY: 'auto',
            display: 'flex',
            $nest: {
              '&.collapsed': {
                display: 'none',
              },
            },
          },
        },
      },
      '.choice-productivity-group': {
        height: isChoiceProductivityOpen ? choiceProductivityHeight : headerHeight,
        maxWidth: '100vw',
        display: 'flex',
        flexDirection: 'column',
        $nest: {
          '&.print': {
            height: 'auto',
          },
          '.items.collapsed': {
            display: 'none',
          },
          '.items': {
            height: `calc(100% - ${headerHeight}px)`,
            display: 'flex',
            flexWrap: 'wrap',
          },
          '.fin-wrapper': {
            display: 'flex',
            height: '100%',
            width: '20%',
            alignItems: 'center',
            minWidth: 220,
            $nest: {
              header: {
                padding: '0 0 10px 35px',
                fontSize: '0.7rem',
                fontWeight: 'unset',
              },
              '.primary': {
                color: primaryTextColor,
                paddingLeft: 40,
                flex: 'none',
              },
              '.key-financial-item': {
                width: '100%',
              },
              '.right-container': {
                paddingLeft: 20,
              },
            },
          },
          '.choice-wrapper': {
            flex: 1,
            display: 'flex',
            flexWrap: 'wrap',
            overflow: 'auto',
          },
          '.key-financial-item': {
            backgroundColor: 'white',
          },
        },
      },
      '.grid-container': {
        height: `calc(100% - calc(${offset}px + ${2 * BOTTOM_MARGIN}rem))`,
        $nest: {
          '&.print': {
            height: 'unset',
            overflowX: 'auto',
          },
          '.data-grid': {
            height: `calc(100% - ${headerHeight}px)`,
            fontSize: '0.8rem',
          },
        },
      },
    },
  });
}
