import { connect, Dispatch } from 'react-redux';
import { ValueProps, FunctionProps } from './StoreGroupChart';
import container from 'src/ServiceContainer';
import { AnyAction as BaseAction } from 'redux';
import {
  cleanUp,
  requestAllData,
  receiveAllData,
  requestTenantConfig,
  receiveTenantConfig,
  receiveDataError,
} from './StoreGroupChart.slice';
import serviceContainer from 'src/ServiceContainer';
import { ASSORTMENT, ASSORTMENT_ANALYSIS_PATH } from '../../../../utils/Domain/Constants';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import StoreGroupChart from './StoreGroupChart';
import { mapValues } from 'lodash';
import { AppState, AppThunkDispatch } from 'src/store';

function getNameSpace() {
  const topLev = window.location.hash.indexOf(ASSORTMENT_ANALYSIS_PATH) >= 0 ? 'AssortmentAnalysis' : 'History';
  return `${topLev}`;
}

function mapStateToProps(state: AppState): ValueProps {
  const { storeGroupChart } = state.pages.hindsighting;

  let title = '';
  let img = '';
  let itemId = '';
  if (storeGroupChart.selectedItem != null) {
    title = `${storeGroupChart.selectedItem.name} - ${storeGroupChart.selectedItem.description}`;
    img = storeGroupChart.selectedItem['attribute:img:id'];
    itemId = storeGroupChart.selectedItem.id;
  }

  return {
    itemId,
    view: storeGroupChart.configLoaded
      ? storeGroupChart.viewDefn.view[1]
      : ({ dataIndex: '', text: '', view: [] } as TenantConfigViewItem),
    tlData: storeGroupChart.allData,
    title,
    isLoading: !storeGroupChart.allDataLoaded || !storeGroupChart.configLoaded,
    img,
  };
}

function asyncGetData() {
  const service = serviceContainer.pivotService;
  return (dispatch: Dispatch<AppState>, getState: () => AppState): Promise<BaseAction> | void => {
    const namespace = getNameSpace();
    const DEFNS = {
      view: 'PerformanceStoreGroupAnalysis',
      listData: 'ByStore',
    };
    const nsDEFNS = mapValues(DEFNS, (defnPath) => {
      return `${namespace}${defnPath}`;
    });
    const { selectedItem, viewDefn } = getState().pages.hindsighting.storeGroupChart;
    const graphAggs: TenantConfigViewItem[] | undefined = viewDefn.view[0].view;
    if (selectedItem != null && graphAggs != null) {
      dispatch(requestAllData());
      return Promise.all(
        graphAggs.map((item) => {
          return service
            .listData(nsDEFNS.listData, ASSORTMENT, {
              aggBy: item.dataIndex,
              topMembers: selectedItem.id,
            })
            .then((resp) => ({
              title: item.text,
              data: resp.tree,
            }));
        })
      )
        .then((all) => dispatch(receiveAllData(all)))
        .catch((_error) => dispatch(receiveDataError()));
    }
  };
}

function dispatchToProps(dispatch: AppThunkDispatch): FunctionProps {
  const { tenantConfigClient } = container;
  const namespace = getNameSpace();
  const DEFNS = {
    view: 'PerformanceStoreGroupAnalysis',
    listData: 'ByStore',
  };
  const nsDEFNS = mapValues(DEFNS, (defnPath) => {
    return `${namespace}${defnPath}`;
  });
  return {
    onShowView() {
      dispatch(requestTenantConfig());
      tenantConfigClient
        .getTenantViewDefns({
          defnIds: [nsDEFNS.view],
          appName: 'Assortment',
        })
        .then((resp) => dispatch(receiveTenantConfig(resp[0])))
        .catch((_error) => dispatch(cleanUp()));
    },
    onDestroy() {
      // doin nuthin'
    },
    onItemUpdate() {
      // doing nuthing
      dispatch(asyncGetData());
    },
  };
}

export default connect(mapStateToProps, dispatchToProps)(StoreGroupChart);
