import React, { ReactElement } from 'react';
import HighchartsReact from 'highcharts-react-official';

import { Overlay, GeoChart } from 'src/common-ui';
import KeyFinancial, {
  Props as KeyFinancialProps,
} from 'src/common-ui/components/Metrics/SimpleMetrics/KeyFinancial/KeyFinancial';
import ChoiceProductivity, {
  Props as ChoicePropductivityProps,
} from 'src/common-ui/components/Metrics/SimpleMetrics/ChoiceProductivity/ChoiceProductivity';

import { StateProjection } from './Summary.selectors';
import SectionHeader from './SectionHeader';
import { SubheaderOwnProps } from 'src/components/Subheader/Subheader.types';
import summaryStyles from './Summary.styles';
import Subheader from 'src/components/Subheader/Subheader.container';
import { MACRO_TRENDS_WARNING_MESSAGE } from 'src/pages/Hindsighting/MacroTrends/MacroTrends';

export type DispatchProps = {
  onShowView(): void;
  onRefetchData(): void;
};

export type Props = StateProjection & DispatchProps;

export type State = {
  isKeyFinancialsOpen: boolean;
  isChoiceProductivityOpen: boolean;
  isMacroTrendAnalysisOpen: boolean;
};

export default class Summary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isKeyFinancialsOpen: true,
      isChoiceProductivityOpen: true,
      isMacroTrendAnalysisOpen: true,
    };

    this.toggleKeyFinancials = this.toggleKeyFinancials.bind(this);
    this.toggleChoiceProductivity = this.toggleChoiceProductivity.bind(this);
    this.toggleMacroTrendAnalysis = this.toggleMacroTrendAnalysis.bind(this);
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentDidUpdate(prevProps: Props, _prevState: State) {
    if (!this.props.loaded || !prevProps.loaded) {
      return;
    }
    const { flowStatus, onRefetchData } = this.props;
    if (prevProps.flowStatus.join('') !== flowStatus.join('')) {
      onRefetchData();
    }
  }

  toggleKeyFinancials() {
    this.setState({
      isKeyFinancialsOpen: !this.state.isKeyFinancialsOpen,
    });
  }

  toggleChoiceProductivity() {
    this.setState({
      isChoiceProductivityOpen: !this.state.isChoiceProductivityOpen,
    });
  }

  toggleMacroTrendAnalysis() {
    this.setState({
      isMacroTrendAnalysisOpen: !this.state.isMacroTrendAnalysisOpen,
    });
  }

  render() {
    let mainContent: ReactElement | null = null;
    const { title } = this.props;
    const { isKeyFinancialsOpen, isChoiceProductivityOpen, isMacroTrendAnalysisOpen } = this.state;

    const subheaderProps: SubheaderOwnProps = {
      title,
      showFlowStatus: true,
      showLookBackPeriod: true,
      errorCondition: MACRO_TRENDS_WARNING_MESSAGE,
    };

    if (this.props.loaded) {
      const {
        keyFinancials,
        choiceProductivity,
        trendAnalysis,
        productMixAndTrend,
        globalRegionVolAndTrend,
      } = this.props;

      const [firstChoice, ...choiceProdItems] = choiceProductivity;

      // .summary-container-fullwidth is only needed while the sections have busted
      // handling of small screen sizes. Fix pls.
      mainContent = (
        <div className={'summary-container'}>
          <div className={'summary-container-fullwidth'}>
            <div className={`key-financials${isKeyFinancialsOpen ? '' : '-collapsed'}`}>
              <SectionHeader
                title="Key Financials"
                handleClick={this.toggleKeyFinancials}
                isOpen={isKeyFinancialsOpen}
              />
              <div className="items">
                {keyFinancials &&
                  keyFinancials.map((item: KeyFinancialProps, i) => {
                    return <KeyFinancial {...item} key={i} />;
                  })}
              </div>
            </div>

            <div className={`choice-productivity-group${isChoiceProductivityOpen ? '' : '-collapsed'}`}>
              <SectionHeader
                title="Choice Productivity"
                handleClick={this.toggleChoiceProductivity}
                isOpen={isChoiceProductivityOpen}
              />
              <div className="items">
                <div className="key-financial-wrapper">
                  <KeyFinancial {...firstChoice} dataLoaded={true} />
                </div>
                <div className="choice-wrapper">
                  {choiceProdItems &&
                    choiceProdItems.map((item: ChoicePropductivityProps, i) => {
                      const mappedMetrics = item.metrics;
                      const label = mappedMetrics[0].label!;
                      mappedMetrics[0].label = label.replace('<br />', '\n');
                      item.metrics = mappedMetrics;
                      return <ChoiceProductivity {...item} key={i} />;
                    })}
                </div>
              </div>
            </div>

            <div className={`macro-trend-analysis__container${isMacroTrendAnalysisOpen ? '' : '-collapsed'}`}>
              <SectionHeader
                title="Macro Trend Analysis"
                handleClick={this.toggleMacroTrendAnalysis}
                isOpen={isMacroTrendAnalysisOpen}
              />
              <div className="macro-trend-analysis__items">
                <div className="col-4">
                  <HighchartsReact options={trendAnalysis} />
                </div>
                <div className="col-4">
                  <HighchartsReact options={productMixAndTrend} />
                </div>
                <div className="col-4">
                  <GeoChart {...globalRegionVolAndTrend} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={'summary ' + summaryStyles}>
        <Overlay type="loading" visible={!this.props.loaded} />
        <Subheader {...subheaderProps} />
        {mainContent}
      </div>
    );
  }
}
