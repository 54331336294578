import * as React from 'react';
import { Member } from 'src/types/Scope';
import styles from './ScopeSelector.styles';
import { classes } from 'typestyle';
import { UNICODE_NO_BREAK_SPACE } from 'src/utils/ApplicationConstants';
import * as dropdownStyles from 'src/utils/Style/Dropdown.styles';
import {
  FormControl,
  InputLabel,
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete, UseAutocompleteProps } from '@material-ui/lab';
import { concat, flatMap, isEmpty, isNil, isString } from 'lodash';
import { rem } from 'csx';

const muistyles = ({}: Theme) => {
  return createStyles({
    disabled: {
      color: '#fff',
    },
  });
};

export type ScopeSelectorDropdownProps = {
  placeholder?: string;
  selection: string | undefined;
  levels: Member[];
  handleChangeOnDropdown: (id: string, level: string) => void;
  invalid?: string;
  locked: boolean;
  qa?: string;
  applyClass?: string;
  selectClass?: string;
};
interface StyleIProps extends WithStyles<typeof muistyles>, ScopeSelectorDropdownProps {}

interface MemberWithPath extends Member {
  path: string[];
  isLeafNode: boolean;
}

function flattenOptions(levels: Member[], treePath: string[] = []): MemberWithPath[] {
  return flatMap(levels, (level) => {
    if (isEmpty(level.children)) {
      return {
        ...level,
        path: treePath,
        isLeafNode: true,
      };
    }

    const currentLevel: MemberWithPath = {
      ...level,
      path: treePath,
      isLeafNode: false,
    };
    const path = concat(treePath, level.name);
    return concat(currentLevel, flattenOptions(level.children, path));
  });
}

export class ScopeSelectorDropdown extends React.Component<StyleIProps> {
  constructor(props: StyleIProps) {
    super(props);
  }

  updateSelectedMetric = (ind: string, level: string) => {
    this.props.handleChangeOnDropdown(ind, level);
  };

  render() {
    const {
      levels = [],
      placeholder = UNICODE_NO_BREAK_SPACE,
      selection,
      locked,
      invalid,
      applyClass,
      qa,
    } = this.props;

    const possiblyInvalidCss = invalid ? styles.isInvalid : undefined;
    const dropdownStyle = applyClass ? applyClass : styles.scopeSelectorDropdown;
    const selectStyle = this.props.selectClass ? this.props.selectClass : dropdownStyles.dropdown;

    const options = flattenOptions(levels);
    const currentValue = options.find((mem) => mem.id === selection) || null;

    const autocompleteProps: UseAutocompleteProps<MemberWithPath, false, false, false> = {
      options: options,
      value: currentValue,
      onChange: (_event: React.ChangeEvent<unknown>, item: MemberWithPath | null) => {
        if (isNil(item)) {
          return;
        }
        if (!isString(item.id)) {
          throw new Error('Bad Select input given');
        }
        if (!isString(item.level)) {
          throw new Error('Level is bad');
        }
        this.updateSelectedMetric(item.id, item.level);
      },
      getOptionLabel: (option) => option.name,
    };

    return (
      <React.Fragment>
        <FormControl
          className={classes(dropdownStyles.form, dropdownStyle, possiblyInvalidCss)}
          data-qa="ScopeSelectorDropdown"
        >
          <InputLabel className={dropdownStyles.autocompleteTitle}>{placeholder}</InputLabel>
          <Autocomplete
            className={selectStyle}
            renderOption={({ path, name, isLeafNode }) => (
              // indentation amount (paddingLeft) is determined by the depth of the path in the tree
              <Typography
                style={{ fontSize: 12, paddingLeft: rem(path.length), fontWeight: isLeafNode ? 'inherit' : 'bold' }}
              >
                {name}
              </Typography>
            )}
            classes={{
              endAdornment: 'endAdornment',
              input: 'no-underline',
              popper: 'scope-select-popper', // styling for this class stored in global.css because the popper lives in a portal
            }}
            disableListWrap={true}
            disableClearable={true}
            disabled={locked || options.length < 1}
            renderInput={(params) => <TextField {...params} variant="standard" />}
            data-qa={qa}
            {...autocompleteProps}
          />
        </FormControl>
      </React.Fragment>
    );
  }
}

export default withStyles(muistyles)(ScopeSelectorDropdown);
