import React from 'react';
import { find, omitBy, cloneDeep, flow, get } from 'lodash';
import fp from 'lodash/fp';
import { Lens } from 'monocle-ts';
import { connect } from 'react-redux';
import { ContainerPayload, setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { RightContainerPayloadType } from 'src/components/RightContainer/RightContainer';
import StandardCardView from 'src/components/StandardCardView/StandardCardView';
import { DispatchProps, ValueProps } from 'src/components/StandardCardView/StandardCardView.types';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { update } from 'src/services/lenses/Lenses.actions';
import { makeCartService } from 'src/pages/AssortmentCart/AssortmentCart.service';
import {
  initialState,
  receiveTenantConfig,
  requestTenantConfig,
  AssortmentAddSlice,
  AssortmentAddSearchDataState,
} from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentAdd.slice';
import container from 'src/ServiceContainer';
import { ASSORTMENT, STYLE_ID, IMG_URI } from 'src/utils/Domain/Constants';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { isAssortmentAddSlice } from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentAdd.slice';
import {
  combineSummaryInputs,
  getGroupedData,
} from 'src/pages/Hindsighting/StyleColorReview/CanvasView/CanvasView.selectors';
import serviceContainer from 'src/ServiceContainer';
import { partial, noop, isNil } from 'lodash/fp';
import { default as AssortmentAddRangePicker } from './AssortmentAddRangePicker.container';
import { watermarkBlob } from 'src/utils/Images/WatermarkImage';
import { validateStyleName, getStyleColorsWithAttributes } from '../StyleEdit/StyleEdit.client';
import cuid from 'cuid';
import { setIsViewPrintable } from 'src/components/higherOrder/Print/Print.slice';
import { getCroppedImgFromString } from 'src/components/ImageCropper/ImageCropper/Cropper.utils';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { AppState, AppThunkDispatch } from 'src/store';
import { retrieveIdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.utils';
import { resolvePath } from 'src/cdn';
import noImagePath from 'src/common-ui/images/noimage.jpg';
import {
  assortmentAddStylesLens,
  subheaderLens,
  assortmentCartLens,
  scopeLensGetter,
  assortmentCartItemsLens,
} from 'src/services/lenses/lenses';
import { withFab, FabType } from 'src/components/higherOrder/withFab';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { AssortmentCartSlice } from '../../AssortmentCart/AssortmentCart.slice';
import { AssortmentAddViewComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { getDataFromCache } from 'src/services/pivotServiceCache';
import { logError } from 'src/services/loggingService';
import { ViewDataState } from 'src/types/Domain';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { processAdornments } from 'src/components/Adornments/Adornments';
import { preSetActivePage, setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';

export const noImage = resolvePath(noImagePath);

type OwnProps = z.infer<typeof AssortmentAddViewComponentProps>;

interface AssortmentAddBackgroundDataLoadingProps {
  viewDataState: ViewDataState | ViewDataState[] | AssortmentAddSearchDataState;
}

function mapStateToProps(state: AppState, ownProps: OwnProps): ValueProps & AssortmentAddBackgroundDataLoadingProps {
  const {
    defns,
    title = 'Select Items',
    level,
    fabType = FabType.none,
    cartItemType,
    showRangeSelector,
    fabTooltip,
    showFlowStatus,
  } = ownProps;
  const addStyles = assortmentAddStylesLens.get(state);
  const subheader = subheaderLens.get(state);
  const cart = assortmentCartLens.get(state).cartItems;
  const currentAdornments: AdornmentType[] = get(addStyles, 'viewDefn.adornments', []);
  const adornments = processAdornments(currentAdornments);

  let selectedItems: BasicPivotItem[] = [];
  if (isAssortmentAddSlice(addStyles)) {
    selectedItems = addStyles.selectedItemsForCart;
  }

  const data = getDataFromCache(addStyles);

  const filteredStyles = !isNil(data) ? data.tree : [];
  if (level === 'styles') {
    filteredStyles.map((group) => {
      const filteredChildren = group.children.filter((style) => {
        return cart.findIndex((cartItem) => cartItem.id === style[STYLE_ID] && cartItem.type === cartItemType) < 0;
      });
      return {
        ...group,
        filteredChildren,
      };
    });
  } else if (level === 'stylecolors') {
    filteredStyles.map((group) => {
      const filteredChildren = group.children.filter((style) => {
        const foundCartItem = cart.find(
          (cartItem) => cartItem.id === style[STYLE_ID] && cartItem.type === cartItemType
        );
        if (!isNil(foundCartItem)) {
          return (
            foundCartItem.children!.findIndex((childItem) => {
              return childItem.id === style.id && childItem.type === cartItemType;
            }) < 0
          );
        }
        return true;
      });
      return {
        ...group,
        children: filteredChildren,
      };
    });
  }
  let rangePickerEl: JSX.Element | undefined = undefined;
  if (showRangeSelector) {
    rangePickerEl = (
      <AssortmentAddRangePicker
        scopeGetter={scopeLensGetter}
        addStylesSlice={assortmentAddStylesLens}
        assortmentModel={defns.assortmentModel}
      />
    );
  }
  const loaded = !addStyles.tenantConfigLoading && addStyles.viewDataState === ViewDataState.liveDataReady;
  const newSubheader = showFlowStatus ? subheader : { ...subheader, flowStatus: [] };
  const assortmentCartLink = state.perspective.viewLinks.find((view) => view.name === 'Assortment Cart')?.link;

  return {
    title,
    loaded,
    config: addStyles.viewDefn,
    sortBy: subheader.sortBy,
    groupBy: subheader.groupBy,
    subheaderViewDefns: defns.subheader,
    filteredStyles,
    groupedStyles: getGroupedData(filteredStyles, addStyles, newSubheader, selectedItems),
    rangePickerEl,
    summary: combineSummaryInputs(addStyles.identityPropsConfig.id, filteredStyles, addStyles.calcViewDefn, subheader),
    identityPropsConfig: addStyles.identityPropsConfig,
    cartCount: addStyles.selectedItemsForCart.length,
    unmodifiedViewDefn: addStyles.unmodifiedViewDefn,
    fabType,
    fabTooltip,
    isFabDisabled: addStyles.selectedItemsForCart.length <= 0,
    viewDataState: addStyles.viewDataState,
    showFlowStatus,
    adornments,
    assortmentCartLink,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps): DispatchProps {
  const cartItemsToAdd = assortmentCartLens.compose(Lens.fromProp<AssortmentCartSlice>()('cartItemsToAddCount'));
  const selectionService = makeCartService(
    assortmentAddStylesLens.compose(Lens.fromProp<AssortmentAddSlice>()('selectedItemsForCart'))
  );

  const service = serviceContainer.pivotService;
  const identityPropsLens = assortmentAddStylesLens.compose(Lens.fromProp<AssortmentAddSlice>()('identityPropsConfig'));

  const factory = {
    onShowView() {
      const client = container.tenantConfigClient;
      dispatch(setIsViewPrintable(false));
      dispatch(requestTenantConfig());
      client
        .getTenantViewDefnsWithFavorites({
          defnIds: ownProps.defns.view,
          appName: ASSORTMENT,
        })
        .then(async (resp) => {
          const config = resp[0];
          const unmodifiedViewDefn = resp[0];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            ownProps.defns.view[0],
            (resp as any)[ownProps.defns.view.length],
            dispatch,
            unmodifiedViewDefn
          );
          const storedConfig = localConfig && localConfig.config ? localConfig.config : resp[0];
          const identityPropsConfig = retrieveIdentityPropsConfig(config);

          const tenantResponse = {
            viewDefn: storedConfig,
            unmodifiedViewDefn: unmodifiedViewDefn,
            modelDefn: storedConfig.model,
            calcViewDefn: resp[1] as TenantConfigViewData,
            identityPropsConfig,
          };

          dispatch(receiveTenantConfig(tenantResponse));
        })
        .catch((error) => {
          logError('An error occurred fetching add to assortment configs', error);
        });
    },
    onRefetchData() {
      // do nothing, epic will handle fetching data
    },
    onDestroy() {
      dispatch(setIsViewPrintable(true));
      dispatch(update(assortmentAddStylesLens.set(initialState), 'Clear Select Styles slice.'));
    },
    onItemClicked(item: ContainerPayload) {
      dispatch((_ign: AppThunkDispatch, getState: () => AppState) => {
        const config = assortmentAddStylesLens.compose(Lens.fromProp<AssortmentAddSlice>()('viewDefn')).get(getState());
        if (fp.get('main.assortment', config)) {
          const addStyles = assortmentAddStylesLens.get(getState());
          const data = getDataFromCache(addStyles)?.flat || [];
          if (item.type !== RightContainerPayloadType.Filter && item.type !== RightContainerPayloadType.Undo) {
            const identityPropsConfig = identityPropsLens.get(getState());
            const foundPivotItem = find(data, (i) => i[identityPropsConfig.id] === item.id);
            if (foundPivotItem == null) {
              return;
            }
            dispatch(update(partial(selectionService.toggleItem, [foundPivotItem]), `Toggle item in 'to cart' list.`));
          }
        } else {
          noop();
        }
      });
    },
    onUpdate() {
      // do nothing, epic will handle fetching data
    },
    showStylePane(item: ContainerPayload) {
      dispatch(setRightContainerPayload(item));
    },
    addSelectedItemsToCart(itemCount: number) {
      dispatch(update(cartItemsToAdd.asSetter().set(itemCount), 'Items to Add Count Updated'));
      dispatch(async (_dispatch: AppThunkDispatch, getState: () => AppState) => {
        const { selectedItemsForCart: selectionItems, assortmentRange } = assortmentAddStylesLens
          .compose(Lens.fromProps<AssortmentAddSlice>()(['selectedItemsForCart', 'assortmentRange']))
          .get(getState());
        const cartService = makeCartService(assortmentCartItemsLens);
        if (selectionItems.length <= 0) {
          return;
        }
        const groupedSelections = fp.groupBy(STYLE_ID, fp.cloneDeep(selectionItems));
        const validMembersListData = !!assortmentRange ? 'AssortmentStyleDependents' : 'HistoryStyleDependents';
        const { scopeRange } = assortmentAddStylesLens.get(getState());
        let extraParams = {};
        if (!isNil(scopeRange)) {
          if (!!assortmentRange) {
            extraParams = {
              salesStart: scopeRange.from,
              salesEnd: scopeRange.to,
            };
          } else {
            extraParams = {
              historyStart: scopeRange.from,
              historyEnd: scopeRange.to,
            };
          }
        }
        let allChoices: BasicPivotItem[];
        let styleAssortmentInfo: BasicPivotItem[] = [];
        await Promise.all([
          (async () => {
            allChoices = (
              await service.listData(validMembersListData, 'Assortment', {
                topMembers: Object.keys(groupedSelections)
                  .filter(fp.isString)
                  .join(','),
                ...extraParams,
                nestData: false,
                sortBy: 'slsu',
              })
            ).flat;
          })(),
          (async () => {
            if (ownProps.cartItemType === 'existing') {
              const topMembers = Object.keys(groupedSelections)
                .filter(fp.isString)
                .join(',');
              styleAssortmentInfo = await getStyleColorsWithAttributes(topMembers, true);
            }
          })(),
        ]);

        const finalStyleSelections = await Promise.all(
          fp.map(async (item: BasicPivotItem) => {
            item.type = ownProps.cartItemType;
            item.name = item['member:style:name'];
            item.description = item['member:style:description'];
            item.id = item[STYLE_ID];
            item._id = cuid();
            item.valid = true;

            if (ownProps.cartItemType === 'similar') {
              item.name = `S5_${item['member:style:name']}`;
              item.valid = await validateStyleName(item.name);
            }

            if (ownProps.cartItemType === 'existing') {
              const chosenStyles = styleAssortmentInfo.filter((style) => {
                return style[STYLE_ID] === item.id;
              });
              if (chosenStyles.length > 0) {
                item.assortmentChildren = chosenStyles[0].children;
              }
            }

            if (ownProps.cartItemType === 'similar' || item[IMG_URI] === '') {
              const scaledImage = await getCroppedImgFromString(item[IMG_URI]);
              const watermarkedImage = await watermarkBlob(scaledImage).catch((_err) => noImage);
              if (watermarkedImage) {
                item[IMG_URI] = watermarkedImage.replace(/generation=[0-9]*&/, '');
                item.isWatermarked = true;
              }
            }

            const styleChoices = await Promise.all(
              allChoices
                .filter((unfilteredChocie) => {
                  return unfilteredChocie[STYLE_ID] === item.id;
                })
                .map(async (styleChoice) => {
                  const final = omitBy(styleChoice, (_v, k) => {
                    if (['dimension', 'shortestPathToRoot', 'ordered', 'index'].indexOf(k) >= 0) {
                      return true;
                    }
                    return false;
                  }) as BasicPivotItem;
                  final.type = ownProps.cartItemType;
                  if (ownProps.cartItemType === 'similar') {
                    const scaledImage = await getCroppedImgFromString(final[IMG_URI]);
                    const watermarkedImage = await watermarkBlob(scaledImage).catch(() => noImage);
                    if (watermarkedImage) {
                      final[IMG_URI] = watermarkedImage.replace(/generation=[0-9]*&/, '');
                      final.isWatermarked = true;
                    }
                  }
                  return final;
                })
            );

            if (ownProps.level === 'stylecolors') {
              const idsToAdd = fp.map('id', fp.get(item.id, groupedSelections));
              item.children = styleChoices.filter((choice) => {
                return idsToAdd.indexOf(choice.id) >= 0;
              });
            } else {
              item.children = styleChoices;
            }

            item.currentChildren = cloneDeep(styleChoices);
            return item;
          }, fp.uniqBy(STYLE_ID, selectionItems))
        );

        // add the selection to the cart
        finalStyleSelections.forEach((style) => {
          dispatch(
            update((s: AppState): AppState => {
              return cartService.concatChildToItem(
                // @ts-ignore
                (style as unknown) as BasicPivotItem,
                ((style as unknown) as BasicPivotItem).children,
                ownProps.cartItemType || 'existing',
                s
              );
            }, `Adding style and stylecolors to cart.`)
          );
        });

        // And finally remove everything from the selection, since it's in the cart now
        dispatch(update(selectionService.removeAllItemsFromCart, 'Clear Select Styles cart.'));
        dispatch(update(cartItemsToAdd.set(0), 'Clear Item Count in Add'));
      });
    },
    onConfigUpdate(config: TenantConfigViewData) {
      dispatch(
        update(
          assortmentAddStylesLens.composeSetter(Lens.fromProps<AssortmentAddSlice>()(['viewDefn']).asSetter()).set({
            viewDefn: config,
          }),
          'Assortment Add View config updated'
        )
      );
    },
    setActivePage(data: string) {
      dispatch(preSetActivePage());
      dispatch(setActivePage(data));
    },
  };
  return factory;
}

const wrappedView = flow(() => StandardCardView, withFab, makePopoverSensitive, makePrintSensitive)();

export default connect(mapStateToProps, dispatchToProps)(wrappedView);
