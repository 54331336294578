import * as React from 'react';
import { BasicItem } from 'src/types/Scope';
import styles from './ScopeSelector.styles';
import { classes } from 'typestyle';
import { UNICODE_NO_BREAK_SPACE } from 'src/utils/ApplicationConstants';
import * as dropdownStyles from 'src/utils/Style/Dropdown.styles';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core';

export type ScopeSelectorDropdownProps = {
  placeholder?: string;
  selection: string | undefined;
  options: BasicItem[];
  handleChangeOnDropdown: (value: string) => void;
  invalid?: string;
  locked: boolean;
  qa?: string;
  applyClass?: string;
  selectClass?: string;
};

const muistyles = ({}: Theme) => {
  return createStyles({
    disabled: {
      color: '#fff',
    },
  });
};

interface StyleIProps extends WithStyles<typeof muistyles>, ScopeSelectorDropdownProps {}

export class ScopeSelectorDropdown extends React.Component<StyleIProps> {
  constructor(props: StyleIProps) {
    super(props);
  }

  updateSelectedMetric = (ind: string) => {
    this.props.handleChangeOnDropdown(ind);
  };

  render() {
    const {
      options = [],
      placeholder = UNICODE_NO_BREAK_SPACE,
      selection,
      locked,
      invalid,
      applyClass,
      qa,
      classes: muiclasses,
    } = this.props;

    let possiblyInvalidCss = '';
    if (invalid) {
      possiblyInvalidCss = styles.isInvalid;
    }
    const optionsJsx = options.map((option, index) => {
      return (
        <MenuItem key={index} value={option.id} title={option.name}>
          {option.name}
        </MenuItem>
      );
    });
    const displayText = selection == null ? placeholder : UNICODE_NO_BREAK_SPACE;
    const dropdownStyle = applyClass ? applyClass : styles.scopeSelectorDropdown;
    const selectStyle = this.props.selectClass ? this.props.selectClass : dropdownStyles.dropdown;
    const disabled = locked || options.length < 1;

    return (
      <React.Fragment>
        <FormControl
          className={classes(dropdownStyles.form, dropdownStyle, possiblyInvalidCss)}
          data-qa="ScopeSelectorDropdown"
        >
          <InputLabel className={dropdownStyles.formLabel} disabled={disabled}>
            {displayText}
          </InputLabel>
          <Select
            className={selectStyle}
            classes={{ disabled: muiclasses.disabled }}
            // TODO: This (along with everything else) should be placed inside muithemes instead of relying on
            // css style competing. (Override top level theme by passing in theme instead of passing in applyClass)

            disableUnderline={true}
            disabled={disabled}
            value={selection || ''}
            onChange={(event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
              if (typeof event.target.value !== 'string') {
                throw new Error('Bad Select input given');
              }
              this.updateSelectedMetric(event.target.value);
            }}
            IconComponent={(props) => {
              if (!locked) {
                return <i {...props} className="far fa-chevron-down" disabled={disabled} />;
              } else {
                return <span hidden={true} />;
              }
            }}
            data-qa={qa}
          >
            {optionsJsx}
          </Select>
        </FormControl>
      </React.Fragment>
    );
  }
}

export default withStyles(muistyles)(ScopeSelectorDropdown);
