import * as React from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { get, isNil } from 'lodash';

import { ValidationLevel, validateChoiceAtLevel } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import { InputCharacterWhitelist } from 'src/common-ui/components/Inputs/InputGeneric/InputGeneric';
import * as validator from 'validator';
import { multilineTextEditor } from './Editor.styles';

export const PENDING_VALIDATION_VALUE = 'PENDING';

type ValidationInfo = {
  isValid: boolean;
  invalidValue: string;
  invalidLevel: string | null;
  initialValue: string | null;
};

export type PendingCellInfo = {
  id: string;
  dataIndex: string;
  validation?: ValidationInfo;
};

enum CustomValidatorWhitelists {
  whitelistCharsNoNewline = "'-\\.,_;:#@`~<>/=\\w \\t\\[\\]\\(\\)&\\*\\+\\$\\?\\!\\^\\{\\}",
}
export const TVE_InputCharacterWhitelist = { ...InputCharacterWhitelist, ...CustomValidatorWhitelists };

export const viewDefnWhitelistToNarrowedCharacterWhitelist = (whitelistString: string) => {
  // Asserting the whitelist character type here, because actually anything can be loading from viewdefn,
  // but we want to have a better idea of what should be reasonably included in the char whitelist
  const incomingColDefWhitelistCharacters = whitelistString as keyof typeof TVE_InputCharacterWhitelist;
  const whitelist = get<
    typeof TVE_InputCharacterWhitelist,
    keyof typeof TVE_InputCharacterWhitelist,
    typeof TVE_InputCharacterWhitelist.alphaNumericSeparators
  >(TVE_InputCharacterWhitelist, incomingColDefWhitelistCharacters, TVE_InputCharacterWhitelist.alphaNumericSeparators);
  // end whitelist col def fetching
  return whitelist;
};
export type TextValidationEditorProps = {
  whitelist: InputCharacterWhitelist | CustomValidatorWhitelists;
  maxLength: number;
  validateAsync: boolean;
  multiline: boolean;
  invalidDataIndex: string | undefined;
  pendingCellInfo: PendingCellInfo;
  validationLevel?: ValidationLevel;
  invertValidationResponse?: boolean;
  onValidated: (value: string, pendingCellInfo: PendingCellInfo) => void;
} & ICellEditorParams;

type TextValidationEditorState = {
  initialValue: string | null;
  inputValue?: string;
};

function resetInvalidStyles(element: HTMLElement) {
  element.style.border = 'none';
}

export class TextValidationEditor extends React.Component<TextValidationEditorProps, TextValidationEditorState> {
  inputRef: React.RefObject<HTMLTextAreaElement & HTMLInputElement> | undefined;
  constructor(props: TextValidationEditorProps) {
    super(props);

    if (!props.validateAsync) {
      resetInvalidStyles(props.eGridCell);
    }
    this.inputRef = React.createRef();
    const initialValue = !isNil(props.value) ? props.value : '';
    this.state = {
      initialValue,
      inputValue: initialValue,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      if (typeof this.inputRef !== 'string' && !isNil(this.inputRef) && !isNil(this.inputRef.current)) {
        this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = 10000;
        this.inputRef.current.focus();
      }
    }, 48);
  }
  getValue() {
    const { validateAsync } = this.props;
    const { initialValue, inputValue } = this.state;

    // no validation needed if no change in value or not an async validation
    if (initialValue === inputValue || !validateAsync) {
      return inputValue;
    }

    if (!isNil(inputValue)) {
      const {
        node,
        column,
        invalidDataIndex,
        api,
        onValidated,
        pendingCellInfo,
        validationLevel,
        invertValidationResponse,
      } = this.props;

      const level = !isNil(validationLevel) ? validationLevel : ValidationLevel.style;
      const invert = !isNil(invertValidationResponse) ? invertValidationResponse : false;
      validateChoiceAtLevel(inputValue, level, invert).then(({ isValid, invalidLevel }) => {
        const newValue = isValid ? inputValue : initialValue;
        const newData = {
          ...node.data,
          [column.getColId()]: newValue,
        };

        // set invalid data flag for invalid cell value styling purposes
        if (!isNil(invalidDataIndex)) {
          newData[invalidDataIndex] = !isValid;
          // using setData to set multiple values
          node.setData(newData);
        } else {
          // using setDataValue instead of setData to trigger cellValueChanged event in grid
          node.setDataValue(column.getColId(), newValue);
        }

        if (!isNil(api)) {
          api.refreshCells({ rowNodes: [node] });
        }

        // special handler to keep grid & companion data in sync
        const completedCellInfo: PendingCellInfo = {
          ...pendingCellInfo,
          validation: {
            isValid,
            invalidValue: inputValue,
            initialValue,
            invalidLevel,
          },
        };
        onValidated(newValue || '', completedCellInfo);
      });
    }

    return PENDING_VALIDATION_VALUE;
  }

  handleBlur = () => {
    this.stopEditing();
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const textValue = event.target.value;
    const { whitelist, maxLength } = this.props;
    let value = isNil(whitelist) ? textValue : validator.whitelist(textValue, whitelist);
    if (!isNil(maxLength) && value.length > maxLength) {
      value = value.substring(0, maxLength);
    }

    this.setState({
      inputValue: value,
    });
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      this.stopEditing();
    }
  };

  stopEditing = () => {
    this.setState(
      (prevState) => ({
        inputValue: !isNil(prevState) && !isNil(prevState.inputValue) ? prevState.inputValue.trim() : '',
      }),
      () => {
        this.props.stopEditing();
      }
    );
  };

  render() {
    const { maxLength, multiline } = this.props;
    const { inputValue } = this.state;

    if (multiline) {
      return (
        <textarea
          maxLength={maxLength}
          value={inputValue}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyDown}
          onBlur={this.handleBlur}
          className={multilineTextEditor}
          ref={this.inputRef}
        />
      );
    }
    return (
      <input
        type="text"
        maxLength={maxLength}
        value={inputValue}
        ref={this.inputRef}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
      />
    );
  }
}
