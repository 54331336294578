import * as React from 'react';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InputGenericProps } from '../InputGeneric/InputGeneric';
import styles from './InputMoney.styles';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

export default class InputMoney extends React.Component<InputGenericProps> {
  constructor(props: InputGenericProps) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(values: NumberFormatValues) {
    if (this.props.onChange && values.floatValue) {
      this.props.onChange(values.floatValue);
    }
  }

  render() {
    return (
      <div>
        <InputAdornment className={styles.adornment} position="start">
          $
        </InputAdornment>
        <NumberFormat
          onValueChange={this.handleOnChange}
          thousandSeparator={true}
          allowNegative={false}
          customInput={Input}
          decimalScale={2}
        />
      </div>
    );
  }
}
