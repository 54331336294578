import { AppState } from 'src/store';
import { createSelector } from 'reselect';
import { isNil, get } from 'lodash';

import { projectState, StateSelection } from 'src/components/ListGridPair/ListGridPair.selectors';
import { FabType } from 'src/components/higherOrder/withFab';
import { GridViewOwnProps } from './GridView.container';
import { getDataFromCache, isDataLoaded } from 'src/services/pivotServiceCache';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { processAdornments } from 'src/components/Adornments/Adornments';

// TODO: why is nothing using selectors and memoized?

function mapStateToProps(state: AppState, ownProps: GridViewOwnProps): StateSelection {
  const { gridView: viewState } = state.pages.hindsighting.styleColorReview;
  const {
    defns,
    title = 'Grid View',
    allowWorklistFunctionality = false,
    allowExceptionMultiSelect,
    fabType = FabType.none,
    hideTitle,
    hideCompanion,
  } = ownProps;

  const treeData = getDataFromCache(viewState)?.tree || [];
  const configLoaded = !viewState.isConfigLoading;
  const dataLoaded = isDataLoaded(viewState.viewDataState);
  const currentAdornments: AdornmentType[] = get(viewState.viewDefns.grid, 'adornments', []);
  const adornments = processAdornments(currentAdornments, { allowWorklistFunctionality });

  return {
    fabType,
    fabTooltip: ownProps.fabTooltip,
    isPrintMode: state.print.isPrintMode,
    identityField: viewState.viewDefns.identityPropsConfig?.id || '',
    configLoaded,
    dataLoaded,
    treeData,
    subheaderState: state.subheader,
    title,
    subheaderViewDefns: defns.subheader,
    viewDefns: viewState.viewDefns,
    isConfigLoading: viewState.isConfigLoading,
    shouldFilterFlowStatus: true,
    adornments,
    allowExceptionMultiSelect: !isNil(allowExceptionMultiSelect) ? allowExceptionMultiSelect : false,
    activeTab: state.perspective.activeTab,
    downloadLink: ownProps.subheader?.downloadLink,
    viewDataState: viewState.viewDataState,
    hideTitle,
    hideCompanion,
    fabDefn: defns.fab,
  };
}

export const selectState = createSelector(mapStateToProps, projectState);
