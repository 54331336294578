import { AxiosError, AxiosResponse } from 'axios';
import Axios from 'src/services/axios';
import { BulkImportMutation, BulkImportNotice, BulkImportFailure } from './BulkImport.slice';

export const BULKIMPORT_API = 'api/bulk/imports';

// TODO: add logger
/* eslint-disable-next-line */
export function WorklistClient() {
  const client = Axios;
  return {
    getMutations() {
      return client
        .get<BulkImportMutation[]>(BULKIMPORT_API)
        .then((resp) => {
          return resp.data;
        })
        .catch(() => {
          throw new Error('An error occured fetching the bulk import operations');
        });
    },
    uploadMutationTemplate(mutationId: string, formData: FormData) {
      return client
        .post<FormData, AxiosResponse<BulkImportFailure | number>>(`${BULKIMPORT_API}/${mutationId}`, formData)
        .then((resp) => {
          // on success, this comes on the resp.data property
          // not sure how to type the axios response correctly here
          return resp.data;
        })
        .catch((e) => {
          const err = e as AxiosError<BulkImportFailure>;
          const reponseStatus = err.response?.status;
          const responseData = err.response?.data;
          if (!reponseStatus || !responseData) throw new Error('An error occured fetching your import feedback');

          if (reponseStatus && reponseStatus === 422) {
            // this endpoint can return 4xx codes if the ingestion fails,
            // at which point we need to return the reponse payload to be rendered to the user
            // so they can fix them and upload again
            return responseData;
          }
          if (responseData) {
            console.error(`An error occured uploading the template: ${JSON.stringify(responseData)}`);
            throw new Error('An error occured fetching your import feedback');
          }
          throw new Error('An error occured fetching your import feedback');
        });
    },
    getNotices(runId: string) {
      return client
        .get<BulkImportNotice[]>(`${BULKIMPORT_API}/${runId}`)
        .then((resp) => {
          return resp.data;
        })
        .catch(() => {
          throw new Error('An error occured fetching your notices');
        });
    },
  };
}
