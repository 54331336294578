import { ICellEditorParams } from 'ag-grid-community';
import { ViewApiConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';

export type ConfigurableDataColumn = {
  text: string;
  dataIndex: string;
  editable: boolean;
  renderer: string;
  groupingKey: string;
  calculation?: string;
  editableByCalc?: string;
};

export type ConfigurableDataRuleEditDependencies = {
  [attribute: string]: string;
};

// TODO: add additional types here that require specific logic
// e.g. for handling logic for receipts or sales adjustment
export enum ConfigurableDataModalClientHandler {
  receipts = 'receipts',
}
export type ConfigurableDataRules = {
  clientHandler: ConfigurableDataModalClientHandler;
  percentage: {
    referenceColumns: Record<string, string>;
  };
};

export enum ConfigurableDataModalDataHandler {
  receipts = 'receipts',
  pivot = 'pivot',
  attribute = 'attribute',
  granular = 'granular',
}

export type ReceiptDataHandler = {
  type: ConfigurableDataModalDataHandler.receipts;
  defnId: string;
  url: string;
  apiIdDataIndex: string;
};
export type GranularDataHandler = {
  type: ConfigurableDataModalDataHandler.granular;
  url: string;
  coordinateMap: { [k: string]: string };
};
export type PivotDataHandler = {
  type: ConfigurableDataModalDataHandler.pivot;
  defnId: string;
  params: { [k: string]: string };
};

export type AttributeDataHandler = {
  type: ConfigurableDataModalDataHandler.attribute;
  url: string;
  keys: { [k: string]: string };
};

export type ConfigurableDataConfig = {
  title: string;
  dataHandlers: {
    fetch: ReceiptDataHandler | PivotDataHandler;
    update: ReceiptDataHandler | AttributeDataHandler | GranularDataHandler;
  };
  columns: ConfigurableDataColumn[];
  dataSectionHeaders: {
    top: string;
    bottom: string;
    bottomRowsDataIndex: string;
  };
  rowSortByDataIndex: string;
  dataRules: ConfigurableDataRules;
  // consumers of modal editors may expect certain response props from getValue
  valueHandlerPropMappings?: Record<string, string>;
  showTotalRow?: boolean; // SPIKE should this be part of a new larger top/bottom or section config?
};

export type FormattedSizingData = {
  [week: string]: {
    items: ViewSizingData[];
  };
};

export type ConfigurableDataModalProps = ICellEditorParams & {
  configApi: ViewApiConfig;
  floorset: string;
  isEditable: boolean; // certain views will not allow edits at all
  cellDataIndex: string; // used when returning after edit changes canceled
  renderTabs?: boolean;
};

export type ConfigurableDataModalState = {
  isLoading: boolean;
  editsSaved: boolean;
  config: ConfigurableDataConfig | null;
  data: FormattedSizingData;
  activeTabIndex: number;
  width: number | null;
  height: number | null;
};

export enum RenderSectionType {
  top = 'top',
  bottom = 'bottom',
}

export type ServerSizingData = {
  id: string;
  week: string;
  stylecolor: string;
  stylecolorsize: string;
  channel: string;
  [key: string]: string | number | null;
};

export type ViewSizingData = ServerSizingData & {
  editPercentages?: Record<string, number>; // percentage weight of edits
};

// Types following are specifically for rendering just the section rows (including totals row)

export type ConfigurableDataSectionHeaderProps = {
  headers: string[];
};

export type ConfigurableDataSectionRowCellConfig = {
  rowHeaderDataIndexValue: string;
  dataIndex: string;
  editable: boolean;
  editableByCalc?: string;
};
export type ConfigurableDataSectionRowCellProps = {
  cellConfig: ConfigurableDataSectionRowCellConfig;
  cellClassName: string;
  cellValue: string | number | null;
  highlight: boolean;
  onCellEdit: (rowDataIndexValue: string, colDataIndex: string, value: number) => void;
};

export type ConfigurableDataSectionTotalRowProps = {
  sectionConfig: ConfigurableDataSectionColumnConfigs;
  totalRowData: ConfigurableDataSectionRowTotal;
  highlight: boolean;
};

export type ConfigurableDataSectionRowProps = {
  sectionConfig: ConfigurableDataSectionColumnConfigs;
  rowData: ViewSizingData[];
  highlight: boolean;
  totalRowData: ConfigurableDataSectionRowTotal;
};

export type ConfigurableDataSectionRowsProps = {
  renderSizeRowData: boolean;
  rowData: ViewSizingData[];
  totalRowData: ConfigurableDataSectionRowTotal;
  sectionConfig: ConfigurableDataSectionColumnConfigs;
  highlight: boolean;
};

export type ConfigurableDataSectionRowTotal = {
  [key: string]: number | null;
};

export type ConfigurableDataSectionColumnConfigs = {
  modalEditsEnabled: boolean;
  dataRules: ConfigurableDataRules;
  columns: ConfigurableDataColumn[];
  rowHeaderDataIndex: string;
  onCellEdit: (rowDataIndexValue: string, colDataIndex: string, value: number) => void;
};

export type ConfigurableDataSectionProps = {
  sectionHeader: string;
  sectionConfig: ConfigurableDataSectionColumnConfigs;
  totalRowData: ConfigurableDataSectionRowTotal;
  rowData?: ViewSizingData[];
  highlight: boolean;
};

export type ConfigurableDataModalTabsProps = {
  tabs: string[];
  activeTabIndex: number;
  onTabChange: (index: number) => void;
};

export type ConfigurableDataModalCellEditParams = {
  rowDataIndexValue: string;
  colDataIndex: string;
  value: number | null;
  activeTabIndex: number;
  data: FormattedSizingData;
  config: ConfigurableDataConfig;
};
