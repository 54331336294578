import * as React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import Renderer from 'src/utils/Domain/Renderer';
import StarRatings from 'react-star-ratings';
import { starDimension, starSpacing } from 'src/components/StandardCardView/StandardCard/StandardCard.styles';
import styles from './StarPercentRenderer.styles';
import { starGoldColor } from 'src/utils/Style/Theme';

export type Params = ICellRendererParams & {
  data: {
    worklistcount: number;
    maxworklistcount: number;
  };
};

export class StarPercentRenderer extends React.Component<Params> {
  /**
   * AgGrid Renderer that takes two numbers, and renderes the percent of the first to the second,
   * and a number out of five starts that percent represents
   * @param {{worklistcount: number, maxworklistcount: number}} data
   */
  constructor(props: Params) {
    super(props);
  }

  private getStarPercent(count: number, maxCount: number) {
    return count / maxCount;
  }

  private isInputValid(worklistcount: any, maxworklistcount: any) {
    return (
      worklistcount && typeof worklistcount === 'number' && maxworklistcount && typeof maxworklistcount === 'number'
    );
  }

  render() {
    const {
      data: { worklistcount, maxworklistcount },
    } = this.props;

    if (this.isInputValid(worklistcount, maxworklistcount)) {
      const countPercent = this.getStarPercent(worklistcount, maxworklistcount);
      return (
        <div className={styles.container}>
          <StarRatings
            rating={countPercent * 5}
            numberOfStars={5}
            starDimension={starDimension}
            starSpacing={starSpacing}
            starRatedColor={starGoldColor}
          />
          <div className={'star-percent-text'}>{Renderer.simplePercent(countPercent)}</div>
        </div>
      );
    }
    // else, fallback to nothing
    return <React.Fragment />;
  }
}
