import * as React from 'react';
import { GeoChart, Overlay } from 'src/common-ui';
import { ExtendedPointObject, SimplerLineChart } from './Charts/SimplerChart';
import {
  default as styles,
  TREND_UP_COLOR,
  TREND_DOWN_COLOR,
  NO_TREND_COLOR,
  MAP_BUBBLE_MAX_SIZE,
  MAP_BUBBLE_MIN_SIZE,
} from './GeoTrends.styles';
import { isNil, get, isEqual, forEach } from 'lodash';
import { TenantConfigViewItem, GeoTrendsConfigData } from 'src/dao/tenantConfigClient';
import { Numbro } from 'numbro';
import * as Highcharts from 'highcharts';
import { Grid } from '@material-ui/core';
import Subheader from 'src/components/Subheader/Subheader.container';
import SubheaderDropdown from 'src/components/Subheader/SubheaderDropdown';
import { StateProjection } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/GeoTrends.selectors';
import { GeoChartProps } from 'src/common-ui/components/Charts/GeoChart/GeoChart';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { MACRO_TRENDS_WARNING_MESSAGE } from 'src/pages/Hindsighting/MacroTrends/MacroTrends';
import Renderer from 'src/utils/Domain/Renderer';
import numbro from 'numbro';
import { SeriesClickEventObject } from 'highcharts';

export interface GeoDataItem {
  globalregion?: string;
  store?: string;
  channel?: string;
  slsr: number;
  children: GeoDataItem[];
  // latitude properties
  GLOBAL_LAT: string;
  CHANNEL_LAT: string;
  STORE_LAT: string;

  // longitude properties
  GLOABL_LNG: string;
  CHANNEL_LNG: string;
  STORE_LNG: string;
  trend: number; // or should be an enum?
}

interface GeoDataChartItem {
  name?: string;
  lat: number;
  lon: number;
  z: number;
  mId: string;
  mDesc: string;
  departmentData: GeoDataChartItem[];
}

type State = {
  selectedLevelInd?: number;
  dropdownOpen: boolean;
};

export type FunctionProps = {
  onShowView: () => void;
  onUpdate: () => void;
  onUpdateGeoLevel: (selectedGeo: TenantConfigViewItem | undefined) => void;
  onSelectPoint: (selectedPoint: ExtendedPointObject) => void;
  onDestroy: () => void;
};

export class GeoTrends extends React.Component<StateProjection & FunctionProps> {
  state: State;

  constructor(props: StateProjection & FunctionProps, state: State) {
    super(props, state);
    this.state = {
      dropdownOpen: false,
    };

    this.handleToggleDropdown = this.handleToggleDropdown.bind(this);
    this.handleClickDropdown = this.handleClickDropdown.bind(this);
    this.onMapBubbleClick = this.onMapBubbleClick.bind(this);
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentDidUpdate(prevProps: StateProjection) {
    if (this.props.loaded && prevProps.loaded) {
      if (isNil(this.props.selectedGeoLevel)) {
        this.updateLevel(numbro(0));
      }
      if (!isEqual(this.props.flowStatus, prevProps.flowStatus)) {
        this.props.onUpdate();
      }
    }
  }

  updateLevel(index: Numbro) {
    if (this.props.loaded) {
      const { levelSelectConfig, selectedGeoLevel } = this.props;

      if (isNil(selectedGeoLevel)) {
        this.props.onUpdateGeoLevel(levelSelectConfig.view[0]);
      } else {
        this.setState({
          selectedLevelInd: index.value(),
        });
        this.props.onUpdateGeoLevel(levelSelectConfig.view[index.value()]);
      }
    }
  }

  handleClickDropdown(event: React.ChangeEvent<HTMLSelectElement>) {
    this.updateLevel(numbro(event.currentTarget.dataset.position));
  }

  handleToggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  onMapBubbleClick(event: SeriesClickEventObject) {
    if (event) {
      const data: ExtendedPointObject = {
        ...event.point,
      } as ExtendedPointObject;
      this.props.onSelectPoint(data);
    }
  }

  parseData(
    geoData: BasicPivotItem[],
    levelSelectConfig: GeoTrendsConfigData
  ): (Highcharts.SeriesMapbubbleOptions | Highcharts.SeriesMapOptions)[] {
    if (!this.props.loaded) {
      return [];
    }
    const { selectedGeoLevel } = this.props;

    if (isNil(selectedGeoLevel)) {
      return [];
    }
    const mapSeries: Highcharts.SeriesMapbubbleOptions[] = [];
    const trendUp: GeoDataChartItem[] = [];
    const trendDown: GeoDataChartItem[] = [];
    const trendZero: GeoDataChartItem[] = [];
    const selectedGeoLevelGroupKey = selectedGeoLevel!.groupingKey || '';

    // basic MapSeries options to be used by all MapSeries
    const standardMapSeriesOptions: Highcharts.SeriesMapbubbleOptions = {
      type: 'mapbubble',
      minSize: MAP_BUBBLE_MIN_SIZE,
      maxSize: MAP_BUBBLE_MAX_SIZE,
      color: TREND_UP_COLOR,
    };

    const trendCheck = {
      0: trendZero,
      1: trendUp,
    };

    const [latitudeKey, longitudeKey] = levelSelectConfig.view.find(
      (item) => item.dataIndex === selectedGeoLevel.dataIndex
    )!.geoLocationKeys!;

    forEach(geoData, (dataItem) => {
      const name = dataItem!.store || dataItem!.channel || dataItem!.globalregion;
      const lat = parseFloat(dataItem[latitudeKey]);
      const lon = parseFloat(dataItem[longitudeKey]);

      if (isNaN(lat) || isNaN(lon)) {
        // tslint:disable:no-console
        console.error(`Could not parse latitude or longitude from GeoData for ${JSON.stringify(dataItem, null, 2)}`);
        return;
      }

      const prefix = `member:${selectedGeoLevelGroupKey.split(':')[1]}`;
      const trend = dataItem.trend;
      const dataArray = trendCheck[trend] || trendDown;
      const zDataIndex = levelSelectConfig.bubble ? levelSelectConfig.bubble.dataIndex : 'slsr';
      const newItem = {
        name: name,
        lat,
        lon,
        z: get(dataItem, zDataIndex),
        mId: dataItem[`${prefix}:id`],
        mDesc: dataItem[`${prefix}:description`],
        departmentData: dataItem.children,
      };

      dataArray.push(newItem);
    });

    // asserting here because 'minSize' isn't in the typedef
    mapSeries.push({
      ...standardMapSeriesOptions,
      name: 'Trending Up (> 10%)',
      data: trendUp,
      color: TREND_UP_COLOR,
    });

    mapSeries.push({
      ...standardMapSeriesOptions,
      data: trendDown,
      name: 'Trending Down (< 10%)',
      color: TREND_DOWN_COLOR,
    });

    mapSeries.push({
      ...standardMapSeriesOptions,
      data: trendZero,
      name: 'No Trend (+/- 10%)',
      color: NO_TREND_COLOR,
    });

    return mapSeries;
  }

  // tslint:disable-next-line:no-any
  getLabel = (point: any) => {
    if (this.props.loaded && this.props.levelSelectConfig.bubble) {
      const popoverTitle = this.props.levelSelectConfig.bubble.text
        ? this.props.levelSelectConfig.bubble.text
        : this.props.levelSelectConfig.bubble.dataIndex;
      const dataRenderer = this.props.levelSelectConfig.bubble
        ? this.props.levelSelectConfig.bubble.renderer
        : 'usMoneyNoCents';
      return point.mDesc + `<br/> ${popoverTitle} ` + Renderer[dataRenderer](point.z);
    } else {
      return '';
    }
  };

  render() {
    const { selectedLevelInd } = this.state;
    /* eslint-disable-next-line @typescript-eslint/no-this-alias */
    const self = this;

    let overlayGeochart = true;
    let topChart;
    let bottomChart;
    let dropDown = <div />;

    const geoChartConfig: GeoChartProps = {
      title: 'Global Region Volume and Trend',
      mapUri: this.props.mapUri,
      mapSeries: [],
    };

    if (this.props.loaded) {
      const {
        data,
        levelSelectConfig,
        topChartConfig,
        bottomChartConfig,
        geoDataLoaded,
        chartDataLoaded,
        chartData,
        selectedItem,
      } = this.props;
      const defaultSelectionIndex = levelSelectConfig.view.findIndex((view) => view.text === levelSelectConfig.default);
      dropDown = (
        <SubheaderDropdown
          defaultSelection={defaultSelectionIndex}
          selection={selectedLevelInd}
          options={levelSelectConfig.view}
          label={'GeoLevel: '}
          handleChangeOnDropdown={this.handleClickDropdown}
        />
      );
      overlayGeochart = !geoDataLoaded;
      geoChartConfig.geoConfig = {
        tooltip: {
          formatter: function() {
            // tslint:disable-next-line:no-any
            return self.getLabel((this as any).point);
          },
        },
      };
      geoChartConfig.onMapBubbleClick = this.onMapBubbleClick;
      geoChartConfig.mapSeries = this.parseData(data, levelSelectConfig);

      if (!isNil(this.props.selectedItem)) {
        topChart = (
          // 'fitParent' tries to squeeze the overlay into a position:relative parent
          <div style={{ position: 'relative', height: '100%' }}>
            <Overlay visible={!chartDataLoaded} type={'loading'} fitParent={true} />
            <SimplerLineChart
              title={`${selectedItem!.mDesc}  ${topChartConfig.text}`}
              data-qa="geotrends-top-chart"
              data={chartData}
              config={topChartConfig}
            />
          </div>
        );
        bottomChart = (
          <div className={styles.chartContainer}>
            <Overlay visible={!chartDataLoaded} type={'loading'} fitParent={true} />
            <SimplerLineChart
              title={`${selectedItem!.mDesc}  ${bottomChartConfig.text}`}
              data-qa="geotrends-bottom-chart"
              data={chartData}
              config={bottomChartConfig}
            />
          </div>
        );
      }
    }

    const mapChart = <GeoChart {...geoChartConfig} />;

    return (
      <Grid container={true} className={styles.geoTrendsContainer} spacing={0} data-qa-component="GeoTrends">
        <Grid item={true} lg={12} md={12}>
          <Subheader
            title={this.props.title}
            showFlowStatus={true}
            showSearch={false}
            showLookBackPeriod={true}
            errorCondition={MACRO_TRENDS_WARNING_MESSAGE}
          />
        </Grid>
        <Grid item={true} lg={8} md={12} xs={12} className={styles.fullHeightGridItem}>
          <Overlay visible={overlayGeochart} type={'loading'} fitParent={true} />
          <div className={styles.dropDownMapChart}>{dropDown}</div>
          {mapChart}
        </Grid>
        <Grid item={true} lg={4} className={styles.fullHeightGridItem}>
          <Grid container={true} style={{ height: '100%' }}>
            <Grid item={true} lg={12} className={styles.fullWidthHalfHeightGridItem}>
              {topChart}
            </Grid>
            <Grid item={true} lg={12} className={styles.fullWidthHalfHeightGridItem}>
              {bottomChart}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
