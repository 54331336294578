import { AppState } from 'src/store';
import { createSelector, OutputSelector } from 'reselect';
import { flattenAndLabelGroup } from 'src/components/StandardCardView/SortAndGroup';
import {
  CardViewDataInputs,
  CardViewGroup,
  CardViewOptionalInputs,
} from 'src/components/StandardCardView/UIData.types';
import { BasicPivotItem, WorklistInfo } from 'src/worker/pivotWorker.types';
import { SummaryViewSlice } from './SummaryView.slice';
import { ReduxSlice as SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { toSummaries } from 'src/utils/Pivot/RollUp';
import { GetSummaryOutput } from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.selectors';
import { filterData } from 'src/utils/Pivot/Filter';
import processDataForCardView from 'src/components/StandardCardView/CardViewDataProcessor';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import { getDataFromCache } from 'src/services/pivotServiceCache';

export type ProcessedDataSelector = OutputSelector<
  AppState,
  CardViewGroup[],
  (res1: BasicPivotItem[], res2: SummaryViewSlice, subh: SubheaderSlice, worklist: WorklistInfo[]) => CardViewGroup[]
>;

const getTreeData = (state: AppState) => {
  return getDataFromCache(state.pages.hindsighting.styleColorReview.summaryView)?.tree || [];
};
const getRollup = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.summaryView.calcViewDefn;
};
const getWorklist = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.sharedData.worklist;
};
const getViewSlice = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.summaryView;
};
const getSubheader = (state: AppState) => {
  return state.subheader;
};
const getSearchIndexes = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.summaryView.viewDefn.searchIndexes;
};
export const getProcessedData: ProcessedDataSelector = createSelector(
  getTreeData,
  getViewSlice,
  getSubheader,
  getWorklist,
  (treeData, viewSlice, subheader, worklist) => {
    if (treeData && viewSlice && subheader) {
      const dataInputs: CardViewDataInputs = {
        styles: treeData,
        subheader,
        defns: {
          view: viewSlice.viewDefn || {},
          rollup: viewSlice.calcViewDefn || {},
        },
      };
      const optionalInputs: CardViewOptionalInputs = {
        worklist,
      };
      return processDataForCardView(dataInputs, optionalInputs);
    }
    return [];
  }
);

export const getSummary = createSelector(
  getTreeData,
  getViewSlice,
  getRollup,
  getSubheader,
  getSearchIndexes,
  (treeData, viewSlice, rollupConfig, subheader, searchIndexes) => {
    if (!treeData) {
      return [] as GetSummaryOutput[];
    }
    const views = rollupConfig.view;
    const groupDataIndex = getGroupBySelectedOptionProperty(subheader.groupBy, 'dataIndex');
    const groupDimension = getGroupBySelectedOptionProperty(subheader.groupBy, 'dimension');
    const flat = flattenAndLabelGroup({ items: treeData, groupDataIndex, groupDimension });
    const searchFields = searchIndexes ? searchIndexes : externalGridSearchFields;

    if (flat.length === 0 || !Array.isArray(views) || views.length === 0) {
      return [] as GetSummaryOutput[];
    }
    const filteredFlat = filterData(
      flat,
      subheader.search,
      searchFields,
      subheader.flowStatus,
      subheader.pareDown && subheader.pareDown.selections
    );
    return toSummaries(filteredFlat, views, viewSlice.identityPropsConfig.id);
  }
);

export const getFilteredFlatData = createSelector(
  getTreeData,
  getSubheader,
  getSearchIndexes,
  (treeData, subheader, searchIndexes) => {
    const groupDataIndex = getGroupBySelectedOptionProperty(subheader.groupBy, 'dataIndex');
    const groupDimension = getGroupBySelectedOptionProperty(subheader.groupBy, 'dimension');
    const flat = flattenAndLabelGroup({ items: treeData, groupDataIndex, groupDimension });
    const searchFields = searchIndexes ? searchIndexes : externalGridSearchFields;
    const pareDown = subheader.pareDown && subheader.pareDown.selections;
    const filteredFlat = filterData(flat, subheader.search, searchFields, subheader.flowStatus, pareDown);
    return filteredFlat;
  }
);
