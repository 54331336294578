import { style, media } from 'typestyle/lib';
import { SUBHEADER_HEIGHT } from 'src/components/Subheader/Subheader.styles';
import { faintBorderColor, smallBreakpoint } from 'src/utils/Style/Theme';

import { makeGridStyles, GridStyles } from 'src/utils/Style/NthChildPatterns';
import { rangePx } from 'src/utils/Style/Media';

export const macroSummaryContainerHeight = 250;
export const macroSummariesHeight = 200;
export const macroSummaryHeightCollapsed = 50;
export const scrollbarHeight = 22;

function borderize(width: number, height: number, totalCount: number) {
  const styleOptions: GridStyles = {
    top: { borderTop: 'none' },
    left: { borderLeft: 'none' },
    right: { borderRight: 'none' },
    bottom: { borderBottom: 'none' },
  };

  if ((width * height) % totalCount !== 0) {
    styleOptions.rangeStyles = [[width * 2 + 1, totalCount, { border: 'none' }]];

    styleOptions.nthRow = [[1, { borderBottom: 'none' }]];
  }

  return makeGridStyles(width, height, styleOptions, {
    prefix: '&',
  });
}

export const dataContainerStyle = style({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const macroGridStyle = style({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  $nest: {
    '.ag-menu .ag-menu-option-icon span': {
      height: 'unset',
      lineHeight: 'unset',
      marginTop: 'unset',
    },
    '.ag-header-icon .ag-icon': {
      top: -33,
      left: 3,
    },
    '.ag-header-cell-label .ag-sort-ascending-icon': {
      marginTop: -20,
    },
    '.ag-header-cell-label .ag-sort-descending-icon': {
      marginTop: -10,
    },
    '.header-row': {
      fontWeight: 700,
    },
    '.grid-container': {
      height: `calc(100% - ${macroSummaryContainerHeight}px)`,
      $nest: {
        '&.expanded': {
          height: `calc(100% - ${macroSummaryHeightCollapsed}px)`,
        },
        '.expander': {
          marginBottom: 16,
        },
      },
    },
    '.macro-container': {
      height: `calc(100% - ${SUBHEADER_HEIGHT}px)`,
    },
    '.data-grid': {
      fontSize: '0.8rem',
      height: `calc(100% - ${scrollbarHeight}px)`,
    },
    '.expander': {
      fontSize: '0.9rem',
      display: 'inline-block',
      height: 20,
      cursor: 'pointer',
      $nest: {
        '> i': {
          marginRight: '0.5rem',
        },
      },
    },
    '.macro-summaries': {
      display: 'flex',
      flexWrap: 'wrap',
      height: macroSummariesHeight,
      overflow: 'auto',
      $nest: {
        '&.collapsed': {
          display: 'none',
        },
      },
    },
    '.macro-summary': {
      height: '50%',
      justifyContent: 'space-between',
      padding: '1rem 0 1rem 1.5rem',
      border: '1px solid ' + faintBorderColor,
      $nest: {
        [rangePx(smallBreakpoint)]: {
          width: '20%',
          ...borderize(5, 2, 10),
        },
        [rangePx(0, smallBreakpoint, true)]: {
          width: '50%',
          height: 'calc(50% + 1px)',
          ...borderize(2, 5, 10),
        },
        '.primary-metric': {
          textTransform: 'uppercase',
        },
        '.variances': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
        '.metric-name': {
          fontSize: '0.5rem',
          width: '3rem',
          textAlign: 'right',
          margin: 0,
        },
        '.metric-value': {
          fontSize: '0.5rem',
          width: '3rem',
          whiteSpace: 'nowrap',
          textAlign: 'left',
        },
      },
    },
  },
});

export const containerPrintMode = style({
  display: 'flex',
  flexDirection: 'column',
  $debugName: 'container-printmode',
  $nest: {
    '.data-container': {
      display: 'flex',
      flexDirection: 'column',
      height: 'initial',
      flex: '1 0',
      $nest: {
        '.macro-summaries': {
          height: 'initial',
        },
        '.grid-container': {
          display: 'flex',
          flex: '1 0 ',
          height: 'initial',
          $nest: {
            '.data-grid': {
              flex: '1 0',
              height: 'initial',
            },
          },
        },
      },
    },
  },
});
