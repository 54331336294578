import React from 'react';
import { MemoizedNavigationGroup } from 'src/components/NavigationGroup/NavigationGroup';
import styles from './Sidenav.styles';
import { Props, InlineProps } from './Sidenav.container';
import { extractNavPath, getSectionForPath } from 'src/pages/NavigationShell/navigationUtils';
import { chain, map, getOrElse } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';

type LocalSidenavState = {
  collapsed: boolean;
  scrollbarShowed: boolean;
};

export const SidenavWrapper = (inline: InlineProps): React.ComponentType<Props> =>
  class InternalSidenav extends React.Component<Props, LocalSidenavState> {
    sidenavRef!: HTMLDivElement | null;

    constructor(props: Props) {
      super(props);

      this.state = {
        collapsed: this.props.collapsed ? true : false,

        scrollbarShowed: false,
      };

      window.addEventListener('resize', this.checkForScrollbar);
    }

    // TODO: I'm not at all sure that this is correct, but the router doesn't
    // Seem to provide any other way of accessing this information easily
    // This tightly couples sidenav to the global path structure...
    componentDidMount() {
      // The first time a tab mounts, we should ensure that we have expanded something.
      function getTabName(url: string) {
        if (!url) {
          return undefined;
        }
        const start = url.split('/', 3).join('/').length + 1,
          end = url.split('/', 4).join('/').length,
          tab = url.substr(start, end - start);
        return tab;
      }

      if (
        (!this.props.groupId || this.props.groupId !== getTabName(this.props.location.pathname)) &&
        inline.tab.boundSections
      ) {
        const setGroupId = pipe(
          extractNavPath(this.props.location),
          chain((navPath) => getSectionForPath(inline.tab, navPath)),
          map((section) => section.id),
          getOrElse(() => (inline.tab.boundSections?.length ? inline.tab.boundSections[0].id : ''))
        );
        this.props.setFocusedGroup(setGroupId);
      }

      if (this.sidenavRef) {
        this.checkForScrollbar();
      }
    }

    componentWillUnmount = () => {
      this.props.setCollapsed(this.state.collapsed);
    };

    toggleCollapsed = (isCollapsed?: boolean) => {
      const collapsed = isCollapsed === undefined ? !this.state.collapsed : isCollapsed;
      this.props.setCollapsed(collapsed);

      this.setState({
        collapsed,
      });
    };

    checkForScrollbar = () => {
      if (
        this.sidenavRef &&
        !this.state.scrollbarShowed &&
        this.sidenavRef.clientHeight < this.sidenavRef.scrollHeight
      ) {
        this.setState({
          scrollbarShowed: true,
        });
      }
      if (
        this.sidenavRef &&
        this.state.scrollbarShowed &&
        this.sidenavRef.clientHeight === this.sidenavRef.scrollHeight
      ) {
        this.setState({
          scrollbarShowed: false,
        });
      }
    };

    render() {
      if (this.props.isPrintMode) {
        return null;
      }

      const { className, perspective, tab, sectionName } = inline;
      const viewFromGroup = pipe(
        extractNavPath(this.props.location),
        chain((navPath) => getSectionForPath(inline.tab, navPath)),
        map((section) => section.id),
        getOrElse(() => '')
      );

      const sidenavGroups = tab.boundSections
        ? tab.boundSections.map((section, i) => (
            <div key={i}>
              <MemoizedNavigationGroup
                setFocusedGroup={(groupId: string) => this.props.setFocusedGroup(groupId)}
                expanded={this.props.groupId === section.id}
                activeViewFrom={viewFromGroup === section.id}
                perspective={perspective}
                tab={tab}
                section={section}
                key={section.name}
                collapsed={this.state.collapsed}
                onItemClick={() => this.props.setCollapsed(this.state.collapsed)}
              />
              <hr />
            </div>
          ))
        : null;

      const sidenavClasses = [className, styles.sidebar(this.state.scrollbarShowed), 'p-0', 'border-right'];
      if (this.state.collapsed) {
        sidenavClasses.unshift(styles.collapsedSidenavStyles(this.state.scrollbarShowed));
      }

      return (
        <React.Fragment>
          {this.state.collapsed ? (
            <div className={styles.fakeSidenavDivForSpacing(this.state.scrollbarShowed)} />
          ) : (
            undefined
          )}
          <div ref={(ref) => (this.sidenavRef = ref)} className={sidenavClasses.join(' ')}>
            <div className={`${styles.title} text-center text-secondary font-weight-bold pt-4`}>
              <span className="sectionNameDisplayed">{sectionName.toUpperCase()}</span>
            </div>
            <span
              onClick={() => this.toggleCollapsed()}
              className={'collapseIconContainer fas fa-angle-' + (this.state.collapsed ? 'right' : 'left')}
            />
            <hr style={{ marginTop: -5 }} />
            {sidenavGroups}
          </div>
        </React.Fragment>
      );
    }
  };
