import { get } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewDefnState } from 'src/dao/tenantConfigClient';
import { ViewDataState } from 'src/types/Domain';
import { fetchListData } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { BasicPivotItem, ListDataOptions, Pivot } from 'src/worker/pivotWorker.types';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { CompanionOvertimeConfig } from '../OvertimeView.types';
import { ID } from 'src/utils/Domain/Constants';
import { CompanionDataLookup } from 'src/utils/Component/ListView';
import { Option } from 'src/components/Configure/ConfigureModal';

export type StyleByLevelOvertimeSlice = {
  viewDataState: ViewDataState;
  companionDataState: ViewDataState;
  viewDefnState: ViewDefnState;
  viewDefn: CompanionOvertimeConfig;
  unmodifiedViewDefn: CompanionOvertimeConfig;
  companionDefn: CompanionDataLookup;
  data: BasicPivotItem[];
  companionData: BasicPivotItem[];
  selectedCompanionId: string;
  configureSelections?: Option[];
  defaultConfigureSelections?: Option[];
  defaultCompanionSortField: string;
};

const initialState: StyleByLevelOvertimeSlice = {
  viewDataState: ViewDataState.idle,
  companionDataState: ViewDataState.idle,
  viewDefnState: ViewDefnState.idle,
  viewDefn: {} as CompanionOvertimeConfig,
  companionDefn: {} as CompanionDataLookup,
  unmodifiedViewDefn: {} as CompanionOvertimeConfig,
  data: [],
  companionData: [],
  selectedCompanionId: '',
  configureSelections: [],
  defaultConfigureSelections: [],
  defaultCompanionSortField: '',
};

const styleByLevelOvertimeSlice = createSlice({
  name: 'StyleByLevelOvertime',
  initialState,
  reducers: {
    requestTenantConfig(state) {
      state.viewDefnState = ViewDefnState.loading;
    },
    receiveTenantConfig(
      state,
      action: PayloadAction<{
        viewDefn: CompanionOvertimeConfig;
        unmodifiedViewDefn: CompanionOvertimeConfig;
        companionDefn?: CompanionDataLookup;
        defaultCompanionSortField?: string;
      }>
    ) {
      const { viewDefn, unmodifiedViewDefn, companionDefn, defaultCompanionSortField } = action.payload;
      state.viewDefn = viewDefn;
      state.unmodifiedViewDefn = unmodifiedViewDefn;
      state.viewDefnState = ViewDefnState.loaded;
      state.defaultConfigureSelections = viewDefn.columns;
      state.configureSelections = viewDefn.columns;
      if (companionDefn) {
        state.companionDefn = companionDefn;
      }
      if (defaultCompanionSortField) {
        state.defaultCompanionSortField = defaultCompanionSortField;
      }
    },
    updateTenantConfig(state, action: PayloadAction<CompanionOvertimeConfig>) {
      state.viewDefn = action.payload;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveLiveData(state, action: PayloadAction<Pivot>) {
      state.viewDataState = ViewDataState.liveDataReady;
      state.data = action.payload.tree;
    },
    removeOldData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
      state.data = [];
    },
    requestCompanionData(state) {
      state.companionDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveCompanionData(state, action: PayloadAction<Pivot>) {
      const data = action.payload.flat;
      state.companionDataState = ViewDataState.liveDataReady;
      state.companionData = data;
      state.selectedCompanionId = get(data, `[0][${ID}]`, '');
    },
    setSelectedId(state, action: PayloadAction<string>) {
      state.selectedCompanionId = action.payload;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestTenantConfig,
  receiveTenantConfig,
  updateTenantConfig,
  requestData,
  requestCompanionData,
  receiveLiveData,
  removeOldData,
  receiveCompanionData,
  setSelectedId,
  receiveError,
  cleanUp,
} = styleByLevelOvertimeSlice.actions;

export function fetchStyleByLevelCompanionData(companionDefn: string, options: ListDataOptions) {
  return fetchListData(companionDefn, requestCompanionData, receiveCompanionData, options);
}

export function fetchStyleByLevelOvertimeData(overtimeDefn: string, options: ListDataOptions) {
  return fetchListData(overtimeDefn, requestData, receiveLiveData, options);
}
export default styleByLevelOvertimeSlice.reducer;
