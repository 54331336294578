import * as React from 'react';
import { macroSummaryStyle } from './MacroSummary.styles';

import { Metric, DirectionalMetric } from '../../CommonTypes';
import { upArrowClass, downArrowClass } from '../../../../theme';

export class Props {
  primaryMetric!: Metric;
  secondaryMetrics!: DirectionalMetric[];
  className?: string;
}

export default function MacroSummary(props: Props) {
  const { primaryMetric, secondaryMetrics, className } = props;

  const classes = macroSummaryStyle + ' macro-summary' + (className ? ' ' + className : '');

  return (
    <div className={classes}>
      <div className="left-container">
        <header>{primaryMetric.label}</header>
        <div className="primary-metric">{primaryMetric.rendered}</div>
      </div>
      <div className="right-container">
        <ul className="variances">
          {secondaryMetrics.map((metric, index) => {
            let directionClass = 'dir fa fas ';
            if (typeof metric.direction === 'string') {
              if (metric.direction === 'up') {
                directionClass += upArrowClass + ' up';
              } else {
                directionClass += downArrowClass + ' down';
              }
            } else {
              directionClass += downArrowClass + ' none';
            }

            return (
              <li key={index}>
                <label className="metric-name">{metric.label}</label>
                <i className={directionClass} />
                <span className="metric-value">{metric.rendered}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
