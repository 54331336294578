import { connect } from 'react-redux';
import FloorsetSelectorForm from './FloorsetSelectorForm';
import { FloorsetConfigResponse } from 'src/types/Scope';
import { AppState, AppThunkDispatch } from 'src/store';
import { asyncGetFloorsetConfig, setSelectedPeriod } from '../ScopeSelector.actions';
import { QuickSelect, QuickSelectItem, QuickSelectRegion } from 'src/dao/scopeClient';
import { isNil } from 'lodash';
import { ASSORTMENT_CONTEXT, HINDSIGHTING_CONTEXT, TOP_DOWN_URI } from 'src/utils/Domain/Constants';
import { BasicItem } from 'src/worker/pivotWorker.types';
import { clearFloorsetAndQuickSelect } from '../ScopeSelector.slice';

//TODO: MAKE A DAMN SUB-STATE FOR THIS COMPONENT
export interface StateProps {
  floorsetConfig: FloorsetConfigResponse;
  selectedFloorset: string | null;
  selectedProductMember: string | null;
  invalid?: string;
  isLoading: boolean;
  locked: boolean;
  quickSelects: QuickSelect[];
  region: QuickSelectRegion;
  isTopDownView: boolean;
  rangeList: BasicItem[];
  historyRangeList: BasicItem[];
}

export type DispatchProps = {
  onChangeFloorset(floorset: QuickSelectItem): void;
  onClickGetFloorset(productMemberId: string): void;
};

export type OwnProps = {
  region: QuickSelectRegion;
};

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  const { productMember, floorSet, historyFloorset } = state.scope.selections;
  const isTopDownView = !isNil(state.perspective.selected)
    ? state.perspective.selected.pathSlot === TOP_DOWN_URI
    : false;

  const {
    selectionErrors,
    currentFloorSet,
    floorsetConfig,
    floorsetLoading,
    quickSelects,
    rangeList,
    pastRangeList: historyRangeList,
  } = state.scope;

  const selectedFloorset = ownProps.region === ASSORTMENT_CONTEXT ? currentFloorSet || floorSet : historyFloorset;
  return {
    floorsetConfig: floorsetConfig,
    selectedFloorset: selectedFloorset,
    selectedProductMember: productMember,
    invalid: selectionErrors.floorSet,
    isLoading: floorsetLoading,
    locked: false,
    quickSelects,
    region: ownProps.region,
    isTopDownView,
    rangeList,
    historyRangeList,
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps): DispatchProps {
  return {
    onChangeFloorset(floorSet: QuickSelectItem): void {
      if (ownProps.region === ASSORTMENT_CONTEXT) {
        dispatch(clearFloorsetAndQuickSelect(HINDSIGHTING_CONTEXT));
      }
      dispatch(setSelectedPeriod(floorSet, ownProps.region));
      // dispatch(asyncGetFloorsetAttributes(floorSet));
    },
    onClickGetFloorset(productMember: string): void {
      dispatch(asyncGetFloorsetConfig(productMember, ownProps.region));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FloorsetSelectorForm);
