import { Action, combineReducers } from 'redux';

import pages from 'src/pages/Pages.reducer';
import scope from 'src/components/ScopeSelector/ScopeSelector.slice';
import subheader from 'src/components/Subheader/Subheader.slice';
import rightContainer from 'src/components/RightContainer/RightContainer.slice';
import filters from 'src/components/FilterPanel/FilterPanel.slice';
import sideNavReducer from 'src/components/Sidenav/SideNav.slice';
import print from 'src/components/higherOrder/Print/Print.slice';
import historyStylePane from 'src/components/HistoryStylePane/HistoryStylePane.reducer';
import planTracker from 'src/pages/AssortmentBuild/Planning.slice';
import appConfig from 'src/services/configuration/AppConfig.slice';
import QRCategorySummarySlice from 'src/components/Reconcilation/QuickRecapView/QRCategorySummary.slice';
import NavigationShellReducer from 'src/pages/NavigationShell/NavigationShell.slice';
import bulkImport from 'src/pages/Reporting/BulkImport/BulkImport.slice';
import worklist from 'src/pages/Worklist/Worklist.slice';
import cart from 'src/pages/AssortmentCart/AssortmentCart.slice';

import { AppState } from './store';
import { isStepAction, StepAction } from './services/lenses/Lenses.actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import error from './components/ErrorBoundary/ErrorBoundary.slice';

const sidenavPersistConfig = {
  key: 'persistSideNav',
  storage,
  whitelist: ['collapsed'],
};

const persistedSidenavReducer = persistReducer(sidenavPersistConfig, sideNavReducer);

export const appReducer = combineReducers({
  appConfig,
  scope,
  historyStylePane,
  rightContainer,
  pages,
  print,
  subheader,
  filters,
  bulkImport,
  error,
  sidenav: persistedSidenavReducer,
  perspective: NavigationShellReducer,
  quickReconcile: QRCategorySummarySlice,
  planTracker,
  worklist,
  cart,
});

export const rootReducer = (state: AppState | undefined, action: Action): ReturnType<typeof appReducer> => {
  // this exists a fall back to resetting initial state
  // doesnt actually work though, because the scope is immediately refetched
  if (action.type === 'RESET_THE_WORLD') {
    return appReducer(window.__debug.bootState!, action);
  }
  if (isStepAction(action) && state) {
    return (action as StepAction<AppState>).step(state);
  }

  return appReducer(state, action);
};
