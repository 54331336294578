import * as React from 'react';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import styles from './DetailsStrip.styles';
import { IMG_URI } from 'src/utils/Domain/Constants';
import Renderer from 'src/utils/Domain/Renderer';
import { DataItem } from 'src/common-ui/components/Charts/PerformanceChart';
import { isFunction, isEqual } from 'lodash/fp';
import { resolvePath } from 'src/cdn';

import noImagePath from 'src/common-ui/images/noimage.jpg';
import CenteredImage from 'src/components/CenteredImage/CenteredImage';
const noImage = resolvePath(noImagePath);

export type DetailsStripProps = {
  sections: TenantConfigViewData;
  item: DataItem | undefined;
  openStore?: (selectedItem: DataItem) => void;
};

export default class DetailsStrip extends React.Component<DetailsStripProps> {
  constructor(props: DetailsStripProps) {
    super(props);
  }

  clickStoreGroup = () => {
    if (isFunction(this.props.openStore) && this.props.item !== undefined) {
      this.props.openStore(this.props.item);
    }
  };

  render() {
    const { sections, item = {} } = this.props;
    const imageSrc: string = item[IMG_URI] ? (item[IMG_URI] as string) : resolvePath(noImage);

    return (
      <div className={styles.detailsStrip} data-qa="detailStrip">
        <div className="imageContainer">
          <CenteredImage
            src={imageSrc}
            width={styles.imgWidth}
            height={styles.imgHeight}
            extraContainerClass={'image'}
          />
        </div>
        <div className="sectionsContainer">
          <div className="detailStripTitle">
            {Object.keys(item).length > 0 && Renderer.renderJustValue(item, sections.title!)}
            <div data-qa-action="open-store-group" className={styles.carouselBtn} onClick={this.clickStoreGroup}>
              Store Group
            </div>
          </div>
          {!isEqual(item, {}) &&
            sections.view.map((section, index) => {
              if (section.visible === false) {
                return;
              }
              return (
                <div key={index} className="sectionTile">
                  <div className="tileTitle">{section.text}</div>
                  <div className="tileText">
                    {Renderer.renderJustValue(item[section.dataIndex || section.text], section)}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
