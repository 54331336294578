import { Dispatch } from 'react-redux';
import {
  updateGroupBy,
  updateSortBy,
  maybeUpdateSortByDirection,
  updatePareDown,
  updateFavoritesList,
  updateCountLimit,
  ReduxSlice as SubheaderSlice,
  updateConfigureSelections,
} from '../Subheader/Subheader.slice';
import { AppState } from 'src/store';
import store from 'src/store';
import { FAVORITE_EXCLUDE_KEYS } from 'src/utils/Domain/Constants';
import { FavoriteListItem, FavoriteListItemStorage, FavoriteResponseItem } from '../Subheader/Favorites/FavoritesMenu';
import { isArray, isNumber, omit, xor } from 'lodash';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { coalesce } from 'src/utils/Functions/Coalesce';

const updateSubheader = (config: FavoriteListItemStorage, subheader: SubheaderSlice, dispatch?: Dispatch<AppState>) => {
  if (dispatch) {
    if (isNumber(config.groupBySelection) && config.groupBySelection >= 0) {
      if (config.groupByDataIndexSelection) {
        const selection = subheader.groupBy.options.findIndex((x) => x.dataIndex === config.groupByDataIndexSelection);
        dispatch(updateGroupBy(selection));
      } else {
        dispatch(updateGroupBy(config.groupBySelection));
      }
    }
    if (isNumber(config.sortBySelection) && config.sortBySelection >= 0) {
      if (config.sortByDataIndexSelection) {
        const selection = subheader.sortBy.options.findIndex((x) => x.dataIndex === config.sortByDataIndexSelection);
        dispatch(updateSortBy(selection));
      } else {
        dispatch(updateSortBy(config.sortBySelection));
      }
    }
    if (config.sortByDirection) {
      dispatch(maybeUpdateSortByDirection(config.sortByDirection));
    }
    if (config.pareDownSelections) {
      dispatch(updatePareDown(config.pareDownSelections));
    }
    if (isNumber(config.limitSelection) && config.limitSelection >= 0) {
      dispatch(updateCountLimit(config.limitSelection));
    }
    if (isArray(config.configurationSelections)) {
      dispatch(updateConfigureSelections(config.configurationSelections));
    }
  }
};

export const updateGroupSortViaLocal = (defnId: string | null, dispatch: Dispatch<AppState>) => {
  const config = defnId !== null ? localStorage.getItem(defnId) : undefined;
  const subheaderState = store.getState().subheader;
  if (config) {
    const localConfig: FavoriteListItemStorage = JSON.parse(config);
    updateSubheader(localConfig, subheaderState, dispatch);
  }
};

export const getFavoriteConfigOriginalKeys = (
  config: TenantConfigViewData,
  unmodifiedViewDefn: TenantConfigViewData,
  getColumns?: () => string[]
): TenantConfigViewData => {
  // If the original viewDefn has the excludeKeys property, strip those out and add the original viewDefns version
  const newConfig = {
    ...unmodifiedViewDefn,
    ...omit(config, FAVORITE_EXCLUDE_KEYS),
  };

  // Compare the new config with the current viewDefn
  // Add/remove columns based off their presence in the current viewDefn
  const unmodifiedCols = coalesce(unmodifiedViewDefn.view, unmodifiedViewDefn.columns, [])!;
  const newCols = coalesce(newConfig.view, newConfig.columns, [])!;

  const unmodifiedDataIndexes = getColumns ? getColumns() : unmodifiedCols.map((x) => x.dataIndex);
  const newDataIndexes = newCols.map((x) => x.dataIndex);

  const diff = xor(newDataIndexes, unmodifiedDataIndexes);
  console.log('diff:', diff);
  diff.forEach((dataIndex) => {
    const unmodifiedIndex = unmodifiedDataIndexes.findIndex((x) => x == dataIndex);
    const newIndex = newDataIndexes.findIndex((x) => x == dataIndex);
    if (newIndex > -1 && unmodifiedIndex == -1) {
      // If a column is present in the favorite and not in the viewDefn, remove it
      newCols.splice(newIndex, 1);
    } else if (newIndex == -1 && unmodifiedIndex > -1) {
      // If a column is not present in the favorite, but is in the viewDefn, add it
      newCols.splice(unmodifiedIndex, 0, unmodifiedCols[unmodifiedIndex]);
    }
  });
  const retObj = {
    ...newConfig,
    view: newCols,
    columns: newCols,
  };

  return retObj;
};

export const getFavoriteConfigWithoutKeys = (config: TenantConfigViewData): TenantConfigViewData => {
  // If the original viewDefn has the excludeKeys property, strip those out and add the original viewDefns version
  return omit(config, FAVORITE_EXCLUDE_KEYS) as any;
};

export const getLocalConfig = (
  _defnStr: string | null,
  favoritesList: FavoriteResponseItem[],
  dispatch: Dispatch<AppState>,
  unmodifiedViewDefn?: TenantConfigViewData,
  getColumns?: () => string[]
) => {
  const state = store.getState();
  const perspective = state.perspective.selected;
  if (!perspective) {
    return;
  }

  dispatch(updateFavoritesList(favoritesList));

  const subheaderState = state.subheader;
  const activeFavorite = favoritesList.find((x) => x.active);

  if (activeFavorite && activeFavorite.jsonBlob) {
    const localConfig: FavoriteListItem = activeFavorite.jsonBlob;

    // In case the favorite has keys that aren't supposed to be there, strip them and add the original configs'

    if (localConfig && localConfig.config && unmodifiedViewDefn) {
      const formattedConfig = getFavoriteConfigOriginalKeys(localConfig.config, unmodifiedViewDefn, getColumns);
      const finalConfig: FavoriteListItem = {
        ...localConfig,
        config: formattedConfig,
      };
      updateSubheader(finalConfig, subheaderState, dispatch);
      return finalConfig;
    } else {
      updateSubheader(localConfig, subheaderState, dispatch);
      return localConfig;
    }
  }
  return;
};
