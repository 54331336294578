import React from 'react';
import { connect } from 'react-redux';
import { z } from 'zod';
import { cloneDeep, find, flow, get, isEmpty, isNil, noop, omitBy } from 'lodash';
import fp, { partial, groupBy } from 'lodash/fp';
import { AssortmentAddBySearchComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { AppState, AppThunkDispatch } from 'src/store';
import { StandardCard, StandardCardProps } from 'src/components/StandardCardView/StandardCard/StandardCard';
import Subheader from 'src/components/Subheader/Subheader.container';
import CardsGrid from 'src/common-ui/components/CardsGrid/CardsGrid';
import { Button, TextareaAutosize } from '@material-ui/core/';
import Tooltip from '@material-ui/core/Tooltip';
import { toast } from 'react-toastify';
import ServiceContainer from 'src/ServiceContainer';
import {
  requestTenantConfig,
  receiveTenantConfig,
  requestSearchData,
  receiveSearchData,
  AssortmentAddSlice,
  initialState,
  AssortmentAddSearchDataState,
} from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentAdd.slice';
import { getProcessedData } from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentAddBySearch/AssortmentAddBySearch.selectors';
import { withFab, FabType } from 'src/components/higherOrder/withFab';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { assortmentAddStylesLens, assortmentCartItemsLens, assortmentCartLens } from 'src/services/lenses/lenses';
import service from 'src/ServiceContainer';
import { errorToLoggingPayload, logError } from 'src/services/loggingService';
import { ASSORTMENT, IMG_URI, STYLE_ID } from 'src/utils/Domain/Constants';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { RightContainerPayloadType } from 'src/components/RightContainer/RightContainer';
import { CardViewItem } from 'src/components/StandardCardView/UIData.types';
import { generateCardProps, retrieveIdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.utils';
import { IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import { Lens } from 'monocle-ts';
import { update } from 'src/services/lenses/Lenses.actions';
import { makeCartService } from 'src/pages/AssortmentCart/AssortmentCart.service';
import { Typography } from '@material-ui/core';
import { ViewDataState } from 'src/types/Domain';
import { Overlay } from 'src/common-ui/index';
import { AssortmentCartSlice } from '../../../AssortmentCart/AssortmentCart.slice';
import cuid from 'cuid';
import { getCroppedImgFromString } from 'src/components/ImageCropper/ImageCropper/Cropper.utils';
import { watermarkBlob } from 'src/utils/Images/WatermarkImage';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { getStyleColorsWithAttributes, validateStyleName } from '../../StyleEdit/StyleEdit.client';
import { noImage } from '../AssortmentAddView.container';
import * as styles from './AssortmentAddBySearch.styles';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { processAdornments } from 'src/components/Adornments/Adornments';
import { preSetActivePage, setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';

type OwnProps = z.infer<typeof AssortmentAddBySearchComponentProps>;

function mapStateToProps(state: AppState, ownProps: OwnProps) {
  const { title, level, fabType = FabType.cart, cartItemType, fabTooltip, defns, showSelectAll } = ownProps;
  const { viewDefn, selectedItemsForCart, tenantConfigLoading, viewDataState } = assortmentAddStylesLens.get(state);
  const currentAdornments: AdornmentType[] = get(viewDefn, 'adornments', []);
  const adornments = processAdornments(currentAdornments);

  const processedData = getProcessedData(state);
  const loaded =
    !tenantConfigLoading &&
    (viewDataState === ViewDataState.idle ||
      viewDataState === AssortmentAddSearchDataState.noSearchItemsFound ||
      viewDataState === ViewDataState.liveDataReady);
  const assortmentCartLink = state.perspective.viewLinks.find((view) => view.name === 'Assortment Cart')?.link;

  return {
    title,
    loaded,
    viewDataState,
    config: viewDefn,
    fabType,
    fabTooltip,
    isFabDisabled: selectedItemsForCart.length <= 0,
    cartCount: selectedItemsForCart.length,
    level,
    cartItemType,
    modelDefn: defns.model,
    searchData: processedData,
    showSelectAll,
    adornments,
    assortmentCartLink,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps) {
  const { defns, level } = ownProps;
  const selectionService = makeCartService(
    assortmentAddStylesLens.compose(Lens.fromProp<AssortmentAddSlice>()('selectedItemsForCart'))
  );
  const cartItemsToAdd = assortmentCartLens.compose(Lens.fromProp<AssortmentCartSlice>()('cartItemsToAddCount'));

  return {
    onShowView() {
      dispatch(requestTenantConfig());
      service.tenantConfigClient
        .getTenantViewDefns({
          defnIds: defns.view,
          appName: ASSORTMENT,
        })
        .then((resp) => {
          const config = resp[0];
          dispatch(
            receiveTenantConfig({
              viewDefn: config,
              modelDefn: defns.model,
              level,
            })
          );
        })
        .catch((error) => {
          logError('An error occurred fetching add to assortment configs', error);
        });
    },
    onDestroy() {
      dispatch(update(assortmentAddStylesLens.set(initialState), 'Clear Select Styles slice.'));
    },
    onItemClicked(item: ContainerPayload) {
      dispatch((_ignore: AppThunkDispatch, getState: () => AppState) => {
        const config = assortmentAddStylesLens.compose(Lens.fromProp<AssortmentAddSlice>()('viewDefn')).get(getState());
        if (get(config, 'main.assortment')) {
          const { searchData } = assortmentAddStylesLens.get(getState());
          if (item.type !== RightContainerPayloadType.Filter && item.type !== RightContainerPayloadType.Undo) {
            const identityPropsConfig = retrieveIdentityPropsConfig(config);
            const foundPivotItem = find(searchData, (i) => i[identityPropsConfig.id] === item.id);
            if (foundPivotItem == null) {
              return;
            }
            dispatch(update(partial(selectionService.toggleItem, [foundPivotItem]), `Toggle item in 'to cart' list.`));
          }
        } else {
          noop();
        }
      });
    },
    onToggleAll(actionType: 'select' | 'deselect') {
      dispatch((_ignore: AppThunkDispatch, getState: () => AppState) => {
        const config = assortmentAddStylesLens.compose(Lens.fromProp<AssortmentAddSlice>()('viewDefn')).get(getState());
        if (get(config, 'main.assortment')) {
          const { searchData } = assortmentAddStylesLens.get(getState());
          if (actionType === 'select') {
            dispatch(
              update(partial(selectionService.addItemsToCart, [searchData]), `Add all items in 'to cart' list.`)
            );
          } else {
            dispatch(update(selectionService.removeAllItemsFromCart, `Remove all items from 'to cart' list.`));
          }
        }
      });
    },
    addSelectedItemsToCart(itemCount: number) {
      dispatch(update(cartItemsToAdd.asSetter().set(itemCount), 'Items to Add Count Updated'));
      dispatch(async (_dispatch: AppThunkDispatch, getState: () => AppState) => {
        const { selectedItemsForCart: selectionItems, assortmentRange } = assortmentAddStylesLens
          .compose(Lens.fromProps<AssortmentAddSlice>()(['selectedItemsForCart', 'assortmentRange']))
          .get(getState());
        const cartService = makeCartService(assortmentCartItemsLens);
        if (selectionItems.length <= 0) {
          return;
        }
        const groupedSelections = groupBy(STYLE_ID, fp.cloneDeep(selectionItems));
        const validMembersListData = !!assortmentRange ? 'AssortmentStyleDependents' : 'HistoryStyleDependents';
        const { scopeRange } = assortmentAddStylesLens.get(getState());
        let extraParams = {};
        if (!isNil(scopeRange)) {
          if (!!assortmentRange) {
            extraParams = {
              salesStart: scopeRange.from,
              salesEnd: scopeRange.to,
            };
          } else {
            extraParams = {
              historyStart: scopeRange.from,
              historyEnd: scopeRange.to,
            };
          }
        }
        let allChoices: BasicPivotItem[];
        let styleAssortmentInfo: BasicPivotItem[] = [];
        await Promise.all([
          (async () => {
            allChoices = (
              await service.pivotService.listData(validMembersListData, ASSORTMENT, {
                topMembers: Object.keys(groupedSelections)
                  .filter(fp.isString)
                  .join(','),
                ...extraParams,
                nestData: false,
                sortBy: 'slsu',
              })
            ).flat;
          })(),
          (async () => {
            if (ownProps.cartItemType === 'existing') {
              const topMembers = Object.keys(groupedSelections)
                .filter(fp.isString)
                .join(',');
              styleAssortmentInfo = await getStyleColorsWithAttributes(topMembers, true);
            }
          })(),
        ]);
        const finalStyleSelections = await Promise.all(
          fp.map(async (item: BasicPivotItem) => {
            item.type = ownProps.cartItemType;
            item.name = item['member:style:name'];
            item.description = item['member:style:description'];
            item.id = item[STYLE_ID];
            item._id = cuid();
            item.valid = true;
            if (ownProps.cartItemType === 'similar') {
              item.name = `S5_${item['member:style:name']}`;
              item.valid = await validateStyleName(item.name);
            }
            if (ownProps.cartItemType === 'existing') {
              const chosenStyles = styleAssortmentInfo.filter((style) => {
                return style[STYLE_ID] === item.id;
              });
              if (chosenStyles.length > 0) {
                item.assortmentChildren = chosenStyles[0].children;
              }
            }
            if (ownProps.cartItemType === 'similar' || item[IMG_URI] === '') {
              const scaledImage = await getCroppedImgFromString(item[IMG_URI]);
              const watermarkedImage = await watermarkBlob(scaledImage).catch((_err) => noImage);
              if (watermarkedImage) {
                item[IMG_URI] = watermarkedImage.replace(/generation=[0-9]*&/, '');
                item.isWatermarked = true;
              }
            }
            const styleChoices = await Promise.all(
              allChoices
                .filter((unfilteredChocie) => {
                  return unfilteredChocie[STYLE_ID] === item.id;
                })
                .map(async (styleChoice) => {
                  const final = omitBy(styleChoice, (_v, k) => {
                    if (['dimension', 'shortestPathToRoot', 'ordered', 'index'].indexOf(k) >= 0) {
                      return true;
                    }
                    return false;
                  }) as BasicPivotItem;
                  final.type = ownProps.cartItemType;
                  if (ownProps.cartItemType === 'similar') {
                    const scaledImage = await getCroppedImgFromString(final[IMG_URI]);
                    const watermarkedImage = await watermarkBlob(scaledImage).catch(() => noImage);
                    if (watermarkedImage) {
                      final[IMG_URI] = watermarkedImage.replace(/generation=[0-9]*&/, '');
                      final.isWatermarked = true;
                    }
                  }
                  return final;
                })
            );
            if (ownProps.level === 'stylecolors') {
              const idsToAdd = fp.map('id', fp.get(item.id, groupedSelections));
              item.children = styleChoices.filter((choice) => {
                return idsToAdd.indexOf(choice.id) >= 0;
              });
            } else {
              item.children = styleChoices;
            }
            item.currentChildren = cloneDeep(styleChoices);
            return item;
          }, fp.uniqBy(STYLE_ID, selectionItems))
        );
        // add the selection to the cart
        finalStyleSelections.forEach((style) => {
          dispatch(
            update((s: AppState): AppState => {
              return cartService.concatChildToItem(
                // @ts-ignore
                (style as unknown) as BasicPivotItem,
                ((style as unknown) as BasicPivotItem).children,
                ownProps.cartItemType || 'existing',
                s
              );
            }, `Adding style and stylecolors to cart.`)
          );
        });
        // And finally remove everything from the selection, since it's in the cart now
        dispatch(update(selectionService.removeAllItemsFromCart, 'Clear Select Styles cart.'));
        dispatch(update(cartItemsToAdd.set(0), 'Clear Item Count in Add'));
        // clear search data, in case they come back and start a new search
        dispatch(receiveSearchData({ searchData: [], searchDataState: ViewDataState.idle }));
      });
    },
    async onSearch(search: string[], level: string) {
      dispatch(requestSearchData());

      try {
        // FIXME: want this to occur in a thunk but I ran into an infinite loop issue
        const searchData = await service.pivotService.asstAddSearchSubmitData(search, level);
        if (isEmpty(searchData)) {
          // [] is what the server sends when it finds nothing
          return dispatch(
            receiveSearchData({ searchData: [], searchDataState: AssortmentAddSearchDataState.noSearchItemsFound })
          );
        }
        const coordinates = searchData.map((r) => ({ [r.levelId]: r.id }));
        const decoratedData = await service.pivotService.redecorate({
          coordinates,
          defnId: defns.model,
          nestData: false,
          aggBy: [`level:${level}`],
        });
        return dispatch(receiveSearchData({ searchData: decoratedData, searchDataState: ViewDataState.liveDataReady }));
      } catch (error) {
        toast.error('An error occured searching for items');
        receiveSearchData({ searchData: [], searchDataState: ViewDataState.idle });
        ServiceContainer.loggingService.error('An error occured searching for items', errorToLoggingPayload(error));
        return;
      }
      return;
    },
    onClearSearch() {
      dispatch(update(selectionService.removeAllItemsFromCart, `Remove all items from 'to cart' list.`));
      dispatch(receiveSearchData({ searchData: [], searchDataState: ViewDataState.idle }));
    },
    setActivePage(data: string) {
      dispatch(preSetActivePage());
      dispatch(setActivePage(data));
    },
  };
}

function getGridItems(
  data: CardViewItem[],
  onItemClick: (item: ContainerPayload) => void,
  identityConfig: IdentityPropsConfig = {} as IdentityPropsConfig,
  adornments: AdornmentType[] = []
): StandardCardProps[] {
  return data.map((styleItem) =>
    generateCardProps({
      styleItem,
      onItemClick,
      identityPropsConfig: identityConfig,
      adornments,
    })
  );
}

function mergeProps(stateProps: ReturnType<typeof mapStateToProps>, dispatchProps: ReturnType<typeof dispatchToProps>) {
  const { config, searchData, adornments } = stateProps;
  const { onItemClicked } = dispatchProps;
  const maybeIdentityPropsConfig = config ? retrieveIdentityPropsConfig(config) : undefined;
  const gridItems = getGridItems(get(searchData, '[0].items', []), onItemClicked, maybeIdentityPropsConfig, adornments);
  return {
    ...stateProps,
    ...dispatchProps,
    gridItems,
    isSelectAllDisabled: gridItems.length === 0,
  };
}

type AssortmentAddBySearchProps = ReturnType<typeof mergeProps>;
type AssortmentAddBySearchState = {
  textInput: string;
};
class AssortmentAddBySearch extends React.Component<AssortmentAddBySearchProps, AssortmentAddBySearchState> {
  constructor(props: AssortmentAddBySearchProps) {
    super(props);
    this.state = {
      textInput: '',
    };
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentWillUnmount() {
    if (this.props.onDestroy) {
      this.props.onDestroy();
    }
  }

  onFabClick = () => {
    switch (this.props.fabType) {
      case FabType.cart:
        const { cartCount, addSelectedItemsToCart } = this.props;
        if (!isNil(addSelectedItemsToCart) && !isNil(cartCount) && !isNil(this.props.assortmentCartLink)) {
          addSelectedItemsToCart(cartCount);
          this.props.setActivePage(this.props.assortmentCartLink);
          window.location.hash = this.props.assortmentCartLink;
        }
        break;
    }
  };

  onToggleAll = () => {
    const { searchData, cartCount, onToggleAll } = this.props;
    const dataLength = get(searchData, '[0].items', []).length;
    const actionType = dataLength === cartCount ? 'deselect' : 'select';
    onToggleAll(actionType);
  };

  onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textInput = event.target.value;
    this.setState({
      textInput,
    });
  };

  onSearchClick = () => {
    const { level, onSearch } = this.props;
    // TODO: text validation requirements (only allow alphanumerics, dashes, underscores, and commas)
    const validated = this.state.textInput.replace(' ', '').split(';');
    const trimmedLevel = level === 'styles' ? 'style' : level === 'stylecolors' ? 'stylecolor' : level;
    onSearch(validated, trimmedLevel);
  };
  onEnterPress = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (evt.key === 'Enter' && evt.ctrlKey) {
      evt.preventDefault();
      this.onSearchClick();
    }
  };
  onClearButtonClick = () => {
    this.setState({ textInput: '' });
    this.props.onClearSearch();
  };
  render() {
    const { loaded, gridItems, viewDataState } = this.props;
    const cardWidth = StandardCard.calcCardWidth(12);
    const cardHeight = StandardCard.calcCardHeight();

    return (
      <div className={styles.dataContainer}>
        <Overlay type="loading" visible={!loaded} fitParent={false} />
        <Subheader title={'Add Choices by Search'} showFlowStatus={false} showSearch={false} />
        <div className={styles.contentContainer}>
          <section className={styles.searchContainer}>
            <Typography variant="subtitle1" classes={{ root: styles.subTitle }}>
              Enter Choice List (separated by a semicolon):
            </Typography>
            <TextareaAutosize
              minRows={10}
              maxRows={10}
              onChange={this.onTextChange}
              value={this.state.textInput}
              style={styles.textArea}
              onKeyDown={this.onEnterPress}
            />
            <Tooltip title={'Control + Enter to search'} placement="right">
              <Button
                variant="outlined"
                disabled={isEmpty(this.state.textInput)}
                onClick={this.onSearchClick}
                classes={{ root: styles.buttonSearch }}
              >
                Search
              </Button>
            </Tooltip>
            <Button
              variant="outlined"
              disabled={isEmpty(this.state.textInput)}
              onClick={this.onClearButtonClick}
              classes={{ root: styles.buttonClear }}
            >
              Clear Search
            </Button>
          </section>
          {(() => {
            switch (viewDataState) {
              case ViewDataState.liveDataReady:
                return (
                  <CardsGrid
                    gridItems={gridItems}
                    cardWidth={cardWidth}
                    cardHeight={cardHeight}
                    additionalWidth={300}
                  />
                );
              case AssortmentAddSearchDataState.noSearchItemsFound:
                return <div className={styles.noData}>No Items Found</div>;
              case ViewDataState.idle:
                return (
                  <div className={styles.noData}>
                    Search for items at the left (use a semicolon to search for multiple styles)
                  </div>
                );
              default:
                return '';
            }
          })()}
        </div>
      </div>
    );
  }
}

const wrappedView = flow(() => AssortmentAddBySearch, withFab, makePopoverSensitive)();

export default connect(mapStateToProps, dispatchToProps, mergeProps)(wrappedView);
