import { style } from 'typestyle';
import { highlight, fadedNeutral, smallMediumFont } from '../../theme';
import { mediumGutterWidth, mediumBorderColor, smallFont, borderHighlightColor } from 'src/utils/Style/Theme';
export const cardHeight = 120;
export const listViewWidth = 320;
export const companionExpandedWidth = 300;
export const companionWidthMin = 100;
export const flatCardStyle = style({
  height: cardHeight,
  width: '100%',
  border: '1px solid transparent',
});

const dropdownStyles = {
  cursor: 'pointer',
  display: 'inline-block',
  margin: '0 .5rem',
  flex: 3,
  height: 24,
  $nest: {
    '.s5-MuiInputBase-root': {
      width: '100%',
    },
    '.s5-MuiSelect-selectMenu': {
      width: '100%',
      color: highlight,
      overflow: 'hidden',
      justifyContent: 'center',
    },
    '.s5-MuiInputBase-input': {
      padding: '4px 0 2px !important',
      fontSize: 14,
    },
  },
};

export const levelByDropDownStyle = style({
  ...dropdownStyles,
  padding: '0 0.6rem 0 0.45rem !important',
});
export const dropDownStyle = style({
  ...dropdownStyles,
  padding: '0 0.6rem 0 0.6rem !important',
});
export const menuListStyle = style({
  marginTop: '0.4rem',
  $nest: {
    '& ul': {
      paddingBottom: 0,
    },
  },
});
export const companionListViewStyle = style({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '0.5rem',
  marginRight: 20,
  flexShrink: 0,
  $nest: {
    '.scroll-target': {
      border: '1px solid ' + highlight,
    },
    '> header': {
      display: 'flex',
      flexDirection: 'column',
      padding: '1rem',
      $nest: {
        '.expander-container': {
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
          justifyContent: 'space-between',
          $nest: {
            '.dropdown': {
              flex: 'initial',
              margin: 0,
              width: '1.7rem',
            },
            '.expander': {
              fontSize: '1rem',
              cursor: 'pointer',
              lineHeight: 0.7,
              transform: 'scaleY(1.7)',
              color: fadedNeutral,
            },
          },
        },
        '.count': {
          fontSize: '0.7rem',
          color: fadedNeutral,
        },
        '.extra-controls': {
          marginTop: '0.5rem',
          display: 'flex',
          flexDirection: 'column',
          $nest: {
            '.check-label': {
              fontSize: smallMediumFont,
            },
            '.form-control': {
              fontSize: smallMediumFont,
            },
            '.input-group-text': {
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'space-between',
              marginTop: '10px',
              border: 'none',
              backgroundColor: '#ffffff',
            },
          },
        },
        '.control-bar': {
          alignItems: 'center',
          display: 'flex',
          fontSize: '1rem',
          justifyContent: 'space-between',
          marginTop: 5,
          padding: 2,
          $nest: {
            label: {
              marginBottom: 0,
            },
          },
        },
        '.level-bar': {
          marginTop: 5,
          marginLeft: 25,
        },
      },
    },
    '.list-container': {
      flex: 1,
    },
    '.flat-card': {
      cursor: 'pointer',
      $nest: {
        '&:hover img': {
          transition: 'transform 0.3s',
          transform: 'translateX(-5px)',
        },
        '&.selected': {
          color: highlight,
        },
      },
    },
    '&.collapsed': {
      $nest: {
        '> header .expander-container .expander': {
          transform: 'scaleY(1.7) rotate(180deg)',
        },
        '.control-bar': {
          display: 'none',
        },
        '.extra-controls': {
          display: 'none',
        },
        '.count': {
          display: 'none',
        },
      },
    },
    '.spacer': {
      flex: 1,
    },
    '.sort-dir': {
      color: highlight,
      cursor: 'pointer',
      fontSize: '1.2rem',
    },
  },
});
export const companionBorder = '1px solid ' + mediumBorderColor;

export function makeListPairStyle(isCollapsed: boolean) {
  const companionWidth = isCollapsed ? companionWidthMin : companionExpandedWidth;

  return style({
    width: `calc(100% - ${companionWidth}px - ${mediumGutterWidth}px)`,
    marginLeft: mediumGutterWidth,
    overflowX: 'hidden',
  });
}

export const companionStyles = style({
  borderRight: companionBorder,
  width: companionExpandedWidth,
  $nest: {
    '&.scroll-target': {
      borderColor: borderHighlightColor,
    },
    '.control-bar > label': {
      fontSize: smallMediumFont,
    },
    '.control-bar .dropdown': {
      fontSize: smallMediumFont,
      alignItems: 'center',
      display: 'flex',
    },
    '.control-bar .dropdown-item': {
      fontSize: smallFont,
    },
    '&.collapsed': {
      maxWidth: 115,
    },
    '> header': {
      borderBottom: companionBorder,
    },
    '.id': {
      fontSize: '0.7rem',
    },
    '.name': {
      height: 40,
    },
  },
});
