import { ICellRendererParams } from 'ag-grid-community';
import { getSeverityStyle } from './SeverityRenderer.styles';
import * as React from 'react';
import { classes } from 'typestyle';

export type Params = ICellRendererParams & {
  data: {
    severitycounts: number[];
    type: 'goodTrend' | 'badTrend';
  };
};

export class SeverityRenderer extends React.Component<Params> {
  constructor(props: Params) {
    super(props);
  }

  render() {
    const {
      data: { severitycounts, type },
    } = this.props;

    if (severitycounts && typeof severitycounts === 'string' && type && typeof type === 'string') {
      const sevArray = severitycounts.split(',');
      return (
        <div className={getSeverityStyle()}>
          {sevArray.map((v, idx) => (
            <section
              style={{
                backgroundColor:
                  type == 'good' ? `rgba(82, 182, 135, ${1 - idx / 5})` : `rgba(226, 119, 110, ${1 - idx / 5})`,
              }}
              className={classes('severity-section', `severity-${type}`)}
              key={idx}
            >
              <p style={{ fontSize: 12 }}>{v.toString()}</p>
            </section>
          ))}
        </div>
      );
    }
    return <React.Fragment />;
  }
}
