import { AxiosInstance } from 'axios';
import { TenantConfig } from './bindings.types';
import { BoundTab, BoundTenant, BoundPerspective, BoundRoute } from './bindings.types';
import { bindTenant, bindPerspectives } from './bindings.utils';
import { validateConfDefn } from './codecs/confdefn';
import { BehaviorSubject, Observable, firstValueFrom, filter, map, from, of, defer } from 'rxjs';
import { PerspectiveConfig } from 'src/pages/PerspectiveSelection/PerspectiveSelection';
import { get } from 'lodash';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { viewPath } from 'src/dao/tenantConfigClient';

const appConfigPath = '/api/uidefn/uiconfig';
const fingerPath = '/api/uidefn/config/fingerprint';

export interface IsDelayed {
  whenDone(): Promise<void>;
}

export interface BoundTabs {
  defaultPathSlot: string;
  tabs: BoundTab[];
}

export interface HasPerspectives {
  getPerspectives(): BoundPerspective[];
  getPerspectivesWithRoutes(): (BoundPerspective | BoundRoute)[];
}

export interface HasBindings {
  getBindings(perspective: BoundPerspective): BoundTenant;
}

export interface HasTimeout {
  getTimeout(): number;
}

interface AppConfiguration {
  tenant: TenantConfig;
  perspective: PerspectiveConfig;
}

export type ConfigurationService = IsDelayed &
  HasPerspectives &
  HasBindings &
  HasTimeout & {
    getOrThrow(): TenantConfig;
    configuration$: Observable<AppConfiguration>;
    errored$: Observable<Error | null>;
  };

export const fetchFingerPrint = (axios: AxiosInstance) => {
  return () => {
    return axios.get(`${fingerPath}`).then((resp): string => {
      return resp.data;
    });
  };
};

export default (axios: AxiosInstance) => (appName: string): ConfigurationService => {
  // Log the initialization
  // We make no effort to dispose subscriptions so this is an easy way of
  // verify that we aren't leaking
  console.info(`Initializing ${appName} configuration service`);
  // Most recent tenant config
  const configuration$: BehaviorSubject<AppConfiguration | null> = new BehaviorSubject<AppConfiguration | null>(null);
  const error$: BehaviorSubject<Error | null> = new BehaviorSubject<Error | null>(null);

  // Immediatley attempt to load the configuration.
  function requestConfig(): Observable<AppConfiguration> {
    async function loadAppConfigurations() {
      const tenantRes = await axios.get(`${appConfigPath}?appName=${appName}`);
      const perspectiveRes = await axios.get<PerspectiveConfig>(`${viewPath}/${ASSORTMENT}/Perspectives`);
      const perspective: PerspectiveConfig = perspectiveRes.data;
      const tenantResData = get(tenantRes, 'data.data');
      const tenant: TenantConfig = validateConfDefn(tenantResData);
      return { tenant, perspective };
    }
    return defer(() => loadAppConfigurations());
  }

  requestConfig().subscribe({
    next: (v) => configuration$.next(v),
    error: (e) => {
      console.error('configuration terminally failed', e);
      error$.next(e);
    },
    complete: () => console.log(`${appName} configuration completed`),
  });

  function assertReady(): AppConfiguration {
    const e = error$.getValue();
    if (e) {
      throw e;
    }
    const c = configuration$.getValue();
    if (!c) {
      throw new Error('Configuration has not been received yet.');
    }
    return c;
  }

  function whenDone(): Promise<void> {
    return firstValueFrom(configuration$.pipe(filter((v) => !!v)).pipe(map(() => undefined)));
  }

  function getPerspectives(): BoundPerspective[] {
    assertReady();
    // Safe due to assertReady
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { tenant, perspective } = configuration$.getValue()!;
    return bindPerspectives(tenant, perspective);
  }

  function getPerspectivesWithRoutes(): (BoundPerspective | BoundRoute)[] {
    assertReady();
    // Safe due to assertReady
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { perspective } = configuration$.getValue()!;
    return perspective.view;
  }

  function getBindings(perspective: BoundPerspective): BoundTenant {
    assertReady();
    // Safe due to assertReady
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { tenant } = configuration$.getValue()!;
    return bindTenant(perspective)(tenant);
  }

  function getTimeout(): number {
    assertReady();
    // Safe due to assertReady
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { tenant } = configuration$.getValue()!;
    return tenant.idleTimeout * 1000 * 60;
  }

  // Only extant configs for downstream to use
  const loadedConfiguration$ = configuration$.pipe(filter((v) => !!v)).pipe(map((v) => v!));

  function getOrThrowConf() {
    assertReady();
    // Safe due to assertReady
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return configuration$.getValue()![0];
  }

  return {
    whenDone,
    getPerspectives,
    getPerspectivesWithRoutes,
    getBindings,
    getTimeout,
    getOrThrow: getOrThrowConf,
    configuration$: loadedConfiguration$,
    errored$: error$,
  };
};
