import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import { styles } from './StoreGroupChart.styles';
import { TenantConfigViewItem, BasicTenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { attempt, isEqual } from 'lodash/fp';
import { Overlay } from 'src/common-ui';
import { resolvePath } from 'src/cdn';
import Renderer from 'src/utils/Domain/Renderer';

import noImagePath from 'src/common-ui/images/noimage.jpg';
import CenteredImage from 'src/components/CenteredImage/CenteredImage';
const noImage = resolvePath(noImagePath);

export type ValueProps = {
  view: TenantConfigViewItem; // TenantConfigView {view: [], text: ...}
  tlData: { title: string; data: BasicPivotItem[] }[];
  img: string;
  title: string;
  itemId: string;
  isLoading: boolean;
};

export type FunctionProps = {
  onShowView: () => void;
  onDestroy: () => void;
  onItemUpdate: () => void;
};

type AllProps = ValueProps & FunctionProps;

function _chartProcess(data: BasicPivotItem[] = [], view: BasicTenantConfigViewItem[]) {
  return view.map((viewItem) => {
    function numberFormatter(val: number) {
      return viewItem.renderer != null ? Renderer[viewItem.renderer](val) : val;
    }
    function formatter(this: { series: { name: string; index: number }; y: number }) {
      const val = numberFormatter(this.y);
      return `${this.series.name}: <b>${val}</b><br/>`;
    }
    switch (viewItem.chartType) {
      case 'spline':
        return {
          type: 'spline',
          name: viewItem.text,
          yAxis: 1,
          tooltip: {
            pointFormatter: formatter,
          },
          data: data.map((dataItem) => ({
            y: dataItem[viewItem.dataIndex],
            dataLabels: {
              enabled: true,
              strokeWidth: '2px',
              strokeLinejoin: 'round',
              formatter: function(this: { y: number }) {
                return numberFormatter(this.y);
              },
            },
          })),
          credits: {
            enabled: false,
          },
        };
      case 'column':
      default:
        return {
          type: 'column',
          name: viewItem.text,
          yAxis: 0,
          tooltip: {
            pointFormatter: formatter,
          },
          data: data.map((dataItem) => dataItem[viewItem.dataIndex]),
          credits: {
            enabled: false,
          },
        };
    }
  });
}

function _axisProcess(data: BasicPivotItem[]) {
  return data.map((viewItem) => viewItem.name);
}

export default class StoreGroupChart extends React.Component<AllProps> {
  constructor(props: AllProps) {
    super(props);
  }

  componentDidMount() {
    attempt(this.props.onShowView);
    attempt(this.props.onItemUpdate);
  }

  componentDidUpdate(prevProps: AllProps) {
    if (!isEqual(prevProps.itemId, this.props.itemId) || !isEqual(prevProps.view, this.props.view)) {
      attempt(this.props.onItemUpdate);
    }
  }

  render() {
    const { view, tlData, img, title, isLoading } = this.props;
    const imageSrc: string = img ? (img as string) : noImage;
    const charts = tlData.map((data, ind) => {
      const config = {
        title: {
          text: data.title,
        },
        xAxis: {
          categories: _axisProcess(data.data),
        },
        yAxis: [
          {
            title: {
              text: '',
              style: {
                color: '#89A54E',
              },
            },
          },
          {
            title: {
              text: '',
              style: {
                color: '#89A54E',
              },
            },
            opposite: true,
          },
        ],
        credits: {
          enabled: false,
        },
        series: _chartProcess(data.data, view.view || []),
      };
      return (
        <div key={ind} className={styles.chart}>
          <HighchartsReact options={config} immutable={true} />
        </div>
      );
    });
    return (
      <div data-qa-component="StoreGroup" className={styles.container}>
        <Overlay type={'loading'} visible={isLoading} />
        <header style={{ width: '100%' }}>
          <h2>{title}</h2>
        </header>
        <CenteredImage src={imageSrc} width={styles.IMGWIDTH} height={styles.IMGHEIGHT} />
        <div className={styles.chartCont}>{charts}</div>
      </div>
    );
  }
}
