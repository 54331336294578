import * as React from 'react';
import { isNil, isArray } from 'lodash/fp';
import { FormGroup } from '@material-ui/core';
import Select, { createFilter } from 'react-select';
import { Props as SelectProps } from 'react-select/lib/Select';
import styles from './InputSuggest.styles';
import { StylesConfig } from 'react-select/lib/styles';
import Option from 'react-select/lib/components/Option';
import SingleValue from 'react-select/lib/components/SingleValue';
import MultiValue from 'react-select/lib/components/MultiValue';
import { ValueType } from 'react-select/lib/types';
import { isString } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { classes } from 'typestyle';

const ErrorTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'rgba(247, 92, 105, 0.97)',
    color: '#FFF',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const QASelectOption = (props: any) => {
  const icon = props.data.icon && (
    <ErrorTooltip title="Conflict: Current value not found in valid values.">
      <i className={classes(styles.errorIcon, props.data.icon)} />
    </ErrorTooltip>
  );
  const option = props.data.icon ? (
    <div className={styles.errorContainer}>
      <Option {...props} />
    </div>
  ) : (
    <Option {...props} />
  );
  return (
    <div data-qa={`select-option-${props.data.value}`}>
      {icon}
      {option}
    </div>
  );
};

const QASelectSingleValue = (props: any) => {
  const icon = props.data.icon && (
    <ErrorTooltip placement="top" title="Conflict: Attribute not found in valid values.">
      <i className={classes(styles.errorIcon, props.data.icon)} />
    </ErrorTooltip>
  );
  const option = props.data.icon ? (
    <div className={styles.errorContainer}>
      <SingleValue {...props} />
    </div>
  ) : (
    <SingleValue {...props} />
  );
  return (
    <div data-qa={`select-option-${props.data.value}`}>
      {icon}
      {option}
    </div>
  );
};

const QASelectMultiValue = (props: any) => {
  return (
    <div data-qa={`select-value-${props.data.value}`}>
      <MultiValue {...props} />
    </div>
  );
};

type InputSuggestProps = {
  validValues: Suggestion[];
  selected: Suggestion[] | Suggestion | string | undefined;
  multiSelect?: boolean;
  valid?: boolean;
  placeholder?: string;
  onSelect?: (sugg: Suggestion) => void;
  onMultiSelect?: (suggs: ValueType<Suggestion>) => void;
  alwaysShowMenu?: boolean;
  styles?: StylesConfig;
  dataQa?: string;
  disabled?: boolean;
};

export type Suggestion = {
  label: string;
  value: string | number | object;
  icon?: string;
};

export default class InputSuggest extends React.Component<InputSuggestProps> {
  static defaultProps = {
    multiSelect: false,
  };

  constructor(props: InputSuggestProps) {
    super(props);
  }

  getSelection() {
    const { selected } = this.props;

    if (isNil(selected)) {
      return [];
    }
    // if it is returning a string, that means it wasn't found and is an error
    if (isString(selected)) {
      const errorSelection: Suggestion = {
        label: selected,
        value: selected,
        icon: 'fal fa-exclamation-triangle',
      };
      return errorSelection;
    }

    return isArray(selected) ? selected : [selected];
  }

  handleSingleSelect(selections: ValueType<Suggestion>) {
    const { onSelect } = this.props;
    let selection;
    if (isArray(selections)) {
      selection = selections[0];
    } else {
      selection = selections;
    }
    if (selection && onSelect) {
      onSelect(selection);
    }
  }

  handleMultiSelect(selections: ValueType<Suggestion>) {
    const { onMultiSelect } = this.props;
    if (onMultiSelect) {
      onMultiSelect(selections);
    }
  }

  onSuggestionsSelected = (selectedSuggestions: ValueType<Suggestion>) => {
    const { multiSelect } = this.props;

    if (multiSelect) {
      this.handleMultiSelect(selectedSuggestions);
    } else {
      this.handleSingleSelect(selectedSuggestions);
    }
  };

  render() {
    const { validValues, multiSelect, placeholder, alwaysShowMenu, dataQa, disabled } = this.props;

    const selected = this.getSelection();
    const selectProps: SelectProps<Suggestion> = {
      className: styles.selectContainer,
      isMulti: multiSelect,
      components: {
        Option: QASelectOption,
        SingleValue: QASelectSingleValue,
        MultiValue: QASelectMultiValue,
      },
      placeholder: placeholder || 'Search...',
      onChange: this.onSuggestionsSelected,
      value: selected,
      options: validValues,
      styles: this.props.styles,
      menuIsOpen: alwaysShowMenu || undefined, // property is only added if true
      isDisabled: !isNil(disabled) ? disabled : false,
      isSearchable: true,
      filterOption: createFilter({ ignoreAccents: false }),
    };
    return (
      <FormGroup className={styles.container}>
        <div data-qa={dataQa}>
          <Select {...selectProps} />
        </div>
      </FormGroup>
    );
  }
}
