import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import Adornments, { AdornmentsOwnProps } from 'src/components/Adornments/Adornments';

type AdornmentsGridRendererProps = ICellRendererParams & AdornmentsOwnProps;

export class AdornmentsGridRenderer extends React.Component<AdornmentsGridRendererProps> {
  constructor(props: AdornmentsGridRendererProps) {
    super(props);
  }

  render() {
    return <Adornments adornments={this.props.adornments} productId={this.props.productId} interactive={false} />;
  }
}
