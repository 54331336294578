import React from 'react';
import _ from 'lodash';
import { Renderer } from 'src/utils/Domain/Renderer';
import { Point, Series } from 'highcharts';
import { RenderProps } from './PieRender';
import HighchartsReact from 'highcharts-react-official';

export class ChartRender extends React.Component<RenderProps> {
  constructor(props: RenderProps) {
    super(props);
  }

  shouldComponentUpdate(nextProps: RenderProps) {
    return !_.isEqual(this.props.data, nextProps.data);
  }

  render() {
    const { data, view, config } = this.props;

    const bars = _.map(
      _.map(view.view, (c) => {
        return {
          title: c.text,
          view: c,
          series: _.map(c.view, (item) => ({
            name: item.text,
            type: 'column',
            innerSize: '40%',
            data: _.map(data, item.dataIndex),
          })),
        };
      }),
      (res, ind) => {
        const inconfig = _.cloneDeep(config);
        inconfig.title = {
          ...inconfig.title,
          text: res.title,
          floating: true,
          margin: 0,
          y: 5,
          verticalAlign: 'top',
        };
        inconfig.tooltip = {
          formatter: function(this: { point: Point; series: Series }) {
            const value = Renderer.renderJustValue(this.point.y, res.view);
            return `<small>
              ${this.point.category}
              </small><br />
              ${this.series.name}: <b>${value}</b>`;
          },
        };
        inconfig.xAxis = { categories: _.map(data, 'name') };
        inconfig.chart = {
          ...inconfig.chart,
          type: 'column',
          marginTop: 25,
        };
        const series = _.map(res.series, (d) => {
          return { name: d.name, data: d.data };
        });
        return (
          <HighchartsReact
            key={ind}
            options={{ ...inconfig, series }}
            containerProps={{
              style: { width: '33%', height: '100%' },
            }}
          />
        );
      }
    );
    return bars;
  }
}
