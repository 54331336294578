import { style } from 'typestyle';

export const favoritesList = style({
  $debugName: 'favorites',
  $nest: {
    '.favorite-menu-item': {
      paddingRight: 2,
      width: 160,
      $nest: {
        ['i']: {
          marginRight: 10,
        },
      },
    },
  },
});

export const favoritesNameStyle = style({
  width: 105,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

export const deleteButtonStyle = {
  fontSize: '1.1rem',
  padding: 0,
  marginLeft: 'auto',
  top: -2,
};
