import Axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { serverSuccessInterceptor, serverErrorInterceptor } from 'src/services/axios/serverError';
import { existingAccessToken, isUnauthorized } from 'src/services/auth/platform';
import { API_URL_PREFIX } from 'src/utils/Domain/Constants';

async function authenticationInterceptor(config: InternalAxiosRequestConfig) {
  const currentBearerToken = await existingAccessToken();
  config.headers.set('Authorization', `Bearer ${currentBearerToken}`);
  return config;
}

export function unauthorizedErrorInterceptor(error: AxiosError): Promise<AxiosError<any>> {
  if (error.response && error.response.status === 401) {
    // only clear access token if this is an actual unauthorized error
    isUnauthorized();
  }
  return Promise.reject(error);
}

const api = Axios.create({
  baseURL: API_URL_PREFIX,
});

api.interceptors.request.use(authenticationInterceptor);
api.interceptors.response.use(serverSuccessInterceptor, serverErrorInterceptor);
api.interceptors.response.use(undefined, unauthorizedErrorInterceptor);

export default api;
