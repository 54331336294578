import { TopTYvsLYSlice } from 'src/pages/Hindsighting/StyleColorReview/TopTYvsLY/TopTYvsLY.slice';
import { ReduxSlice as SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { generateGroupBy } from './PlanogramSimple.container';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import processDataForCardView from 'src/components/StandardCardView/CardViewDataProcessor';
import { CardViewDataInputs, CardViewOptionalInputs } from 'src/components/StandardCardView/UIData.types';

export const processData = (styles: BasicPivotItem[], viewConfig: TopTYvsLYSlice, subheader: SubheaderSlice) => {
  if (viewConfig && viewConfig.groupingInfo && subheader) {
    const groupingInfo = viewConfig.groupingInfo;
    const groupBy = generateGroupBy(groupingInfo.dataIndex);

    const dataInputs: CardViewDataInputs = {
      styles,
      subheader: { ...subheader, groupBy },
      defns: {
        view: viewConfig.viewDefn || {},
        rollup: viewConfig.calcViewDefn || {},
      },
    };
    const optionalInputs: CardViewOptionalInputs = {
      staticColumns: groupingInfo.staticColumns,
    };

    return processDataForCardView(dataInputs, optionalInputs);
  }
  return [];
};
