import * as React from 'react';
import styles from './ValidOptions.styles';
import { keys } from '../../utils/Domain/Constants';

export type ValidOptionReadOnly = {
  editable: false;
  value: string | number;
};
export type ValidOptionEditable = {
  editable: true;
  value: string | number;
  selected: boolean;
  onOptionClick: (option: string) => boolean;
};

type ValidOptionProps = ValidOptionReadOnly | ValidOptionEditable;

const REACT_KEY_DOWN = 'keydown';
export default class ValidOption extends React.Component<ValidOptionProps> {
  onAction = (option: string | undefined) => {
    if (!option) {
      return;
    }
    if (this.props.editable && this.props.onOptionClick && option) {
      this.props.onOptionClick(option);
    }
  };
  onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const option = event.currentTarget.dataset.value;
    return this.onAction(option);
  };
  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.type === REACT_KEY_DOWN && event.keyCode !== keys.enter && event.keyCode !== keys.space) {
      return;
    }
    const option = event.currentTarget.dataset.value;
    return this.onAction(option);
  };

  render() {
    const { value } = this.props;
    let content;
    if (this.props.editable) {
      content = (
        <div
          className={styles.buildValidOptionStyle(this.props.selected, this.props.editable)}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
          tabIndex={0}
          data-value={value}
        >
          {value}
        </div>
      );
    } else {
      content = <div className={styles.buildValidOptionStyle(true, this.props.editable)}>{value}</div>;
    }
    return content;
  }
}
