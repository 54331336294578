import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { ExceptionList } from './ExceptionsSummary';
import { cacheCheckFetchPivotData } from '../../StyleColorReview/StyleColorReview.slice';
import service from 'src/ServiceContainer';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { ViewDataState } from 'src/types/Domain';

export type ExceptionsSummaryConfigs = {
  columns: TenantConfigViewItem[];
  main: {
    title: string;
    goodColorRange: string[];
    badColorRange: string[];
  };
};

export type ExceptionsSummarySlice = {
  viewDefns: undefined | ExceptionsSummaryConfigs;
  isConfigLoading: boolean;
  isGridDataLoading: boolean;
  gridData: ExceptionList;
  cacheHash: string | null;
  viewDataState: ViewDataState;
};

const initExceptionsSummarySlice: ExceptionsSummarySlice = {
  viewDefns: undefined,
  isConfigLoading: false,
  isGridDataLoading: false,
  gridData: [],
  cacheHash: null,
  viewDataState: ViewDataState.idle,
};

const exceptionsSummarySliceReducer = createSlice({
  name: 'exceptionsSummary',
  initialState: initExceptionsSummarySlice,
  reducers: {
    requestExceptionsSummaryConfig(state) {
      state.isConfigLoading = true;
    },
    requestExceptionsSummaryGridData(state) {
      state.isGridDataLoading = true;
    },
    receiveExceptionsSummaryConfig(state, action: PayloadAction<ExceptionsSummaryConfigs>) {
      state.isConfigLoading = false;
      state.viewDefns = action.payload;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveExceptionsSummaryCacheData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveExceptionsSummaryLiveData(state, action: PayloadAction<string>) {
      state.isGridDataLoading = false;
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataReady;
      }
    },
    requestExceptionsSummaryError() {
      return initExceptionsSummarySlice;
    },
  },
});

export const {
  requestExceptionsSummaryConfig,
  requestExceptionsSummaryGridData,
  receiveExceptionsSummaryConfig,
  receiveCacheHash,
  receiveExceptionsSummaryCacheData,
  receiveExceptionsSummaryLiveData,
  requestExceptionsSummaryError,
} = exceptionsSummarySliceReducer.actions;
export default exceptionsSummarySliceReducer.reducer;

export function fetchExceptionsSummaryData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestExceptionsSummaryGridData,
    receiveCacheHash,
    receiveExceptionsSummaryCacheData,
    receiveExceptionsSummaryLiveData
  );
}
