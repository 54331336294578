import * as React from 'react';
import { style } from 'typestyle';

const styles = {
  container: style({
    width: '100%',
    height: '100%',
  }),
  headerContainer: style({
    textAlign: 'center',
    height: '30%',
    fontSize: '0.75rem',
  }),
  valueContainer: style({
    height: '70%',
    width: '80%',
    display: 'flex',
    margin: '0 auto',
    fontSize: '1.2rem',
  }),
  arrowContainer: style({
    textAlign: 'center',
    height: '100%',
    fontSize: '0.75rem',
    flexGrow: 1,
  }),
  valueTextContainer: style({
    textAlign: 'center',
    height: '100%',
    flexGrow: 1,
  }),
  arrowUp: style({
    display: 'inline-block',
    borderLeft: 'solid 6px transparent',
    borderRight: 'solid 6px transparent',
    borderBottom: 'solid 8px #318310',
    color: '#e07d31',
    position: 'relative',
    top: '15%',
  }),
  arrowDown: style({
    display: 'inline-block',
    borderLeft: 'solid 6px transparent',
    borderRight: 'solid 6px transparent',
    borderTop: 'solid 8px #c20c09',
    position: 'relative',
    top: '15%',
  }),
};

export enum ARROWDIRECTIONS {
  UP = 'UP',
  DOWN = 'DOWN',
}

export type ArrowValueRendererProps = {
  header: string;
  value: string;
  arrowDirection: ARROWDIRECTIONS;
};

export default class ArrowValueRenderer extends React.Component<ArrowValueRendererProps> {
  constructor(props: ArrowValueRendererProps) {
    super(props);
  }

  render() {
    const { header, value, arrowDirection } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.headerContainer}>{header}</div>
        <div className={styles.valueContainer}>
          <div className={styles.arrowContainer}>
            <div className={arrowDirection === 'UP' ? styles.arrowUp : styles.arrowDown} />
          </div>
          <div className={styles.valueTextContainer}>
            <p>{value}</p>
          </div>
        </div>
      </div>
    );
  }
}
