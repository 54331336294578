import * as React from 'react';
import Cropper from 'react-easy-crop';

import styles from './ImageCropper.styles';
import { getCroppedImg } from 'src/components/ImageCropper/ImageCropper/Cropper.utils';
import { isNil } from 'lodash';
import { Slider } from '@material-ui/core';

export type ImageCropperProps = {
  image: string;
};

export type CropperSelection = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type ImageCropperState = {
  crop: CropObject;
  zoom: number | number[];
  aspect: number;
  croppedAreaPixels: CropperSelection | null;
  croppedImage: Blob | null;
};

type CropObject = {
  x: number;
  y: number;
};

export default class ImageCropper extends React.Component<ImageCropperProps, ImageCropperState> {
  constructor(props: ImageCropperProps) {
    super(props);

    this.state = {
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 2 / 3,
      croppedAreaPixels: null,
      croppedImage: null,
    };
  }

  onCropChange = (crop: CropObject) => {
    this.setState({
      crop: crop,
    });
  };

  onCropComplete = (_croppedArea: CropperSelection, croppedAreaPixels: CropperSelection) => {
    this.setState({
      croppedAreaPixels,
    });
  };

  onZoomChange = (_event: React.ChangeEvent<{}>, zoom: number | number[]) => {
    if (Array.isArray(zoom)) {
      // typeguard here to gate valid slider events
      throw new Error('Only one slider present, shouldnt be an array');
    }
    this.setState({ zoom: zoom ? zoom : 1 });
  };

  getCroppedImage = async () => {
    const { croppedAreaPixels } = this.state;
    if (isNil(croppedAreaPixels)) {
      return null;
    }

    const croppedImage = await getCroppedImg(this.props.image, croppedAreaPixels);
    this.setState({
      croppedImage,
    });
    return croppedImage;
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.cropContainer}>
          <Cropper
            image={this.props.image}
            crop={this.state.crop}
            zoom={this.state.zoom}
            aspect={this.state.aspect}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
            restrictPosition={false}
          />
        </div>
        <div className={styles.sliderContainer}>
          <span className={styles.sliderText}>Zoom Slider:</span>
          <Slider
            value={this.state.zoom}
            min={0.5}
            max={2}
            valueLabelDisplay="on"
            valueLabelFormat={(value) => <div>{Math.floor(value * 100)}%</div>}
            step={0.01}
            aria-labelledby="Zoom"
            onChange={this.onZoomChange}
          />
        </div>
      </div>
    );
  }
}
