import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isNil, isEmpty, forEach } from 'lodash';

import Axios from 'src/services/axios';
import container from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import {
  PricingGrid,
  PricingGridProps,
  ComponentTypeGridDispatchProps,
} from 'src/pages/AssortmentBuild/Pricing/PricingGrid';
import {
  fetchOverTimeData,
  receivePricingEvents,
  receivePricingOverTimeConfig,
  submitPayload as submitPricingPayload,
  cleanUp as cleanupPricing,
  receiveSomePricingOverTimeConfig,
} from 'src/pages/AssortmentBuild/Pricing/PricingOverTime.slice';
import { PricingEventResponse } from 'src/pages/AssortmentBuild/Pricing/PricingOverTime.types';
import { convertPivotItemsToRowData } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheet.utils';
import { RowData } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheet.types';
import { ASSORTMENT, ASSORTMENT_BUILD_FILTER_WARNING } from 'src/utils/Domain/Constants';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { TreePivot } from 'src/worker/pivotWorker.types';

import Subheader from 'src/components/Subheader/Subheader.container';
import { LegacySubheaderActionButtonProps } from 'src/components/Subheader/Subheader.types';
import { AutoSizer } from 'react-virtualized';
import { getUniqueDataFromCache, HashType, isDataLoaded } from 'src/services/pivotServiceCache';
import { ViewDataState } from 'src/types/Domain';
import { MassEditConfig, MassEditSubmissionData } from 'src/components/MassEdit/MassEdit';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { FavoriteResponseItem } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { WRAPPER_SUBHEADER_HEIGHT } from 'src/pages/Worklist/Worklist.styles';
import { TabbedComponentWrapperOwnProps } from 'src/pages/Worklist/Worklist.types';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';

type PricingProps = ReturnType<typeof mapStateToPropsPricing>;
type PricingGridWithSubheader = PricingGridProps & {
  subheaderSummary?: string;
  overtimeData?: TreePivot;
} & PricingProps;

export function mapStateToPropsPricing(state: AppState, ownProps: TabbedComponentWrapperOwnProps) {
  const { pages, scope, subheader, worklist } = state;
  const viewState = pages.assortmentBuild.pricing;
  const scopeStart = !isNil(scope.scope.start) ? scope.scope.start : undefined;
  const { viewDataStateOverTime, viewDefns } = viewState;
  const rangeList = scope.rangeList;
  const allowFrom = !isEmpty(rangeList) ? rangeList[0].id : '';
  const allowTo = !isEmpty(rangeList) ? rangeList[rangeList.length - 1].id : '';
  const massEditData = worklist.liveData;
  const isMassEditDataLoaded = isDataLoaded(viewState.viewDataStateMassEdit);

  const overtimeData = getUniqueDataFromCache(viewState, HashType.pricingOverTime)?.tree || [];
  const isLiveDataReady = viewDataStateOverTime === ViewDataState.liveDataReady;
  const groupRowHeight = viewState.viewDefns.grid.main?.groupRowHeight;

  return {
    loaded: isDataLoaded(viewDataStateOverTime),
    timeEntries: scope.timeInfo.entries,
    rowData: [],
    overtimeData,
    anchorField: scopeStart,
    rowHeight: viewDefns?.grid.main?.rowHeight,
    groupRowHeight,
    itemId: ownProps.selectedItemId || '',
    editable: isLiveDataReady,
    ...subheader,
    ...viewState,
    allowFrom,
    allowTo,
    rangeList: scope.daysRangeList,
    isMassEditDataLoaded,
    massEditData,
    showFlowStatus: ownProps.showFlowStatus === false ? false : true,
  };
}

export function dispatchToPropsPricing(
  dispatch: AppThunkDispatch,
  ownProps: TabbedComponentWrapperOwnProps
): ComponentTypeGridDispatchProps {
  const { viewDefns } = ownProps;

  return {
    onRenderGrid: async () => {
      const configResponse = await container.tenantConfigClient.getTenantViewDefnsWithFavorites({
        defnIds: ownProps.viewDefns,
        appName: ASSORTMENT,
      });

      // have to remap views to view property for access in grid data formatting function
      const { views, ...remainingObj } = configResponse[0];
      configResponse[0] = {
        ...remainingObj,
        view: !isNil(views) ? views : [],
      };
      const favoritesList = configResponse[2];
      const unmodifiedViewDefn: TenantConfigViewData = configResponse[0];
      const localConfig = getLocalConfig(
        viewDefns[0],
        (favoritesList as unknown) as FavoriteResponseItem[],
        dispatch,
        unmodifiedViewDefn
      );

      const configWithFavorites = isNil(localConfig) ? unmodifiedViewDefn : localConfig.config;

      dispatch(
        receivePricingOverTimeConfig({
          grid: configWithFavorites || unmodifiedViewDefn,
          listSort: {} as TenantConfigViewData,
          subheaderRollUp: {} as TenantConfigViewData,
          list: {} as TenantConfigViewData,
          massEdit: !isNil(configResponse[1]) ? ((configResponse[1] as unknown) as MassEditConfig) : undefined,
          unmodifiedViewDefn,
        })
      );
      const eventResponse = await Axios.get<PricingEventResponse>(`/api/v2/events?appName=${ASSORTMENT}`);
      dispatch(receivePricingEvents(eventResponse.data.data));
      dispatch(fetchOverTimeData(ownProps.selectedItemId));
    },
    onCompanionItemChange() {
      dispatch(fetchOverTimeData(ownProps.selectedItemId));
    },
    onUpdateConfig(config: TenantConfigViewData) {
      dispatch(receiveSomePricingOverTimeConfig(config));
    },
    onRefetchData: async () => {
      return dispatch(fetchOverTimeData(ownProps.selectedItemId));
    },
    submitPayload: async (payload: GranularEditPayloadItem[], shouldRefresh: boolean) => {
      return dispatch(submitPricingPayload(payload, shouldRefresh));
    },
    onCleanup: () => {
      dispatch(cleanupPricing());
    },
  };
}

const PricingGridWithSubheader = ({ subheaderSummary, ...props }: PricingGridWithSubheader) => {
  const {
    allowFrom,
    allowTo,
    anchorField,
    rangeList,
    isMassEditDataLoaded,
    viewDefns,
    massEditData,
    showFlowStatus,
    overtimeData,
    showUndoBtn,
  } = props;
  const { massEdit: massEditConfig } = viewDefns;
  const itemsDataIndex = !isNil(massEditConfig) ? massEditConfig.views[1].dataIndex : '';
  const editableItems =
    massEditData &&
    massEditData.flat.map((dataItem) => {
      const label = `${dataItem['name']}-${dataItem[itemsDataIndex]}`;
      return {
        value: dataItem['id'],
        label,
      };
    });

  const [rowData, setRowData] = useState([] as RowData[]);
  useEffect(() => {
    if (overtimeData != null) {
      const requiredKeys = ['addoff', 'eo', 'eff_aur', 'corpaddoff', 'corpexcl', 'cccurp', 'ccdiscountpct'];
      setRowData(convertPivotItemsToRowData(overtimeData, viewDefns.grid.view, requiredKeys, 'stylecolor', 'week'));
    }
  }, [overtimeData, viewDefns]);

  const handleMassEditSubmission = async (submission: MassEditSubmissionData) => {
    const { selectedModifier, selectedItems, selectedWeekList, modifierValue } = submission;

    if (!isNil(selectedModifier) && !isNil(selectedWeekList)) {
      // no null submission data expected, except for modifier value
      const updatedData: GranularEditPayloadItem[] = [];

      forEach(selectedWeekList, (weekNumber) => {
        const updatedWeekData = selectedItems.map((item) => {
          return {
            [selectedModifier!]: modifierValue,
            coordinates: {
              product: item.value, // styleColor id
              time: weekNumber,
            },
          };
        });

        updatedData.push(...updatedWeekData);
      });

      if (props.submitPayload) {
        props.submitPayload(updatedData, true);
      }

      // update grid data
      if (props.onRefetchData) {
        props.onRefetchData();
      }
    }
  };

  const onUpdateConfig = (config: TenantConfigViewData) => {
    props.onUpdateConfig && props.onUpdateConfig(config);
  };

  const generateExtraActionButtonProps = (): LegacySubheaderActionButtonProps => {
    const massEdit = isNil(massEditConfig)
      ? undefined
      : {
          config: massEditConfig,
          allowFrom,
          allowTo,
          scopeStart: anchorField || '',
          rangeList,
          editableItems,
          title: 'Mass Copy Pricing Events',
          handleSubmit: handleMassEditSubmission,
          dataLoading: isMassEditDataLoaded,
        };

    return {
      massEdit,
    };
  };

  const viewConfigurator = viewDefns &&
    viewDefns.unmodifiedViewDefn && {
      viewConfig: viewDefns.grid,
      unmodifiedViewDefn: viewDefns.unmodifiedViewDefn,
      showPinCheckboxForGrid: false,
      updateConfig: onUpdateConfig,
    };

  const extraActionButtons = generateExtraActionButtonProps();

  return (
    <React.Fragment>
      <AutoSizer style={{ height: '100%', width: '100%' }}>
        {({ height }) => {
          const fsGridProps = {
            ...props,
            rowData,
          };
          return (
            <React.Fragment>
              <Subheader
                title={''}
                errorCondition={ASSORTMENT_BUILD_FILTER_WARNING}
                // search is rendered in the companion view already
                showSearch={false}
                showFlowStatus={showFlowStatus}
                showUndoBtn={showUndoBtn}
                summary={subheaderSummary}
                viewConfigurator={viewConfigurator}
                extraLegacyActionButtons={extraActionButtons}
              />
              {/* Height needs to be explicit for grid.  */}
              <div style={{ height: height - WRAPPER_SUBHEADER_HEIGHT }}>
                <PricingGrid {...fsGridProps} />
              </div>
            </React.Fragment>
          );
        }}
      </AutoSizer>
    </React.Fragment>
  );
};

export const TabbedPricingGrid = connect(
  mapStateToPropsPricing,
  dispatchToPropsPricing
  // @ts-ignore
)(makeScopeAndFilterSensitive(makePopoverSensitive(PricingGridWithSubheader)));
