import { z } from 'zod';
import { BaseDefnsComponentProps } from './confdefnComponentProps';
import { ConfigApiZod } from './confdefnView';

/** Print */

export const PrintSize = z.object({
  name: z.string().optional(),
  width: z.number(),
  height: z.number(),
});

/** FlowStatus */

export const FlowStatus = z.object({
  values: z.array(z.string()),
  defaultSelection: z.array(z.number()),
});

/** Placeholder */

export const Placeholder = z.object({
  text: z.string(),
});
export type PlaceholderConfig = z.infer<typeof Placeholder>;

/** Quick Recon */

export const QuickRecon = BaseDefnsComponentProps.merge(
  z.object({
    configApi: ConfigApiZod,
  })
);
