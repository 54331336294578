import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { ViewDataState } from 'src/types/Domain';
import { cacheCheckFetchPivotData } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import serviceContainer from 'src/ServiceContainer';
import { isEmpty } from 'lodash';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

export type NestedViewConfig = {
  grid: TenantConfigViewData;
  unmodifiedViewDefn: TenantConfigViewData;
  listSort: TenantConfigViewData;
  configure: TenantConfigViewData;
  list: TenantConfigViewData;
  subheaderRollUp: TenantConfigViewData;
};

export type NestedViewSlice = {
  aggBys: string[];
  viewDefns: NestedViewConfig;
  isConfigLoading: boolean;
  cacheHash: string | null;
  viewDataState: ViewDataState;
};

const initialState: NestedViewSlice = {
  aggBys: [],
  viewDefns: {
    grid: {} as TenantConfigViewData,
    unmodifiedViewDefn: {} as TenantConfigViewData,
    listSort: {} as TenantConfigViewData,
    configure: {} as TenantConfigViewData,
    list: {} as TenantConfigViewData,
    subheaderRollUp: {} as TenantConfigViewData,
  },
  isConfigLoading: false,
  viewDataState: ViewDataState.idle,
  cacheHash: null,
};

const nestedViewReducer = createSlice({
  name: 'NestedView',
  initialState,
  reducers: {
    updateAggBys(state, action: PayloadAction<string[]>) {
      state.aggBys = action.payload;
    },
    updateAggBysNoOverwriteExisting(state, action: PayloadAction<string[]>) {
      state.aggBys = !isEmpty(state.aggBys) ? state.aggBys : action.payload;
    },
    requestConfig(state) {
      state.isConfigLoading = true;
    },
    receiveConfig(state, action: PayloadAction<NestedViewConfig>) {
      state.isConfigLoading = false;
      state.viewDefns = action.payload;
    },
    receiveSomeNestedViewConfig(state, action: PayloadAction<TenantConfigViewData>) {
      state.isConfigLoading = false;
      state.viewDefns.grid = {
        ...state.viewDefns.grid,
        ...action.payload,
      };
    },
    requestData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataReady;
      }
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  updateAggBys,
  updateAggBysNoOverwriteExisting,
  requestConfig,
  receiveConfig,
  receiveSomeNestedViewConfig,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  receiveError,
  cleanUp,
} = nestedViewReducer.actions;

export function fetchNestedViewData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    serviceContainer.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData
  );
}

export default nestedViewReducer.reducer;
