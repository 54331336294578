import React from 'react';
import { isNil, cloneDeep } from 'lodash';

import { StyleEditSections } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSections/StyleEditSections';
import {
  StyleEditViewSection,
  UIViewApiConfig,
  ViewApiConfig,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { processApiParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { StyleEditSection } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import DividedColumnDetails from 'src/pages/AssortmentBuild/StyleEdit/DividedColumnDetailsSection/DividedColumnDetails';
import {
  StyleEditMultiSectionProps,
  HeaderData,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.types';
import StyleEditMultiSection from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditMultiSection';

import styleEditStyles from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.styles';

type SectionColorHeaderProps = {
  stickyElement?: JSX.Element;
};
export const SectionColorHeader = ({ stickyElement }: SectionColorHeaderProps) => {
  return (
    <React.Fragment>
      <div className={styleEditStyles.headerStylingDecoy}>
        <div onClick={(event) => event.stopPropagation()} />
      </div>
      <div className={styleEditStyles.headerColumnContainer}>
        <div className="columnColorContainer">{stickyElement}</div>
      </div>
    </React.Fragment>
  );
};

function createHeaderData(context: StyleEditSections): HeaderData {
  const { filteredStyleColors, removableStyleColors } = context.props;

  const headerData = filteredStyleColors.reduce((acc, child) => {
    acc[child.id] = cloneDeep(child);
    acc[child.id]['isremovable'] = removableStyleColors.indexOf(child.id) >= 0;
    return acc;
  }, {});

  return headerData;
}

export function buildStyleEditSection(
  context: StyleEditSections,
  config: StyleEditViewSection,
  scope: BasicPivotItem,
  expanded: boolean,
  refreshSections: (componentId: string | null) => void,
  currentRefreshCount: number,
  lastEditedSection: string | null
) {
  const { existingStyleColors, sortedStyleColorIds, onRefresh } = context.props;
  const styleId = scope.id;
  let configApi: UIViewApiConfig = config.configApi;
  if (config.configApi.type !== 'viewdefnv2') {
    configApi = {
      ...processApiParams(config.configApi as ViewApiConfig, scope),
      type: 'viewdefnv1',
    };
  }
  const sectionData = {
    ...config,
    ...(!isNil(configApi) ? { configApi } : undefined),
    ...(!isNil(config.dataApi) ? { dataApi: processApiParams(config.dataApi, scope) } : undefined),
  };

  const showOnlyPreexist = !isNil(config.showOnlyPreexist) ? true : config.showOnlyPreexist;
  return (
    <div style={{ width: '100%' }}>
      <StyleEditSection
        expanded={expanded}
        existingStyleColors={existingStyleColors}
        sectionData={sectionData}
        headerData={createHeaderData(context)}
        parentId={styleId} // choose one this or one below
        styleId={styleId}
        addColor={context.addColor}
        sortedStyleColorIds={sortedStyleColorIds}
        refresh={onRefresh}
        getHeaderElement={(element: JSX.Element | undefined) => {
          context.setState({
            stickyHeaderAdditionElement: element,
          });
        }}
        onSectionDataLoaded={() => {
          context.setState({
            sectionDataLoaded: true,
          });
        }}
        showOnlyPreexist={showOnlyPreexist}
        refreshSections={refreshSections}
        currentRefreshCount={currentRefreshCount}
        lastEditedSection={lastEditedSection}
      />
    </div>
  );
}

export function createSectionElement(
  context: StyleEditSections,
  config: StyleEditViewSection,
  scope: BasicPivotItem,
  expanded: boolean,
  refreshSections: (componentId: string | null) => void,
  currentRefreshCount: number,
  lastEditedSection: string | null
) {
  switch (config.componentType) {
    case 'column_form':
      const dataApi = processApiParams(config.dataApi, scope);
      let configApi: any;
      // technically not yet supported
      if (config.configApi.type !== 'viewdefnv2') {
        configApi = processApiParams(config.configApi, scope);
      } else {
        configApi = config.configApi;
      }
      return (
        <DividedColumnDetails
          styleId={scope.id}
          dataApi={dataApi}
          configApi={configApi}
          expanded={expanded}
          refreshSections={refreshSections}
          currentRefreshCount={currentRefreshCount}
          lastEditedSection={lastEditedSection}
        />
      );
    case 'column_grid':
      return buildStyleEditSection(
        context,
        config,
        scope,
        expanded,
        refreshSections,
        currentRefreshCount,
        lastEditedSection
      );
    case 'column_multi_grid':
      const processedDataApi = processApiParams(config.dataApi, scope);
      const styleEditMultiSectionProps: StyleEditMultiSectionProps = {
        expanded,
        dataApi: processedDataApi,
        configApi: config.configApi,
        skipForwardPropagation: !isNil(config.skipForwardPropagation) ? config.skipForwardPropagation : false,
        scope: scope,
        headerData: createHeaderData(context),
        existingStyleColors: context.props.existingStyleColors,
        parentId: !isNil(context.props.styleData) ? context.props.styleData.id : '',
        sortedStyleColorIds: context.props.sortedStyleColorIds,
        getHeaderElement: (element: JSX.Element | undefined) => {
          if (!element) {
            context.setState({
              stickyHeaderAdditionElement: undefined,
            });
          } else if (!context.state.stickyHeaderAdditionElement) {
            context.setState({
              stickyHeaderAdditionElement: element,
            });
          }
        },
        onSectionDataLoaded: () => {
          context.setState({
            sectionDataLoaded: true,
          });
        },
        refreshSections: refreshSections,
        currentRefreshCount: currentRefreshCount,
        lastEditedSection,
      };

      return <StyleEditMultiSection {...styleEditMultiSectionProps} />;
    default:
      return <div>Invalid componentType</div>;
  }
}
