import { z } from 'zod';

export const zAdornments = z.enum(['planning', 'worklist', 'exception']);
export const AdornmentsEnum = zAdornments.enum;
export type AdornmentType = z.infer<typeof zAdornments>;

export const BasicEditors = z.union([z.literal('multiselect'), z.literal('numberInput')]);

export const zConfigurableGridGroupEditors = z.enum([
  'validValues',
  'validValuesMulti',
  'textValidator',
  'textValidatorAsync',
  'integer',
  'checkbox',
]);

export const ConfigurableGridGroupEditorsEnum = zConfigurableGridGroupEditors.enum;

// TODO: Convert to enum
export const GridEditors = z.literal('lifecycleParameters');

/**
 * @Deprecated
 * Please refer to ConfDefnComponentType instead.
 * There is currently a transform that takes place in the WorklistTab zod validator
 * to convert these values to ConfDefnComponentTypes for backward compatibility only.
 * */
export const WorklistTabComponents = z.union([
  z.literal('visualize'),
  z.literal('styleEdit'),
  z.literal('pricing'),
  z.literal('flowsheet'),
]);

export const StyleEditSectionComponents = z.union([
  z.literal('column_grid'),
  z.literal('column_form'),
  z.literal('column_multi_grid'),
]);
