import Axios from 'src/services/axios';
import { ViewConfiguratorModalProps } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';
import { FavoriteListItem } from './FavoritesMenu';
import ServiceContainer from 'src/ServiceContainer';
import { toast } from 'react-toastify';
import { errorToLoggingPayload } from 'src/services/loggingService';
import { provideAppName } from 'src/utils/Domain/Perspective';
import { isEmpty } from 'lodash';

export const postFavorite = async (
  viewConfigurator: ViewConfiguratorModalProps,
  newFavItem: FavoriteListItem,
  active: boolean
) => {
  const favId = viewConfigurator.viewConfig.$id || viewConfigurator.viewConfig.id;
  const isActive = active ? true : false;
  return provideAppName(async (appName) => {
    try {
      return Axios.post(`/api/favorites/by-path/${favId}/${newFavItem.id}?appName=${appName}&active=${isActive}`, {
        ...newFavItem,
      });
    } catch (e) {
      toast.error('An error occured saving your favorites');
      ServiceContainer.loggingService.error('An error occured saving a favorite', errorToLoggingPayload(e));
      return Promise.reject(); // TODO: verify this is okay, was previously resolved
    }
  });
};

export const getFavoriteByDefnId = async (defnId: string) => {
  return provideAppName(async (appName) => {
    return Axios.get(`/api/favorites/by-path/${defnId}?appName=${appName}`).catch((e) => {
      toast.error('An error occured fecthing your favorites');
      ServiceContainer.loggingService.error('An error occured fetching a favorite', e.stack);
    });
  });
};

export const deleteFavorite = (key: string) => {
  return Axios.delete(`/api/favorites/by-key/${key}`);
};

export const clearFavoriteActives = async (favId?: string) => {
  return provideAppName(async (appName) => {
    if (isEmpty(favId)) return Promise.reject();
    try {
      return Axios.post(`/api/favorites/by-path/${favId}/clearActives?appName=${appName}`);
    } catch (e) {
      toast.error('An error occured clearing favorite actives');
      ServiceContainer.loggingService.error('An error occured clearing favorite actives', errorToLoggingPayload(e));
      return Promise.reject();
    }
  });
};

export const setFavoriteActive = async (viewConfigurator: ViewConfiguratorModalProps, favItem: FavoriteListItem) => {
  return provideAppName(async (appName) => {
    const favId = viewConfigurator.viewConfig.$id || viewConfigurator.viewConfig.id;
    try {
      return Axios.post(`/api/favorites/by-path/${favId}/${favItem.id}/setActive?appName=${appName}`);
    } catch (e) {
      toast.error('An error occured setting favorite actives');
      ServiceContainer.loggingService.error('An error occured setting favorite actives', errorToLoggingPayload(e));
      return Promise.reject();
    }
  });
};
