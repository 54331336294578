import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { resetPlanItem } from 'src/pages/AssortmentBuild/Planning.slice';


export const planRefresh: AppEpic = (action$) => {
  return action$.pipe(
    ofType(setActivePage.type),
    mergeMap(() => {
      return of(resetPlanItem());
    })
  );
};
