import * as React from 'react';
import * as lodash from 'lodash';
import Modal from '@material-ui/core/Modal';
import { classes } from 'typestyle';

import { DataItem } from 'src/common-ui/components/Charts/PerformanceChart';
import { Overlay } from 'src/common-ui';

import { ParetoAnalysisDispatchProps, ParetoAnalysisStateProps } from './ParetoAnalysis.container';
import ParetoSummary from 'src/components/views/ParetoAnalysis/ParetoSummary';
import ParetoGraph, { DisplayModel } from 'src/components/views/ParetoAnalysis/ParetoGraph';
import styles from './ParetoAnalysis.styles';
import Subheader from 'src/components/Subheader/Subheader.container';
import { formatSummaries } from 'src/utils/Pivot/RollUp';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { ConfigureOptions } from 'src/components/Subheader/Subheader.types';
import { SLSR } from 'src/utils/Domain/Constants';
import { PrintProps } from 'src/components/higherOrder/Print/Print';
import coalesce from 'src/utils/Functions/Coalesce';
import ConfigureModal, { ConfigureModalProps, Option, OptionGroup } from 'src/components/Configure/ConfigureModal';
import { ViewConfiguratorModalProps } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';

type Props = ParetoAnalysisStateProps & ParetoAnalysisDispatchProps & PrintProps;

type State = {
  configureIsOpen: boolean;
  configureLastSelected?: Option[];
  storeOpen: boolean;
};

const CHUNK_SIZE = 100;

function buildSummaryData(flatData: Record<string, any>[]) {
  const groupCount = Math.ceil(flatData.length / CHUNK_SIZE);
  let i = 0;
  let summarySet: number[] = [];
  flatData.sort((a, b) => {
    return b[SLSR] - a[SLSR];
  });
  while (i < flatData.length) {
    const items = flatData.slice(i, i + groupCount);
    let reducedItem = items.reduce((accumulator, currentItem) => accumulator + currentItem[SLSR], 0);
    reducedItem /= groupCount;
    summarySet.push(reducedItem);
    i += groupCount;
  }

  summarySet = summarySet.sort((a, b) => {
    return b - a;
  });
  return summarySet;
}

export default class ParetoAnalysis extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      configureIsOpen: false,
      storeOpen: false,
    };
    this.buildDisplayModel = this.buildDisplayModel.bind(this);
  }

  componentDidMount() {
    this.props.onShowView();
  }

  buildConfigureOptions(viewDefn: TenantConfigViewData): OptionGroup[] {
    if (!viewDefn) {
      return [];
    }
    const configurableSection = viewDefn.view[0]; // 1
    const options: OptionGroup[] = [];
    if (configurableSection && configurableSection.view) {
      // 2
      configurableSection.view.forEach((section) => {
        const optionGroup: OptionGroup = {
          text: section.text,
          options: [],
        };
        if (section.view) {
          section.view.forEach((configItem) => {
            optionGroup.options.push({
              dataIndex: configItem.dataIndex,
              text: configItem.text,
              renderer: configItem.renderer,
            });
          });
        }
        options.push(optionGroup);
      });
    }
    return options;
  }

  buildDisplayModel(rootView: TenantConfigViewItem[]): DisplayModel {
    const { configureSelections = this.props.defaultConfigureSelections } = this.props;

    const blankOption: Option = {
      dataIndex: '',
      text: '',
    };
    // FIXME: 2 things:
    // 1. There is no reason this needs to be a hardcoded set, it should work just like the top section
    // 2. Configuration should be corrected such that we aren't fetchin arrays of arrays of arrays to find a specific value.
    let bar1BottomProperty: Option = blankOption;
    let bar2BottomProperty: Option = blankOption;
    let spline1BottomProperty: Option = blankOption;
    const bottomItems: TenantConfigViewItem[] = lodash.get(rootView, '[1].view') || [];
    const bars = bottomItems.slice(0, bottomItems.length - 1);
    const spline = bottomItems.slice(-1);
    const bar1Item = (lodash.get(bars, '[0].view[0]') as unknown) as Option;
    if (bar1Item) {
      bar1BottomProperty = {
        dataIndex: bar1Item.dataIndex,
        text: bar1Item.text,
        renderer: bar1Item.renderer,
      };
    }
    const bar2Item = (lodash.get(bars, '[1].view[0]') as unknown) as Option;
    if (bar2Item !== undefined) {
      bar2BottomProperty = {
        dataIndex: bar2Item.dataIndex,
        text: bar2Item.text,
        renderer: bar2Item.renderer,
      };
    }
    const spline1Item = (lodash.get(spline, '[0].view[0]') as unknown) as Option;
    if (spline1Item) {
      spline1BottomProperty = {
        dataIndex: spline1Item.dataIndex,
        text: spline1Item.text,
        renderer: spline1Item.renderer,
      };
    }

    const model: DisplayModel = {
      topTitle: lodash.get(rootView, '[0].text') || '',
      bottomTitle: lodash.get(rootView, '[1].text') || '',
      bar1Property: configureSelections[0] || blankOption,
      bar2Property: configureSelections[1] || blankOption,
      spline1Property: configureSelections[2] || blankOption,
      spline2Property: configureSelections[3] || blankOption,
      cumulativeProperty: configureSelections[0] || blankOption,
      bar1BottomProperty: bar1BottomProperty || blankOption,
      bar2BottomProperty: bar2BottomProperty || blankOption,
      spline1BottomProperty: spline1BottomProperty || blankOption,
    };
    return model;
  }

  openStoreAndPop = (item: DataItem) => {
    if (this.props.openStore) {
      this.props.openStore(item);
      this.setState({
        storeOpen: true,
      });
    }
  };

  closeStorePop = () => {
    this.setState({
      storeOpen: false,
    });
  };

  updateConfigureSelections = (selections: Option[]) => {
    if (this.props.updateConfigureSelections) {
      this.props.updateConfigureSelections(selections);
    }
  };

  render() {
    const {
      loaded,
      title,
      subheaderViewDefns,
      summary,
      allProductsSummary,
      flat,
      viewDefns,
      page,
      isPrintMode,
      defaultConfigureSelections,
      originalDefaultSelections,
      summaryDataState,
      analysisDataState,
    } = this.props;
    const configureSelections: Option[] = coalesce(this.props.configureSelections, defaultConfigureSelections)!;
    const { configureLastSelected = defaultConfigureSelections, configureIsOpen } = this.state;

    const configurationViewDefn = viewDefns[2];
    const stripLayout = viewDefns[1];
    const builtOptionGroups = this.buildConfigureOptions(configurationViewDefn);
    let content, viewConfigurator: ViewConfiguratorModalProps | undefined;

    const configureOptions: ConfigureOptions = {
      type: 'enabled',
      onConfigureClick: () =>
        this.setState({
          configureIsOpen: !this.state.configureIsOpen,
        }),
    };

    if (this.props.page === 'details') {
      viewConfigurator = stripLayout && {
        viewConfig: stripLayout,
        unmodifiedViewDefn: viewDefns[4],
        configurationSelections: configureSelections,
        defaultConfigurationSelections: originalDefaultSelections,
        updateConfiguration: (selections: Option[]) => {
          this.updateConfigureSelections(selections);
        },
        updateConfig: this.props.onUpdateConfig,
      };
    }
    const subheaderContent = (
      <Subheader
        title={title}
        sortByDefn={subheaderViewDefns.sortBy}
        showFlowStatus={true}
        summary={formatSummaries(allProductsSummary)}
        configureOptions={this.props.page === 'details' ? configureOptions : undefined}
        showSearch={this.props.page === 'details'}
        viewConfigurator={viewConfigurator}
        viewDataState={[summaryDataState, analysisDataState]}
      />
    );
    if (!loaded) {
      return (
        <div>
          {subheaderContent}
          <div>
            <Overlay type="loading" visible={true} />
          </div>
        </div>
      );
    }

    if (page === 'details') {
      const displayModel: DisplayModel = this.buildDisplayModel(configurationViewDefn.view);
      content = (
        <ParetoGraph
          flat={flat}
          stripLayout={stripLayout}
          displayModel={displayModel}
          openStore={this.openStoreAndPop}
          isPrintMode={isPrintMode}
        />
      );
    } else {
      let summaryGraphData: number[] = [];
      if (flat.length > 0) {
        summaryGraphData = buildSummaryData(flat);
      }
      // FIXME: fix type error with quintiles possibly being undefined
      // @ts-ignore
      content = <ParetoSummary {...summary} summaryGraphData={summaryGraphData} />;
    }

    // let defaultConfigureSelections: Option[] = [];

    // Idk what this is supposed to do but it causes infinite loop, because selections is always empty
    // if (configureSelections.length === 0) {
    //   this.updateSelection(configurationViewDefn);
    // }

    const configureModalProps: ConfigureModalProps = {
      enabled: true,
      isOpen: configureIsOpen,
      optionGroups: builtOptionGroups,
      selections: configureSelections,
      onReset: () => {
        this.setState({
          configureLastSelected: defaultConfigureSelections,
        });
        this.updateConfigureSelections(defaultConfigureSelections);
      },
      onToggleModal: (action) => {
        const nextState: Partial<State> = {
          configureIsOpen: !configureIsOpen,
        };

        switch (action) {
          case 'apply': {
            nextState.configureLastSelected = configureSelections;
            break;
          }
          default:
            this.updateConfigureSelections(configureLastSelected);
        }
        this.setState(nextState as any);
      },
      selectionUpdate: (selections: Option[]) => {
        this.updateConfigureSelections(selections);
      },
    };

    let mainClasses = classes(styles.mainContent, styles.mainContentFitScreen);
    let mainContentWrapper = styles.mainContentWrapper;
    if (isPrintMode) {
      // Determines if print is in landscape or portrait and sets scaling accordingly
      mainContentWrapper = '';
      mainClasses = classes(styles.mainContent, styles.mainContentPrint);

      /* tslint:disable-next-line */
      const cssRules = (document.styleSheets[document.styleSheets.length - 1] as any).cssRules[0];
      if (cssRules && cssRules.cssText) {
        mainClasses = cssRules.cssText.includes('landscape')
          ? classes(styles.mainContent, styles.mainContentPrint)
          : styles.mainContent;
      }
    }

    return (
      <React.Fragment>
        <div
          className={mainContentWrapper}
          style={{ width: '100%', height: '100%' }}
          data-qa="pareto-summary-container"
        >
          {subheaderContent}
          <div className={mainClasses}>{content}</div>
        </div>
        <ConfigureModal {...configureModalProps} />
        <Modal open={this.state.storeOpen} onClose={this.closeStorePop} className={styles.modal}>
          <div
            style={{
              width: '90vw',
              height: '98vh',
              backgroundColor: 'white',
              overflowY: 'auto',
              maxWidth: 1500,
            }}
          >
            <header className={styles.modalHeader}>
              <h3>Group Analysis</h3>
              <i className={styles.exitButton} onClick={this.closeStorePop} />
            </header>
            {this.props.storeModal}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
