import { AppState } from 'src/store';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { get, isEqual } from 'lodash';
import VisualizeSlice from './Visualize.slice';
import { Metric } from 'src/common-ui/components/Metrics/CommonTypes';
import { ReduxSlice as SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { STYLE_COLOR_ID } from 'src/utils/Domain/Constants';
import { macroConfigItemToMetric } from '../MacroGridPair/MacroGridPair.selectors';
import { WrapperOwnProps } from './Visualize.container';
import { Scope } from 'src/types/Scope';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { Pivot, WorklistInfo } from 'src/worker/pivotWorker.types';
import { StylePreviewData } from 'src/components/StylePreview/StylePreview.types';
import { sharedDataLens } from 'src/services/lenses/lenses';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { processAdornments } from 'src/components/Adornments/Adornments';
import { isWorklistActive } from 'src/services/configuration/codecs/confdefnComponents';

export type StateSelection = ReturnType<typeof VisualizeSlice> &
  WrapperOwnProps & {
    scopeStart: Scope['start'];
    worklistItems?: WorklistInfo[];
    adornments: AdornmentType[];
    isWorklistActive: boolean;
  };

export type VisualizeStateProjection = {
  loading?: boolean;
  data?: Pivot;
  scopeStart: Scope['start'];
  selectedItemPreviewData: StylePreviewData;
  subheaderSlice: SubheaderSlice;
  allStylecolorIds: string[];
  onSelectStyle: (id: string) => void;
  summaryData: Metric[] | undefined;
  worklistItems?: WorklistInfo[];
  graphsViewDefn: TenantConfigViewData | undefined;
  dontFilterSwatches?: boolean;
  adornments: AdornmentType[];
  lineGraphDefnId: string;
  isWorklistActive: boolean;
};

export function selectState(state: AppState, ownProps: WrapperOwnProps): StateSelection {
  // FIXME: be wary, the spreads here will create new objects and break object reference links
  const scopeStart = state.scope.scope.start;
  const worklistItems = sharedDataLens.get(state).worklist;
  const viewState = state.pages.assortmentBuild.visualize;
  const currentAdornments: AdornmentType[] = get(viewState.vizViewDefns?.grid, 'adornments', []);
  const adornments = processAdornments(currentAdornments);

  return {
    scopeStart,
    worklistItems,
    adornments,
    isWorklistActive: isWorklistActive(state),
    ...state.pages.assortmentBuild.visualize,
    ...ownProps,
  };
}

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
export default createDeepEqualSelector(selectState, (stateSelection) => {
  const { vizViewDefns, summaryData, styles } = stateSelection;
  const graphsViewDefn = vizViewDefns?.graphs;
  // TODO: do this somewhere else
  let renderedSummaryData: Metric[] = [];
  if (summaryData && summaryData.length > 0 && vizViewDefns && vizViewDefns.summary) {
    renderedSummaryData = vizViewDefns.summary.view.map((macroConfigItem) => {
      return macroConfigItemToMetric(macroConfigItem, summaryData[0]);
    });
  }

  return {
    ...stateSelection,
    graphsViewDefn,
    allStylecolorIds: styles.map((x) => x[STYLE_COLOR_ID]),
    summaryData: renderedSummaryData,
    lineGraphDefnId: stateSelection.viewDefns[2],
  };
});
