import { ColDef, ColGroupDef, GridApi } from 'ag-grid-community';
import { isNil } from 'lodash';

import * as StyleEditSectionStyles from './StyleEditSection.styles';

export function getRowHeight(columnDef: any) {
  switch (columnDef.renderer) {
    case 'image': {
      return 150;
    }
    case 'validValuesMultiLine':
    case 'validSizes': {
      return StyleEditSectionStyles.validSizesRowHeight;
    }
    case 'color_header': {
      return StyleEditSectionStyles.colorHeaderRowHeight;
    }
    default: {
      return StyleEditSectionStyles.defaultRowHeight;
    }
  }
}

export function setGridRowHeights(columnDefs: (ColDef | ColGroupDef)[], gridApi: GridApi) {
  if (isNil(columnDefs) || isNil(gridApi)) {
    return;
  }

  columnDefs.forEach((colInf: any) => {
    const node = gridApi.getRowNode(colInf.dataIndex);
    if (isNil(node)) {
      return;
    }

    node.setRowHeight(getRowHeight(colInf));
  });

  gridApi.onRowHeightChanged();
}
