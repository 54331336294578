import React from 'react';
import { AutoSizer } from 'react-virtualized';
import { toast } from 'react-toastify';

import { FitView, Overlay } from 'src/common-ui';
import { FitViewGroupPayload } from 'src/common-ui/components/FitView/FitViewInterfaces';
import CollectionViewStyles from './CollectionView.styles';
import Subheader from 'src/components/Subheader/Subheader.container';
import {
  CollectionViewDispatchProps,
  CollectionViewStateProps,
} from 'src/components/views/CollectionView/CollectionView.types';
import { ItemData } from 'src/common-ui/components/FitView/FitViewInterfaces';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { WithLeafTypeProps } from 'src/components/higherOrder/withLeafType';
import { formatSummaries } from 'src/utils/Pivot/RollUp';
import { PrintProps } from 'src/components/higherOrder/Print/Print';
import { resolvePath } from 'src/cdn';
import { CollectionViewOwnProps } from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.container';
import { ViewConfiguratorModalProps } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';

import noImagePath from 'src/common-ui/images/noimage.jpg';
import { FabHandlerInvocable, FabType } from 'src/components/higherOrder/withFab';
import { WorklistService } from 'src/services/Worklist/Worklist.service';
import ServiceContainer from 'src/ServiceContainer';
import { errorToLoggingPayload } from 'src/services/loggingService';
import { MINIMUM_GROUP_BOX_WIDTH } from 'src/common-ui/components/FitView/FitViewGroup.styles';
const noImage = resolvePath(noImagePath);

type Props = CollectionViewStateProps &
  CollectionViewDispatchProps &
  WithLeafTypeProps &
  PrintProps &
  CollectionViewOwnProps &
  FabHandlerInvocable;

const MAX_GROUP_SIZE = 300;
const MAX_GROUPS = 20;
const ITS_TOO_BIG_MSG = 'Data set is too large to display visually, limiting to ';

function truncate(payloads: FitViewGroupPayload[]) {
  const shorter = payloads.length > MAX_GROUPS ? payloads.slice(0, MAX_GROUPS) : payloads;
  const total = shorter.reduce((acc, next) => acc + next.items.length, 0);
  const weights = shorter.map((group) => group.items.length / total);

  return shorter.map((payload, i) => ({
    ...payload,
    items: payload.items.slice(0, Math.round(weights[i] * MAX_GROUP_SIZE)),
  }));
}

export class CollectionView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.handleItemClickEvent = this.handleItemClickEvent.bind(this);
  }

  componentDidMount() {
    this.props.onShowView();
  }

  handleItemClickEvent(item: ItemData, event?: React.SyntheticEvent<HTMLElement>) {
    const { onItemClicked } = this.props;
    const target: HTMLElement | null = event ? (event.target as HTMLElement) : null;
    const children = this.props.flatStyles;

    const found: BasicPivotItem | undefined = children.find((s) => s.id === item.id);
    if (found && target) {
      onItemClicked(found, target);
    }
  }

  onFabClick = () => {
    switch (this.props.fabType) {
      case FabType.worklist:
        this.addItemsToWorklist();
        break;
      default:
        break;
    }
  };

  addItemsToWorklist = async () => {
    const worklistService = WorklistService();
    const { flatStyles, identityProps } = this.props;
    const itemIds = flatStyles.map((item) => item[identityProps.idProp]);

    try {
      await worklistService.addItemsToWorklist(itemIds);
      toast.info('Item Successfully added to worklist');
    } catch (error) {
      const msg = 'An error occured adding an item to the worklist';
      toast.error(msg);
      ServiceContainer.loggingService.error(msg, errorToLoggingPayload(error));
    }
  };

  render() {
    const {
      styles,
      loaded,
      viewDefn,
      showFlowStatus,
      summary,
      subheaderViewDefns,
      isPrintMode = false,
      downloadLink,
      title,
      hideTitle,
    } = this.props;
    let content;
    const maxLength = styles.reduce((a, b) => a + b.items.length, 0);
    const isOverloaded = styles.length > MAX_GROUPS || maxLength > MAX_GROUP_SIZE;
    let limitedCount: string | number = 'fewer items.';

    if (viewDefn) {
      // TODO: Handle how the border of the fit view changes its width in the actual component.
      const fitViewBorderSize = 1;
      const clamped = truncate((styles as unknown) as FitViewGroupPayload[]);
      limitedCount = clamped.reduce((acc, next) => acc + next.items.length, 0);

      content = (
        <div data-qa="CollectionViewRenderedItems" style={{ width: '100%', height: '100%' }}>
          <AutoSizer defaultHeight={1000}>
            {({ height: wheight, width: wwidth }) => {
              const minWidth = Math.max(clamped.length * MINIMUM_GROUP_BOX_WIDTH, wwidth - fitViewBorderSize * 2);
              return (
                <FitView
                  noImageUrl={noImage}
                  width={minWidth}
                  height={wheight}
                  useSrc={true}
                  groups={clamped}
                  onItemClick={this.handleItemClickEvent}
                />
              );
            }}
          </AutoSizer>
        </div>
      );
    } else {
      content = <div data-qa="CollectionViewDataError">An error was encountered.</div>;
    }
    const errorCondition = isOverloaded ? ITS_TOO_BIG_MSG + limitedCount : undefined;
    const viewConfigurator: ViewConfiguratorModalProps = {
      viewConfig: viewDefn,
      unmodifiedViewDefn: viewDefn,
    };
    return (
      <React.Fragment>
        <Subheader
          title={title}
          hideTitle={hideTitle}
          groupByDefn={subheaderViewDefns.groupBy}
          sortByDefn={subheaderViewDefns.sortBy}
          pareDownDefn={subheaderViewDefns.pareDown}
          showFlowStatus={showFlowStatus}
          summary={formatSummaries(summary)}
          showSearch={true}
          errorCondition={errorCondition}
          viewConfigurator={viewConfigurator}
          downloadLink={downloadLink}
          viewDataState={this.props.viewDataState}
        />
        <div
          className={isPrintMode ? CollectionViewStyles.inPrintMode : CollectionViewStyles.mainView}
          style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden' }}
          data-qa="CollectionViewMainView"
        >
          <Overlay qaKey="LoadingOverlay" type="loading" visible={!loaded} />
          {loaded ? content : null}
        </div>
      </React.Fragment>
    );
  }
}

export default CollectionView;
