// tslint:disable
import * as React from 'react';
import { DispatchProps, StateProps, RouteProps } from './DimensionForm.container';
import styles from '../ScopeSelector.styles';
import { useCallback } from 'react';
import ScopeSelectorDropdownAutocomplete from '../ScopeSelectorDropdownAutocomplete';

export type DimensionProps = StateProps & DispatchProps & RouteProps;

const DimensionForm = (props: DimensionProps) => {
  const {
    selectedProductMember,
    selectedLocationMember,
    selectionErrors,
    productLevels,
    locationLevels,
    onChangeProductMember,
    onChangeLocationMember,
    onChangeProductLevel,
    onChangeLocationLevel,
  } = props;

  const handleProductMemberChange = useCallback(
    (id: string, level: string) => {
      onChangeProductMember(id);
      onChangeProductLevel(level);
    },
    [onChangeProductMember, onChangeProductLevel]
  );
  const handleLocationMemberChange = useCallback(
    (id: string, level: string) => {
      onChangeLocationMember(id);
      onChangeLocationLevel(level);
    },
    [onChangeLocationMember, onChangeLocationLevel]
  );

  return (
    <React.Fragment>
      <div key={0} className={styles.scopeColumn}>
        <ScopeSelectorDropdownAutocomplete
          key={'SelectProductDropdown'}
          levels={productLevels}
          selection={selectedProductMember || undefined}
          placeholder={'Product'}
          handleChangeOnDropdown={handleProductMemberChange}
          invalid={selectionErrors.productMember}
          locked={false}
          qa="dropdown-productmember"
        />
      </div>
      <div key={1} className={styles.scopeColumn}>
        <ScopeSelectorDropdownAutocomplete
          key={'SelectLocationDropdown'}
          levels={locationLevels}
          selection={selectedLocationMember || undefined}
          placeholder={'Location'}
          handleChangeOnDropdown={handleLocationMemberChange}
          invalid={selectionErrors.locationMember}
          locked={false}
          qa="dropdown-locationmember"
        />
      </div>
    </React.Fragment>
  );
};
export default DimensionForm;
