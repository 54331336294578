import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import {
  TargetListComponent,
  maybeGetComponentProps,
  ConfDefnComponentType,
} from 'src/services/configuration/codecs/confdefnComponents';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/epicsFunctions';
import { Dispatch } from 'react-redux';
import { TargetListReduxSlice } from '../TargetList/TargetList.types';
import { update } from 'src/services/lenses/Lenses.actions';
import { Lens } from 'monocle-ts';
import { getTargetList, TargetType } from '../Criteria/Criteria.client';
import { merge, isNil } from 'lodash';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { TargetSettingConfiguration } from '../TargetSetting/TargetSetting.types';
import { Scope } from 'src/types/Scope';
import { targetListLens, scopeLens } from 'src/services/lenses/lenses';
import { AppState } from 'src/store';
import { receiveScopeRefreshTrigger } from 'src/components/ScopeSelector/ScopeSelector.slice';

export function pullDateInformationFromScope(ownProps: { type: TargetType; view: string[] }) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    // we're going to dispatch update our list stuff
    if (true) {
      const state = getState();
      const client = container.tenantConfigClient;
      const targetType = ownProps.type;
      const viewDefnName = ownProps.view;
      const viewConf = await client
        .getTenantViewDefns<TargetSettingConfiguration>({
          defnIds: viewDefnName,
          appName: ASSORTMENT,
        })
        .then((resp) => {
          return resp[0];
        });
      const { rangeList, pastRangeList, daysRangeList, daysPastRangeList } = state.scope;
      getTargetList({
        type: targetType,
      }).then((resp) => {
        const tData = resp.filter((item) => {
          return item.version === 'WP';
        });

        const rowData = tData.map((item) => {
          return merge(item, item.data);
        });
        rowData.forEach((item) => {
          if (item.targetSetup && item.targetSetup.versions == null) {
            item.targetSetup.versions = [];
            resp.forEach((innerItem) => {
              if (innerItem.id === item.id) {
                item.targetSetup.versions.push(innerItem.version);
              }
            });
          }
        });
        dispatch(
          update(
            targetListLens.compose(Lens.fromProps<TargetListReduxSlice>()(['rowData', 'viewConf'])).set({
              rowData,
              viewConf,
            }),
            'Load TargetList Data'
          )
        );
      });
      return dispatch(
        update(
          targetListLens.modify(
            (targetList: TargetListReduxSlice): TargetListReduxSlice => {
              return {
                ...targetList,
                targetNew: {
                  ...targetList.targetNew,
                  productId: scopeLens.compose(Lens.fromProp<Scope>()('productMember')).get(state) || '',
                  locationId: scopeLens.compose(Lens.fromProp<Scope>()('locationMember')).get(state) || '',
                  targetReceipt: {
                    loaded: true,
                    rangeList: rangeList,
                    daysRangeList,
                  },
                  targetSales: {
                    loaded: true,
                    rangeList: rangeList,
                    daysRangeList,
                  },
                  lyReceipt: {
                    loaded: true,
                    rangeList: pastRangeList,
                    daysRangeList: daysPastRangeList,
                  },
                  lySales: {
                    loaded: true,
                    rangeList: pastRangeList,
                    daysRangeList: daysPastRangeList,
                  },
                },
              };
            }
          ),
          'Load in TargetCreation defaults from scope.'
        )
      );
    }
  };
}

function getRouteSelectedTargetParams() {
  // fetches just the "remaining" after view route (nested routes/subpages)
  const hash = window.location.hash.split('/').slice(5);
  if (hash.length >= 3) {
    return {
      type: hash[0],
      targetKey: hash[1],
      version: hash[2],
    };
  } else if (hash.length > 0) {
    return {
      type: hash[0],
    };
  } else {
    return null;
  }
}

export const loadTargetList: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(setActivePage.type, setActiveSubPage.type, receiveScopeRefreshTrigger.type),

    map(() => {
      return maybeGetComponentProps<TargetListComponent>(state$.value, ConfDefnComponentType.targetList);
    }),
    filter(inputIsNotNullOrUndefined),
    mergeMap((ownProps) => {
      const targetRoute = getRouteSelectedTargetParams();
      // Only load list if at list level, not within a selected target
      if (isNil(targetRoute) || !isNil(targetRoute.version)) {
        return of();
      } else {
        // load list info
        return of(pullDateInformationFromScope(ownProps));
      }
    })
  );
};
