import { connect } from 'react-redux';
import { flow, get } from 'lodash';

import ColumnGroupedView, { StateProps, DispatchProps } from 'src/components/StandardCardView/ColumnGroupedView';
import container from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { receiveTenantConfig, receiveError, requestTenantConfig } from './FlowType.slice';
import { getProcessedData, getSummary } from './FlowType.selectors';
import { generateGroupBy } from '../TopTYvsLY/TopTYvsLY.container';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { FabType, withFab } from 'src/components/higherOrder/withFab';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { ColumnGroupedViewComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { FlowTypeViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { RollupDefn } from 'src/services/configuration/codecs/viewdefns/general';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { processAdornments } from 'src/components/Adornments/Adornments';

export type FlowTypeOwnProps = z.infer<typeof ColumnGroupedViewComponentProps>;

function mapStateToProps(state: AppState, ownProps: FlowTypeOwnProps): StateProps {
  const { flowType: viewState } = state.pages.hindsighting.styleColorReview;
  const {
    title = 'Flow Type',
    defns,
    keys,
    groupingInfo,
    subheader,
    fabType = FabType.none,
    hideTitle,
    allowWorklistFunctionality = false,
  } = ownProps;
  const groupBy = generateGroupBy(groupingInfo.dataIndex);
  const loaded = !viewState.tenantConfigLoading && isDataLoaded(viewState.viewDataState);
  const currentAdornments: AdornmentType[] = get(viewState.viewDefn, 'adornments', []);
  const adornments = processAdornments(currentAdornments, { allowWorklistFunctionality });

  return {
    title,
    hideTitle,
    loaded,
    config: viewState.viewDefn,
    sortBy: state.subheader.sortBy,
    groupBy,
    subheaderViewDefns: defns.subheader,
    groupedStyles: getProcessedData(state),
    summary: getSummary(state),
    idProp: keys.idProp,
    descProp: keys.descProp || '',
    currentTab: state.perspective.activeTab,
    unmodifiedViewDefn: viewState.unmodifiedViewDefn,
    fabType,
    fabTooltip: ownProps.fabTooltip,
    isFabDisabled: false,
    downloadLink: subheader?.downloadLink,
    viewDataState: viewState.viewDataState,
    fabDefn: defns.fab,
    adornments,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: FlowTypeOwnProps): DispatchProps {
  const { defns, keys } = ownProps;
  return {
    onShowView() {
      dispatch(requestTenantConfig());
      container.tenantConfigClient
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: ASSORTMENT,
          validationSchemas: [FlowTypeViewDefn, RollupDefn],
        })
        .then((resp) => {
          const unmodifiedViewDefn = resp[0];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defns.view[0],
            (resp as any)[defns.view.length],
            dispatch,
            unmodifiedViewDefn
          );
          if (localConfig && localConfig.config) {
            resp[0] = localConfig.config;
          }
          dispatch(
            receiveTenantConfig({
              viewDefn: resp[0],
              calcViewDefn: resp[1],
              unmodifiedViewDefn,
              identityProps: keys,
            })
          );
        })
        .catch((error) =>
          dispatch(
            receiveError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.flowType,
              stack: (error as Error)?.stack,
              issues: error,
            })
          )
        );
    },
    showStylePane(item: ContainerPayload) {
      dispatch(setRightContainerPayload(item));
    },
    onItemClicked(item: ContainerPayload) {
      dispatch(setRightContainerPayload(item));
    },
    onConfigUpdate(config: TenantConfigViewData) {
      dispatch(
        receiveTenantConfig({
          viewDefn: config,
          identityProps: keys,
        })
      );
    },
  };
}

const wrappedView = flow(() => ColumnGroupedView, withFab, makePopoverSensitive, makePrintSensitive)();

export default connect(mapStateToProps, dispatchToProps)(wrappedView);
