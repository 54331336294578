import React from 'react';
import cuid from 'cuid';
import { isArray, isEmpty, isNil, isUndefined } from 'lodash/fp';
import { HORPADDING, BORDERWIDTH, default as styles } from '../StandardCard/StandardCard.styles';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { RightContainerPayloadType } from 'src/components/RightContainer/RightContainer';
import { classes, style } from 'typestyle';
import { default as topCardStyles, IMGWIDTH, TOPHEIGHT, COLWIDTH } from './TopCard.styles';
import Renderer from 'src/utils/Domain/Renderer';
import { CardViewCardColumn } from '../UIData.types';
import CenteredImage from 'src/components/CenteredImage/CenteredImage';
import { swatchStyles } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/StyleDetailsEditor';
import { IS_HINDSIGHTING } from 'src/utils/Domain/ConstantsFunctions';
import { getSectionContext } from 'src/utils/Domain/Perspective';
import { HINDSIGHTING_CONTEXT } from 'src/utils/Domain/Constants';

export type Props = {
  isBlankCard?: boolean;
  popoverTitle?: string;
  imgSrc: string;
  stars: number;
  id: string;
  name: string;
  styleName: string;
  styleId: string;
  description: string;
  styleDescription: string;
  value: number | string;
  columns?: CardViewCardColumn[];
  departmentId?: string;
  swatchIds?: string[];
  isPrintMode?: boolean;
  onItemClick?: (item: ContainerPayload) => void;
  onMoreInfoClick?: (item: ContainerPayload) => void;
};
type State = {
  popoverOpen: boolean;
  loadingImages: boolean;
  targetId: string;
};

export const calcCardWidth = (_colItemCount?: number, _includePadding?: boolean) => {
  return IMGWIDTH + 10 + COLWIDTH + HORPADDING;
};

export class TopCard extends React.Component<Props, State> {
  inCleanup: boolean;

  static calcCardWidth = (_colItemCount?: number, _includePadding?: boolean) => {
    return IMGWIDTH + 10 + COLWIDTH + HORPADDING;
  };

  static calcCardHeight = () => {
    return TOPHEIGHT + BORDERWIDTH * 2;
  };

  constructor(props: Props) {
    super(props);
    this.render = this.render.bind(this);
    this.inCleanup = false;
    this.state = {
      popoverOpen: false,
      loadingImages: false,
      targetId: cuid(),
    };
  }

  componentWillUnmount() {
    this.inCleanup = true;
  }

  handleItemClick = () => {
    if (isNil(this.props.onItemClick)) {
      return;
    }

    const currentContext = getSectionContext();
    const isHindsighting = IS_HINDSIGHTING(currentContext);
    const type =
      currentContext === HINDSIGHTING_CONTEXT
        ? RightContainerPayloadType.History
        : RightContainerPayloadType.Assortment;
    this.props.onItemClick({
      type,
      id: this.props.id,
      parentId: this.props.styleId,
      isAssortmentBuild: !isHindsighting,
    });
  };

  onMoreInfoClick = (item: ContainerPayload) => {
    if (this.props.onMoreInfoClick) {
      this.props.onMoreInfoClick(item);
    }
  };

  formatSwatchValue(values: string[]) {
    return values.map((swatchId) => {
      return {
        id: swatchId,
        colorId: swatchId,
        noImageUrl: '',
      };
    });
  }

  render() {
    const props = this.props;

    const { imgSrc, description, id, name, isPrintMode } = this.props;
    const columns = props.columns
      ? props.columns.map((column, index) => {
          const rendererFn = Renderer[column.renderer];
          const isColorSwatch = column.renderer === 'colorSwatch';
          let renderValue;
          if (isColorSwatch) {
            if (isEmpty(column.value) || isUndefined(column.value) || !isArray(column.value)) {
              return;
            }
            const swatchesValue = this.formatSwatchValue(column.value);
            // we don't want the selection highlight, so send -1 in as selected
            renderValue = Renderer.colorSwatch(swatchesValue, undefined, -1, swatchStyles);
          } else {
            renderValue = rendererFn ? rendererFn(column.value) : column.value;
          }
          return (
            <div
              key={index}
              className={
                isColorSwatch ? topCardStyles.swatchesContainer : classes(styles.colItem, topCardStyles.colItem)
              }
              data-qa={column.title.toLocaleLowerCase()}
            >
              <span className="columnTitle">{column.title}</span>
              <span className={isColorSwatch ? 'swatches' : 'columnValue'}>{renderValue}</span>
            </div>
          );
        })
      : [];
    const cardDisplay = (
      <div id={this.state.targetId} data-qa-action="CardClick" className={classes(styles.infoContainer)}>
        <CenteredImage
          src={imgSrc}
          width={IMGWIDTH}
          height={TOPHEIGHT}
          extraImgProps={{ isBlankCard: props.isBlankCard }}
          extraContainerClass={topCardStyles.image}
        />
        <article className={style({ display: 'block' })}>
          <header className={classes(styles.description, topCardStyles.header)}>
            <h5 className={topCardStyles.headerText}>{`${name} ${description}`}</h5>
            <hr className={topCardStyles.headerRuler} />
          </header>
          {columns.length > 0 ? (
            <section data-qa-component="CardColumns" className={topCardStyles.column}>
              {columns}
            </section>
          ) : null}
        </article>
      </div>
    );
    return (
      <div
        className={!isPrintMode ? `${styles.card} ${topCardStyles.cardContainer}` :  `${styles.card}`}
        data-qa-component="TopCard"
        data-qa-action="OpenPopover"
        onClick={this.handleItemClick}
        data-qa-key={id}
      >
        {cardDisplay}
      </div>
    );
  }
}
