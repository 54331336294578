import * as React from 'react';

export interface Props {
  name: string;
  selected: boolean;
  index: number;
  onSelectTab(tab: number): void;
  clearTabSelections(tab: number): void;
}

export interface State {
  selected: boolean;
}

export default class FilterTab extends React.PureComponent<Props, State> {
  state: State = {
    selected: false,
  };

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { name, selected, onSelectTab, index, clearTabSelections } = this.props;
    const classes = 'tab' + (selected ? ' selected' : '');

    return (
      <div className={classes} onClick={() => onSelectTab(index)} data-selected={selected ? '1' : '0'}>
        <span className="tab-text">
          {name} {selected}
        </span>
        <i className="tab-clear far fa-times" onClick={() => clearTabSelections(index)} />
      </div>
    );
  }
}
