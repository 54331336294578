import * as React from 'react';
import Axios from 'src/services/axios';
import { get, isNil } from 'lodash/fp';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { ViewApiConfig } from '../../StyleEdit.types';
import { Suggestion } from 'src/common-ui/components/Inputs/InputSuggest/InputSuggest';
import { getUrl } from '../../StyleEdit.utils';
import { style } from 'typestyle';
import { muiTheme, TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { MuiThemeProvider } from '@material-ui/core';

export type SpecialStoreGroupProps = {
  selectedItemId: string;
  dataConfig: ViewApiConfig;
  onSelect: (selection: Suggestion | null, initialRenderSelection?: boolean) => void;
};

export type SpecialStoreGroupState = {
  selection: Suggestion | null;
  validValues: Suggestion[];
  filteredGroups: Suggestion[];
};

const styles = {
  select: style({
    width: '100%',
  }),
  selected: style({
    backgroundColor: TEAL_PRIMARY,
  }),
  unselected: style({
    backgroundColor: 'white',
  }),
};

export type SSGData = {
  ssg_id: string;
  ssg_name: string;
  stores: string[];
};

const tableStyle = style({
  $debugName: 'ssg-table-editor',
  $nest: {
    th: {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
});

const EmptyTableRow = () => (
  <TableRow>
    <TableCell>_</TableCell>
    <TableCell>_</TableCell>
    <TableCell>_</TableCell>
    <TableCell>_</TableCell>
  </TableRow>
);

export class SpecialStoreGroupEditor extends React.Component<SpecialStoreGroupProps> {
  state: SpecialStoreGroupState;

  constructor(props: SpecialStoreGroupProps) {
    super(props);

    this.state = {
      selection: null,
      validValues: [],
      filteredGroups: [],
    };
  }

  componentDidMount() {
    const { dataConfig } = this.props;
    const url = getUrl(dataConfig);
    const promise = Axios.get(url).then((resp) => resp.data);

    promise.then((resp: any) => {
      const data: SSGData[] = resp.data;
      const validValues = data.map(
        (store: SSGData): Suggestion => {
          return {
            label: store.ssg_name,
            value: {
              id: store.ssg_id,
              description: store.stores.length,
            },
          };
        }
      );

      const selection = validValues.find((item) => get('value.id', item) === this.props.selectedItemId);

      this.setState({
        selection: isNil(selection) ? null : selection,
        filteredGroups: validValues,
        validValues,
      });

      if (!isNil(selection) && selection) {
        this.props.onSelect(selection, true);
      }
    });

    promise.catch((err) => console.log(err));
  }

  displayRows() {
    const { filteredGroups, selection } = this.state;
    if (isNil(filteredGroups)) {
      return <EmptyTableRow />;
    }

    return filteredGroups.map((item) => {
      const { label: name, value } = item;
      const id = get('id', value);
      const desc = get('description', value);
      const rowClass =
        selection && get('value.id', item) === get('value.id', selection) ? styles.selected : styles.unselected;
      return (
        <TableRow
          key={id}
          className={rowClass}
          onClick={() => {
            this.handleSelection(item);
          }}
        >
          <TableCell>{id}</TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{desc}</TableCell>
        </TableRow>
      );
    });
  }

  handleSelection = (updated: Suggestion) => {
    if (isNil(updated)) {
      return;
    }
    this.setState({
      selection: updated,
    });

    if (!isNil(updated)) {
      this.props.onSelect(updated);
    }
  };

  handleRemove = () => {
    this.setState({
      selection: null,
    });

    this.props.onSelect(null);
  };

  handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    const keyword = e.currentTarget!.value;
    const exp = new RegExp(keyword, 'i');
    const filteredGroups = this.state.validValues.filter((value) => {
      return !isNil(['label', 'value.id'].find((key) => get(key, value).search(exp) > -1));
    });
    this.setState({
      filteredGroups: filteredGroups,
    });
  };

  render() {
    const {} = this.state;

    return (
      <MuiThemeProvider theme={muiTheme}>
        <input
          type="text"
          className="form-control"
          placeholder="Search groups..."
          aria-label="Search groups"
          onChange={this.handleSearchChange}
          defaultValue={''}
        />
        <div style={{ maxHeight: 300, overflowY: 'auto' }}>
          <Table>
            <TableHead className={tableStyle}>
              <TableRow>
                <TableCell>SSG ID</TableCell>
                <TableCell>SSG NAME</TableCell>
                <TableCell># STRS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.displayRows()}</TableBody>
          </Table>
        </div>
      </MuiThemeProvider>
    );
  }
}
