import * as React from 'react';
import Button from '@material-ui/core/Button';
import rejectButton from './RejectButton.styles';

export type Props = {
  onClick: () => void;
};

const RejectButton = (props: Props) => {
  return (
    <Button
      variant="contained"
      className={rejectButton}
      onClick={() => {
        props.onClick();
      }}
    >
      <i className="fal fa-fw fa-2x fa-times" />
    </Button>
  );
};

export default RejectButton;
