import { createAsyncThunk } from '@reduxjs/toolkit';
import { WorklistClient } from './BulkImport.client';
import { ThunkApi } from 'src/store';
import { BulkImportMutation, BulkImportNotice } from './BulkImport.slice';

export const getMutations = createAsyncThunk<BulkImportMutation[], undefined, ThunkApi & { rejectValue: string }>(
  'BulkImport/getMutations',
  async (_none, { rejectWithValue }) => {
    try {
      return WorklistClient().getMutations();
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  }
);

export const uploadMutationTemplate = createAsyncThunk<
  BulkImportNotice,
  {
    mutationId: string;
    formData: FormData;
  },
  ThunkApi & { rejectValue: string }
>('BulkImport/uploadMutationTemplate', async (payload, { rejectWithValue }) => {
  try {
    const notices = await WorklistClient().uploadMutationTemplate(payload.mutationId, payload.formData);

    const ret: BulkImportNotice =
      typeof notices === 'number'
        ? {
            rowsLoaded: notices,
          }
        : notices;
    return ret;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});

export const getNotices = createAsyncThunk<BulkImportNotice[], string, ThunkApi & { rejectValue: string }>(
  'BulkImport/getNotices',
  async (runId: string, { rejectWithValue }) => {
    try {
      return WorklistClient().getNotices(runId);
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  }
);
