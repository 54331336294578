import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cacheCheckListData } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { ViewDataState } from 'src/types/Domain';
import { Pivot, ListDataOptions } from 'src/worker/pivotWorker.types';
import { IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import { WorklistViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { GroupViewItem } from 'src/services/configuration/codecs/viewdefns/general';

export type WorklistSlice = {
  viewDefn: WorklistViewDefn | undefined;
  isConfigLoading: boolean;
  isDataLoading: boolean;
  liveData: Pivot;
  cachedData: Pivot;
  viewDataState: ViewDataState;
  worklistFlowStatus: number[];
  worklistSearch: string;
  selectedItem?: string;
  selectedLevel?: GroupViewItem;
  identityPropsConfig?: IdentityPropsConfig;
};

const initialState: WorklistSlice = {
  viewDefn: undefined,
  isConfigLoading: false,
  isDataLoading: false,
  viewDataState: ViewDataState.idle,
  worklistFlowStatus: [],
  worklistSearch: '',
  cachedData: {
    tree: [],
    flat: [],
  },
  liveData: {
    tree: [],
    flat: [],
  },
};

const worklistSliceReducer = createSlice({
  name: 'Worklist',
  initialState: initialState,
  reducers: {
    requestWorklistConfig(state) {
      state.isConfigLoading = true;
    },
    receiveWorklistConfig(state, action: PayloadAction<WorklistViewDefn>) {
      state.isConfigLoading = false;
      state.viewDefn = action.payload;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
      state.cachedData = initialState.cachedData;
      state.liveData = initialState.liveData;
    },
    receiveCachedData(state, action: PayloadAction<Pivot>) {
      state.viewDataState = ViewDataState.liveDataLoadingFoundCache;
      state.cachedData = action.payload;
    },
    receiveLiveData(state, action: PayloadAction<Pivot>) {
      state.viewDataState = ViewDataState.liveDataReady;
      state.liveData = action.payload;
    },
    updateSelectedItem(state, action: PayloadAction<string>) {
      state.selectedItem = action.payload;
    },
    updateSelectedLevel(state, action: PayloadAction<GroupViewItem | undefined>) {
      state.selectedLevel = action.payload;
    },
    updateWorklistFlowStatus(state, action: PayloadAction<number[]>) {
      state.worklistFlowStatus = action.payload;
    },
    updateWorklistSearch(state, action: PayloadAction<string>) {
      state.worklistSearch = action.payload;
    },
    receiveWorklistError(_state, _action) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestWorklistConfig,
  receiveWorklistConfig,
  requestData,
  receiveCachedData,
  receiveLiveData,
  updateSelectedItem,
  updateSelectedLevel,
  updateWorklistFlowStatus,
  updateWorklistSearch,
  receiveWorklistError,
  cleanUp,
} = worklistSliceReducer.actions;
export default worklistSliceReducer.reducer;

export function fetchCompanionListData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckListData(
    modelDefn,
    'worklist.isDataLoading',
    requestData,
    receiveCachedData,
    receiveLiveData,
    options
  );
}
