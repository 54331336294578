import * as React from 'react';
import { classes } from 'typestyle';
import { isArray, isNil, isNumber, isString } from 'lodash';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import * as dropdownStyles from 'src/utils/Style/Dropdown.styles';
import coalesce from 'src/utils/Functions/Coalesce';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';

export type SubheaderDropdownProps = {
  label: string;
  defaultSelection?: number | null;
  selection?: number | null;
  options: TenantConfigViewItem[];
  qaKey?: string;
  customClass?: string;
  disabled?: boolean;
  dataQa?: string;
  handleChangeOnDropdown: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

type State = {
  dropdownOpen: boolean;
};

export default class SubheaderDropdown extends React.Component<SubheaderDropdownProps, State> {
  maxCharacterCount = 20;

  constructor(props: SubheaderDropdownProps) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  /**
   * Type Guard that checks for the specific event type expected by mui4
   * @param event mui4 gives out the minimal type, so name and value<unknown>
   * @returns
   */
  static isChangeEventSelect = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): event is React.ChangeEvent<HTMLSelectElement> => {
    return isArray(event.target.value) || isString(event.target.value) || isNumber(event.target.value);
  };
  /**
   * Type guard that verifies we have a specific type of event from a Select
   * @param value unknown
   * @returns boolean
   * TS doesn't like 'prop' in obj for some reason, so it's ignored here, but the check should be good
   */
  static isChangeEventSelectValue = (value: unknown): value is Record<'key', string> => {
    // @ts-ignore
    return typeof value === 'object' && !isNil(value) && 'key' in value && typeof value.key === 'string';
  };

  handleOnChange(event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) {
    const { handleChangeOnDropdown } = this.props;
    if (!SubheaderDropdown.isChangeEventSelect(event)) {
      throw new Error('Invalid change event fired, this is unexpected');
    }
    if (handleChangeOnDropdown) {
      handleChangeOnDropdown(event);
    }
  }

  render() {
    const { label, defaultSelection, selection, options, qaKey, customClass, dataQa } = this.props;


    const selected = coalesce(selection, defaultSelection, 0) as number; // final being 0 guarantees number

    // add empty option if no options available to prevent warning bombardment
    const optionsWithFallback =
      options.length === 0
        ? [
            {
              dataIndex: '',
              dimension: 'product',
              text: '',
            },
          ]
        : options;
    const optionsJsx = optionsWithFallback.map((option, index) => {
      const text = option.text + '';
      return (
        <MenuItem key={index} value={index} data-position={index} title={option.text}>
          {text}
        </MenuItem>
      );
    });
    return (
      <FormControl
        className={classes('form-control-dropdown', dropdownStyles.form)}
        data-qa-component="SubheaderDropdown"
        data-qa-key={qaKey}
      >
        {label && <InputLabel className={dropdownStyles.formLabel}>{label}</InputLabel>}

        <Select
          data-qa-action="DropdownClick"
          className={classes(dropdownStyles.dropdown, isNil(customClass) ? '' : customClass)}
          disableUnderline={true}
          value={selected}
          onChange={this.handleOnChange}
          IconComponent={(props) => <i {...props} className="far fa-chevron-down" />}
          data-qa={dataQa}
        >
          {optionsJsx}
        </Select>
      </FormControl>
    );
  }
}
