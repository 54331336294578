import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UNINITIALIZED } from 'src/utils/Domain/Constants';
import { TenantConfig } from './bindings.types';

export interface AppConfigSlice {
  tenantConfig: TenantConfig;
  configurationConfig: TenantConfig;
  configFingerprint?: string;
}

const defaultConfig = {
  id: UNINITIALIZED,
  defaultTab: {
    /* eslint-disable @typescript-eslint/naming-convention */
    BottomUp: UNINITIALIZED,
    TopDown: UNINITIALIZED,
    Configurator: UNINITIALIZED,
    /* eslint-enable @typescript-eslint/naming-convention */
  },
  idleTimeout: 300,
  flowStatus: {
    values: [],
    defaultSelection: [],
  },
  lookBackPeriods: [],
  perspectives: [],
  placeholder: {
    text: 'PLACEHOLDER',
  },
  context: {
    assortment: { tabs: [] },
    hindsighting: { tabs: [] },
    allocation: { tabs: [] },
  },
  tabs: [],
  printSizes: [],
  canAddNewStyles: true,
  disableScope: true,
  disableFilters: true,
} as TenantConfig;

export const initialState: AppConfigSlice = {
  tenantConfig: defaultConfig,
  configurationConfig: defaultConfig,
};

const appConfigSlice = createSlice({
  name: 'AppConfig',
  initialState,
  reducers: {
    updateAppConfig(state, action: PayloadAction<TenantConfig>) {
      state.tenantConfig = action.payload;
    },
    updateConfiguratorConfig(state, action: PayloadAction<TenantConfig>) {
      state.configurationConfig = action.payload;
    },
    updateConfigFingerPrint(state, action: PayloadAction<string>) {
      state.tenantConfig = state.tenantConfig;
      state.configFingerprint = action.payload;
    },
  },
});

export const { updateAppConfig, updateConfiguratorConfig, updateConfigFingerPrint } = appConfigSlice.actions;

export default appConfigSlice.reducer;
