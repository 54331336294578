import { connect, Dispatch } from 'react-redux';
import Subheader from './Subheader';
import { AppState } from 'src/store';
import {
  cleanUp,
  updateSearch,
  requestViewDefns,
  receiveViewDefns,
  updateGroupBy,
  updateSortBy,
  updateFlowStatus,
  updateSortByDirection,
  updateLookBackPeriod,
  updateCountLimit,
  updateAlternateSearch,
  updateAlternateFlowStatus,
  updatePareDown,
  updateFavoritesList,
  receiveError,
} from 'src/components/Subheader/Subheader.slice';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { FavoriteResponseItem } from './Favorites/FavoritesMenu';
import { getFavoriteByDefnId } from './Favorites/Favorites.client';
import { resetGridModel } from 'src/components/Sidenav/SideNav.slice';
import { setRightContainerPayload } from '../RightContainer/RightContainer.slice';
import { RightContainerPayloadType } from '../RightContainer/RightContainer';
import { SubheaderOwnProps, SubheaderStateProps, SubheaderDispatchProps } from './Subheader.types';

export function mapStateToProps(state: AppState, ownProps: SubheaderOwnProps): SubheaderStateProps {
  return {
    subheader: state.subheader,
    lookBackPeriods: state.appConfig.tenantConfig.lookBackPeriods,
    flowStatusOptions: state.appConfig.tenantConfig.flowStatus,
    showDateRange: true,
    gridFilterModel: state.sidenav.gridFilterModel,
    gridSortModel: state.sidenav.gridSortModel,
    ...ownProps,
  };
}

export function dispatchToProps<S>(dispatch: Dispatch<S>): SubheaderDispatchProps {
  const client = container.tenantConfigClient;
  return {
    getViewDefns(defns: string[]) {
      dispatch(requestViewDefns(defns));
      client
        .getTenantViewDefns({
          defnIds: defns,
          appName: ASSORTMENT,
        })
        .then((resp) => {
          dispatch(
            receiveViewDefns({
              groupBy: resp[0],
              sortBy: resp[1],
              countLimit: resp[2],
              pareDown: resp[3],
            })
          );
        })
        .catch((_error) => dispatch(receiveError("Couldn't load view definitions")));
    },
    updateSearchString(value: string) {
      dispatch(updateSearch(value));
    },
    updateAltSearchString(value: string) {
      dispatch(updateAlternateSearch(value));
    },
    updateFlowStatus(values: number[]) {
      dispatch(updateFlowStatus(values));
    },
    updateAltFlowStatus(values: number[]) {
      dispatch(updateAlternateFlowStatus(values));
    },
    updateLookBackPeriod(value: string) {
      dispatch(updateLookBackPeriod(value));
    },
    setGroupBySelection(value: number | null) {
      dispatch(updateGroupBy(value));
    },
    setSortBySelection(value: number | null) {
      dispatch(updateSortBy(value));
    },
    setPareDownSelections(values: TenantConfigViewItem[]) {
      dispatch(updatePareDown(values));
    },
    setSortByDirection() {
      dispatch(updateSortByDirection());
    },
    setCountLimit(value: number) {
      dispatch(updateCountLimit(value));
    },
    setFavoritesList(list: FavoriteResponseItem[]) {
      dispatch(updateFavoritesList(list));
    },
    getFavoritesList(defnId: string) {
      getFavoriteByDefnId(defnId).then((resp) => {
        if (resp) dispatch(updateFavoritesList(resp.data.data as FavoriteResponseItem[]));
      });
    },
    onOpenUndoPanel() {
      dispatch(setRightContainerPayload({ type: RightContainerPayloadType.Undo }));
    },
    onDestroy() {
      dispatch(cleanUp());
    },
    resetGridModel() {
      dispatch(resetGridModel());
    },
  };
}

export default connect(mapStateToProps, dispatchToProps)(Subheader);
