import { combineReducers } from 'redux';
import categorySummaryReducer, { CategorySummarySlice } from './CategorySummary/CategorySummary.slice';

export type CategoryRecapSlice = {
  categorySummary: CategorySummarySlice;
};

export default combineReducers({
  categorySummary: categorySummaryReducer,
});
