import { BasicTenantConfigViewItem, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { partial } from 'lodash/fp';
import { GroupBySlice, SortBySlice, SubheaderDropdownSlice } from 'src/components/Subheader/Subheader.slice';
import * as _ from 'lodash';
import coalesce from 'src/utils/Functions/Coalesce';

function getSliceBySelectedOptionProperty(ddSlice: SubheaderDropdownSlice, prop: keyof BasicTenantConfigViewItem) {
  return _.flow(
    () => ddSlice,
    (slice) => {
      return coalesce(slice.selection, slice.defaultSelection);
    },
    (selection) => {
      return _.get(ddSlice.options, `[${selection}].${prop}`, '');
    }
  )();
}

export function getGroupBySelectedOptionProperty(groupBy: GroupBySlice, prop: keyof BasicTenantConfigViewItem): string {
  return getSliceBySelectedOptionProperty(groupBy, prop);
}

export function getSortBySelectedOptionProperty(sortBy: SortBySlice, prop: keyof BasicTenantConfigViewItem): string {
  return getSliceBySelectedOptionProperty(sortBy, prop);
}

export type SortBy = {
  defaultSelection: number;
  direction: 'asc' | 'desc';
  selection: number | null;
  options: TenantConfigViewItem[];
};

// tslint:disable-next-line:no-any
function _nilCoerce(type: string, value: any) {
  switch (type) {
    case 'string':
      return value == null ? '' : value;
    case 'number':
      return value == null ? -1 : value;
    default:
      return value;
  }
}

export function simpleByField<T extends Record<string, any>>(
  sortables: T[],
  field: string,
  direction: 'asc' | 'desc' = 'desc'
) {
  if (Array.isArray(sortables)) {
    // find first non-nil value (or all are nil; set to undef)
    const validObject = sortables.find((i) => i[field] != null);
    const dataType = typeof (validObject ? validObject[field] : undefined);
    const inverter = direction === 'desc' ? -1 : 1;
    let comparator;
    const coerce = partial(_nilCoerce, [dataType]);

    switch (dataType) {
      case 'string':
        comparator = (l: string, r: string) => coerce(l[field]).localeCompare(coerce(r[field])) * inverter;
        break;
      case 'number':
        comparator = (l: number, r: number) => (coerce(l[field]) - coerce(r[field])) * inverter;
        break;
      case 'undefined':
        // tslint:disable-next-line:no-any
        comparator = () => 0;
        break;
      default:
        throw new Error('No comparator for type: ' + dataType);
    }
    // tslint:disable-next-line:no-any
    return [...sortables].sort(comparator as any);
  }
  return sortables;
}

export function maybeSortPivotItems<T extends Record<string, any>>(sortBy: SortBy, pivotItems: T[]) {
  const key = getSortBySelectedOptionProperty(sortBy, 'dataIndex');
  return simpleByField(pivotItems, key, sortBy.direction);
}
