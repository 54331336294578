import React, { useRef, useState } from 'react';
import { Popover, Typography } from '@material-ui/core';
import { PrintSize } from 'src/components/higherOrder/Print/Print';
import { IconLink } from 'src/components/Headerbar/Links/IconLink';
import styles from 'src/components/Headerbar/Headerbar.styles';

interface PrintLinkProps {
  isViewPrintable: boolean;
  isPrintMode: boolean | undefined;
  printSizes: PrintSize[];
  onClick: (size: PrintSize) => void;
}

export const PrintLink = ({ isViewPrintable, isPrintMode = false, printSizes, onClick }: PrintLinkProps) => {
  const [isOpen, setPopoverOpen] = useState(false);
  const anchorElement = useRef(null);

  const onLinkClick = () => {
    const nextState = !isOpen;
    setPopoverOpen(nextState);
  };

  const handlePrint = () => window.print();

  if (!isViewPrintable) {
    return null;
  }

  return (
    <React.Fragment>
      {isPrintMode && <IconLink id="PrintLink" iconClass="far fa-print" onClick={handlePrint} text="Print" />}
      <IconLink
        id="ConfigurePageSizeLink"
        iconClass={isPrintMode ? 'fal fa-expand-arrows' : 'far fa-print'}
        text={isPrintMode ? 'Configure Page Size' : 'Print'}
        onClick={onLinkClick}
        ref={anchorElement}
      />
      <div>
        <Popover
          open={isOpen}
          onClose={onLinkClick}
          anchorEl={anchorElement.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{ root: styles.printPopover }}
        >
          <Typography>Paper Size</Typography>
          <ul className={styles.selectorDropDown}>
            {printSizes.map((size) => {
              const printLabel = size.name ? size.name : `${size.width}in x ${size.height}in`;
              return (
                <li onClick={() => onClick(size)} key={size.width} data-qa-key={size}>
                  <a>{printLabel}</a>
                </li>
              );
            })}
          </ul>
        </Popover>
      </div>
    </React.Fragment>
  );
};
