import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ReportConfig } from 'src/dao/reportClient';

export type ReportingSlice = {
  isLoading: boolean;
  reportConfig?: ReportConfig;
};

const initialState: ReportingSlice = {
  isLoading: true,
  reportConfig: {} as ReportConfig,
};

const reportingSlice = createSlice({
  name: 'Reporting',
  initialState,
  reducers: {
    requestReportingConfig(state) {
      state.isLoading = true;
    },
    receiveReportingConfig(state, action: PayloadAction<ReportConfig>) {
      state.reportConfig = action.payload;
      state.isLoading = false;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const { requestReportingConfig, receiveReportingConfig, receiveError, cleanUp } = reportingSlice.actions;

export default reportingSlice.reducer;
