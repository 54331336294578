import { FavoriteListItem } from 'src/components/Subheader/Favorites/FavoritesMenu';

export function FavoritesService(view: string) {
  const getFavorites = () => {
    return localStorage.getItem(view + '-Favorites') || '';
  };
  const setFavorites = (newFavorites: string) => {
    localStorage.setItem(view + '-Favorites', newFavorites);
  };
  const service = {
    addFavoriteToStorage: (favItem: FavoriteListItem) => {
      localStorage.setItem(favItem.id, JSON.stringify(favItem));
      setFavorites(getFavorites() + favItem.id + ',');
    },
    applyFavorite: (favItem: FavoriteListItem) => {
      let newLocalView = {
        config: favItem.config,
        groupBySelection: favItem.groupBySelection,
        sortBySelection: favItem.sortBySelection,
        sortByDirection: favItem.sortByDirection,
        configurationSelections: favItem.configurationSelections,
      };
      const localView = localStorage.getItem(view);
      if (localView) {
        newLocalView = {
          ...JSON.parse(localView),
          ...newLocalView,
        };
      }
      localStorage.setItem(view, JSON.stringify(newLocalView));
    },
    getFavorites: (): FavoriteListItem[] => {
      const favoritesString = getFavorites();
      return favoritesString.length > 0
        ? favoritesString
            .substr(0, favoritesString.length - 1)
            .split(',')
            .map((id: string) => JSON.parse(localStorage.getItem(id)!))
        : [];
    },
    removeFavorite: (favId: string) => {
      localStorage.removeItem(favId);
      const newFavorites: string = getFavorites().replace(favId + ',', '');
      setFavorites(newFavorites);
      return true;
    },
    applySingleFieldToStorage: (favItem: any) => {
      const oldLocalView = JSON.parse(localStorage.getItem(view)!);
      const newLocalView = {
        ...oldLocalView,
        ...favItem,
      };
      localStorage.setItem(view, JSON.stringify(newLocalView));
    },
  };
  return service;
}

export type FavoritesServiceType = ReturnType<typeof FavoritesService>;
